import React from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Form from './common/forms/Form';
import Button from './Button';
import useAuthContext from 'hooks/useAuthContext';
import TextInput from './TextInput';
import FormErrorText from './common/forms/FormErrorText';
import SubmitButton from './common/forms/SubmitButton';

import styles from 'assets/css/ReportView.module.scss';

const INPUT_NAME = 'email';

type EmailForm = { [INPUT_NAME]: string };

type SendToEmailProps = {
  initialButtonText?: string;
  disabled?: boolean;
  onSubmit: (email: string) => void;
};

const SendToEmailForm = ({
  initialButtonText = 'Send to email',
  disabled,
  onSubmit
}: SendToEmailProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const { user } = useAuthContext();

  return (
    <section className={styles.actionButton}>
      <Form
        className={styles.statementsEmailContainer}
        validateOnChange
        initialValues={{
          [INPUT_NAME]: user ? user.email : ''
        }}
        validationSchema={Yup.object().shape({
          [INPUT_NAME]: Yup.string()
            .email(t('Invalid Email'))
            .required(t('Email is required'))
        })}
        onSubmit={({ [INPUT_NAME]: email }: EmailForm) => onSubmit(email)}
      >
        {({ isSubmitting, errors, touched }: FormikProps<EmailForm>) => (
          <>
            {isOpen ? (
              <>
                <TextInput
                  {...{
                    type: 'email',
                    name: INPUT_NAME,
                    placeholder: t('Enter your email address'),
                    focus: true,
                    errors,
                    touched
                  }}
                />

                <SubmitButton disableIfNotValid isDisabled={disabled}>
                  {t('Send')}
                </SubmitButton>
                <FormErrorText name={INPUT_NAME} />
              </>
            ) : (
              <Button
                type="primary"
                size="lg"
                rightIcon="envelope"
                onClick={() => setIsOpen(true)}
                disabled={isSubmitting || disabled}
              >
                {t(initialButtonText)}
              </Button>
            )}
          </>
        )}
      </Form>
    </section>
  );
};

export default SendToEmailForm;
