import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import moment from 'moment';
import Measure from 'react-measure';

import useStateMachine from 'hooks/useStateMachine';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { RESPONSIVE_BREAKPOINTS } from 'helpers/TypeConstants';
import { hasKeys } from 'utils';
import FloatingActionButton from 'components/FloatingActionButtonModal';
import { AuthContext } from 'contexts/AuthContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';
import { OutletContext } from 'contexts/OutletContext';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { MapleContext } from 'contexts/MapleContext';
import { NATIVE, call } from 'utils/nativeInterface';

import OpsLayout from 'layouts/OpsLayout';
import AppLayout from 'layouts/AppLayout';
import SingleDatePicker from 'components/SingleDatePicker';
import Heading from 'components/Heading';
import Badge from 'components/Badge';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';
import Button, { TappedButton } from 'components/Button';
import FavePayIcon from 'assets/images/favepay-icon.svg';
import FaveDealIcon from 'assets/images/favedeal-icon.svg';
import AliPayIcon from 'assets/images/alipay-icon.svg';
import NoTransactionImage from 'assets/images/img-no-transaction.svg';
import qrFAB from 'assets/images/ic-qr-fab.svg';
import Cookie from 'helpers/Cookie';

import { STATE, EVENT, stateMachine } from './stateMachine';
import {
  useErrorNotifications,
  useIntersectionObservers,
  useOutletTransactions,
  useOutletTransactionsSummary,
  useOutletFilters,
  useHandleOutletChange,
  useRefreshTransactions,
  useOutletSocketUpdate,
  useFirstTransactionUpdateNow,
  useClearCache,
  useScrollToTopForToday
} from './effects';
import styles from 'assets/css/TransactionsView.module.scss';
import GuaranteeFeeLabel from 'components/GuaranteeFeeLabel';

let cache = {};

function TransactionsView() {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { isMaple } = useContext(MapleContext);
  const { isWebview } = useContext(WebviewContext);
  const { clearCache } = useContext(QueryStringContext);
  const { authCode: qsAuthCode } = useContext(QueryStringContext);

  const authCode = qsAuthCode || Cookie.get('authCode', null);
  const isStaffMode = isWebview || !isMaple ? true : !!authCode;

  const filters = [
    { text: t('All'), value: 'all', event: 'filter_by_all' },
    { text: t('FavePay'), value: 'favepay', event: 'filter_by_favepay' },
    { text: t('Deals'), value: 'deals', event: 'filter_by_deals' },
    { text: t('Alipay'), value: 'alipay', event: 'filter_by_alipay' }
  ];

  const { user, settings } = useContext(
    isStaffMode ? OpsAuthContext : AuthContext
  );
  const { currentOutlet, currentCompany } = useContext(
    isStaffMode ? OpsOutletContext : OutletContext
  );
  const [orientation, setOrientation] = useState('horizontal');

  const [sentCTEvent, setSentCTEvent] = useState(false);
  useEffect(() => {
    if (!currentCompany.partner_name || sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'transaction' });
    setSentCTEvent(true);
  }, [currentCompany, sentCTEvent, cleverTap.screenDisplayed]);

  const initialState = {
    /* allow returning page to clearCache via query param */
    hasCache: hasKeys(cache) && !clearCache,
    state: hasKeys(cache) ? STATE.FETCHING_SUMMARY : STATE.IDLE,
    filters: cache.filters || ['all'],
    filter: cache.filter || filters[0].value,
    date: cache.date || moment(),
    transactions: [],
    page: 1,
    hasMoreTransactions: false,
    hasNewTransactions: false,
    transactionsCount: 0,
    transactionSummaryVisible: false,
    firstTransactionVisible: true,
    summary: { revenue: 0, transactions: 0 }
  };
  const [current, send] = useStateMachine(stateMachine, initialState);

  cache = useClearCache({ current, cache });
  useErrorNotifications({ current });
  useHandleOutletChange({ send, currentOutlet });
  useOutletFilters({ current, send, settings, isStaffMode });
  useOutletTransactionsSummary({ current, send, currentOutlet });
  useOutletTransactions({ current, send, currentOutlet });
  useIntersectionObservers({ current, send });
  useOutletSocketUpdate({ current, send, currentOutlet });
  useRefreshTransactions({ current, send, t });
  useFirstTransactionUpdateNow({ current, send });
  useScrollToTopForToday({ current });
  window.refreshTransactions = () => send(EVENT.RESET);

  const Filters = () => (
    <section
      className={classNames(styles.filters, isWebview && styles.webviewSpacing)}
    >
      {filters
        .filter(f => current.filters.includes(f.value))
        .map(({ value, text, event }) => (
          <TappedButton
            key={value}
            type="ghost"
            event={{
              screen_name: 'transaction',
              section_name: 'filter_tabs',
              tapped_on: event
            }}
            onClick={() => send(EVENT.CHANGE_FILTER, { filter: value })}
          >
            <Badge checked={current.filter === value}>{text}</Badge>
          </TappedButton>
        ))}
    </section>
  );

  const TransactionsSummary = () => (
    <Card id="transactionSummary" className={styles.cardLayout}>
      <SingleDatePicker
        orientation={orientation}
        date={current.date}
        onChange={date => {
          send(EVENT.CHANGE_DATE, { date });
          cleverTap.tapped.push({
            screen_name: 'transaction',
            tapped_on: 'btn_date'
          });
        }}
        isOutsideRange={
          day => day.isAfter(moment(), 'day') /* no future days */
        }
        showToday
        initialVisibleMonth={
          () => moment().subtract(1, 'M') /* previous month */
        }
      />
      <Button
        type="ghost"
        rightIcon="reload"
        onClick={() => {
          send(EVENT.RESET);
          cleverTap.tapped.push({
            screen_name: 'transaction',
            tapped_on: 'btn_refresh'
          });
        }}
        children=""
      />
      <dl className={styles.summaryLayout}>
        <dt>
          {t('Revenue ({{currency}})', {
            currency: ((user || {}).city || {}).currency_display
          })}
        </dt>
        <dd>{current.summary.revenue}</dd>
        <dt>{t('Transactions')}</dt>
        <dd>{current.summary.count}</dd>
      </dl>
    </Card>
  );

  const NewTransactionsButton = () => (
    <Button
      type="primary"
      className={classNames(
        styles.newTransactions,
        current.showNewTransactions && styles.visible
      )}
      onClick={() => send(EVENT.CLEAR_NEW_TRANSACTIONS)}
    >
      {t('{{transactionsCount}} New incoming transactions.', {
        transactionsCount: current.transactionsCount
      })}
      <span className={styles.view}>{t('View')}</span>
    </Button>
  );

  const getFilterByType = type =>
    (
      filters.find(
        filter =>
          filter.value === (type === 'flp' || type === 'rpp' ? 'favepay' : type)
      ) || {}
    ).text;

  const NoTransactionsListItem = () => (
    <li disabled>
      <img src={NoTransactionImage} alt={t('No transaction yet')} />
      {t('There is no transaction yet.')}
    </li>
  );

  const TransactionListItem = ({ transaction, index }) => {
    const iconByTransaction = {
      rpp: FavePayIcon,
      flp: FavePayIcon,
      favepay: FavePayIcon,
      deals: FaveDealIcon,
      alipay: AliPayIcon,
      'off-maple': FavePayIcon,
      maple: FavePayIcon
    };

    const routes = isStaffMode
      ? {
          alipay: `/operations/transactions/alipay/${transaction.id}`,
          favepay: `/operations/transactions/${transaction.id}`,
          flp: `/operations/transactions/flp/${transaction.id}`,
          rpp: `/operations/transactions/rpp/${transaction.id}`,
          deals: `/operations/redemption/${transaction.redemption_code}`,
          'off-maple': `/operations/transactions/off-maple/${transaction.id}`,
          maple: `/operations/transactions/maple/${transaction.id}`
        }
      : {
          'off-maple': `/operations/transactions/off-maple/${transaction.id}`,
          maple: `/operations/transactions/maple/${transaction.id}`
        };
    const isNow = date =>
      moment(date)
        .fromNow()
        .includes('few seconds');

    const getTransactionDisplayDateTime = transaction =>
      format(new Date(transaction.created_at), 'p');

    const renderAppName = client => (
      <div className={styles.paidVia}>
        {t('Paid via ')}
        {client.app_name}
      </div>
    );

    return (
      <li
        id={index === 0 ? 'firstTransaction' : undefined}
        className={classNames(styles[transaction.status])}
        onClick={() => {
          cache = {
            date: current.date,
            filters: current.filters,
            filter: current.filter
          };
          cleverTap.tapped.push({
            screen_name: 'transaction',
            tapped_on: 'btn_transaction_details'
          });
          navigate(routes[transaction.type]);
        }}
      >
        <section className={styles.leftColumn}>
          <section className={styles.transactionType}>
            <img
              alt={transaction.type}
              src={iconByTransaction[transaction.type]}
              className={styles.transactionTypeIcon}
            />
            <div>{getFilterByType(transaction.type)}</div>
          </section>

          <section className={styles.transactionDetails}>
            <h4 className={styles.transactionHeading}>
              {transaction.user_name}
            </h4>
            <div className={styles.transactionID}>
              {transaction.redemption_code
                ? transaction.redemption_code.toUpperCase()
                : transaction.rpp_participant
                ? `Ending-${transaction.receipt_id.slice(-8)}` // If RPP, show last 8 digits only
                : transaction.receipt_id}
            </div>
            {(transaction.flp_client || transaction.rpp_participant) &&
              renderAppName(
                transaction.flp_client || transaction.rpp_participant
              )}
          </section>
        </section>

        <section
          className={classNames(
            styles.rightColumn,
            isNow(transaction.created_at) && styles.now
          )}
        >
          <time dateTime={transaction.created_at}>
            {isNow(transaction.created_at)
              ? t('now')
              : getTransactionDisplayDateTime(transaction)}
          </time>
          <div className={styles.amount}>{transaction.total_amount}</div>
          {transaction.type === 'deals' && !transaction.status ? (
            <GuaranteeFeeLabel
              guarunteeFee={transaction.guarantee_fee_percentage}
              isTransactionCard={true}
            />
          ) : (
            <></>
          )}
          {transaction.status === 'cancelled' && (
            <div className={styles.cancelledText}>
              {t('Transaction Cancelled')}
            </div>
          )}
          {transaction.status === 'pending_payment' && (
            <div className={styles.pendingText}>{t('Transaction Pending')}</div>
          )}
        </section>
      </li>
    );
  };

  const LoadMoreListItem = () => (
    <li
      id="loadMoreTransactions"
      className={styles.loadMore}
      onClick={() => send(EVENT.FETCH_MORE_TRANSACTIONS)}
    >
      {current.state === STATE.FETCHING_MORE_TRANSACTIONS ? (
        <>
          {/* maintain padding */} &nbsp;
          <LoadingSpinner baseSize="1px" />
        </>
      ) : (
        t('Load more')
      )}
    </li>
  );

  const MapleQRFAB = ({ outlet }) => (
    <Button
      type="ghost"
      onClick={() => {
        cleverTap.tapped.push({
          screen_name: 'tools',
          tapped_on: 'btn_outlet_qrcode'
        });
        isWebview
          ? call(NATIVE.ON_QR_CLICKED, outlet.id)
          : navigate(
              isStaffMode
                ? '/operations/payment'
                : `/payment/${currentOutlet.id}`
            );
      }}
      className={styles.mapleQRFAB}
    >
      <img src={qrFAB} alt="show-qr" />
    </Button>
  );

  const TransactionViewContent = () => (
    <>
      {!isWebview && (
        <Heading
          title={t('Transactions')}
          subtitle={t(
            'View your latest and live transactions of your outlet here'
          )}
        />
      )}
      <Measure
        bounds
        onResize={({ bounds }) => {
          setOrientation(
            bounds.width < RESPONSIVE_BREAKPOINTS.md ? 'vertical' : 'horizontal'
          );
        }}
      >
        {({ measureRef }) => (
          <section ref={measureRef}>
            {!isMaple ? <Filters /> : <br />}
            {current.state !== STATE.PICKING_OUTLET ? (
              <TransactionsSummary />
            ) : (
              <></>
            )}
            <section
              className={styles.transactionsLayout}
              style={{ '--buttonTop': isWebview ? '1rem' : '5rem' }}
            >
              <NewTransactionsButton />
              {[
                STATE.FETCHING_FILTERS,
                !current.hasCache && STATE.FETCHING_SUMMARY,
                !current.hasCache && STATE.FETCHING_TRANSACTIONS
              ].includes(current.state) ? (
                <LoadingSpinner className={styles.transactionSpinner} />
              ) : current.state === STATE.PICKING_OUTLET ? (
                <ul className={styles.transactionList}>
                  <li className={styles.pickOutlet} disabled>
                    {t('Select an outlet to view transactions')}
                  </li>
                </ul>
              ) : (
                <ul
                  className={classNames(
                    styles.transactionList,
                    current.showNewTransactions && styles.noTopMargin
                  )}
                >
                  {current.transactions.length === 0 && (
                    <NoTransactionsListItem />
                  )}
                  {current.transactions.map((transaction, index) => (
                    <TransactionListItem
                      key={transaction.id}
                      index={index}
                      transaction={transaction}
                    />
                  ))}
                  {current.hasMoreTransactions && <LoadMoreListItem />}
                </ul>
              )}
            </section>
          </section>
        )}
      </Measure>
      {isMaple ? (
        current.state !== STATE.PICKING_OUTLET &&
        ((!isStaffMode &&
          currentOutlet &&
          currentOutlet.qr_codes.length > 0 &&
          currentOutlet.can_receive_payment) ||
          (isStaffMode &&
            currentOutlet.qr_code_id !== null &&
            currentOutlet.can_receive_payment)) ? (
          <MapleQRFAB outlet={currentOutlet} />
        ) : (
          <></>
        )
      ) : (
        <FloatingActionButton outlet={currentOutlet} />
      )}
    </>
  );

  return (
    <>
      {isStaffMode ? (
        <OpsLayout>
          <TransactionViewContent />
        </OpsLayout>
      ) : (
        <AppLayout>
          <TransactionViewContent />
        </AppLayout>
      )}
    </>
  );
}

export default TransactionsView;
