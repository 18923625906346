import React, { useState, useEffect } from 'react';
import { TIMEOUT_IN_MS } from 'helpers/TypeConstants';
import Notification, { INotificationProps } from 'components/Notification';

/**
 * Notification structure
 * {
 *   message: The message to display
 *   type: The type of notification
 *   wait: duration in milliseconds
 *   autoClose: set to true by default. set false to keep notification showing till user dismiss
 *   cta: optional. to add link in notification
 *   mobileMessage: optional. if need to show shorter message in mobile
 * }
 */

interface INotificationState
  extends Partial<Omit<INotificationProps, 'handleDismiss'>> {
  wait?: number;
  autoClose?: boolean;
}

interface INotificationContext {
  setNotification: (state: INotificationState) => void;
}

const NotificationContext = React.createContext<INotificationContext>({
  setNotification: () => {},
});

const NotificationProvider: React.FC = (props) => {
  const [notification, setNotification] = useState<INotificationState>();
  const { message, type, wait, autoClose = true, cta, mobileMessage } =
    notification || {};
  const implementation = { setNotification };

  useEffect(() => {
    if (!message || !autoClose) return;
    const timeout = setTimeout(
      () => setNotification({ type }),
      wait || TIMEOUT_IN_MS
    );
    return () => clearTimeout(timeout);
  }, [wait, message, type, autoClose]);

  return (
    <NotificationContext.Provider value={implementation}>
      {
        <Notification
          message={message}
          mobileMessage={mobileMessage}
          type={type}
          handleDismiss={() => setNotification({ type })}
          cta={cta}
        />
      }
      {props.children}
    </NotificationContext.Provider>
  );
};

const useNotificationContext = () => React.useContext(NotificationContext);

export { NotificationContext, NotificationProvider, useNotificationContext };
