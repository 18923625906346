import React, { useState, useEffect, useContext } from 'react';

import useHubSpotTrack from 'hooks/useHubSpotTrack';
import LoginLayout from 'layouts/LoginLayout';
import Cookie from 'helpers/Cookie';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { NotificationContext } from 'contexts/NotificationContext';
import verifyOTP from 'commands/verifyOTP';
import requestOTP from 'commands/requestOTP';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'assets/css/VerifyPinView.module.scss';

const VerifyPinView = ({ location }) => {
  const { t } = useTranslation();
  useHubSpotTrack(location.pathname);
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'otp' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  const header = Cookie.get('sign_up_data');
  const { setNotification } = useContext(NotificationContext);
  const [enableResend, setEnableResend] = useState(true);
  const submitForm = (values, formikBag) => {
    const submitFormBody = {
      method: header.method,
      code: values.OTP,
      request_id: header.request_id
    };
    verifyOTP(submitFormBody)
      .then(response => {
        Cookie.set('sign_up_data', {
          ...header,
          ...response
        });
        navigate('/set_pin');
      })
      .catch(error => {
        if ('value' in error) {
          const { value } = error;
          setNotification({ message: value.response.error, type: 'error' });
        } else {
          setNotification({ message: error.toString(), type: 'error' });
        }
      })
      .finally(() => formikBag.setSubmitting(false));
  };

  const resentOTP = () => {
    const submitFormBody = {
      method: header.method,
      value: header.value,
      company_name: header.company_name,
      route: 'sign_up',
      city: header.city
    };
    requestOTP(submitFormBody)
      .then(response => {
        setNotification({ message: t('OTP sent'), type: 'success' });
      })
      .catch(error => {
        if ('value' in error) {
          const { value } = error;
          setNotification({ message: value.response.error, type: 'error' });
        } else {
          setNotification({ message: error.toString(), type: 'error' });
        }
      });
  };
  const initFormData = Cookie.get('sign_up_data');
  const [timeRemaining, setTimeRemaining] = useState(300);
  const ResendTimer = ({ setEnableResend }) => {
    function fmtMSS(s) {
      return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
    }
    useEffect(() => {
      setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      if (timeRemaining === 0) {
        setEnableResend(true);
      }
    }, [setEnableResend]);
    return (
      <div className={styles.resendTimerSection}>
        <div className={styles.timer}>{fmtMSS(timeRemaining)}</div>
        <div className={styles.resendText}>
          <p>{t('If you have not received the SMS,')}</p>
          <p>{t('please press Resend OTP after the time elapses.')}</p>
        </div>
      </div>
    );
  };
  return (
    <LoginLayout
      withFooter={false}
      backButtonProps={{
        to: `/sign-up/form?country=${
          initFormData.city === 'singapore' ? 'sg' : 'my'
        }`
      }}
    >
      <div className={styles.pinViewContainer}>
        <div className={styles.verifyCode}>
          <h3>{t('Verification')}</h3>
          <h5>
            {t('Enter the OTP sent to {{value}}', { value: header.value })}
          </h5>
          <Formik
            initialValues={{
              OTP: ''
            }}
            validationSchema={Yup.object().shape({
              OTP: Yup.string()
                .matches(/^\d+$/, t('Only numbers allowed'))
                .length(6, t(`PIN should be 6 digits long`))
                .required(t('PIN is required'))
            })}
            validateOnBlur={false}
            onSubmit={submitForm}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className={styles.form}>
                <label htmlFor="OTP">
                  <TextInput
                    type="text"
                    name="OTP"
                    maxLength={6}
                    placeholder={t('Enter OTP*')}
                    className={styles.telNumber}
                    focus
                    {...{ errors, touched }}
                  />
                </label>
                <div className={styles.footerSection}>
                  <div className={styles.resendSection}>
                    <h5>{t("Didn't receive your OTP?")}</h5>
                    <Button
                      className={styles.resendButton}
                      disabled={!enableResend}
                      onClick={() => {
                        cleverTap.tapped.push({
                          screen_name: 'otp',
                          tapped_on: 'btn_otp_resend'
                        });
                        resentOTP();
                        setEnableResend(false);
                      }}
                      type={'ghost'}
                    >
                      {t('Resend')}
                    </Button>
                  </div>
                  {!enableResend && (
                    <ResendTimer setEnableResend={setEnableResend} />
                  )}
                  <Button
                    type="submit"
                    size="lg"
                    rightIcon="arrow-right"
                    justify="space-between"
                    className={styles.verifyPinSubmit}
                    onClick={() =>
                      cleverTap.tapped.push({
                        screen_name: 'otp',
                        tapped_on: 'btn_continue'
                      })
                    }
                    disabled={isSubmitting}
                  >
                    {t('Next')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </LoginLayout>
  );
};

export default VerifyPinView;
