import React, { useState, useContext, useCallback, useEffect } from 'react';
import { NotificationContext } from 'contexts/NotificationContext';
import { navigate } from '@reach/router';
import { Formik, Form } from 'formik';

import getRedemptionVoucher from 'queries/getRedemptionVoucher';
import { NATIVE, call, scannerResult } from 'utils/nativeInterface';
import { WebviewContext } from 'contexts/WebviewContext';

import OpsLayout from 'layouts/OpsLayout';
import redeemIcon from 'assets/images/ic-redeem.svg';
import Heading from 'components/Heading';
import TextInput from 'components/TextInput';
import Button, { PrimaryInvertedButton, CloseButton } from 'components/Button';
import Card from 'components/Card';
import GuaranteeFeeLabel from 'components/GuaranteeFeeLabel';
import styles from 'assets/css/VoucherRedemptionView.module.scss';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { claimVoucher } from 'commands/claimVoucher';
import { useTranslation, Trans } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';

const VoucherRedemptionView = () => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [step, setStep] = useState(1);
  const [voucher, setVoucher] = useState();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const { currentOutlet } = useContext(OpsOutletContext);
  const { isWebview } = useContext(WebviewContext);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'redeem' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  const getDetailsURL = useCallback(
    voucherCode =>
      `/operations/redemption/${voucherCode}?outlet=${currentOutlet.id}`,
    [currentOutlet]
  );

  const VerifyVoucher = ({ setVoucher, setStep }) => {
    const { setNotification } = useContext(NotificationContext);
    const [voucherCode, setVoucherCode] = useState('');
    window.scannerResult = scannerResult(result =>
      setVoucherCode(result.toUpperCase())
    );

    const handleScannerOpen = useCallback(() => {
      cleverTap.tapped.push({
        screen_name: 'redeem',
        tapped_on: 'btn_scan_qrcode'
      });
      return isWebview ? call(NATIVE.LAUNCH_REDEMPTION_SCANNER) : undefined;
    }, []);

    const onVerifyVoucher = useCallback(() => {
      if (voucherCode.length < 8)
        return setNotification({
          message: t('Code length too small'),
          type: 'error'
        });
      cleverTap.tapped.push({
        screen_name: 'redeem',
        tapped_on: 'btn_verify_voucher'
      });
      getRedemptionVoucher({ code: voucherCode }).then(
        ({ error_message, redemptions }) => {
          if (error_message)
            return setNotification({ message: error_message, type: 'error' });
          if (redemptions.has_redeemed === true) {
            navigate(getDetailsURL(redemptions.code));
            setNotification({ message: t('Redeemed already'), type: 'error' });
          } else {
            setVoucher(redemptions);
            setStep(2);
          }
        }
      );
    }, [setNotification, setStep, setVoucher, voucherCode]);

    return (
      <>
        <Button
          leftIcon="arrow-left"
          href={'/operations/transactions'}
          type="text"
          children={t('Back to transactions')}
        />
        <div className={styles.redemptionContainer}>
          <Card className={styles.redemptionCard}>
            <div className={styles.redemptionCardContainer}>
              <div className={styles.redemptionIconSection}>
                <img
                  src={redeemIcon}
                  alt="qr"
                  className={styles.redemptionIcon}
                />
                <h4>{t('Voucher')}</h4>
              </div>
              <div className={styles.redemptionButtomSection}>
                <h5>{t('Enter your customer’s voucher code')}</h5>
              </div>
              {isWebview && voucherCode.length === 0 && (
                <>
                  <PrimaryInvertedButton
                    onClick={handleScannerOpen}
                    leftIcon="scan"
                    iconColor="apple-green"
                    className={styles.scanButton}
                    size="lg"
                    justify="center"
                    children={t('Scan QR or Bar code')}
                  />
                  <p className={styles.webviewText}>
                    <Trans>
                      or <span>Manually enter your voucher code</span>
                    </Trans>
                  </p>
                </>
              )}
            </div>
            <Formik initialValues={{}} onSubmit={() => {}}>
              {() => (
                <Form>
                  <TextInput
                    id="redemptionInput"
                    name="redemptionInput"
                    type="text"
                    value={voucherCode}
                    onChange={e => setVoucherCode(e.target.value.toUpperCase())}
                    className={styles.redemptionInput}
                    placeholder="eg. AG-AB82149"
                  />
                  {voucherCode.length !== 0 && (
                    <CloseButton onClick={() => setVoucherCode('')} />
                  )}
                </Form>
              )}
            </Formik>
          </Card>
          <Button
            disabled={voucherCode.length === 0}
            type="primary"
            onClick={onVerifyVoucher}
            className={styles.redemptionButton}
            children={t('Verify Voucher')}
          />
        </div>
      </>
    );
  };

  const VoucherDetails = ({ voucher: { code }, setStep }) => {
    const { setNotification } = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(false);
    const onRedeemVoucher = useCallback(() => {
      setIsLoading(true);
      claimVoucher({ outlet_id: currentOutlet.id, redemptions: [{ code }] })
        .then(({ results: [result] }) =>
          result.error_message
            ? setNotification({ message: result.error_message, type: 'error' })
            : navigate(getDetailsURL(result.redemption_code))
        )
        .finally(() => setIsLoading(false));
    }, [setNotification, code]);

    return (
      <>
        <Button
          leftIcon="arrow-left"
          onClick={() => setStep(1)}
          type="text"
          children={t('Back to Verify')}
        />
        <div className={styles.voucherDetailContainer}>
          <Card className={styles.voucherDetailCard}>
            <div className={styles.detailContainer}>
              <div className={styles.detailHeader}>
                <div className={styles.voucherImage}>
                  <img src={voucher.offer_thumbnail_image_url} alt="Voucher" />
                </div>
                <div className={styles.voucherHeader}>
                  <div className={styles.title}>{voucher.activity_name}</div>
                  <div className={styles.priceSection}>
                    <div className={styles.realPrice}>
                      {voucher.original_price}
                    </div>
                    <div className={styles.discountedPrice}>
                      {voucher.discounted_price}
                    </div>
                  </div>
                  <GuaranteeFeeLabel
                    guarunteeFee={voucher.guarantee_fee_percentage}
                    isTransactionCard={false}
                  />
                </div>
              </div>
              <div className={styles.detailDescription}>
                <div className={styles.detailDescriptionLeft}>
                  <div className={styles.detailContent}>
                    <div className={styles.detailContentTitle}>
                      {t('Purchased by')}
                    </div>
                    <div className={styles.detailContentContent}>
                      {voucher.name}
                    </div>
                  </div>
                  <div className={styles.detailContent}>
                    <div className={styles.detailContentTitle}>
                      {t('Voucher ID')}
                    </div>
                    <div className={styles.detailContentContent}>
                      {voucher.id}
                    </div>
                  </div>
                  <div className={styles.detailContent}>
                    <div className={styles.detailContentTitle}>
                      {t('Quantity')}
                    </div>
                    <div className={styles.detailContentContent}>
                      {voucher.reservations_count}
                    </div>
                  </div>
                </div>
                <div className={styles.detailDescriptionRight}>
                  <div className={styles.detailContent}>
                    <div className={styles.detailContentTitle}>
                      {t('Valid from')}
                    </div>
                    <div className={styles.detailContentContent}>
                      {`${voucher.redeemable_from
                        .split(' ')
                        .splice(0, 2)
                        .join(' ')} - ${voucher.redeemable_to}`}
                    </div>
                  </div>
                  <div className={styles.detailContent}>
                    <div className={styles.detailContentTitle}>
                      {t('Location')}
                    </div>
                    <div className={styles.detailContentContent}>
                      {currentOutlet.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Button
            type="primary"
            isLoading={isLoading}
            onClick={onRedeemVoucher}
            className={styles.redemptionButton}
            children={t('Redeem Voucher')}
          />
        </div>
      </>
    );
  };

  return (
    <OpsLayout>
      {!isWebview && (
        <Heading
          title={t('Redeem Voucher')}
          subtitle={t('Redeem your customer voucher here')}
        />
      )}
      {step === 1 && <VerifyVoucher {...{ setStep, setVoucher }} />}
      {step === 2 && <VoucherDetails {...{ setStep, voucher }} />}
    </OpsLayout>
  );
};

export default VoucherRedemptionView;
