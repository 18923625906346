import React, { useCallback, useContext, useEffect, useState } from 'react';
import style from 'assets/css/NewPostView.module.scss';
import modalStyle from 'assets/css/ModalContainer.module.scss';
import AppLayout from 'layouts/AppLayout';
import { Trans, useTranslation } from 'react-i18next';
import Heading from 'components/Heading';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import Button from 'components/Button';
import BannerMessage from 'components/BannerMessage';
import useOnceCallback from 'hooks/useOnceCallback';
import useCleverTapOld from 'hooks/useCleverTapOld';
import InlineLink from 'components/InlineLink';
import ic_announcement from 'assets/images/FaveFeed/announcement-post.svg';
import ic_featured from 'assets/images/FaveFeed/featured-post.svg';
import { ReactComponent as LockedPostTypeSVG } from 'assets/images/FaveFeed/locked-post-type.svg';
import { ReactComponent as InfoIconSVG } from 'assets/images/FaveFeed/info-icon.svg';
import { ReactComponent as FeatureDisabledSVG } from 'assets/images/FaveFeed/fave-feed-feature-locked.svg';

import {
  CLEVERTAP_ADDITIONAL,
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
  SELF_ONBOARDING_STATUS
} from 'helpers/TypeConstants';
import { WebviewContext } from 'contexts/WebviewContext';
import { call, NATIVE } from 'utils/nativeInterface';
import WebviewHeading from 'components/WebviewHeading';
import { OutletContext } from 'contexts/OutletContext';
import { OutletType } from 'types/SettingsAPIResponseType';
import ModalContainer from 'components/common/ModalContainer';
import useModal from 'hooks/useModal';
import { getBackgroundImageFromUrl } from 'utils/utilFunctions';
import routesMap from 'helpers/routesMap';
import useChat from 'hooks/useChat';
import { capitalize } from 'lodash';

type PostType = 'Featured' | 'Announcement';
type ServiceType = 'Deal' | 'eCard';

const NewPostView: React.FC = () => {
  const { t } = useTranslation();
  const [postType, setPostType] = useState<PostType | ''>('');
  const [
    showAnnouncementLockedModal,
    setShowAnnouncementLockedModal
  ] = useState(false);
  const [showFeatureLockedModal, setShowFeatureLockedModal] = useState(false);
  const cleverTap = useCleverTapOld();
  const { isWebview } = useContext(WebviewContext);
  const { currentCompany, currentOutlet, outlets } = useContext(OutletContext);
  const { chatText, openWidget } = useChat();

  const chatPhrase = capitalize(chatText);

  // unlocked based on fulfilled requirements
  const isAnnouncementPostUnlocked =
    outlets && outlets.some((outlet: OutletType) => outlet.has_fave_payment);
  const isFeaturedPostUnlocked =
    currentCompany && (currentCompany.has_deals || currentCompany.has_e_cards);

  // enabled / disabled from admin tool side
  const isAnnouncementPostEnabled =
    currentCompany && currentCompany.announcement_feed_enabled;
  const isFeaturedPostEnabled =
    currentCompany && currentCompany.feature_feed_enabled;

  // auto select post type if the other one is disabled
  useEffect(() => {
    if (currentCompany) {
      if (!isAnnouncementPostEnabled || !isAnnouncementPostUnlocked) {
        setPostType('Featured');
      } else if (!isFeaturedPostEnabled || !isFeaturedPostUnlocked) {
        setPostType('Announcement');
      }
    }
  }, [
    currentCompany,
    isAnnouncementPostEnabled,
    isAnnouncementPostUnlocked,
    isFeaturedPostEnabled,
    isFeaturedPostUnlocked
  ]);

  const onClickFAQ = () => {
    if (isWebview) {
      call(NATIVE.FAVEFEED_FAQ);
    } else {
      return window.open(routesMap.faveFeedFAQ);
    }
  };

  const onClickContactUs = () => {
    if (isWebview) {
      call(NATIVE.OPEN_HELP);
    } else {
      return;
    }
  };

  const onClickCreatePost = () => {
    if (postType === 'Announcement') {
      if (isAnnouncementPostEnabled) {
        navigate('/feed/create');
      } else {
        setShowAnnouncementLockedModal(true);
      }
    } else if (postType === 'Featured') {
      if (isFeaturedPostEnabled) {
        navigate('/feed/create/deals');
      } else {
        setShowFeatureLockedModal(true);
      }
    }
  };

  useOnceCallback(() =>
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW
    })
  );

  const isSelfOnboardingStatusEqual = useCallback(
    status => {
      return currentOutlet === ''
        ? (outlets || []).some(
            (data: OutletType) => data.self_onboarding_status === status
          )
        : (currentOutlet || {}).self_onboarding_status === status;
    },
    [outlets, currentOutlet]
  );

  const PostTypeBlock = ({
    type,
    description,
    isLocked = false
  }: {
    type: PostType;
    description: string;
    isLocked?: boolean;
  }) => {
    const isAnnouncement = type === 'Announcement';

    const handlePostTypeClick = useCallback(() => {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW,
        tapped_on: isAnnouncement
          ? CLEVERTAP_TAPPED_ON.BUTTON_ANNOUNCEMENT_POST
          : CLEVERTAP_TAPPED_ON.BUTTON_FEATURED_POST
      });

      setPostType(type);
    }, [type, isAnnouncement]);

    // when user chose the post type A, the other unselected post type B will grey out
    const isPostTypeNotSelected =
      !isLocked && postType !== '' && postType !== type;
    // to prevent flickering of box shadows
    const isPostTypeSelected =
      !isLocked && postType !== '' && postType === type;
    const image = type === 'Featured' ? ic_featured : ic_announcement;

    return (
      <div
        className={classNames(
          style.postType,
          !isLocked && style.unlockedPostTypeOnHover,
          isPostTypeNotSelected && style.unselectedPostTypeFade,
          isPostTypeSelected && style.selectedPostTypeBoxShadow
        )}
        onClick={!isLocked ? handlePostTypeClick : undefined}
      >
        <div className={isLocked ? style.postLocked : style.postImage}>
          <div className={style.postBackground}>
            {isLocked && (
              <>
                <div className={style.blurFilter} />
                <div className={style.comingSoon}>
                  <div className={style.infoIcon}>
                    <Button
                      type={'ghost'}
                      onClick={() => {
                        isAnnouncement
                          ? setShowAnnouncementLockedModal(true)
                          : setShowFeatureLockedModal(true);
                      }}
                    >
                      <InfoIconSVG />
                    </Button>
                  </div>
                  <LockedPostTypeSVG />
                  <Trans>
                    {type === 'Announcement' ? (
                      <p>
                        {isAnnouncementPostEnabled
                          ? t('Unlock by activating FavePay')
                          : t(`${chatPhrase} to unlock announcement post`)}
                      </p>
                    ) : (
                      <p>
                        {isFeaturedPostEnabled
                          ? t('Unlock by activating FaveDeals & eCards')
                          : t(`${chatPhrase} to unlock featured post`)}
                      </p>
                    )}
                  </Trans>
                </div>
              </>
            )}
            <div
              className={style.postIcon}
              style={{
                background: getBackgroundImageFromUrl(
                  image,
                  undefined,
                  'bottom'
                )
              }}
            ></div>
          </div>
        </div>
        <div
          className={classNames(
            style.postContent,
            isLocked && style.postContentLocked
          )}
        >
          <div>
            <label className={style.container}>
              <div className={style.postTitleAndBanner}>
                <div className={style.title}>
                  {t(`${type} Post`)}
                  <div
                    className={classNames(
                      style.banner,
                      type === 'Announcement' && style.hide
                    )}
                  >
                    Deals / eCards
                  </div>
                </div>
              </div>

              {!isLocked && (
                <>
                  {/* checked value is handled by main div of this component */}
                  <input
                    onChange={() => {}}
                    type="radio"
                    name="radio"
                    checked={type === postType}
                  />
                  <span className={style.checkmark}></span>
                </>
              )}
              <p className={style.description}>{description}</p>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const FeatureLockedModal = ({ onClose }: { onClose: () => void }) => {
    const [serviceType, setServiceType] = useState<ServiceType | ''>('');
    const [isDisabled, setIsDisabled] = useState(true);

    const onClickContinue = () => {
      onClose();
      if (!isFeaturedPostEnabled) {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CHAT_US,
          locked_type: CLEVERTAP_ADDITIONAL.ADMIN_LOCKED_FEATURED
        });
        if (isWebview) call(NATIVE.OPEN_HELP);
        else openWidget();
      } else if (isFeaturedPostEnabled && !isFeaturedPostUnlocked) {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          tapped_on:
            serviceType === 'Deal'
              ? CLEVERTAP_TAPPED_ON.BUTTON_SETUP_DEALS
              : CLEVERTAP_TAPPED_ON.BUTTON_CREATE_NEW_ECARD,
          locked_type: CLEVERTAP_ADDITIONAL.NO_OFFERS
        });
        if (isWebview) {
          if (serviceType === 'eCard') {
            call(NATIVE.ECARDS_EDUCATION_PAGE);
          } else if (serviceType === 'Deal') {
            call(NATIVE.LAUNCH_DEALS_INFO_PAGE);
          }
        } else {
          navigate('/more-services');
        }
      }
    };

    const onClickServiceType = (selectedService: ServiceType) => {
      setServiceType(selectedService);
      setIsDisabled(false);
    };

    const isServiceTypeDeal = serviceType === 'Deal';
    const isServiceTypeECard = serviceType === 'eCard';

    return (
      <ModalContainer className={style.featureLockedModal}>
        {!isFeaturedPostEnabled && <FeatureDisabledSVG />}
        <p className={modalStyle.title}>{t('This feature is locked')}</p>
        <p className={modalStyle.description}>
          {!isFeaturedPostEnabled
            ? t(`${chatPhrase} to learn how to unlock the Featured Post again.`)
            : t('Create a Deal/eCard to unlock this feature post.')}
        </p>

        {isFeaturedPostEnabled && (
          <div className={style.serviceContainer}>
            <label
              className={classNames(
                style.serviceType,
                isServiceTypeDeal && style.isSelectedServiceType
              )}
            >
              I want to create a Deal
              <input
                onChange={() => onClickServiceType('Deal')}
                type="radio"
                name="serviceTypeRadio"
                checked={serviceType === 'Deal'}
              />
              <span className={style.checkmark}></span>
            </label>
            <label
              className={classNames(
                style.serviceType,
                isServiceTypeECard && style.isSelectedServiceType
              )}
            >
              I want to create an eCard
              <input
                onChange={() => onClickServiceType('eCard')}
                type="radio"
                name="serviceTypeRadio"
                checked={serviceType === 'eCard'}
              />
              <span className={style.checkmark}></span>
            </label>
          </div>
        )}
        <div>
          <Button
            type={'primary'}
            disabled={
              isFeaturedPostEnabled ? isDisabled : isFeaturedPostEnabled
            }
            size={'lg'}
            isWebview={true}
            onClick={onClickContinue}
          >
            {!isFeaturedPostEnabled ? t(chatText) : t('Continue')}
          </Button>
        </div>
      </ModalContainer>
    );
  };

  const AnnouncementLockedModal = ({ onClose }: { onClose: () => void }) => {
    const shouldShowFinishPaymentSetup = isSelfOnboardingStatusEqual(
      SELF_ONBOARDING_STATUS.LINK_BANK_ACCOUNT
    );
    const shouldShowSarterKitBanner = isSelfOnboardingStatusEqual(
      SELF_ONBOARDING_STATUS.STARTER_KIT
    );
    const onClick = () => {
      onClose();
      if (!isAnnouncementPostEnabled) {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CHAT_US,
          locked_type: CLEVERTAP_ADDITIONAL.ADMIN_LOCKED_ANNOUNCEMENT
        });
        if (isWebview) call(NATIVE.OPEN_HELP);
        else openWidget();
      } else if (isAnnouncementPostEnabled && !isAnnouncementPostUnlocked) {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ACTIVATE_FAVEPAY,
          locked_type: CLEVERTAP_ADDITIONAL.NO_FAVEPAY
        });
        if (isWebview) {
          call(NATIVE.FAVEPAY_VIEW_EDUCATION);
        } else {
          if (shouldShowFinishPaymentSetup) {
            navigate('/favepay/setup?step=2');
          } else if (shouldShowSarterKitBanner) {
            navigate('/favepay/setup?step=3');
          } else {
            navigate('/more-services');
          }
        }
      }
    };
    return (
      <ModalContainer>
        {!isAnnouncementPostEnabled && <FeatureDisabledSVG />}
        <p className={modalStyle.title}>{t('This feature is locked')}</p>
        <p className={modalStyle.description}>
          {!isAnnouncementPostEnabled
            ? t(
                `${chatPhrase} to learn how to unlock the Announcement Post again.`
              )
            : t('Please activate FavePay to start creating a feed post.')}
        </p>
        <div>
          <Button type={'primary'} size={'lg'} onClick={onClick}>
            {!isAnnouncementPostEnabled ? t(chatPhrase) : t('Activate FavePay')}
          </Button>
        </div>
      </ModalContainer>
    );
  };

  useModal({
    Component: FeatureLockedModal,
    openOnReady: showFeatureLockedModal,
    onClose: () => setShowFeatureLockedModal(false),
    openCallback: () => {
      if (!isFeaturedPostEnabled) {
        cleverTap.screenDisplayed.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          locked_type: CLEVERTAP_ADDITIONAL.ADMIN_LOCKED_FEATURED
        });
      } else if (isFeaturedPostEnabled && !isFeaturedPostUnlocked) {
        cleverTap.screenDisplayed.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          locked_type: CLEVERTAP_ADDITIONAL.NO_OFFERS
        });
      }
    }
  });

  useModal({
    Component: AnnouncementLockedModal,
    openOnReady: showAnnouncementLockedModal,
    onClose: () => setShowAnnouncementLockedModal(false),
    openCallback: () => {
      if (!isAnnouncementPostEnabled) {
        cleverTap.screenDisplayed.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          locked_type: CLEVERTAP_ADDITIONAL.ADMIN_LOCKED_ANNOUNCEMENT
        });
      } else if (isAnnouncementPostEnabled && !isAnnouncementPostUnlocked) {
        cleverTap.screenDisplayed.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          locked_type: CLEVERTAP_ADDITIONAL.NO_FAVEPAY
        });
      }
    }
  });

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading
          onBack={() => navigate('/feed')}
          title={t('New Post')}
          subtitle={t('Don’t worry, we’ll guide you through step-by-step')}
        />
      ) : (
        <Heading
          title={t('New Post')}
          subtitle={t('Don’t worry, we’ll guide you through step-by-step')}
        />
      )}
      {!isWebview && (
        <Button
          leftIcon="arrow-left"
          onClick={() => navigate(`/feed`)}
          type="text"
          children={t('Back')}
          iconColor={'nobel'}
          textColor={'charcoal-gray'}
        />
      )}
      <BannerMessage
        message={t(
          'You are only allowed to post one announcement post per day for each of your outlet'
        )}
      />
      <div className={style.newPostContainer}>
        <PostTypeBlock
          type={'Featured'}
          description={t(
            'Increase your conversion by promoting your deals/eCards.'
          )}
          isLocked={!isFeaturedPostEnabled || !isFeaturedPostUnlocked}
        />
        <PostTypeBlock
          type={'Announcement'}
          description={t('Drive traffic to your store & shoutout What’s New.')}
          isLocked={!isAnnouncementPostEnabled || !isAnnouncementPostUnlocked}
        />
      </div>
      <Trans>
        {'Read this '}
        <InlineLink
          children={'FAQ'}
          className={style.inlineLink}
          onClick={onClickFAQ}
          event={{
            screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_FAQ
          }}
        />
        {' before creating your first feed post or '}
        <InlineLink
          to={isWebview ? '' : '/contact-us'}
          onClick={onClickContactUs}
          children={'contact us.'}
          className={style.inlineLink}
          event={{
            screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_HELP_CONTACT
          }}
        />
      </Trans>
      <div className={style.nextStepContainer}>
        <div
          className={classNames(style.container, isWebview && style.fullWidth)}
        >
          <Button
            onClick={onClickCreatePost}
            type={'primary'}
            size={'lg'}
            className={style.nextStepButton}
            isWebview={isWebview}
            fullWidth={true}
            disabled={postType === ''}
          >
            {t('Continue')}
          </Button>
        </div>
      </div>
    </AppLayout>
  );
};

export default NewPostView;
