import classnames from 'classnames';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../assets/css/TransactionCancelationModal.module.scss';
import Button, {TappedButton } from './Button';
import Icon from './Icon';
import TextInput from './TextInput';

type CancellationModalProps = {
  receiptId: number;
  totalBill: string;
  paidBy: string;
  isLoading: boolean;
  requireCancelationCode: boolean;
  confirmCancelCleverTap: () => void;
  proceedButtonCleverTap:() => void;
  handleCancel: (
    cancellation_code: object,
    onSuccess?: () => void,
    finishSubmit?: () => void
  ) => void;
  onClose: () => void;
};

const TransactionCancelationModal = ({
  receiptId,
  totalBill,
  requireCancelationCode,
  paidBy,    
  handleCancel,
  onClose,
  confirmCancelCleverTap,
  proceedButtonCleverTap,
}: CancellationModalProps) => {
  const { t } = useTranslation();
  const [showCancelationCodePrompt, setShowCancelationCodePrompt] = useState<
    boolean
  >(false);
  const [slideFromRight, setSlideFromRight] = useState<boolean>(false);
  const [isLoading,setLoading] = useState<boolean>(false);

  const showCancelationCode = () => {    
    setShowCancelationCodePrompt(true);    
    setSlideFromRight(true);
  };

  const ConfirmPrompt = () => {
    return (
      <div
        className={
          slideFromRight
            ? classnames(Styles.confirmPrompt, Styles.slidesLeft)
            : Styles.confirmPrompt
        }
      >
        <div className={Styles.alertIcon}></div>
        <div className={Styles.title}>{t('You are about to cancel')}</div>
        <dl>
          <dt> {t('Receipt ID')}</dt>
          <dd>{receiptId}</dd>
          <dt> {t('Total bill')}</dt>
          <dd>{totalBill}</dd>
          <dt>{t('Paid by')}</dt>
          <dd>{paidBy}</dd>
        </dl>
        <div className={Styles.buttonDiv}>
          <TappedButton
            size="lg"
            justify="center"
            type="primary"
            children={t('Confirm')}
            isLoading={isLoading}
            onClick={() => {       
              setLoading(!isLoading);                     
              confirmCancelCleverTap();
              if (requireCancelationCode) showCancelationCode();
              else handleCancel({},onClose);
            }}
            fullWidth
          />
          <br />
          <TappedButton
            type="default"
            isRounded
            justify="center"
            fullWidth
            onClick={onClose}
            children={t('No')}
            size="lg"
          />
        </div>
      </div>
    );
  };

  const CancelationCodePrompt = () => {
    return (
      <div className={Styles.cancelationCodePrompt}>
        <Button type="text" onClick={() => {
          setLoading(!isLoading)
          setShowCancelationCodePrompt(false)
          }}>
          <Icon icon="angle-left" color="grayish-blue" size="1.5rem" />
        </Button>
        <div className={Styles.mainContent}>
          <p className={Styles.title}>
            {t('Please key in your cancellation code to proceed')}
          </p>
          <Formik
            initialValues={{ cancellation_code: '' }}
            onSubmit={(code, formikBag) => {
              handleCancel(code, onClose, () => formikBag.setSubmitting(false));
            }}
            validationSchema={Yup.object().shape({
              cancellation_code: Yup.string().required(t('Required'))
            })}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <TextInput
                  className={Styles.textField}
                  type="text"
                  {...{ errors, touched }}
                  name="cancellation_code"
                  placeholder={t('Cancellation Code')}
                />
                <Button
                  className={Styles.buttons}
                  isLoading={isSubmitting}
                  type="submit"
                  size="lg"
                  fullWidth
                  justify="center"
                  children={t('Proceed')}
                  onClick={proceedButtonCleverTap}
                />
                <TappedButton
                  className={Styles.buttons}
                  onClick={onClose}
                  justify="center"
                  fullWidth
                  size="lg"
                  isRounded
                  children={t('Dismiss')}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.mainBody}>
      {showCancelationCodePrompt ? (
        <CancelationCodePrompt />
      ) : (
        <ConfirmPrompt />
      )}
    </div>
  );
};

export default TransactionCancelationModal;
