import React, { useContext, useState, useEffect } from 'react';

import Heading from 'components/Heading';
import Grid from 'components/Grid';
import LoadingSpinner from 'components/LoadingSpinner';
import ToggleFilter from 'components/common/ToggleFilter';
import OutletCard from 'components/OutletCard';
import { useTranslation } from 'react-i18next';

import AppLayout from 'layouts/AppLayout';
import { OutletContext } from 'contexts/OutletContext';
import useCleverTapOld from 'hooks/useCleverTapOld';
import EmptyOutletListStrip from 'components/EmptyOutletListStrip';
import { navigate } from '@reach/router';
import { NATIVE, call } from 'utils/nativeInterface';
import { WebviewContext } from 'contexts/WebviewContext';
import { MapleContext } from 'contexts/MapleContext';
import MissingOutletImgStrip from 'components/MissingOutletImgTip';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';

const Outlets = ({ outlets }) => {
  const cleverTap = useCleverTapOld();
  const { isWebview } = useContext(WebviewContext);

  const onClickViewOutletInfo = outletID => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.OUTLET,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_OUTLET_VIEW_INFO
    });

    if (isWebview) {
      call(NATIVE.FAVEPAY_VIEW_OUTLET_INFO, outletID);
    }
    navigate(`/outlets/${outletID}?from=outlets`);
  };

  const onReceivePaymentClick = outletID => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.OUTLET,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_OUTLET_RECEIVE_PAYMENT
    });
    navigate(`/payment/${outletID}?from=/outlets`);
  };

  return (
    <Grid>
      {outlets.map(outlet => (
        <OutletCard
          key={outlet.id}
          outlet={outlet}
          onReceivePaymentClick={() => onReceivePaymentClick(outlet.id)}
          onViewInfoClick={() => onClickViewOutletInfo(outlet.id)}
        />
      ))}
    </Grid>
  );
};

const OutletsView = () => {
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const [showActiveOutlets, setShowActiveOutlets] = useState(true);

  const { outlets, currentOutlet } = useContext(OutletContext);
  const isLoading = !Array.isArray(outlets);
  const hasOutlets = !isLoading && outlets.length !== 0;
  const isAllOutlets = currentOutlet === '';

  useEffect(() => {
    if (sentCTEvent || !hasOutlets) return;
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.OUTLET
    });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent, hasOutlets]);

  const outletList = (isAllOutlets
    ? outlets
    : currentOutlet
    ? [currentOutlet]
    : []
  ).filter(outlet => outlet.has_fave_payment === showActiveOutlets);

  const hasOutletsWithMissingImages = outletList.some(
    outlet => !outlet.upload_image_urls || outlet.upload_image_urls.length === 0
  );

  return (
    <AppLayout>
      <Heading
        title={t('Your Outlets')}
        subtitle={t('Your Business Information')}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : hasOutlets ? (
        <>
          <ToggleFilter
            value={showActiveOutlets}
            onChange={setShowActiveOutlets}
            activeButtonProps={{
              children: t('Active'),
              event: {
                screen_name: CLEVERTAP_SCREEN_NAME.OUTLET,
                section_name: CLEVERTAP_SECTION_NAME.FILTER_TABS,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ACTIVE
              }
            }}
            inactiveButtonProps={{
              children: t('Inactive'),
              event: {
                screen_name: CLEVERTAP_SCREEN_NAME.OUTLET,
                section_name: CLEVERTAP_SECTION_NAME.FILTER_TABS,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_INACTIVE
              }
            }}
          />

          {!isMaple && hasOutletsWithMissingImages && (
            <MissingOutletImgStrip screenName={CLEVERTAP_SCREEN_NAME.OUTLET} />
          )}

          {outletList.length > 0 ? (
            <Outlets outlets={outletList} />
          ) : (
            <EmptyOutletListStrip />
          )}
        </>
      ) : (
        <EmptyOutletListStrip />
      )}
    </AppLayout>
  );
};

export default OutletsView;
