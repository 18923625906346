import React, { useState } from 'react';
import style from 'assets/css/BulkActionsButton.module.scss';
import classNames from 'classnames';
import OutOfBoundClick from 'components/OutOfBoundClick';
import Button, { IButtonSizeTypes } from './Button';

type ButtonProps = {
  text: string;
  onClick: () => void;
};

export type BulkActionsButtonProps = {
  className?: string;
  mainText?: string;
  buttonProps: ButtonProps[];
  onClickProps: () => void;
  size?: IButtonSizeTypes;
};

const BulkActionsButton: React.FC<BulkActionsButtonProps> = props => {
  const [showBulk, setShowBulk] = useState(false);
  const {
    className,
    mainText = '\u00b7\u00b7\u00b7',
    buttonProps,
    onClickProps,
    size = 'md'
  } = props;

  const buttonOnClick = (action: () => void) => {
    setShowBulk(false);
    action();
  };

  const mainButtonOnClick = () => {
    if (onClickProps) {
      onClickProps();
    }
    setShowBulk(!showBulk);
  };

  return (
    <div className={classNames(style.bulkActionsContainer, className)}>
      <Button
        type={'primary'}
        onClick={mainButtonOnClick}
        size={size}
        textBold={true}
      >
        {mainText}
      </Button>
      <OutOfBoundClick onClick={() => setShowBulk(false)}>
        <div
          className={classNames(style.bulkActions, showBulk && style.isOpen)}
        >
          {buttonProps.map((button, index) => {
            const isLastButton = index === buttonProps.length - 1;
            const isFirstButton = index === 0;
            return (
              <React.Fragment key={index}>
                <Button
                  className={classNames(
                    style.actionButton,
                    isFirstButton && style.curvedTopBorder,
                    isLastButton && style.curvedBottomBorder
                  )}
                  onClick={() => buttonOnClick(button.onClick)}
                  fullWidth={true}
                  size={size}
                >
                  {button.text}
                </Button>
                {!isLastButton && <hr />}
              </React.Fragment>
            );
          })}
        </div>
      </OutOfBoundClick>
    </div>
  );
};

export default BulkActionsButton;
