import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import styles from 'assets/css/Select.module.scss';
import FormikError, { FormikErrorProps } from 'components/FormikError';

type SelectProps = {
  items: {
    text: string;
    code: string;
  }[];
  className: string;
  placeholder?: string;
} & Partial<FormikErrorProps>;

/*
  To match design, wrapper contains select and custom dropdown arrow icon.
  Default arrow icon hidden in scss
 */
const Select: React.FC<SelectProps> = ({
  name,
  items,
  errors,
  touched,
  className,
  placeholder
}) => (
  <section className={classNames(styles.selectWrapper, className)}>
    <Field
      component="select"
      name={name}
      className={styles.select}
      /*
        if focused and submit clicked, nothing happens,
        needs another click. override onBlur to solve it
      */
      onBlur={() => {}}
    >
      {placeholder && (
        <option key='placeholder' value='placeholder'>{placeholder}</option>)
      }
      {(items || []).map(item => (
        <option key={item.code} value={item.code}>
          {item.text}
        </option>
      ))}
    </Field>
    {name && errors && touched && (
      <FormikError {...{ name, errors, touched }} />
    )}
  </section>
);

export default Select;
