import React, { useState, useCallback, useEffect, useContext } from 'react';
import classNames from 'classnames';

import { hasData } from 'insights/utils';
import useInsightsParams from 'insights/hooks/useInsightsParams';
import useGetSettings from 'insights/queries/useGetSettings';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { InsightsAuthContext } from 'insights/contexts/InsightsAuthContext';
import StickyHeader from 'insights/components/StickyHeader';
import Nav from 'insights/components/Nav';
import Footer from 'insights/components/Footer';
import EmailFader from 'insights/components/EmailFader';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'insights/assets/css/InsightsApp.module.scss';
import Cards from './Cards';

const InsightsApp = ({ location }) => {
  const { email: hasEmailHeader } = useContext(QueryStringContext);
  const { settings, setSettings } = useContext(InsightsAuthContext);
  const { companyId, outletId } = useInsightsParams();
  const response = useGetSettings();
  const CleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    if (!hasData(response.data)) return;
    setSettings({ ...response.data.settings, outletId });
  }, [response.data, setSettings, outletId]);

  useEffect(() => {
    if (sentCTEvent || Object.keys(settings).length === 0) return;
    CleverTap.screenDisplayed.push({ screen_name: 'insight' });
    setSentCTEvent(true);
  }, [CleverTap.screenDisplayed, sentCTEvent, settings]);

  const { features = {}, name: companyName } = settings || {};

  const [mobileScrollMarginTop, setMobileScrollMarginTop] = useState('8rem');
  const onCloseAlert = useCallback(() => setMobileScrollMarginTop('5rem'), []);

  return (
    <div
      style={{ '--mobile-scroll-margin-top': mobileScrollMarginTop }}
      className={styles.layout}
    >
      {!hasEmailHeader && (
        <StickyHeader
          {...{ location, onClose: onCloseAlert, hasEmailHeader, companyName }}
        />
      )}
      {!hasEmailHeader && <Nav {...{ features, mobileScrollMarginTop }} />}
      <main
        className={classNames(
          styles.insightsMain,
          hasEmailHeader && styles.emailHeader
        )}
      >
        <div id="favebiz-insights" className={styles.insightsTitle}>
          <h1>Favebiz Insights</h1>
          <p>
            We have prepared you a holistic view of your business performance
            with Favebiz
          </p>
        </div>
        <Cards {...{ features, companyId }} />
      </main>
      <Footer />
      {hasEmailHeader && <EmailFader {...settings} />}
    </div>
  );
};

export default InsightsApp;
