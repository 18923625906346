import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import getECardsRevenue from 'queries/getECardsRevenue';
import { OutletContext } from 'contexts/OutletContext';
import { NATIVE, call } from 'utils/nativeInterface';
import { WebviewContext } from 'contexts/WebviewContext';
import { AuthContext } from 'contexts/AuthContext';

import useCleverTapOld from 'hooks/useCleverTapOld';
import useECardModal from 'hooks/useECardModal';
import Cookie from 'helpers/Cookie';
import { hasKeys } from 'utils';

import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import ECardWithDetails from 'components/ECardWithDetails';
import eCardsEmpty from 'assets/images/ecards/eCards_empty.svg';
import WebviewHeading from 'components/WebviewHeading';

import styles from 'assets/css/ECardsView.module.scss';

const ECardsView = () => {
  const { t } = useTranslation();
  const { currentCompany } = useContext(OutletContext);
  const { isWebview } = useContext(WebviewContext);
  const { user, isValidUser } = useContext(AuthContext);

  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'ecard_explore' });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed]);

  const [eCardsActiveArray, setECardsActiveArray] = useState([]);
  const [eCardsPendingArray, setECardsPendingArray] = useState([]);
  const response = getECardsRevenue({
    company_id: currentCompany && currentCompany.id
  });

  const { logo_url: outletImage, partner_name: companyName } =
    currentCompany || {};
  const currency = isValidUser && user.city.currency_display;

  useEffect(() => {
    if (!response.data) return;
    const { e_cards: eCards } = response.data;
    setECardsActiveArray(eCards.filter(data => data.status === 'active'));
    setECardsPendingArray(eCards.filter(data => data.status === 'draft'));
  }, [response.data]);

  const eCardSubmitted = Cookie.get('eCardSubmitted') || {};
  const { modals, openECardModal } = useECardModal({
    ...eCardSubmitted,
    outletImage,
    currency,
    companyName
  });

  useEffect(() => {
    if (!hasKeys(eCardSubmitted) || !currency || !currentCompany) return;
    cleverTap.screenDisplayed.push({
      screen_name: 'ecard_setup3_postsubmission'
    });
    openECardModal({ modal: modals.submission, eCard: eCardSubmitted });
    Cookie.remove('eCardSubmitted');
  }, [
    eCardSubmitted,
    modals.submission,
    openECardModal,
    currency,
    currentCompany,
    cleverTap.screenDisplayed
  ]);

  const PendingECards = ({ count = 0 }) => {
    if (Boolean(count)) {
      return (
        <Button
          type="ghost"
          className={styles.pendingContainer}
          onClick={() => navigate('ecards/pending')}
        >
          <Icon icon="pending-cards" size="1.875rem" color="pelorous" />
          <span className={styles.pendingStatement}>
            {t('{{number}} eCards pending approval.', { number: count })}
          </span>
          <Icon icon="angle-right" size="1rem" color="pelorous" />
        </Button>
      );
    }
    return null;
  };

  const CreateNewECardsButton = ({ className }) => {
    return (
      <div className={classNames(styles.buttonContainer, className)}>
        <Button
          type="submit"
          className={styles.createNewECards}
          onClick={() => {
            cleverTap.tapped.push({
              screen_name: 'ecard_explore',
              tapped_on: 'btn_create_new_ecard'
            });

            if (isWebview) {
              return call(NATIVE.CREATE_ECARDS);
            }

            return navigate('/ecards/create');
          }}
        >
          {t('Create new eCard')}
        </Button>
      </div>
    );
  };

  const ECardsListing = ({ eCardsData }) => {
    if (eCardsData.length && eCardsActiveArray.length) {
      return (
        <>
          <div className={styles.eCardsContainer}>
            {eCardsData.map(
              ({
                id,
                currency,
                original_price,
                selling_price,
                discount,
                revenue,
                sold,
                text_color,
                background_color,
                default_logo,
                company_logo_url
              }) => (
                <ECardWithDetails
                  key={id}
                  href={`/ecards/${id}`}
                  sellingPrice={selling_price}
                  discountRate={discount}
                  originalPrice={original_price}
                  currency={currency}
                  outletImage={default_logo ? outletImage : company_logo_url}
                  companyName={companyName}
                  revenue={revenue}
                  sold={sold}
                  textColor={text_color}
                  backgroundColor={background_color}
                  ctEvent={() =>
                    cleverTap.tapped.push({
                      screen_name: 'ecard_explore',
                      tapped_on: 'btn_details'
                    })
                  }
                />
              )
            )}
          </div>
          <CreateNewECardsButton
            type="primary"
            className={styles.eCardsButton}
          />
        </>
      );
    }
    return (
      <div className={styles.eCardsEmptyPlaceholder}>
        <img src={eCardsEmpty} alt="ecards empty placeholder" />
        <span>{t('Your eCard has been submitted.')}</span>
        <span>{t('Our team will reach out to you shortly.')}</span>
        <CreateNewECardsButton
          type="primary"
          className={styles.eCardsEmptyPlaceholderButton}
        />
      </div>
    );
  };

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading
          title="eCards"
          onBack={() => call(NATIVE.CLOSE_WEBVIEW)}
        />
      ) : (
        <Heading
          title="eCards"
          subtitle={t('View your eCards performance at your outlet')}
        />
      )}
      {!Boolean(eCardsActiveArray.length || eCardsPendingArray.length) ? (
        <LoadingSpinner />
      ) : (
        <>
          <PendingECards count={eCardsPendingArray.length} />
          <ECardsListing eCardsData={eCardsActiveArray} />
        </>
      )}
    </AppLayout>
  );
};

export default ECardsView;
