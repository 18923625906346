import React from 'react';
import classNames from 'classnames';

import Button, { IButtonProps } from './Button';
import styles from 'assets/css/CancelButton.module.scss';

const CancelButton: React.FC<IButtonProps> = ({
  className,
  children,
  ...restProps
}) => (
  <Button
    size="lg"
    isRounded
    className={classNames(styles.button, styles.cancel, className)}
    {...restProps}
  >
    {children}
  </Button>
);

export default CancelButton;
