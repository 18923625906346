import React from 'react';
import Slider, { Settings } from 'react-slick';
import classnames from 'classnames';
import Card from 'components/Card';
import { RESPONSIVE_BREAKPOINTS } from 'helpers/TypeConstants';
import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from 'assets/css/DashboardCard.module.scss';

interface IDashboardCardProps {
  title: string;
  iconTitle: string;
  isSlider: boolean;
  sliderProps: Settings;
  className: string;
  sliderRef: React.RefObject<Slider> | null | undefined;
  topChildren: string;
}

const DashboardCard: React.FC<IDashboardCardProps> = ({
  title,
  iconTitle,
  children,
  isSlider,
  sliderProps = {},
  className,
  sliderRef,
  topChildren
}) => {
  const settings: Settings = {
    infinite: false,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: RESPONSIVE_BREAKPOINTS.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: RESPONSIVE_BREAKPOINTS.sm,
        settings: {
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    ...sliderProps
  };

  return (
    <Card className={classnames(styles.dashboardCard, className)}>
      <div data-top-section className={styles.dashboardCardTopSection}>
        <section className={styles.iconAndTitle}>
          {iconTitle && <Icon icon={iconTitle} color="primary" size="1.5rem" />}
          <span className={styles.dashboardCardTitle}>{title}</span>
        </section>
        <div data-top-children>{topChildren}</div>
        {isSlider && (
          <div className={styles.dashboardPagingControl}>
            <Button
              type="text"
              className={styles.dashboardPagingButton}
              onClick={() => sliderRef?.current?.slickPrev()}
            >
              <Icon icon="angle-left" color="grayish-blue" size="1.5rem" />
            </Button>
            <Button
              type="text"
              className={styles.dashboardPagingButton}
              onClick={() => sliderRef?.current?.slickNext()}
            >
              <Icon icon="angle-right" color="grayish-blue" size="1.5rem" />
            </Button>
          </div>
        )}
      </div>
      {isSlider ? (
        <Slider ref={sliderRef} {...settings}>
          {children}
        </Slider>
      ) : (
        children
      )}
    </Card>
  );
};

export default DashboardCard;
