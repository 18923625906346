import { useContext, useEffect } from 'react';
import { HubSpotContext } from 'contexts/HubSpotContext';

export default function useHubSpotTrack(route) {
  const { trackRoute, isLoaded } = useContext(HubSpotContext);

  useEffect(() => {
    if (isLoaded) trackRoute(route);
  }, [trackRoute, route, isLoaded]);
}
