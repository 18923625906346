import React, { useContext } from 'react';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import { LangContext } from 'contexts/LangContext';
import { FCMContext } from 'contexts/FCMContext';
import Card from 'components/Card';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

import styles from 'assets/css/SwitchLanguageView.module.scss';

const SwitchLanguageView = props => {
  const { location } = props;
  const { t } = useTranslation();
  const { currentLang, changeLang } = useContext(LangContext);
  const prevLocationParam = location.state && location.state.prevLocation;
  const { resubscribeAllOnLocaleChange } = useContext(FCMContext);

  const languages = [
    {
      title: t('English'),
      key: 'en-US',
      icon: 'EN'
    },
    {
      title: t('Bahasa Melayu'),
      key: 'ms-MY',
      icon: 'MY'
    },
    {
      title: t('Bahasa Indonesia'),
      key: 'id-ID',
      icon: 'ID'
    },
    {
      title: t('Chinese (Simplified)'),
      key: 'zh-HK',
      icon: '中文'
    }
  ];

  const LanguageButton = ({ id, name, iconText }) => (
    <Button
      type="text"
      fullWidth
      size="lg"
      className={classNames(
        styles.button,
        styles.LanguageButton,
        currentLang === id && [styles.activeLanguage]
      )}
      onClick={() => {
        changeLang(id);
        resubscribeAllOnLocaleChange(id);
        prevLocationParam
          ? navigate(prevLocationParam.url, {
              state: prevLocationParam.state,
              replace: true
            })
          : window.history.go(-1);
      }}
    >
      <div
        className={classNames(
          styles.languageBadge,
          currentLang === id && [styles.activeLanguage, styles.activeBadge],
          id === 'zh-HK' && [styles.chineseFont]
        )}
      >
        {iconText}
      </div>
      {name}
      <span
        className={classNames(currentLang === id && [styles.languageIcon])}
      />
    </Button>
  );

  return (
    <div className={styles.overlay}>
      <Card className={styles.switchLanguageCard}>
        <div className={styles.title}>{t('Select your language')}</div>
        {!currentLang && (
          <section className={styles.loadingSpinnerWrapper}>
            <LoadingSpinner />
          </section>
        )}
        <section className={styles.languages}>
          {languages.map(language => (
            <LanguageButton
              key={language.key}
              id={language.key}
              name={language.title}
              iconText={language.icon}
            />
          ))}
        </section>
      </Card>
    </div>
  );
};

export default SwitchLanguageView;
