import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { navigate } from '@reach/router';
import { alipayCreateTransactionalQrCode } from 'commands/alipayTransaction';
import { getAlipayVerifyPayment } from 'queries/getAlipayPayment';
import { OpsAuthContext } from 'contexts/OpsAuthContext';
import { NotificationContext } from 'contexts/NotificationContext';

import alipayLogo from 'assets/images/alipay-logo.png';
import alipayIcon from 'assets/images/ic-alipay.svg';

import OpsLayout from 'layouts/OpsLayout';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Card from 'components/Card';
import CurrencyTextInput from 'components/CurrencyTextInput';

import styles from 'assets/css/Alipay.module.scss';
import { WebviewContext } from 'contexts/WebviewContext';

const FORM_NAME = {
  amount: 'amount'
};

const INITIAL_VALUES = {
  amount: ''
};

const AlipayView = () => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { user, settings } = useContext(OpsAuthContext);
  const { setNotification } = useContext(NotificationContext);
  const [qrCodeResponse, setQrCodeResponse] = useState({});
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const [amount, setAmount] = useState(0);
  const countryCurrency = user && user.city && user.city.currency_display;
  const { isWebview } = useContext(WebviewContext);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'alipay' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  const VALIDATIONSCHEMA = Yup.object().shape({
    [FORM_NAME.amount]:
      process.env.NODE_ENV === 'production'
        ? Yup.number()
            .positive(t('Amount must be more than 0'))
            .required(t('Amount is required'))
        : Yup.number()
            .positive(t('Amount must be more than 0'))
            .required(t('Amount is required'))
            .lessThan(
              1.0,
              `Amount need to be less than ${countryCurrency}\${less} (qa-staging only)`
            )
  });

  const removeCurrencyFormat = value => value.split('.').join('');

  const onCreateTransactionalQrCode = ({ amount }, formikProps) => {
    if (settings && settings.features.alipay) {
      return alipayCreateTransactionalQrCode({
        price_cents: removeCurrencyFormat(amount)
      })
        .then(response => {
          setAmount(amount);
          setQrCodeResponse(response);
        })
        .catch(error => {
          setNotification({
            message: t('{{error}}. Please try again', {
              error
            }),
            type: 'error'
          });
        })
        .finally(() => formikProps.setSubmitting(false));
    }

    return setNotification({
      message: t('Outlet has no alipay set-up. Please select other outlets.'),
      type: 'error'
    });
  };

  const onAlipayVerifyPaymentClick = () => {
    const { external_payment_id } = qrCodeResponse;

    getAlipayVerifyPayment({ external_payment_id })
      .then(response => {
        if (response.external_payment && response.external_payment.status) {
          return navigate(
            `/operations/transactions/alipay/${external_payment_id}`
          );
        }
      })
      .catch(err => {
        const errorStatement = err && err.value && err.value.response.error;

        setNotification({
          message: t('{{errorStatement}}. Please try again', {
            errorStatement
          }),
          type: 'error'
        });
      });
  };

  const RenderAlipayEnterAmount = () => {
    return (
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATIONSCHEMA}
        onSubmit={onCreateTransactionalQrCode}
      >
        {formikProps => (
          <Form className={styles.formSize}>
            <Card className={styles.alipayCard}>
              <div className={styles.logoContainer}>
                <img
                  src={alipayIcon}
                  alt={'alipay logo'}
                  className={styles.alipayLogo}
                />
                <p className={styles.alipayLogoTitle}>{t('Alipay')}</p>
                <p className={styles.description}>
                  {t('Enter payable amount after discount')}
                </p>
              </div>
              <div className={styles.textInputContainer}>
                <p className={styles.textPlaceholder}>
                  {user.city.currency_display || ''}
                </p>
                <CurrencyTextInput
                  name={FORM_NAME.amount}
                  className={styles.textInput}
                  errors={formikProps.errors}
                  touched={formikProps.touched}
                  withErrors={true}
                />
              </div>
            </Card>
            <Button
              type="submit"
              className={styles.confirmButton}
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'alipay',
                  tapped_on: 'btn_confirm'
                })
              }
              isLoading={formikProps.isSubmitting}
            >
              {t('Confirm')}
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  const RenderQRCode = () => {
    const { qr_code_image_url } = qrCodeResponse;
    const price = amount;

    return (
      <div className={styles.alipayQRContainer}>
        <Card className={styles.qrCodeCard}>
          <img src={alipayLogo} alt="alipay logo" className={styles.logo} />
          <div className={styles.amountPayable}>
            {t('Total Amount Payable')}
          </div>
          <div className={styles.price}>
            {user.city.currency_display || ''} {price}
          </div>
          <div
            className={styles.qrCode}
            style={{ '--bg': `url(${qr_code_image_url})` }}
          />
          <div className={styles.bottomText}>
            {t('Scan QR to make payment')}
          </div>
        </Card>
        <Button
          type="primary"
          className={styles.buttonVerify}
          onClick={onAlipayVerifyPaymentClick}
        >
          {t('Verify Transaction')}
        </Button>
      </div>
    );
  };

  const RenderAlipayFlow = () => {
    if (!isEmpty(qrCodeResponse)) {
      return (
        <>
          <Button
            type="text"
            leftIcon="arrow-left"
            className={styles.backTransaction}
            onClick={() => setQrCodeResponse(null)}
          >
            {t('Back to Alipay')}
          </Button>
          <RenderQRCode />
        </>
      );
    }

    return (
      <>
        <Button
          type="text"
          leftIcon="arrow-left"
          className={styles.backTransaction}
          onClick={() => navigate('/operations/transactions')}
        >
          {t('Back to transactions')}
        </Button>
        <RenderAlipayEnterAmount />
      </>
    );
  };

  return (
    <OpsLayout>
      {!isWebview && (
        <Heading title="Alipay" subtitle={t('Generate Alipay QR code here')} />
      )}
      <RenderAlipayFlow />
    </OpsLayout>
  );
};

export default AlipayView;
