import FaveBizAPI from 'integrations/FaveBizAPI';

export const getAutoExtendEcards = (param: {
  uid: string;
}) =>
  FaveBizAPI.get(`/v1/auto_extension/ecards`, param).then(res => res);

export const getAutoExtendDeals = (param: {
  uid: string;
}) =>
  FaveBizAPI.get(`/v1/auto_extension/deals`, param).then(res => res);
