import React from 'react';
import PropTypes from 'prop-types';
import styles from 'insights/assets/css/ScrollToElement.module.scss';

const ScrollToElement = ({ id, children }) => {
  return (
    <div id={id} className={styles.scroll}>
      {children}
    </div>
  );
};

ScrollToElement.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
};

export default ScrollToElement;
