import React from 'react';
import classNames from 'classnames';

import { COUNTRY_CODES } from 'helpers/TypeConstants';
import TextInput from 'components/TextInput';
import Select from 'components/Select';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/PhoneInput.module.scss';
import { MapleContext } from 'contexts/MapleContext';

type PhoneInputProps = {
  placeholder: string;
  compact: boolean;
  errors: object;
  touched: object;
};

const PhoneInput: React.FC<PhoneInputProps> = props => {
  const { isMaple } = React.useContext(MapleContext);
  const { t } = useTranslation();

  const {
    compact = false,
    placeholder = t('Enter your phone number'),
    errors,
    touched
  } = props;

  const countryIconCode = (code: string) => {
    if (code === '+60') return 'MY';
    else if (code === '+62') return 'ID';
    else return 'SG';
  };

  return (
    <div className={styles.telWrapperContainer}>
      <label
        htmlFor="phone"
        className={classNames(styles.telWrapper, compact && styles.compact)}
      >
        {isMaple ? <TextInput
          name="code"
          disabled
          className={styles.telCode}
        /> :
        <Select
          name="code"
          items={COUNTRY_CODES.map(d => ({
            ...d,
            text: compact ? d.code : `${countryIconCode(d.code)} ${d.code}`
          }))}
          className={styles.telCode}
        />}
        <TextInput
          type="tel"
          name="phone"
          placeholder={placeholder}
          className={styles.telNumber}
          {...{ errors, touched }}
        />
      </label>
    </div>
  );
};
export default PhoneInput;
