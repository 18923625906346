import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import useCleverTapOld from 'hooks/useCleverTapOld';

import Button from 'components/Button';
import GoogleMap from 'components/GoogleMap';
import AutoSuggest from 'components/AutoSuggest';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/FavePaySetupView/MapModal.module.scss';

function MapModal({ onClose, onChange, address: propsAddress, outletContext }) {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();

  const [address, setAddress] = useState({});
  useEffect(() => {
    setAddress(propsAddress);
  }, [propsAddress]);
  useEffect(() => {
    if (address.latitude) onChange(address);
  }, [address, onChange]);

  const onChangeAddress = useCallback(address => setAddress(address), []);
  const onChangeAddressText = useCallback(
    addressText => {
      if (address.latitude && address.longitude) {
        setAddress(prevState => ({ ...prevState, description: addressText }));
      }
    },
    [address]
  );

  return (
    <section className={styles.layout}>
      <h2 className={styles.heading}>{t('Pin your outlet location')}</h2>
      <section className={styles.map}>
        <GoogleMap
          lat={address.latitude}
          lng={address.longitude}
          onChange={onChangeAddress}
        />
      </section>
      <Formik initialValues={{}} onSubmit={() => {}}>
        {({ errors, touched }) => (
          <Form className={styles.form}>
            <AutoSuggest
              key="outletAddress"
              name="outletAddress"
              placeholder="Eg. No 8, Suite 28-1, Level 28, Vertical Corporate Office Tower B Bangsar South City, Jalan Kerinchi, Bangsar South, 59200 Kuala Lumpur"
              className={styles.addressInput}
              onChange={onChangeAddress}
              onChangeQuery={onChangeAddressText}
              value={address}
              {...{ errors, touched }}
            />
            <Button
              type="primary"
              size="lg"
              className={styles.button}
              onClick={() => {
                onClose();
                cleverTap.tapped.push({
                  screen_name: 'payment_setup2_business',
                  tapped_on: 'btn_done_outlet_map',
                  outletContext
                });
              }}
            >
              {t('Done')}
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
}

MapModal.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  address: PropTypes.shape({
    description: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number
  })
};

MapModal.defaultProps = {
  onClose: () => {},
  onChange: () => {},
  address: {}
};

export default MapModal;
