import authUser from 'commands/authUser';
import LoadingSpinner from 'components/LoadingSpinner';
import { CleverTapContextOld } from 'contexts/CleverTapContextOld';
import verifyUser from 'queries/verifyUser';
import logoutUser from 'commands/logoutUser';
import React, { useContext, useEffect, useState } from 'react';
import { QueryStringContext } from 'contexts/QueryStringContext';
import Cookie from 'helpers/Cookie';
import { hasKeys } from 'utils';
import find from 'lodash/find';
import { COUNTRIES } from 'helpers/TypeConstants';

export const AuthContext = React.createContext();
export const AuthContextProvider = props => {
  const { cleverTapProfile } = useContext(CleverTapContextOld);
  const {
    updateUser,
    user,
    isValidUser,
    token,
    companyID,
    outletID,
    isMY,
    setCompanyID,
    setOutletID,
    setCurrentOutletCode
  } = useUserSession();
  const isAuthenticatingUser = !user;

  const isIndonesia =
    user && user.city && COUNTRIES[user.city.slug] === 'Indonesia';

  useEffect(() => {
    cleverTapProfile.push(user);
  }, [user, cleverTapProfile]);

  useEffect(() => {
    if (user) return;

    if (!token) {
      updateUser({});
    } else {
      verifyUser('/v1/company_managers/current_manager', token)
        .then(updateUser)
        .catch(error => updateUser({}));
    }
  }, [user, updateUser, token]);

  const implementation = {
    loginUser: ({ method, phone, email, pin_number }) =>
      authUser({
        method,
        pin_number,
        value: method === 'email' ? email : phone
      }).then(updateUser),
    unauthenticateUser: callback => updateUser({}, callback),
    updateUser,
    isMY,
    isValidUser,
    isAuthenticatingUser,
    user,
    companyID,
    outletID,
    isIndonesia,
    setCompanyID,
    setOutletID,
    setCurrentOutletCode
  };
  return (
    <AuthContext.Provider value={implementation}>
      {isAuthenticatingUser ? <LoadingSpinner /> : props.children}
    </AuthContext.Provider>
  );
};

function useUserSession() {
  const params = useContext(QueryStringContext);
  const [user, setUser] = useState();
  const [updated, setUpdated] = useState();
  const cookieCompany = Cookie.get('currentCompany');
  const cookieOutlet = Cookie.get('currentOutlet');
  const setCompanyID = id => Cookie.set('currentCompany', id);
  const setOutletID = id => {
    Cookie.set('currentOutlet', id);
    setUpdated(new Date().toString());
  };
  const setCurrentOutletCode = data => {
    Cookie.set('currentOutletCode', data);
  };

  const updateUser = (user, callback) => {
    if (hasKeys(user)) {
      // valid user, update cookies for company, outlet, token
      if (user?.jwt_token) {
        Cookie.set('accessToken', user.jwt_token);
      }

      const { companies } = user;
      const company =
        find(companies, { id: +(params.company || cookieCompany) }) ||
        companies[0];
      setCompanyID(company.id);

      const { outlets } = company;

      const outletID =
        'outlet' in params
          ? params.outlet
          : cookieOutlet || (outlets.length === 1 && outlets[0].id);

      setOutletID(outletID || '');
      const outlet = find(outlets, { id: outletID });
      setCurrentOutletCode(outlet ? outlet.auth_code : '');
    } else {
      // logout + cleanup;
      logoutUser().finally(() => {
        if (window.fcWidget) window.fcWidget.destroy();
        Cookie.remove('accessToken');
        Cookie.remove('currentCompany');
        Cookie.remove('currentOutlet');
        Cookie.remove('currentOutletCode');
      });
    }
    if (callback) callback(); /* allow other cleanups */
    setUser(user);
  };

  const isMY = () => {
    return user?.city?.slug === 'kuala-lumpur';
  };

  return {
    updated,
    updateUser,
    user,
    isMY,
    isValidUser: hasKeys(user),
    token: params.token || Cookie.get('accessToken'),
    companyID: cookieCompany,
    outletID: cookieOutlet,
    setCompanyID,
    setOutletID,
    setCurrentOutletCode
  };
}
