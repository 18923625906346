import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from 'insights/assets/css/AlertToUseDesktop.module.scss';

function AlertToUseDesktop({ onClose }) {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible && (
        <div className={styles.layout}>
          <span>
            Check out FaveBiz Insights
            <br /> on desktop for better viewing experience.
          </span>
          <img
            src={require('insights/assets/images/alert-close.svg')}
            alt="Close"
            onClick={() => {
              setVisible(false);
              onClose();
            }}
          />
        </div>
      )}
    </>
  );
}

AlertToUseDesktop.propTypes = { onClose: PropTypes.func };
AlertToUseDesktop.defaultProps = { onClose: () => {} };

export default AlertToUseDesktop;
