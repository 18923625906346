import React from 'react';
import Icon from 'components/Icon';

type StarRatingProps = {
  star: number;
};

const HIGH_RATING = {
  number: 4,
  color: 'apple-green'
};

const MEDIUM_RATING = {
  number: 3,
  color: 'tangerine-orange'
};

const LOW_RATING = {
  number: 2,
  color: 'sunset-red'
};

const DEFAULT_COLOR = 'black';

const StarRating = ({ star }: StarRatingProps) => {
  const ratingColorBracket = (rating: number) => {
    if (rating >= HIGH_RATING.number) return HIGH_RATING.color;

    if (rating === MEDIUM_RATING.number) return MEDIUM_RATING.color;

    if (rating <= LOW_RATING.number) return LOW_RATING.color;

    return DEFAULT_COLOR;
  };

  return (
    <>
      {Array.from({ length: star }).map((_, i) => (
        <Icon key={i} icon="star" color={ratingColorBracket(star)} />
      ))}
    </>
  );
};

export default StarRating;
