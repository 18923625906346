import React from 'react';
import classNames from 'classnames';
import { TappedButton } from 'components/Button';
import styles from 'assets/css/Banner.module.scss';

const Banner = ({ title, image, link, className }) => {
  return (
    <article
      className={classNames(styles.banner, className)}
      style={{ '--bg': `url(${image})` }}
      title={title}
    >
      <TappedButton
        type="ghost"
        href={link}
        className={styles.bannerLink}
        event={{
          screen_name: 'home',
          tapped_on: 'btn_banner',
          section_name: 'whats_new',
          banner_name: title
        }}
      >
        {title}
      </TappedButton>
    </article>
  );
};

export default Banner;
