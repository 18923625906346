import FaveBizAPI from 'integrations/FaveBizAPI';
import { UrlImage } from 'types/UrlImage';
import { FeatureItem } from './getFeatureListing';

export default ({ id }: { id: number }) => FaveBizAPI.get(`/v1/feed/${id}`);

export type Outlets = {
  id: number;
  name: string;
  checked: boolean;
};

type ShareUrl = { url: string };

export type FaveFeedPostDetailsResponse = {
  created_by: string;
  created_on: string;
  deleted_on?: any;
  description: string;
  faved_count: number;
  flagged: boolean;
  hide_count: number;
  id: number;
  images: UrlImage[];
  outlets: Outlets[];
  scheduled_date: Date;
  expired: boolean;
  share: ShareUrl;
  shared_count: number;
  status: 'active' | 'inactive' | 'review' | 'scheduled';
  type: 'announcement' | 'deal' | 'e_card';
  updated_by: string;
  updated_on: string;
  viewed_count: number;
  deal?: FeatureItem;
  e_card?: FeatureItem;
};
