import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withFormik, Form } from 'formik';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { getCountryCodeByCitySlug } from 'utils';
import { COUNTRY_CODES } from 'helpers/TypeConstants';
import FormikError from 'components/FormikError';
import { TappedButton, PrimaryInvertedButton } from 'components/Button';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import Checkbox from 'components/Checkbox';
import PhoneInput from 'components/PhoneInput';
import { ModalContext } from 'contexts/ModalContext';
import { AuthContext } from 'contexts/AuthContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { OutletContext } from 'contexts/OutletContext';
import getBankList from 'queries/getBankList';
import addBusinessInfo from 'commands/addBusinessInfo';
import bankAccountImage from 'assets/images/img-link-bank-acct.svg';
import businessInfoIcon from 'assets/images/ic-business-info-black.svg';
import bankInfoIcon from 'assets/images/ic-bank-black.svg';
import outletIcon from 'assets/images/ic-outlet-black.svg';
import locationIcon from 'assets/images/ic-location-black.svg';
import editIcon from 'assets/images/ic-edit.svg';
import useCleverTapOld from 'hooks/useCleverTapOld';

import SetupCard from './SetupCard';
import StepCTASection from './StepCTASection';
import SectionHeader from './SectionHeader';
import FormGrid from './FormGrid';
import MapModal from './MapModal';
import { Trans, withTranslation } from 'react-i18next';
import styles from 'assets/css/FavePaySetupView/BusinessDetailsView.module.scss';
import routesMap from 'helpers/routesMap';

const SINGAPORE = COUNTRY_CODES[2].code;

// why we wrap component in withFormik: to make formik functions
// available to useEffect
function BusinessDetailsView({
  onSubmit,
  outlet,
  errors,
  touched,
  setFieldValue,
  handleSubmit,
  t
}) {
  const { user, updateUser } = useContext(AuthContext);
  const { setNotification } = useContext(NotificationContext);
  const outletContext = useContext(OutletContext);
  const cleverTap = useCleverTapOld();
  const [address, setAddress] = useState({});
  const [addressText, setAddressText] = useState('');
  const hasAddressText = addressText.length !== 0;
  const hasAddress = Object.keys(address).length !== 0;
  const [firstCompany] = user.companies || [];
  const isCitySingapore = user.city.slug === 'singapore';

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);

  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'payment_setup2_business' });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  const mapModal = useContext(ModalContext);
  const onCloseMapModal = useCallback(address => {
    const addressSchema = Yup.object().shape({
      description: Yup.string().required(),
      latitude: Yup.number().required(),
      longitude: Yup.number().required()
    });
    if (addressSchema.isValidSync(address)) setAddress(address);
  }, []);
  useEffect(() => {
    mapModal.init({
      name: 'MapModal',
      Component: MapModal,
      componentProps: { address, outletContext },
      onClose: onCloseMapModal
    });
  }, [onCloseMapModal, mapModal, address, cleverTap.tapped, outletContext]);

  useEffect(() => {
    if (hasAddress) {
      setFieldValue('address', address);
      setAddressText(address.description);
    }
  }, [address, hasAddress, setFieldValue]);

  const [banks, setBanks] = useState([]);
  useEffect(() => {
    let isMounted = true;
    async function initBankDropdown() {
      const banks = await getBankList();
      if (!isMounted) return;
      setFieldValue('bankId', `${banks[0].id}`);
      setBanks(banks.map(({ id, name }) => ({ code: `${id}`, text: name })));
    }
    if (banks.length === 0) initBankDropdown();
    setFieldValue('email', user.email || '');
    const countryCode = getCountryCodeByCitySlug(user.city.slug);
    const phoneSansCountryCode = (user.phone || '').replace(countryCode, '');
    setFieldValue('code', countryCode);
    setFieldValue('phone', phoneSansCountryCode);
    setFieldValue('companyId', firstCompany.id);
    setFieldValue('outletId', outlet.id);
    setFieldValue('setNotification', setNotification);
    setFieldValue('updateUser', updateUser);
    setFieldValue('cleverTapEventPush', cleverTap.tapped.push);
    setFieldValue('onSubmit', onSubmit);
    setFieldValue('isCitySingapore', isCitySingapore);
    return () => (isMounted = false);
  }, [
    setFieldValue,
    user,
    firstCompany,
    onSubmit,
    outlet,
    setNotification,
    cleverTap.tapped.push,
    updateUser,
    isCitySingapore,
    banks
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <SetupCard className={styles.card}>
        <FormGrid className={styles.hero}>
          <img
            className={styles.heroImage}
            src={bankAccountImage}
            alt="Link bank account"
          />
          <section className={styles.description}>
            <h3 className={styles.header}>{t('Link your bank account')}</h3>
            <p className={styles.paragraph}>
              {t(
                'To receive payment, you will need to link your bank account. If you own a registered business, enter your company bank account details.'
              )}
            </p>
            <p className={styles.paragraph}>
              {t(
                'Payment will be made weekly on every Thursday, for transactions for the previous week from Monday to Sunday.'
              )}
            </p>
          </section>
        </FormGrid>
        <section className={styles.businessLayout}>
          <SectionHeader
            icon={businessInfoIcon}
            title={t('Business details')}
          />
          <FormGrid>
            <TextInput
              type="text"
              name="businessRegistrationName"
              placeholder={t('Business registration name*')}
              {...{ errors, touched }}
            />
            <TextInput
              type="text"
              name="businessRegistrationNumber"
              placeholder={t('Business registration number*')}
              {...{ errors, touched }}
            />
          </FormGrid>
        </section>
        <section className={styles.bankLayout}>
          <SectionHeader
            icon={bankInfoIcon}
            title={t('Bank account details')}
          />
          <FormGrid>
            <Select
              name="bankId"
              placeholder={t('Bank name*')}
              items={banks}
              {...{ errors, touched }}
            />
            <TextInput
              type="text"
              name="bankAccountNumber"
              placeholder={t('Bank account number*')}
              {...{ errors, touched }}
            />
            <TextInput
              type="text"
              name="bankAccountName"
              placeholder={t('Beneficiary name*')}
              {...{ errors, touched }}
            />
            <PhoneInput
              compact
              placeholder={t('Enter phone number*')}
              {...{ errors, touched }}
            />
            <TextInput
              type="email"
              name="email"
              placeholder={t('Notification email address*')}
              {...{ errors, touched }}
            />
            {isCitySingapore && (
              <TextInput
                type="text"
                name="bankBranchCode"
                placeholder={t('Branch code')}
                {...{ errors, touched }}
              />
            )}
          </FormGrid>
        </section>
        <section className={styles.outletLayout}>
          <SectionHeader icon={outletIcon} title={t('Outlet details')} />
          <FormGrid>
            <TextInput
              type="text"
              name="outletName"
              placeholder={t('Outlet area (e.g. Bangsar)*')}
              {...{ errors, touched }}
            />
            <section className={styles.outletAddress}>
              {hasAddressText && <p>{addressText}</p>}
              <PrimaryInvertedButton
                className={styles.addressButton}
                fullWidth
                leftIcon={hasAddressText ? editIcon : locationIcon}
                onClick={() => mapModal.open()}
              >
                {hasAddressText ? t('Edit address') : t('Pin outlet on map*')}
              </PrimaryInvertedButton>
              <input type="hidden" name="address" />
              <FormikError {...{ name: 'address', errors, touched }} />
            </section>
          </FormGrid>
        </section>
        <section className={styles.tnc}>
          <label
            htmlFor="isAgreeTNC"
            className={styles.tncRow}
            onChange={e => setFieldValue('isAgreeTNC', e.target.checked)}
          >
            <Checkbox
              type="green"
              id="isAgreeTNC"
              name="isAgreeTNC"
              className={styles.checkbox}
            />
            <span>
              {t(
                'I understand that once my business is verified, it will be listed on Fave app and I will be able to receive weekly payment.'
              )}
            </span>
          </label>
          <FormikError {...{ name: 'isAgreeTNC', errors, touched }} />
        </section>
      </SetupCard>
      <StepCTASection submitText={t('Continue with step 3')}>
        <Trans>
          Need help? Read the
          <TappedButton
            type="text"
            textColor="pelorous"
            href={routesMap.businessDetailsFAQ}
            event={{
              screen_name: 'payment_setup2_business',
              tapped_on: 'btn_faq'
            }}
          >
            FAQ
          </TappedButton>
          or
          <TappedButton
            type="text"
            textColor="pelorous"
            href="/contact-us"
            event={{
              screen_name: 'payment_setup2_business',
              tapped_on: 'btn_help_contact'
            }}
          >
            contact us.
          </TappedButton>
        </Trans>
      </StepCTASection>
    </Form>
  );
}

BusinessDetailsView.propTypes = {
  onSubmit: PropTypes.func,
  outlet: PropTypes.object
};

BusinessDetailsView.defaultProps = {
  onSubmit: () => {},
  outlet: {}
};

export default withTranslation()(
  withFormik({
    mapPropsToValues: ({ t }) => ({
      email: '',
      businessRegistrationName: '',
      businessRegistrationNumber: '',
      bankAccountNumber: '',
      bankAccountName: '',
      bankBranchCode: undefined,
      outletName: '',
      bankId: '',
      address: '',
      isAgreeTNC: false,
      t,
      code: '',
      phone: ''
    }),
    validationSchema: ({ t }) =>
      Yup.object().shape({
        email: Yup.string()
          .email(t('Invalid email'))
          .required(t('Email required')),
        bankId: Yup.string().required(t('Required')),
        businessRegistrationName: Yup.string().required(t('Required')),
        businessRegistrationNumber: Yup.string().required(t('Required')),
        bankAccountNumber: Yup.string().required(t('Required')),
        bankAccountName: Yup.string().required(t('Required')),
        phone: Yup.string()
          .required(t('Phone number incomplete'))
          .when('code', {
            is: SINGAPORE,
            then: Yup.string().matches(/\d{8,}$/, t('Minimum 8 digits')),
            otherwise: Yup.string().matches(/\d{9,}$/, t('Minimum 9 digits'))
          }),
        bankBranchCode: Yup.string().when('isCitySingapore', {
          is: true,
          then: Yup.string().required(t('Required'))
        }),
        outletName: Yup.string().required(t('Required')),
        address: Yup.object().required(t('Address location required')),
        isAgreeTNC: Yup.boolean().oneOf(
          [true],
          t('Kindly tick the checkbox to accept terms and conditions')
        )
      }),
    handleSubmit: values => {
      const {
        email,
        bankId,
        businessRegistrationName,
        businessRegistrationNumber,
        bankAccountNumber,
        bankAccountName,
        bankBranchCode,
        outletName,
        address,
        companyId,
        outletId,
        setNotification,
        cleverTapEventPush,
        updateUser,
        onSubmit,
        t,
        code,
        phone
      } = values;

      const params = {
        bank_info_id: bankId,
        bank_branch_code: bankBranchCode /* -> just for SG */,
        bank_account_number: bankAccountNumber,
        bank_account_name: bankAccountName,
        email,
        phone: phone ? `${code}${phone}` : undefined,
        outlet_id: outletId,
        gst_registered_name: businessRegistrationName,
        business_registration_number: businessRegistrationNumber,
        outlet_name: outletName,
        outlet_address: address.description,
        address_latitude: address.latitude,
        address_longitude: address.longitude
      };

      addBusinessInfo(companyId, params)
        .then(user => {
          updateUser(user);
          setNotification({
            message: t(
              'Your submission has been submitted for approval. We shall get back to you soon.'
            ),
            type: 'info'
          });
          cleverTapEventPush({
            screen_name: 'payment_setup2_business',
            tapped_on: 'btn_continue_step3'
          });

          onSubmit();
          navigate('/favepay/setup', {
            replace: true,
            state: { outletAddress: address.description, phone }
          });
        })
        .catch(error => {
          setNotification({
            message: error.value.response.error,
            type: 'error'
          });
        });
    }
  })(BusinessDetailsView)
);
