import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useQuery
} from 'react-query';
import FaveBizAPI from 'integrations/FaveBizAPI';
import getStatementsOfAccounts, {
  StatementOfAccountParams,
  StatementOfAccountResponse
} from './getStatementsOfAccounts';
import { defaultClientConfig } from 'App';
import { ApiError } from 'types/ApiError';

const baseURL = '/v1/invoices';

type BankAccountsParam = {
  company_id: number;
  outlet_id: number;
  page: number;
  per_page: number;
};

type InvoiceProductsParam = {
  finance_account_id: number;
  invoice_period_start: string;
  invoice_period_end: string;
};

type InvoicesParams = Omit<InvoiceProductsParam, 'tenant_code'> & {
  products: ProductOptionsResponse;
};

type ProductOptionsResponse = string[];

export type InvoiceDocument = {
  name: string;
  document_type: string;
};

export type InvoiceProduct = {
  name: string;
  documents: InvoiceDocument[];
};

export type Invoice = {
  invoice_period: string;
  products: InvoiceProduct[];
};

type InvoicesResponse = Invoice[];

const getBankAccountsList = ({
  pageParam = 1,
  queryKey
}: QueryFunctionContext<QueryKey, number>) => {
  const [, params] = queryKey as [string, BankAccountsParam];

  return getStatementsOfAccounts({ ...params, page: pageParam });
};

const getProductList = ({
  queryKey
}: QueryFunctionContext<QueryKey, number>) => {
  const [, params] = queryKey as [string, InvoiceProductsParam];

  return new Promise<ProductOptionsResponse>((resolve, reject) => {
    FaveBizAPI.get(`${baseURL}/products`, params)
      .then(({ products }) => {
        // Change the text for display purposes only
        const newProducts = products.map((product: string) =>
          product === 'FaveDeal' ? 'Deal' : product
        );

        resolve(newProducts);
      })
      .catch(error => reject(error));
  });
};

const getInvoicesList = ({
  queryKey
}: QueryFunctionContext<QueryKey, number>) => {
  const [, params] = queryKey as [string, InvoicesParams];

  const encodedParams = { ...params };
  (encodedParams as any).products = encodedParams.products
    // Change the text for display purposes only
    .map((product: string) => (product === 'Deal' ? 'FaveDeal' : product))
    .join(',');

  return new Promise<InvoicesResponse>((resolve, reject) => {
    FaveBizAPI.get(baseURL, encodedParams)
      .then(({ invoices }) => {
        // Change the text for display purposes only
        invoices.forEach((invoice: Invoice) =>
          invoice.products.forEach(product => {
            if (product.name === 'FaveDeal') product.name = 'Deal';
          })
        );

        resolve(invoices);
      })
      .catch(error => reject(error));
  });
};

export const useBankAccountList = (
  params: StatementOfAccountParams,
  enabled?: boolean
) =>
  useInfiniteQuery<StatementOfAccountResponse>(
    ['invoiceBankAccountListing', params],
    getBankAccountsList,
    {
      ...defaultClientConfig,
      enabled,
      getNextPageParam: (lastPage: StatementOfAccountResponse) => {
        const ITEMS_PER_PAGE = params.per_page || 20;

        if (lastPage.page * ITEMS_PER_PAGE < lastPage.total_count)
          return lastPage.page + 1;

        return false;
      }
    }
  );

export const useProductList = (
  params: InvoiceProductsParam,
  enabled: boolean
) =>
  useQuery<ProductOptionsResponse, ApiError>(
    ['invoiceSelfServeProducts', params],
    getProductList,
    { ...defaultClientConfig, enabled }
  );

export const useInvoicesList = (params: InvoicesParams, enabled: boolean) =>
  useQuery<InvoicesResponse, ApiError>(
    ['invoiceSelfServeListing', params],
    getInvoicesList,
    {
      ...defaultClientConfig,
      enabled
    }
  );
