import React from 'react';
import style from 'assets/css/ModalContainer.module.scss';
import classNames from 'classnames';

type Props = {
  title?: string;
  description?: string;
  className?: string;
};

const ModalContainer: React.FC<Props> = props => {
  const { title, description, className, children } = props;
  return (
    <div className={classNames(style.modal, className)}>
      {title && <p className={style.title}>{title}</p>}
      {description && <p className={style.description}>{description}</p>}
      {children}
    </div>
  );
};

export default ModalContainer;
