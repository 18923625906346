import React, { ReactNode } from 'react';
import { navigate } from '@reach/router';
import { useWebViewContext } from 'contexts/WebviewContext';
import WebviewHeading from './WebviewHeading';
import Heading from './Heading';

export const DefaultHeading = ({
  title,
  subTitle
}: {
  title: ReactNode;
  subTitle: ReactNode;
}) => {
  const { isWebview } = useWebViewContext();

  const handleBackClick = () => navigate(-1);

  return (
    <>
      {isWebview ? (
        <WebviewHeading title={title} onBack={handleBackClick} />
      ) : (
        <Heading title={title} subtitle={subTitle} />
      )}
    </>
  );
};
