import { useWebViewContext } from 'contexts/WebviewContext';
import { COUNTRIES } from 'helpers/TypeConstants';
import { call, NATIVE } from 'utils/nativeInterface';
import { getContactByCityCurrency } from 'views/ContactUsView';
import {
  openWidget as zOpenWidget,
  closeWidget,
  showWidgetButton,
  hideWidgetButton
} from '../utils/zendesk.chat.client';
import useAuthContext from './useAuthContext';

// If the chat client needs to be changed in the future,
// This hook can be updated without the need to change other files
const useChat = (allowWebView = true) => {
  const { user } = useAuthContext();
  const { isWebview } = useWebViewContext();

  const isIndonesia =
    user &&
    user.city &&
    COUNTRIES[user.city.slug as keyof typeof COUNTRIES] === 'Indonesia';

  const chatText = isIndonesia ? 'contact us' : 'chat with us';

  const openWidget = () => {
    if (isWebview && allowWebView) call(NATIVE.OPEN_HELP);
    else {
      if (isIndonesia) {
        const { email } = getContactByCityCurrency(user.city);

        if (email) window.location.assign(`mailto:${email}`);
      } else zOpenWidget();
    }
  };

  return {
    chatText,
    openWidget,
    closeWidget,
    showWidgetButton,
    hideWidgetButton
  };
};

export default useChat;
