import React, { useContext } from 'react';
import { OpsAuthContext } from 'contexts/OpsAuthContext';

export const OpsOutletContext = React.createContext();

export const OpsOutletProvider = props => {
  const { settings } = useContext(OpsAuthContext);
  const currentCompany = {
    partner_name: (settings.profile || {}).company_name,
    id: (settings.profile || {}).company_id,
    city_slug: settings.city_slug
  };

  const currentOutlet = settings.outlet
    ? {
        ...settings.outlet
      }
    : undefined;

  const outlets = [settings.outlet];

  const implementation = {
    currentCompany,
    currentOutlet,
    outlets
  };

  return (
    <OpsOutletContext.Provider value={implementation}>
      {props.children}
    </OpsOutletContext.Provider>
  );
};
