import React from 'react';
import { useTranslation } from 'react-i18next';

import Button, { PrimaryInvertedButton } from 'components/Button';
import styles from 'assets/css/AllowPushNotificationsModal.module.scss';

function AllowPushNotificationsModal({
  onClose
}: {
  onClose: ({ showDialog }: { showDialog: boolean }) => void;
}) {
  const { t } = useTranslation();
  return (
    <section className={styles.overlay}>
      <h3>{t('Stay up to date with your latest transactions in Fave')}</h3>
      <p>{t('You can unsubscribe from notifications anytime.')}</p>
      <section className={styles.actionButtons}>
        <PrimaryInvertedButton
          size="lg"
          justify="center"
          onClick={() => onClose({ showDialog: false })}
        >
          {t('Later')}
        </PrimaryInvertedButton>
        <Button
          type="primary"
          size="lg"
          justify="center"
          onClick={() => onClose({ showDialog: true })}
        >
          {t('Allow')}
        </Button>
      </section>
    </section>
  );
}

export default AllowPushNotificationsModal;
