import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback
} from 'react';
import {
  format,
  compareDesc,
  endOfYesterday,
  startOfYesterday,
  parseISO
} from 'date-fns';
import { navigate } from '@reach/router';
import AppLayout from 'layouts/AppLayout';
import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';
import { NotificationContext } from 'contexts/NotificationContext';
import useCleverTapOld from 'hooks/useCleverTapOld';
import getBanners from 'queries/getBanners';
import getRevenueSummary from 'queries/getRevenueSummary';
import getRPPLink from 'queries/getRPPLink';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON,
  COUNTRIES
} from 'helpers/TypeConstants';

import { getFavepayReviews, getFavedealsReviews } from 'queries/getReviews';
import routes from 'helpers/routesMap';
import {
  CHRONOLOGICAL,
  RESPONSIVE_BREAKPOINTS,
  REVIEWS
} from 'helpers/TypeConstants';
import Banner from 'components/Banner';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Button, { TappedButton, PrimaryInvertedButton } from 'components/Button';
import StarRatings from 'components/StarRating';
import WelcomeModal from 'components/WelcomeModal';
import DashboardCard from 'components/DashboardCard';
import useOnceModal from 'hooks/useOnceModal';
import { timezone } from 'utils/Dates';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';

import styles from 'assets/css/DashboardView.module.scss';
import ProductTour from 'components/ProductTour';
import { EVENTS } from 'react-joyride';
import CloseBannerIcon from 'assets/images/close-banner-icon.svg';
import Cookie from 'helpers/Cookie';
import { MapleContext } from 'contexts/MapleContext';

const PAGE_NUMBER = 1;
const PER_PAGE = 4;
const SORT_BY = CHRONOLOGICAL;

const DashboardView = props => {
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const reviewsSliderRef = useRef();
  const whatsNewSliderRef = useRef();

  const [banners, setBanners] = useState([]);
  const [todayRevenue, setTodayRevenue] = useState(0);
  const [yesterdayRevenue, setYesterdayRevenue] = useState(0);
  const [combinedReview, setCombinedReview] = useState([]);
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const [showTopRightDropDown, setShowTopRightDropDown] = useState(false);
  const [startProductTour, setStartProductTour] = useState(false);
  const newDashboardBannerCookie = 'showNewDashboardBannerFirstTime';
  const [showOnceNewDashboardBanner, setShowOnceNewDashboardBanner] = useState(
    Cookie.get(newDashboardBannerCookie, false)
  );

  const { user } = useContext(AuthContext);
  const { outlets, currentOutlet, currentCompany, ...rest } = useContext(
    OutletContext
  );

  const { setNotification } = useContext(NotificationContext);
  const isMY = user?.city?.slug === 'kuala-lumpur';
  const cleverTap = useCleverTapOld();

  // RPP header for MY only
  const responseData = isMY && getRPPLink(currentCompany?.id).data;
  useEffect(() => {
    if (!responseData || !responseData?.require_rpp_link) return;
    setNotification({
      type: 'info',
      message:
        "We are upgrading your existing FavePay QR to Malaysia's national DuitNow QR. Accept payments from more than 22 million users across 35 banks and non-banks! Submit your required documents today.",
      mobileMessage:
        'Malaysia’s National DuitNow QR is here! Submit required documents to upgrade your FavePay QR now.',
      cta: {
        link: responseData.favebiz_rpp_link,
        text: 'Get Started'
      },
      autoClose: false
    });
  }, [responseData, setNotification]);

  useOnceModal({
    Component: WelcomeModal,
    componentProps: {
      outletContext: {
        outlets,
        currentOutlet,
        currentCompany,
        ...rest
      },
      city: user?.city?.slug
    },
    cookieName: 'WelcomeModal',
    openOnReady: true,
    openCallback: () =>
      cleverTap.screenDisplayed.push({
        screen_name: CLEVERTAP_SCREEN_NAME.GETTING_STARTED
      }),
    openOnlyIf: useCallback(
      (
        companies = user.companies || [],
        companyOutlets = outlets || [],
        firstOutlet = companyOutlets[0] || {}
      ) =>
        /* new merchant starts with 1 company, 1 outlet */
        companies.length === 1 &&
        companyOutlets.length === 1 &&
        firstOutlet.self_onboard &&
        firstOutlet.self_onboarding_status === 'activate_favepay',
      [outlets, user]
    )
  });

  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  const CleverTap = useCleverTapOld();
  useEffect(() => {
    if (!currentCompany || hasSentCleverTapEvent) return;
    CleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.HOME
    });
    setHasSentCleverTapEvent(true);
  }, [currentCompany, hasSentCleverTapEvent, CleverTap]);

  const isOnboardingActivated =
    outlets &&
    outlets.length === 1 &&
    outlets[0].self_onboard &&
    outlets[0].self_onboarding_status === 'activate_favepay' &&
    !outlets[0].fave_deals_interest;

  useEffect(() => {
    let isMounted = true;
    (async () => {
      currentCompany &&
        getBanners(currentCompany.id)
          .then(bannerResponse => {
            isMounted && setBanners(bannerResponse.favebiz_app_banners);
          })
          .catch(err => {
            console.log(`getBanners error:`, err);
          });

      if (outlets && outlets.length) {
        let outletIds;
        const ratingsParams = {
          companyId: currentCompany && currentCompany.id,
          pageNumber: PAGE_NUMBER,
          perPage: PER_PAGE,
          sortBy: SORT_BY
        };

        if (currentOutlet !== '') {
          outletIds = currentOutlet.id;
        } else {
          outletIds = outlets && outlets.map(data => data.id).join(',');
        }

        const companyRevenue = await getRevenueSummary({
          outlet_ids: outletIds,
          company_id: currentCompany.id,
          schema_name: user.city.slug,
          start_datetime: timezone(startOfYesterday()),
          end_datetime: timezone(endOfYesterday())
        });

        if (!isMounted) return;
        setTodayRevenue(companyRevenue.today.revenue_amount);
        setYesterdayRevenue(companyRevenue.yesterday.revenue_amount);

        const favedealsResponse = await getFavedealsReviews({
          ...ratingsParams
        });
        const favepayResponse = await getFavepayReviews({ ...ratingsParams });

        const sortedReviews = [
          ...favepayResponse.reviews.map(data => ({
            ...data,
            reviewType: REVIEWS.FAVEPAY
          })),
          ...favedealsResponse.reviews.map(data => ({
            ...data,
            reviewType: REVIEWS.DEALS
          }))
        ].sort((a, b) =>
          compareDesc(parseISO(a.created_at), parseISO(b.created_at))
        );
        isMounted && setCombinedReview(sortedReviews.slice(0, 4));
      }
    })();
    return () => (isMounted = false);
  }, [currentCompany, outlets, currentOutlet, user]);

  const RenderRecentReview = () => (
    <DashboardCard
      title={t('Recent Reviews')}
      iconTitle="home-star"
      isSlider
      sliderProps={{
        slidesToShow: 1.2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: RESPONSIVE_BREAKPOINTS.md,
            settings: {
              initialSlide: 0,
              slidesToShow: 1.2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: RESPONSIVE_BREAKPOINTS.sm,
            settings: {
              initialSlide: 0,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }}
      className={styles.dashboardRecentReview}
      sliderRef={reviewsSliderRef}
    >
      {combinedReview.map(review => {
        const {
          id,
          rating,
          user,
          created_at,
          comment,
          review_reply,
          reviewType
        } = review;
        const showReplyDate = format(new Date(created_at), 'd MMM yyyy');

        return (
          <section
            key={id}
            className={styles.dashboardReview}
            onClick={() =>
              props.navigate(
                `${routes.reviews}?pageNumber=1&reviewType=${reviewType}&sortBy=${CHRONOLOGICAL}`,
                { state: { reviewId: id } }
              )
            }
          >
            <div className={styles.star}>
              <StarRatings star={rating} />
            </div>
            <section className={styles.topSection}>
              <div className={styles.name}>{user && user.name}</div>
              <div className={styles.date}>{showReplyDate}</div>
            </section>
            <section>
              <p className={styles.comment}>{comment}</p>
            </section>
            {review_reply && review_reply.content ? (
              <section className={styles.replyComment}>
                <span className={styles.replyTitle}>{t('Your Reply')}</span>
                <p className={styles.replyContent}>{review_reply.content}</p>
              </section>
            ) : (
              <TappedButton
                isRounded
                leftIcon="envelope"
                iconColor="apple-green"
                textColor="text-light-gray"
                className={styles.replyButton}
                event={{
                  screen_name: 'home',
                  section_name: 'recent_review',
                  tapped_on: 'btn_reply'
                }}
              >
                {t('Reply')}
              </TappedButton>
            )}
          </section>
        );
      })}
      {!!combinedReview.length && (
        <TappedButton
          type="ghost"
          className={styles.lastReviewSlider}
          onClick={() => props.navigate(routes.reviews)}
          event={{
            screen_name: 'home',
            section_name: 'recent_review',
            tapped_on: 'btn_reviews_all'
          }}
        >
          <span>
            {Array(3)
              .fill('home-star')
              .map((icon, index) => (
                <Icon key={index} icon={icon} size="2rem" color="primary" />
              ))}
          </span>
          <p className={styles.viewAll}>
            {t('View all reviews ')}
            <Icon icon="arrow-right" />
          </p>
        </TappedButton>
      )}
    </DashboardCard>
  );

  const RenderRevenueAnalyticsSection = ({
    user,
    todayRevenue,
    yesterdayRevenue
  }) => (
    <div className={styles.revenueSection}>
      <div>
        <span>
          {t('Today ({{currency}})', {
            currency: user.city && user.city.currency_display
          })}
        </span>
        <p>{todayRevenue}</p>
      </div>
      <div>
        <span>
          {t('Yesterday ({{currency}})', {
            currency: user.city && user.city.currency_display
          })}
        </span>
        <p>{yesterdayRevenue}</p>
      </div>
      <TappedButton
        textColor="pelorous"
        type="ghost"
        justify="flex-end"
        iconColor="pelorous"
        onClick={() =>
          window.open(
            isMaple
              ? `/insights/coming-soon`
              : `/insights/${COUNTRIES[user.city.slug].toLowerCase()}/${
                  currentCompany.id
                }` + (currentOutlet ? `?outletId=${currentOutlet.id}` : ''),
            '_blank'
          )
        }
        rightIcon="angle-right"
        event={{
          screen_name: 'home',
          tapped_on: 'btn_insights'
        }}
      >
        {t('View Insights')}
      </TappedButton>
    </div>
  );

  const RenderSetupServiceSection = () => (
    <div className={styles.revenueSectionEmpty}>
      <p className={styles.revenueSectionEmptyTitle}>
        {t('You have no transactions yet.')}
      </p>
      <TappedButton
        type="primary"
        size="lg"
        className={styles.revenueSectionEmptyButton}
        onClick={() => navigate(routes.moreServices)}
        event={{
          screen_name: 'home',
          tapped_on: 'btn_moreservices'
        }}
      >
        {t('Set up services now')}
      </TappedButton>
    </div>
  );

  const closeNewDashboardBanner = () => {
    document.getElementById('root').classList.remove(styles.rootNoOverflow);
    setShowOnceNewDashboardBanner(true);
    Cookie.set(newDashboardBannerCookie, true, {
      maxAge: 60 * 60 * 24 * 365 * 10
    });
  };

  const beginProductTour = () => {
    document.getElementById('root').classList.add(styles.rootNoOverflow);
    const sequentialSteps = () =>
      new Promise(resolve => {
        resolve(setSideBarVisible(true));
      }).then(() => {
        setStartProductTour(true);
      });
    sequentialSteps();
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.HOME,
      section_name: CLEVERTAP_SECTION_NAME.EDUCATION_BANNER,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_SHOW_ME_AROUND
    });
  };

  const NewDashboardBanner = () => (
    <>
      <div className={styles.newDashboardBanner}>
        <div className={styles.content}>
          <div className={styles.textAndButton}>
            <div className={styles.textContent}>
              <div>{t('Hey there!')}</div>
              <div>
                {t('Welcome to your dashboard, would you like a tour?')}
              </div>
            </div>
            <div>
              <Button onClick={() => beginProductTour()} type={'primary'}>
                {t('Show me around')}
              </Button>
            </div>
          </div>
          <div>
            <img
              onClick={() => closeNewDashboardBanner()}
              className={styles.closeNewDashboardBanner}
              src={CloseBannerIcon}
              alt="close new dashboard banner"
            />
          </div>
        </div>
      </div>
    </>
  );

  const productTourSteps = [
    {
      target: '.sales-navigation-button',
      title: t('All about the numbers!'),
      content: t(
        'Check out your revenue, transactions and get your reports and statements all in one place.'
      ),
      disableBeacon: true,
      placement: 'right',
      tapped_on: 'btn_next',
      disabled: false
    },
    {
      target: '.customer-tools-navigation-button',
      title: t('Engage with your customers'),
      content: t(
        'Reply to customer reviews or create Feed posts to reach out to your customers.'
      ),
      disableBeacon: true,
      placement: 'right',
      tapped_on: 'btn_next',
      disabled: isMaple
    },
    {
      target: '.support-navigation-button',
      title: t('Get help if you need'),
      content: t(
        'Chat with our staff if you have any questions about FaveBiz.'
      ),
      disableBeacon: true,
      placement: 'right',
      tapped_on: 'btn_next',
      differentSection: true,
      additionalFunction: () => setShowTopRightDropDown(true),
      disabled: false
    },
    {
      target: '.switch-staff-navigation-button',
      title: t('Staff mode for your employees'),
      content: t(
        'A limited version of FaveBiz for your staff to check transactions and perform necessary daily operations.'
      ),
      disableBeacon: true,
      placement: 'left',
      tapped_on: 'btn_done',
      additionalFunction: () => closeNewDashboardBanner(),
      disabled: false
    }
  ];

  const productTourStepCallbackFunction = stepsData => {
    const { index, type } = stepsData;
    const stepNumberCloseSidebar = 2;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (index === stepNumberCloseSidebar) setSideBarVisible(false);
    }
  };

  return (
    <AppLayout
      sideBarVisibleDefaultValue={sideBarVisible}
      showTopRightDropDown={showTopRightDropDown}
      setProductTourCookie={setShowOnceNewDashboardBanner}
    >
      <Heading
        title={t('Home')}
        subtitle={t(
          isMaple
            ? 'View your sales and outlets at Fave here'
            : 'View all your sales, reviews and latest updates from Fave here'
        )}
      />
      {!showOnceNewDashboardBanner && !isMaple && <NewDashboardBanner />}
      <section className={styles.dashboardGrid}>
        <DashboardCard
          title={t('Sales Summary')}
          iconTitle="briefcase"
          buttonText={isOnboardingActivated ? 'View All' : undefined}
          buttonIcon="angle-right"
          buttonLink="/summary"
          className={styles.revenue}
        >
          {isOnboardingActivated ? (
            <RenderSetupServiceSection />
          ) : (
            <RenderRevenueAnalyticsSection
              user={user}
              todayRevenue={todayRevenue}
              yesterdayRevenue={yesterdayRevenue}
            />
          )}
        </DashboardCard>
        {!isMaple && (
          <DashboardCard
            title={t("What's new?")}
            iconTitle="new"
            className={styles.whatsNew}
            isSlider
            sliderProps={{
              slidesToShow: 1.1,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: RESPONSIVE_BREAKPOINTS.md,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: RESPONSIVE_BREAKPOINTS.sm,
                  settings: {
                    initialSlide: 0,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
            }}
            sliderRef={whatsNewSliderRef}
          >
            {banners?.map(banner => (
              <Banner
                key={banner.title}
                title={banner.title}
                image={banner.image_url}
                link={banner.web_url}
              />
            ))}
          </DashboardCard>
        )}
        <OutletsSection isMY={isMY} />
        {!isMaple && <RenderRecentReview />}
        {startProductTour && (
          <ProductTour
            steps={productTourSteps.filter(step => step.disabled === false)}
            callback={productTourStepCallbackFunction}
            totalSteps={
              productTourSteps.filter(step => step.disabled === false).length
            }
            cleverTapEvents={{
              screen_name: CLEVERTAP_SCREEN_NAME.HOME_EDUCATION
            }}
          />
        )}
      </section>
    </AppLayout>
  );
};

export default DashboardView;

const OutletsSection = ({ isMY }) => {
  const { t } = useTranslation();
  const { outlets } = useContext(OutletContext);
  const { isMaple } = useContext(MapleContext);
  return (
    <DashboardCard
      title={t('Outlets')}
      iconTitle="dashboard-outlet"
      topChildren={outlets.length > 1 && t('Sorted by: A – Z')}
      className={styles.outletsSection}
    >
      <div data-layout>
        {sortBy(outlets, ['name']).map(outlet => (
          <div key={outlet.id} data-outlet>
            <div data-address>
              {isMaple ? (
                <span>
                  <div>{outlet.name}</div>
                  {outlet.neighbourhood_slug ? (
                    <div className={styles.neighbourhoodSlug}>
                      | {outlet.neighbourhood_slug}
                    </div>
                  ) : (
                    <></>
                  )}
                </span>
              ) : (
                <span>{outlet.name}</span>
              )}
              <Button
                textColor="pelorous"
                type="ghost"
                justify="flex-end"
                iconColor="pelorous"
                onClick={() => navigate(`/outlets/${outlet.id}`)}
                rightIcon="angle-right"
                children={t('View Details')}
              />
            </div>
            {(isMY ? outlet.can_receive_payment : outlet.has_fave_payment) && (
              <div data-cta>
                <PrimaryInvertedButton
                  size="md"
                  onClick={() => navigate(`/payment/${outlet.id}`)}
                  children={t('Receive Payment')}
                  event={{
                    screen_name: 'home',
                    tapped_on: 'btn_outlet_receive_payment'
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </DashboardCard>
  );
};
