import React, { useState, useEffect, useCallback } from 'react';

import styles from 'insights/assets/css/ScrollProgressBar.module.scss';

const ScrollProgressBar = props => {
  const [scrollPercent, setScrollPercent] = useState('');

  const listenToScroll = useCallback(
    () =>
      document.addEventListener('scroll', () =>
        requestAnimationFrame(() => setScrollPercent(calculateScrollDistance()))
      ),
    []
  );
  const calculateScrollDistance = () => {
    var limit =
      Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      ) - window.innerHeight;

    return (window.pageYOffset / limit) * 100;
  };

  useEffect(() => {
    listenToScroll();
  }, [listenToScroll]);
  return (
    <div className={styles.ProgressBarContainer}>
      <div
        className={styles.scrollBar}
        style={{ '--width': `${scrollPercent}%` }}
      ></div>
    </div>
  );
};

export default ScrollProgressBar;
