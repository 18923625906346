import React, { useState, useContext, useEffect } from 'react';

import useHubSpotTrack from 'hooks/useHubSpotTrack';
import LoginLayout from 'layouts/LoginLayout';
import { NotificationContext } from 'contexts/NotificationContext';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { Formik, Form } from 'formik';
import Select from 'components/Select';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import * as Yup from 'yup';
import classNames from 'classnames';
import Icon from 'components/Icon';
import requestOTP from 'commands/requestOTP';
import Cookie from 'helpers/Cookie';
import { navigate } from '@reach/router';
import getCategories from 'queries/getCategories';
import { useTranslation, Trans } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'assets/css/SignUpFormView.module.scss';

const TNC = {
  my: '/MY_Fave_Merchant_Agreement.pdf',
  sg: '/SG_Fave_Merchant_Agreement.pdf'
};

const countryCodes = [
  { code: '+60', text: 'MY +60' },
  { code: '+65', text: 'SG +65' }
];

const SINGAPORE = countryCodes[1].code;

const FOOD_AND_BEVERAGES_CODE = '1';

const initFormData = Cookie.get('sign_up_data') || {};

const SignUpFormView = ({ location }) => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  useHubSpotTrack(location.pathname);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'signup' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  const LogInSchema = Yup.object().shape({
    method: Yup.string().required(t('Required')),
    phone: Yup.string().when('method', {
      is: 'phone',
      then: Yup.string()
        .required(t('Phone number incomplete'))
        .when('code', {
          is: SINGAPORE,
          then: Yup.string().matches(/\d{8,}$/, t('Minimum 8 digits')),
          otherwise: Yup.string().matches(/\d{9,}$/, t('Minimum 9 digits'))
        })
    }),
    email: Yup.string().when('method', {
      is: 'email',
      then: Yup.string()
        .email(t('Invalid email'))
        .required(t('Email required'))
    }),
    businessName: Yup.string().required(t('Business Name required')),
    businessType: Yup.string().test(
      'businessType',
      t('Select a type'),
      value => {
        return value !== 'default' && value !== undefined;
      }
    )
  });

  const EmailForm = ({ errors, touched }) => (
    <>
      <label htmlFor="email">
        <TextInput
          type="email"
          name="email"
          placeholder={t('Enter email address*')}
          className={styles.telNumber}
          focus
          {...{ errors, touched }}
        />
      </label>
      <label htmlFor="businessName">
        <TextInput
          type="text"
          name="businessName"
          placeholder={t('Enter your business name*')}
          className={styles.telNumber}
          {...{ errors, touched }}
        />
      </label>
    </>
  );
  const MobileForm = ({ errors, touched }) => (
    <>
      <label htmlFor="phone" className={styles.telWrapper}>
        <Select name="code" items={countryCodes} className={styles.telCode} />
        <TextInput
          type="tel"
          name="phone"
          placeholder={t('Enter your phone number*')}
          className={styles.telNumber}
          focus
          {...{ errors, touched }}
        />
      </label>
      <label htmlFor="businessName">
        <TextInput
          type="text"
          name="businessName"
          placeholder={t('Enter your business name*')}
          className={styles.telNumber}
          {...{ errors, touched }}
        />
      </label>
    </>
  );

  const currentQueryString = useContext(QueryStringContext);
  const [formToggle, setFormToggle] = useState(
    currentQueryString.method || 'email'
  );
  const [categoryState, setCategoryState] = useState([
    {
      code: 'default',
      text: t('Select your business type*')
    }
  ]);

  const [inviteCodeToggle, setInviteCodeToggle] = useState(false);
  const isLoginMethodPhone = formToggle === 'phone';
  const { setNotification } = useContext(NotificationContext);
  useEffect(() => {
    getCategories({
      city: currentQueryString.country === 'my' ? 'kuala-lumpur' : 'singapore'
    }).then(response => {
      if (categoryState.length < response.categories.length) {
        setCategoryState([
          ...categoryState,
          ...response.categories.map(({ id, name }) => ({
            code: `${id}`,
            text: name
          }))
        ]);
      }
    });
  }, [currentQueryString, categoryState]);
  const submitForm = (values, formikBag) => {
    values.phone =
      values.phone[0] === '0' ? values.phone.slice(1) : values.phone;
    const submitFormBody = {
      method: formToggle,
      value: isLoginMethodPhone
        ? `${values.code}${values.phone}`
        : values.email,
      company_name: values.businessName,
      route: 'sign_up',
      city: currentQueryString.country === 'my' ? 'kuala-lumpur' : 'singapore',
      category_id: values.businessType,
      invite_code: values.inviteCode
    };

    Cookie.set('sign_up_data', { ...submitFormBody });

    // to navigate to mcc questionnaire if user is singaporean and businessType is food
    if (
      currentQueryString.country === 'sg' &&
      values.businessType === FOOD_AND_BEVERAGES_CODE
    ) {
      navigate(`/sign-up/mcc?country=${currentQueryString.country}`);
    } else {
      requestOTP(submitFormBody)
        .then(response => {
          Cookie.set('sign_up_data', {
            ...submitFormBody,
            ...response
          });
          navigate('/verify_pin');
        })
        .catch(error => {
          if ('value' in error) {
            const { value } = error;
            setNotification({ message: value.response.error, type: 'error' });
          } else {
            setNotification({ message: error.toString(), type: 'error' });
          }
        })
        .finally(() => formikBag.setSubmitting(false));
    }
  };

  const LoginMethodRadio = ({ name, onChange }) => {
    const isRadioPhone = name === 'phone';
    const isActive = isLoginMethodPhone === isRadioPhone;
    return (
      <label
        htmlFor={name}
        className={classNames(
          styles.radioLabel,
          isActive ? styles.active : styles.inactive
        )}
      >
        <input
          type="radio"
          id={name}
          name="method"
          onChange={onChange}
          checked={isActive}
        />
        <Icon
          icon={isRadioPhone ? 'mobile-phone' : 'envelope'}
          color={isActive ? 'apple-green' : 'gray'}
          size="2.125rem"
        />
        {t('Sign up with {{phoneOrEmail}}', {
          phoneOrEmail: isRadioPhone ? 'number' : 'email'
        })}
      </label>
    );
  };
  const LoginMethodTabs = ({ onChange }) => (
    <section className={styles.loginMethod}>
      <LoginMethodRadio name="email" onChange={onChange} />
      <LoginMethodRadio name="phone" onChange={onChange} />
    </section>
  );

  return (
    <LoginLayout withFooter={false}>
      <Button
        leftIcon="angle-left"
        href={`/sign-up/contract?country=${currentQueryString.country}`}
        type="text"
        textColor="charcoal-gray"
        children={t('Back')}
      />
      <Formik
        initialValues={{
          phone: initFormData.method === 'phone' ? initFormData.value : '',
          email: initFormData.method === 'email' ? initFormData.value : '',
          businessName: (initFormData && initFormData.company_name) || '',
          method: formToggle,
          businessType: categoryState.text,
          code:
            currentQueryString.country === 'my'
              ? countryCodes[0].code
              : countryCodes[1].code
        }}
        enableReinitialize
        validationSchema={LogInSchema}
        validateOnBlur={false}
        onSubmit={submitForm}
        onBlur={() => {}}
      >
        {({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
          <Form className={styles.form}>
            <LoginMethodTabs
              onChange={({ target }) => {
                if ('checked' in target && target.checked) {
                  resetForm();
                  target.id === 'phone'
                    ? cleverTap.tapped.push({
                        screen_name: 'signup',
                        tapped_on: 'btn_signup_number'
                      })
                    : cleverTap.tapped.push({
                        screen_name: 'signup',
                        tapped_on: 'btn_signup_email'
                      });
                  setFormToggle(target.id);
                  setFieldValue('method', target.id);
                }
              }}
            />
            {formToggle === 'email' ? (
              <EmailForm {...{ errors, touched }} />
            ) : (
              <MobileForm {...{ errors, touched }} />
            )}
            <label htmlFor="businessType">
              <Select
                name="businessType"
                items={categoryState}
                className={styles.telCode}
                {...{ errors, touched }}
              />
            </label>
            {!inviteCodeToggle ? (
              <div className={styles.inviteCode}>
                {t('Have an invite code?')}
                <Button
                  type="text"
                  textColor="blue"
                  size="xs"
                  onClick={() => {
                    cleverTap.tapped.push({
                      screen_name: 'signup',
                      tapped_on: 'btn_invite_code'
                    });
                    setInviteCodeToggle(true);
                  }}
                >
                  {t('Enter it now.')}
                </Button>
              </div>
            ) : (
              <label htmlFor="inviteCode">
                <TextInput
                  type="text"
                  name="inviteCode"
                  placeholder={t('Enter your invite code')}
                  className={styles.telNumber}
                  focus
                  {...{ errors, touched }}
                />
              </label>
            )}
            <div className={styles.footerSection}>
              <Button
                type="submit"
                size="lg"
                rightIcon="arrow-right"
                fullWidth
                justify="space-between"
                onClick={() =>
                  cleverTap.tapped.push({
                    screen_name: 'signup',
                    tapped_on: 'btn_continue'
                  })
                }
                disabled={isSubmitting}
              >
                {t('Next')}
              </Button>
              <Trans>
                <small className={styles.tnc}>
                  By signing up, I agree to{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={TNC[currentQueryString.country]}
                    onClick={() =>
                      cleverTap.tapped.push({
                        screen_name: 'signup',
                        tapped_on: 'btn_terms'
                      })
                    }
                  >
                    Fave's Terms and Conditions
                  </a>
                </small>
              </Trans>
            </div>
          </Form>
        )}
      </Formik>
    </LoginLayout>
  );
};

export default SignUpFormView;
