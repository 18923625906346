import Kfit, { getStagingInfo } from './Kfit';

const { isProduction, stagingIdentifier } = getStagingInfo();

class MapleBizAPI extends Kfit {
  static ENDPOINTS() {
    return {
      consumer: isProduction
        ? 'REACT_APP_KFIT_CONSUMER_API'
        : `REACT_APP${stagingIdentifier}_KFIT_CONSUMER_API`,
      partner: isProduction
        ? 'REACT_APP_MAPLEBIZ_PARTNER_API'
        : `REACT_APP${stagingIdentifier}_MAPLEBIZ_PARTNER_API`
    };
  }
}

export default MapleBizAPI;
