import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './common/ModalContainer';
import Button from 'components/Button';

import styles from 'assets/css/NoInternetModal.module.scss';

type NoInternetModalProps = {
  onClose: () => void;
};

function NoInternetModal({ onClose }: NoInternetModalProps) {
  const { t } = useTranslation();

  return (
    <ModalContainer>
      <p className={styles.title}>
        {t('Uh oh...')}
      </p>
      <p className={styles.content}>
        {t('Something went wrong. Please check your internet connection and try again.')}
      </p>
      <div className={styles.buttonPane}>
        <Button
          type="primary"
          onClick={() => {
            onClose();
          }}
          justify="center"
          size={'lg'}>

          {t('Try again')}
        </Button>
      </div>
    </ModalContainer>
  );
}

export default NoInternetModal;
