import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useOutletContext from 'hooks/useOutletContext';
import { FCMContext } from 'contexts/FCMContext';
import Button from 'components/Button';
import ToggleButton from 'components/ToggleButton';
import LoadingSpinner from 'components/LoadingSpinner';
import styles from 'assets/css/OpsNotifications.module.scss';
import useCleverTapOld from 'hooks/useCleverTapOld';

export default ({ className }: { className: string }) => {
  const { t } = useTranslation();
  const clevertap = useCleverTapOld();
  const { subscriptions, toggleSubscription, isGettingToken } = useContext(
    FCMContext
  );
  const { currentOutlet } = useOutletContext();
  const [isToggle, setIsToggle] = useState(false);

  useEffect(() => {
    if (subscriptions.length === 0) return;
    setIsToggle(
      subscriptions.find((s: string) => s.includes(currentOutlet.id))
    );
  }, [currentOutlet, subscriptions]);

  return (
    <Button
      leftIcon="notification"
      type="text"
      size="xs"
      className={className}
      iconColor="charcoal-gray"
      onClick={e => e.nativeEvent.stopImmediatePropagation()}
    >
      <span className={styles.dropdownItemSpacer}>
        {t('Push notifications')}
      </span>
      {isGettingToken ? (
        <section className={styles.loadingSpinner}>
          <LoadingSpinner baseSize="1px" />
        </section>
      ) : (
        <ToggleButton
          id={`pushNotifications_${currentOutlet.id}`}
          checked={isToggle}
          onToggle={isEnabled => {
            clevertap.tapped.push({
              screen_name: 'profile',
              section_name: 'profile',
              tapped_on: 'btn_push_notification'
            });
            setIsToggle(isEnabled);
            toggleSubscription({
              outlet: currentOutlet,
              subscribed: isEnabled
            });
          }}
        />
      )}
    </Button>
  );
};
