import React, { useContext, useState, useEffect, CSSProperties } from 'react';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { ModalContext } from 'contexts/ModalContext';
import styles from '../assets/css/AutoExtendDealCard.module.scss';
import classNames from 'classnames';
import AutoExtensionDeactivatedModal from 'components/AutoExtensionDeactivatedModal';
import getDealDeactivationReasons from 'queries/getDealDeactivationReasons';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { OutletContext } from 'contexts/OutletContext';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
  CLEVERTAP_ADDITIONAL
} from 'helpers/TypeConstants';

type AutoExtendDealCardProps = {
  id: number;
  name: string;
  purchaseCount: number;
  imageUrl: string;
  originalPrice: string;
  discountedPrice: string;
  deactivateVisible: boolean;
  isActive: boolean;
  handleDeactivation: (
    id: number,
    deactivation_reasons: any,
    onSuccess: () => void,
    finishSubmit: () => void
  ) => void;
};

type Reason = {
  code: string;
  text: string;
};

const AutoExtendDealCard: React.FC<AutoExtendDealCardProps> = ({
    id,
    name,
    purchaseCount,
    imageUrl,
    originalPrice,
    discountedPrice,
    deactivateVisible,
    isActive,
    handleDeactivation
  }) => {
  const { t } = useTranslation();
  const { init } = useContext(ModalContext);
  const cleverTap = useCleverTapOld();
  const outletContext = useContext(OutletContext);
  const [reasonsData, setReasonsData] = useState<Array<Reason>>([]);
 
  const onClose = () => {
  }

  useEffect(() => {
    let isMounted = true;

    getDealDeactivationReasons().then(response => {
      if (!isMounted) return;
      setReasonsData(response.reasons || []);
    });

    return () => {(isMounted = false)};
  }, []);

  const renderDeactivatedModal = () => {
    init({
      Component: AutoExtensionDeactivatedModal,
      openOnReady: true,
      hasCloseButton: false,
      componentProps: {
        type: 'deal',
        id: id,
        reasonsList: reasonsData,
        handleDeactivation: handleDeactivation,
        onClose: onClose,
        outletContext: outletContext
      }
    });
  };

  return (
    <div 
      className={classNames(styles.Container, !isActive && styles.inactive) }
    >
      <div className={styles.image}
        style={
          {
            'backgroundImage': `url(${imageUrl})`
          } as CSSProperties
        }
      ></div>
      <div className={styles.content}>
        <div className={styles.dealID}>DEAL ID: {id}</div>
        <div className={styles.name}>{name}</div>
        <div className={styles.price}><span className={styles.originalPrice}>{originalPrice}</span> {discountedPrice}</div>
        <div>
          <div className={styles.soldBox}>{purchaseCount} Deals sold</div>
        </div>
      </div>
      { deactivateVisible && isActive && (
        <div className={styles.action}>
          <Button
            fullWidth
            size="lg"
            className={styles.deactivate}
            onClick={() => {
              cleverTap.tapped.push({ 
                screen_name: CLEVERTAP_SCREEN_NAME.AUTO_EXTENSION,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_DEACTIVATION,
                offers_type: CLEVERTAP_ADDITIONAL.TYPE_DEALS
              });
              renderDeactivatedModal();
              }}
          >
            {t('Deactivate')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AutoExtendDealCard;
