import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import FormikError, { FormikErrorProps } from 'components/FormikError';
import styles from 'assets/css/TextArea.module.scss';

type TextAreaProps = {
  name: string /* required by Formik */;
  className: string;
  errors: FormikErrorProps['errors'];
  touched: FormikErrorProps['touched'];
};

function TextArea({
  name,
  errors,
  touched,
  className,
  ...restProps
}: TextAreaProps) {
  return (
    <label htmlFor={name} className={classNames(styles.label, className)}>
      <Field name={name} component="textarea" rows="5" {...restProps} />
      <FormikError {...{ name, errors, touched }} />
    </label>
  );
}

export default TextArea;
