import React, { useState, useEffect, useContext } from 'react';
import Cookie from 'helpers/Cookie';
import getOpsSettings from 'queries/getOpsSettings';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { CleverTapContextOld } from 'contexts/CleverTapContextOld';
import verifyUser from 'queries/verifyUser';
import { hasKeys, isPromise } from 'utils';
import { COUNTRIES } from 'helpers/TypeConstants';

const OpsAuthContext = React.createContext();

const OpsAuthContextProvider = props => {
  const [settings, setSettings] = useState({});
  const [user, setUser] = useState({});
  const { cleverTapProfile } = useContext(CleverTapContextOld);

  const {
    authCode: qsAuthCode,
    token: qsAccessToken,
    old,
    outlet,
    company
  } = useContext(QueryStringContext);

  const isValidUser = hasKeys(user);
  const authCode = qsAuthCode || Cookie.get('authCode');
  const accessToken = qsAccessToken || Cookie.get('accessToken');
  const isOperations = !!authCode;
  const isManager = !!accessToken;

  const isIndonesia =
    user && user.city && COUNTRIES[user.city.slug] === 'Indonesia';

  useEffect(() => {
    if (!settings) return;
    isManager
      ? cleverTapProfile.push(user)
      : cleverTapProfile.push(settings, true);
  }, [settings, user, cleverTapProfile, isManager]);

  const authenticateOps = code => {
    return getOpsSettings(code)
      .then(res => {
        Cookie.set('authCode', code);
        setSettings(res.settings);
        return true;
      })
      .catch(err => {
        Cookie.remove('authCode');
        window.location.assign('/');
        return false;
      });
  };

  const authenticate = () => {
    //Necessary for old apps not having authCode in the query params - for /transactions to /operations/transactions
    if (old) {
      return verifyUser(
        '/v1/company_managers/current_manager',
        accessToken
      ).then(user => {
        let code = user.companies
          .find(c => c.id === company)
          .outlets.find(o => o.id === outlet).auth_code;

        Cookie.set('accessToken', accessToken);
        setUser(user);
        return authenticateOps(code);
      });
    } else if (isManager) {
      return verifyUser('/v1/company_managers/current_manager', accessToken)
        .then(user => {
          Cookie.set('accessToken', accessToken);
          setUser(user);
          return authenticateOps(authCode);
        })
        .catch(err => {
          Cookie.remove('accessToken');
          return authenticateOps(authCode);
        });
    } else {
      return authenticateOps(authCode);
    }
  };

  useEffect(() => {
    if (isManager) return;
    const user = {
      companies: settings.profile
        ? [
            {
              id: settings.profile.company_id,
              partner_name: settings.profile.company_name,
              outlets: settings.outlet
                ? [
                    {
                      ...settings.outlet,
                      auth_code: authCode
                    }
                  ]
                : []
            }
          ]
        : [],
      city: {
        currency_display: (settings.currency || {}).display,
        slug: settings.city_slug
      }
    };
    setUser(user);
  }, [isManager, settings, authCode]);

  //TODO call clevertap profile ppush here after handling staff tracking

  const ensureAuthenticated = () =>
    Object.keys(settings).length > 0 || ((authCode || old) && authenticate());

  const unauthenticateOps = callback => {
    if (callback) callback();
    Cookie.remove('authCode');
    setSettings({});
    window.location.assign('/');
  };

  const authenticatingOps = ensureAuthenticated();

  const implementation = {
    isValidUser,
    isOperations,
    isManager,
    isIndonesia,
    user,
    isValidOps: authenticatingOps === true,
    settings,
    unauthenticateOps,
    isAuthenticatingOps: isPromise(authenticatingOps)
  };

  return (
    <OpsAuthContext.Provider value={implementation}>
      {props.children}
    </OpsAuthContext.Provider>
  );
};

const OpsAuthContextConsumer = OpsAuthContext.Consumer;

export { OpsAuthContext, OpsAuthContextProvider, OpsAuthContextConsumer };
