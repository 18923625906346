import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { NotificationContext } from 'contexts/NotificationContext';

import requestOTP from 'commands/requestOTP';
import Cookie from 'helpers/Cookie';

import LoginLayout from 'layouts/LoginLayout';
import Button from 'components/Button';
import AlcoholIcon from 'assets/images/mcc/mcc-alcohol.svg';
import FeeIcon from 'assets/images/mcc/mcc-fees.svg';
import EndIcon from 'assets/images/mcc/mcc-end.svg';
import PayIcon from 'assets/images/mcc/mcc-pay.svg';
import styles from 'assets/css/SignUpMCCView.module.scss';

const SignUpMCCView = () => {
  const { t } = useTranslation();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [prevQuestion, setPrevQuestion] = useState();
  const [isMCC, setIsMCC] = useState(true);
  const currentQueryString = useContext(QueryStringContext);
  const { setNotification } = useContext(NotificationContext);

  const questions = [
    {
      icon: AlcoholIcon,
      title: 'Do you sell alcohol?',
      description: 'Anything from cocktails, beers, wines, liquors and others.'
    },
    {
      icon: PayIcon,
      title: 'Do customers pay after getting their food?',
      description:
        'Customers make payments, either at the cashier or on their devices after their food is served. (If you serve buffet, please click no.)'
    },
    {
      icon: FeeIcon,
      title: 'Do you charge a service fee?',
      description:
        'Not to be confused with GST, this is a service fee charged by your business.'
    },
    {
      icon: EndIcon,
      title: 'That’s it, thanks a lot!',
      description:
        'Answering the question(s) helps us better customise your experience with FaveBiz.'
    }
  ];

  const continueSignUp = () => {
    const signUpData = Cookie.get('sign_up_data');
    requestOTP(signUpData)
      .then(response => {
        Cookie.set('sign_up_data', {
          ...signUpData,
          ...response,
          mcc_eligibility: isMCC
        });
        navigate('/verify_pin');
      })
      .catch(error => {
        if ('value' in error) {
          const { value } = error;
          setNotification({ message: value.response.error, type: 'error' });
        } else {
          setNotification({ message: error.toString(), type: 'error' });
        }
      });
  };

  const Question = ({ icon, title, description }) => (
    <section className={styles.questionContainer}>
      <img src={icon} alt="Alcohol" />
      <h2>{title}</h2>
      <p>{description}</p>
      <div className={styles.buttonsContainer}>
        {currentQuestion === questions.length - 1 ? (
          <Button
            justify="center"
            fullWidth
            type="primary"
            onClick={continueSignUp}
          >
            Continue
          </Button>
        ) : (
          <>
            <Button
              justify="center"
              fullWidth
              type="primary"
              onClick={() => {
                setPrevQuestion(currentQuestion); // store last asked question if user says yes
                setIsMCC(false);
                setCurrentQuestion(questions.length - 1);
              }}
            >
              Yes
            </Button>

            <Button
              justify="center"
              fullWidth
              isRounded
              type="skeleton"
              onClick={() => {
                setIsMCC(true); // explicitly set to true to allow for reset if user has previously answered yes
                setCurrentQuestion(prevState => prevState + 1);
              }}
            >
              No
            </Button>
          </>
        )}
      </div>
    </section>
  );

  return (
    <LoginLayout withFooter={false}>
      <Button
        leftIcon="angle-left"
        onClick={() =>
          currentQuestion === 0 // if it's the first question, go back to sign up form
            ? navigate(`/sign-up/form?country=${currentQueryString.country}`)
            : // if user escaped flow in a yes, go back to last asked question
              setCurrentQuestion(prevState =>
                isMCC === false ? prevQuestion : prevState - 1
              )
        }
        type="text"
        textColor="charcoal-gray"
        children={t('Back')}
      />
      <Question {...questions[currentQuestion]} />
    </LoginLayout>
  );
};

export default SignUpMCCView;
