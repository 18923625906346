import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { subDays, startOfToday, endOfToday, startOfYesterday } from 'date-fns';

import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { QueryStringContext } from 'contexts/QueryStringContext';
import useCleverTapOld from 'hooks/useCleverTapOld';

import getDealDetails from 'queries/getDealDetails';
import getDealRevenue from 'queries/getDealRevenue';

import getRevenueParams from 'utils/getRevenueParams';
import { timezone } from 'utils/Dates';

import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import WebviewHeading from 'components/WebviewHeading';
import Button from 'components/Button';
import TappedButton from 'components/Button/TappedButton';
import Table from 'components/Table';
import Badge from 'components/Badge';
import DateRangePicker from 'components/DateRangePicker';
import LoadingSpinner from 'components/LoadingSpinner';
import GuaranteeFeeLabel from 'components/GuaranteeFeeLabel';

import { useTranslation } from 'react-i18next';

import styles from 'assets/css/DealRevenueView.module.scss';

const DealRevenueView = props => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { currentCompany, currentOutlet } = useContext(OutletContext);
  const { isWebview } = useContext(WebviewContext);
  const { from, date, type, customStartDate, customEndDate } = useContext(
    QueryStringContext
  );
  const [deal, setDeal] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(customStartDate) || moment()
  );
  const [endDate, setEndDate] = useState(moment(customEndDate) || moment());
  const today = timezone(endOfToday());
  const [filter, setFilter] = useState({
    date: date || today,
    type: type || 'all'
  });
  useEffect(() => {
    let isMounted = true;
    if (!props) return;

    getDealDetails({ id: props.deal_id }).then(res => {
      if (!isMounted) return;
      setDeal(res.voucher);
    });

    return () => (isMounted = false);
  }, [props]);

  useEffect(() => {
    let isMounted = true;

    if (!currentCompany || !deal) return;
    const allOutletParams = getRevenueParams({
      currentOutlet,
      user,
      startDate,
      endDate,
      filter,
      outlets: currentCompany.outlets,
      deal_ids: deal.id
    });

    getDealRevenue(allOutletParams)
      .then(res => {
        if (!isMounted) return;
        setTotalRevenue(res.total_revenue);
        setOutlets(res.outlets);
      })
      .catch(error => {});
    return () => (isMounted = false);
  }, [deal, currentCompany, currentOutlet, user, startDate, endDate, filter]);

  const dateFilters = [
    { text: t('Today'), value: today },
    { text: t('Yesterday'), value: startOfYesterday() },
    { text: t('Last 7'), value: subDays(startOfToday(), 7) },
    { text: t('Last 30'), value: subDays(startOfToday(), 30) },
    { text: t('Custom'), value: 'custom' }
  ];

  const onChangeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFilters = value => {
    let newFilter = { ...filter };
    if (value.date) {
      if (value.date === 'custom') {
        setOpenDateRange(true);
      } else {
        setOpenDateRange(false);
        setStartDate(moment());
        setEndDate(moment());
      }
      newFilter.date = value.date;
    }

    if (value.type) {
      newFilter.type = value.type;
    }
    setFilter(newFilter);
  };

  const formatDate = date =>
    new Date(date).toLocaleDateString('en-my', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  const CleverTap = useCleverTapOld();
  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    CleverTap.screenDisplayed.push({ screen_name: 'deals_revenue_breakdown' });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, CleverTap.screenDisplayed]);

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading
          title={t('Revenue breakdown')}
          backURL={from || '/deals'}
        />
      ) : (
        <Heading
          title={t('Revenue Breakdown')}
          subtitle={t('View your Deals performance according to each outlet.')}
        />
      )}
      {!isWebview && (
        <Button
          leftIcon="arrow-left"
          type="text"
          href={from || '/deals'}
          className="backToDealRoute"
          iconColor="light-black"
          children={t('Back')}
          textColor="charcoal-gray"
        />
      )}

      <header className={styles.dateFilter}>
        <p>{t('Select Date Range')}</p>
        {dateFilters.map(f => {
          const formattedDate =
            f.value === 'custom' ? 'custom' : timezone(f.value);
          const isCurrent = formattedDate === filter.date;
          return (
            <Badge
              key={f.value}
              checked={isCurrent}
              onChange={() => {
                handleFilters({ date: formattedDate });
              }}
            >
              {f.text}
            </Badge>
          );
        })}
        {openDateRange && (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDateRange}
            openDirection="down"
            isOutsideRange={day => day.isAfter(moment(), 'day')}
          />
        )}
      </header>

      {totalRevenue && outlets ? (
        <section>
          <Table>
            <thead>
              <tr>
                <th>{t('Outlets')}</th>
                <th>
                  {t('Revenue ({{currency}})', {
                    currency: user && user.city && user.city.currency_display
                  })}
                </th>
                <th>{t('Redemptions')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.totalRevenue}>
                {totalRevenue && (
                  <>
                    <td>{t('All outlets')}</td>
                    <td>{totalRevenue.revenue_amount || 0}</td>
                    <td>{totalRevenue.count || 0}</td>
                  </>
                )}
              </tr>
              {outlets &&
                outlets.map(outlet => (
                  <tr key={outlet.id}>
                    <td>{outlet.name}</td>
                    <td>{outlet.revenue_amount || 0}</td>
                    <td>{outlet.count || 0}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </section>
      ) : (
        <LoadingSpinner />
      )}

      {deal ? (
        <article className={styles.dealDetails}>
          <header>
            <h3>{deal.name}</h3>
            <h4>
              {t('Deal ID:')}
              {deal.id}
            </h4>
            <TappedButton
              type="text"
              size="xs"
              textColor="pelorous"
              iconSize="1rem"
              iconColor="pelorous"
              rightIcon="angle-right"
              href={`/deals/${deal.id}`}
              event={{
                screen_name: 'deals_revenue_breakdown',
                tapped_on: 'btn_details'
              }}
            >
              {t('Details')}
            </TappedButton>
          </header>
          <dl>
            <dt className={styles.date}>{t('Feature Date')}</dt>
            <dd>
              <time dateTime={deal.start_date}>
                {formatDate(deal.start_date)}
              </time>{' '}
              -{' \n'}
              <time dateTime={deal.end_date}>{formatDate(deal.end_date)}</time>
            </dd>
            <dt className={styles.price}>{t('Price')}</dt>
            <dd>
              {deal.discounted_price} <span>{deal.original_price}</span>
              <GuaranteeFeeLabel
                guarunteeFee={deal.guarantee_fee_percentage}
                isTransactionCard={false}
              />
            </dd>
          </dl>
          <img src={deal.cover_image_url} alt={deal.name} />
        </article>
      ) : (
        <LoadingSpinner />
      )}
    </AppLayout>
  );
};

export default DealRevenueView;
