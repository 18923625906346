import FaveBizAPI from 'integrations/FaveBizAPI';
export interface IAvailableOutlet {
  id: number;
  name: string;
  checked: boolean;
}

export const getAvailableOutlets = (param: {
  company_id: number;
  start_at: Date;
}): Promise<{ outlets: IAvailableOutlet[] }> =>
  FaveBizAPI.get(`/v1/feed/outlets`, param);
