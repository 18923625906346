import * as firebase from 'firebase/app';

const firebaseConfig = {
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  appId: process.env.REACT_APP_FCM_APP_ID,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  authDomain: `${process.env.REACT_APP_FCM_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FCM_PROJECT_ID}.firebaseio.com`,
  storageBucket: `${process.env.REACT_APP_FCM_PROJECT_ID}.appspot.com`
};

let app;

export const initializeFirebaseApp = () => {
  if (app) return;
  app = firebase.initializeApp(firebaseConfig);
};
