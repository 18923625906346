import { WebviewContext } from 'contexts/WebviewContext';
import { navigateUrlIfBrowser } from 'helpers/navigateURLIfBrowser';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import useCleverTapOld from 'hooks/useCleverTapOld';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { call, NATIVE } from 'utils/nativeInterface';
import InlineLink from './InlineLink';
import UserTipStrip from './UserTipStrip';

const PaymentSetupTip = () => {
  const cleverTap = useCleverTapOld();
  const { isWebview } = useContext(WebviewContext);

  const onClickPaymentSetup = () => {
    if (isWebview) {
      call(NATIVE.ADD_BUSINESS_INFO);
    }
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY,
      section_name: CLEVERTAP_SECTION_NAME.BANNER,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_PAYMENT_STEP_2_BUSINESS
    });
  };

  return (
    <UserTipStrip>
      <span>
        <Trans>
          {
            'You’re almost all set up! In order to get paid by Fave, complete your '
          }
          <InlineLink
            to={navigateUrlIfBrowser(isWebview, '/favepay/setup?step=2')}
            children={'bank and business details.'}
            onClick={onClickPaymentSetup}
          />
        </Trans>
      </span>
    </UserTipStrip>
  );
};

export default PaymentSetupTip;
