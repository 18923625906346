import React from 'react';
import { Field, FieldAttributes } from 'formik';
import classNames from 'classnames';
import styles from 'assets/css/LimitedTextInput.module.scss';

export type TextAreaInputProps = {
  name: string;
  className?: string;
  inputClassName?: string;
};

const TextAreaInput = ({
  name,
  className,
  inputClassName,
  ...rest
}: TextAreaInputProps | FieldAttributes<any>) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Field
        autoCorrect="off"
        spellCheck="false"
        className={classNames(styles.input, inputClassName)}
        name={name}
        as="textarea"
        {...rest}
      />
    </div>
  );
};

export default TextAreaInput;
