import React, { useContext, useEffect, useState } from 'react';
import { Router, Link, Redirect } from '@reach/router';
import { AuthContext } from 'contexts/AuthContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { MapleContext } from 'contexts/MapleContext';
import routes from 'helpers/routesMap';
import DashboardView from 'views/DashboardView';
import LoginView from 'views/LoginView';
import ManagerLoginView from 'views/ManagerLoginView';
import ContactUsView from 'views/ContactUsView';
import ReportView from 'views/ReportView';
import OutletsView from 'views/OutletsView';
import ResetPinView from 'views/ResetPinView';
import AuthenticateView from 'views/AuthenticateView';
import AnnouncementView from 'views/AnnouncementView';
import AnnouncementFormView from 'views/AnnouncementFormView';
import FaveFeedView from 'views/FaveFeed/FaveFeedView';
import FaveFeedFormView from 'views/FaveFeed/FaveFeedFormView';
import CashbackView from 'views/CashbackView';
import RatingsView from 'views/RatingsView';
import ReceivePaymentView from 'views/ReceivePaymentView';
import OutletInfoView from 'views/OutletInfoView';
import DealsView from 'views/DealsView.jsx';
import DealDetailsView from 'views/DealDetailsView';
import DealRevenueView from 'views/DealRevenueView';
import FavePayView from 'views/FavePayView';
import FavePaySetupView from 'views/FavePaySetupView';
import BusinessInfoView from 'views/BusinessInfoView';
import StatementsView from 'views/StatementsView';
import OutletStatementsView from 'views/OutletStatementsView';
import SwitchBusinessView from 'views/SwitchBusinessView';
import SwitchStaffModeView from 'views/SwitchStaffModeView';
import SwitchLanguageView from 'views/SwitchLanguageView';
import MoreServicesView from 'views/MoreServicesView';
import SalesSummaryView from './views/SalesSummaryView';
import SignUpCountryView from 'views/SignUpCountryView';
import SignUpContractView from 'views/SignUpContractView';
import SignUpFormView from 'views/SignUpFormView';
import SignUpMCCView from 'views/SignUpMCCView';
import VerifyPinView from 'views/VerifyPinView';
import SetPinView from 'views/SetPinView';
import SetupDeals from 'views/SetupDealsView';
import CashbackSetupView from 'views/CashbackSetupView';
import OpsLoginView from 'views/OpsLoginView';
import PushNotifications from 'views/PushNotifications';
import ECardsSetupView from 'views/ECardsSetupView';
import getOpsSettings from 'queries/getOpsSettings';
import Cookie from 'helpers/Cookie';
import LoadingSpinner from 'components/LoadingSpinner';
import ECardsView from 'views/ECardsView';
import ECardsDetailsView from 'views/ECardsDetailsView';
import ECardsPendingView from 'views/ECardsPendingView';
import ECardsPendingDetailsView from 'views/ECardsPendingDetailsView';
import OfferDeactivateView from 'views/OfferDeactivateView';
import { OutletContext } from 'contexts/OutletContext';
import NewPostView from 'views/FaveFeed/NewPostView';
import PostDetailsView from 'views/FaveFeed/PostDetailsView';
import TransactionsView from 'views/TransactionsView';
import OffMapleTransactionDetailView from 'views/OffMapleTransactionDetailView';
import MapleTransactionDetailView from 'views/MapleTransactionDetailView';
import EmailNotifications from 'views/EmailNotificationsView';
import PDFFileEmbed from 'components/PDFFileEmbed';
import useChat from 'hooks/useChat';
import InvoicesView from 'views/invoices/InvoiceView';

const NotFound = ({ back }) => (
  <main>
    <h1>
      <span role="img" aria-label="No entry sign">
        🚫&nbsp;
      </span>
      Oops, this place does not exist...
    </h1>
    <Link to={back || '/'}>Click here to go back</Link>
  </main>
);

export default () => {
  const { isValidUser, isAuthenticatingUser, isIndonesia } = useContext(
    AuthContext
  );
  const { currentOutlet } = useContext(OutletContext);
  const authCode = Cookie.get('authCode');
  const [isStaffMode, setIsStaffMode] = useState(!!authCode);
  const { isWebview } = useContext(WebviewContext);
  const { isMaple } = useContext(MapleContext);
  const { showWidgetButton, hideWidgetButton } = useChat();

  if (isStaffMode) {
    getOpsSettings(authCode)
      .then(() => {
        if (!isWebview) {
          window.location.assign(
            `/operations/transactions?authCode=${authCode}`
          );
          return <></>;
        }
      })
      .catch(() => {
        Cookie.remove('authCode');
        setIsStaffMode(false);
      });
  }

  useEffect(() => {
    if (isValidUser && !isIndonesia) showWidgetButton();
    else hideWidgetButton();
  }, [isValidUser, isIndonesia, showWidgetButton, hideWidgetButton]);

  return (
    <Router>
      <PDFFileEmbed path={'*.pdf'} />
      {isStaffMode ? (
        <LoadingSpinner path="/" />
      ) : isValidUser || isAuthenticatingUser ? (
        <DashboardView path="/" />
      ) : (
        <LoginView path="/" />
      )}
      <ReportView path="reports" />
      {currentOutlet ? (
        <OutletStatementsView path="statements" />
      ) : (
        <>
          <StatementsView path="statements" />
          <OutletStatementsView path="statements/outlet/:finance_account_id" />
        </>
      )}
      <InvoicesView path="invoices" />
      <InvoicesView path="invoices/:finance_account_id" />
      <FavePayView path="favepay" />
      <OutletInfoView path="favepay/outlet/:id" />
      <FavePaySetupView path="favepay/setup" />
      <DealsView path="deals" />
      <DealDetailsView path="deals/:deal_id" />
      <DealRevenueView path="deals/:deal_id/revenue" />
      <SetupDeals path="deals/enable" />
      <MoreServicesView path={routes.moreServices} />
      <RatingsView path={routes.reviews} />
      {isMaple && (
        <>
          <AnnouncementView path="announcements" />
          <AnnouncementFormView path="announcements/:formId/edit" />
          <AnnouncementFormView path="announcements/create" />
        </>
      )}
      <FaveFeedFormView path="feed/create/*" />
      <FaveFeedFormView path="feed/edit/:post_id" />
      <FaveFeedView path="feed" />
      <NewPostView path="feed/new-post" />
      <PostDetailsView path="feed/post-details/:post_id" />
      <BusinessInfoView path="business-info" />
      <CashbackView path="cashback" />
      <CashbackSetupView path="cashback/setup" />
      <OutletsView path="outlets" />
      <OutletInfoView path="outlets/:id" />
      <ReceivePaymentView path="payment" />
      <ReceivePaymentView path="payment/:qr_id" />
      <ContactUsView path="contact-us" />
      <SalesSummaryView path={isMaple ? 'summary' : 'data'} />
      <ECardsSetupView path="ecards/create" />
      <SwitchBusinessView path="switch-business" />
      <SwitchStaffModeView path="switch-to-staff" />
      <SwitchLanguageView path="switch-language" />
      <ECardsView path="ecards" />
      <ECardsDetailsView path="ecards/:ecards_id" />
      <ECardsPendingView path="ecards/pending" />
      <ECardsPendingDetailsView path="ecards/pending/:ecards_id" />
      <OfferDeactivateView path="/offer/deactivate/ecards" />
      <OfferDeactivateView path="/offer/deactivate/deals" />
      <Redirect from="/login" to="/" noThrow />
      <AuthenticateView path="/authenticate" />
      <SignUpCountryView path="/sign-up" />
      <SignUpContractView path="/sign-up/contract" />
      <SignUpMCCView path="/sign-up/mcc" />
      <SignUpFormView path="/sign-up/form" />
      <ResetPinView path="/forgot_pin" />
      <VerifyPinView path="/verify_pin" />
      <SetPinView path="/set_pin" />
      <OpsLoginView path="/ops-login" />
      <ManagerLoginView path="/manager-login" />
      <ManagerLoginView path="/edm-manager-login" />
      <PushNotifications path="/push-notifications" />
      <TransactionsView path="/operations/transactions" />
      <MapleTransactionDetailView path="/operations/transactions/maple/:id" />
      <OffMapleTransactionDetailView path="/operations/transactions/off-maple/:id" />
      {isMaple && <EmailNotifications path="/email-notifications" />}
      <NotFound back="/" default />
    </Router>
  );
};
