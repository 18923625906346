import FaveBizAPI from 'integrations/FaveBizAPI';
import { IEmailNotificationOutlet } from 'queries/getEmailNotifications';
import qs from 'qs';

export const updateEmailNotification = (param: {
  flag: boolean,
  company_id: number,
  all?: boolean,
  outlet_id?: number
}): Promise<{ all_flag: boolean, outlets: IEmailNotificationOutlet[] }> =>
  FaveBizAPI.put(`/v1/email_notifications/update_settings${qs.stringify(param, {
    arrayFormat: 'brackets',
    addQueryPrefix: true,
    skipNulls: true
  })}`);
