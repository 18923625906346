import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';

export default function useAuthContext() {
  const { isOperations } = useContext(OpsAuthContext) || {};
  return {
    isOperations,
    user:
      (useContext(isOperations ? OpsAuthContext : AuthContext) || {}).user || {}
  };
}
