import FaveBizAPI from 'integrations/FaveBizAPI';
import MapleBizAPI from 'integrations/MapleBizAPI';
import { isAppMapleBiz } from 'contexts/MapleContext';

export default params =>
  isAppMapleBiz
    ? MapleBizAPI.put(`/v1/company_managers/reset_pin_number`, {
        ...params,
        phone: params.value
      }).then(res => res)
    : FaveBizAPI.put(`/v1/auth/reset_pin_number`, params).then(res => res);
