import React, { useEffect, useContext, useState, useCallback } from 'react';
import { navigate } from '@reach/router';
import qs from 'qs';

import { OutletContext } from 'contexts/OutletContext';
import {
  getAllReviews,
  getUnrepliedReviews,
  getFavepayReviews,
  getFavedealsReviews,
  getAverageRatings
} from 'queries/getReviews';
import {
  REVIEWS,
  ASC_RATING,
  DESC_RATING,
  CHRONOLOGICAL
} from 'helpers/TypeConstants';
import { queryParamsDecoder, hasKeys } from 'utils';
import AppLayout from 'layouts/AppLayout';
import UserReview, { handleSubmitReview } from 'components/UserReview';
import Heading from 'components/Heading';
import Card from 'components/Card';
import Badge from 'components/Badge';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

import styles from 'assets/css/RatingsView.module.scss';
import { WebviewContext } from 'contexts/WebviewContext';
import ViewEditUserReview from 'components/ViewEditUserReview';
import WebviewHeading from 'components/WebviewHeading';
import Icon from 'components/Icon';
import { call, NATIVE } from 'utils/nativeInterface';
import useWebCallback from 'hooks/useWebCallback';
import useOnceCallback from 'hooks/useOnceCallback';
import useCleverTapOld from 'hooks/useCleverTapOld';

const screenName = 'reviews';

const HIGH_RATING = {
  number: {
    low: 3.5,
    high: 5
  },
  color: '--apple-green'
};

const MEDIUM_RATING = {
  number: {
    low: 2.5,
    high: 3.4
  },
  color: '--tangerine-orange'
};

const LOW_RATING = {
  number: {
    low: 0,
    high: 2.4
  },
  color: '--sunset-red'
};

const DEFAULT_RATING_COLOR = '--black';

const INITIAL_STATE = {
  pageNumber: 1,
  reviewType: REVIEWS.ALL,
  sortBy: CHRONOLOGICAL
};

const SortButton = ({ onClick }) => (
  <Icon
    className={styles.sortButton}
    onClick={onClick}
    icon={'sliders'}
    color={'bali-hai'}
    size={'1.5rem'}
  />
);

const RatingsView = ({ location }) => {
  const { t } = useTranslation();
  const { currentCompany } = useContext(OutletContext);
  const { isWebview } = useContext(WebviewContext);
  const cleverTap = useCleverTapOld();

  // Trigger clevertap upon landing page
  useOnceCallback(() =>
    cleverTap.screenDisplayed.push({ screen_name: screenName })
  );

  // Get the latest query params upon loading the page
  const currentQueryString = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    decoder: queryParamsDecoder
  });

  const [reviews, setReviews] = useState({});
  const [locationParams, setLocationParams] = useState({ targetScroll: null });
  const [companyAverageRating, setCompanyAverageRating] = useState();
  const [viewEditReview, setViewEditReview] = useState();

  // Parse query params and set as initial state, if not use default value
  const [urlQueryState, setUrlQueryState] = useState(
    hasKeys(currentQueryString) ? currentQueryString : INITIAL_STATE
  );

  // Effect for each time urlQueryState changes, URLSearchParams will run and reflect on user url
  useEffect(() => {
    const searchParams = new URLSearchParams();

    Object.keys(urlQueryState).map(data =>
      searchParams.set(data, urlQueryState[data])
    );
    navigate(`?${searchParams.toString()}`);
  }, [urlQueryState]);

  // Effect for scrolling behavior initiated from user clicking reviews in home dashboard
  useEffect(() => {
    if (location && location.state && location.state.reviewId) {
      setLocationParams(state => ({
        ...state,
        targetScroll: location.state.reviewId
      }));
    }
  }, [location]);

  const onClickReviewTabs = tappedKey => {
    const sectionName = 'filter_tabs';
    const tappedOn = {
      [REVIEWS.ALL]: 'filter_by_all',
      [REVIEWS.UNREPLIED]: 'filter_by_unreplied',
      [REVIEWS.DEALS]: 'filter_by_deals',
      [REVIEWS.FAVEPAY]: 'filter_by_favepay'
    };
    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on: tappedOn[tappedKey],
      section_name: sectionName
    });
  };

  const loadReviewsBy = useCallback(
    (reviewType, { reload = false } = {}) => {
      const loadBy = {
        [REVIEWS.ALL]: getAllReviews,
        [REVIEWS.UNREPLIED]: getUnrepliedReviews,
        [REVIEWS.DEALS]: getFavedealsReviews,
        [REVIEWS.FAVEPAY]: getFavepayReviews
      };
      if (!hasKeys(currentCompany) || !loadBy[reviewType]) return;

      loadBy[reviewType]({
        companyId: currentCompany.id,
        ...urlQueryState,
        reload
      }).then(setReviews);
    },
    [currentCompany, urlQueryState]
  );

  // Effect for fetching API and setting query params
  useEffect(() => {
    // If possible, query params will use existing location.search, if not default to INITIAL_STATE
    navigate(
      qs.stringify(location.search || INITIAL_STATE, { addQueryPrefix: true })
    );

    loadReviewsBy(urlQueryState.reviewType);
    if (hasKeys(currentCompany)) {
      getAverageRatings(currentCompany.id).then(setCompanyAverageRating);
    }
  }, [currentCompany, location.search, urlQueryState, loadReviewsBy]);

  const AverageRating = () => {
    const averageRating =
      hasKeys(companyAverageRating) &&
      !isNaN(companyAverageRating.summary.average_rating)
        ? companyAverageRating.summary.average_rating.toFixed(2)
        : '-';

    const totalReviews = (reviews && reviews.total_count) || '-';

    const ratingColorBracket = rating => {
      if (rating >= HIGH_RATING.number.low && rating <= HIGH_RATING.number.high)
        return HIGH_RATING.color;

      if (
        rating >= MEDIUM_RATING.number.low &&
        rating <= MEDIUM_RATING.number.high
      )
        return MEDIUM_RATING.color;

      if (rating >= LOW_RATING.number.low && rating <= LOW_RATING.number.high)
        return LOW_RATING.color;

      return DEFAULT_RATING_COLOR;
    };

    return (
      <Card className={styles.ratingsOverall}>
        <div>
          <p>{t('Your Ratings')}</p>
          <h1
            style={{
              '--text-color': `var(${ratingColorBracket(averageRating)})`
            }}
          >
            {averageRating}
          </h1>
        </div>
        <div>
          <p>{t('Total Reviews')}</p>
          <h1>{totalReviews}</h1>
        </div>
      </Card>
    );
  };

  const Filters = () => {
    const handleReviewTypeChange = type => {
      onClickReviewTabs(type);
      setUrlQueryState(state => {
        if (state.reviewType === type) {
          return state;
        } else {
          setLocationParams(state => ({ ...state, targetScroll: null }));
          return {
            ...state,
            pageNumber: 1,
            reviewType: type
          };
        }
      });
    };

    return (
      <>
        <span>{t('Show reviews from')}</span>
        <div className={styles.reviewType}>
          {[REVIEWS.ALL, REVIEWS.DEALS, REVIEWS.FAVEPAY, REVIEWS.UNREPLIED].map(
            reviewType => (
              <Badge
                className={styles.filterBadge}
                key={reviewType}
                onChange={() => handleReviewTypeChange(reviewType)}
                checked={reviewType === urlQueryState.reviewType}
              >
                {reviewType}
              </Badge>
            )
          )}
        </div>
      </>
    );
  };

  const SortBy = () => (
    <div className={styles.reviewFilters}>
      <span>{t('Sort By : ')}</span>
      <select
        className={styles.companySelect}
        onChange={e => {
          e.persist();
          setUrlQueryState(state => ({
            ...state,
            pageNumber: 1,
            sortBy: e.target.value
          }));
        }}
        value={urlQueryState.sortBy}
      >
        <option value={CHRONOLOGICAL}>{t('Most Recent')}</option>
        <option value={DESC_RATING}>{t('Ratings: High to Low')}</option>
        <option value={ASC_RATING}>{t('Ratings: Low to High')}</option>
      </select>
    </div>
  );

  const reloadPage = reviewType => loadReviewsBy(reviewType, { reload: true });

  const PageControlButtons = () => {
    const PrevPageButton = () => (
      <Button
        className={styles.button}
        onClick={() =>
          setUrlQueryState(state => ({
            ...state,
            pageNumber:
              state.pageNumber > 1 ? state.pageNumber - 1 : state.pageNumber
          }))
        }
      >
        &#8249;
      </Button>
    );

    const NextPageButton = () => (
      <Button
        className={styles.button}
        onClick={() =>
          setUrlQueryState(state => ({
            ...state,
            pageNumber: state.pageNumber + 1
          }))
        }
      >
        &#8250;
      </Button>
    );

    const totalPageNumber = Math.ceil((reviews.total_count || 0) / 15);

    return (
      <div className={styles.pageControlButtons}>
        <PrevPageButton />
        <p>{urlQueryState.pageNumber}</p>
        {urlQueryState.pageNumber < totalPageNumber && <NextPageButton />}
      </div>
    );
  };

  const handleReviewClicked = isWebview
    ? review => setViewEditReview(review)
    : undefined;

  const Reviews = () => (
    <>
      {(reviews.reviews || []).length === 0 ? (
        <Card className={styles.emptyReview}>
          <div className={styles.starIcon} />
          <span>{t('You have no reviews yet')}</span>
        </Card>
      ) : (
        <>
          {reviews.reviews.map(data => (
            <UserReview
              key={data.id}
              targetScroll={locationParams.targetScroll}
              review={data}
              reviewType={urlQueryState.reviewType}
              handleReviewsChange={reloadPage}
              onReviewClicked={handleReviewClicked}
            />
          ))}
          <PageControlButtons />
        </>
      )}
    </>
  );

  const handleSubmit = (replyText, review) =>
    handleSubmitReview(
      replyText,
      review,
      type => reloadPage(type),
      viewEditReview
        ? () =>
            setViewEditReview({
              ...viewEditReview,
              review_reply: {
                content: replyText,
                created_at: new Date()
              }
            })
        : setViewEditReview
    );

  const title = t(viewEditReview ? 'Review' : 'Ratings and Reviews');
  const subTitle = t(
    viewEditReview ? '' : 'View your reviews and ratings from your customers'
  );

  const handleBackClick = () =>
    viewEditReview
      ? setViewEditReview(undefined)
      : isWebview
      ? call(NATIVE.CLOSE_WEBVIEW)
      : navigate(-1);

  const handleSortClick = () =>
    call(NATIVE.OPEN_REVIEW_SORTING_POPUP, urlQueryState.sortBy);

  const SortIcon = viewEditReview ? (
    undefined
  ) : (
    <SortButton onClick={handleSortClick} />
  );

  useWebCallback(() => {
    window.sortReviews = filterParam =>
      setUrlQueryState(state => ({
        ...state,
        pageNumber: 1,
        sortBy: filterParam
      }));

    window.onBackClick = () => handleBackClick();

    return () => {
      window.sortReviews = undefined;
      window.onBackClick = undefined;
    };
  });

  return (
    <AppLayout sectionClassName={styles.layout}>
      {isWebview ? (
        <WebviewHeading
          title={title}
          subtitle={subTitle}
          onBack={handleBackClick}
          IconElement={SortIcon}
        />
      ) : (
        <Heading title={title} subtitle={subTitle} />
      )}

      {viewEditReview ? (
        <ViewEditUserReview
          review={viewEditReview}
          onSubmit={handleSubmit}
          onCancel={() => setViewEditReview(undefined)}
        />
      ) : (
        <>
          <AverageRating />
          <Filters />
          {!isWebview && <SortBy />}
          <Reviews />
        </>
      )}
    </AppLayout>
  );
};

export default RatingsView;
