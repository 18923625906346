import Kfit from 'integrations/Kfit';
import DataLoader from 'dataloader';

const Repo = new DataLoader(
  keys =>
    Promise.all(
      keys.map(key =>
        Kfit.get(key, undefined, { endpoint: Kfit.ENDPOINTS().consumer })
      )
    ),
  {
    batch: false,
    cacheKeyFn: key => JSON.stringify(key)
  }
);

function loadInfo(key, reload) {
  if (reload) Repo.clearAll();
  return Repo.load(key);
}

export default ({ access_token, place_id }) =>
  loadInfo(
    `/v3/places/details?access_token=${access_token}&place_id=${place_id}`
  );
