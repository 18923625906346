import { FeatureItem } from 'queries/getFeatureListing';
import React, { useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import styles from 'assets/css/FeedECardPreview.module.scss';
import { getBackgroundImageFromUrl } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { AspectRatio } from './FeedPostPreviewCard';
import { caclculateHeightByAspect } from './common/Carousel';
import useDebouncedCallback from 'hooks/useDebouncedCallback';

const eCardFixedApsect: AspectRatio = {
  height: 169,
  width: 303
};

const FeedECardPreview = ({ item }: { item: FeatureItem | undefined }) => {
  const [slideWidth, setSlideWidth] = useState<number>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const handleResize = useDebouncedCallback(([clientWidth]) => {
    if (clientWidth > 0) setSlideWidth(clientWidth);
  }, 200);

  useLayoutEffect(() => {
    const ref = containerRef.current;

    if (ref) {
      setSlideWidth(ref.clientWidth);

      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        handleResize(entries[0].target.clientWidth)
      );

      observer.observe(ref);

      return () => {
        observer.unobserve(ref);
      };
    }
  }, [handleResize]);

  if (!item) return null;

  const {
    theme,
    company_logo,
    company_name,
    payable_amount,
    bonus_credit_amount,
    total_value,
    background_color
  } = item;

  const fontColor = !theme || theme === 'dark' ? 'charcoal-gray' : 'true-white';

  const currentChildHeight = caclculateHeightByAspect(
    slideWidth,
    eCardFixedApsect.height,
    eCardFixedApsect.width
  );

  const heightToTriggerFontSizeChange = 170;

  const previewBackground =
    theme === 'dark'
      ? `radial-gradient(farthest-corner at 150% 150%, black -150%, ${background_color} 100%)`
      : `radial-gradient(farthest-corner at 150% 150%, transparent -50%, ${background_color})`;

  const currentFontSize =
    currentChildHeight && currentChildHeight >= heightToTriggerFontSizeChange
      ? '1rem'
      : '0.7rem';

  return (
    <div
      ref={containerRef}
      className={styles.preview}
      style={{
        color: `var(--${fontColor})`,
        background: previewBackground,
        height: currentChildHeight,
        borderRadius: 'inherit',
        fontSize: currentFontSize
      }}
    >
      <div
        style={{
          background: getBackgroundImageFromUrl(company_logo, 'left')
        }}
        className={styles.logo}
      />
      <div className={styles.space}></div>
      <span className={styles.name}>{company_name}</span>
      <div className={styles.description}>
        <div className={styles.value}>
          <span className={styles.label}>{t('PAY')}</span>
          <span className={styles.amount}>{payable_amount}</span>
          &nbsp;{'|'}&nbsp;
          <span className={styles.label}>{t('GET')}</span>
          <span className={styles.amount}>{total_value}</span>
        </div>
        {bonus_credit_amount && (
          <span className={styles.bonus}>{bonus_credit_amount}</span>
        )}
      </div>
    </div>
  );
};

export default FeedECardPreview;
