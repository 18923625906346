import React, { CSSProperties, PropsWithChildren } from 'react';
import styles from 'assets/css/Grid.module.scss';
import classNames from 'classnames';

type GridProps = {
  columns?: number;
  minWidth?: string;
  rowGap?: string;
  columnGap?: string;
  className?: string;
};

/*
  In order of ideal usage:
  1) default - don't specify columns or minWidth, defaultWidth is mobile first
  2) minWidth: "150px" if screen size below minWidth, wrap flow - fluid
  3) columns: 3 => 3 cards per row - not fluid
 */
function Grid({
  children,
  columns = 0,
  minWidth,
  rowGap = '0.6875rem',
  columnGap = '0.6875rem',
  className = undefined
}: PropsWithChildren<GridProps>) {
  const defaultWidth = '250px';
  const minColWidth = minWidth || (columns > 0 ? undefined : defaultWidth);
  return (
    <section
      className={classNames(styles.grid, className)}
      style={
        {
          '--columns': columns,
          '--col-min-width':
            minColWidth ||
            `calc((89vw - var(--sidebar-size)) / var(--columns))`,
          '--row-gap': rowGap,
          '--column-gap': columnGap
        } as CSSProperties
      }
    >
      {children}
    </section>
  );
}

export default Grid;
