import React, { useContext, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import useHubSpotTrack from 'hooks/useHubSpotTrack';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import { Formik, Form } from 'formik';
import TextInput from 'components/TextInput';
import Select from 'components/Select';
import Button from 'components/Button';
import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import requestFaveDealSetup from 'commands/requestFaveDealSetup';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import { NotificationContext } from 'contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'assets/css/SetupDealsView.module.scss';

const SetupDealsView = ({ location }) => {
  const { t } = useTranslation();
  useHubSpotTrack(location.pathname);

  const LogInSchema = Yup.object().shape({
    businessLocation: Yup.string().test(
      'businessLocation',
      'Select a city',
      value => {
        return value !== 'default' && value !== undefined;
      }
    )
  });
  const cleverTap = useCleverTapOld();
  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  const cities = {
    'kuala-lumpur': [
      { code: 'default', text: t('Select a city*') },
      { code: 'johor', text: 'Johor' },
      { code: 'kedah', text: 'Kedah' },
      { code: 'kelantan', text: 'Kelantan' },
      { code: 'kuala-lumpur', text: 'Kuala Lumpur' },
      { code: 'melaka', text: 'Melaka' },
      { code: 'negeri-sembilan', text: 'Negeri Sembilan' },
      { code: 'pahang', text: 'Pahang' },
      { code: 'penang', text: 'Penang' },
      { code: 'perak', text: 'Perak' },
      { code: 'perlis', text: 'Perlis' },
      { code: 'sabah', text: 'Sabah' },
      { code: 'sarawak', text: 'Sarawak' },
      { code: 'selangor', text: 'Selangor' },
      { code: 'terengganu', text: 'Terengganu' }
    ],

    jakarta: [
      { code: 'default', text: t('Select a city') },
      { code: 'jakarta', text: 'Jakarta' },
      { code: 'bali', text: 'Bali' },
      { code: 'bandung', text: 'Bandung' },
      { code: 'surabaya', text: 'Surabaya' },
      { code: 'medan', text: 'Medan' }
    ],

    singapore: [{ code: 'singapore', text: 'Singapore' }]
  };

  const { user, updateUser } = useContext(AuthContext);
  const { currentCompany } = useContext(OutletContext);
  const { setNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const isSingapore = !isEmpty(user) && user.city.slug === 'singapore';

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'deals_setup' });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  const submitForm = (values, formikBag) => {
    setIsLoading(true);
    const submitFormBody = {
      company_id: currentCompany.id,
      business_category: currentCompany.parent_category_name,
      location: values.businessLocation,
      additional_details: values.businessDetails
    };
    requestFaveDealSetup(submitFormBody)
      .then(user => {
        cleverTap.tapped.push({
          screen_name: 'deals_setup',
          tapped_on: 'btn_done'
        });
        updateUser(user);
        setNotification({
          message: t(
            'Our business development team will contact you right away to help you with your setup.'
          ),
          type: 'success'
        });
        navigate('/deals');
      })
      .catch(error => {
        if ('value' in error) {
          const { value } = error;
          setNotification({ message: value.response.error, type: 'error' });
        } else {
          setNotification({ message: error.toString(), type: 'error' });
        }
      })
      .finally(() => {
        formikBag.setSubmitting(false);
        setIsLoading(false);
      });
  };
  return (
    <AppLayout>
      <Heading
        title={t('Set up Deals')}
        subtitle={t('Get more customers with attractive deals.')}
      />
      <div className={styles.dealsViewContainer}>
        <div className={styles.dealsHeader}>
          <h4>{t('Business category')}</h4>
          <h5>{(currentCompany || {}).parent_category_name}</h5>
        </div>
        <div className={styles.dealsForms}>
          <Formik
            enableReinitialize
            initialValues={{
              businessLocation: isSingapore ? 'singapore' : '',
              businessDetails: ''
            }}
            validationSchema={LogInSchema}
            validateOnBlur={false}
            onBlur={() => {}}
            onSubmit={submitForm}
          >
            {({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              resetForm,
              handleChange
            }) => (
              <Form className={styles.form}>
                <div className={styles.businessCity}>
                  <h4>{t('Business location')}</h4>
                  <Select
                    name="businessLocation"
                    items={cities[user.city.slug]}
                    {...{ errors, touched }}
                  />
                </div>

                <div className={styles.businessDetails}>
                  <h4>{t('Additional details')}</h4>
                  <label htmlFor="businessDetails">
                    <TextInput
                      name="businessDetails"
                      component="textarea"
                      placeholder={t(
                        'Tell us more so we can assist you better (optional)'
                      )}
                      className={styles.detailEntry}
                      {...{ errors, touched }}
                    />
                  </label>
                  <div className={styles.businessInfo}>
                    {t(
                      'Our business development team will contact and assist you in the creation of Deals.'
                    )}
                  </div>
                  <Button
                    type="submit"
                    size="lg"
                    rightIcon="arrow-right"
                    justify="space-between"
                    className={styles.verifyPinSubmit}
                    disabled={isSubmitting}
                    isLoading={isLoading}
                  >
                    {t('Done')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </AppLayout>
  );
};

export default SetupDealsView;
