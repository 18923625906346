import React, { useContext } from 'react';
import { flatten } from 'lodash';
import { isObject } from 'utils';
import { COUNTRIES, LANGUAGES } from 'helpers/TypeConstants';
import { LangContext } from 'contexts/LangContext';

const CleverTapContextOld = React.createContext();

/* clevertap needs to be in global window scope for script to work */
window.clevertap = {
  event: [],
  profile: [],
  account: [],
  onUserLogin: [],
  notifications: [],
  privacy: []
};

const CleverTapContextProviderOld = props => {
  const { clevertap } = window;
  const { currentLang } = useContext(LangContext);

  clevertap.account.push({ id: process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID });

  // using global window.clevertap with initial props above,
  // self-executing function below looks for and decorates window.clevertap
  // with props and functions for calling their API
  // https://developer.clevertap.com/docs/web-quickstart-guide
  (function() {
    if (window.cleverTapScriptTagAppended) return;
    const wzrk = document.createElement('script');
    wzrk.type = 'text/javascript';
    wzrk.async = true;
    wzrk.src =
      (document.location.protocol === 'https:'
        ? 'https://d2r1yp2w7bby2u.cloudfront.net'
        : 'http://static.clevertap.com') + '/js/a.js';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wzrk, s);
    window.cleverTapScriptTagAppended = true;
  })();

  const hasFavePay = companies => {
    const allOutlets = flatten(companies.map(c => flatten(c.outlets)));
    return allOutlets.some(outlet => outlet.has_fave_payment);
  };

  return (
    <CleverTapContextOld.Provider
      value={{
        cleverTapProfile: {
          push: (user, isStaff = false) => {
            const hasUser = Object.keys(user || {}).length !== 0;
            if (!hasUser) return;
            if (isStaff) {
              const profile = {
                Site: {
                  Identity: `${user.outlet.id} - ${user.country}`,
                  language: LANGUAGES[currentLang],
                  country: COUNTRIES[user.city_slug],
                  role_1: 'Staff',
                  partner_name: user.profile.company_name,
                  partner_ids_1: user.profile.company_id,
                  enabled_favepay_1: user.features.fave_payment,
                  enabled_deals_1: user.features.voucher
                  //pennding enabled_e_cards + partner_category
                }
              };

              clevertap.profile.push(profile);
            } else {
              const profile = {
                Site: {
                  Identity: user.email || user.phone,
                  manager_name: user.manager_name,
                  Email: user.email,
                  Phone: user.phone,
                  country: COUNTRIES[user.city.slug],
                  role_1: 'Manager',
                  language: LANGUAGES[currentLang],
                  partner_category: user.companies
                    .map(c => c.parent_category_name)
                    .join(', '),
                  partner_name: user.companies
                    .map(c => c.partner_name)
                    .join(', '),
                  partner_ids_1: user.companies.map(c => c.id),
                  enabled_favepay_1: hasFavePay(user.companies),
                  enabled_deals_1: user.companies.some(c => c.has_deals),
                  enabled_ecards_1: user.companies.some(c => c.has_e_cards),
                  enabled_feed_announcement_1: user.companies.some(
                    company => company.announcement_feed_enabled
                  ),
                  //pending enabled_ordering

                  // https://developer.clevertap.com/docs/concepts-user-profiles#section-manually-updating-predefined-user-profile-properties
                  'MSG-push': true // Enable push notifications
                }
              };
              clevertap.profile.push(profile);
            }

            clevertap.notifications.push({
              titleText: 'Would you like to receive Push Notifications?',
              bodyText:
                'We promise to only send you relevant content and give you updates on your transactions',
              okButtonText: 'Sign me up!',
              rejectButtonText: 'No thanks',
              okButtonColor: '#de206a',
              // askAgainTimeInSeconds: 5 /* defaults to 1 week if omitted */,
              serviceWorkerPath: 'service-worker.js' // path to CRA's build service worker
            });
          },
          logout: () => clevertap.logout()
        },
        cleverTapEvent: {
          push: ({
            name,
            screen_name,
            tapped_on,
            section_name,
            props,
            outletContext: { currentOutlet, currentCompany },
            platform,
            mode
          }) => {
            if (!name) return;
            const event = {
              ...props,
              screen_name,
              tapped_on,
              section_name,
              screen_type: mode,
              company_name: currentCompany && currentCompany.partner_name,
              outlet_name: isObject(currentOutlet)
                ? currentOutlet.name
                : 'All Outlets',
              platform: platform
            };
            clevertap.event.push(name, event);
          }
        }
      }}
    >
      {props.children}
    </CleverTapContextOld.Provider>
  );
};

export { CleverTapContextOld, CleverTapContextProviderOld };
