import { useCallback, useContext } from 'react';
import { navigate } from '@reach/router';

import { jsonToQueryString } from 'insights/utils';
import { QueryStringContext } from 'contexts/QueryStringContext';

export default function useNavigate({ location }) {
  const currentQueryParams = useContext(QueryStringContext);
  return useCallback(
    ({ outletId, startDate, endDate }) => {
      const { pathname, hash } = location;
      const queryParams = jsonToQueryString({
        ...currentQueryParams,
        outletId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      });
      navigate(`${pathname}${queryParams}${hash}`);
    },
    [location, currentQueryParams]
  );
}
