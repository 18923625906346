import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';

import AppLayout from 'layouts/AppLayout';
import { OutletContext } from 'contexts/OutletContext';
import Card from 'components/Card';
import Button from 'components/Button';
import Icon from 'components/Icon';
import ToggleButton from 'components/ToggleButton';
import LoadingSpinner from 'components/LoadingSpinner';
import styles from 'assets/css/EmailNotifications.module.scss';
import {
  getEmailNotifications,
  IEmailNotificationOutlet
} from 'queries/getEmailNotifications';
import { updateEmailNotification } from 'commands/updateEmailNotification';
import { NotificationContext } from 'contexts/NotificationContext';

const EmailNotificationsView: React.FC = () => {
  const { currentCompany } = useContext(OutletContext);
  const [allFlag = false, setAllFlag] = useState<
    boolean
  >();
  const [emailNotificationOutlets = [], setEmailNotificationOutlets] = useState<
    IEmailNotificationOutlet[]
  >();
  const { setNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!currentCompany) return;
    getEmailNotifications({ company_id: currentCompany.id }).then(response => {
      setAllFlag(response.all_flag)
      setEmailNotificationOutlets(response.outlets)
    })
    .catch((error) => {
      setAllFlag(false);
      setEmailNotificationOutlets([]);
      setNotification({
        message: error.value.response.error,
        type: 'error'
      });
      setIsLoading(false);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [currentCompany, setNotification, setIsLoading]);

  type OutletProps = {
    outlet?: IEmailNotificationOutlet;
    isAllFlag?: boolean;
    allFlag?: boolean;
  };

  const OutletButton: React.FC<OutletProps> = props => {
    return (
      props.isAllFlag ?
        <Button
          type="text"
          fullWidth
          size="lg"
          className={classNames(styles.button, styles.outletButton)}
        >
          <span className={styles.outletName}>All Outlets</span>
          <ToggleButton
            id={`emailNotifications_all`}
            checked={props.allFlag || false}
            onToggle={(enabled: boolean) => {
              setIsLoading(true);
              setAllFlag(enabled)
              updateEmailNotification({
                flag: enabled,
                company_id: currentCompany.id,
                all: true
              }).then(response => {
                setAllFlag(response.all_flag)
                setEmailNotificationOutlets(response.outlets)
              })
              .catch((error) => {
                setAllFlag(!enabled);
                setNotification({
                  message: error.value.response.error,
                  type: 'error'
                })
              })
              .finally(() => {
                setIsLoading(false);
              });
            }}
          />
        </Button>
        :
        <Button
          type="text"
          fullWidth
          size="lg"
          className={classNames(styles.button, styles.outletButton)}
        >
          <span className={styles.outletName}>{props.outlet?.name}</span>
          <ToggleButton
            id={`emailNotifications_${props.outlet?.id}`}
            checked={props.outlet?.email_notification || false}
            onToggle={(enabled: boolean) => {
              setIsLoading(true);
              setEmailNotificationOutlets(emailNotificationOutlets.map(outlet => outlet.id === props.outlet?.id ? {...outlet, email_notification: enabled} : outlet))
              updateEmailNotification({
                flag: enabled,
                company_id: currentCompany.id,
                outlet_id: props.outlet?.id
              }).then(response => {
                setAllFlag(response.all_flag)
                setEmailNotificationOutlets(response.outlets)
              })
              .catch((error) => {
                setEmailNotificationOutlets(emailNotificationOutlets.map(outlet => outlet.id === props.outlet?.id ? {...outlet, email_notification: !enabled} : outlet))
                setNotification({
                  message: error.value.response.error,
                  type: 'error'
                })
              })
              .finally(() => {
                setIsLoading(false);
              });
            }}
          />
        </Button>
    );
  };

  return (
    <AppLayout>
      <div className={styles.overlay}>
        <Card className={styles.switchOutletCard}>
          {isLoading && <LoadingSpinner />}
          <Button
            type="text"
            className={styles.backButton}
            onClick={() => window.history.back()}
          >
            <Icon icon="angle-left" />
            Back
          </Button>
          <div className={styles.title}>Email notifications</div>
          <section className={styles.outlets}>
            <OutletButton
              key={'all'}
              isAllFlag={true}
              allFlag={allFlag}
            />
            {emailNotificationOutlets.map(outlet => (
              <OutletButton key={outlet.id} outlet={outlet} />
            ))}
          </section>
        </Card>
      </div>
    </AppLayout>
  );
};

export default EmailNotificationsView;
