import Kfit from '../integrations/Kfit';

export const resetAccessCode = (id, params) =>
  Kfit.put(`/v7/outlets/${id}/reset_mobile_access`, params);

export const setCancellationCode = (id, params) =>
  Kfit.put(`/v7/outlets/${id}/update_cancellation_code`, params);

export const updateRequireCancellation = (id, params) =>
  Kfit.put(`/v7/outlets/${id}/update_require_cancellation_code`, params);
