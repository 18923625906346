import React from 'react';
import PropTypes from 'prop-types';

import styles from 'assets/css/FavePaySetupView/SectionHeader.module.scss';

const SectionHeader = ({ icon, title }) => (
  <h3 className={styles.sectionHeader}>
    <img src={icon} alt={title} />
    <span>{title}</span>
  </h3>
);

SectionHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default SectionHeader;
