import React from 'react';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import classNames from 'classnames';
import styles from 'assets/css/LimitedTextInput.module.scss';

export type LimitedTextInputProps = {
  name: string;
  className: string;
  maxLength: number;
};

const LimitedTextInput = ({
  name,
  className,
  maxLength,
  ...rest
}: LimitedTextInputProps | FieldAttributes<any>) => {
  const { values } = useFormikContext<any>();

  const value = values[name];

  const lengthText = `${value ? value.length : 0} / ${maxLength}`;

  const exceedsLimit = value.length > maxLength;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const length = event.currentTarget.value.length;

    if (length >= maxLength) return false;
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const length = (event.currentTarget as HTMLTextAreaElement).value.length;

    const text = (event.clipboardData || (window as any).clipboardData).getData(
      'text'
    );

    if (length + text.length >= maxLength) event.preventDefault();
  };

  return (
    <div className={classNames(styles.container, className)}>
      <Field
        autoCorrect="off"
        spellCheck="false"
        className={styles.input}
        name={name}
        as="textarea"
        maxLength={maxLength}
        onKeyPress={handleKeyPress}
        onPaste={handlePaste}
        {...rest}
      />
      <span
        className={classNames(
          styles.lengthCounter,
          exceedsLimit ? styles.invalidLength : ''
        )}
      >
        {lengthText}
      </span>
    </div>
  );
};

export default LimitedTextInput;
