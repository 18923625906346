import moment from 'moment';

export const STATE = {
  IDLE: 'idle',
  PICKING_OUTLET: 'pickingOutlet',
  FETCHING_FILTERS: 'fetchingFilters',
  FETCHING_TRANSACTIONS: 'fetchingTransactions',
  FETCHING_MORE_TRANSACTIONS: 'fetchingMoreTransactions',
  FETCHING_SUMMARY: 'fetchingSummary'
};

export const EVENT = {
  PICK_OUTLET: 'pickOutlet',
  FETCH_FILTERS: 'fetchFilters',
  FETCH_SUMMARY: 'fetchSummary',
  FETCH_TRANSACTIONS: 'fetchTransactions',
  FETCH_MORE_TRANSACTIONS: 'fetchMoreTransactions',
  NEW_TRANSACTIONS: 'newTransactions',
  SHOW_NEW_TRANSACTIONS: 'showNewTransactions',
  CLEAR_NEW_TRANSACTIONS: 'clearNewTransactions',
  TRANSACTION_SUMMARY_VISIBLE: 'transactionSummaryVisible',
  TRANSACTION_SUMMARY_INVISIBLE: 'transactionSummaryInvisible',
  FIRST_TRANSACTION_VISIBLE: 'firstTransactionVisible',
  FIRST_TRANSACTION_INVISIBLE: 'firstTransactionInvisible',
  RESET: 'reset',
  DONE: 'done',
  ERROR: 'error',
  CHANGE_FILTER: 'changeFilter',
  CHANGE_DATE: 'changeDate'
};

const onPickOutlet = current => ({
  ...current,
  page: 1,
  state: STATE.PICKING_OUTLET
});

const onFetchTransactions = current => ({
  ...current,
  state: STATE.FETCHING_TRANSACTIONS,
  page: 1
});

const onFetchSummary = current => ({
  ...current,
  state: STATE.FETCHING_SUMMARY,
  page: 1
});

const onFetchMoreTransactions = current => ({
  ...current,
  state: STATE.FETCHING_MORE_TRANSACTIONS,
  page: current.page + 1
});

const onChangeFilter = (current, event) =>
  current.filter === event.filter
    ? current
    : {
        ...current,
        filter: event.filter,
        page: 1,
        state: STATE.FETCHING_SUMMARY
      };

const onChangeDate = (current, event) =>
  current.date.isSame(event.date, 'day')
    ? current
    : {
        ...current,
        date: event.date,
        page: 1,
        state: STATE.FETCHING_SUMMARY
      };

const onFetchError = (current, event) => ({
  ...current,
  error: event.error,
  state: STATE.IDLE
});

const onFetchFiltersDone = (current, event) => ({
  ...current,
  filters: event.data,
  state: STATE.FETCHING_SUMMARY
});

const onFetchTransactionsDone = (current, event) => ({
  ...current,
  hasMoreTransactions:
    current.transactions.length + event.data.length !==
    current.transactions.length,
  transactions: event.data,
  hasCache: false,
  state: STATE.IDLE
});

const onFetchMoreTransactionsDone = (current, event) => ({
  ...current,
  hasMoreTransactions:
    current.transactions.length + event.data.length !==
    current.transactions.length,
  transactions: [...current.transactions, ...event.data],
  state: STATE.IDLE
});

const onPickOutletDone = current => ({
  ...current,
  state: STATE.FETCHING_FILTERS
});

const onFetchSummaryDone = (current, event) => ({
  ...current,
  summary: { revenue: event.data.revenue_amount, count: event.data.count },
  state: STATE.FETCHING_TRANSACTIONS
});

const onReset = (current, event, initialState) => ({
  ...initialState,
  hasCache: false,
  filters: current.filters,
  date: moment(),
  state: STATE.FETCHING_FILTERS
});

const onNewTransactions = current => ({
  ...current,
  transactionsCount: current.transactionsCount + 1,
  hasNewTransactions: true
});

const onShowNewTransactions = current => ({
  ...current,
  hasNewTransactions: false,
  showNewTransactions: true
});

const onFetchFilters = current => ({
  ...current,
  page: 1,
  hasNewTransactions: false,
  transactionsCount: 0,
  state: STATE.FETCHING_FILTERS
});

const onTransactionSummaryVisible = current => ({
  ...current,
  transactionSummaryVisible: true
});

const onTransactionSummaryInvisible = current => ({
  ...current,
  transactionSummaryVisible: false
});

const onFirstTransactionVisible = current => ({
  ...current,
  firstTransactionVisible: true
});

const onFirstTransactionInvisible = current => ({
  ...current,
  firstTransactionVisible: false
});

export const stateMachine = {
  [STATE.IDLE]: {
    [EVENT.PICK_OUTLET]: onPickOutlet,
    [EVENT.FETCH_FILTERS]: onFetchFilters,
    [EVENT.FETCH_SUMMARY]: onFetchSummary,
    [EVENT.FETCH_TRANSACTIONS]: onFetchTransactions,
    [EVENT.FETCH_MORE_TRANSACTIONS]: onFetchMoreTransactions,
    [EVENT.CHANGE_FILTER]: onChangeFilter,
    [EVENT.CHANGE_DATE]: onChangeDate,
    [EVENT.RESET]: onReset,
    [EVENT.NEW_TRANSACTIONS]: onNewTransactions,
    [EVENT.SHOW_NEW_TRANSACTIONS]: onShowNewTransactions,
    [EVENT.CLEAR_NEW_TRANSACTIONS]: onReset,
    [EVENT.TRANSACTION_SUMMARY_VISIBLE]: onTransactionSummaryVisible,
    [EVENT.TRANSACTION_SUMMARY_INVISIBLE]: onTransactionSummaryInvisible,
    [EVENT.FIRST_TRANSACTION_VISIBLE]: onFirstTransactionVisible,
    [EVENT.FIRST_TRANSACTION_INVISIBLE]: onFirstTransactionInvisible
  },
  [STATE.PICKING_OUTLET]: {
    [EVENT.FETCH_FILTERS]: onPickOutletDone
  },
  [STATE.FETCHING_FILTERS]: {
    [EVENT.DONE]: onFetchFiltersDone,
    [EVENT.ERROR]: onFetchError
  },
  [STATE.FETCHING_SUMMARY]: {
    [EVENT.DONE]: onFetchSummaryDone,
    [EVENT.ERROR]: onFetchError
  },
  [STATE.FETCHING_TRANSACTIONS]: {
    [EVENT.DONE]: onFetchTransactionsDone,
    [EVENT.ERROR]: onFetchError
  },
  [STATE.FETCHING_MORE_TRANSACTIONS]: {
    [EVENT.DONE]: onFetchMoreTransactionsDone,
    [EVENT.ERROR]: onFetchError
  }
};
