import DataLoader from 'dataloader';
import Kfit from 'integrations/Kfit';
import FaveBizAPI from 'integrations/FaveBizAPI';
import { REPORT_TYPES } from 'helpers/TypeConstants';

const reportLoader = new DataLoader(
  keys => Promise.all(keys.map(({ key, params }) => Kfit.get(key, params))),
  { batch: false, cacheKeyFn: key => JSON.stringify(key) }
);

const favebizReportLoader = new DataLoader(
  keys =>
    Promise.all(keys.map(({ key, params }) => FaveBizAPI.get(key, params))),
  { batch: false, cacheKeyFn: key => JSON.stringify(key) }
);

export default function getReportRequest(
  params,
  reportType = REPORT_TYPES.FAVE_PAY.value
) {
  if (
    reportType === REPORT_TYPES.ECARD_PURCHASED.value ||
    reportType === REPORT_TYPES.ECARD_UTILISED.value
  ) {
    return favebizReportLoader.load({
      key: `/v1/reports/${reportType}`,
      params
    });
  }

  return reportLoader.load({ key: `/v5/reports/${reportType}`, params });
}
