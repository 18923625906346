import React from 'react';
import classNames from 'classnames';
import styles from 'assets/css/BarSteps.module.scss';
import { useTranslation } from 'react-i18next';

export type BarStep = {
  title: string;
};

export type BarStepsProps = {
  className?: string;
  steps: BarStep[];
  activeStepIndex: number;
  isLite: boolean;
  getStepSubtitle?: (step: BarStep, index: number) => string;
};

const BarSteps = ({
  className = '',
  steps,
  activeStepIndex,
  isLite = false,
  getStepSubtitle
}: BarStepsProps) => {
  const BAR_ROW_INDEX = 0;
  const SUBTITLE_ROW_INDEX = 1;
  const TITLE_ROW_INDEX = 2;

  const { t } = useTranslation();

  if (!getStepSubtitle)
    getStepSubtitle = (_step: BarStep, index: number) =>
      t(`Step {{step}}`, { step: index + 1 });

  return (
    <section className={classNames(styles.stepsContainerLite, className)}>
      {Array(3)
        .fill(undefined)
        .map((_row, rowIndex) => {
          return (
            <div key={rowIndex} className={styles.stepSegement}>
              {steps.map((step, columnIndex) => {
                const barStepStyles = classNames(
                  styles.stepItem,
                  (isLite && activeStepIndex >= columnIndex) ||
                    (!isLite && activeStepIndex === columnIndex)
                    ? styles.activeItem
                    : ''
                );

                const stepStyles = classNames(
                  styles.stepItem,
                  activeStepIndex === columnIndex ? styles.activeItem : ''
                );

                return (
                  <React.Fragment key={columnIndex}>
                    {rowIndex === BAR_ROW_INDEX ? (
                      <div className={barStepStyles}>
                        <div className={styles.stepBar}></div>
                      </div>
                    ) : (
                      <>
                        {(!isLite || activeStepIndex === columnIndex) && (
                          <div className={stepStyles}>
                            {rowIndex === SUBTITLE_ROW_INDEX && (
                              <span className={styles.subtitle}>
                                {getStepSubtitle &&
                                  getStepSubtitle(step, columnIndex)}
                              </span>
                            )}

                            {rowIndex === TITLE_ROW_INDEX && (
                              <span className={styles.title}>{step.title}</span>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
    </section>
  );
};

export default BarSteps;
