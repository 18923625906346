import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import styles from 'assets/css/BrandLogo.module.scss';

type BrandLogoProps = {
  position?: string;
  height?: string;
  className?: string;
};

const BrandLogo: React.FC<BrandLogoProps> = ({
  position = 'center',
  height = '2.5rem',
  className
}) => (
  <div
    onClick={() => navigate('/')}
    style={
      {
        '--brand-logo-height': height,
        '--logo-position': position
      } as CSSProperties
    }
    className={classNames(styles.brandLogo, className)}
  />
);

export default BrandLogo;
