import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import OpsLayout from 'layouts/OpsLayout';
import getRedemptionVoucher from 'queries/getRedemptionVoucher';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Card from 'components/Card';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { NotificationContext } from 'contexts/NotificationContext';
import useCleverTapOld from 'hooks/useCleverTapOld';

import { isObject } from 'utils';

import { useTranslation } from 'react-i18next';
import styles from 'assets/css/VoucherRedemptionDetailsView.module.scss';
import { WebviewContext } from 'contexts/WebviewContext';
import GuaranteeFeeLabel from 'components/GuaranteeFeeLabel';

const VoucherRedemptionDetailsView = props => {
  const { t } = useTranslation();
  const [redemption, setRedemption] = useState(null);
  const { currentOutlet } = useContext(OpsOutletContext);
  const { setNotification } = useContext(NotificationContext);
  const { isWebview } = useContext(WebviewContext);

  const cleverTap = useCleverTapOld();

  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({
      screen_name: 'transactiondetails_deals'
    });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  useEffect(() => {
    if (!isObject(currentOutlet)) return;
    getRedemptionVoucher({
      authCode: currentOutlet.auth_code,
      code: props.code
    })
      .then(res => {
        setRedemption(res.redemptions);
      })
      .catch(err =>
        setNotification({ message: err.value.response.error, type: 'error' })
      );
  });

  return (
    <OpsLayout>
      {!isWebview && (
        <Heading
          title={t('Redeem Voucher')}
          subtitle={t('Redeem your customer voucher here')}
        />
      )}
      <Button
        leftIcon="arrow-left"
        href="/operations/transactions"
        type="text"
        children={t('Back to transactions')}
      />
      {redemption && (
        <Card className={classNames(styles.card, styles.redemptionCard)}>
          <header>
            <img
              alt={redemption.activity_name}
              src={redemption.offer_thumbnail_image_url}
            />

            <p className={styles.redeemed}>
              Redeemed on{' '}
              <time dateTime={redemption.redeemed_at}>
                {redemption.redeemed_at}
              </time>
            </p>

            <div className={styles.title}>
              <h3>{redemption.activity_name}</h3>
              <p className={styles.price}>
                <span>{redemption.original_price}</span>{' '}
                {redemption.discounted_price}
              </p>
              <GuaranteeFeeLabel
                guarunteeFee={redemption.guarantee_fee_percentage}
                isTransactionCard={false}
              />
            </div>
          </header>

          <dl>
            <dt> {t('Redeemed by')}</dt>
            <dd>{redemption.name}</dd>
            <dt> {t('Valid from')}</dt>
            <dd>
              {redemption.redeemable_from} - {redemption.redeemable_to}
            </dd>
            <dt>{t('Redemption code')}</dt>
            <dd>{redemption.code.toUpperCase()}</dd>
            <dt>{t('Receipt ID')}</dt>
            <dd>{redemption.receipt_id}</dd>
            <dt>{t('Quantity')}</dt>
            <dd>{redemption.reservations_count}</dd>
            <dt>{t('Location')}</dt>
            <dd>{redemption.outlet_name}</dd>
          </dl>
        </Card>
      )}
    </OpsLayout>
  );
};

export default VoucherRedemptionDetailsView;
