import React from 'react';
import classNames from 'classnames';

import Button, { IButtonProps } from './Button';
import styles from 'assets/css/LinkButton.module.scss';

const LinkButton: React.FC<IButtonProps> = ({
  className,
  children,
  ...restProps
}) => (
  <Button
    type="text"
    textColor="pelorous"
    iconSize="1.5rem"
    iconColor="pelorous"
    className={classNames(styles.button, styles.link, className)}
    {...restProps}
  >
    {children}
  </Button>
);

export default LinkButton;
