import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import AppLayout from 'layouts/AppLayout';
import OpsLayout from 'layouts/OpsLayout';
import Heading from 'components/Heading';
import Button, { CancelButton } from 'components/Button';
import Card from 'components/Card';
import { ModalContext } from 'contexts/ModalContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { MapleContext } from 'contexts/MapleContext';
import useCleverTapOld from 'hooks/useCleverTapOld';

import { useTranslation, Trans } from 'react-i18next';
import styles from 'assets/css/TransactionDetail.module.scss';
import { WebviewContext } from 'contexts/WebviewContext';
import { QueryStringContext } from 'contexts/QueryStringContext';
import TransactionCancelationModal from './TransactionCancelationModal';
import Cookie from 'helpers/Cookie';

type TransactionDetailProps = {
  screenName: string;
  useTransactionDetails: (id: number) => any;
  cancelTransaction: (id: number, code: object) => Promise<any>;
  id: number;
  responseProp: keyof TransactionProps;
};

type TransactionProps = {
  id: number;
  created_at_json: string;
  require_cancellation_code: boolean;
  cancellable: boolean;
  receipt_id: string;
  total_amount: string;
  customer: string;
  paid_via: string;
  cancelled_by: string;
  cancelled_at_json: string;
  cancelled: boolean;
  rpp_participant: {
    app_name: string;
  };
  flp_client: {
    app_name: string;
  };
  additional_details: AdditionalDetails[];
};

type AdditionalDetails = {
  field_name: string;
  field_value: string;
};

type StatusNotificationProps = {
  date: string;
  translatedStatus: string;
  status: string;
  cancelledBy?: string;
};

type TypeExcludesFalse = <T>(x: T | false) => x is T;

const TransactionDetail: React.FC<TransactionDetailProps> = props => {
  const { screenName, useTransactionDetails, id, responseProp } = props;
  const { t } = useTranslation();
  const { isMaple } = useContext(MapleContext);
  const [transaction, setTransaction] = useState<TransactionProps>();
  const [statusNotifications, setStatusNotifications] = useState<
    StatusNotificationProps[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isWebview } = useContext(WebviewContext);
  const authCode = Cookie.get('authCode', null);
  const isStaffMode = isWebview ? true : !!authCode;
  const { from } = useContext(QueryStringContext);
  const { init } = useContext(ModalContext);
  const { setNotification } = useContext(NotificationContext);
  const cleverTap = useCleverTapOld();

  const formatDate = (date: string) =>
    moment(date).format('ddd, Do MMM YYYY, h:mm:ss a');

  const [hasSentCTEvent, setHasSentCTEvent] = useState(false);

  useEffect(() => {
    if (hasSentCTEvent || !transaction) return;
    cleverTap.screenDisplayed.push({
      screen_name: `transactiondetails_${screenName}`
    });
    setHasSentCTEvent(true);
  }, [hasSentCTEvent, cleverTap.screenDisplayed, transaction, screenName]);

  const responseData = useTransactionDetails(id).data;

  useEffect(() => {
    if (responseData) setTransaction(responseData[responseProp]);
  }, [responseData, responseProp]);

  useEffect(() => {
    if (!transaction) return;

    setStatusNotifications(() =>
      [
        {
          date: formatDate(transaction.created_at_json),
          translatedStatus: t('paid'),
          status: 'paid'
        },
        transaction.cancelled && {
          date: formatDate(transaction.cancelled_at_json),
          translatedStatus: t('cancelled'),
          status: 'cancelled',
          cancelledBy: transaction.cancelled_by
        }
      ].filter((d => d) as TypeExcludesFalse)
    );
  }, [transaction, t, responseProp]);

  const handleCancel = (
    cancellation_code: object,
    onSuccess = () => {},
    finishSubmit = () => {}
  ) => {
    setIsLoading(true);
    props
      .cancelTransaction(id, cancellation_code)
      .then(res => {
        setTransaction(res[responseProp]);
        cleverTap.screenDisplayed.push({
          screen_name: 'transaction_cancel_successful'
        });
        onSuccess();
      })
      .catch(error => {
        setNotification({
          message: error.value.response.error,
          type: 'error'
        });
      })
      .finally(() => {
        setIsLoading(false);
        finishSubmit();
      });
  };

  const onOpenCancellationCodeModal = () => {
    cleverTap.tapped.push({
      screen_name: `transactiondetails_${screenName}`,
      tapped_on: 'btn_cancel_transaction'
    });

    init({
      Component: TransactionCancelationModal,
      openOnReady: true,
      hasCloseButton: false,
      componentProps: {
        receiptId: transaction?.receipt_id,
        totalBill: transaction?.total_amount,
        paidBy: transaction?.customer,
        requireCancelationCode: transaction?.require_cancellation_code,
        isLoading,
        handleCancel,
        proceedButtonCleverTap: () => {
          cleverTap.tapped.push({
            screen_name: 'transaction_cancel',
            tapped_on: 'btn_proceed'
          });
        },
        confirmCancelCleverTap: () => {
          cleverTap.tapped.push({
            screen_name: 'transactiondetails_favepay',
            tapped_on: 'btn_confirm_cancel'
          });
        }
      }
    });
  };

  const renderPaidViaColumn = (client: { app_name: string }) => (
    <>
      <dt>{t('Paid via')}</dt>
      <dd>{client?.app_name}</dd>
    </>
  );

  const TransactionDetailContent = () => (
    <>
      {!isWebview && (
        <Heading
          title={t('Transactions')}
          subtitle={t(
            'View your latest and live transactions of your outlet here'
          )}
        />
      )}
      <Button
        leftIcon="arrow-left"
        href={from ? `/${from}` : '/operations/transactions?clearCache=true'}
        type="text"
        children={from ? t('Back to order') : t('Back to transactions')}
      />
      {transaction && (
        <Card className={styles.card}>
          <section className="statusNotifications">
            {statusNotifications &&
              statusNotifications.map(
                ({ translatedStatus, status, date, cancelledBy }, i) => (
                  <div
                    key={i}
                    className={classNames(
                      styles.notification,
                      status === 'cancelled' ? styles.cancel : styles.paid
                    )}
                  >
                    {cancelledBy ? (
                      <Trans>
                        <p>
                          {{ translatedStatus }} by{' '}
                          <strong>{{ cancelledBy }}</strong> on {{ date }}
                        </p>
                      </Trans>
                    ) : (
                      <Trans>
                        <p>
                          {{ translatedStatus }} on {{ date }}
                        </p>
                      </Trans>
                    )}
                  </div>
                )
              )}
          </section>

          <dl>
            <dt> {t('Receipt ID')}</dt>
            <dd>{transaction.receipt_id}</dd>
            <dt> {t('Total bill')}</dt>
            <dd>{transaction.total_amount}</dd>
            <dt>{t('Paid by')}</dt>
            <dd>{transaction.customer}</dd>
            {transaction.paid_via && (
              <>
                <dt>{t('Paid via')}</dt>
                <dd>{transaction.paid_via}</dd>
              </>
            )}
            {transaction.additional_details && (
              <>
                <dt>{t('Additional Details')}</dt>
                <dd>
                  {transaction.additional_details &&
                    transaction.additional_details.map((item, index) => {
                      return (
                        <p key={index} className={styles.additionalDetails}>
                          {item.field_name} : {item.field_value}
                        </p>
                      );
                    })}
                </dd>
              </>
            )}
            {(transaction.rpp_participant || transaction.flp_client) &&
              renderPaidViaColumn(
                transaction.rpp_participant || transaction.flp_client
              )}
          </dl>
          <div className={styles.buttonContainer}>
            {!isMaple && transaction.cancellable && (
              <CancelButton
                className={classNames(styles.button, styles.cancel)}
                onClick={onOpenCancellationCodeModal}
                children={t('Cancel Transaction')}
              />
            )}
          </div>
        </Card>
      )}
    </>
  );

  return (
    <>
      {isStaffMode ? (
        <OpsLayout>
          <TransactionDetailContent />
        </OpsLayout>
      ) : (
        <AppLayout>
          <TransactionDetailContent />
        </AppLayout>
      )}
    </>
  );
};

export default TransactionDetail;
