import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from 'assets/css/ReportView.module.scss';
import Card from 'components/Card';

// todo:
//      replace the components in ReportView.jsx with these components to make them reusable
//      Also move the styles in ReportView.module.scss to a separate file

type ClassName = { className?: string };

export const OptionsCard: FC<PropsWithChildren<ClassName>> = ({
  children,
  className
}) => (
  <Card className={classNames(styles.card, styles.reportCard, className)}>
    {children}
  </Card>
);

export const OptionsLabel: FC<PropsWithChildren<ClassName>> = ({
  children,
  className
}) => <p className={classNames(styles.selectReport, className)}>{children}</p>;

export const BadgeWrapper: FC<PropsWithChildren<ClassName>> = ({
  children,
  className
}) => (
  <section className={classNames(styles.reportTypeBadges, className)}>
    {children}
  </section>
);
