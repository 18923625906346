import React from 'react';
import classNames from 'classnames';
import styles from 'assets/css/Card.module.scss';

export interface ICardProps {
  className: string;
  id?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Card: React.FC<ICardProps> = ({
  className,
  id,
  style,
  children,
  onClick
}) => (
  <section
    id={id}
    className={classNames(styles.card, className)}
    style={style}
    onClick={onClick}
  >
    {children}
  </section>
);

export default Card;
