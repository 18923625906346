export const todaysDate = new Date().toISOString().slice(0, 10);

export const getYMDFormat = (dateObj:Date) => {
  // format date with offset timezone to Year-Month-Day format for calendar
  const date = new Date(dateObj);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10);
};

export const timezone = (dateObj:Date|string) => dateObj.toString();
