import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from 'assets/css/UserTipText.module.scss';
import Icon from './Icon';

export type UserTipTextProps = {
  className?: string;
  icon: string;
  color?: string;
};

export const UserTipBlock = ({
  children,
  className
}: PropsWithChildren<{ className?: string }>) => (
  <div className={classNames(styles.userTipBlock, className)}>{children}</div>
);

const UserTipText = ({
  icon,
  color = 'charcoal-gray',
  className,
  children
}: React.PropsWithChildren<UserTipTextProps>) => {
  const classes = classNames(styles.textTip, className);

  return (
    <div
      className={classes}
      style={{
        color: `var(--${color})`
      }}
    >
      <Icon className={styles.icon} icon={icon} color={color} size={'1rem'} />
      {children}
    </div>
  );
};

export default UserTipText;
