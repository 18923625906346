import { useEffect, useState } from 'react';

const useViewportTrigger = (mediaQuery: string) => {
  const [matches, setMatches] = useState(false);

  const mediaQueryList = window.matchMedia(mediaQuery);

  useEffect(() => {
    const eventName = 'change';

    setMatches(mediaQueryList.matches);

    const changeHandler = ({ matches }: MediaQueryListEvent) =>
      setMatches(matches);

    if (mediaQueryList.addEventListener)
      mediaQueryList.addEventListener(eventName, changeHandler);
    else mediaQueryList.addListener(changeHandler);

    return () => {
      if (mediaQueryList.removeEventListener)
        mediaQueryList.removeEventListener(eventName, changeHandler);
      else mediaQueryList.removeListener(changeHandler);
    };
  }, [mediaQueryList]);

  return { matches };
};

export default useViewportTrigger;
