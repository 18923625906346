import FaveBizAPI from 'integrations/FaveBizAPI';
import { FeedPostStatus, FeedPostTypes } from 'uiHelpers/feedForm';

export type Deal = {
  name: string;
  purchase_count: number;
};

export type eCard = {
  company_name: string;
  company_id: string;
  company_logo: string;
  background_color: string;
  total_value: string;
  payable_amount: string;
  bonus_credit_amount: string;
  validity: Date;
  theme: string;
};

type FeatureUnion = Deal & eCard;

export interface FeatureItem extends FeatureUnion {
  id: number;
  type: FeedPostTypes;
  start_date: Date;
  end_date: Date;
  sold_count: number;
  post_details: {
    id: number;
    status: FeedPostStatus;
    start_at: Date;
  };
  share?: {
    url: string;
    description: string;
  };
  images: string[];
  outlets: string[];
}

type ListingFetchParam = {
  featureType: FeedPostTypes;
  company_id: number;
  page: number;
  //limit: number;
};

export type FeatureResponse = {
  deals: FeatureItem[];
  e_cards: FeatureItem[];
  list: FeatureItem[];
  page: number;
  total_count: number;
};

export const getFeaturedList = (
  param: ListingFetchParam
): Promise<FeatureResponse> =>
  param.featureType === FeedPostTypes.Deals
    ? FaveBizAPI.get(`/v1/deals`, param)
    : FaveBizAPI.get(`/v1/e_cards`, param);
