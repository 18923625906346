import React, { useState, useContext, useRef } from 'react';
import { Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import useCloseOnElementBlur from 'hooks/useCloseOnElementBlur';
import styles from 'assets/css/FloatingActionButton.module.scss';
import useCleverTapOld from 'hooks/useCleverTapOld';

import { NATIVE, call } from 'utils/nativeInterface';
import { WebviewContext } from 'contexts/WebviewContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';
import { MapleContext } from 'contexts/MapleContext';
import Button from 'components/Button';
import floatingIcon from 'assets/images/ic-floating-button.svg';
import floatingCloseIcon from 'assets/images/ic-close-icon.svg';
import { ReactComponent as ReceivePaymentIcon } from 'assets/images/receive-payment.svg';
import redeemIcon from 'assets/images/ic-redeem.svg';
import alipayIcon from 'assets/images/ic-alipay.svg';
import reportsIcon from 'assets/images/reports-icon.svg';

type FloatingActionButtonProps = {
  data: {
    id: number;
  };
  outlet: {
    id: number;
  };
};

type ToolCardProps = {
  img: string;
  title: string;
  link: string;
  event: string;
  enabled: boolean;
};

function useOutletFeatures() {
  const { settings } = useContext(OpsAuthContext);
  const { isMaple } = useContext(MapleContext);

  const isMY = settings.city_slug === 'kuala-lumpur';
  const allowDeals = !!settings.features.voucher && !isMaple;
  const allowAliPay = !!settings.features.alipay && isMY && !isMaple;
  const allowQR = isMY
    ? settings.outlet.can_receive_payment
    : settings.features.fave_payment;
  return { alipay: allowAliPay, deals: allowDeals, qr: allowQR };
}

const FloatingActionButtonModal: React.FC<FloatingActionButtonProps> = props => {
  const { t } = useTranslation();
  const { isMaple } = useContext(MapleContext);
  const elementRef = useRef<HTMLDivElement>(null);
  const [modalShow, setModalShow] = useState(false);
  const { isWebview } = useContext(WebviewContext);
  const allow = useOutletFeatures();
  const cleverTap = useCleverTapOld();

  useCloseOnElementBlur({ elementRef, setIsOpen: setModalShow } as any);

  const handleQRClicked = ({ id }: FloatingActionButtonProps['data']) =>
    isWebview
      ? call(NATIVE.ON_QR_CLICKED, id)
      : navigate('/operations/payment');

  const ToolCard = ({ img, title, link, event }: ToolCardProps) => (
    <Link
      to={link}
      onClick={() =>
        cleverTap.tapped.push({
          screen_name: 'tools',
          tapped_on: event
        })
      }
    >
      <div className={styles.miniCardContainer}>
        <img src={img} alt={title} />
        <h5>{title}</h5>
      </div>
    </Link>
  );

  const tools = [
    {
      enabled: allow.deals,
      link: '/operations/redemption',
      img: redeemIcon,
      title: t('Redeem'),
      event: 'btn_redeem'
    },
    {
      enabled: allow.alipay,
      link: '/operations/transactions/alipay',
      img: alipayIcon,
      title: t('Alipay'),
      event: 'btn_alipay'
    },
    {
      enabled: true,
      link: '/operations/reports',
      img: reportsIcon,
      title: t('Reports'),
      event: 'btn_reports'
    }
  ].filter(tool => tool.enabled);

  const ActionModalMobile = (
    props: Pick<FloatingActionButtonProps, 'data'>
  ) => (
    <div className={styles.container}>
      <h5>{t('Tools')}</h5>
      <div className={styles.cardContainer}>
        {allow.qr && (
          <div
            className={classnames(
              styles.miniCardContainer,
              styles.receivePayment
            )}
            onClick={() => {
              cleverTap.tapped.push({
                screen_name: 'tools',
                tapped_on: 'btn_outlet_qrcode'
              });
              handleQRClicked(props.data);
            }}
          >
            <ReceivePaymentIcon />
            <h5>{t('Payment')}</h5>
          </div>
        )}
        {!isMaple &&
          tools.map(tool => (
            <ToolCard
              key={tool.link}
              link={tool.link}
              img={tool.img}
              title={tool.title}
              enabled={tool.enabled}
              event={tool.event}
            />
          ))}
      </div>
    </div>
  );

  return (
    <>
      {modalShow && <div className={styles.pageMask}></div>}
      <div
        ref={elementRef}
        className={classnames(styles.floatButton, !modalShow && styles.visible)}
      >
        <Button
          type="ghost"
          onClick={() => {
            cleverTap.tapped.push({
              screen_name: 'transaction',
              tapped_on: 'btn_floating_tools'
            });
            cleverTap.screenDisplayed.push({ screen_name: 'tools' });
          }}
        >
          <img src={floatingIcon} alt="fl" />
        </Button>
      </div>
      <div
        className={classnames(styles.floatingBar, modalShow && styles.visible)}
      >
        <Button type="ghost">
          <img src={floatingCloseIcon} alt="fl" />
        </Button>
        <ActionModalMobile data={props.outlet} />
      </div>
    </>
  );
};

export default FloatingActionButtonModal;
