import React, { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Slider, SliderMarker } from '@reach/slider';
import '@reach/slider/styles.css';

import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';
import { NotificationContext } from 'contexts/NotificationContext';

import useCleverTapOld from 'hooks/useCleverTapOld';
import useECardModal from 'hooks/useECardModal';
import Cookie from 'helpers/Cookie';

import getECardsPresets from 'queries/getECardsPresets';
import getECardsCalculation from 'queries/getECardsCalculation';
import createECard from 'commands/createECard';

import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import Button from 'components/Button';
import ECard from 'components/ECard';
import CostStructure from 'components/CostStructure';

import styles from 'assets/css/ECardsSetupView.module.scss';
const MY_TNC = '/MY_Fave_Merchant_Agreement.pdf';
const SG_TNC = '/SG_Fave_Merchant_Agreement.pdf';

const ECardsSetupView = () => {
  const { t } = useTranslation();
  const { currentCompany = {} } = useContext(OutletContext);
  const { user, isValidUser } = useContext(AuthContext);
  const { setNotification } = useContext(NotificationContext);
  const cleverTap = useCleverTapOld();

  const currency = isValidUser && user.city.currency_display;
  const isSingapore = isValidUser && user.city.slug === 'singapore';
  const {
    logo_url: outletImage,
    partner_name: companyName,
    parent_category_name: companyCategory,
    num_bank_accounts
  } = currentCompany || {};
  const outletNo = (currentCompany.outlets || []).length;
  const [preset, setPresets] = useState();
  const [originalPrice, setOriginalPrice] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [takebackProfit, setTakebackProfit] = useState(0);
  const [calculatedResults, setCalculatedResults] = useState();

  const NOTCH_COUNT = 12;
  const DISCOUNT_STEP = 10;

  const presetResponse = getECardsPresets({
    company_id: currentCompany.id
  });

  const {
    upper_price_limit,
    lower_price_limit,
    price_incremental,
    gst,
    commission,
    commission_enabled
  } = preset || {};

  const { modals, openECardModal } = useECardModal({
    companyCategory,
    commission,
    discountRate,
    originalPrice,
    currency,
    sellingPrice,
    outletImage,
    companyName,
    isSingapore
  });

  const [sentCTEvent, setSentCTEvent] = useState(false);
  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'ecard_setup1' });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed]);

  useEffect(() => {
    if (!presetResponse.data) return;
    const { e_card_preset } = presetResponse.data;
    setPresets(e_card_preset);
    setOriginalPrice(e_card_preset.price);
    setDiscountRate(e_card_preset.min_discount_rate);
  }, [presetResponse.data]);

  useEffect(() => {
    setSellingPrice(+(originalPrice * ((100 - discountRate) / 100)).toFixed(2));
  }, [originalPrice, discountRate]);

  useEffect(() => {
    const actualCommission = commission_enabled ? commission : 0;
    setTakebackProfit(
      +(
        sellingPrice -
        ((sellingPrice * actualCommission) / 100 +
          ((sellingPrice * actualCommission) / 100) * (gst / 100))
      ).toFixed(2)
    );
  }, [sellingPrice, preset, gst, commission, commission_enabled]);

  const confirmationCheck = () => {
    cleverTap.tapped.push({
      screen_name: 'ecard_setup1',
      tapped_on: 'btn_continue'
    });

    if (discountRate >= 50) {
      openECardModal({
        modal: {
          ...modals.confirmation,
          options: {
            onYes: () => {
              cleverTap.tapped.push({
                screen_name: 'ecard_setup1',
                tapped_on: 'btn_yes_sure'
              });
              onConfirm();
            }
          }
        }
      });
    } else {
      onConfirm();
    }
  };

  const onConfirm = () => {
    getECardsCalculation({
      company_id: currentCompany.id,
      original_price: originalPrice,
      selling_price: sellingPrice
    })
      .then(response => {
        setCalculatedResults(response.e_card);
        cleverTap.screenDisplayed.push({
          screen_name: 'ecard_setup2_presubmission'
        });
      })
      .catch(error =>
        setNotification({
          message: error.value.response.error,
          type: 'error'
        })
      );
  };

  const onSubmitECard = () => {
    createECard({
      company_id: currentCompany.id,
      original_price: calculatedResults.original_price,
      selling_price: calculatedResults.selling_price
    }).then(response => {
      cleverTap.tapped.push({
        screen_name: 'ecard_setup2_presubmission',
        tapped_on: 'btn_submit_ecard'
      });
      Cookie.set('eCardSubmitted', response.e_card);
      window.location.assign('/ecards');
    });
  };

  return (
    <AppLayout>
      <Heading
        title={t('Set up eCards')}
        subtitle={t(
          'Sell more, get paid upfront. It’s like gift cards, but better.'
        )}
      />
      {calculatedResults && (
        <Button
          leftIcon="arrow-left"
          type="text"
          onClick={() => setCalculatedResults()}
        >
          {t('Back')}
        </Button>
      )}

      {preset && (
        <section className={styles.layout}>
          <div className={styles.eCardContainer}>
            <ECard
              sellingPrice={
                calculatedResults
                  ? calculatedResults.selling_price
                  : sellingPrice
              }
              discountRate={
                calculatedResults
                  ? calculatedResults.discount_rate
                  : discountRate
              }
              originalPrice={
                calculatedResults
                  ? calculatedResults.original_price
                  : originalPrice
              }
              outletImage={outletImage}
              companyName={companyName}
              currency={currency}
            />
            <small className={styles.previewText}>
              {t('This is only a preview, the actual card design might vary.')}
            </small>
          </div>

          {!calculatedResults && (
            <div className={styles.setupContainer}>
              <h2 className={styles.heading}>{t('Set eCard Price')}</h2>
              <p className={styles.subheading}>
                {t(
                  'Determine the original price and selling price for your eCard.'
                )}
              </p>
              <section className={styles.slidersContainer}>
                <div className={styles.slider}>
                  <h3 className={styles.name}>
                    {t('Original Price')} ({currency})
                    <Button
                      className={styles.info}
                      type="ghost"
                      leftIcon="deals-info"
                      iconColor="bali-hai"
                      onClick={() =>
                        openECardModal({ modal: modals.originalPrice })
                      }
                      children=""
                    />
                  </h3>
                  <p className={styles.value}>{originalPrice}</p>
                  <Slider
                    value={originalPrice}
                    onChange={setOriginalPrice}
                    min={lower_price_limit}
                    max={upper_price_limit}
                    step={price_incremental}
                  >
                    {Array.from({ length: NOTCH_COUNT }).map((x, index) => {
                      return (
                        <SliderMarker
                          title={t('RECOMMENDED')}
                          data-recommended={
                            lower_price_limit + index * price_incremental ===
                              preset.price && originalPrice !== preset.price
                          }
                          key={index}
                          value={
                            index === 0
                              ? lower_price_limit
                              : lower_price_limit + index * price_incremental
                          }
                        />
                      );
                    })}
                  </Slider>
                </div>

                <div className={styles.slider}>
                  <h3 className={styles.name}>{t('Discount Rate')}</h3>
                  <p className={styles.value}>{discountRate}%</p>
                  <Slider
                    value={discountRate}
                    onChange={setDiscountRate}
                    min={preset.min_discount_rate}
                    max={preset.max_discount_rate}
                    step={DISCOUNT_STEP}
                  >
                    {Array.from({ length: NOTCH_COUNT }).map((x, index) => (
                      <SliderMarker key={index} value={index * DISCOUNT_STEP} />
                    ))}
                  </Slider>
                </div>

                <div className={styles.result}>
                  <h3 className={styles.name}>
                    {t('Selling Price')} ({currency})
                    <Button
                      className={styles.info}
                      type="ghost"
                      leftIcon="deals-info"
                      iconColor="bali-hai"
                      onClick={() =>
                        openECardModal({ modal: modals.sellingPrice })
                      }
                      children=""
                    />
                  </h3>
                  <p className={styles.value}>{sellingPrice}</p>
                </div>
              </section>

              <h3 className={styles.heading}>
                {t('Your takeback profit')}{' '}
                <Button
                  className={styles.info}
                  type="ghost"
                  leftIcon="deals-info"
                  iconColor="bali-hai"
                  onClick={() =>
                    openECardModal({ modal: modals.takebackProfit })
                  }
                  children=""
                />
              </h3>
              <p className={styles.profit}>
                {currency}
                {takebackProfit}{' '}
                <small>
                  (
                  {isSingapore
                    ? t('{{commission}}% commission rate & {{gst}}% GST', {
                        commission: commission_enabled ? commission : 0,
                        gst
                      })
                    : commission_enabled
                    ? t('{{commission}}% commission rate', { commission })
                    : t('Commission waived')}
                  )
                </small>
              </p>

              <Button
                onClick={confirmationCheck}
                className={styles.submitButton}
                justify="center"
                fullWidth
                type="primary"
              >
                {t('Continue')}
              </Button>
            </div>
          )}

          {calculatedResults && (
            <div className={styles.costContainer}>
              <CostStructure
                className={styles.costStructure}
                currency={currency}
                originalPrice={calculatedResults.original_price}
                sellingPrice={calculatedResults.selling_price}
                discountRate={calculatedResults.discount_rate}
                commissionRate={commission} // TODO: Use calculated result commission rate after 30/4/2020
                gstCommission={gst}
                takebackProfit={calculatedResults.takeback_profit}
                outletNo={outletNo}
                isSingapore={isSingapore}
                isSetup
                commissionEnabled={commission_enabled}
                showDisbursementMethod={num_bank_accounts > 1}
              />
              <Button
                onClick={onSubmitECard}
                className={styles.submitButton}
                justify="center"
                fullWidth
                type="primary"
              >
                {t('Submit')}
              </Button>{' '}
              <Trans>
                <small className={styles.tnc}>
                  By creating an eCard, you accept our
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={isSingapore ? SG_TNC : MY_TNC}
                  >
                    {` `}Terms and Conditions
                  </a>
                </small>
              </Trans>
            </div>
          )}
        </section>
      )}
    </AppLayout>
  );
};

export default ECardsSetupView;
