import React from 'react';
import { Trans } from 'react-i18next';
import UserTipStrip from './UserTipStrip';
import InlineLink from './InlineLink';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import useChat from 'hooks/useChat';

const InactiveOutletTip = () => {
  const { chatText, openWidget } = useChat();

  const handleChatClick = () => openWidget();

  return (
    <UserTipStrip>
      <span>
        <Trans>
          {'These outlets are inactive, '}
          <InlineLink
            children={chatText}
            onClick={handleChatClick}
            event={{
              screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY,
              section_name: CLEVERTAP_SECTION_NAME.BANNER,
              tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_LIVE_CHAT
            }}
          />
          {' to reactivate them.'}
        </Trans>
      </span>
    </UserTipStrip>
  );
};

export default InactiveOutletTip;
