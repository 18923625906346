import DataLoader from 'dataloader';
import Kfit from 'integrations/Kfit';
import { REVIEWS } from 'helpers/TypeConstants';

function loadInfo(key, reload) {
  const Repo = new DataLoader(
    keys =>
      Promise.all(keys.map(key => Kfit.get(key).then(response => response))),
    { batch: false, cacheKeyFn: key => JSON.stringify(key) }
  );
  if (reload) Repo.clearAll();
  return Repo.load(key);
}

export const getAverageRatings = companyId =>
  loadInfo(`/v6/reviews/summary?company_id=${companyId}`);

const loadReviews = ({
  type = undefined,
  unreplied = undefined,
  companyId,
  pageNumber = 1,
  perPage = 15,
  sortBy,
  reload = false
}) =>
  loadInfo(
    [
      `/v6/reviews/${companyId}`,
      `?page=${pageNumber}`,
      `&per_page=${perPage}`,
      `&sort_by=${sortBy}`,
      type && `&type=${type}`,
      unreplied && `&unreplied=true`
    ]
      .filter(d => d)
      .join(''),
    reload
  );

export const getFavepayReviews = ({
  companyId,
  pageNumber,
  perPage,
  sortBy,
  reload
}) =>
  loadReviews({
    type: REVIEWS.FAVEPAY.toLowerCase(),
    companyId,
    pageNumber,
    perPage,
    sortBy,
    reload
  });

export const getFavedealsReviews = ({
  companyId,
  pageNumber,
  perPage,
  sortBy,
  reload
}) =>
  loadReviews({
    type: REVIEWS.DEALS.toLowerCase(),
    companyId,
    pageNumber,
    perPage,
    sortBy,
    reload
  });

export const getAllReviews = ({
  companyId,
  pageNumber,
  perPage,
  sortBy,
  reload
}) =>
  loadReviews({
    companyId,
    pageNumber,
    perPage,
    sortBy,
    reload
  });

export const getUnrepliedReviews = ({
  companyId,
  pageNumber,
  perPage,
  sortBy,
  reload
}) =>
  loadReviews({
    unreplied: true,
    companyId,
    pageNumber,
    perPage,
    sortBy,
    reload
  });
