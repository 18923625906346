import React, { useState, useEffect, useContext } from 'react';
import DOMPurify from 'dompurify';
import Parser from 'html-react-parser';

import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import { WebviewContext } from 'contexts/WebviewContext';
import useCleverTapOld from 'hooks/useCleverTapOld';

import getDealDetails from 'queries/getDealDetails';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import WebviewHeading from 'components/WebviewHeading';
import Button, { TappedButton } from 'components/Button';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/DealDetailsView.module.scss';
import GuaranteeFeeLabel from 'components/GuaranteeFeeLabel';

const DealDetails = props => {
  const { t } = useTranslation();
  const [deal, setDeal] = useState(null);
  const [dealOutlet, setDealOutlet] = useState();
  const { outlets } = useContext(OutletContext);
  const { user } = useContext(AuthContext);
  const { isWebview } = useContext(WebviewContext);
  const cleverTap = useCleverTapOld();

  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'deals_details' });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  useEffect(() => {
    let isMounted = true;
    getDealDetails({ id: props.deal_id })
      .then(res => {
        if (!isMounted) return;
        setDeal(res.voucher);
      })
      .catch(err => {});
    return () => (isMounted = false);
  }, [props]);

  useEffect(() => {
    if (!deal || !outlets) return;
    const getOutletName = () =>
      outlets.find(outlet => deal.outlet_ids.includes(outlet.id)).name;
    setDealOutlet(getOutletName());
  }, [deal, outlets]);

  const formatDate = date =>
    new Date(date).toLocaleDateString('en-my', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading title="FaveDeals" backURL={'/deals'} />
      ) : (
        <Heading
          title={'FaveDeals'}
          subtitle={t('View your FaveDeals performance at your outlet')}
        />
      )}
      {!isWebview && (
        <Button
          leftIcon="arrow-left"
          type="text"
          href={'/deals'}
          className="backToDealRoute"
          iconColor="light-black"
          children={t('Back')}
          textColor="charcoal-gray"
        />
      )}
      {deal && user.city && (
        <article
          className={styles.dealContainer}
          style={{
            '--bg': `url(${deal.cover_image_url})`
          }}
        >
          <header className={styles.dealHeader}>
            <h3 className={styles.smallTitle}>
              {t('Deal ID:')} {deal.id}
            </h3>
            <TappedButton
              href={`https://myfave.com/o?id=${deal.id}&city=${user.city.slug}`}
              className={styles.dealLink}
              iconSize="1rem"
              rightIcon="new-tab"
              type="text"
              textColor="blue"
              iconColor="blue"
              event={{
                screen_name: 'deals_details',
                tapped_on: 'btn_view_deals'
              }}
            >
              {t('View deal')}
            </TappedButton>
            <h2 className={styles.dealTitle}>{deal.name}</h2>
          </header>

          <dl className={styles.dealDetail}>
            <dt className={styles.date}>{t('Feature Date')}</dt>
            <dd>
              <time dateTime={deal.start_date}>
                {formatDate(deal.start_date)}
              </time>{' '}
              -{' '}
              <time dateTime={deal.end_date}>{formatDate(deal.end_date)}</time>
            </dd>
            <dt className={styles.price}>{t('Price')}</dt>
            <dd>
              {deal.discounted_price} <span>{deal.original_price}</span>
              <GuaranteeFeeLabel
                guarunteeFee={deal.guarantee_fee_percentage}
                isTransactionCard={false}
              />
            </dd>
            <dt className={styles.outlets}>{t('Outlets')}</dt>
            <dd>
              {dealOutlet ? dealOutlet : t('No outlets available')}{' '}
              {deal &&
                deal.outlet_ids.length > 1 &&
                t('+ {{otherOutlets}} other outlet(s)', {
                  otherOutlets: deal.outlet_ids.length - 1
                })}
              <TappedButton
                type="text"
                iconColor="pelorous"
                size="xs"
                iconSize="1rem"
                rightIcon="angle-right"
                href={`/deals/${deal.id}/revenue?from=/deals/${deal.id}`}
                event={{
                  screen_name: 'deals_details',
                  tapped_on: 'btn_revenue_breakdown'
                }}
              >
                {t('Revenue breakdown')}
              </TappedButton>
            </dd>
            {deal.gallery_images && (
              <>
                <dt className={styles.photos}>{t('Photos')}</dt>
                <dd>
                  <ul className={styles.dealGallery}>
                    {(deal.gallery_images || []).map(src => (
                      <li key={src}>
                        <img
                          alt="gallery"
                          className={styles.galleryImage}
                          src={src}
                        />
                      </li>
                    ))}
                  </ul>
                </dd>
              </>
            )}
          </dl>
          <dl className={styles.dealTNC}>
            <dt className={styles.customer}>{t('What Customers get?')}</dt>
            <dd>{Parser(DOMPurify.sanitize(deal.what_you_get))}</dd>
            <dt className={styles.redemption}>{t('Redemption Information')}</dt>
            <dd>{Parser(DOMPurify.sanitize(deal.redemption_instructions))}</dd>
            <dt className={styles.finePrint}>{t('Fine Print')}</dt>
            <dd>{Parser(DOMPurify.sanitize(deal.fine_print))}</dd>
          </dl>
        </article>
      )}
    </AppLayout>
  );
};

export default DealDetails;
