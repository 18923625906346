import React, { useContext, useState, useEffect, useCallback } from 'react';
import { navigate, Redirect } from '@reach/router';
import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';
import useCleverTapOld from 'hooks/useCleverTapOld';
import getFavePay from 'queries/getFavePay';
import AppLayout from 'layouts/AppLayout';
import Grid from 'components/Grid';
import Heading from 'components/Heading';
import FavePaySetupDoneModal from './FavePaySetupView/FavePaySetupDoneModal';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
  CLEVERTAP_SECTION_NAME,
  FAVE_PAY_CAMPAIGN,
  SELF_ONBOARDING_STATUS
} from 'helpers/TypeConstants';
import routes from 'helpers/routesMap';
import { useTranslation } from 'react-i18next';
import useOnceModal from 'hooks/useOnceModal';
import styles from 'assets/css/FavePayView.module.scss';
import ToggleFilter from 'components/common/ToggleFilter';
import OutletCard from 'components/OutletCard';
import EmptyOutletListStrip from 'components/EmptyOutletListStrip';
import InfoIcon from 'assets/images/info-icon.svg';
import { NATIVE, call } from 'utils/nativeInterface';
import { WebviewContext } from 'contexts/WebviewContext';
import InactiveOutletTip from 'components/InactiveOutletTip';
import PaymentSetupTip from 'components/PaymentSetupTip';
import WebviewHeading from 'components/WebviewHeading';
import StarterKitTip from 'components/StarterKitTip';

const FavePayView = () => {
  const { t } = useTranslation();
  const outletContext = useContext(OutletContext);
  const { currentCompany, currentOutlet, outlets } = outletContext;
  const { user } = useContext(AuthContext);
  const [faveResponse, setFaveResponse] = useState([]);
  const [isLoadingOutlets, setIsLoadingOutlets] = useState(true);
  const [isShowActiveOutlets, setShowingActiveOutlets] = useState(true);

  const isAllOutlets = currentOutlet === '';

  const cleverTap = useCleverTapOld();
  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  const { isWebview } = useContext(WebviewContext);

  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY
    });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  const isFavePayActive =
    outlets && outlets.some(outlet => outlet.has_fave_payment === true);

  const isSelfOnboardingStatusEqual = useCallback(
    status => {
      return currentOutlet === ''
        ? (outlets || []).some(data => data.self_onboarding_status === status)
        : (currentOutlet || {}).self_onboarding_status === status;
    },
    [outlets, currentOutlet]
  );

  useOnceModal({
    Component: FavePaySetupDoneModal,
    cookieName: 'FavePaySetupDoneModal',
    openOnReady: true,
    openOnlyIf: useCallback(
      () =>
        isSelfOnboardingStatusEqual(SELF_ONBOARDING_STATUS.STARTER_KIT) ||
        isSelfOnboardingStatusEqual(SELF_ONBOARDING_STATUS.COMPLETED),
      [isSelfOnboardingStatusEqual]
    ),
    openCallback: () =>
      cleverTap.screenDisplayed.push({
        screen_name: 'payment_setup_success',
        outletContext
      }),
    componentProps: { outletContext }
  });

  const shouldShowFinishPaymentSetup = isSelfOnboardingStatusEqual(
    SELF_ONBOARDING_STATUS.LINK_BANK_ACCOUNT
  );

  const shouldShowSarterKitBanner = isSelfOnboardingStatusEqual(
    SELF_ONBOARDING_STATUS.STARTER_KIT
  );

  useEffect(() => {
    let isMounted = true;

    if (!(user && user.city && currentCompany && currentCompany.outlets)) {
      return;
    }

    getFavePay({
      campaign_type: FAVE_PAY_CAMPAIGN,
      company_id: currentOutlet ? null : currentCompany.id,
      outlet_id: currentOutlet ? currentOutlet.id : null
    }).then(response => {
      if (!isMounted) return;
      setFaveResponse(response.favepay || []);
      setIsLoadingOutlets(false);
    });

    return () => (isMounted = false);
  }, [currentCompany, currentOutlet, user]);

  const outletList = (isAllOutlets
    ? faveResponse
    : currentOutlet
    ? [currentOutlet]
    : []
  ).filter(outlet => outlet.has_fave_payment === isShowActiveOutlets);

  const onClickFavePayInfoIcon = () => {
    if (isWebview) {
      call(NATIVE.FAVEPAY_VIEW_EDUCATION);
    } else {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_INFO
      });
      window.open('https://www.favebiz.com/login/payments', '_blank');
    }
  };

  const FavePayInfoIcon = () => {
    return (
      <img
        onClick={() => onClickFavePayInfoIcon()}
        className={styles.favePayInfoIcon}
        src={InfoIcon}
        alt="view favepay info"
      />
    );
  };

  const onClickViewOutletInfo = outletID => {
    if (isWebview) {
      call(NATIVE.FAVEPAY_VIEW_OUTLET_INFO, outletID);
    } else {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_FAVEPAY_VIEW_INFO
      });
      navigate(`/favepay/outlet/${outletID}?from=favepay`);
    }
  };

  const onReceivePaymentClick = outletID => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_FAVEPAY_RECEIVE_PAYMENT
    });
    navigate(`/payment/${outletID}?from=/favepay`);
  };

  const isShowingInactiveOutlets =
    !isShowActiveOutlets && outletList.length > 0;

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading
          title={t('FavePay')}
          onBack={() => call(NATIVE.CLOSE_WEBVIEW)}
          IconElement={<FavePayInfoIcon />}
        />
      ) : (
        <Heading
          title="FavePay"
          subtitle={t('View your FavePay performance at your outlet')}
          iconElement={!isWebview && <FavePayInfoIcon />}
        />
      )}
      {isLoadingOutlets ? (
        <LoadingSpinner />
      ) : (
        (!isFavePayActive && <Redirect noThrow to={routes.moreServices} />) || (
          <>
            <ToggleFilter
              value={isShowActiveOutlets}
              onChange={setShowingActiveOutlets}
              activeButtonProps={{
                children: t('Active'),
                event: {
                  screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY,
                  section_name: CLEVERTAP_SECTION_NAME.FILTER_TABS,
                  tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ACTIVE
                }
              }}
              inactiveButtonProps={{
                children: t('Inactive'),
                event: {
                  screen_name: CLEVERTAP_SCREEN_NAME.FAVEPAY,
                  section_name: CLEVERTAP_SECTION_NAME.FILTER_TABS,
                  tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_INACTIVE
                }
              }}
            />

            {isShowingInactiveOutlets && <InactiveOutletTip />}

            {shouldShowFinishPaymentSetup && isShowActiveOutlets && (
              <PaymentSetupTip />
            )}

            {shouldShowSarterKitBanner && isShowActiveOutlets && (
              <StarterKitTip />
            )}

            {outletList.length > 0 ? (
              <Grid>
                {outletList.map(outlet => {
                  return (
                    <OutletCard
                      key={outlet.id}
                      outlet={outlet}
                      showCashbackLabel={true}
                      onReceivePaymentClick={() =>
                        onReceivePaymentClick(outlet.id)
                      }
                      onViewInfoClick={() => onClickViewOutletInfo(outlet.id)}
                    />
                  );
                })}
              </Grid>
            ) : (
              <EmptyOutletListStrip />
            )}
          </>
        )
      )}
    </AppLayout>
  );
};

export default FavePayView;
