import { AuthContext } from 'contexts/AuthContext';
import find from 'lodash/find';
import React, { useCallback, useContext, useEffect, useState } from 'react';

export const OutletContext = React.createContext();
export const OutletProvider = props => {
  const {
    user,
    companyID,
    outletID,
    setCompanyID,
    setOutletID,
    setCurrentOutletCode
  } = useContext(AuthContext);
  const [currentCompany, setCurrentCompany] = useState();
  const [currentOutlet, setCurrentOutlet] = useState();
  const currentCompanyOutlets = (currentCompany || {}).outlets || [];

  const changeOutlet = useCallback(
    (id, outlets = currentCompanyOutlets) => {
      const outlet = find(outlets, { id: +id });
      const singleOutlet = outlets.length === 1;
      const [firstOutlet] = outlets;
      setCurrentOutlet(singleOutlet ? firstOutlet : outlet || '');
      setOutletID(singleOutlet ? firstOutlet.id : (outlet || {}).id || '');
      setCurrentOutletCode(
        singleOutlet ? firstOutlet.auth_code : (outlet || {}).auth_code || ''
      );
    },
    [currentCompanyOutlets, setOutletID, setCurrentOutletCode]
  );

  const changeCompany = useCallback(
    (companyID, outletID) => {
      const company = find(user.companies, { id: +companyID });
      setCurrentCompany(company);
      setCompanyID(company.id);
      changeOutlet(outletID, company.outlets);
    },
    [changeOutlet, user, setCompanyID]
  );

  useEffect(() => {
    if ((user.companies || []).length === 0) return;
    changeCompany(companyID, outletID);
  }, [user, companyID, outletID, changeCompany]);

  const implementation = {
    outlets: currentCompanyOutlets,
    currentCompany,
    currentOutlet,
    changeOutlet,
    changeCompany
  };

  return (
    <OutletContext.Provider value={implementation}>
      {props.children}
    </OutletContext.Provider>
  );
};

export const useOutletContext = () => useContext(OutletContext);
