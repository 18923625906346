import React from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';

import styles from 'assets/css/Accordion/AccordionItem.module.scss';

interface IAccordionItem {
  isActive: boolean;
  children: React.ReactElement[];
}

const AccordionItem: React.FC<IAccordionItem> = props => {
  const { isActive = false } = props;
  const AccordionHeader = props.children[0] || (() => <></>);
  const AccordionPanel = props.children[1] || (() => <></>);

  return (
    <div className={styles.layout}>
      <div>{AccordionHeader}</div>
      <AnimateHeight duration={250} height={isActive ? 'auto' : 0}>
        <section className={classNames(styles.panel)}>{AccordionPanel}</section>
      </AnimateHeight>
    </div>
  );
};

export default AccordionItem;
