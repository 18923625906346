import { useContext, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';

import { RelaymanContext } from 'contexts/RelaymanContext';
import { NotificationContext } from 'contexts/NotificationContext';
import useGetTransactions from 'queries/useGetTransactions';
import useGetTransactionsSummary from 'queries/useGetTransactionsSummary';
import { isObject, hasKeys } from 'utils';

import { STATE, EVENT } from './stateMachine';

export function useOutletSocketUpdate({ current, send, currentOutlet }) {
  const { onNewTransaction } = useContext(RelaymanContext);
  let hasSetCallbackRef = useRef(false);
  useEffect(() => {
    if (current.state !== STATE.IDLE) return;
    if (!isObject(currentOutlet)) return;
    if (hasSetCallbackRef.current) return;
    onNewTransaction(() => send(EVENT.NEW_TRANSACTIONS));
    hasSetCallbackRef.current = true;
  }, [current, send, currentOutlet, onNewTransaction]);
}

export function useScrollToTopForToday({ current }) {
  useEffect(() => {
    if (current.state !== STATE.FETCHING_FILTERS) return;
    if (current.hasCache) return;
    const isToday = current.date.isSame(moment(), 'day');
    if (isToday) window.scroll({ top: 0, behavior: 'smooth' });
  }, [current]);
}

export function useRefreshTransactions({ current, send, t }) {
  const { setNotification } = useContext(NotificationContext);
  useEffect(() => {
    if (current.state !== STATE.IDLE) return;
    if (!current.hasNewTransactions) return;

    const isToday = current.date.isSame(moment(), 'day');

    if (isToday && current.transactionSummaryVisible) {
      setNotification({
        message: t('Refreshing transactions...'),
        type: 'info'
      });
      send(EVENT.RESET);
    } else {
      send(EVENT.SHOW_NEW_TRANSACTIONS);
    }
  }, [current, send, setNotification, t]);
}

export function useHandleOutletChange({ send, currentOutlet }) {
  useEffect(() => {
    if (currentOutlet === '') send(EVENT.PICK_OUTLET);
    if (isObject(currentOutlet)) send(EVENT.FETCH_FILTERS);
  }, [currentOutlet, send]);
}

export function useErrorNotifications({ current }) {
  const { setNotification } = useContext(NotificationContext);
  useEffect(() => {
    if (!current.error) return;
    if (current.state !== STATE.IDLE) return;

    const { error } = current;
    setNotification({
      message: 'value' in error ? error.value.response.error : error.toString(),
      type: 'error'
    });
  }, [current, setNotification]);
}

export function useFirstTransactionUpdateNow({ current, send }) {
  const interval = useRef();
  useEffect(() => {
    let isMounted = true;
    if (current.firstTransactionVisible) {
      if (interval.current) clearInterval(interval.current);
      interval.current = setTimeout(() => {
        if (isMounted) send(EVENT.FIRST_TRANSACTION_VISIBLE);
      }, 1000 * 60);
    }
    return () => (isMounted = false);
  }, [current, send]);
}

export function useIntersectionObservers({ current, send }) {
  const createObserver = useCallback(
    function({ selector, onIntersectEvents = {}, shouldTriggerEvent = true }) {
      const targetElement = document.querySelector(selector);
      if (!targetElement) return;
      const options = {
        root: null /* watch visibility changes of target element relative to document viewport */,
        rootMargin: '0px',
        threshold: 1
      };
      function handleIntersect(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting ? shouldTriggerEvent : !shouldTriggerEvent) {
            send(onIntersectEvents[entry.isIntersecting]);
          }
        });
      }
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(targetElement);
      return observer;
    },
    [send]
  );

  useEffect(() => {
    if (current.state !== STATE.IDLE) return;

    // observe transaction summary for live transactions
    const liveTransactionsObserver = createObserver({
      selector: '#transactionSummary',
      onIntersectEvents: {
        true: EVENT.TRANSACTION_SUMMARY_VISIBLE,
        false: EVENT.TRANSACTION_SUMMARY_INVISIBLE
      },
      shouldTriggerEvent: !current.transactionSummaryVisible
    });

    let loadMoreObserver;
    let firstTransactionObserver;

    if (current.transactions.length !== 0) {
      // observe load more for infinite scroll
      loadMoreObserver = createObserver({
        selector: '#loadMoreTransactions',
        onIntersectEvents: { true: EVENT.FETCH_MORE_TRANSACTIONS }
      });

      // observe first transaction for "now" display
      firstTransactionObserver = createObserver({
        selector: '#firstTransaction',
        onIntersectEvents: {
          true: EVENT.FIRST_TRANSACTION_VISIBLE,
          false: EVENT.FIRST_TRANSACTION_INVISIBLE
        },
        shouldTriggerEvent: !current.firstTransactionVisible
      });
    }

    return () => {
      if (loadMoreObserver) loadMoreObserver.disconnect();
      if (liveTransactionsObserver) liveTransactionsObserver.disconnect();
      if (firstTransactionObserver) firstTransactionObserver.disconnect();
    };
  }, [current, send, createObserver]);
}

export function useOutletTransactions({ current, send, currentOutlet }) {
  const swrTransactions = useGetTransactions(
    {
      currentOutlet,
      type: current.filter,
      date: current.date.format('DD/MM/YYYY'),
      page: current.page
    },
    {
      if: [
        STATE.FETCHING_TRANSACTIONS,
        STATE.FETCHING_MORE_TRANSACTIONS
      ].includes(current.state),
      reload: !current.hasCache
    }
  );

  if (swrTransactions.data)
    send(EVENT.DONE, { data: swrTransactions.data.transactions });
  if (swrTransactions.error)
    send(EVENT.ERROR, { error: swrTransactions.error });
}

export function useOutletTransactionsSummary({ current, send, currentOutlet }) {
  const swrTransactionsSummary = useGetTransactionsSummary(
    {
      currentOutlet,
      type: current.filter,
      date: current.date.format('DD/MM/YYYY')
    },
    {
      if: [STATE.FETCHING_SUMMARY].includes(current.state),
      reload: true
    }
  );

  if (swrTransactionsSummary.data)
    send(EVENT.DONE, { data: swrTransactionsSummary.data });
  if (swrTransactionsSummary.error)
    send(EVENT.ERROR, { error: swrTransactionsSummary.error });
}

export function useOutletFilters({ current, send, settings, isStaffMode }) {
  if (current.state !== STATE.FETCHING_FILTERS) return;
  if (!hasKeys(settings) && isStaffMode) return;
  if (isStaffMode) {
    const {
      features: { fave_payment, alipay, voucher }
    } = settings;
    send(EVENT.DONE, {
      data: [
        'all',
        fave_payment && 'favepay',
        alipay && 'alipay',
        voucher && 'deals'
      ].filter(d => d)
    });
  } else {
    send(EVENT.DONE, {
      data: ['all'].filter(d => d)
    });
  }
}

export function useClearCache({ current, cache }) {
  if (!hasKeys(cache)) return cache;
  if (current.state !== STATE.IDLE) return cache;
  return {};
}
