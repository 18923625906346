import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Routes from 'Routes';
import { MapleContextProvider } from 'contexts/MapleContext';
import { AuthContextProvider } from 'contexts/AuthContext';
import { NotificationProvider } from 'contexts/NotificationContext';
import { OutletProvider } from 'contexts/OutletContext';
import { ModalProvider } from 'contexts/ModalContext';
import { CleverTapContextProvider } from 'contexts/CleverTapContext';
import { CleverTapContextProviderOld } from 'contexts/CleverTapContextOld';
import { HubSpotContextProvider } from 'contexts/HubSpotContext';
import { LangProvider } from 'contexts/LangContext';
import { QueryStringContextProvider } from 'contexts/QueryStringContext';
import { WebviewContextProvider } from 'contexts/WebviewContext';
import { FCMContextProvider } from 'contexts/FCMContext';
import { AddToHomescreenProvider } from 'contexts/AddToHomescreenContext';
import 'i18n';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadingSpinner from 'components/LoadingSpinner';

import 'assets/css/index.scss';
import { QueryClient, QueryClientProvider } from 'react-query';

export const defaultClientConfig = {
  retry: 0,
  refetchOnWindowFocus: false
};

export const defaultQueryClient = new QueryClient(defaultClientConfig);

export default function renderReactApp(rootEl) {
  ReactDOM.render(
    <Suspense fallback={<LoadingSpinner />}>
      <HubSpotContextProvider>
        <MapleContextProvider>
          <QueryStringContextProvider>
            <LangProvider>
              <CleverTapContextProvider>
                <CleverTapContextProviderOld>
                  <WebviewContextProvider>
                    <AuthContextProvider>
                      <ModalProvider>
                        <OutletProvider>
                          <NotificationProvider>
                            <FCMContextProvider>
                              <ErrorBoundary>
                                <AddToHomescreenProvider>
                                  <QueryClientProvider
                                    client={defaultQueryClient}
                                  >
                                    <Routes />
                                  </QueryClientProvider>
                                </AddToHomescreenProvider>
                              </ErrorBoundary>
                            </FCMContextProvider>
                          </NotificationProvider>
                        </OutletProvider>
                      </ModalProvider>
                    </AuthContextProvider>
                  </WebviewContextProvider>
                </CleverTapContextProviderOld>
              </CleverTapContextProvider>
            </LangProvider>
          </QueryStringContextProvider>
        </MapleContextProvider>
      </HubSpotContextProvider>
    </Suspense>,
    rootEl
  );
}
