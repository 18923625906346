import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';

import logo from '../assets/images/insights-logo.svg';
import Button from 'components/Button';
import InsightsOutletSwitcher from 'insights/components/InsightsOutletSwitcher';
import useNavigate from 'insights/hooks/useNavigate';
import useInsightsParams from 'insights/hooks/useInsightsParams';
import useCloseOnElementBlur from 'hooks/useCloseOnElementBlur';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'insights/assets/css/Header.module.scss';
import { copyToClipboard } from 'utils';

const DateRangePicker = React.lazy(() =>
  import('insights/components/DateRangePicker')
);

const Header = ({ location, hasEmailHeader, companyName }) => {
  const { startDate, endDate, outletId, country } = useInsightsParams();
  const navigate = useNavigate({ location });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const modalEl = useRef();
  const CleverTap = useCleverTapOld();

  const onChangeOutlet = useCallback(
    outletId => navigate({ outletId, startDate, endDate }),
    [navigate, endDate, startDate]
  );

  const onChangeDates = useCallback(
    ({ startDate: asStartDate, endDate: asEndDate, isOpen, isValid }) => {
      // // return if picker is open or dates invalid
      if (isOpen || !isValid) return;
      navigate({ outletId, startDate: asStartDate, endDate: asEndDate });
    },
    [outletId, navigate]
  );

  useCloseOnElementBlur({
    elementRef: modalEl,
    setIsOpen: setIsModalOpen,
    stayOpen: true
  });

  const onShare = e => {
    e.nativeEvent.stopImmediatePropagation();
    CleverTap.tapped.push({
      screen_name: 'insight',
      section_name: 'bar_top',
      tapped_on: 'btn_share_insight'
    });
    if (navigator.share) {
      navigator
        .share({ title: 'Favebiz Insights', url: window.location.href })
        .then(() => {})
        .catch(console.error);
    } else {
      setIsModalOpen(true);
    }
  };

  const copyURL = () =>
    copyToClipboard({
      text: window.location.href,
      onDone: () => {
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 3000);
      }
    });

  const COUNTRY_CODES = {
    singapore: 'SG',
    malaysia: 'MY',
    indonesia: 'ID'
  };

  return (
    <header className={styles.header}>
      <img
        className={classNames(styles.logo)}
        src={logo}
        alt="FaveBiz Insights"
      />
      <div className={styles.companyName}>
        {COUNTRY_CODES[country]} - {companyName}
      </div>
      <div className={styles.headerOptions}>
        <InsightsOutletSwitcher onChange={onChangeOutlet} />
        <DateRangePicker location={location} onChange={onChangeDates} />
      </div>

      {!hasEmailHeader && (
        <div ref={modalEl}>
          <Button
            leftIcon={require('insights/assets/images/nav/share.svg')}
            className={styles.shareButton}
            iconColor="primary"
            iconSize="1.5rem"
            children=""
            onClick={onShare}
          />
          <div
            className={classNames(
              styles.shareModal,
              isModalOpen && styles.active
            )}
          >
            <p>
              Anyone with the link <strong>can view</strong> the Insights of
              your business
            </p>
            <div>
              <span onClick={copyURL} className={styles.shareLink}>
                {window.location.href}
              </span>
              <Button
                className={styles.copyButton}
                onClick={copyURL}
                justify="center"
              >
                Copy link
              </Button>
            </div>
            <p
              className={classNames(
                styles.shareLinkNotification,
                linkCopied && styles.active
              )}
            >
              Link copied
            </p>
          </div>
        </div>
      )}
    </header>
  );
};
export default Header;
