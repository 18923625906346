import TransactionDetail from 'components/TransactionDetail';
import React from 'react';
import useGetRealTimeRetailPaymentsPlatform from 'queries/useGetRealTimeRetailPaymentsPlatform';
import { refundRealTimeRetailPaymentsPlatformTransaction } from 'commands/refundRealTimeRetailPaymentsPlatformTransaction';

const RealTimeRetailPaymentsPlatformDetailView = props => (
  <TransactionDetail
    {...props}
    type="Real-time Retail Payments Platform"
    responseProp="rpp_credit_transfer"
    useTransactionDetails={useGetRealTimeRetailPaymentsPlatform}
    cancelTransaction={refundRealTimeRetailPaymentsPlatformTransaction}
  />
);
export default RealTimeRetailPaymentsPlatformDetailView;
