import classNames from 'classnames';
import {
  getHours,
  getMinutes,
  getDay,
  getDaysInMonth,
  startOfToday,
  isBefore,
  isSameDay,
  getDate,
  format,
  isEqual,
  isAfter,
  addDays,
  getMonth,
  getYear
} from 'date-fns';
import {
  CLEVERTAP_TAPPED_ON,
  DAYARRAY,
  CLEVERTAP_SECTION_NAME,
  MONTHSARRAY
} from 'helpers/TypeConstants';
import React, { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import OutOfBoundClick from './OutOfBoundClick';
import leftArrow from 'assets/images/icon-left-dark-grey.svg';
import rightArrow from 'assets/images/icon-right-dark-grey.svg';

import style from 'assets/css/DatePicker.module.scss';
import { ModalContext } from 'contexts/ModalContext';
import useCleverTap from 'hooks/useCleverTap';
import { formatDate } from 'helpers/dateUtility';

// todo: add capabilities such as autoclose upon clicking dates,
// add SOA input box, favefeed input box.

type DatePickerPanelProps = {
  startDate: Date;
  endDate?: Date | undefined;
  setStartDate: (value: Date | undefined) => void;
  setEndDate?: (value: Date | undefined) => void;
  screenName: string;
  className?: string;
  disabled?: boolean;
  isDayDisabled?: (date: Date) => boolean;
  rangeDateSelection?: boolean;
  showTimeSelection?: boolean;
  showApplyClearButtons?: boolean;
  disableFutureDates?: boolean;
  componentProps?: any;
  isModal?: boolean;
  CustomContent?: ReactNode;
  dateToShowCustomContent?: Date;
  dateToHideLeftButton?: Date;
};

type DatePickerProps = DatePickerPanelProps & {
  isModal?: boolean;
  children: (text: string | HTMLElement, trigger: () => void) => ReactNode;
};

type CalendarProps = {
  monthNumber: number;
  yearNumber: number;
  localStartDate: Date;
  localEndDate?: Date | undefined;
  setEndDate?: (value: Date | undefined) => void;
  setAllStartDate: (date: Date) => void;
  setAllEndDate: (date: Date | undefined) => void;
  disableFutureDates?: boolean;
  isDayDisabled?: (date: Date) => boolean;
  rangeDateSelection?: boolean;
};

export const DateInputBox = ({
  startDate,
  endDate,
  rangeDateSelection,
  onClick,
  customFormat,
  isTextGreen
}: {
  startDate: Date | undefined;
  endDate?: Date | undefined;
  rangeDateSelection?: boolean;
  onClick: () => void;
  customFormat?: string;
  isTextGreen?: boolean;
}) => {
  return (
    <>
      <div
        className={classNames(
          style.dateInputBox,
          isTextGreen && style.greenText
        )}
        onClick={onClick}
      >
        {customFormat ? (
          customFormat
        ) : (
          <>
            {`${startDate ? format(startDate, 'dd MMM yy') : 'Start Date'}`}
            {rangeDateSelection && (
              <>
                <span
                  style={{ paddingRight: 8, paddingLeft: 8 }}
                >{` to `}</span>
                {`${endDate ? format(endDate, 'dd MMM yy') : 'End Date'}`}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export const DateInputBadge = ({
  startDate,
  endDate,
  onClick
}: {
  startDate: Date | undefined;
  endDate?: Date | undefined;
  onClick: () => void;
}) => {
  return (
    <div
      className={classNames(
        style.dateInputBadge,
        (startDate || endDate) && style.active
      )}
      onClick={onClick}
    >
      {`${startDate ? formatDate(startDate) : 'Dates'} ${
        endDate ? `to ${formatDate(endDate)}` : ''
      }`}
    </div>
  );
};

const Calendar: React.FC<CalendarProps> = props => {
  const {
    monthNumber,
    yearNumber,
    localStartDate,
    localEndDate,
    setEndDate,
    setAllStartDate,
    setAllEndDate,
    disableFutureDates,
    isDayDisabled,
    rangeDateSelection
  } = props;

  const firstDayIndex = getDay(new Date(yearNumber, monthNumber, 1));
  const totalDays = getDaysInMonth(new Date(yearNumber, monthNumber));
  let allowRender = false;
  let allowRenderBorderBottomForDateBoxes = false;
  let dayNumber = 0;
  const possibleNumberOfBoxesRendered = 49;
  const today = startOfToday();

  return (
    <>
      <div className={style.calendar}>
        <div className={style.dates}>
          {/* day names */}
          {Array.from(DAYARRAY, day => {
            if (day === DAYARRAY[firstDayIndex]) {
              allowRenderBorderBottomForDateBoxes = true;
            }
            return (
              <div
                className={classNames(
                  style.week,
                  allowRenderBorderBottomForDateBoxes && style.borderBottom
                )}
                key={day}
              >
                {day}
              </div>
            );
          })}

          {/* dates */}
          {Array.from({ length: possibleNumberOfBoxesRendered }, (_, index) => {
            if (dayNumber < totalDays) {
              if (index === firstDayIndex) {
                allowRender = true;
              }
              if (!allowRender) {
                const dayBeforeFirstDay = index + 1;
                return (
                  <div
                    className={classNames(
                      style.borderBottom,
                      dayBeforeFirstDay === firstDayIndex && style.borderRight
                    )}
                    key={index}
                  />
                );
              }

              dayNumber = dayNumber + 1;
              const currentDayNumber = dayNumber;

              const currentDayDate = new Date(
                yearNumber,
                monthNumber,
                currentDayNumber
              );

              const notAllowed = disableFutureDates
                ? isAfter(currentDayDate, startOfToday())
                : isDayDisabled
                ? isBefore(currentDayDate, today) ||
                  (isDayDisabled && isDayDisabled(currentDayDate))
                : false;

              const firstSelectedDate =
                localStartDate && isSameDay(currentDayDate, localStartDate);
              const selectedDatesRange =
                localStartDate &&
                localEndDate &&
                isBefore(localStartDate, currentDayDate) &&
                isBefore(
                  currentDayDate,
                  addDays(
                    localEndDate,
                    isEqual(localStartDate, localEndDate) ? 0 : 1
                  )
                );

              const newStartDate = rangeDateSelection
                ? isBefore(currentDayDate, localStartDate)
                : !isEqual(currentDayDate, localStartDate);

              return (
                <div
                  className={classNames(
                    style.day,
                    notAllowed && style.disabledDate,
                    firstSelectedDate && style.selectedDate,
                    selectedDatesRange && style.selectedDate
                  )}
                  key={index}
                  onClick={() => {
                    if (!notAllowed) {
                      if (!localStartDate) {
                        setAllStartDate(currentDayDate);
                      } else if (newStartDate) {
                        setAllStartDate(currentDayDate);
                        if (setEndDate) {
                          setAllEndDate(undefined);
                        }
                      } else if (!localEndDate && setEndDate) {
                        setAllEndDate(currentDayDate);
                      } else {
                        if (setEndDate) {
                          setAllStartDate(currentDayDate);
                          setAllEndDate(undefined);
                        }
                      }
                    }
                  }}
                >
                  {currentDayNumber}
                </div>
              );
            }
            return <div key={index} />;
          })}
        </div>
      </div>
    </>
  );
};

const DatePickerPanel: React.FC<DatePickerPanelProps & {
  onClose?: () => void;
}> = props => {
  const {
    startDate = new Date(),
    endDate = undefined,
    setStartDate,
    setEndDate = undefined,
    screenName,
    className,
    rangeDateSelection,
    showTimeSelection = false,
    showApplyClearButtons = false,
    disableFutureDates = false,
    isDayDisabled,
    CustomContent,
    dateToShowCustomContent,
    dateToHideLeftButton,
    onClose
  } = props;

  const cleverTap = useCleverTap();
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate || undefined);
  const [month, setMonth] = useState(localStartDate.getMonth());
  const [year, setYear] = useState(localStartDate.getFullYear());
  const [showHourModal, setShowHourModal] = useState(false);
  const [showMinutesModal, setShowMinuteModal] = useState(false);
  const defaultRadix = 10;
  const twelveHourFormatValue = 12;
  const minutesInOneHour = 60;
  const fiveMinuteIntervals = 5;

  const [selectedMeridien, setSelectedMeridien] = useState(
    getHours(localStartDate) >= twelveHourFormatValue ? 'PM' : 'AM'
  );

  const setAllStartDate = (date: Date) => {
    setLocalStartDate(date);
    if (!showApplyClearButtons && !showTimeSelection) setStartDate(date);
  };

  const setAllEndDate = (date: Date | undefined) => {
    setLocalEndDate(date);
    if (!showApplyClearButtons && !showTimeSelection && setEndDate)
      setEndDate(date);
  };

  const isSelectedMeridienAM = selectedMeridien === 'AM';
  const isSelectedMeridienPM = selectedMeridien === 'PM';
  const { t } = useTranslation();
  const firstMonthNumber = 0;
  const lastMonthNumber = 11;
  const [selectedHour, setSelectedHour] = useState(() => {
    if (getHours(localStartDate) === 0 || getHours(localStartDate) === 12) {
      // for 12am and 12pm
      return '12';
    } else if (getHours(localStartDate) > twelveHourFormatValue) {
      // for 1pm until 11pm
      return String(getHours(localStartDate) - twelveHourFormatValue);
    } else {
      return String(getHours(localStartDate));
    }
  });
  const [selectedMinute, setSelectedMinute] = useState(
    String(getMinutes(localStartDate)).padStart(2, '0')
  );

  const showCustomContent =
    dateToShowCustomContent &&
    month === getMonth(dateToShowCustomContent) &&
    year === getYear(dateToShowCustomContent);

  const hideLeftButton =
    dateToHideLeftButton &&
    month === getMonth(dateToHideLeftButton) &&
    year === getYear(dateToHideLeftButton);

  const LeftButton = () => {
    return (
      <div
        className={style.monthNavigatorButton}
        onClick={() => {
          setMonth(monthNumber => {
            if (monthNumber === firstMonthNumber) {
              return lastMonthNumber;
            }
            return monthNumber - 1;
          });
          if (month === firstMonthNumber) {
            setYear(yearNumber => yearNumber - 1);
          }
        }}
      >
        <img src={leftArrow} alt="left arrow previous month" />
      </div>
    );
  };

  const RightButton = () => {
    return (
      <div
        className={style.monthNavigatorButton}
        onClick={() => {
          setMonth(monthNumber => {
            if (monthNumber === lastMonthNumber) {
              return firstMonthNumber;
            }
            return monthNumber + 1;
          });
          if (month === lastMonthNumber) {
            setYear(yearNumber => yearNumber + 1);
          }
        }}
      >
        <img src={rightArrow} alt="right arrow next month" />
      </div>
    );
  };

  const onClickApplyDates = () => {
    cleverTap.tapped.push({
      screen_name: screenName,
      section_name: CLEVERTAP_SECTION_NAME.CALENDAR,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_APPLY_DATE
    });

    setAllStartDate(localStartDate);
    setStartDate(localStartDate);
    setAllEndDate(localEndDate);
    setEndDate && setEndDate(localEndDate);

    // for showApplyClearButtons & showTimeSelection
    if (onClose) {
      onClose();
    }
  };

  const hoursRange = Array.from({
    length: twelveHourFormatValue
  }).map((_, hour) => (hour + 1).toString());
  const minutesRange = Array.from({ length: minutesInOneHour })
    .map((_, minute) => {
      if (minute % fiveMinuteIntervals !== 0) {
        return '';
      }
      const correctMinute = minute.toString();
      return correctMinute.padStart(2, '0');
    })
    .filter(minute => Boolean(minute));

  const onChangeHour = (hour: string) => {
    const newDate = new Date(localStartDate);
    const currentDayDate = getDate(new Date(localStartDate));
    if (
      (isSelectedMeridienAM &&
        parseInt(hour, defaultRadix) < twelveHourFormatValue) ||
      (isSelectedMeridienPM &&
        parseInt(hour, defaultRadix) === twelveHourFormatValue)
    ) {
      newDate.setHours(parseInt(hour, defaultRadix));
    } else if (
      (isSelectedMeridienPM &&
        parseInt(hour, defaultRadix) < twelveHourFormatValue) ||
      (isSelectedMeridienAM &&
        parseInt(hour, defaultRadix) === twelveHourFormatValue)
    ) {
      newDate.setHours(parseInt(hour, defaultRadix) + twelveHourFormatValue);
    }
    newDate.setDate(currentDayDate);
    setShowHourModal(false);
    setSelectedHour(hour);
    setLocalStartDate(newDate);
  };

  const onChangeMinute = (minute: string) => {
    const newDate = new Date(localStartDate);
    newDate.setMinutes(parseInt(minute, defaultRadix));
    setShowMinuteModal(false);
    setSelectedMinute(minute);
    setLocalStartDate(newDate);
  };

  const onChangeMeridien = (isAM: boolean = true) => {
    const newDate = new Date(localStartDate);
    const currentDayDate = getDate(new Date(localStartDate));

    if (isAM && parseInt(selectedHour, defaultRadix) < twelveHourFormatValue) {
      newDate.setHours(parseInt(selectedHour, defaultRadix));
    } else if (
      !isAM &&
      parseInt(selectedHour, defaultRadix) === twelveHourFormatValue
    ) {
      newDate.setHours(twelveHourFormatValue);
    } else {
      newDate.setHours(
        parseInt(selectedHour, defaultRadix) + twelveHourFormatValue
      );
    }

    newDate.setDate(currentDayDate);
    setSelectedHour(selectedHour);
    setSelectedMeridien(isAM ? 'AM' : 'PM');
    setLocalStartDate(newDate);
  };

  const onClearDates = () => {
    setStartDate(undefined);
    if (setEndDate) {
      setEndDate(undefined);
    }
    if (onClose) {
      onClose();
    }
  };

  const ClearDatesButton = () => {
    return (
      <Button
        className={style.clearDatesButton}
        onClick={onClearDates}
        type={'ghost'}
        size={'md'}
      >
        {t('Clear Dates')}
      </Button>
    );
  };

  const ApplyDatesButton = () => {
    const isDisabled = () => {
      if ((localStartDate && !setEndDate) || (localStartDate && localEndDate)) {
        return false;
      } else {
        return true;
      }
    };
    return (
      <Button
        onClick={onClickApplyDates}
        disabled={isDisabled()}
        type={'primary'}
        size={'md'}
        className={style.confirmButton}
      >
        {t('Confirm')}
      </Button>
    );
  };

  return (
    <>
      <div className={classNames(style.datePickerButtonsContainer, className)}>
        <div
          className={classNames(
            style.datepickerpanel,
            showTimeSelection && style.timeSelection
          )}
        >
          <div className={style.monthContainer}>
            <div className={style.monthNavigator}>
              {hideLeftButton ? (
                <div className={style.invisibleNavigatorButton} />
              ) : (
                <LeftButton />
              )}
              <p className={style.monthName}>
                {t(`${MONTHSARRAY[month]}`)} {year}
              </p>
              <RightButton />
            </div>

            {showCustomContent && CustomContent ? (
              CustomContent
            ) : (
              <Calendar
                monthNumber={month}
                yearNumber={year}
                localStartDate={localStartDate}
                localEndDate={localEndDate}
                setEndDate={setEndDate}
                setAllStartDate={setAllStartDate}
                setAllEndDate={setAllEndDate}
                disableFutureDates={disableFutureDates}
                rangeDateSelection={rangeDateSelection}
                isDayDisabled={isDayDisabled}
              />
            )}
          </div>
          {showTimeSelection && (
            <div className={style.timeSelectionFooter}>
              <div className={style.selectTimeContainer}>
                <div className={style.selectTime}>
                  <OutOfBoundClick
                    className={classNames(
                      style.outOfBoundContainer,
                      style.timeModalContainer
                    )}
                    onClick={() => setShowHourModal(false)}
                  >
                    <div className={style.selectHour}>
                      <div className={style.time}>
                        <div
                          className={style.hour}
                          onClick={() => setShowHourModal(true)}
                        >
                          {selectedHour}
                        </div>
                        <div
                          className={classNames(
                            style.hourModal,
                            showHourModal && style.isOpen
                          )}
                        >
                          {hoursRange.map((hour, index) => (
                            <p
                              className={style.timeValue}
                              key={index}
                              onClick={() => onChangeHour(hour)}
                            >
                              {hour}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </OutOfBoundClick>
                  <div className={style.timeSeparator}>:</div>
                  <OutOfBoundClick
                    className={classNames(
                      style.outOfBoundContainer,
                      style.timeModalContainer
                    )}
                    onClick={() => setShowMinuteModal(false)}
                  >
                    <div className={style.selectMinute}>
                      <div className={style.time}>
                        <div
                          className={style.minute}
                          onClick={() => setShowMinuteModal(true)}
                        >
                          {selectedMinute}
                        </div>
                        <div
                          className={classNames(
                            style.hourModal,
                            showMinutesModal && style.isOpen
                          )}
                        >
                          {minutesRange.map((minute, index) => (
                            <p
                              className={style.timeValue}
                              key={index}
                              onClick={() => onChangeMinute(minute)}
                            >
                              {minute}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </OutOfBoundClick>
                </div>
                <div className={style.selectMeridien}>
                  <div
                    className={classNames(
                      style.meridien,
                      isSelectedMeridienAM && style.activeMeridien
                    )}
                    onClick={() => onChangeMeridien()}
                  >
                    AM
                  </div>
                  <div
                    className={classNames(
                      style.meridien,
                      isSelectedMeridienPM && style.activeMeridien
                    )}
                    onClick={() => {
                      onChangeMeridien(false);
                    }}
                  >
                    PM
                  </div>
                </div>
              </div>
              <ApplyDatesButton />
            </div>
          )}
          {showApplyClearButtons && (
            <div className={style.applyClearDatesFooter}>
              {localEndDate && <ClearDatesButton />}
              <ApplyDatesButton />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const DatePicker: React.FC<DatePickerProps> = props => {
  const {
    startDate = new Date(),
    endDate = undefined,
    setStartDate,
    setEndDate = undefined,
    children,
    screenName,
    className,
    showTimeSelection = false,
    showApplyClearButtons = false,
    disabled = false,
    disableFutureDates = false,
    isDayDisabled,
    isModal = false,
    CustomContent,
    dateToShowCustomContent,
    dateToHideLeftButton
  } = props;
  const { init } = useContext(ModalContext);
  const cleverTap = useCleverTap();
  const [datePickerVisible, setDatepickerVisible] = useState(false);

  const rangeDateSelection = setEndDate ? true : false;

  const tempText = `${
    startDate ? format(new Date(startDate), 'dd MMM yy') : 'Start Date'
  } ${endDate ? `to ${format(new Date(endDate), 'dd MMM yy')}` : 'End Date'}`;

  const showDatePicker = isModal
    ? () =>
        init({
          Component: DatePickerPanel,
          openOnReady: true,
          closeOnBlur: true,
          hasCloseButton: false,
          componentProps: {
            startDate,
            endDate,
            screenName,
            className,
            setStartDate,
            setEndDate,
            rangeDateSelection,
            disableFutureDates,
            isDayDisabled,
            showTimeSelection,
            showApplyClearButtons,
            CustomContent,
            dateToShowCustomContent,
            dateToHideLeftButton
          }
        })
    : () => setDatepickerVisible(true);

  const trigger = () => {
    if (disabled) {
      return;
    }
    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_DATE
    });

    showDatePicker();
  };

  return (
    <div className={style.datepicker}>
      <div
        className={classNames(
          style.container,
          disabled && style.disabled,
          className
        )}
        onClick={trigger}
      >
        {children(tempText, trigger)}
      </div>
      <OutOfBoundClick
        onClick={() =>
          datePickerVisible ? setDatepickerVisible(!datePickerVisible) : ''
        }
      >
        {datePickerVisible && (
          <DatePickerPanel
            startDate={startDate}
            endDate={endDate}
            screenName={screenName}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            rangeDateSelection={rangeDateSelection}
            disableFutureDates={disableFutureDates}
            isDayDisabled={isDayDisabled}
            showTimeSelection={showTimeSelection}
            showApplyClearButtons={showApplyClearButtons}
            className={classNames(!isModal && style.popupDatepicker)}
            onClose={() => setDatepickerVisible(false)}
            isModal={isModal}
            CustomContent={CustomContent}
            dateToShowCustomContent={dateToShowCustomContent}
            dateToHideLeftButton={dateToHideLeftButton}
          />
        )}
      </OutOfBoundClick>
    </div>
  );
};

export default DatePicker;
