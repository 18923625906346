import TransactionDetail from 'components/TransactionDetail';
import React from 'react';
import useGetMapleTransactionDetail from 'queries/useGetMapleTransactionDetail';

const MapleTransactionDetailView = props => (
  <TransactionDetail
    {...props}
    type="Maple Payment"
    responseProp="maple_payment"
    useTransactionDetails={useGetMapleTransactionDetail}
  />
);
export default MapleTransactionDetailView;
