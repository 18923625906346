/// <reference types="googlemaps" />
import React, { useState, useEffect, useRef } from 'react';

import styles from 'assets/css/GoogleMap.module.scss';

// Careful with this as this declaration will affect the whole project
declare global {
  interface Window {
    googleMapScriptTagAppended: boolean;
  }
}

type GoogleMapProps = {
  lat: number;
  lng: number;
  onChange: (props: {
    description: string;
    place_id: string;
    latitude: number;
    longitude: number;
  }) => void;
};

function GoogleMap({
  lat = 3.1569486,
  lng = 101.712303,
  onChange = () => {}
}: GoogleMapProps) {
  const [map, setMap] = useState<google.maps.Map<Element>>();
  const geocoderRef = useRef<google.maps.Geocoder>();
  const mapRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!map || isNaN(lat + lng)) return;
    let isMounted = true;
    let marker: google.maps.Marker | null = new window.google.maps.Marker({
      position: { lat, lng },
      map,
      draggable: true,
      title: 'Move pin to desired location'
    });
    marker.addListener('dragend', event => {
      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();

      if (!geocoderRef.current) {
        geocoderRef.current = new window.google.maps.Geocoder();
      }

      geocoderRef.current.geocode(
        { location: { lat: newLat, lng: newLng } },
        function(results, status) {
          if (!isMounted) return;
          if (status === 'OK') {
            const [firstResult] = results;
            if (firstResult) {
              onChange({
                description: firstResult.formatted_address,
                place_id: firstResult.place_id,
                latitude: newLat,
                longitude: newLng
              });
            } else {
              console.log('No results found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }
      );
    });
    map.panTo(marker.getPosition() as google.maps.LatLng);

    return () => {
      isMounted = false;
      marker?.setMap(null);
      marker = null;
    };
  }, [map, lat, lng, onChange]);

  useEffect(() => {
    function loadMap() {
      mapRef.current &&
        setMap(new window.google.maps.Map(mapRef.current, { zoom: 16 }));
    }
    if (window.googleMapScriptTagAppended) {
      loadMap();
      return;
    }

    const script = document.createElement('script');
    script.src = `//maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    document.body.appendChild(script);
    window.googleMapScriptTagAppended = true;

    script.addEventListener('load', loadMap);
    return () => script.removeEventListener('load', loadMap);
  }, []);

  return <section ref={mapRef} className={styles.layout} />;
}

export default GoogleMap;
