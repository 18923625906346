import React, { useCallback } from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';

import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import styles from 'assets/css/Button.module.scss';

const IconSpacer = () => (
  <span data-icon-spacer className={styles.iconSpacer} />
);

export type IButtonSizeTypes = 'xs' | 'md' | 'lg';
export interface IButtonProps {
  href?: string;
  style?: React.CSSProperties;
  state?: any;
  type?:
    | 'submit'
    | 'primary'
    | 'default'
    | 'text'
    | 'ghost'
    | 'skeleton'
    | 'inversePrimary'
    | 'noHoverPrimary';
  size?: IButtonSizeTypes;
  className?: string;
  leftIcon?: string;
  rightIcon?: string;
  iconSize?: string;
  justify?: string;
  fullWidth?: boolean;
  textCenter?: boolean;
  textBold?: boolean;
  replace?: boolean;
  disabled?: boolean;
  isRedirect?: boolean;
  isLoading?: boolean;
  isRounded?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onMouseDown?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  iconColor?: string;
  textColor?: string;
  isWebview?: boolean;
  events?: {
    name?: string;
    screen_name?: string;
    tapped_on?: string;
    section_name?: string;
    props?: {};
  };
}

type IComponentProps = React.PropsWithChildren<
  Pick<IButtonProps, 'className' | 'disabled' | 'onClick' | 'style'>
>;

const Button: React.FC<IButtonProps> = ({
  href,
  type = 'default',
  size = 'md',
  fullWidth = false,
  textCenter = false,
  textBold = false,
  state = {},
  replace = false,
  disabled = false,
  isRedirect = true,
  isLoading = false,
  children,
  className,
  leftIcon,
  rightIcon,
  iconSize,
  justify,
  isWebview = false,
  ...restProps
}) => {
  const isLink = !!href;
  const isNonHttpLink = /^(mailto|tel):/.test(href ?? '');
  const isExternalLink = /^(http|https|favebiz):/.test(href ?? '');
  const isSubmit = type === 'submit';
  const isGhost = type === 'ghost';
  const isInversePrimary = type === 'inversePrimary';
  const isNoHoverPrimary = type === 'noHoverPrimary';
  const isPrimary = type === 'primary' || isSubmit || isInversePrimary;
  const iconColor =
    (isPrimary && 'white') || (disabled && 'disabled') || restProps.iconColor;
  const textLinkColor = disabled ? 'disabled' : restProps.textColor;
  const isRounded = isPrimary || isNoHoverPrimary ? true : restProps.isRounded;
  const isFullWidth = fullWidth || isWebview;
  const isTextCenter = isWebview || textCenter;
  const isTextBold = isWebview || textBold;
  const buttonSize = isGhost ? '' : isWebview ? 'lg' : size;

  const Component = useCallback(
    (props: IComponentProps) =>
      ((isNonHttpLink || isExternalLink) && (
        <a
          href={href}
          rel="noreferrer noopener"
          target={isExternalLink && isRedirect ? '_blank' : '_self'}
          {...props}
        >
          {props.children}
        </a>
      )) ||
      (isLink && (
        <Link
          getProps={({ isCurrent, isPartiallyCurrent }) =>
            isCurrent || isPartiallyCurrent ? { 'aria-current': 'page' } : {}
          }
          {...{ to: href ?? '', state, replace, ...props }}
        />
      )) || (
        <button
          type={isSubmit ? 'submit' : 'button'}
          {...props}
          onMouseDown={event => {
            if (restProps.onMouseDown) restProps.onMouseDown(event);
          }}
        />
      ),
    [
      href,
      isExternalLink,
      isLink,
      isNonHttpLink,
      isRedirect,
      isSubmit,
      replace,
      state,
      restProps
    ]
  );

  return (
    <Component
      className={classNames(
        className,
        styles.button,
        styles[type],
        styles[buttonSize],
        isRounded && styles.rounded,
        justify ? styles[justify] : null,
        isFullWidth && styles.fullWidth,
        isTextCenter && styles.center,
        isTextBold && styles.bold,
        rightIcon && styles.textWithRightIcon,
        leftIcon && styles.textWithLeftIcon
      )}
      style={
        {
          '--text-link-color': textLinkColor
            ? `var(--${textLinkColor})`
            : `var(--charcoal-gray)`
        } as React.CSSProperties
      }
      disabled={disabled || isLoading}
      onClick={restProps.onClick}
    >
      {isLoading ? (
        <LoadingSpinner baseSize="1px" color="white" />
      ) : (
        <>
          {leftIcon && (
            <>
              <Icon icon={leftIcon} color={iconColor} size={iconSize} />
              <IconSpacer />
            </>
          )}
          {children}
          {rightIcon && (
            <>
              <IconSpacer />
              <Icon icon={rightIcon} color={iconColor} size={iconSize} />
            </>
          )}
        </>
      )}
    </Component>
  );
};

export default Button;
