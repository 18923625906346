import Kfit, { getStagingInfo } from './Kfit';

const { isProduction, stagingIdentifier } = getStagingInfo();

class FaveBizAPI extends Kfit {
  static ENDPOINTS() {
    return {
      consumer: isProduction
        ? 'REACT_APP_KFIT_CONSUMER_API'
        : `REACT_APP${stagingIdentifier}_KFIT_CONSUMER_API`,
      partner: isProduction
        ? 'REACT_APP_FAVEBIZ_PARTNER_API'
        : `REACT_APP${stagingIdentifier}_FAVEBIZ_PARTNER_API`
    };
  }
}

export default FaveBizAPI;
