import React from 'react';
import classNames from 'classnames';

import styles from 'assets/css/ItemPickerInput.module.scss';
import { useFormikContext } from 'formik';
import { ErrorText } from './FormErrorText';

export interface ItemPickerInputProps<T, TField, T1Field> {
  name: keyof T;
  className?: string;
  isDisabled?: boolean | ((values: T) => boolean);
  conditionalStyles?: (values: T) => string;
  onClick: (
    values: T,
    setFieldValue: (
      field: string,
      value: TField,
      shouldValidate?: boolean
    ) => void,
    setPickerValue: (value: T1Field) => void
  ) => void;
  getPlaceholderText: (value: T1Field, values: T) => string;
  getErrorText: (values: T) => string;
}

const ItemPickerInput = <T extends {}, TField extends {}, T1Field>({
  name,
  className,
  isDisabled,
  conditionalStyles,
  onClick,
  getPlaceholderText,
  getErrorText,
  ...rest
}: ItemPickerInputProps<T, TField, T1Field>) => {
  const { values, setFieldValue } = useFormikContext<T>();

  const setPickerValue = (value: T1Field) =>
    setFieldValue(name as string, value, true);

  const value = (values[name] as unknown) as T1Field;

  const placeholder = getPlaceholderText(value, values);
  const error = getErrorText(values);

  const disabled = isDisabled
    ? typeof isDisabled === 'boolean'
      ? isDisabled
      : isDisabled(values)
    : false;

  return (
    <>
      <input
        className={classNames(
          styles.input,
          error ? styles.error : '',
          className,
          conditionalStyles && conditionalStyles(values)
        )}
        disabled={disabled}
        placeholder={placeholder}
        onClick={() => {
          onClick(values, setFieldValue, setPickerValue);
        }}
        name={name as string}
        {...rest}
        readOnly={true}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export default ItemPickerInput;
