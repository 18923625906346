import React, { useEffect, useState } from 'react';
import style from 'assets/css/ProductTour.module.scss';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import Button from './Button';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { useTranslation } from 'react-i18next';

type CleverTapProps = {
  screen_name?: string;
  tapped_on?: string;
  section_name?: string;
};

type Props = {
  steps: Step[];
  callback?: (data: CallBackProps) => void;
  totalSteps?: number;
  cleverTapEvents?: CleverTapProps;
};

type TooltipStep = Step & {
  stepNumber?: number;
  additionalFunction?: () => void;
  differentSection?: boolean;
  tapped_on?: string;
};

type JoyridePrimaryProps = {
  'aria-label': string;
  'data-action': string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  role: string;
  title: string;
};

type JoyrideTooltipProps = {
  'aria-modal': boolean;
  ref: React.RefCallback<HTMLElement>;
  role: string;
};

const ProductTour: React.FC<Props> = props => {
  const {
    steps,
    callback = undefined,
    totalSteps = 0,
    cleverTapEvents = undefined
  } = props;
  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const CleverTap = useCleverTapOld();
  const { t } = useTranslation();

  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    CleverTap.screenDisplayed.push(cleverTapEvents);
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTapEvents, CleverTap.screenDisplayed]);

  const Tooltip = ({
    step,
    primaryProps,
    tooltipProps
  }: {
    step: TooltipStep;
    primaryProps: JoyridePrimaryProps;
    tooltipProps: JoyrideTooltipProps;
  }) => {
    const nextStepFunction = () => {
      if (step.differentSection) {
        const targetedIndexFunction = () =>
          new Promise(resolve => {
            step.additionalFunction && resolve(step.additionalFunction());
          }).then(() => {
            setStepIndex(index => index + 1);
          });
        targetedIndexFunction();
      } else {
        setStepIndex(index => index + 1);
      }
      if (!step.differentSection && step.additionalFunction) {
        step.additionalFunction();
      }
      if (cleverTapEvents) {
        CleverTap.tapped.push({
          ...cleverTapEvents,
          tapped_on: step.tapped_on
        });
      }
    };
    const stepNumber = stepIndex + 1;
    const isLastStep = totalSteps === stepNumber;

    return (
      <div {...tooltipProps} className={style.tooltipComponent}>
        <div className={style.productTourContent}>
          <p className={style.stepNumber}>{stepNumber}</p>
          <p className={style.stepTitle}>{step.title}</p>
          <p>{step.content}</p>
        </div>
        <div className={style.tooltipFooter}>
          <span className={style.stepNumber}>
            {t(`Steps ${stepNumber} of ${totalSteps}`)}
          </span>
          <div {...primaryProps} className={style.nextButton}>
            <div id="next" onClick={() => nextStepFunction()}>
              <Button className={style.button}>
                {isLastStep ? t('Done') : t('Next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Joyride
      steps={steps}
      tooltipComponent={Tooltip}
      callback={callback}
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      continuous={true}
      stepIndex={stepIndex}
      floaterProps={{
        hideArrow: true,
        styles: {
          floater: {
            filter: 'none'
          }
        }
      }}
    />
  );
};

export default ProductTour;
