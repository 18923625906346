import classNames from 'classnames';
import emailPaymentStatements from 'commands/emailPaymentStatements';
import { NotificationContext } from 'contexts/NotificationContext';
import { OutletContext } from 'contexts/OutletContext';
import useAuthContext from 'hooks/useAuthContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import TextInput from './TextInput';
import OutOfBoundClick from './OutOfBoundClick';
import style from '../assets/css/BulkActionsDropdown.module.scss';
import LoadingSpinnerSmall from './LoadingSpinnerSmall';
import useCleverTapOld from 'hooks/useCleverTapOld';
import forwardToEmailIcon from 'assets/images/forward-to-email-icon.svg';

const BulkActionsDropDown = ({
  selectedStatements,
  buttonType,
  buttonClassName,
  buttonChildren
}: {
  selectedStatements: Array<number>;
  buttonType: 'text' | 'submit' | 'primary' | 'default' | 'ghost' | 'skeleton';
  buttonClassName?: string;
  buttonChildren: string | ReactElement;
  canViewStatement: boolean;
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showLeftSlide, setShowLeftSlide] = useState(false);
  const cleverTap = useCleverTapOld();

  useEffect(() => {
    setShowEmail(false);
    setShowLeftSlide(false);
  }, [showDropdown]);

  const DropdownComponent = () => (
    <div
      className={classNames(
        style.BulkActionDropDown,
        showDropdown && style.isOpen
      )}
    >
      {showEmail ? (
        <EmailForm />
      ) : (
        <div
          className={classNames(
            showLeftSlide && style.DropdownItemButtonAnimation
          )}
        >
          <Button
            className={style.DropdownItemButton}
            fullWidth
            type={'text'}
            onClick={() => {
              setShowEmail(true);
              setShowLeftSlide(true);
              cleverTap.tapped.push({
                screen_name: 'statements_individual',
                tapped_on: 'btn_send_statements'
              });
            }}
          >
            <img
              className={style.dropdownItemIcon}
              src={forwardToEmailIcon}
              alt=""
            />
            {t('Forward to Email')}
          </Button>
        </div>
      )}
    </div>
  );

  const EmailForm = () => {
    const [sentTo, setSentTo] = useState<Array<string>>([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const { currentCompany } = useContext(OutletContext);
    const { setNotification } = useContext(NotificationContext);
    const [emailSentLoading, setEmailSentLoading] = useState(false);

    const sendStatementsToEmail = (email: string, cc_email: string | null) => {
      setEmailSentLoading(true);
      emailPaymentStatements({
        email,
        cc_email,
        company_id: currentCompany?.id,
        journal_ids: selectedStatements
          .map((statements: number) => statements.toString())
          .join()
      })
        .then(() => {
          setEmailSentLoading(false);
          setShowSuccess(true);
        })
        .catch(() => {
          setEmailSentLoading(false);
          setNotification({
            message: `${t('Oops! Something went wrong.')}`,
            type: 'error'
          });
        });
    };

    return (
      <>
        {showSuccess ? (
          <EmailSent sentTo={sentTo} />
        ) : (
          <div className={style.EmailForm}>
            <div className={style.EmailHeader}>
              <Button
                leftIcon="angle-left"
                type="text"
                onClick={() => setShowEmail(false)}
              />
              <h3>{t('Forward to Email')}</h3>
            </div>

            <h5>{`${selectedStatements.length} ${t(
              selectedStatements.length === 1
                ? t('Statement Selected')
                : t('Statements Selected')
            )}`}</h5>
            <Formik
              initialValues={{
                email_address: (user && user.email) || '',
                cc_email_address: ''
              }}
              validationSchema={Yup.object().shape({
                email_address: Yup.string()
                  .email(t('Invalid Email'))
                  .required(t('Email is required')),
                cc_email_address: Yup.string()
                  .email(t('Invalid Email'))
                  .nullable()
              })}
              onSubmit={({
                email_address,
                cc_email_address
              }: {
                email_address: string;
                cc_email_address: string;
              }) => {
                cc_email_address
                  ? setSentTo([...sentTo, email_address, cc_email_address])
                  : setSentTo([...sentTo, email_address]);
                cleverTap.tapped.push({
                  screen_name: 'statements_individual',
                  tapped_on: 'btn_send'
                });
                sendStatementsToEmail(email_address, cc_email_address);
              }}
            >
              {({ errors }) => (
                <Form>
                  <p>{t('Enter Email Address')}</p>
                  <TextInput
                    type={'email'}
                    name={'email_address'}
                    placeholder={t('Email Address') || ''}
                    errors={errors}
                  />
                  {errors.email_address ? (
                    <div className={style.ErrorMessage}>
                      {errors.email_address}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={style.CCEmailForm}>
                    <p>{t('CC Email Address')}</p>
                    <p>{t('OPTIONAL')}</p>
                  </div>
                  <TextInput
                    type={'email'}
                    name={'cc_email_address'}
                    placeholder={t('CC Email Address') || ''}
                    errors={errors}
                  />
                  {errors.cc_email_address ? (
                    <div className={style.ErrorMessage}>
                      {errors.cc_email_address}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={style.SendButtonContainer}>
                    {emailSentLoading && (
                      <LoadingSpinnerSmall
                        className={style.LoadingSpinnerContainer}
                      />
                    )}
                    <Button
                      className={style.SendButton}
                      disabled={emailSentLoading}
                      type="submit"
                      size="xs"
                    >
                      {t('Send')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </>
    );
  };

  const EmailSent = ({ sentTo }: { sentTo: string[] }) => {
    cleverTap.screenDisplayed.push({
      screen_name: 'sent_statement_successful'
    });
    return (
      <div className={style.EmailSent}>
        <div className={style.CheckIcon}></div>
        <h3>{t('Statements are sent successfully')}</h3>
        <p>{t('Statements have been forwarded to')}</p>
        {sentTo.length === 1 && <span>{sentTo[0]}</span>}
        {sentTo.length > 1 && (
          <>
            <span>{`${sentTo[0]} &`}</span>
            <span>{sentTo[1]}</span>
          </>
        )}
        <Button
          className={style.SubmitButton}
          type={'primary'}
          onClick={() => setShowDropdown(false)}
        >
          {t('Awesome')}
        </Button>
      </div>
    );
  };

  return (
    <div className={style.BulkActionButton}>
      <Button
        type={buttonType}
        onClick={() => {
          cleverTap.tapped.push({
            screen_name: 'statements_individual',
            tapped_on: 'btn_bulk_actions'
          });
          setShowDropdown(!showDropdown);
        }}
        className={buttonClassName || ''}
        children={buttonChildren}
      />
      <OutOfBoundClick onClick={() => setShowDropdown(false)}>
        <DropdownComponent />
      </OutOfBoundClick>
    </div>
  );
};

export default BulkActionsDropDown;
