import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { useSwipeable } from 'react-swipeable';
import ResizeObserver from 'resize-observer-polyfill';
import classNames from 'classnames';

import styles from 'assets/css/SwipeTabLayout.module.scss';
import useDebouncedCallback from 'hooks/useDebouncedCallback';

type SwipeTabLayoutprops = {
  className?: string;
  selectedTabState: React.MutableRefObject<number>;
  TabAreaContent: (
    activeIndex: number,
    swipeIndex: (isLeft: boolean) => void
  ) => ReactNode;
  children: (width: number, itemStyle: string) => ReactNode;
};

function SwipeTabLayout({
  TabAreaContent,
  children,
  className,
  selectedTabState
}: SwipeTabLayoutprops) {
  const [itemWidth, setItemWidth] = useState<number>();
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLElement | null>(null);

  const handleResize = useDebouncedCallback(([clientWidth]) => {
    if (clientWidth > 0) setItemWidth(clientWidth);
  }, 50);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setItemWidth(containerRef.current.clientWidth);

      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        handleResize(entries[0].target.clientWidth)
      );

      observer.observe(containerRef.current);

      return () => {
        if (containerRef.current) observer.unobserve(containerRef.current);
      };
    }
  }, [handleResize]);

  const updateIndex = useCallback(
    (newIndex: number) => {
      const count = 2;
      const isLeftEdge = newIndex < 0;
      const isRightEdge = newIndex >= count;

      if (!(isLeftEdge || isRightEdge)) {
        selectedTabState.current = newIndex;
        setActiveIndex(newIndex);
      }
    },
    [selectedTabState]
  );

  const swipeIndex = (isLeft: boolean) => {
    const newIndex = isLeft ? activeIndex - 1 : activeIndex + 1;

    updateIndex(newIndex);
  };

  const handlers = useSwipeable({
    trackMouse: true,
    trackTouch: true,
    preventDefaultTouchmoveEvent: true,
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  const refPassthrough = (el: HTMLDivElement | null) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set containerRef el so you can access it yourself
    containerRef.current = el;
  };

  return (
    <div
      className={classNames(styles.container, className)}
      ref={refPassthrough}
    >
      <div className={styles.tabArea}>
        {TabAreaContent(activeIndex, swipeIndex)}
      </div>

      <div className={styles.tabContent}>
        {itemWidth && itemWidth > 0 && (
          <div
            className={styles.swiper}
            style={{
              transform: `translateX(-${activeIndex * 50}%)`
            }}
          >
            {children(itemWidth, styles.slideItem)}
          </div>
        )}
      </div>
    </div>
  );
}

export default SwipeTabLayout;
