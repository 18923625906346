import React from 'react';

import styles from 'assets/css/EmptyOutletListStrip.module.scss';
import emptyOutletListIcon from 'assets/images/banners/ic-empty-list.svg';
import { useTranslation } from 'react-i18next';

const EmptyOutletListStrip = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyListBanner}>
      <img
        className={styles.emptyListBanner}
        src={emptyOutletListIcon}
        alt="No data"
      />
      <p className={styles.noOutletText}>{t(`There's nothing here yet`)}</p>
    </div>
  );
};

export default EmptyOutletListStrip;
