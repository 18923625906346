import Modal from 'components/Modal';
import ModalContainer from 'components/common/ModalContainer';
import React, { useState } from 'react';
import { ApiError } from 'types/ApiError';
import useOnceCallback from './useOnceCallback';

type ErrorData = {
  isTriggered: boolean;
  error: ApiError | null;
};

const useQueryErrorModal = (errors: ErrorData[]) => {
  const [open, setOpen] = useState(false);

  const { setUsed } = useOnceCallback(
    () => setOpen(true),
    !open && errors.some(error => error.isTriggered)
  );

  return {
    ErrorModal: () => (
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <ModalContainer>
          {errors
            .filter(errorObj => errorObj.isTriggered && errorObj.error)
            .map((errorObj, index) => {
              const error = errorObj.error!;
              return (
                <span key={index}>
                  {error.message || error?.value?.response?.error}
                </span>
              );
            })}
        </ModalContainer>
      </Modal>
    ),
    reset: () => setUsed(false)
  };
};

export default useQueryErrorModal;
