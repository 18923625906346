import React, { FC } from 'react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from 'components/LoadingSpinner';
import LoadingSpinnerSmall from 'components/LoadingSpinnerSmall';
import ReportingTable, {
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow
} from 'components/ReportingTable';
import useFetchDataOnScroll from 'hooks/useFetchDataOnScroll';
import useOnceCallback from 'hooks/useOnceCallback';
import { StatementOfAccountResponse } from 'queries/getStatementsOfAccounts';

import styles from 'assets/css/InvoicesView.module.scss';
import ChevronRightGreyIcon from 'assets/images/ic-chevron-right-grey.svg';
import { UseInfiniteQueryResult } from 'react-query';
import { ViewOutletsModal } from 'views/OutletStatementsView';
import EmptyDataMessage from 'components/EmptyDataMessage';
import useCleverTap from 'hooks/useCleverTap';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';

type BankAccountSelectionViewProps = {
  outlet_id: number | undefined;
  bankQuery: UseInfiniteQueryResult<StatementOfAccountResponse, unknown>;
};

const screenName = CLEVERTAP_SCREEN_NAME.TAX_INVOICE;

const BankAccountSelectionView: FC<BankAccountSelectionViewProps> = ({
  outlet_id,
  bankQuery
}) => {
  const { t } = useTranslation();
  const { data, fetchNextPage, hasNextPage, isFetching } = bankQuery;
  const { screenDisplayed, tapped } = useCleverTap();

  const lastRowRef = useFetchDataOnScroll(
    isFetching,
    hasNextPage || false,
    fetchNextPage
  );

  const dataAvailable =
    data !== undefined &&
    data.pages.length > 0 &&
    data.pages[0].accounts_detail.length > 0;

  useOnceCallback(() =>
    screenDisplayed.push({
      screen_name: screenName
    })
  );

  // auto-select first bank account if an outlet is selected
  useOnceCallback(() => {
    navigate(
      `/invoices/${data!.pages[0].accounts_detail[0].finance_account_id}`,
      { replace: true }
    );
  }, outlet_id !== undefined && dataAvailable);

  return (
    <>
      {isFetching ? (
        <LoadingSpinner />
      ) : dataAvailable ? (
        <>
          <ReportingTable>
            <TableHead>
              <TableRow>
                <TableHeadCell>{t('Outlet')}</TableHeadCell>
                <TableHeadCell>{t('Bank Name')}</TableHeadCell>
                <TableHeadCell>{t('Account Number')}</TableHeadCell>
                <TableHeadCell>{t('Last Updated')}</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data!.pages.map(page =>
                page.accounts_detail.map(bank => {
                  const {
                    finance_account_id,
                    outlets_name,
                    bank_name,
                    bank_account_number,
                    last_updated
                  } = bank;

                  return (
                    <TableRow
                      className={styles.bankAccountRow}
                      key={`${finance_account_id}-${bank_account_number}`}
                      onClick={() => {
                        tapped.push({
                          screen_name: screenName,
                          tapped_on: CLEVERTAP_TAPPED_ON.TAX_INVOICE_INDIVIDUAL
                        });

                        navigate(`/invoices/${bank.finance_account_id}`);
                      }}
                    >
                      <TableBodyCell>
                        {outlets_name.length === 1 ? (
                          outlets_name[0]
                        ) : (
                          <ViewOutletsModal bankDetails={bank} />
                        )}
                      </TableBodyCell>
                      <TableBodyCell>{bank_name}</TableBodyCell>
                      <TableBodyCell>{bank_account_number}</TableBodyCell>
                      <TableBodyCell>{last_updated}</TableBodyCell>
                      <TableBodyCell>
                        <img src={ChevronRightGreyIcon} alt="" />
                      </TableBodyCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </ReportingTable>

          <div ref={lastRowRef} />
          {hasNextPage && <LoadingSpinnerSmall />}
        </>
      ) : (
        <EmptyDataMessage title={t('No bank account data available')} />
      )}
    </>
  );
};

export default BankAccountSelectionView;
