import React from 'react';
import classnames from 'classnames';

import styles from 'assets/css/Checkbox.module.scss';

type CheckboxProps = {
  id?: string;
  name: string;
  value?: string;
  className?: string;
  onChange: (checked: boolean) => void;
  checked?: boolean | undefined;
  partial?: boolean;
  type?: 'default' | 'circular' | 'green' | 'menuHeader' | 'subMenuHeader';
  disabled?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  value,
  onChange,
  className,
  checked,
  type = 'default',
  partial = false,
  disabled = false,
  children
}) => {
  return (
    <label
      htmlFor={name}
      className={classnames(
        styles.layout,
        className,
        styles[type],
        children && styles.withChildren
      )}
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        value={value}
        onChange={event => onChange(event.target.checked)}
        checked={checked}
        disabled={disabled}
      />
      {partial ? (
        <span className={styles.partialIcon} />
      ) : (
        <span className={styles.checkboxIcon} />
      )}
      {children}
    </label>
  );
};

export default Checkbox;
