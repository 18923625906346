import React from 'react';
import classNames from 'classnames';
import styles from 'assets/css/FavePaySetupView/SetupCard.module.scss';

function SetupCard({ className, ...props }) {
  return (
    <section className={classNames(styles.layout, className)} {...props}>
      {props.children}
    </section>
  );
}

export default SetupCard;
