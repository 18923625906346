import { useState, useEffect } from 'react';

export default function useDebounce(value: any, timeout = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const interval = setTimeout(() => setDebouncedValue(value), timeout);
    return () => clearInterval(interval);
  }, [value, timeout]);
  return debouncedValue;
}
