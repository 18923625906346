import React from 'react';

import Card from './Card';

import { FeatureItem } from 'queries/getFeatureListing';
import styles from 'assets/css/FeedFeatureCard.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'helpers/dateUtility';
import { FeedPostTypes } from 'uiHelpers/feedForm';
import Separator from './common/Separator';
import FeedECardPreview from './FeedECardPreview';

function FeedFeatureCard({
  item,
  onFeatureClick
}: {
  item: FeatureItem;
  onFeatureClick: (item: FeatureItem) => void;
}) {
  const { t } = useTranslation();

  const {
    id,
    name,
    type,
    company_name,
    company_logo,
    payable_amount,
    bonus_credit_amount,
    sold_count,
    purchase_count,
    post_details,
    images,
    background_color
  } = item;

  const { image, typeName, description, count } =
    type === FeedPostTypes.Deals
      ? {
          image: images[0],
          typeName: 'Deal',
          description: name,
          count: purchase_count
        }
      : {
          image: company_logo,
          typeName: 'eCard',
          description: `${company_name} ${payable_amount} (${bonus_credit_amount})`,
          count: sold_count
        };

  const featuredECardBackground = `radial-gradient(${background_color} -400%, transparent 110%)`;
  const featuredECardBoxshadow = `0px 40px 40px -35px ${background_color}`;

  return (
    <Card
      className={styles.featureCard}
      key={id}
      onClick={() => onFeatureClick(item)}
    >
      <div
        className={styles.image}
        style={{
          background:
            type === FeedPostTypes.Deals
              ? `center / cover no-repeat url(${image})`
              : featuredECardBackground
        }}
      >
        <div
          className={styles.eCard}
          style={{
            boxShadow:
              type === FeedPostTypes.Deals ? '' : featuredECardBoxshadow
          }}
        >
          {type === FeedPostTypes.eCards && <FeedECardPreview item={item} />}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.id}>
          {t('{{typeName}} ID: {{id}}', {
            id,
            typeName
          })}
        </div>
        <div className={styles.details}>{description}</div>

        <Separator className={styles.separator} />

        <div className={styles.stats}>
          <div>
            <span className={styles.label}>{t('Sold')}</span>
            <span>{count}</span>
          </div>
          {post_details && (
            <div>
              <span className={styles.label}>
                {post_details.status === 'active'
                  ? t('Posted on')
                  : t('Scheduled for')}
              </span>
              <span>{formatDate(post_details.start_at)}</span>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default FeedFeatureCard;
