import { useEffect, useContext } from 'react';
import { ModalContext } from 'contexts/ModalContext';

type Props = {
  Component: Object;
  openOnReady?: boolean;
  openCallback?: () => void;
  openOnlyIf?: Object;
  onClose?: () => void;
  hasCloseButton?: boolean;
  componentProps?: Object;
};

function useModal(props: Props) {
  const { init } = useContext(ModalContext);
  useEffect(() => {
    if (!props.openOnReady) return;
    init({
      ...props
    });
  }, [init, props]);
}

export default useModal;
