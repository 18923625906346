import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

import InlineLink from 'components/InlineLink';
import useDebouncedCallback from 'hooks/useDebouncedCallback';

import classNames from 'classnames';
import styles from 'assets/css/ShowMoreText.module.scss';

type ShowMoreTextProps = {
  className?: string;
  disabled?: boolean;
};

const isElementOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight
}: HTMLElement) => scrollHeight > clientHeight || scrollWidth > clientWidth;

const ShowMoreButton = ({ onClick }: { onClick: () => void }) => (
  <InlineLink onClick={onClick}>{'show more'}</InlineLink>
);

const ShowMoreText = ({
  disabled,
  className,
  children
}: PropsWithChildren<ShowMoreTextProps>) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isOverflown, setIsOverflown] = useState(false);

  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLSpanElement | null>(null);

  const handleResize = useDebouncedCallback(() => {
    if (childRef.current)
      isExpanded || setIsOverflown(isElementOverflown(childRef.current));
  }, 500);

  useEffect(() => {
    const parentElement = parentRef.current;

    if (parentElement && childRef.current) {
      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        handleResize(entries[0].target)
      );

      observer.observe(parentElement);

      return () => {
        if (parentElement) observer.unobserve(parentElement);
      };
    }
  }, [parentRef, handleResize]);

  useEffect(() => {
    if (childRef.current) setIsOverflown(isElementOverflown(childRef.current));
  }, [childRef]);

  const handleClick = () => setExpanded(!isExpanded);

  const style = isExpanded || disabled ? styles.extendedContainer : styles.showMoreContainer;

  return (
    <div className={classNames(style, className)} ref={parentRef}>
      <span ref={childRef} className={styles.content}>
        {children}
      </span>

      {isOverflown && !isExpanded && !disabled && (
        <ShowMoreButton onClick={handleClick} />
      )}
    </div>
  );
};

export default ShowMoreText;
