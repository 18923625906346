import React, { useState, useEffect, useContext } from 'react';
import { subDays, startOfToday, endOfToday, startOfYesterday } from 'date-fns';
import numeral from 'numeral';
import getRevenueParams from 'utils/getRevenueParams';
import useCleverTapOld from 'hooks/useCleverTapOld';
import getOutletsRevenue from 'queries/getOutletsRevenue';
import getOutletsECardRevenue from 'queries/getOutletsECardRevenue';
import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';
import AppLayout from 'layouts/AppLayout';
import { RESPONSIVE_BREAKPOINTS, COUNTRIES } from 'helpers/TypeConstants';
import Heading from 'components/Heading';
import Badge from 'components/Badge';
import { timezone } from 'utils/Dates';
import Button, { TappedButton } from 'components/Button';
import Table from 'components/Table';
import { Trans, useTranslation } from 'react-i18next';
import styles from 'assets/css/SalesSummaryView.module.scss';
import desktopInsightsBanner from 'assets/images/insights/insights-banner-desktop.svg';
import mobileInsightsBanner from 'assets/images/insights/insights-banner-mobile.svg';
import { MapleContext } from 'contexts/MapleContext';
import { WebviewContext } from 'contexts/WebviewContext';
import WebviewHeading from 'components/WebviewHeading';
import DatePicker, { DateInputBox } from 'components/DatePicker';
import { Alert } from 'components/common/Alert';

const today = timezone(endOfToday());
const todayDate = new Date();

const reportTypeFilter = [
  { text: 'All', value: 'total', event: 'filter_by_all' },
  { text: 'FavePay', value: 'fave_payment', event: 'filter_by_favepay' },
  { text: 'Deals', value: 'reservation', event: 'filter_by_deals' },
  { text: 'AliPay', value: 'alipay', event: 'filter_by_alipay' },
  { text: 'eCards', value: 'ecard', event: 'filter_by_ecards' }
];

const SalesSummaryView = props => {
  const { isMaple } = useContext(MapleContext);
  const { isWebview } = useContext(WebviewContext);
  const { t } = useTranslation();
  const dateFilters = [
    { text: t('Today'), value: today, event: 'filter_date_by_today' },
    isMaple && {
      text: t('Yesterday'),
      value: startOfYesterday(),
      event: 'filter_date_by_yesterday'
    },
    isMaple && {
      text: t('Last 7'),
      value: subDays(startOfToday(), 7),
      event: 'filter_date_by_last_7_days'
    },
    isMaple && {
      text: t('Last 30'),
      value: subDays(startOfToday(), 30),
      event: 'filter_date_by_last_30_days'
    }
  ].filter(item => item);
  const { user } = useContext(AuthContext);
  const [filter, setFilter] = useState({
    date: today,
    type: 'total'
  });

  const [revenue, setRevenue] = useState();
  const [cardRevenue, setCardRevenue] = useState();
  const { currentCompany, currentOutlet, outlets } = useContext(OutletContext);
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);

  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const screenName = isMaple ? 'summary' : 'data';
  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: screenName });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed, screenName]);

  const handleFilters = value => {
    let newFilter = { ...filter };
    if (value.date) {
      newFilter.date = value.date;

      cleverTap.tapped.push({
        screen_name: screenName,
        section_name: 'date_tabs',
        tapped_on: value.event
      });
    }

    if (value.type) {
      newFilter.type = value.type;
    }
    setFilter(newFilter);
  };

  const outletName = id => {
    let cachedOutlet = outlets.find(o => o.id === id);
    if (cachedOutlet) {
      return cachedOutlet.name;
    }
    return id;
  };

  const outletNeighbourhoodSlug = id => {
    let cachedOutlet = outlets.find(o => o.id === id);
    if (cachedOutlet) {
      return cachedOutlet.neighbourhood_slug;
    }
    return null;
  };

  const getTotalRevenueAmount = () => {
    if (filter.type === 'total') return revenue?.total_revenue?.revenue_amount;
    if (filter.type === 'ecard') return cardRevenue?.total?.amount;
    const sum = revenue.outlets.reduce(
      (cnt, o) =>
        o[`${filter.type}_revenue`]
          ? cnt + o[`${filter.type}_revenue`].revenue_cents
          : cnt + 0,
      0
    );

    const numeralSum = numeral((sum / 100).toFixed(2));
    return user.city.slug === 'jakarta'
      ? numeralSum.format('0,0').replaceAll(',', '.')
      : numeralSum.format('0,0.00');
  };

  const getTotalCount = () => {
    if (filter.type === 'total') return revenue?.total_revenue?.count;
    if (filter.type === 'ecard') return cardRevenue?.total?.transactions;
    return revenue.outlets.reduce(
      (cnt, o) =>
        o[`${filter.type}_revenue`]
          ? cnt + o[`${filter.type}_revenue`].count
          : cnt + 0,
      0
    );
  };

  const switchToInsights = () => {
    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on: 'btn_insights'
    });

    currentOutlet
      ? window.open(
          `/insights/${COUNTRIES[user.city.slug].toLowerCase()}/${
            currentCompany.id
          }?outletId=${currentOutlet.id}`,
          '_blank'
        )
      : window.open(
          `/insights/${COUNTRIES[user.city.slug].toLowerCase()}/${
            currentCompany.id
          }`,
          '_blank'
        );
  };

  useEffect(() => {
    let isMounted = true;
    if (endDate) {
      setRevenue();
      setCardRevenue();

      if (currentCompany && user.city) {
        const params = getRevenueParams({
          currentOutlet,
          user,
          startDate: startDate,
          endDate: endDate,
          filter,
          outlets,
          include_today_revenue: filter.date === timezone(today)
        });
        getOutletsRevenue({ ...params, company_id: currentCompany.id }).then(
          data => {
            if (!isMounted) return;
            setRevenue(data);
          }
        );

        getOutletsECardRevenue({
          company_id: currentCompany.id,
          start_datetime: timezone(startDate),
          end_datetime: timezone(endDate)
        }).then(data => {
          if (isMaple || !isMounted) return;
          // first item in the list is total
          setCardRevenue({
            outlets: data?.slice(1),
            total: data[0]
          });
        });
      }
    }

    return () => (isMounted = false);
  }, [
    currentCompany,
    currentOutlet,
    endDate,
    filter,
    filter.date,
    outlets,
    startDate,
    user,
    isMaple
  ]);

  return (
    <AppLayout>
      <section className={styles.SalesSummary}>
        {isWebview ? (
          <WebviewHeading
            title={isMaple ? t('Summary') : t('Data')}
            subtitle={t(
              'You can view all your sales data by outlet and customer'
            )}
          />
        ) : (
          <Heading
            title={isMaple ? t('Summary') : t('Data')}
            subtitle={t(
              'You can view all your sales data by outlet and customer'
            )}
          />
        )}
        {!isMaple && user.city && user.city.slug !== 'jakarta' && !isWebview && (
          <>
            <Button
              onClick={switchToInsights}
              type="ghost"
              className={styles.insightsLink}
            >
              <img
                src={desktopInsightsBanner}
                alt="Insights"
                className={styles.desktopInsightsBanner}
              />
            </Button>
            <Button
              onClick={switchToInsights}
              type="ghost"
              className={styles.insightsLink}
            >
              <img
                src={mobileInsightsBanner}
                alt="Insights"
                className={styles.mobileInsightsBanner}
              />
            </Button>
          </>
        )}
        <p className={styles.NavTitle}>{t('Select Date Range')}</p>
        <nav className={styles.Nav}>
          {dateFilters.map(f => {
            const formattedDate = timezone(f.value);
            const isCurrent = formattedDate === filter.date;
            return (
              <Badge
                key={f.value}
                checked={isCurrent}
                onChange={() => {
                  handleFilters({ date: formattedDate, event: f.event });
                }}
              >
                {f.text}
              </Badge>
            );
          })}

          <div className={styles.DateRange}>
            <DatePicker
              startDate={startDate}
              setStartDate={date => {
                if (date === undefined) {
                  // if the date-picker is cleared we will reset to today.
                  setFilter(filter => ({ ...filter, date: today }));
                }

                setStartDate(date || todayDate);
              }}
              endDate={endDate}
              setEndDate={date => setEndDate(date || todayDate)}
              disableFutureDates={true}
              isModal={true}
              showApplyClearButtons={true}
            >
              {(_, trigger) =>
                startDate === todayDate && endDate === todayDate ? (
                  <Badge
                    key="dates"
                    onChange={() => {
                      trigger();
                      handleFilters({
                        date: 'custom',
                        event: isMaple
                          ? 'filter_by_custom_dates'
                          : 'filter_date_by_dates'
                      });
                    }}
                  >
                    {isMaple ? t('Custom') : t('Dates')}
                  </Badge>
                ) : (
                  <DateInputBox
                    startDate={startDate}
                    endDate={endDate}
                    rangeDateSelection={true}
                    onClick={trigger}
                    isTextGreen={true}
                  />
                )
              }
            </DatePicker>
          </div>
        </nav>
        {!isMaple && (
          <div>
            <p className={styles.NavTitle}>{t('Show data for')}</p>
            <nav className={styles.Nav}>
              {reportTypeFilter.map(f => (
                <TappedButton
                  key={f.value}
                  type="ghost"
                  event={{
                    screen_name: screenName,
                    section_name: 'filter_tabs',
                    tapped_on: f.event
                  }}
                  onClick={() => handleFilters({ type: f.value })}
                >
                  <Badge checked={filter.type === f.value}>{f.text}</Badge>
                </TappedButton>
              ))}
            </nav>
            {filter.type === 'total' && (
              <Alert className={styles.alertWrapper}>
                <Trans>
                  The data shown for <strong>ALL</strong> does not include
                  eCards. To view data for eCards, please select the eCards
                  option.
                </Trans>
              </Alert>
            )}
          </div>
        )}
        {
          <Table className={styles.SalesSummaryResults}>
            <thead>
              <tr>
                <th>{t('Outlets')}</th>
                <th>
                  {filter.type === 'ecard'
                    ? t('eCard Credits Utilized ({{currency}})', {
                        currency:
                          user && user.city && user.city.currency_display
                      })
                    : t('Revenue ({{currency}})', {
                        currency:
                          user && user.city && user.city.currency_display
                      })}
                </th>
                <th>
                  {window.innerWidth < RESPONSIVE_BREAKPOINTS.sm
                    ? 'Txn'
                    : t('Transactions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {(!currentOutlet || filter.type === 'ecard') && (
                <tr className={styles.total}>
                  <td>{t('All outlets')}</td>
                  <td>{getTotalRevenueAmount() || 0}</td>
                  <td>{getTotalCount() || 0}</td>
                </tr>
              )}
              {filter.type === 'ecard' && cardRevenue
                ? cardRevenue.outlets &&
                  cardRevenue.outlets.map(
                    ({ outlet_name, amount, transactions }) => (
                      <tr key={outlet_name}>
                        <td>{outlet_name}</td>
                        <td>{Number(amount).toFixed(2)}</td>
                        <td>{transactions}</td>
                      </tr>
                    )
                  )
                : revenue &&
                  revenue.outlets &&
                  revenue.outlets.map(outlet => (
                    <tr key={outlet.id}>
                      <td>
                        {outletName(outlet.id)}
                        {isMaple && outletNeighbourhoodSlug(outlet.id) ? (
                          <span className={styles.neighbourhoodSlug}>
                            {' '}
                            | {outletNeighbourhoodSlug(outlet.id)}
                          </span>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {(outlet[`${filter.type}_revenue`] &&
                          outlet[`${filter.type}_revenue`].revenue_amount) ||
                          0}
                      </td>
                      <td>
                        {(outlet[`${filter.type}_revenue`] &&
                          outlet[`${filter.type}_revenue`].count) ||
                          0}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        }
      </section>
    </AppLayout>
  );
};

export default SalesSummaryView;
