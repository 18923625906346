import classNames from 'classnames';
import React, { useRef } from 'react';
import Button from './Button';
import IntroImage1 from 'assets/images/FaveFeed/fave-feed-modal-1.svg';
import IntroImage2 from 'assets/images/FaveFeed/fave-feed-modal-2.svg';
import IntroImage3 from 'assets/images/FaveFeed/fave-feed-modal-3.svg';
import IntroImage4 from 'assets/images/FaveFeed/fave-feed-modal-4.svg';
import style from 'assets/css/FaveFeedEducationModal.module.scss';
import { useTranslation } from 'react-i18next';
import Carousel from './common/Carousel';
import useCleverTapOld from 'hooks/useCleverTapOld';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import ModalContainer from './common/ModalContainer';
import { OutletContextType } from 'types/SettingsAPIResponseType';

const FaveFeedEducationModal = ({
  onClose,
  outletContext
}: {
  onClose: () => void;
  outletContext: OutletContextType;
}) => {
  const { t } = useTranslation();
  const selectedPreviewRef = useRef(0);
  const cleverTap = useCleverTapOld();

  const introSteps = [
    {
      id: 1,
      image: IntroImage1,
      title: t('Promote your offers with Feed for free'),
      description: t(
        'Reach out to all your customers by attaching your offer to your feed.'
      ),
      altImage: 'understand customer interaction'
    },
    {
      id: 2,
      image: IntroImage2,
      title: t('Write a quick shoutout on what’s new'),
      description: t('Let your customers know what’s new at your store.'),
      altImage: 'create announcement post'
    },
    {
      id: 3,
      image: IntroImage3,
      title: t('Review the performance of your post'),
      description: t('Understand how your customers interact with your posts.'),
      altImage: 'create announcement post'
    },
    {
      id: 4,
      image: IntroImage4,
      title: t('Learn more about our feed guidelines'),
      description: t('Understand our feed guidelines to create a post.'),
      altImage: 'promote offers'
    }
  ];

  const onClickContinue = (
    nextDisabled: boolean,
    swipeIndex: (isNext: boolean) => void
  ) => {
    if (nextDisabled) {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_EDUCATION,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_DONE,
        outletContext
      });
      onClose();
    } else {
      cleverTap.screenDisplayed.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_EDUCATION,
        outletContext
      });
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_EDUCATION,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CONTINUE,
        outletContext
      });
      swipeIndex(false);
    }
  };

  return (
    <ModalContainer className={style.educationModal}>
      <Carousel
        className={style.modalSwiper}
        showNavigationButtons={false}
        indicatorContainerStyles={style.indicatorContainer}
        selectedPreviewState={selectedPreviewRef}
        onDrawStaticContent={(
          _,
          nextDisabled: boolean,
          swipeIndex: (isNext: boolean) => void
        ) => (
          <div className={style.navigatorContainer}>
            <Button
              className={style.continueButton}
              type={'noHoverPrimary'}
              onClick={() => onClickContinue(nextDisabled, swipeIndex)}
              size={'lg'}
              textCenter={true}
              textBold={true}
            >
              {t(nextDisabled ? 'Done' : 'Continue')}
            </Button>
          </div>
        )}
        onDrawIndicator={(_, isActive: boolean) => (
          <div
            className={classNames(
              style.progressPoint,
              isActive && style.isActiveProgressPoint
            )}
          />
        )}
        onSwipeFunctionCall={() => {
          cleverTap.screenDisplayed.push({
            screen_name: CLEVERTAP_SCREEN_NAME.FEED_EDUCATION,
            outletContext
          });
        }}
      >
        {introSteps.map(item => (
          <div key={item.id} className={style.slide}>
            <div className={style.slideContainer}>
              <img
                src={item.image}
                className={style.modalImage}
                alt={item.altImage}
                draggable={false}
                onDragStart={e => e.preventDefault()}
              />
            </div>

            <div className={style.content}>
              <p className={style.title}>{item.title}</p>
              <p className={style.description}>{item.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </ModalContainer>
  );
};

export default FaveFeedEducationModal;
