import React, { CSSProperties, useCallback, useRef, useState } from 'react';
import {
  FeatureItem,
  FeatureResponse,
  getFeaturedList
} from 'queries/getFeatureListing';
import {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery
} from 'react-query';
import useFetchDataOnScroll from 'hooks/useFetchDataOnScroll';
import FaveFeedToggleFilter from 'components/FaveFeedToggleFilter';
import Grid from 'components/Grid';
import FeedFeatureCard from 'components/FeedFeatureCard';
import LoadingSpinnerSmall from 'components/LoadingSpinnerSmall';
import { FeedPostTypes, getScreenDisplayedEvent } from 'uiHelpers/feedForm';
import useCleverTapOld from 'hooks/useCleverTapOld';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import SwipeTabLayout from 'components/common/SwipeTabLayout';
import FilterPane from 'components/common/FilterPane';
import { TappedButton } from 'components/Button';
import EmptyListIndicator from 'components/EmptyListIndicator';

import noDealsIcon from 'assets/images/FaveFeed/ic-no-deals.svg';
import noECardsIcon from 'assets/images/FaveFeed/ic-no-ecards.svg';

import styles from 'assets/css/FeedFeatureListing.module.scss';

type FeatureListingProps = {
  type: FeedPostTypes;
  width?: number;
  itemClassName?: string;
  hasNextPage: boolean | undefined;
  useAnimatedTabs?: boolean;
  lastItemRef: (node: HTMLDivElement) => void;
  featureListing: InfiniteData<FeatureResponse> | undefined;
  onFeatureClick: (item: FeatureItem) => void;
};

type TabItemContentProps = {
  width?: number;
  className: string;
};

const EmptyStateMessage = ({ type }: { type: FeedPostTypes }) => {
  const isEcard = type === FeedPostTypes.eCards;

  let types = ['Deals', 'eCards'];

  if (isEcard) types = types.reverse();

  return (
    <EmptyListIndicator
      className={styles.emptyState}
      icon={isEcard ? noECardsIcon : noDealsIcon}
      title={`No ${types[0]}`}
      message={`You don't have any ${types[0]} yet. Try ${types[1]} instead.`}
    />
  );
};

const FeatureGrid = ({
  type,
  width,
  itemClassName,
  hasNextPage,
  lastItemRef,
  featureListing,
  onFeatureClick
}: FeatureListingProps) => {
  const style = width
    ? ({
        width
      } as CSSProperties)
    : {};

  const isEmpty =
    !featureListing ||
    (featureListing.pages.length && featureListing.pages[0].list.length === 0);

  return (
    <div className={itemClassName} style={style}>
      {isEmpty ? (
        <EmptyStateMessage type={type} />
      ) : (
        <>
          <Grid
            columns={3}
            minWidth={'15rem'}
            rowGap={'1.5rem'}
            columnGap={'1.5rem'}
          >
            {featureListing &&
              featureListing.pages.map(page =>
                page.list.map(item => (
                  <FeedFeatureCard
                    onFeatureClick={onFeatureClick}
                    key={item.id}
                    item={item}
                  />
                ))
              )}
          </Grid>
          <div ref={lastItemRef} />
          {hasNextPage && <LoadingSpinnerSmall />}
        </>
      )}
    </div>
  );
};

const FeedFeatureListing = ({
  company_id,
  initialPostType, // should never change
  onFeatureClick
}: {
  company_id: number;
  initialPostType: FeedPostTypes;
  onFeatureClick: (item: FeatureItem) => void;
}) => {
  const [postType, setPostType] = useState(initialPostType);
  const [useAnimatedTabs, setUseAnimatedTabs] = useState(true); // temporary for debugging
  const selectedTabRef = useRef(0);

  const cleverTap = useCleverTapOld();

  const {
    data: dealsListing,
    fetchNextPage: fetchDealsNextPage,
    hasNextPage: hasDealsNextPage,
    isFetching: isFetchingDeals
  } = useInfiniteQuery<FeatureResponse>(
    ['dealsList', { postType: FeedPostTypes.Deals, company_id: company_id }],
    FeatureListFetcher,
    dataFetchSettings
  );

  const {
    data: eCardsListing,
    fetchNextPage: fetcheCardsNextPage,
    hasNextPage: haseCardsNextPage,
    isFetching: isFetchingeCards
  } = useInfiniteQuery<FeatureResponse>(
    ['eCardsList', { postType: FeedPostTypes.eCards, company_id: company_id }],
    FeatureListFetcher,
    dataFetchSettings
  );

  const { featureListing, isFetching, hasNextPage, fetchNextPage } =
    postType === FeedPostTypes.Deals
      ? {
          featureListing: dealsListing,
          isFetching: isFetchingDeals,
          hasNextPage: hasDealsNextPage,
          fetchNextPage: fetchDealsNextPage
        }
      : {
          featureListing: eCardsListing,
          isFetching: isFetchingeCards,
          hasNextPage: haseCardsNextPage,
          fetchNextPage: fetcheCardsNextPage
        };

  const lastFeatureCardRef = useFetchDataOnScroll(
    isFetching,
    hasNextPage || false,
    fetchNextPage
  );

  const pushScreenDisplayedEvent = useCallback(
    (type: FeedPostTypes) => {
      const event = getScreenDisplayedEvent(1, false, type);

      if (event.screen_name) cleverTap.screenDisplayed.push(event);
    },
    [cleverTap]
  );

  const tabs = [
    {
      text: 'Deals',
      onClick: () => {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_FEATURED_STEP1,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_DEALS
        });

        const type = FeedPostTypes.Deals;

        pushScreenDisplayedEvent(type);
        setPostType(type);
      },
      Component: ({ width, className }: TabItemContentProps) => {
        const lastFeatureCardRef = useFetchDataOnScroll(
          isFetchingDeals,
          hasDealsNextPage || false,
          fetchDealsNextPage
        );

        return (
          <FeatureGrid
            width={width}
            type={FeedPostTypes.Deals}
            itemClassName={className}
            hasNextPage={hasDealsNextPage}
            lastItemRef={lastFeatureCardRef}
            featureListing={dealsListing}
            onFeatureClick={onFeatureClick}
          />
        );
      }
    },
    {
      text: 'eCards',
      onClick: () => {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_FEATURED_STEP1,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ECARDS
        });

        const type = FeedPostTypes.eCards;

        pushScreenDisplayedEvent(type);
        setPostType(type);
      },
      Component: ({ width, className }: TabItemContentProps) => {
        const lastFeatureCardRef = useFetchDataOnScroll(
          isFetchingeCards,
          haseCardsNextPage || false,
          fetcheCardsNextPage
        );

        return (
          <FeatureGrid
            width={width}
            type={FeedPostTypes.eCards}
            itemClassName={className}
            hasNextPage={haseCardsNextPage}
            lastItemRef={lastFeatureCardRef}
            featureListing={eCardsListing}
            onFeatureClick={onFeatureClick}
          />
        );
      }
    }
  ];

  return (
    <>
      {/* Temporary for debugging */}
      <label style={{ display: 'none' }}>
        <input
          type="checkbox"
          checked={useAnimatedTabs}
          onChange={() => setUseAnimatedTabs(!useAnimatedTabs)}
        />
        {' Use Animated Tabs'}
      </label>

      {useAnimatedTabs ? (
        <SwipeTabLayout
          selectedTabState={selectedTabRef}
          TabAreaContent={(activeIndex, swipeIndex) => (
            <FilterPane>
              {tabs.map((item, index) => (
                <TappedButton
                  key={item.text}
                  type={activeIndex === index ? 'primary' : 'default'}
                  isRedirect={false}
                  isRounded
                  {...item}
                  onClick={() => {
                    item.onClick();

                    if (activeIndex !== index) swipeIndex(index < activeIndex);
                  }}
                >
                  {item.text}
                </TappedButton>
              ))}
            </FilterPane>
          )}
        >
          {(width, itemClass) => (
            <>
              {tabs.map(tab => (
                <tab.Component
                  key={tab.text}
                  width={width}
                  className={itemClass}
                />
              ))}
            </>
          )}
        </SwipeTabLayout>
      ) : (
        <>
          <FaveFeedToggleFilter
            value={FeedPostTypes[postType]}
            buttonProps={tabs}
          />

          <FeatureGrid
            type={postType}
            hasNextPage={hasNextPage}
            lastItemRef={lastFeatureCardRef}
            featureListing={featureListing}
            onFeatureClick={onFeatureClick}
          />
        </>
      )}
    </>
  );
};

const setItemType = (item: FeatureItem, type: FeedPostTypes) =>
  (item.type = type);

const dataFetchSettings = {
  //other args: pages: FeatureResponse[]
  getNextPageParam: (lastPage: FeatureResponse) => {
    const ITEMS_PER_PAGE = 10;

    if (lastPage.page * ITEMS_PER_PAGE < lastPage.total_count)
      return lastPage.page + 1;

    return false;
  },
  retry: 0,
  refetchOnWindowFocus: false
};

const FeatureListFetcher = ({
  pageParam = 1,
  queryKey
}: QueryFunctionContext<QueryKey, number>) => {
  const [, { postType, company_id }] = queryKey as [
    string,
    { postType: FeedPostTypes; company_id: number | undefined }
  ];

  if (!company_id || !postType || postType === FeedPostTypes.Announcement)
    return new Promise<FeatureResponse>(resolve =>
      resolve({
        list: [],
        e_cards: [],
        deals: [],
        page: pageParam,
        total_count: 0
      })
    );

  return new Promise<FeatureResponse>((resolve, reject) => {
    getFeaturedList({
      company_id: company_id,
      page: pageParam,
      featureType: postType
    })
      .then(({ deals, e_cards, total_count }) => {
        const list = postType === FeedPostTypes.Deals ? deals : e_cards;

        list.forEach((item: FeatureItem) => setItemType(item, postType));

        resolve({ list, e_cards: [], deals: [], page: pageParam, total_count });
      })
      .catch(error => reject(error));
  });
};

export default FeedFeatureListing;
