import { useEffect } from 'react';

export default function useNoScrollBackground(noScroll = false) {
  useEffect(() => {
    // https://stackoverflow.com/questions/3047337/does-overflowhidden-applied-to-body-work-on-iphone-safari
    [document.documentElement, document.body].forEach(el =>
      el.classList[noScroll ? 'add' : 'remove']('noScroll')
    );
  }, [noScroll]);
}
