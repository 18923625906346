import React, { useContext } from 'react';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import AppLayout from 'layouts/AppLayout';
import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import Card from 'components/Card';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/SwitchBusiness.module.scss';

const SwitchBusinessView = () => {
  const { t } = useTranslation();
  const { user = {} } = useContext(AuthContext);
  const { currentCompany, changeCompany } = useContext(OutletContext);
  const currentCompanyId = (currentCompany || {}).id;
  const { companies = [] } = user;

  const CompanyButton = ({ id, name, logo_url }) => (
    <Button
      type="text"
      size="lg"
      className={classNames(styles.button, styles.companyButton)}
      onClick={() => {
        changeCompany(id);
        navigate('/');
      }}
    >
      <div
        className={styles.companyImage}
        role="presentation"
        aria-label={name}
        style={{ '--bg': `url(${logo_url})` }}
      />
      {name}
      <span
        className={classNames(id === currentCompanyId && [styles.companyIcon])}
      />
    </Button>
  );

  return (
    <AppLayout>
      <div className={styles.overlay}>
        <Card className={styles.switchCompanyCard}>
          <div className={styles.title}>{t('Select your business')}</div>
          {companies.length === 0 && (
            <section className={styles.loadingSpinnerWrapper}>
              <LoadingSpinner />
            </section>
          )}
          <section className={styles.companies}>
            {companies.map(company => (
              <CompanyButton
                key={company.id}
                name={company.partner_name}
                {...company}
              />
            ))}
          </section>
        </Card>
      </div>
    </AppLayout>
  );
};

export default SwitchBusinessView;
