import React, { useContext, useEffect, useState } from 'react'
import styles from 'assets/css/GuaranteeFeeLabel.module.scss'
import useAuthContext from 'hooks/useAuthContext';
import { AuthContext } from 'contexts/AuthContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';

type GuaranteeFeeLabelProps = {
    guarunteeFee: number,
    isTransactionCard: boolean,
}

interface CountrySettings {
    'singapore': boolean,
    'kuala-lumpur': boolean,
    'jakarta': boolean,
}

const SHOW_IN: CountrySettings = {
    'singapore': true,
    'kuala-lumpur': false,
    'jakarta': false,
}

export default function GuaranteeFeeLabel({ guarunteeFee, isTransactionCard }: GuaranteeFeeLabelProps) {
    const { isOperations } = useAuthContext();
    const { user } = useContext(isOperations ? OpsAuthContext : AuthContext);
    const [showLabel, setShowLabel] = useState<boolean>(false);

    useEffect(() => {
        const showInThisCity = SHOW_IN[user?.city?.slug as
            'singapore' | 'kuala-lumpur' | 'jakarta']
        setShowLabel(showInThisCity && (guarunteeFee > 0))
    }, [user, guarunteeFee])

    return (
        showLabel ? (
            <div className={styles.exclusiveGuarunteeFee}>
                {
                    isTransactionCard ? (<div>
                        *Fave Guarantee Fee is added<span>{' to the price on Fave App'}</span>
                    </div>) : (<div className={styles.linkDiv}>
                        <span id={styles.internalSpan}>
                            <a
                                href="https://support.favebiz.com/support/solutions/articles/43000582714-what-is-fave-guarantee-fee-"
                                id={styles.linkStyle}
                                rel="noopener noreferrer"
                                target="_blank" >
                                *Fave Guarantee Fee
                                <div className={styles.image}></div>
                            </a>
                            {'is added'}
                        </span>
                        <span id={styles.internalSpan}>{"to the price on Fave App"}</span>
                    </div>)
                }
            </div>
        ) : <></>
    );
}


