import React, { useState, useContext, useCallback } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { OutletContext } from 'contexts/OutletContext';
import { MapleContext } from 'contexts/MapleContext';
import useCleverTapOld from 'hooks/useCleverTapOld';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Button from 'components/Button';
import OutOfBoundClick from 'components/OutOfBoundClick';
import { navigate } from '@reach/router';

import styles from 'assets/css/OutletSwitcher.module.scss';

const OutletSwitcher = ({ onChange }) => {
  const { t } = useTranslation();
  const clevertap = useCleverTapOld();
  const { outlets, currentCompany, currentOutlet, changeOutlet } = useContext(
    OutletContext
  );
  const { isMaple } = useContext(MapleContext);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const outletId = currentOutlet ? currentOutlet.id : '';
  const outletImage = currentCompany && currentCompany.logo_url;
  const items = outlets || [];

  const isAllOutlet = outletId === '';
  const selectedOutlet = items && items.find(data => data.id === outletId);
  const isSingleOutlet = items && items.length === 1;

  const onClickCallback = data => {
    setStatementsURL();
    changeOutlet(data);
    setIsDropdownVisible(false);
    onChange(data);
  };

  const setStatementsURL = () => {
    // if all outlets is selected, brings back to list of statements grouped by outlets
    if (!currentOutlet && window.location.pathname.includes('/statements')) {
      navigate(`/statements`);
    }
    return;
  };

  const outletSwitchCTEvent = () =>
    clevertap.tapped.push({
      screen_name: 'navigation_bar',
      section_name: 'bar_side',
      tapped_on: 'btn_outlet_business'
    });

  const OutletButton = useCallback(
    ({ item, onClick = () => { }, disabled = false }) => (
      <Button
        type="text"
        disabled={disabled}
        className={disabled ?
          classNames(styles.button, styles.outletButton, styles.outletButtonDisabled) :
          classNames(styles.button, styles.outletButton)}
        onClick={onClick}
        fullWidth
      >
        <section className={styles.outletButtonWrapper}>
          <div
            className={styles.outletImage}
            role="presentation"
            aria-label={item.name}
            style={{ '--bg': `url(${outletImage})` }}
          />
          {isMaple ?
            <div>
              <div className={styles.mapleOutletName}>{item.name}</div>
              {item.neighbourhood_slug ? <div className={styles.mapleOutletNeighbourhoodSlug}>| {item.neighbourhood_slug}</div> : <></>}
            </div> :
            <div className={styles.outletName}>{item.name}</div>
          }
        </section>
        {item.id === outletId && (
          <div className={styles.outletCheckIcon}>
            <Icon icon="check" />
          </div>
        )}
      </Button>
    ),
    [outletId, outletImage, isMaple]
  );

  const SwitcherButton = useCallback(() => {
    const currentOutlet =
      (isAllOutlet && t('All Outlets')) ||
      (selectedOutlet && selectedOutlet.name);
    const neighbourhoodSlug =
      (isAllOutlet && null) ||
      (selectedOutlet && selectedOutlet.neighbourhood_slug);
    return (
      <Button
        className={classNames(
          styles.button,
          isSingleOutlet
            ? styles.switcherButton
            : styles.switcherButtonWithCaretDown
        )}
        type="text"
        onClick={() => setIsDropdownVisible(prev => !prev)}
        disabled={isSingleOutlet}
      >
        <section className={styles.outletButtonWrapper}>
          {outletImage && (
            <div
              className={styles.outletImage}
              role="presentation"
              aria-label={currentOutlet}
              style={{ '--bg': `url(${outletImage})` }}
            />
          )}
          {isMaple ?
            <div className={styles.switcherMapleTitle}>
              <div className={styles.switcherMapleOutletName}>{currentOutlet}</div>
              {neighbourhoodSlug ? <div className={styles.switcherMapleOutletNeighbourhoodSlug}>| {neighbourhoodSlug}</div> : <></>}
            </div> :
            <div className={styles.switcherTitle}>{currentOutlet}</div>
          }
        </section>
      </Button>
    );
  }, [isAllOutlet, isSingleOutlet, outletImage, selectedOutlet, t, isMaple]);

  return (
    <OutOfBoundClick onClick={() => setIsDropdownVisible(false)}>
      <Card className={styles.switcherContainer}>
        <SwitcherButton />
        <Card
          className={classNames(
            styles.outletCard,
            isDropdownVisible && styles.visible
          )}
        >
          <section>
            <span className={styles.outletTitle}>Select your outlets</span>
            {!isSingleOutlet && (
              <>
                <OutletButton
                  item={{ name: t('All Outlets'), id: '' }}
                  onClick={() => {
                    onClickCallback('');
                    outletSwitchCTEvent();
                  }}
                  disabled={window.location.pathname.includes('/operations/transactions')}
                />
                {(items || []).map(item => (
                  <OutletButton
                    key={item.id || item.name}
                    item={item}
                    onClick={() => {
                      onClickCallback(item.id);
                      outletSwitchCTEvent();
                    }}
                  />
                ))}
              </>
            )}
          </section>
        </Card>
      </Card>
    </OutOfBoundClick>
  );
};

export default OutletSwitcher;
