import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import styles from 'assets/css/LoadingSpinnerSmall.module.scss';

type LoadingSpinnerProps = {
  baseSize?: string;
  color?: string;
  className?: string;
};

const LoadingSpinnerSmall = ({
  baseSize = '2px',
  color = 'primary',
  className
}: LoadingSpinnerProps) => (
  <section className={classNames(className, styles.container)}>
    <div
      className={styles.loadingSpinner}
      style={
        {
          '--loading-base-size': baseSize,
          '--loading-color': `var(--${color})`
        } as CSSProperties
      }
    />
  </section>
);

export default LoadingSpinnerSmall;
