import FaveBizAPI from 'integrations/FaveBizAPI';
import { GenericSuccessType } from '../types/GenericSuccessType';

type Params = {
  finance_account_id: number;
  email: string;
  documents: string[];
  invoice_period_start: string;
  invoice_period_end: string;
};

export const sendInvoiceEmail = (params: Params): Promise<GenericSuccessType> =>
  FaveBizAPI.post(`/v1/invoices/send_email`, params);
