import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from 'components/LoadingSpinner';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Button from 'components/Button';
import styles from 'insights/assets/css/InsightsOutletSwitcher.module.scss';
import useInsightsParams from 'insights/hooks/useInsightsParams';
import useNoScrollBackground from 'hooks/useNoScrollBackground';
import useGetSettings from 'insights/queries/useGetSettings';
import { hasData } from 'insights/utils';
import useCleverTapOld from 'hooks/useCleverTapOld';

function InsightsOutletSwitcher({ onChange }) {
  const { t } = useTranslation();
  const { outletId } = useInsightsParams();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const currentOutlet = outlets.find(outlet => outlet.id === outletId);
  const isSingleOutlet = outlets.length < 1;
  const isLoading = outlets.length === 0;
  const CleverTap = useCleverTapOld();

  const response = useGetSettings();
  useEffect(() => {
    if (hasData(response.data)) setOutlets(response.data.settings.outlets);
  }, [response.data]);

  useNoScrollBackground(isDropdownVisible);

  const onSwitchOutlet = outletId => {
    setIsDropdownVisible(false);
    onChange(outletId);
  };

  const OutletButton = useCallback(
    ({ outlet, onClick = () => {} }) => {
      const isActive = outlet.id === outletId;
      return (
        <Button
          type="text"
          className={classNames(styles.button, styles.outletButton)}
          onClick={onClick}
          fullWidth
          size="lg"
        >
          <span
            className={classNames(
              styles.outletName,
              isActive && styles.isActive
            )}
          >
            {outlet.name}
          </span>
          {isActive && (
            <div className={styles.outletCheckIcon}>
              <Icon icon="check" />
            </div>
          )}
        </Button>
      );
    },
    [outletId]
  );

  return (
    <>
      <div className={styles.switcherButtonLayout}>
        <div className={styles.label}>Outlet</div>
        <img
          src={require('insights/assets/images/nav/outlet.svg')}
          alt="Outlet"
        />
        <Button
          className={styles.switcherButton}
          type="text"
          onClick={() => {
            setIsDropdownVisible(true);
            CleverTap.tapped.push({
              screen_name: 'insight',
              section_name: 'bar_top',
              tapped_on: 'btn_outlet'
            });
          }}
          disabled={isLoading}
        >
          <span className={styles.switcherTitle}>
            {currentOutlet ? currentOutlet.name : 'All Outlets'}
          </span>
          {isLoading && (
            <LoadingSpinner className={styles.loadingSpinner} baseSize=".8px" />
          )}
        </Button>
      </div>
      <div
        className={classNames(
          styles.overlay,
          isDropdownVisible && styles.visible
        )}
        onClick={() => setIsDropdownVisible(false)}
      >
        <Card className={classNames(styles.outletCard)}>
          <section className={styles.scrollableLayout}>
            <span className={styles.outletTitle}>Select your outlet</span>
            <ul>
              {!isSingleOutlet && (
                <>
                  <li>
                    <OutletButton
                      outlet={{ name: t('All Outlets'), id: '' }}
                      onClick={() => onSwitchOutlet('')}
                    />
                  </li>
                  {(outlets || []).map(outlet => (
                    <li key={outlet.id}>
                      <OutletButton
                        outlet={outlet}
                        onClick={() => onSwitchOutlet(outlet.id)}
                      />
                    </li>
                  ))}
                </>
              )}
            </ul>
          </section>
        </Card>
      </div>
    </>
  );
}

InsightsOutletSwitcher.propTypes = { onChange: PropTypes.func };
InsightsOutletSwitcher.defaultProps = { onChange: () => {} };

export default InsightsOutletSwitcher;
