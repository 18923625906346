import React, { ReactNode } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import Button from 'components/Button';
import styles from 'assets/css/SubmitButton.module.scss';

export type SubmitButtonProps<T> = {
  isDisabled?:
    | boolean
    | ((values: T, context: FormikContextType<T>) => boolean);
  disableIfNotValid: boolean;
  isWebview?: boolean;
  children?: ReactNode | ((values: T, isSubmitting: boolean) => ReactNode);
};

const SubmitButton = <T extends {}>({
  children,
  isDisabled,
  disableIfNotValid,
  isWebview = false
}: SubmitButtonProps<T>) => {
  const context = useFormikContext<T>();

  const { values, handleSubmit, isValid, isSubmitting } = context;

  const conditionallyDisabled = isDisabled
    ? typeof isDisabled === 'boolean'
      ? isDisabled
      : isDisabled(values, context)
    : false;

  const disabled =
    isSubmitting || conditionallyDisabled || (disableIfNotValid && !isValid);

  return (
    <Button
      className={styles.button}
      type="primary"
      disabled={disabled}
      onClick={_event => handleSubmit()} // incompatible types between formik and Button
      onMouseDown={event => {
        //to prevent blur event from triggering validation
        //which cancels the submit
        event.preventDefault();
      }}
      isWebview={isWebview}
      size={'lg'}
    >
      {children &&
        (typeof children === 'function'
          ? children(values, isSubmitting)
          : children)}
    </Button>
  );
};

export default SubmitButton;
