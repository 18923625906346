import React, { ComponentType, ElementType } from 'react';
import style from 'assets/css/BannerMessage.module.scss';
import PostInfoIcon from 'assets/images/FaveFeed/post-info-icon.svg';
import PostWarningIcon from 'assets/images/FaveFeed/post-warning-icon.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type BannerType = 'info' | 'warning';

type BannerMessagePropTypes<T = {}> = {
  Component?: ComponentType<T> | ElementType;
  message?: string;
  type?: BannerType;
  marginBottom?: boolean;
};

const BannerMessage: React.FC<BannerMessagePropTypes> = props => {
  const { t } = useTranslation();

  const {
    Component,
    message = '',
    type = 'info',
    marginBottom = true,
    children
  } = props;

  const isInfo = type === 'info';
  const bannerStyle = isInfo ? style.postInfo : style.postWarning;
  const BannerIcon = isInfo ? PostInfoIcon : PostWarningIcon;

  return (
    <div
      className={classNames(
        style.postLimitBanner,
        bannerStyle,
        !marginBottom && style.noMarginBottom
      )}
    >
      <div className={style.bannerIcon}>
        <img src={BannerIcon} alt="post limit warning" />{' '}
      </div>

      {Component ? (
        <Component />
      ) : (
        <div className={style.bannerText}>{t(message)}</div>
      )}

      <div>{children}</div>
    </div>
  );
};

export default BannerMessage;
