import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import useCloseOnElementBlur from 'hooks/useCloseOnElementBlur';
import { TappedButton } from 'components/Button';
import styles from 'assets/css/Dropdown.module.scss';
import Separator from './common/Separator';

export type IDropdownProps = {
  buttonClassName?: string;
  contentClassName?: string;
  dropdownButton: JSX.Element;
  dropdownItems: {
    separator?: boolean;
    tap?: boolean;
    event?: { screen_name: string; section_name?: string; tapped_on: string };
    Component?: ({ className }: { className: string }) => JSX.Element;
    classNames?: string[];
    title?: string;
    onClick?: () => void;
  }[];
  stayOpen?: boolean;
  onClick?: (isOpen: boolean) => void;
  showTopRightDropDown?: boolean;
  showToggleButton?: boolean;
};

const Dropdown: React.FC<IDropdownProps> = ({
  buttonClassName,
  contentClassName,
  dropdownButton,
  dropdownItems = [],
  stayOpen = false,
  onClick = () => {},
  showTopRightDropDown = false,
  showToggleButton = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownEl = useRef<HTMLDivElement>(null);
  const toggleEl = useRef<HTMLDivElement>(null);

  const handleItemClick = (clickCallback?: () => void) => {
    clickCallback && clickCallback();
    setIsOpen(false);
  };

  useCloseOnElementBlur({
    elementRef: dropdownEl,
    setIsOpen,
    stayOpen: stayOpen || !showTopRightDropDown,
    elementToIgnore: toggleEl
  });

  useEffect(() => {
    setIsOpen(showTopRightDropDown);
  }, [showTopRightDropDown]);

  return (
    <section ref={dropdownEl} className={styles.dropdown}>
      <div
        ref={toggleEl}
        className={classNames({
          [styles.toggle]: showToggleButton,
          buttonClassName
        })}
        onClick={() => onClick(isOpen)}
      >
        {dropdownButton}
      </div>
      <div
        className={classNames(
          styles.content,
          contentClassName,
          isOpen && styles.isOpen
        )}
      >
        <nav className={styles.dropdownItems}>
          {dropdownItems.map(
            (
              {
                tap,
                separator,
                event,
                Component,
                classNames: itemClassNames,
                title,
                onClick,
                ...link
              },
              index
            ) =>
              separator ? (
                <Separator className={styles.separator} key={title || index} />
              ) : Component ? (
                <Component
                  key={title}
                  className={classNames(
                    ...(itemClassNames || []).map(name => styles[name])
                  )}
                />
              ) : (
                <TappedButton
                  key={title}
                  type="text"
                  size="xs"
                  className={classNames(
                    styles.button,
                    styles.dropdownItem,
                    itemClassNames
                  )}
                  event={tap ? event : undefined}
                  onClick={() => handleItemClick(onClick)}
                  {...link}
                >
                  {title}
                </TappedButton>
              )
          )}
        </nav>
      </div>
    </section>
  );
};

export default Dropdown;
