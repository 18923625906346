import React, { useEffect } from 'react';
import { navigate, RouterProps } from '@reach/router';

const PDFFileEmbed: React.FC<RouterProps> = ({ location }) => {
  const pdfFileName = location?.pathname;

  const url = `https://terms.favebiz.com${pdfFileName}`;

  useEffect(() => {
    // fallback for mobile browsers, notably safari on iOS
    // navigator.maxTouchPoints returns undefined on iOS 12

    const isMobileDevice =
      window.matchMedia('(pointer: coarse)').matches ||
      navigator.maxTouchPoints > 1;
    if (isMobileDevice) {
      navigate(url);
    }
  }, [url]);

  return (
    <div style={{ maxHeight: '100vh', overflow: 'hidden' }}>
      <iframe
        title={url}
        src={url}
        height={window.innerHeight}
        width={'100%'}
      />
    </div>
  );
};

export default PDFFileEmbed;
