import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from 'assets/css/EmptyListIndicator.module.scss';

function EmptyListIndicator({
  className,
  title,
  message,
  icon,
  children
}: PropsWithChildren<{
  title: string;
  message: string;
  icon: string;
  className?: string;
}>) {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.icon}>
        <img src={icon} alt={title} />
      </div>
      <div className={styles.text}>
        <strong className={styles.title}>{t(title)}</strong>
        <span className={styles.message}>{t(message)}</span>
      </div>
      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
}

export default EmptyListIndicator;
