import React, { useState, useEffect } from 'react';

export const isAppMapleBiz = process.env.REACT_APP_NAME === 'MapleBiz';

const MapleContext = React.createContext();

const MapleContextProvider = props => {
  const [isMaple, setIsMaple] = useState(false);

  useEffect(() => {
    if (isAppMapleBiz) setIsMaple(true);
  }, []);

  const implementation = {
    isMaple
  };

  return (
    <MapleContext.Provider value={implementation}>
      {props.children}
    </MapleContext.Provider>
  );
};

const MapleContextConsumer = MapleContext.Consumer;

export { MapleContext, MapleContextProvider, MapleContextConsumer };
