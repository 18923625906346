import Kfit from '../integrations/Kfit';
import DataLoader from 'dataloader';

export default params =>
  params.id
    ? loadInfo(`/v5/vouchers/${params.id}`)
    : loadInfo(`/v5/vouchers`, params);

export const Repo = new DataLoader(
  keys =>
    Promise.all(
      keys.map(({ key, params }) =>
        Kfit.get(key, params).then(response => response)
      )
    ),
  {
    batch: false,
    cacheKeyFn: key => JSON.stringify(key)
  }
);

function loadInfo(key, params = null) {
  return Repo.load({ key, params });
}
