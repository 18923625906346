import React, { useContext } from 'react';
import classNames from 'classnames';
import AppLayout from 'layouts/AppLayout';
import { OutletContext } from 'contexts/OutletContext';
import { MapleContext } from 'contexts/MapleContext';
import Card from 'components/Card';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Cookie from 'helpers/Cookie';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/SwitchStaffModeView.module.scss';

const SwitchStaffModeView = () => {
  const { t } = useTranslation();
  const { outlets, currentCompany } = useContext(OutletContext);
  const { isMaple } = useContext(MapleContext);
  const companyLogo = (currentCompany || {}).logo_url;

  const OutletButton = ({ id, name, auth_code, neighbourhood_slug }) => (
    <Button
      type="text"
      size="lg"
      className={classNames(styles.button, styles.outletButton)}
      onClick={() => {
        Cookie.remove('currentOutletCode');
        Cookie.set('authCode', auth_code);
        window.location.assign('/operations/transactions');
      }}
    >
      <img
        alt="business logo"
        src={companyLogo}
        className={styles.companyImage}
      />
      {name}{isMaple && neighbourhood_slug ?
        <span className={styles.outletNeighbourhoodSlug}>&nbsp;| {neighbourhood_slug}</span> :
        <></>
      }
      <div className={styles.outletCode}>{`- ${auth_code}`}</div>
    </Button>
  );

  return (
    <AppLayout>
      <div className={styles.overlay}>
        <Card className={styles.switchOutletCard}>
          <div className={styles.title}>
            {t('Select Outlet to go to Staff Mode')}
          </div>
          {outlets && outlets.length === 0 && (
            <section className={styles.loadingSpinnerWrapper}>
              <LoadingSpinner />
            </section>
          )}
          <section className={styles.outlets}>
            {outlets &&
              outlets.map(outlet => (
                <OutletButton
                  key={outlet.id}
                  name={outlet.partner_name}
                  {...outlet}
                />
              ))}
          </section>
        </Card>
      </div>
    </AppLayout>
  );
};

export default SwitchStaffModeView;
