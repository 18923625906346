import React, { useContext, useState, useEffect } from 'react';
import { QueryStringContext } from 'contexts/QueryStringContext';
import Cookie from 'helpers/Cookie';

const WebviewContext = React.createContext();

const WebviewContextProvider = props => {
  const removedWebviewCookie = Cookie.get('removedWebviewCookie');

  if (removedWebviewCookie !== true) {
    Cookie.remove('isWebview');
    Cookie.set('removedWebviewCookie', true);
  }

  const [isWebview, setIsWebview] = useState(Cookie.get('isWebview', false));

  const params = useContext(QueryStringContext);

  const [platform, setPlatform] = useState(Cookie.get('platform', 'Web'));

  const [isManagerMode, setIsManagerMode] = useState(false);
  /**iOS & Android need this special param because they allow
   * manager to interact with staff mode without having to
   * switch to staff mode first
   */

  useEffect(() => {
    if (isWebview || params.app) {
      if (params.os) setPlatform(params.os);
      if (params.mode === 'manager') setIsManagerMode(true);
      Cookie.set('isWebview', true);
      Cookie.set('platform', platform);
      setIsWebview(true);
      Cookie.set('firstTime', false);
    } else {
      setIsWebview(false);
      setPlatform('Web');
      Cookie.set('platform', 'Web');
      Cookie.set('isWebview', false);
    }
  }, [params, isWebview, platform]);

  const implementation = {
    isWebview,
    platform,
    isManagerMode
  };

  return (
    <WebviewContext.Provider value={implementation}>
      {props.children}
    </WebviewContext.Provider>
  );
};

const useWebViewContext = () => useContext(WebviewContext);

const WebviewContextConsumer = WebviewContext.Consumer;

export {
  WebviewContext,
  WebviewContextProvider,
  useWebViewContext,
  WebviewContextConsumer
};
