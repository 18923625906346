import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookie from 'helpers/Cookie';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: false,
    returnEmptyString: false,
    lng: Cookie.get('currentLang', 'en-US'),

    nsSeparator: false,
    keySeparator: false,
    react: {
      wait: true,
      useSuspense: true
    }
  });

export default i18n;
