import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { OutletContext } from 'contexts/OutletContext';
import getECardsRevenueDetails from 'queries/getECardsRevenueDetails';
import { WebviewContext } from 'contexts/WebviewContext';

import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import Button from 'components/Button';
import ECard from 'components/ECard';
import CostStructure from 'components/CostStructure';
import WebviewHeading from 'components/WebviewHeading';

import styles from 'assets/css/ECardsPendingDetailsView.module.scss';

const ECardsDetailsView = ({ ecards_id }) => {
  const { t } = useTranslation();
  const { currentCompany } = useContext(OutletContext);
  const { isWebview } = useContext(WebviewContext);

  const [eCardsResponse, setECardsResponse] = useState({});
  const {
    currency,
    sellingPrice,
    originalPrice,
    discountRate,
    commissionRate,
    gstCommission,
    totalTakebackProfit,
    textColor,
    backgroundColor,
    outletNo,
    validity,
    sold,
    revenue,
    hasDefaultLogo,
    companyLogoUrl
  } = eCardsResponse || {};
  const {
    logo_url: outletImage,
    partner_name: companyName,
    id: company_id,
    num_bank_accounts
  } = currentCompany || {};
  const response = getECardsRevenueDetails({ ecards_id, company_id });

  useEffect(() => {
    if (!response.data) return;
    const {
      e_card: {
        currency,
        selling_price,
        original_price,
        discount,
        commision_rate,
        gst,
        total_takeback_profit,
        text_color,
        background_color,
        outlets_count,
        validity,
        sold,
        revenue,
        default_logo,
        company_logo_url
      }
    } = response.data;

    setECardsResponse({
      currency,
      sellingPrice: selling_price,
      originalPrice: original_price,
      discountRate: discount,
      commissionRate: commision_rate,
      gstCommission: gst,
      totalTakebackProfit: total_takeback_profit,
      textColor: text_color,
      backgroundColor: background_color,
      outletNo: outlets_count,
      validity: validity,
      sold,
      revenue,
      hasDefaultLogo: default_logo,
      companyLogoUrl: company_logo_url
    });
  }, [response.data]);

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading title="View Details" backURL={'/ecards'} />
      ) : (
        <>
          <Heading
            title="eCards"
            subtitle={t('View your eCards performance at your outlet')}
          />
          <Button
            leftIcon="arrow-left"
            type="text"
            href={'/ecards'}
            iconColor="light-black"
            children={t('Back')}
            textColor="charcoal-gray"
          />
        </>
      )}

      <div className={styles.container}>
        <ECard
          className={isWebview ? styles.webviewCard : styles.card}
          sellingPrice={sellingPrice}
          discountRate={discountRate}
          originalPrice={originalPrice}
          outletImage={hasDefaultLogo ? outletImage : companyLogoUrl}
          companyName={companyName}
          currency={currency}
          textColor={textColor}
          backgroundColor={backgroundColor}
        />
        <CostStructure
          title={t('eCards details')}
          currency={currency || ''}
          originalPrice={originalPrice}
          sellingPrice={sellingPrice}
          discountRate={discountRate}
          commissionRate={commissionRate}
          commissionEnabled={Boolean(commissionRate)}
          takebackProfit={totalTakebackProfit}
          gstCommission={gstCommission}
          outletNo={outletNo}
          validity={validity}
          sold={sold}
          revenue={revenue}
          showDisbursementMethod={num_bank_accounts > 1}
        />
      </div>
    </AppLayout>
  );
};

export default ECardsDetailsView;
