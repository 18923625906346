import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import Dropdown, { IDropdownProps } from 'components/Dropdown';
import BrandLogo from 'components/BrandLogo';
import Icon from 'components/Icon';
import Button from 'components/Button';
import AddToHomescreenPromo from 'components/AddToHomescreenPromo';

import styles from 'assets/css/Topbar.module.scss';

type TopbarProps = {
  dropdownItems: IDropdownProps['dropdownItems'];
  sidebarToggle: () => void;
  currentCompanyLogo: string;
  currentOutletName: string;
  isStaff?: boolean;
  onClickProfile?: (isOpen: boolean) => void;
  showTopRightDropDown?: boolean;
};

const Topbar: React.FC<TopbarProps> = ({
  dropdownItems,
  sidebarToggle,
  currentOutletName,
  currentCompanyLogo,
  isStaff = false,
  onClickProfile,
  showTopRightDropDown = false
}) => {
  return (
    <header className={styles.topbar}>
      <AddToHomescreenPromo />
      <div className={classNames(styles.topbarContent, 'container')}>
        <BrandLogo position="left" className={styles.brandLogo} />

        <section className={styles.menuWrapper}>
          <Button
            type="text"
            className={classNames(styles.button, styles.toggle)}
            onClick={() => {
              sidebarToggle();
            }}
          >
            <Icon icon="menu" color="primary" size="1.9375rem" />
          </Button>
          <span className={styles.mobileOutletName}>{currentOutletName}</span>
        </section>
        {isStaff && <div className={styles.staffBadge}>Staff Mode</div>}

        <Dropdown
          buttonClassName={styles.dropdownButton}
          dropdownButton={
            <div
              className={styles.profileIcon}
              style={
                {
                  '--profile-icon': `url(${currentCompanyLogo})`
                } as CSSProperties
              }
            />
          }
          onClick={onClickProfile}
          dropdownItems={dropdownItems}
          showTopRightDropDown={showTopRightDropDown}
        />
      </div>
    </header>
  );
};

export default Topbar;
