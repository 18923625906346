export default function renderErrorPage(rootEl) {
  const imageWrapperEl = document.createElement('section');
  imageWrapperEl.style.height = '17.8125rem';
  imageWrapperEl.style.width = '100%';
  imageWrapperEl.style.background =
    'url(/assets/images/errorcat.png) no-repeat center/contain';

  const headerEl = document.createElement('h2');
  headerEl.innerText = 'Oh Snap!';

  const messageEl = document.createElement('p');
  messageEl.innerText =
    ' Internet Explorer is not supported. Please download Chrome, Firefox or Edge.';

  const wrapperEl = document.createElement('section');
  wrapperEl.style.position = 'absolute';
  wrapperEl.style.top = '50%';
  wrapperEl.style.left = '50%';
  wrapperEl.style.transform = 'translate(-50%,-50%)';
  // IE9 and below - https://stackoverflow.com/a/4865276
  wrapperEl.style['-sand-transform'] = 'translate(-50%,-50%)';
  wrapperEl.style['-ms-transform'] = 'translate(-50%,-50%)';

  wrapperEl.appendChild(imageWrapperEl);
  wrapperEl.appendChild(headerEl);
  wrapperEl.appendChild(messageEl);

  const layoutEl = document.createElement('section');
  layoutEl.style.textAlign = 'center';
  layoutEl.style.position = 'fixed';
  layoutEl.style.top = '0';
  layoutEl.style.bottom = '0';
  layoutEl.style.right = '0';
  layoutEl.style.left = '0';
  layoutEl.style.fontFamily = "'Rubik', sans-serif";
  layoutEl.appendChild(wrapperEl);

  rootEl && rootEl.appendChild(layoutEl);
}
