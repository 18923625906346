import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

interface IAddToHomescreenProps {
  prompt?: IBeforeInstallPromptEvent | null;
  isEnable: boolean;
  promptToInstall: () => void;
}

const AddToHomescreenContext = createContext<IAddToHomescreenProps>({
  prompt: null,
  isEnable: false,
  promptToInstall: () => {}
});

const AddToHomescreenProvider: React.FC = ({ children }) => {
  const { isValidUser, isAuthenticatingUser } = useContext(AuthContext);

  const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);

  const isEnable =
    'serviceWorker' in navigator &&
    !!prompt &&
    (isValidUser || isAuthenticatingUser);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  const implementation = { prompt, isEnable, promptToInstall };

  return (
    <AddToHomescreenContext.Provider value={implementation}>
      {children}
    </AddToHomescreenContext.Provider>
  );
};

export { AddToHomescreenProvider, AddToHomescreenContext };
