import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { navigate } from '@reach/router';
import { Trans, useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { FormikContextType, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { parseISO, format, isSameDay, isAfter } from 'date-fns';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import WebviewHeading from 'components/WebviewHeading';
import { WebviewContext } from 'contexts/WebviewContext';
import Form from 'components/common/forms/Form';
import { ImageFile, ImageUpload } from 'components/common/forms/ImageUpload';
import SubmitButton from 'components/common/forms/SubmitButton';
import LimitedTextInput from 'components/common/forms/LimitedTextInput';
import Button from 'components/Button';
import { ModalContext } from 'contexts/ModalContext';
import BarSteps from 'components/common/BarSteps';
import FeedPostPreviewCard from 'components/FeedPostPreviewCard';
import ModalContainer from 'components/common/ModalContainer';

import modals, {
  MoreOutletsModal,
  SelectOutletsModal
} from 'components/FaveFeedModals';
import ItemPickerInput from 'components/common/forms/ItemPickerInput';
import DateTimePicker from 'components/common/forms/DateTimePicker';
import LoadingSpinner from 'components/LoadingSpinner';
import ScrollToTopButton from 'components/ScrollToTopButton';
import DetailLayout from 'layouts/DetailLayout';

import { OutletContext } from 'contexts/OutletContext';
import useViewportTrigger from 'hooks/useViewportTrigger';
import useOnceCallback from 'hooks/useOnceCallback';

import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON,
  CLEVERTAP_FEED_POST_TYPES,
  CLEVERTAP_SUBMISSION_TYPES
} from 'helpers/TypeConstants';

import styles from 'assets/css/FaveFeedFormView.module.scss';
import { IAvailableOutlet } from 'queries/getAvailableOutlets';
import getFaveFeedPostDetails from 'queries/getFaveFeedPostDetails';
import { createFeedPost } from 'commands/createFeedPost';
import { updateFeedPost } from 'commands/updateFeedPost';
import useCleverTapOld from 'hooks/useCleverTapOld';
import useNavigateAwayWarning from 'hooks/useNavigateAwayWarning';
import classNames from 'classnames';
import {
  FaveFeedFormViewProps,
  FeedPostFields,
  getFeedPostType,
  joinParams,
  loadOutlets,
  handleDateChanged,
  PostSubmittedScheduledModal,
  getOutletPlaceholderText,
  getScreenName,
  FeedPostTypes,
  getFeedPostApiTypeName,
  getFeedPostTypeFromApiName,
  getClevertapOfferType,
  getScreenDisplayedEvent
} from 'uiHelpers/feedForm';
import FeedFeatureListing from './FeedFeatureListing';
import { FeatureItem } from 'queries/getFeatureListing';
import UserTipText, { UserTipBlock } from 'components/UserTipText';
import { formatDate } from 'helpers/dateUtility';
import InlineLink from 'components/InlineLink';
import FormErrorText from 'components/common/forms/FormErrorText';
import useWebCallback from 'hooks/useWebCallback';

/*
Todo:
* fix revoke URL not releasing memory
*/

const FaveFeedFormView = ({
  post_id,
  '*': post_type
}: FaveFeedFormViewProps) => {
  const imageUrlField = 'url';

  const FAVE_POST_STEP_1 = 1;
  const FAVE_POST_STEP_2 = 2;
  const FAVE_POST_STEP_3 = 3;

  const maxDescriptionLength = 200;
  const maxImageCount = 5;
  const maxImageSize = 10 * 1024 * 1024;
  //const maxImageWidth = 800;
  //const maxImageHeight = 500;

  const isEditMode = post_id ? true : false;

  let initialPostType: FeedPostTypes | undefined; // postType is only used in new posts

  if (!isEditMode) {
    if (post_type) initialPostType = getFeedPostType(post_type);
    else initialPostType = FeedPostTypes.Announcement;
  }

  const { currentCompany } = useContext(OutletContext);
  const { init: modalInit, showConfirmModal } = useContext(ModalContext);
  const { isWebview } = useContext(WebviewContext);
  const selectedPreviewRef = useRef(0);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const cleverTap = useCleverTapOld();
  const { t } = useTranslation();
  const { matches: isWideView } = useViewportTrigger('(min-width: 990px)'); //fixed by design

  const formRef = useRef<FormikContextType<FeedPostFields>>();

  const [post, setPost] = useState<FeedPostFields>({
    id: 0,
    company_id: 0,
    date: new Date(),
    description: '',
    type: initialPostType || FeedPostTypes.Announcement,
    status: '',
    flagged: false,
    availableOutlets: [],
    selectedOutlets: '',
    images: [],
    paths: [],
    deleted_images_ids: []
  });

  const pageText = isEditMode
    ? {
        title: t('Edit Post'),
        subTitle: t('Make any necessary adjustments to your post.')
      }
    : {
        title: t('New Post'),
        subTitle: t('Don’t worry, we’ll guide you through step-by-step')
      };

  const createFeatureListingStep = (id: number) => {
    return {
      id,
      title: t('Select product'),
      rules: Yup.object().shape({}),
      stepFillsScreen: true,
      handleSubmit: ({ setSubmitting }: FormikHelpers<FeedPostFields>) => {
        setSubmitting(false);
      },
      Component: FeedFeatureListing
    };
  };

  const createPostDetailsStep = (id: number) => {
    return {
      id,
      title: t('Post details'),
      rules: Yup.object().shape({
        description: Yup.string()
          .max(maxDescriptionLength, 'Too Long!')
          .required('Required')
      }),
      stepFillsScreen: false,
      handleSubmit: (
        values: FeedPostFields,
        { setSubmitting, setFieldValue }: FormikHelpers<FeedPostFields>
      ) => {
        //load available outlets for new posts when the user
        //goes to step 2 for the first time
        if (!isEditMode && post.availableOutlets.length === 0)
          loadOutlets(
            values.company_id,
            values.date,
            'availableOutlets',
            setFieldValue
          );

        setStepPassthrough(id + 1, values.type);
        setSubmitting(false);
      },
      Component: () => {
        const { values } = useFormikContext<FeedPostFields>();

        const isECard = values.type === FeedPostTypes.eCards;
        const isAnnouncement = values.type === FeedPostTypes.Announcement;

        const uploadLabel = isAnnouncement
          ? 'Upload an image (optional)'
          : 'Deal images';
        const uploadTip = isAnnouncement
          ? '10MB each (max 5 images)'
          : 'The first 5 images from your deal will be selected.';

        const dealImages =
          values.type === FeedPostTypes.Deals
            ? (values: FeedPostFields) => values.featureItem?.images
            : undefined;

        return (
          <>
            <h5 className={styles.formLabel}>{t('Write a description*')}</h5>

            <LimitedTextInput
              name="description"
              className={styles.postDescription}
              placeholder={descriptionPlaceholder}
              maxLength={maxDescriptionLength}
            />

            {!isECard && (
              <>
                <h5 className={styles.formLabel}>{t(uploadLabel)}</h5>

                <span className={styles.formCaption}>{t(uploadTip)}</span>

                <ImageUpload
                  newFilesfieldName="images"
                  currentFilesFieldName="paths"
                  deletedFilesfieldName="deleted_images_ids"
                  imageUrlField={imageUrlField}
                  className={styles.postImages}
                  addImageButtonVisible={isAnnouncement}
                  deleteImageButtonVisible={isAnnouncement}
                  customImageList={dealImages}
                  onFilesSelected={handleFilesSelected}
                />
              </>
            )}
          </>
        );
      }
    };
  };

  const createSchedulePostStep = (id: number) => {
    return {
      id,
      title: t('Schedule post'),
      rules: Yup.object().shape({
        selectedOutlets: Yup.string().when('type', {
          is: FeedPostTypes.Announcement,
          then: Yup.string()
            .min(1)
            .required('Required')
        }),
        date: Yup.date()
          .required('Required')
          .test('single post per feature', '', function(this, value) {
            const context = this;
            const { type, featureItem } = context.parent as FeedPostFields;

            // the user chose to replace the active post, so they cannot use the same date
            if (
              !isEditMode &&
              type !== FeedPostTypes.Announcement &&
              featureItem &&
              featureItem.post_details &&
              isSameDay(
                value,
                parseISO(
                  (featureItem.post_details.start_at as unknown) as string
                )
              )
            ) {
              return context.createError({
                message:
                  'You have featured this post on this date. Please select another date.'
              });
            }

            return true;
          })
      }),
      stepFillsScreen: false,
      handleSubmit: (
        values: FeedPostFields,
        { setSubmitting }: FormikHelpers<FeedPostFields>
      ) => {
        const dateObj = values.date;
        const date = format(dateObj, "yyyy-MM-dd'T'HH:mm:ss");
        const now = new Date();
        const isLive = !isAfter(dateObj, now);
        const isFlagged = post_id !== undefined && values.flagged;
        const isInactive =
          post_id !== undefined && values.status === 'inactive';
        const isAnnouncement = values.type === FeedPostTypes.Announcement;

        const formData = new FormData();

        formData.append('company_id', (values.company_id as unknown) as string);
        formData.append('description', values.description);
        formData.append('start_at', date);

        if (isAnnouncement) {
          if (values.selectedOutlets.length > 0)
            formData.append('outlet_ids', values.selectedOutlets);

          if (values.images.length > 0)
            values.images.forEach((image: ImageFile) =>
              formData.append('images[]', image)
            );
        }

        if (isEditMode) {
          if (isAnnouncement && values.deleted_images_ids.length > 0)
            formData.append(
              'deleted_image_ids',
              values.deleted_images_ids.join(',')
            );

          updateFeedPost(post_id, formData)
            .then(() =>
              handleSubmitComplete(isFlagged, isLive, isInactive, values)
            )
            .catch(handleApiError)
            .finally(() => setSubmitting(false));
        } else {
          formData.append('type', getFeedPostApiTypeName(values.type));

          if (!isAnnouncement && values.featureItem)
            formData.append(
              'offer_id',
              (values.featureItem.id as unknown) as string
            );

          createFeedPost(formData)
            .then(() =>
              handleSubmitComplete(isFlagged, isLive, isInactive, values)
            )
            .catch(handleApiError)
            .finally(() => setSubmitting(false));
        }

        pushTappedEvent(
          id,
          isEditMode,
          values.type,
          CLEVERTAP_TAPPED_ON.BUTTON_SUBMIT
        );
      },
      Component: () => {
        const { values } = useFormikContext<FeedPostFields>();

        const isAnnouncement = values.type === FeedPostTypes.Announcement;

        const type = values.type === FeedPostTypes.Deals ? 'Deal' : 'eCard';
        const outlets =
          isAnnouncement || !values.featureItem
            ? []
            : values.featureItem.outlets;

        const expiryDateText =
          isAnnouncement || !values.featureItem
            ? null
            : formatDate(values.featureItem.end_date);

        const screenName = getScreenName(id, isEditMode, values.type);

        return (
          <>
            <h5 className={styles.formLabel}>{t('Schedule a date*')}</h5>

            <DateTimePicker
              name="date"
              screenName={screenName}
              className={styles.datePicker}
              disabled={(values: FeedPostFields) => !!values.isLive}
              onChanged={handleDateChanged}
              isModal={true}
              isDayDisabled={(date, values) => {
                if (
                  values.type !== FeedPostTypes.Announcement &&
                  values.featureItem !== undefined
                ) {
                  const expirydate = parseISO(
                    (values.featureItem.end_date as unknown) as string
                  );

                  return (
                    isSameDay(date, expirydate) || isAfter(date, expirydate)
                  );
                }

                return false;
              }}
            />

            {values.type !== FeedPostTypes.Announcement && (
              <FormErrorText name={'date'} />
            )}

            {isAnnouncement ? (
              <>
                <h5 className={classNames(styles.formLabel, styles.marginTop)}>
                  {t('Run post for these outlets*')}
                </h5>

                <ItemPickerInput
                  name={'selectedOutlets'}
                  onClick={createOutletSelectHandler(id)}
                  className={styles.outletPicker}
                  isDisabled={(values: FeedPostFields) =>
                    !!values.isLoading || !!values.isError
                  }
                  conditionalStyles={(values: FeedPostFields) =>
                    isEmpty(values.availableOutlets)
                      ? styles.noOutletsFound
                      : ''
                  }
                  getPlaceholderText={(value: string, values: FeedPostFields) =>
                    getOutletPlaceholderText(
                      t,
                      values.availableOutlets.length,
                      value ? value.split(',').length : 0,
                      values.isLoading
                    )
                  }
                  getErrorText={(values: FeedPostFields) => {
                    if (values.isError)
                      return t(
                        'There was an error loading the outlets. Please select the date again'
                      );
                    else if (
                      values.date &&
                      values.availableOutlets.length === 0
                    )
                      return t(
                        'There are no available outlets to schedule on this date. Please select another date.'
                      );
                    else return '';
                  }}
                />
              </>
            ) : (
              <UserTipBlock>
                {expiryDateText && (
                  <UserTipText icon={'clock-clockwise'}>
                    {t(`This ${type} will be expiring on {{date}}`, {
                      date: expiryDateText
                    })}
                  </UserTipText>
                )}

                <UserTipText icon={'outlet'}>
                  <Trans count={outlets.length}>
                    <span>
                      {'This post will be uploaded to '}
                      <InlineLink
                        children={`{{count}} outlets.`}
                        onClick={() => {
                          showModal(MoreOutletsModal, undefined, {
                            outlets,
                            className: styles.viewOutletsModalContainer,
                            hasCloseButton: true
                          });
                        }}
                      />
                    </span>
                  </Trans>
                </UserTipText>
              </UserTipBlock>
            )}
          </>
        );
      }
    };
  };

  const steps =
    isEditMode || post.type === FeedPostTypes.Announcement
      ? [
          createPostDetailsStep(FAVE_POST_STEP_1),
          createSchedulePostStep(FAVE_POST_STEP_2)
        ]
      : [
          createFeatureListingStep(FAVE_POST_STEP_1),
          createPostDetailsStep(FAVE_POST_STEP_2),
          createSchedulePostStep(FAVE_POST_STEP_3)
        ];

  const [currentStep, setCurrentStep] = useState(steps[0]);

  const pushTappedEvent = (
    step: number,
    isEdit: boolean,
    postType: FeedPostTypes,
    tapped_on: string,
    section_name?: string
  ) => {
    const event = {
      screen_name: getScreenName(step, isEdit, postType),
      tapped_on: tapped_on
    } as any; // by design

    if (section_name) event.section_name = section_name;

    if (postType !== FeedPostTypes.Announcement)
      event.offers_type = getClevertapOfferType(postType);

    cleverTap.tapped.push(event);
  };

  const pushScreenDisplayedEvent = useCallback(
    (step: number, isEdit: boolean, postType: FeedPostTypes) => {
      const event = getScreenDisplayedEvent(step, isEdit, postType);

      if (event.screen_name) cleverTap.screenDisplayed.push(event);
    },
    [cleverTap]
  );

  const pushModalScreenDisplayedEvent = (
    screen_name: string,
    postType: FeedPostTypes,
    submission_type: string
  ) => {
    const eventPostType =
      postType === FeedPostTypes.Announcement
        ? CLEVERTAP_FEED_POST_TYPES.ANNOUNCEMENT
        : CLEVERTAP_FEED_POST_TYPES.FEATURED;

    const event = {
      screen_name,
      post_type: eventPostType,
      submission_type
    } as any; //clever tap uses "any" object. We don't have types yet

    if (postType !== FeedPostTypes.Announcement)
      event.offers_type = FeedPostTypes[postType].toLowerCase();

    cleverTap.screenDisplayed.push(event);
  };

  const pushErrorScreenDisplayedEvent = (error_type: string) => {
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_ERROR,
      error_type
    });
  };

  const setStepPassthrough = useCallback(
    (step: number, postType: FeedPostTypes) => {
      setCurrentStep(steps[step - 1]);
      pushScreenDisplayedEvent(step, isEditMode, postType);
    },
    [steps, isEditMode, pushScreenDisplayedEvent]
  );

  useOnceCallback(
    () => pushScreenDisplayedEvent(FAVE_POST_STEP_1, isEditMode, post.type),
    (isEditMode && post.id > 0) || !isEditMode
  );

  useEffect(() => {
    if (currentCompany) {
      if (!post.company_id) {
        const newPostState = cloneDeep(post);
        newPostState.company_id = currentCompany.id;
        setPost(newPostState);
      }

      if (isEditMode && post_id && !post.id) {
        setIsFormLoading(true);

        getFaveFeedPostDetails({ id: post_id })
          .then(data => {
            const type =
              getFeedPostTypeFromApiName(data.type) ||
              FeedPostTypes.Announcement;

            const featuredItem =
              type === FeedPostTypes.Deals
                ? data.deal
                : type === FeedPostTypes.eCards
                ? data.e_card
                : undefined;

            setPost({
              id: data.id,
              company_id: currentCompany.id,
              description: data.description,
              date: parseISO(data.scheduled_date),
              type: type,
              status: data.status,
              flagged: data.flagged,
              isLive: data.is_live,
              availableOutlets: data.outlets,
              selectedOutlets: joinParams(
                data.outlets.filter(
                  (outlet: IAvailableOutlet) => outlet.checked
                )
              ),
              featureItem: featuredItem,
              images: [],
              paths: data.images,
              deleted_images_ids: []
            });
          })
          .finally(() => setIsFormLoading(false));
      }
    }
  }, [isEditMode, currentCompany, post, post_id]);

  useNavigateAwayWarning(
    () => formRef.current != null && formRef.current.dirty
  );

  const handleSubmitComplete = (
    isFlagged: boolean,
    isLive: boolean,
    isInactive: boolean,
    values: FeedPostFields
  ) => {
    const screenName = isEditMode
      ? CLEVERTAP_SCREEN_NAME.FEED_EDIT_POST_SUCCESSFUL
      : CLEVERTAP_SCREEN_NAME.FEED_NEW_POST_SUCCESSFUL;

    if (isFlagged) {
      showModal(modals.PostSubmittedReviewModal, () => {
        navigate('/feed?activeTab=inactive');
      });

      pushModalScreenDisplayedEvent(
        CLEVERTAP_SCREEN_NAME.FEED_EDIT_SUBMIT_REVIEW,
        values.type,
        isInactive
          ? CLEVERTAP_SUBMISSION_TYPES.INACTIVE
          : isLive
          ? CLEVERTAP_SUBMISSION_TYPES.LIVE
          : CLEVERTAP_SUBMISSION_TYPES.SCHEDULED
      );
    } else if (isLive) {
      showModal(modals.PostSubmittedLiveModal, () => {
        navigate('/feed?activeTab=active');
      });

      pushModalScreenDisplayedEvent(
        screenName,
        values.type,
        CLEVERTAP_SUBMISSION_TYPES.LIVE
      );
    } else {
      showModal(PostSubmittedScheduledModal, undefined, {
        scheduledPostDate: values.date
      });

      pushModalScreenDisplayedEvent(
        screenName,
        values.type,
        CLEVERTAP_SUBMISSION_TYPES.SCHEDULED
      );
    }
  };

  const handleApiError = () =>
    showModalMessage(
      'Uh oh...',
      'Something went wrong. Please check your internet connection and try again.'
    );

  const PreviewCard = ({ hintOnTop = false, centered = false }) => (
    <>
      {currentCompany && (
        <FeedPostPreviewCard
          logoUrl={currentCompany.logo_url}
          title={currentCompany.partner_name}
          subtitle={t('Just now')}
          placeholder={t('This is where your description text will be.')}
          selectedPreviewState={selectedPreviewRef}
          hintOnTop={hintOnTop}
          centered={centered}
        />
      )}
    </>
  );

  const showModalMessage = (title: string, content: string) => {
    showModal((
      { onClose }: any // using any as defined by the modal
    ) => (
      <ModalContainer>
        <p className={styles.modalTitle}>{t(title)}</p>
        <p className={styles.modalContent}>{t(content)}</p>
        <Button onClick={onClose} type="primary" justify="center" size={'lg'}>
          {t('Okay')}
        </Button>
      </ModalContainer>
    ));
  };

  useWebCallback(() => {
    window.showFeedModal = (title, content) => showModalMessage(title, content);

    return () => (window.showFeedModal = undefined);
  });

  // using any as defined by the modal
  const showModal = (Component: any, onClose?: () => void, props?: any) => {
    modalInit({
      Component: Component,
      openOnReady: true,
      componentProps: props || {},
      onClose: () => onClose && onClose(),
      hasCloseButton: (props && props.hasCloseButton) || false
    });
  };

  const handleFilesSelected = (files: ImageFile[], currentCount: number) => {
    const excludedFiles = [];

    if (currentCount > maxImageCount) {
      showModalMessage(
        `Upload limit`,
        `You have reached the max limit of ${maxImageCount} images.`
      );
      pushErrorScreenDisplayedEvent('image_limit');
      return [];
    }

    const filtered = files.filter(file => {
      //const { width, height } = file.dimensions;

      // only validate size for now (per Joyce and Adrian)
      const valid = file.size <= maxImageSize; //&&
      //width <= maxImageWidth &&
      //height <= maxImageHeight;

      if (!valid && file.preview) {
        URL.revokeObjectURL(file.preview);
        excludedFiles.push(file);
      }

      return valid;
    });

    if (excludedFiles.length > 0) {
      showModalMessage(
        `File size limit`,
        `The file size must be 10MB or less.`
      );

      pushErrorScreenDisplayedEvent('file_size');
    }

    return filtered;
  };

  const createOutletSelectHandler = (step: number) => (
    values: FeedPostFields,
    setFieldValue: (
      field: string,
      value: IAvailableOutlet[],
      shouldValidate?: boolean
    ) => void,
    setPickerValue: (value: string) => void
  ) => {
    if (values.availableOutlets.length > 0)
      modalInit({
        // any by definition in modal context
        Component: ({ onClose }: any) => {
          return (
            <SelectOutletsModal
              items={values.availableOutlets}
              onSearchTapped={() =>
                pushTappedEvent(
                  step,
                  isEditMode,
                  values.type,
                  CLEVERTAP_TAPPED_ON.BUTTON_SEARCH_OUTLET,
                  CLEVERTAP_SECTION_NAME.SELECT_OUTLET
                )
              }
              onConfrimTapped={() =>
                pushTappedEvent(
                  step,
                  isEditMode,
                  values.type,
                  CLEVERTAP_TAPPED_ON.BUTTON_DONE,
                  CLEVERTAP_SECTION_NAME.SELECT_OUTLET
                )
              }
              onClose={(outlets: IAvailableOutlet[]) => {
                setFieldValue('availableOutlets', outlets);

                setPickerValue(
                  joinParams(outlets.filter(outlets => outlets.checked))
                );
                onClose();
              }}
            />
          );
        },
        openOnReady: true,
        onClose: () => {}
      });
  };

  const handleBackClick = () => {
    if (currentStep.id === FAVE_POST_STEP_1) navigate(-1);
    else setStepPassthrough(currentStep.id - 1, post.type);
  };

  const handleFeatureClick = (item: FeatureItem) => {
    const screenName = CLEVERTAP_SCREEN_NAME.FEED_NEW_FEATURED_STEP1;

    const continueCreationFlow = () => {
      setPost({ ...post, type: item.type, featureItem: item });
      setStepPassthrough(currentStep.id + 1, item.type);
    };

    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on:
        item.type === FeedPostTypes.Deals
          ? CLEVERTAP_TAPPED_ON.BUTTON_SELECT_DEAL
          : CLEVERTAP_TAPPED_ON.BUTTON_SELECT_ECARD
    });

    if (item.post_details) {
      const isActive = item.post_details.status === 'active';

      const offerType = getClevertapOfferType(item.type);

      const sectionName = isActive
        ? CLEVERTAP_SECTION_NAME.FEED_REPOST_LIVE
        : CLEVERTAP_SECTION_NAME.FEED_REPOST_SCHEDULED;

      const prompt = isActive
        ? 'You have an active post featuring this offer. If you continue, your previous post will be replaced.'
        : 'You have scheduled a post for this offer. Do you want to view the post?';

      const yesButtonText = isActive ? 'Yes, replace' : 'View post';
      const noButtonText = isActive ? 'No, keep' : 'Edit post';

      const pushConfirmTappedEvent = (tappedOn: string) =>
        cleverTap.tapped.push({
          screen_name: screenName,
          section_name: sectionName,
          tapped_on: tappedOn,
          offers_type: offerType
        });

      showConfirmModal(
        prompt,
        () => {
          if (isActive) {
            pushConfirmTappedEvent(CLEVERTAP_TAPPED_ON.BUTTON_REPLACE);

            continueCreationFlow();
          } else {
            pushConfirmTappedEvent(CLEVERTAP_TAPPED_ON.BUTTON_FEED_DETAIL);

            navigate(`/feed/post-details/${item.post_details.id}`);
          }
        },
        () => {
          if (isActive) {
            //do nothing
          } else {
            //edit scheduled
            pushConfirmTappedEvent(CLEVERTAP_TAPPED_ON.BUTTON_EDIT);

            navigate(`/feed/edit/${item.post_details.id}`);
          }
        },
        yesButtonText,
        noButtonText
      );

      cleverTap.screenDisplayed.push({
        screen_name: screenName,
        section_name: sectionName,
        offers_type: offerType
      });
    } else continueCreationFlow();
  };

  const currentStepIndex = steps.findIndex(step => step.id === currentStep.id);

  const isLastStep = currentStep.id === steps.length;

  const descriptionPlaceholder = t(
    'Get your Smooth Like Butter Cookies for RM8.90 Only!'
  );

  return (
    <AppLayout>
      <DetailLayout>
        {isWebview ? (
          <WebviewHeading
            title={pageText.title}
            onBack={handleBackClick}
            additionalContent={
              !isEditMode && (
                <BarSteps
                  isLite={isWebview}
                  steps={steps}
                  activeStepIndex={currentStepIndex}
                />
              )
            }
          />
        ) : (
          <Heading title={pageText.title} subtitle={pageText.subTitle} />
        )}

        {!isEditMode &&
          post.type !== FeedPostTypes.Announcement &&
          currentStep.id === FAVE_POST_STEP_1 && <ScrollToTopButton />}

        {!isWebview && (
          <Button
            leftIcon="arrow-left"
            type="text"
            className="backToDealRoute"
            iconColor="light-black"
            children={t('Back')}
            textColor="charcoal-gray"
            onClick={handleBackClick}
            size={'lg'}
          />
        )}

        {!isEditMode && !isWebview && (
          <BarSteps
            isLite={isWebview}
            steps={steps}
            activeStepIndex={currentStepIndex}
          />
        )}

        {isFormLoading ? (
          <LoadingSpinner />
        ) : (
          <section className={styles.postForm}>
            <Form
              key={`${post.id}, ${post.type}`} // force remount when an existing post is loaded
              initialValues={post}
              onSubmit={currentStep.handleSubmit}
              validationSchema={currentStep.rules}
              validateOnMount
              validateOnChange
              validateOnSchemaChange
              formRef={formRef}
            >
              {!isWebview && isWideView && !currentStep.stepFillsScreen && (
                <div className={styles.postPreview}>
                  <PreviewCard />
                </div>
              )}

              <div className={styles.postDetails}>
                <currentStep.Component
                  company_id={currentCompany?.id}
                  initialPostType={post.type}
                  onFeatureClick={handleFeatureClick}
                />

                {(isWebview || !isWideView) && !currentStep.stepFillsScreen && (
                  <PreviewCard centered hintOnTop />
                )}

                {!currentStep.stepFillsScreen && (
                  <SubmitButton
                    isDisabled={(values: FeedPostFields, context) =>
                      (isLastStep && !context.dirty) ||
                      !!values.isLoading ||
                      !!values.isError
                    }
                    disableIfNotValid
                    isWebview={isWebview}
                  >
                    {(_, isSubmitting) => {
                      if (isLastStep) {
                        if (isSubmitting) return t('Submitting');
                        else return t('Submit');
                      } else return t('Continue');
                    }}
                  </SubmitButton>
                )}
              </div>
            </Form>
          </section>
        )}
      </DetailLayout>
    </AppLayout>
  );
};

export default FaveFeedFormView;
