import React, { ComponentType } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from 'assets/css/FeedPostPreviewCard.module.scss';
import Carousel from './common/Carousel';
import { ImageFile } from './common/forms/ImageUpload';
import { UrlImage } from 'types/UrlImage';
import { FeedPostFields, FeedPostTypes } from 'uiHelpers/feedForm';
import Icon from './Icon';
import FeedECardPreview from './FeedECardPreview';
import { getBackgroundImageFromUrl } from 'utils/utilFunctions';

export type FeedPostPreviewCardProps = {
  centered?: boolean;
  logoUrl: string;
  title: string;
  subtitle: string;
  placeholder: string;
  imageText?: string;
  selectedPreviewState: React.MutableRefObject<number>;
  hintOnTop?: boolean;
};

export type AspectRatio = {
  height: number;
  width: number;
};

export const PostSummaryBanner = ({ text }: { text: string }) => (
  <>
    {text && (
      <div className={styles.postSummary}>
        <span className={styles.text}>{text}</span>
        <Icon className={styles.chvron} icon="angle-right" color="true-white" />
      </div>
    )}
  </>
);

// Keeping this as there is a high chance we go back to it

// const landscapeCardFixedApsect: AspectRatio = {
//   height: 212,
//   width: 303
// };

const cardFixedApsect: AspectRatio = {
  height: 1,
  width: 1
};

export const ImageCarousel = ({
  images,
  EmptyImagesPreview,
  selectedPreviewState,
  decription
}: {
  EmptyImagesPreview?: ComponentType;
  images: (string | undefined)[];
  selectedPreviewState: React.MutableRefObject<number>;
  decription?: string;
}) => {
  return (
    <Carousel
      aspectHeight={cardFixedApsect.height}
      aspectWidth={cardFixedApsect.width}
      selectedPreviewState={selectedPreviewState}
      BottomChildren={
        decription ? () => <PostSummaryBanner text={decription} /> : undefined
      }
    >
      {EmptyImagesPreview && (!images || images.length === 0) ? (
        <EmptyImagesPreview />
      ) : (
        images.map(image => (
          <img
            key={image}
            src={image}
            className={styles.carouselImage}
            alt="Feed post"
            draggable={false}
            onDragStart={e => e.preventDefault()}
          />
        ))
      )}
    </Carousel>
  );
};

const FeedPostPreviewCard = ({
  centered = false,
  logoUrl,
  title,
  subtitle,
  placeholder,
  selectedPreviewState,
  hintOnTop = false
}: FeedPostPreviewCardProps) => {
  const { values } = useFormikContext<FeedPostFields>();
  const { t } = useTranslation();

  const isEcard = values.type === FeedPostTypes.eCards;

  const decription = values.description;

  const dealName =
    values.type === FeedPostTypes.Deals && values.featureItem
      ? values.featureItem.name
      : '';

  const images =
    values.type === FeedPostTypes.Deals
      ? values.featureItem?.images || []
      : [
          ...values.paths.map((path: UrlImage) => path.url),
          ...values.images.map((file: ImageFile) => file.preview)
        ];

  return (
    <div
      className={classNames(
        styles.cardWrapper,
        hintOnTop ? styles.reversed : '',
        centered ? styles.centered : ''
      )}
    >
      <div className={styles.postCard}>
        <div className={styles.header}>
          <div
            style={{ background: getBackgroundImageFromUrl(logoUrl) }}
            className={styles.logo}
          />
          <div className={styles.headerContent}>
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
          </div>
        </div>
        {decription ? (
          <span className={styles.description}>{decription}</span>
        ) : (
          <span className={styles.placeholder}>{placeholder}</span>
        )}

        {(isEcard || images.length > 0) && (
          <div className={styles.image}>
            {isEcard ? (
              <FeedECardPreview item={values.featureItem} />
            ) : (
              <ImageCarousel
                images={images}
                decription={dealName}
                selectedPreviewState={selectedPreviewState}
              />
            )}
          </div>
        )}
      </div>
      <span className={styles.hintText}>
        {t('This is how the post looks like on the Fave app.')}
      </span>
    </div>
  );
};

export { cardFixedApsect };
export default FeedPostPreviewCard;
