import Heading from 'components/Heading';
import WebviewHeading from 'components/WebviewHeading';
import { WebviewContext } from 'contexts/WebviewContext';
import useModal from 'hooks/useModal';
import AppLayout from 'layouts/AppLayout';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { call, NATIVE } from 'utils/nativeInterface';
import style from 'assets/css/FaveFeedView.module.scss';
import modalStyle from 'assets/css/ModalContainer.module.scss';
import NoPostsIcon from 'assets/images/FaveFeed/no-posts-found.png';
import Button from 'components/Button';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import ModalContainer from 'components/common/ModalContainer';
import { OutletContext } from 'contexts/OutletContext';
import getFaveFeedList, {
  FaveFeed,
  FaveFeedResponse
} from 'queries/getFaveFeedList';
import LoadingSpinner from 'components/LoadingSpinner';
import { capitalize, isEmpty, isNull } from 'lodash';
import InfoIcon from 'components/InfoIcon';
import FaveFeedEducationModal from 'components/FaveFeedEducationModal';
import FaveFeedToggleFilter from 'components/FaveFeedToggleFilter';
import Grid from 'components/Grid';
import BannerMessage from 'components/BannerMessage';
import useFetchDataOnScroll from 'hooks/useFetchDataOnScroll';
import LoadingSpinnerSmall from 'components/LoadingSpinnerSmall';
import PostTemplateIcon from 'assets/images/FaveFeed/post-template-icon.svg';
import { ReactComponent as FeatureDisabledSVG } from 'assets/images/FaveFeed/fave-feed-feature-locked.svg';
import DetailLayout from 'layouts/DetailLayout';
import useViewportTrigger from 'hooks/useViewportTrigger';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { formatDate, formatDateAndTime } from 'helpers/dateUtility';
import useCleverTapOld from 'hooks/useCleverTapOld';
import {
  CLEVERTAP_ADDITIONAL,
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import { OutletType } from 'types/SettingsAPIResponseType';
import FeedECardPreview from 'components/FeedECardPreview';
import { FeatureItem } from 'queries/getFeatureListing';
import routesMap from 'helpers/routesMap';
import useChat from 'hooks/useChat';

export interface FaveFeedViewProps {}

type OutletStatusType = 'active' | 'inactive' | 'scheduled';

export const EmptyImageTemplateText = () => (
  <>
    <span>Add an image to attract</span>
    <span>customer’s attention</span>
  </>
);

export const DeletedOffersTemplateText = ({
  isDealPost
}: {
  isDealPost: boolean;
}) => (
  <>
    <span>{`This ${isDealPost ? 'deal' : 'eCard'} has been deleted from`}</span>
    <span>your outlet.</span>{' '}
  </>
);

const FaveFeedView: React.FC<FaveFeedViewProps> = () => {
  const { isWebview } = useContext(WebviewContext);
  const { activeTab } = useContext(QueryStringContext);
  const { chatText, openWidget } = useChat();
  const { t } = useTranslation();
  const outletContext = useContext(OutletContext);
  const { currentCompany, outlets } = outletContext;
  const cleverTap = useCleverTapOld();
  const [hasSentCT, setHasSentCT] = useState(false);
  const [faveFeedData, setFaveFeedData] = useState<FaveFeed[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFaveFeedData, setIsLoadingFaveFeedData] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [showFeatureLockedModal, setShowFeatureLockedModal] = useState(false);
  const [outletType, setOutletType] = useState<OutletStatusType>(
    activeTab ? activeTab : 'active'
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isNotLastPage, setIsNotLastPage] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [feedOutlets, setFeedOutlets] = useState<FaveFeed[]>([]);
  const showToggleButtons = !isEmpty(faveFeedData);
  const { matches: isWideView } = useViewportTrigger('(min-width: 540px)');

  const title = 'Feed';

  const isFeedEnabled =
    currentCompany &&
    (currentCompany.announcement_feed_enabled ||
      currentCompany.feature_feed_enabled);
  const isFavePayActive =
    outlets && outlets.some((outlet: OutletType) => outlet.has_fave_payment);
  const hasDeals = currentCompany && currentCompany.has_deals;
  const hasECards = currentCompany && currentCompany.has_e_cards;

  const isCreateFeedPostAllowed =
    (isFavePayActive || hasDeals || hasECards) &&
    currentCompany &&
    currentCompany.enabled;

  useEffect(() => {
    if (currentCompany) {
      const getActiveFeed = getFaveFeedList({
        company_id: currentCompany.id,
        status: 'active'
      });
      const getScheduledFeed = getFaveFeedList({
        company_id: currentCompany.id,
        status: 'scheduled'
      });
      const getInactiveFeed = getFaveFeedList({
        company_id: currentCompany.id,
        status: 'inactive'
      });

      Promise.all([getActiveFeed, getScheduledFeed, getInactiveFeed])
        .then(response =>
          response.forEach((data: FaveFeedResponse) =>
            setFaveFeedData(stateData => stateData.concat(data.feed))
          )
        )
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setIsLoadingFaveFeedData(false);
        });
    }
  }, [currentCompany]);

  useEffect(() => {
    setIsLoadingMore(true);
    setFeedOutlets([]);
    setCurrentPage(1);
    setShouldFetch(true);
    setIsNotLastPage(true);
    if (currentCompany) {
      getFaveFeedList({
        company_id: currentCompany.id,
        status: outletType
      })
        .then(data => {
          if (!isEmpty(data.feed)) {
            setFeedOutlets(data.feed);
            setCurrentPage(page => page + 1);
            setShouldFetch(true);
          } else {
            setIsNotLastPage(false);
            setShouldFetch(false);
          }
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsLoadingMore(false);
        });
    }
  }, [currentCompany, outletType]);

  useEffect(() => {
    if (!hasSentCT && !isLoadingFaveFeedData) {
      cleverTap.screenDisplayed.push({
        screen_name: isEmpty(faveFeedData)
          ? CLEVERTAP_SCREEN_NAME.FEED
          : CLEVERTAP_SCREEN_NAME.FEED_LIST
      });
      setHasSentCT(true);
    }
  }, [
    faveFeedData,
    hasSentCT,
    cleverTap.screenDisplayed,
    isLoadingFaveFeedData
  ]);

  const changeOutletType = (type: OutletStatusType) => {
    if (type !== outletType) {
      setIsLoading(true);
      setOutletType(type);
    }
  };

  const toggleButtonProps = [
    {
      text: 'Active',
      onClick: () => {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_LIST,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ACTIVE
        });
        changeOutletType('active');
      }
    },
    {
      text: 'Scheduled',
      onClick: () => {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_LIST,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_SCHEDULED
        });
        changeOutletType('scheduled');
      }
    },
    {
      text: 'Inactive',
      onClick: () => {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_LIST,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_INACTIVE
        });
        changeOutletType('inactive');
      }
    }
  ];

  const FeatureLockedModal = ({ onClose }: { onClose: () => void }) => {
    const onClick = () => {
      if (isFeedEnabled && !isCreateFeedPostAllowed) {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_VIEW_SERVICES,
          locked_type: CLEVERTAP_ADDITIONAL.NO_SERVICES
        });

        if (isWebview) call(NATIVE.NAVIGATE_SERVICES);
        else navigate(routesMap.moreServices);
      } else if (!isFeedEnabled) {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CHAT_US,
          locked_type: CLEVERTAP_ADDITIONAL.ADMIN_LOCKED_BOTH
        });
        openWidget();
      }
      onClose();
    };

    const chatPhrase = capitalize(chatText);

    return (
      <ModalContainer>
        {!isFeedEnabled && <FeatureDisabledSVG />}
        <p className={modalStyle.title}>{t('This feature is locked')}</p>
        <p className={modalStyle.description}>
          {!isFeedEnabled
            ? t(`${chatPhrase} to learn how to unlock this feature again.`)
            : t('Activate a service to unlock this Feed feature.')}
        </p>
        <div>
          <Button type={'primary'} size={'lg'} onClick={onClick}>
            {!isFeedEnabled ? t(chatPhrase) : t('View Services')}
          </Button>
        </div>
      </ModalContainer>
    );
  };

  useModal({
    Component: FaveFeedEducationModal,
    openOnReady: showEducationModal,
    onClose: () => setShowEducationModal(false),
    openCallback: () =>
      cleverTap.screenDisplayed.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_EDUCATION,
        outletContext
      }),
    componentProps: { outletContext }
  });

  useModal({
    Component: FeatureLockedModal,
    openOnReady: showFeatureLockedModal,
    onClose: () => setShowFeatureLockedModal(false),
    openCallback: () => {
      if (isFeedEnabled && !isCreateFeedPostAllowed) {
        cleverTap.screenDisplayed.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          locked_type: CLEVERTAP_ADDITIONAL.NO_SERVICES
        });
      } else if (!isFeedEnabled) {
        cleverTap.screenDisplayed.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_NEW_LOCKED,
          locked_type: CLEVERTAP_ADDITIONAL.ADMIN_LOCKED_BOTH
        });
      }
    },
    componentProps: { currentCompany }
  });

  const onClickNewFeedPost = () => {
    cleverTap.tapped.push({
      screen_name: isEmpty(faveFeedData)
        ? CLEVERTAP_SCREEN_NAME.FEED
        : CLEVERTAP_SCREEN_NAME.FEED_LIST,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ADD_FEED
    });
    isFeedEnabled && isCreateFeedPostAllowed
      ? navigate('/feed/new-post')
      : setShowFeatureLockedModal(true);
  };

  const NoPostsFound = () => {
    return (
      <div className={style.noPostsFound}>
        <div className={style.icon}>
          <img src={NoPostsIcon} alt="no posts found" />
        </div>
        <div className={style.statement}>
          <p>
            <strong>{t('No post')}</strong>
          </p>
          {t(`Create a feed post to drive more traffic to your store now!`)}
        </div>
        <div
          className={classNames(
            style.buttonsContainer,
            isWebview && style.webview
          )}
        >
          <Button
            onClick={handleUserEducationClick}
            type={'inversePrimary'}
            isWebview={isWebview}
            size={'lg'}
          >
            {t('Learn more')}
          </Button>
          <Button
            type={'primary'}
            leftIcon={'cross'}
            onClick={onClickNewFeedPost}
            isWebview={isWebview}
            size={'lg'}
          >
            {t('New feed post')}
          </Button>
        </div>
      </div>
    );
  };

  const PostCard = ({ data }: { data: FaveFeed }) => {
    const viewPostDetails = () => {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_LIST,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_FEED_DETAIL
      });
      navigate(`/feed/post-details/${data.id}`);
    };

    const isAnnouncementPost = data.type === 'announcement';
    const isDealPost = data.type === 'deal';
    const isECardPost = data.type === 'e_card';

    const isActive = data.status === 'active';
    const isScheduled = data.status === 'scheduled';
    const isInactive = data.status === 'inactive';
    const isDeleted = !isNull(data.deleted_on);
    const isOfferDeleted =
      data && ((isDealPost && !data.deal) || (isECardPost && !data.e_card));
    const isUnderReview = data.status === 'review';
    const isFlagged = data.flagged;
    const isExpired = data.expired;

    let backgroundImageURL: string | undefined = undefined;

    if (isAnnouncementPost && data.images[0])
      backgroundImageURL = data.images[0].url;
    else if (isECardPost && data.e_card)
      backgroundImageURL = data.e_card.company_logo;
    else if (isDealPost && data.deal) backgroundImageURL = data.deal.images[0];

    const hasNoBackgroundImage = !backgroundImageURL;

    const PostEmptyBackgroundImage = ({
      isFeaturedOfferDeleted = false
    }: {
      isFeaturedOfferDeleted?: boolean;
    }) => {
      return (
        <div className={style.noImage}>
          <>
            <img src={PostTemplateIcon} alt="post template" />
            <Trans>
              {isFeaturedOfferDeleted ? (
                <DeletedOffersTemplateText isDealPost={isDealPost} />
              ) : (
                <EmptyImageTemplateText />
              )}
            </Trans>
          </>
        </div>
      );
    };

    const featureItem = data.e_card
      ? ((data.e_card as unknown) as FeatureItem)
      : undefined;

    const eCardBackground = `radial-gradient(${featureItem?.background_color} -400%, white 110%)`;
    const eCardBoxshadow = `0px 40px 40px -35px ${featureItem?.background_color}`;

    return (
      <div className={style.post} onClick={viewPostDetails}>
        <div className={style.postID}>
          <span>ID: {data.id}</span>
          <span>{isAnnouncementPost ? 'Announcement' : 'Featured'}</span>
        </div>
        {isOfferDeleted ? (
          <PostEmptyBackgroundImage isFeaturedOfferDeleted={true} />
        ) : hasNoBackgroundImage ? (
          isECardPost ? (
            // ecard bg image is company_logo, to counter company_logo = null
            <div
              className={style.postPicture}
              style={{
                background: eCardBackground
              }}
            >
              <div
                className={style.postEcard}
                style={{
                  boxShadow: eCardBoxshadow
                }}
              >
                <FeedECardPreview item={featureItem} />
              </div>
            </div>
          ) : (
            <PostEmptyBackgroundImage />
          )
        ) : isECardPost ? (
          <div
            className={style.postPicture}
            style={{
              background: eCardBackground
            }}
          >
            <div
              className={style.postEcard}
              style={{
                boxShadow: eCardBoxshadow
              }}
            >
              <FeedECardPreview item={featureItem} />
            </div>
          </div>
        ) : (
          <div
            className={style.postPicture}
            style={{
              backgroundImage: `url(${backgroundImageURL})`
            }}
          />
        )}
        <div className={style.postContent}>
          {/* can be Live, Edited, Created, Flagged, Deleted */}
          <div className={style.postCreated}>
            <span>
              {isActive &&
                t(`Live on {{datetime}}`, {
                  datetime: formatDate(data.scheduled_date)
                })}
              {isScheduled &&
                t(`Created on {{datetime}}`, {
                  datetime: formatDate(data.created_on)
                })}
              {isDeleted &&
                !isExpired &&
                t(`Deleted on {{datetime}}`, {
                  datetime: formatDate(data.deleted_on)
                })}
              {isUnderReview &&
                t(`Edited on {{datetime}}`, {
                  datetime: formatDate(data.updated_on)
                })}
              {isFlagged &&
                !isUnderReview &&
                t(`Flagged on {{datetime}}`, {
                  datetime: formatDate(data.flagged_on)
                })}
              {isExpired &&
                t(`Expired on {{datetime}}`, {
                  datetime: formatDate(data.deleted_on)
                })}
            </span>
          </div>

          <p className={style.postDetails}>{data.description}</p>
          <hr />
          {((isAnnouncementPost &&
            (isActive || (isInactive && !isUnderReview && !isFlagged))) ||
            (!isAnnouncementPost &&
              !isExpired &&
              !isUnderReview &&
              !isFlagged)) && (
            <div className={style.postActive}>
              <div>
                <div className={style.count}>{data.viewed_count}</div>
                <div className={style.title}>{t('Interactions')}</div>
              </div>
              <div>
                <div className={style.count}>{data.faved_count}</div>
                <div className={style.title}>{t('Faved Business')}</div>
              </div>
              <Button
                type="text"
                className={style.viewPostDetails}
                rightIcon="angle-right"
                size="xs"
              />
            </div>
          )}
          {isScheduled && (
            <div className={style.postScheduled}>
              <p>
                <Trans>
                  {'Post scheduled to go live on '}
                  <span className={style.bold}>
                    {{
                      datetime: formatDateAndTime(data.scheduled_date)
                    }}
                    .
                  </span>
                </Trans>
              </p>
              <Button
                type="text"
                className={style.viewPostDetails}
                rightIcon="angle-right"
                size="xs"
              />
            </div>
          )}
          {isUnderReview && (
            <div className={style.postInactive}>
              <BannerMessage
                type={'warning'}
                message={t('Your post is being reviewed. Contact us.')}
                marginBottom={false}
              >
                <Button
                  type="text"
                  iconColor={'persian-red'}
                  className={style.viewPostDetails}
                  rightIcon="angle-right"
                  size="xs"
                />
              </BannerMessage>
            </div>
          )}
          {!isAnnouncementPost && isExpired && (
            <div className={style.postInactive}>
              <BannerMessage
                type={'warning'}
                message={t('Your offer has expired.')}
                marginBottom={false}
              >
                <Button
                  type="text"
                  iconColor={'persian-red'}
                  className={style.viewPostDetails}
                  rightIcon="angle-right"
                  size="xs"
                />
              </BannerMessage>
            </div>
          )}
          {isInactive && isFlagged && (
            <div className={style.postInactive}>
              <BannerMessage
                type={'warning'}
                message={t(`Your post has been flagged. Contact us.`)}
                marginBottom={false}
              >
                <Button
                  type="text"
                  iconColor={'persian-red'}
                  className={style.viewPostDetails}
                  rightIcon="angle-right"
                  size="xs"
                />
              </BannerMessage>
            </div>
          )}
        </div>
      </div>
    );
  };

  const loadMoreOutlets = () => {
    setIsLoadingMore(true);
    getFaveFeedList({
      company_id: currentCompany.id,
      status: outletType,
      page: currentPage
    })
      .then(data => {
        if (!isEmpty(data.feed)) {
          setFeedOutlets(feedOutlets.concat(data.feed));
          setCurrentPage(page => page + 1);
        } else {
          setIsNotLastPage(false);
          setShouldFetch(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setIsNotLastPage(false);
        setShouldFetch(false);
      })
      .finally(() => {
        setIsLoadingMore(false);
      });
  };

  const fetchStatementsRef = useFetchDataOnScroll(
    isLoadingMore,
    isNotLastPage,
    loadMoreOutlets,
    shouldFetch
  );

  const NoPostsBasedOnTab = () => {
    return (
      <div className={style.noPostsFound}>
        <div className={style.icon}>
          <img src={NoPostsIcon} alt="no posts found" />
        </div>
        <div className={style.statement}>
          <p>
            <strong>{t('No post')}</strong>
          </p>
          {t(`Start interacting with your customers by creating a post.`)}
        </div>
      </div>
    );
  };

  const Feed = () => {
    if (isLoading || isLoadingFaveFeedData) {
      return <LoadingSpinner />;
    } else if (isEmpty(faveFeedData)) {
      return <NoPostsFound />;
    } else if (isEmpty(feedOutlets)) {
      return <NoPostsBasedOnTab />;
    } else if (!isEmpty(feedOutlets)) {
      return (
        <>
          <Grid
            columns={3}
            minWidth={'15rem'}
            rowGap={'1.5rem'}
            columnGap={'1.5rem'}
            className={style.feedContainer}
          >
            {feedOutlets.map((item: FaveFeed) => (
              <PostCard key={item.id} data={item} />
            ))}
          </Grid>
          <div ref={fetchStatementsRef} />
          {isLoadingMore && <LoadingSpinnerSmall />}
        </>
      );
    }
    return <></>;
  };

  const FeedInfoIcon = () => {
    if (isEmpty(faveFeedData)) return null;
    else return <InfoIcon onClick={handleUserEducationClick} />;
  };

  const handleUserEducationClick = () => {
    const isFeedEmpty = isEmpty(faveFeedData);
    cleverTap.tapped.push({
      screen_name: isFeedEmpty
        ? CLEVERTAP_SCREEN_NAME.FEED
        : CLEVERTAP_SCREEN_NAME.FEED_LIST,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_LEARN_MORE_FEED
    });
    if (isWebview) call(NATIVE.FEED_VIEW_EDUCATION);
    else setShowEducationModal(true);
  };

  return (
    <AppLayout className={classNames(isWebview && style.webviewLayout)}>
      <DetailLayout>
        {isWebview ? (
          <WebviewHeading
            title={t(title)}
            onBack={() => call(NATIVE.CLOSE_WEBVIEW)}
            IconElement={<FeedInfoIcon />}
            additionalContent={
              showToggleButtons && (
                <FaveFeedToggleFilter
                  value={outletType}
                  buttonProps={toggleButtonProps}
                  onClickCreatePost={onClickNewFeedPost}
                />
              )
            }
          />
        ) : (
          <>
            <Heading
              title={t(title)}
              subtitle={t(
                'Start interacting with your customers by creating a post.'
              )}
              iconElement={<FeedInfoIcon />}
            />
            {showToggleButtons && (
              <FaveFeedToggleFilter
                value={outletType}
                buttonProps={toggleButtonProps}
                showFeedButtonSmall={isWideView}
                onClickCreatePost={onClickNewFeedPost}
              />
            )}
          </>
        )}
        <div className={style.feedPage}>
          <Feed />
          {(isWebview || !isWideView) && !isEmpty(faveFeedData) && (
            <div className={style.createPostButtonContainer}>
              <Button
                type={'primary'}
                onClick={onClickNewFeedPost}
                fullWidth={true}
                leftIcon={'cross'}
                isWebview={isWebview || !isWideView}
              >
                {t('New feed post')}
              </Button>
            </div>
          )}
        </div>
      </DetailLayout>
    </AppLayout>
  );
};

export default FaveFeedView;
