import {
  Invoice,
  InvoiceDocument,
  InvoiceProduct
} from 'queries/getInvoiceSelfServe';

export type MonthOption = {
  title: string;
  date: Date;
  dataParam: string;
};

const generateMonthList = () => {
  const monthList: MonthOption[] = [];
  const date = new Date();
  const initialMonth = date.getMonth();

  for (let i = 1; i <= 12; i++) {
    const monthDate = new Date(date);
    monthDate.setDate(1);
    monthDate.setMonth(initialMonth - i);

    monthList.push({
      title: `${monthDate.toLocaleString('default', {
        month: 'short'
      })} ${monthDate.getFullYear()}`,
      dataParam: monthDate
        .toLocaleString('default', {
          year: 'numeric',
          month: '2-digit'
        })
        .replace('/', '-'),
      date: monthDate
    });
  }

  return monthList;
};

export const getInvoiceKey = (
  { invoice_period }: Invoice,
  { name: productName }: InvoiceProduct,
  { name: docName, document_type }: InvoiceDocument
) => `${invoice_period}/${productName}/${docName}/${document_type}`;

export const monthList = generateMonthList();

export default {};
