import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import getECardsRevenue from 'queries/getECardsRevenue';
import { OutletContext } from 'contexts/OutletContext';
import { WebviewContext } from 'contexts/WebviewContext';

import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import ECardWithDetails from 'components/ECardWithDetails';
import Button from 'components/Button';
import WebviewHeading from 'components/WebviewHeading';

import styles from 'assets/css/ECardsPendingView.module.scss';

const ECardsPendingView = () => {
  const { t } = useTranslation();
  const { currentCompany } = useContext(OutletContext);
  const { isWebview } = useContext(WebviewContext);

  const [pendingECardsArray, setPendingECardsArray] = useState([]);
  const { logo_url: outletImage, partner_name: companyName } =
    currentCompany || {};
  const response = getECardsRevenue({
    company_id: currentCompany && currentCompany.id
  });

  useEffect(() => {
    if (!response.data) return;
    const { e_cards: eCards } = response.data;
    setPendingECardsArray(eCards.filter(data => data.status === 'draft'));
  }, [response.data]);

  return (
    <AppLayout>
      {isWebview ? (
        <>
          <WebviewHeading title="Pending Approval" backURL={'/ecards'} />
          <div className={styles.webviewSubheading}>
            {t(
              'Your eCards are pending approval, we will get back to you as soon as possible.'
            )}
          </div>
        </>
      ) : (
        <>
          <Heading
            title="Pending approval"
            subtitle={t('Take a look at the eCards that you have submitted')}
          />
          <Button
            leftIcon="arrow-left"
            type="text"
            href={'/ecards'}
            iconColor="light-black"
            children={t('Back')}
            textColor="charcoal-gray"
          />
        </>
      )}

      <div className={styles.cardGrid}>
        {!!pendingECardsArray.length &&
          pendingECardsArray.map(
            ({
              id,
              currency,
              original_price,
              selling_price,
              discount,
              default_logo,
              company_logo_url
            }) => (
              <ECardWithDetails
                key={id}
                href={`/ecards/pending/${id}`}
                sellingPrice={selling_price}
                discountRate={discount}
                originalPrice={original_price}
                outletImage={default_logo ? outletImage : company_logo_url}
                companyName={companyName}
                currency={currency}
              />
            )
          )}
      </div>
    </AppLayout>
  );
};

export default ECardsPendingView;
