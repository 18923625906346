import React, { useState } from 'react';
import classNames from 'classnames';
import { AccordionItem, AccordionHeader } from 'components/Accordion';

import styles from 'assets/css/Accordion.module.scss';

interface IItems {
  header: string | JSX.Element;
  headerClass?: string;
  headerStyle?: React.CSSProperties;
  panelClass?: string;
  Panel: () => JSX.Element;
}

export interface IAccordionProps {
  className: string;
  items: IItems[];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Accordion: React.FC<IAccordionProps> = ({ className, ...props }) => {
  const { items, onClick } = props;
  const [currentItemHeader, setCurrentItemHeader] = useState<
    string | JSX.Element
  >('');

  return (
    <section className={className} onClick={onClick}>
      {items.map((item, index) => {
        const isCurrentItem = item.header === currentItemHeader;
        return (
          <AccordionItem
            key={typeof item.header === 'string' ? item.header : index}
            isActive={isCurrentItem}
          >
            <AccordionHeader
              className={item.headerClass ?? ''}
              style={item.headerStyle}
              isActive={isCurrentItem}
              onClick={() =>
                setCurrentItemHeader(prevState =>
                  prevState === item.header ? '' : item.header
                )
              }
            >
              {item.header}
            </AccordionHeader>
            <section className={classNames(styles.layout, item.panelClass)}>
              <item.Panel />
            </section>
          </AccordionItem>
        );
      })}
    </section>
  );
};

export default Accordion;
