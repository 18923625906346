import Kfit from '../integrations/Kfit';
import MapleBizAPI from 'integrations/MapleBizAPI';
import { isAppMapleBiz } from 'contexts/MapleContext';

export default params =>
  isAppMapleBiz
    ? MapleBizAPI.post(`/v1/auth/verify_request`, {
        ...params,
        phone: params.value
      }).then(res => res)
    : Kfit.post(`/v6/auth/verify_request`, params).then(res => res);
