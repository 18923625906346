import React from 'react';
import { navigate } from '@reach/router';
import SidebarAccordionItem from 'components/SidebarAccordionItem';
import { useTranslation } from 'react-i18next';
import { MapleContext } from 'contexts/MapleContext';

const rootPath = '/operations/';

type OpsSidebarNavProps = {
  links: [];
  selectedOpenAccordion: string;
  setSelectedOpenAccordion: () => void;
  features: {
    alipay: boolean;
    fave_payment: boolean;
    voucher: boolean;
    can_receive_payment: boolean;
  };
  country: string;
};

const OpsSidebarNav: React.FC<OpsSidebarNavProps> = ({ features, country }) => {
  const { t } = useTranslation();
  const isMY = country === 'Malaysia';
  const { isMaple } = React.useContext(MapleContext);

  const links = [
    {
      title: t('Transactions'),
      icon: 'money-bag',
      path: `${rootPath}transactions`,
      tap: true,
      enabled: true,
      event: 'btn_transaction'
    },
    {
      title: t('Receive Payment'),
      icon: 'receive-payment',
      path: `${rootPath}payment`,
      tap: true,
      enabled: isMY ? features.can_receive_payment : features.fave_payment,
      event: 'btn_outlet_qrcode'
    },
    {
      title: t('Redeem'),
      icon: 'redeem',
      path: `${rootPath}redemption`,
      tap: true,
      enabled: features.voucher,
      event: 'btn_redeem'
    },
    {
      title: 'Alipay',
      icon: 'alipay',
      path: `${rootPath}transactions/alipay`,
      tap: true,
      enabled: features.alipay && isMY,
      event: 'btn_alipay'
    },
    {
      title: t('Reports'),
      icon: 'reports',
      path: `/operations/reports`,
      tap: true,
      event: 'btn_reports',
      enabled: !isMaple
    },
    {
      title: t('Contact Us'),
      icon: 'phone',
      path: `/contact-us`,
      tap: true,
      event: 'btn_help_contact',
      enabled: !isMaple
    },
    {
      title: t('Need help'),
      icon: 'phone',
      tap: true,
      event: 'btn_help_contact',
      enabled: isMaple,
      path: `https://partners.myfave.in/support/home`
    }
  ].filter(link => link.enabled);

  return (
    <>
      {links.map(link => (
        <SidebarAccordionItem
          key={link.path}
          link={link}
          isHighlighted={window.location.href.includes(link.path)}
          onHeaderClick={() => navigate(link.path)}
        />
      ))}
    </>
  );
};

export default OpsSidebarNav;
