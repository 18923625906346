import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Field, FieldArray, Formik } from 'formik';
import { navigate } from '@reach/router';
import classnames from 'classnames';
import moment from 'moment';
import { OutletContext } from 'contexts/OutletContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { MapleContext } from 'contexts/MapleContext';
import {
  updateAnnouncement,
  createAnnouncement
} from 'commands/announcementAPI';
import { getAnnouncement } from 'queries/getOutletAnnouncement';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import WebviewHeading from 'components/WebviewHeading';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DateRangePicker from 'components/DateRangePicker';
import { getYMDFormat } from 'utils/Dates';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import Styles from 'assets/css/AnnouncementFormView.module.scss';

const TITLE_LIMIT = 20;
const ANNOUNCEMENT_LIMIT = 100;

const AnnouncementFormView = props => {
  const { t } = useTranslation();
  const { isWebview } = useContext(WebviewContext);
  const ReportSchema = Yup.object().shape({
    title: Yup.string().required(t('Please add a title to your announcement')),
    announcement: Yup.string().required(
      t('Please add a description to your announcement')
    ),
    outletCheckbox: Yup.array().min(1, t('at least one outlet'))
  });

  const { formId } = props;
  const { currentCompany } = useContext(OutletContext);
  const { setNotification } = useContext(NotificationContext);
  const { isMaple } = useContext(MapleContext);
  const [titleCharCounter, setTitleCharCounter] = useState(0);
  const [announceCharCounter, setAnnounceCharCounter] = useState(0);
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementBody, setAnnouncementBody] = useState('');
  const [imageSrc, setImageSrc] = useState();
  const [imageFile, setImageFile] = useState();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const cleverTap = useCleverTapOld();

  useEffect(() => {
    if (formId) {
      getAnnouncement(formId).then(response => {
        setImageSrc(response.image_url);
        setSelectedOutlets(response.outlets.map(outlet => outlet.id));
        setStartDate(moment(response.start_at));
        setEndDate(moment(response.end_at));
        setAnnouncementTitle(response.title);
        setAnnouncementBody(response.announcement);
      });
    } else if (currentCompany) {
      setSelectedOutlets(currentCompany.outlets.map(data => data.id));
    }
  }, [formId, currentCompany]);

  const inputCharacterChange = formik => {
    const titleInput = formik.values.title;
    const announceInput = formik.values.announcement;
    if (titleInput.length <= TITLE_LIMIT) {
      setTitleCharCounter(titleInput.length);
    } else {
      setTitleCharCounter(TITLE_LIMIT);
    }

    if (announceInput.length <= ANNOUNCEMENT_LIMIT) {
      setAnnounceCharCounter(announceInput.length);
    } else {
      setAnnounceCharCounter(ANNOUNCEMENT_LIMIT);
    }
  };

  const onSelectAllClick = outlets =>
    setSelectedOutlets(outlets.map(outletData => outletData.id));

  const onDeselectAllClick = () => setSelectedOutlets([]);

  const onEditAnnouncementFormSubmit = (value, formikBag) => {
    const { outletCheckbox } = value;
    const { setSubmitting } = formikBag;
    const formData = new FormData();
    formData.append('title', announcementTitle);
    formData.append('announcement', announcementBody);
    formData.append('outlet_ids', outletCheckbox.join());
    formData.append('start_at', getYMDFormat(startDate));
    formData.append('end_at', getYMDFormat(endDate));
    if (imageFile) {
      formData.append('image', imageFile);
    }

    if (formId && parseInt(formId, 10)) {
      updateAnnouncement(formId, formData)
        .then(() => {
          setNotification({ message: t('Update Success'), type: 'success' });
          navigate('/announcements');
        })
        .catch(error =>
          setNotification({
            message: error.value.response.error,
            type: 'error'
          })
        )
        .finally(() => setSubmitting(false));
    } else {
      formData.append('status', 'active');
      createAnnouncement(formData)
        .then(response => {
          setNotification({
            message: t('Announcement Created'),
            type: 'success'
          });
          navigate('/announcements');
        })
        .catch(error =>
          setNotification({
            message: error.value.response.error,
            type: 'error'
          })
        )
        .finally(() => setSubmitting(false));
    }
  };

  const onUploadClick = e => {
    const file = document.querySelector('input[type=file]').files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        setImageFile(file);
        setImageSrc(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onChangeDateRange = ({ startDate, endDate }) => {
    startDate && setStartDate(startDate);
    endDate && setEndDate(endDate);
  };
  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading title={t('Announcements')} backURL="/announcements" />
      ) : (
        <Heading
          title={t('Announcements')}
          subtitle={t(
            'Got something to share with your customer? Create an annoucement'
          )}
        />
      )}
      {!isWebview && (
        <Button
          leftIcon="arrow-left"
          href="/announcements"
          type="text"
          className={Styles.back}
          children={t('Back to all Announcements')}
        />
      )}
      <h3 className={Styles.announcementFormTitle}>
        {t('Create New Annoucement')}
      </h3>
      <p className={Styles.announcementFormSubtitle}>
        {t(
          'Add an announcement and it will be featured on your outlet page on Fave.'
        )}
      </p>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          title: announcementTitle,
          announcement: announcementBody,
          outletCheckbox: selectedOutlets
        }}
        validationSchema={ReportSchema}
        onSubmit={(values, formikBag) => {
          onEditAnnouncementFormSubmit(values, formikBag);
        }}
      >
        {formik => (
          <Form>
            {inputCharacterChange(formik)}
            <div className={Styles.announcementForm}>
              <div className={Styles.formDate}>
                <h5>{t('Date Range')}</h5>
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  openDirection="down"
                  onChange={onChangeDateRange}
                  isOutsideRange={day => day.isBefore(moment(), 'day')}
                />
              </div>

              <div className={Styles.formElement}>
                <h5>{t('Announcement Title*')}</h5>
                <Field
                  type="text"
                  name="title"
                  className={classnames(Styles.title, Styles.field)}
                  placeholder={t('For e.g Teatime Promotion')}
                  maxLength={TITLE_LIMIT}
                  onChange={e => {
                    setAnnouncementTitle(e.target.value);
                  }}
                />
                <span
                  className={Styles.characterLimit}
                >{`${titleCharCounter}/${TITLE_LIMIT}`}</span>
                <span className={Styles.error}>{formik.errors.title}</span>
              </div>

              <div className={Styles.formElement}>
                <h5>{t('Description*')}</h5>
                <Field
                  component="textarea"
                  type="text"
                  name="announcement"
                  maxLength={ANNOUNCEMENT_LIMIT}
                  className={classnames(Styles.announcement, Styles.field)}
                  placeholder="For e.g Purchase any pie and get a complimentary Americano from 3PM - 5PM daily."
                  onChange={e => {
                    setAnnouncementBody(e.target.value);
                  }}
                />
                <span
                  className={Styles.characterLimit}
                >{`${announceCharCounter}/${ANNOUNCEMENT_LIMIT}`}</span>
                <span className={Styles.error}>
                  {formik.errors.announcement}
                </span>
              </div>

              <div className={Styles.formPhoto}>
                <h5>{t('Upload a photo')}</h5>
                <input
                  id="file"
                  className={Styles.imageUpload}
                  type="file"
                  aria-label="File browser example"
                  accept="image/*"
                  onChange={onUploadClick}
                />
                <label htmlFor="file">Choose File</label>
                {imageSrc && (
                  <img
                    src={imageSrc}
                    className={Styles.imagePreview}
                    alt="upload preview"
                  />
                )}
              </div>

              <div className={Styles.formElement}>
                <h5>{t('Run announcement for these outlets*')}</h5>
                <p className={Styles.outletOptions}>
                  <span
                    onClick={() => onSelectAllClick(currentCompany.outlets)}
                  >
                    {t('Select All')}
                  </span>
                  <span onClick={() => onDeselectAllClick()}>
                    {t('Deselect All')}
                  </span>
                </p>
              </div>
              <span className={Styles.error}>
                {formik.errors.outletCheckbox}
              </span>
              <div className={Styles.formOutlets}>
                {currentCompany && (
                  <FieldArray
                    name="outletCheckbox"
                    render={arrayHelpers =>
                      currentCompany.outlets.map(outlet => {
                        return (
                          <Checkbox
                            key={outlet.id}
                            value={outlet.id}
                            type="green"
                            checked={formik.values.outletCheckbox.includes(
                              outlet.id
                            )}
                            onChange={e => {
                              if (e.target.checked)
                                arrayHelpers.push(outlet.id);
                              else {
                                const checkedOutletIndex = formik.values.outletCheckbox.indexOf(
                                  outlet.id
                                );
                                arrayHelpers.remove(checkedOutletIndex);
                              }
                            }}
                          >
                            {outlet.name}{' '}
                            {isMaple && outlet.neighbourhood_slug !== '' ? (
                              <span className={Styles.neighbourhoodSlug}>
                                {' '}
                                | {outlet.neighbourhood_slug}
                              </span>
                            ) : null}
                          </Checkbox>
                        );
                      })
                    }
                  />
                )}
              </div>

              <Button
                type="submit"
                className={Styles.submitButton}
                size="lg"
                onClick={() =>
                  cleverTap.tapped.push({
                    screen_name: formId
                      ? 'announcement_edit'
                      : 'announcement_new',
                    tapped_on: 'btn_submit_announcement'
                  })
                }
              >
                {t('Submit')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AppLayout>
  );
};

export default AnnouncementFormView;
