import React, { useState, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Redirect, navigate, useLocation } from '@reach/router';

import { FCMContext } from 'contexts/FCMContext';
import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { ModalContext } from 'contexts/ModalContext';
import { CleverTapContextOld } from 'contexts/CleverTapContextOld';

import SideBarNav from 'components/SideBarNav';
import OutletSwitcher from 'components/OutletSwitcher';
import Topbar from 'components/Topbar';
import Button from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import FinancingFeedbackModal from 'components/FinancingFeedbackModal';
import { useTranslation } from 'react-i18next';
import Cookie from 'helpers/Cookie';
import styles from 'assets/css/Layout.module.scss';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { MapleContext } from 'contexts/MapleContext';

import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import useChat from 'hooks/useChat';
import { capitalize } from 'lodash';
import { getStagingInfo } from 'integrations/Kfit';
import Card from 'components/Card';

type Props = {
  sideBarVisibleDefaultValue?: boolean;
  showTopRightDropDown?: boolean;
  className?: string;
  sectionClassName?: string;
  onOutletSwitcherChanged?: () => void;
};

const { isProduction, stagingName } = getStagingInfo();

const AppLayout: React.FC<Props> = props => {
  const {
    sideBarVisibleDefaultValue = false,
    showTopRightDropDown = false,
    className,
    sectionClassName,
    onOutletSwitcherChanged
  } = props;
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const queryParams = useLocation().search;
  const clevertap = useCleverTapOld();
  const { outlets, currentCompany, currentOutlet } = useContext(OutletContext);
  const { isWebview } = useContext(WebviewContext);
  const [redirectPath, setRedirectPath] = useState(pathname + queryParams);
  const {
    isAuthenticatingUser,
    isValidUser,
    unauthenticateUser,
    user
  } = useContext(AuthContext);
  const { unsubscribeAll, isBrowserSupported } = useContext(FCMContext);
  const [asideVisible, setAsideVisible] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(
    sideBarVisibleDefaultValue
  );
  const { init, showConfirmModal } = useContext(ModalContext);
  const { cleverTapProfile } = useContext(CleverTapContextOld);
  const { chatText, openWidget } = useChat();

  useEffect(() => {
    setSidebarVisible(sideBarVisibleDefaultValue);
  }, [sideBarVisibleDefaultValue]);

  useEffect(() => {
    if (pathname.startsWith('/offer/deactivate')) {
      setAsideVisible(false);
    }
  }, [pathname]);

  let dropdownItems = [
    {
      title: t('Switch Business'),
      href: '/switch-business',
      leftIcon: 'switch-business',
      iconColor: 'charcoal-gray',
      enabled: true,
      tap: true,
      event: {
        screen_name: 'profile',
        section_name: 'profile',
        tapped_on: 'btn_switch_business'
      }
    },
    {
      title: t('Switch to Staff'),
      onClick: () => {
        if (outlets && outlets.length === 1) {
          Cookie.remove('currentOutletCode');
          Cookie.set('authCode', outlets[0].auth_code);
          return window.location.assign('/operations/transactions');
        }
        return navigate('/switch-to-staff');
      },
      leftIcon: 'switch',
      iconColor: 'charcoal-gray',
      tap: true,
      event: {
        screen_name: 'profile',
        section_name: 'profile',
        tapped_on: 'btn_switch_staff'
      },
      enabled: true,
      classNames: ['switch-staff-navigation-button']
    },
    {
      title: t('Change Language'),
      href: '/switch-language',
      leftIcon: 'switch-lang',
      iconColor: 'charcoal-gray',
      enabled: !isMaple,
      tap: true,
      event: {
        screen_name: 'profile',
        section_name: 'profile',
        tapped_on: 'btn_switch_language'
      }
    },
    {
      title: t('Notifications'),
      Component: ({ className }: { className: string }) => (
        <Button
          href="/push-notifications"
          leftIcon="notification"
          type="text"
          size="xs"
          className={className}
          iconColor="charcoal-gray"
          onClick={() =>
            clevertap.tapped.push({
              screen_name: 'profile',
              section_name: 'profile',
              tapped_on: 'btn_push_notification'
            })
          }
        >
          {t('Push notifications')}
        </Button>
      ),
      classNames: ['button', 'dropdownItem'],
      enabled: !isMaple && isBrowserSupported
    },
    {
      title: t('Notifications'),
      Component: ({ className }: { className: string }) => (
        <Button
          href="/email-notifications"
          leftIcon="notification"
          type="text"
          size="xs"
          className={className}
          iconColor="charcoal-gray"
        >
          {t('Notifications')}
        </Button>
      ),
      classNames: ['button', 'dropdownItem'],
      enabled: isMaple
    },
    {
      title: t('Logout'),
      leftIcon: 'logout',
      iconColor: 'charcoal-gray',
      onClick: () => {
        showConfirmModal(
          t(
            'Staying signed in makes it easier to quickly access your business data. Are you sure you want to sign out?'
          ),
          () => {
            setRedirectPath('');
            cleverTapProfile.logout();
            unauthenticateUser(unsubscribeAll);
          },
          undefined
        );
      },
      enabled: true,
      tap: true,
      event: {
        screen_name: 'profile',
        section_name: 'profile',
        tapped_on: 'btn_signout'
      }
    },
    {
      separator: !isMaple
    },
    {
      title: t('Delete account'),
      leftIcon: 'trash-bin',
      iconColor: 'charcoal-gray',
      onClick: () => {
        const chatPhrase = capitalize(chatText);

        showConfirmModal(
          `Deleting your account is permanent. ${chatPhrase} to delete your account.`,
          () => {
            openWidget();

            clevertap.tapped.push({
              screen_name: CLEVERTAP_SCREEN_NAME.DELETE_ACCOUNT,
              tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CHAT_US
            });
          },
          undefined, //just close the modal
          chatPhrase,
          'Keep my account'
        );

        clevertap.screenDisplayed.push({
          screen_name: CLEVERTAP_SCREEN_NAME.DELETE_ACCOUNT
        });
      },
      enabled: !isMaple,
      tap: true,
      event: {
        screen_name: 'account',
        tapped_on: 'btn_delete_account'
      }
    }
  ].filter(d => d.enabled || d.separator);

  dropdownItems =
    user && user.companies && user.companies.length === 1
      ? dropdownItems.filter(item => item.leftIcon !== 'switch-business')
      : dropdownItems;

  useEffect(() => {
    if (!isValidUser) return;

    const shouldShow =
      Cookie.get('financingFeedback', '') && pathname !== '/financing';

    //the modal would still flash even if openOnReady/openOnlyIf are false/falsey
    if (shouldShow)
      init({
        Component: FinancingFeedbackModal,
        openOnReady: true,
        onClose: () => {
          Cookie.remove('financingFeedback');
        }
      });
  }, [isValidUser, pathname, init]);

  const redirectToRoot = useMemo(
    () =>
      pathname.startsWith('/offer/deactivate') ? '/edm-manager-login' : '/',
    [pathname]
  );

  const stagingLabelVisible = !(isProduction || isMaple);

  return isAuthenticatingUser ? (
    <LoadingSpinner />
  ) : isValidUser ? (
    <div>
      {!isWebview && (
        <Topbar
          sidebarToggle={() => setSidebarVisible(!sidebarVisible)}
          dropdownItems={dropdownItems}
          currentCompanyLogo={(currentCompany || {}).logo_url}
          currentOutletName={(currentOutlet || {}).name || t('All Outlets')}
          onClickProfile={isOpen => {
            !isOpen &&
              clevertap.tapped.push({
                screen_name: 'navigation_bar',
                tapped_on: 'btn_profile',
                section_name: 'bar_top'
              });
          }}
          showTopRightDropDown={showTopRightDropDown}
        />
      )}
      <main
        className={classNames(styles.contentWrapper, 'container', className)}
      >
        {!isWebview && asideVisible && (
          <aside
            className={classNames(
              styles.sidebar,
              sidebarVisible && styles.open
            )}
          >
            <Button
              type="text"
              textColor="dark-gray"
              className={classNames(styles.button, styles.toggleClose)}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <Icon icon="times" color="primary" size="1.9375rem" />
              &nbsp;
            </Button>
            <span className={styles.businessTitle}>
              {(currentCompany || {}).partner_name}
            </span>
            {currentCompany && (
              <OutletSwitcher
                onChange={() => {
                  setSidebarVisible(false);

                  onOutletSwitcherChanged && onOutletSwitcherChanged();
                }}
              />
            )}
            {stagingLabelVisible && (
              <Card className={styles.stagingLabel}>
                <span>{`${stagingName} staging`}</span>
              </Card>
            )}
            <div
              className={classNames(styles.menuDivider, {
                [styles.smallDivider]: stagingLabelVisible
              })}
            />
            <SideBarNav />
          </aside>
        )}
        <section className={classNames(styles.content, sectionClassName)}>
          {props.children}
        </section>
      </main>
    </div>
  ) : (
    <Redirect
      to={
        redirectPath === ''
          ? redirectToRoot
          : `${redirectToRoot}?redirect_path=` + redirectPath
      }
      noThrow
    />
  );
};

export default AppLayout;
