import React, { useContext } from 'react';
import { InsightsAuthContext } from 'insights/contexts/InsightsAuthContext';

export const InsightsOutletContext = React.createContext();

export const InsightsOutletProvider = props => {
  const { settings = {} } = useContext(InsightsAuthContext);
  const { outletId, outlets = [], name: companyName } = settings;

  const implementation = {
    isInsights: true,
    currentCompany: { partner_name: companyName },
    currentOutlet: outlets.find(o => o.id === parseInt(outletId)),
    outlets
  };

  return (
    <InsightsOutletContext.Provider value={implementation}>
      {props.children}
    </InsightsOutletContext.Provider>
  );
};
