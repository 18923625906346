import React, { useContext, useState } from 'react';
import { hasKeys } from 'utils';
import { useTranslation } from 'react-i18next';

import { FCMContext } from 'contexts/FCMContext';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';
import LoadingSpinner from 'components/LoadingSpinner';
import OpsNotifications from 'components/OpsNotifications';
import classNames from 'classnames';
import { WebviewContext } from 'contexts/WebviewContext';
import Topbar from 'components/Topbar';
import Button from 'components/Button';
import Icon from 'components/Icon';
import OpsSidebarNav from 'components/OpsSidebarNav';
import styles from 'assets/css/Layout.module.scss';
import Cookie from 'helpers/Cookie';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { MapleContext } from 'contexts/MapleContext';

const OpsLayout = props => {
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const clevertap = useCleverTapOld();
  const {
    isValidOps,
    isAuthenticatingOps,
    settings,
    unauthenticateOps,
    isManager
  } = useContext(OpsAuthContext);
  const { unsubscribeAll, isBrowserSupported } = useContext(FCMContext);

  const { currentOutlet } = useContext(OpsOutletContext);

  let dropdownItems = [
    {
      title: t('Change Language'),
      href: '/switch-language',
      leftIcon: 'switch-lang',
      iconColor: 'charcoal-gray',
      enabled: !isMaple,
      tap: true,
      event: {
        screen_name: 'profile',
        section_name: 'profile',
        tapped_on: 'btn_switch_language'
      }
    },
    {
      title: t('Switch to Manager'),
      leftIcon: 'switch',
      iconColor: 'charcoal-gray',
      onClick: () => unauthenticateOps(),
      enabled: true,
      tap: true,
      event: {
        screen_name: 'profile',
        section_name: 'profile',
        tapped_on: 'btn_switch_manager'
      }
    },
    {
      title: t('Notifications'),
      Component: OpsNotifications,
      classNames: ['button', 'dropdownItem'],
      enabled: !isMaple && isBrowserSupported
    },
    {
      title: t('Logout'),
      leftIcon: 'logout',
      iconColor: 'charcoal-gray',
      onClick: () => {
        Cookie.remove('accessToken');
        unauthenticateOps(unsubscribeAll);
      },
      enabled: true,
      tap: true,
      event: {
        screen_name: 'profile',
        section_name: 'profile',
        tapped_on: 'btn_signout'
      }
    }
  ].filter(d => d.enabled);

  dropdownItems = isManager
    ? dropdownItems
    : dropdownItems.filter(item => item.leftIcon !== 'switch');

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const { isWebview } = useContext(WebviewContext);
  return isAuthenticatingOps ? (
    <LoadingSpinner />
  ) : isValidOps ? (
    <div>
      {!isWebview && (
        <Topbar
          isStaff={true}
          currentCompanyLogo={
            hasKeys(settings) ? settings.profile.logo_url : ''
          }
          currentOutletName={currentOutlet.name}
          dropdownItems={dropdownItems}
          sidebarToggle={() => setSidebarVisible(!sidebarVisible)}
          profileOnclick={isOpen =>
            !isOpen &&
            clevertap.tapped.push({
              screen_name: 'navigation_bar',
              tapped_on: 'btn_profile',
              section_name: 'bar_top'
            })
          }
        />
      )}
      <main className={classNames(styles.contentWrapper, 'container')}>
        {!isWebview && (
          <aside
            className={classNames(
              styles.sidebar,
              sidebarVisible && styles.open
            )}
          >
            <Button
              type="text"
              textColor="dark-gray"
              className={classNames(styles.button, styles.toggleClose)}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <Icon icon="times" color="primary" size="1.9375rem" />
              &nbsp;
            </Button>
            <p className={styles.businessTitle}>
              {settings.profile.company_name}
              <span className={styles.outletTitle}>{currentOutlet.name}</span>
              {isMaple && currentOutlet.neighbourhood_slug ? (
                <span className={styles.outletNeighbourhoodSlug}>
                  | {currentOutlet.neighbourhood_slug}
                </span>
              ) : (
                <></>
              )}
            </p>
            <OpsSidebarNav
              features={{
                ...settings.features,
                can_receive_payment: currentOutlet?.can_receive_payment
              }}
              country={settings.country}
              closeSidebar={() => setSidebarVisible(false)}
            />
          </aside>
        )}
        <section className={styles.content}>{props.children}</section>
      </main>
    </div>
  ) : (
    <>( window.location.assign('/') )</>
  );
};

export default OpsLayout;
