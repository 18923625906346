import classNames from 'classnames';
import { WebviewContext } from 'contexts/WebviewContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAndroid } from 'utils/nativeInterface';
import * as Yup from 'yup';

import styles from '../assets/css/ViewEditUserReview.module.scss';
import Button from './Button';
import Form from './common/forms/Form';
import SubmitButton from './common/forms/SubmitButton';
import TextAreaInput from './common/forms/TextAreaInput';
import { ReplyCard, Review, ReviewCard } from './UserReview';

type ViewEditUserReviewProps = {
  review: Review;
  onSubmit: (replyText: string, review: Review) => void;
  onCancel: () => void;
};

type CommentType = { reply: string };

const validationRules = Yup.object().shape({
  reply: Yup.string()
    .min(2)
    //.max(maxDescriptionLength, 'Too Long!') //might need this
    .required('Required')
});

const ViewEditUserReview = ({
  review,
  onSubmit,
  onCancel
}: ViewEditUserReviewProps) => {
  const { t } = useTranslation();
  const { isWebview } = useContext(WebviewContext);

  const { review_reply } = review;

  const isEditMode = !review_reply;

  const handleSubmit = (values: CommentType) => onSubmit(values.reply, review);
  const [commentInputFocused, setCommentInputFocused] = useState(false);
  const isIOS = isWebview && !isAndroid;

  return (
    <div className={styles.container}>
      <ReviewCard review={review} showMoreDisabled />

      {isEditMode ? (
        <Form
          initialValues={{ reply: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationRules}
          validateOnMount
          validateOnChange
          validateOnSchemaChange
        >
          <div
            className={classNames(
              styles.replyForm,
              commentInputFocused && styles.marginUnset
            )}
          >
            <TextAreaInput
              name={'reply'}
              inputClassName={styles.replyInput}
              placeholder={t('Enter your reply here')}
              onFocus={() => isIOS && setCommentInputFocused(true)}
              onBlur={() => isIOS && setCommentInputFocused(false)}
            />

            <div className={styles.buttons}>
              <Button
                isRounded
                type="default"
                onClick={onCancel}
                isWebview={true}
                size={'lg'}
              >
                {t('Cancel')}
              </Button>
              <SubmitButton disableIfNotValid isWebview>
                {t('Submit')}
              </SubmitButton>
            </div>
          </div>
        </Form>
      ) : (
        <ReplyCard review={review} />
      )}
    </div>
  );
};

export default ViewEditUserReview;
