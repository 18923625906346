import { useState } from 'react';

const useTriggeredText = (
  initialText: string,
  temporaryText: string,
  timeoutSeconds: number = 2
) => {
  const [isTriggered, setIsTriggered] = useState(false);

  const trigger = () => {
    setIsTriggered(true);

    if (timeoutSeconds > 0)
      setTimeout(() => setIsTriggered(false), timeoutSeconds * 1000);
  };

  const text = isTriggered ? temporaryText : initialText;

  return { text, isTriggered, trigger };
};

export default useTriggeredText;
