import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from 'assets/css/Badge.module.scss';

type BadgeProps = {
  checked: boolean;
  onChange?: (t?: boolean) => void;
  preventUnchecked?: boolean;
  withCheckMark?: boolean;
  activeClassName?: string;
  className?: string;
};

const Badge: React.FC<BadgeProps> = ({
  onChange = () => {},
  checked = false,
  preventUnchecked = false,
  className,
  withCheckMark = true,
  activeClassName,
  children
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    preventUnchecked && setIsChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <label
      className={classNames(
        styles.badge,
        className,
        isChecked && (activeClassName || styles.active)
      )}
    >
      {children}
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={isChecked}
        onChange={onChangeCheckbox}
      />
      <span
        className={classNames(
          withCheckMark && styles.checkmark,
          isChecked && styles.active
        )}
      />
    </label>
  );
};

export default Badge;
