import React, { useRef, useEffect, PropsWithChildren } from 'react';

type OutOfBoundClickProps = {
  onClick: () => void;
  className?: string;
};

/**
 * Component that invoke a function if you click outside bound of it
 * Use mainly to close/off a dropdown element
 */
export default function OutOfBoundClick(
  props: PropsWithChildren<OutOfBoundClickProps>
) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        props.onClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [props]);

  return (
    <div ref={wrapperRef} className={props.className}>
      {props.children}
    </div>
  );
}
