import BulkActionsDropDown from 'components/BulkActionsDropdown';
import Button from 'components/Button';
import Heading from 'components/Heading';
import LoadingSpinner from 'components/LoadingSpinner';
import LoadingSpinnerSmall from 'components/LoadingSpinnerSmall';
import OutOfBoundClick from 'components/OutOfBoundClick';
import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import AppLayout from 'layouts/AppLayout';
import { compact, find, first, isEmpty, pull, size, uniq } from 'lodash';
import getStatementsOfAccountsDetails from 'queries/getStatementsOfAccountsDetails';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import style from '../assets/css/OutletStatementsView.module.scss';
import StatementsImage from 'assets/images/statements.svg';
import NoBankAccountIcon from 'assets/images/no-bank-account-icon.png';
import { MONTHNUMBER, MONTHSARRAY } from 'helpers/TypeConstants';
import Checkbox from 'components/Checkbox';
import useCleverTapOld from 'hooks/useCleverTapOld';
import classNames from 'classnames';
import Icon from 'components/Icon';
import useFetchDataOnScroll from 'hooks/useFetchDataOnScroll';
import getStatementsOfAccounts, {
  StatementOfAccountBank
} from 'queries/getStatementsOfAccounts';
import { navigate } from '@reach/router';
import DatePicker, { DateInputBadge } from 'components/DatePicker';
import { formatISOToLocalTime } from 'helpers/dateUtility';

type StatementsType = {
  journal_id: number;
  reference_no: string;
  formatted_issued_date: string;
  amount: number;
  formatted_amount: string;
  period: string;
  transaction_count: number;
  isSelected?: boolean;
};

type MonthStatementsType = {
  month: number;
  year: number;
  details: Array<StatementsType>;
};

type StatementsOfAccountsDetailsResponseType = {
  bank_account_number: string;
  bank_name: string;
  outlets_name: string[];
  total_count: number;
  statements: MonthStatementsType[];
};

type ReducerStateType = {
  currentPage: number;
  selectedStatements: number[];
  startDate: Date | undefined;
  endDate: Date | undefined;
  isLoadingStatements: boolean;
  isLoadingMoreStatements: boolean;
  isNotLastPage: boolean;
  shouldFetch: boolean;
  isLoadingChangeOutlet: boolean;
  allStatements: Array<MonthStatementsType>;
  statementsOfAccountsDetailsData: StatementsOfAccountsDetailsResponseType | null;
  financeAccountID: number | null;
  currentOutletName: string;
};

type ReducerActionType =
  | 'getStatementsOfAccountsAPICatch'
  | 'getStatementsOfAccountsDetailsAPI'
  | 'getStatementsOfAccountsDetailsAPICatch'
  | 'getStatementsOfAccountsDetailsAPIFinally'
  | 'setSelectedStatements'
  | 'setStartDate'
  | 'setEndDate'
  | 'setIsLoadingMoreStatements'
  | 'resetToDefault'
  | 'setAllStatements'
  | 'onApplyClearDates'
  | 'clearAllSelection'
  | 'loadMoreSuccess'
  | 'loadMoreFail'
  | 'setLoadMoreLoadingFalse'
  | 'setFinanceAccountID';

const reducer = (
  state: ReducerStateType,
  action: {
    type: ReducerActionType;
    payload?: any;
  }
): ReducerStateType => {
  switch (action.type) {
    case 'getStatementsOfAccountsAPICatch':
      return {
        ...state,
        isLoadingStatements: false,
        isLoadingMoreStatements: false,
        isLoadingChangeOutlet: false,
        currentOutletName: action.payload
      };
    case 'getStatementsOfAccountsDetailsAPI':
      const newDataFromResponse = action.payload.statements.map(
        (statement: MonthStatementsType) => ({
          ...statement,
          details: statement.details.map((item: StatementsType) => {
            return {
              ...item,
              isSelected: false
            };
          }),
          isSelected: false
        })
      );
      return {
        ...state,
        statementsOfAccountsDetailsData: action.payload,
        allStatements: newDataFromResponse,
        currentPage: state.currentPage + 1,
        shouldFetch: true
      };
    case 'getStatementsOfAccountsDetailsAPICatch':
      return {
        ...state,
        allStatements: [],
        shouldFetch: false
      };
    case 'getStatementsOfAccountsDetailsAPIFinally':
      return {
        ...state,
        isLoadingStatements: false,
        isLoadingChangeOutlet: false
      };
    case 'setAllStatements':
      return {
        ...state,
        allStatements: action.payload
      };
    case 'onApplyClearDates':
      return {
        ...state,
        isLoadingStatements: true,
        currentPage: 1,
        allStatements: [],
        selectedStatements: [],
        isNotLastPage: true,
        shouldFetch: true
      };
    case 'setStartDate':
      return {
        ...state,
        startDate: action.payload
      };
    case 'setEndDate':
      return {
        ...state,
        endDate: action.payload
      };
    case 'clearAllSelection':
      return {
        ...state,
        allStatements: state.allStatements.map(
          (statement: MonthStatementsType) => ({
            ...statement,
            details: statement.details.map(
              (monthlyStatement: StatementsType) => ({
                ...monthlyStatement,
                isSelected: false
              })
            )
          })
        ),
        selectedStatements: []
      };
    case 'setSelectedStatements':
      return {
        ...state,
        selectedStatements: action.payload
      };
    case 'setIsLoadingMoreStatements':
      return {
        ...state,
        isLoadingMoreStatements: action.payload
      };
    case 'loadMoreSuccess':
      return {
        ...state,
        allStatements: action.payload,
        currentPage: state.currentPage + 1
      };
    case 'loadMoreFail':
      return {
        ...state,
        isNotLastPage: false,
        shouldFetch: false
      };
    case 'setLoadMoreLoadingFalse':
      return {
        ...state,
        isLoadingStatements: false,
        isLoadingMoreStatements: false
      };
    case 'resetToDefault':
      return {
        ...state,
        allStatements: [],
        statementsOfAccountsDetailsData: null,
        selectedStatements: [],
        isLoadingStatements: true,
        isLoadingChangeOutlet: true,
        currentPage: 1,
        shouldFetch: false,
        currentOutletName: '',
        financeAccountID: null
      };
    case 'setFinanceAccountID':
      return {
        ...state,
        financeAccountID: action.payload
      };
    default:
      return { ...state };
  }
};

export const ViewOutletsModal = ({
  bankDetails
}: {
  bankDetails: StatementOfAccountBank;
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const openModal = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={style.modal}>
      <span>
        {size(bankDetails?.outlets_name)} {t('Outlets')}
      </span>
      <span>|</span>
      <OutOfBoundClick className={style.outOfBoundModal} onClick={closeModal}>
        <span
          className={style.viewOutletButton}
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => openModal(e)}
        >
          {t('View Outlets')}
        </span>
        <div
          className={classNames(style.modalContents, showModal && style.isOpen)}
        >
          <div>
            <p className={style.modalHeading}>
              {t('These outlets are linked to')}
            </p>
            <p className={style.modalBankAccountNumber}>
              {`${bankDetails?.bank_name} ${bankDetails?.bank_account_number}`}
            </p>
          </div>
          <hr />

          <div className={style.modalOutletNames}>
            {bankDetails?.outlets_name.map(item => {
              return <p key={item}>{item}</p>;
            })}
          </div>
        </div>
      </OutOfBoundClick>
    </div>
  );
};

const OutletStatementsReducerView = (props: { finance_account_id: number }) => {
  const { t } = useTranslation();
  const { currentCompany, currentOutlet, changeOutlet } = useContext(
    OutletContext
  );
  const cleverTap = useCleverTapOld();
  const { user } = useContext(AuthContext);
  const isJakarta = user?.city?.slug === 'jakarta';

  const jakartaTime = {
    olderStatementsDate: `1 ${t('April')} 2020`,
    showCustomContentDate: new Date('3/1/2020')
  };

  const kualaSingTime = {
    olderStatementsDate: `1 ${t('January')} 2020`,
    showCustomContentDate: new Date('12/1/2019')
  };

  const initialValues: ReducerStateType = {
    currentPage: 1,
    selectedStatements: [],
    startDate: undefined,
    endDate: undefined,
    isLoadingStatements: true,
    isLoadingMoreStatements: false,
    isNotLastPage: true,
    shouldFetch: false,
    isLoadingChangeOutlet: true,
    allStatements: [],
    statementsOfAccountsDetailsData: null,
    financeAccountID: null,
    currentOutletName: ''
  };
  const [
    {
      currentPage,
      selectedStatements,
      startDate,
      endDate,
      isLoadingStatements,
      isLoadingMoreStatements,
      isNotLastPage,
      shouldFetch,
      isLoadingChangeOutlet,
      allStatements,
      statementsOfAccountsDetailsData,
      financeAccountID,
      currentOutletName
    },
    dispatch
  ] = useReducer(reducer, initialValues);
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const screenName = 'statements_individual';

  useEffect(() => {
    if (props.finance_account_id) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }, [props.finance_account_id]);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: screenName });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed]);

  // when current outlet is all outlets
  useEffect(() => {
    if (currentCompany && props.finance_account_id) {
      dispatch({
        type: 'setFinanceAccountID',
        payload: props.finance_account_id
      });
      getStatementsOfAccountsDetails({
        company_id: currentCompany.id,
        finance_account_id: props.finance_account_id
      })
        .then(data =>
          dispatch({ type: 'getStatementsOfAccountsDetailsAPI', payload: data })
        )
        .catch(() =>
          dispatch({ type: 'getStatementsOfAccountsDetailsAPICatch' })
        )
        .finally(() =>
          dispatch({ type: 'getStatementsOfAccountsDetailsAPIFinally' })
        );
    }
  }, [currentCompany, props.finance_account_id]);

  // when current outlet is specific
  useEffect(() => {
    if (currentOutlet && currentCompany) {
      dispatch({ type: 'resetToDefault' });
      getStatementsOfAccounts({
        company_id: currentCompany.id,
        outlet_id: currentOutlet.id
      })
        .then(data => {
          const idFromResponse = data.accounts_detail[0].finance_account_id;
          dispatch({ type: 'setFinanceAccountID', payload: idFromResponse });
          getStatementsOfAccountsDetails({
            company_id: currentCompany.id,
            finance_account_id: idFromResponse
          })
            .then(data =>
              dispatch({
                type: 'getStatementsOfAccountsDetailsAPI',
                payload: data
              })
            )
            .catch(() =>
              dispatch({ type: 'getStatementsOfAccountsDetailsAPICatch' })
            )
            .finally(() =>
              dispatch({ type: 'getStatementsOfAccountsDetailsAPIFinally' })
            );
        })
        .catch(() =>
          dispatch({
            type: 'getStatementsOfAccountsAPICatch',
            payload: currentOutlet.name
          })
        );
    }
  }, [currentCompany, currentOutlet]);

  const loadMore = useCallback(() => {
    if (currentCompany && props) {
      dispatch({ type: 'setIsLoadingMoreStatements', payload: true });
      const payload = {
        company_id: currentCompany.id,
        finance_account_id: financeAccountID,
        page: currentPage,
        start_date: startDate && formatISOToLocalTime(startDate),
        end_date: endDate && formatISOToLocalTime(endDate)
      };
      getStatementsOfAccountsDetails(payload)
        .then(data => {
          if (!isEmpty(data.statements)) {
            const newDataFromResponse = data.statements.map(
              (statement: MonthStatementsType) => ({
                ...statement,
                details: statement.details.map((item: StatementsType) => {
                  return {
                    ...item,
                    isSelected: false
                  };
                }),
                isSelected: false
              })
            );

            const combinedNewWithExistingData = allStatements
              .map(item => {
                const foundSameStatementGroup = find(
                  newDataFromResponse,
                  statement => {
                    return (
                      statement.month === item.month &&
                      statement.year === item.year
                    );
                  }
                );
                if (foundSameStatementGroup) {
                  pull(newDataFromResponse, foundSameStatementGroup);
                  return {
                    ...item,
                    details: [
                      ...item.details,
                      ...foundSameStatementGroup.details
                    ],
                    isSelected: false
                  };
                } else {
                  return {
                    ...item
                  };
                }
              })
              .concat(newDataFromResponse);

            dispatch({
              type: 'loadMoreSuccess',
              payload: combinedNewWithExistingData
            });
          } else {
            dispatch({ type: 'loadMoreFail' });
          }
        })
        .then(() => {
          dispatch({ type: 'setLoadMoreLoadingFalse' });
        });
    }
  }, [
    currentCompany,
    allStatements,
    currentPage,
    endDate,
    props,
    startDate,
    financeAccountID
  ]);

  const fetchStatementsRef = useFetchDataOnScroll(
    isLoadingMoreStatements,
    isNotLastPage,
    loadMore,
    shouldFetch
  );

  const StatementsTitle = () => {
    const showBackButton = !currentOutlet;
    const backToAllStatements = () => {
      if (currentOutlet) {
        changeOutlet('');
      } else {
        navigate(`/statements`);
      }
    };

    return (
      <>
        {showBackButton && (
          <Button
            leftIcon="arrow-left"
            onClick={() => backToAllStatements()}
            type="text"
            children={t('Back')}
            className={style.backToAllStatements}
          />
        )}
        {statementsOfAccountsDetailsData ? (
          size(statementsOfAccountsDetailsData.outlets_name) === 1 ? (
            first(statementsOfAccountsDetailsData.outlets_name)
          ) : (
            <ViewOutletsModal
              bankDetails={
                (statementsOfAccountsDetailsData as unknown) as StatementOfAccountBank // none-overlapping properties are not needed. that's why unknown
              }
            />
          )
        ) : (
          currentOutletName
        )}
      </>
    );
  };

  const StatementsSubtitle = () => {
    return (
      <>
        {statementsOfAccountsDetailsData
          ? `${statementsOfAccountsDetailsData?.bank_name} ${statementsOfAccountsDetailsData?.bank_account_number}`
          : ''}
      </>
    );
  };

  const ContactPMTeamBanner = ({
    olderStatementDate
  }: {
    olderStatementDate: string;
  }) => {
    const { t } = useTranslation();
    return (
      <div className={style.ContactPMTeamBanner}>
        <Icon icon="info-circle" size="1.5rem" />

        <div className={style.InfoStyle}>{`${t(
          'Contact our PM team for any statement older than'
        )} "${olderStatementDate}"`}</div>
        <Button
          type={'primary'}
          children={t('Contact Now')}
          href="/contact-us"
          onClick={() => {
            cleverTap.tapped.push({
              screen_name: screenName,
              tapped_on: 'btn_help_contact'
            });
          }}
        />
      </div>
    );
  };

  const RenderEmptyStatements = () => (
    <div className={style.statementsEmpty}>
      {!financeAccountID ? (
        <>
          <img
            src={StatementsImage}
            className={style.statementImage}
            alt="empty statements"
          />
          <span className={style.statementsEmptyData}>
            {t("There's nothing here yet, check back another time.")}
          </span>
        </>
      ) : (
        <>
          <img
            src={NoBankAccountIcon}
            className={style.noBankAccountIcon}
            alt="no bank account"
          />
          <div className={style.noBankAccountText}>
            <p>{t('You have no payment statement')}</p>
            <span>
              {t(
                'Whenever a payment is made to your bank account, a Statement of Account (SOA) will be sent to your email.'
              )}
            </span>
          </div>
        </>
      )}
    </div>
  );

  const selectStatement = (statementObject: {
    reference_no?: string;
    month: number;
    year: number;
    journal_id?: number;
    isSelected?: boolean;
  }) => {
    const {
      reference_no,
      month,
      year,
      journal_id,
      isSelected
    } = statementObject;

    // if no reference_no, means user clicked select all
    const selectedItems: number[] = selectedStatements;
    if (!isSelected) {
      journal_id && selectedItems.push(journal_id);
      dispatch({ type: 'setSelectedStatements', payload: uniq(selectedItems) });
    } else {
      journal_id &&
        dispatch({
          type: 'setSelectedStatements',
          payload: pull(selectedItems, journal_id)
        });
    }
    const newArray = allStatements.map((statement: MonthStatementsType) => {
      if (statement.month === month && statement.year === year) {
        if (!reference_no) {
          return {
            ...statement,
            details: statement.details.map(
              (monthlyStatement: StatementsType) => {
                if (monthIsAllSelected({ month, year })) {
                  dispatch({
                    type: 'setSelectedStatements',
                    payload: pull(selectedItems, monthlyStatement.journal_id)
                  });
                }
                return {
                  ...monthlyStatement,
                  isSelected: !monthIsAllSelected({ month, year })
                };
              }
            )
          };
        } else {
          return {
            ...statement,
            details: statement.details.map(
              (monthlyStatement: StatementsType) => {
                if (selectedItems.includes(monthlyStatement.journal_id)) {
                  return {
                    ...monthlyStatement,
                    isSelected: true
                  };
                } else {
                  return {
                    ...monthlyStatement,
                    isSelected: false
                  };
                }
              }
            )
          };
        }
      } else {
        return statement;
      }
    });
    dispatch({ type: 'setAllStatements', payload: newArray });
    const newSelectedItemsJournalIds = () => {
      const array = [];
      for (let index = 0; index < newArray.length; index++) {
        for (
          let innerIndex = 0;
          innerIndex < newArray[index].details.length;
          innerIndex++
        ) {
          if (newArray[index].details[innerIndex].isSelected) {
            array.push(newArray[index].details[innerIndex].journal_id);
          }
        }
      }
      return array;
    };

    dispatch({
      type: 'setSelectedStatements',
      payload: compact(uniq(selectedItems.concat(newSelectedItemsJournalIds())))
    });
  };

  const monthIsAllSelected = ({
    month,
    year
  }: {
    month: number;
    year: number;
  }) => {
    return allStatements
      .find(
        (statements: MonthStatementsType) =>
          statements.month === month && statements.year === year
      )
      ?.details.every(data => data.isSelected);
  };

  const StatementsByMonthComponent = ({
    month,
    year,
    details: allStatements
  }: MonthStatementsType) => {
    const showOldBanner = ({
      month,
      year
    }: {
      month: number;
      year: number;
    }) => {
      return isJakarta
        ? month === MONTHNUMBER.Apr && year === 2020
        : month === MONTHNUMBER.Jan && year === 2020;
    };

    return (
      <div className={style.MonthStatement}>
        <h3>{`${t(MONTHSARRAY[month - 1])} ${year}`}</h3>
        <div className={style.StatementsHeader}>
          <Checkbox
            className={style.CheckBox}
            id={`select_all_${t(MONTHSARRAY[month - 1])}_${year}`}
            name={`select_all_${t(MONTHSARRAY[month - 1])}_${year}`}
            value={`select_all_${t(MONTHSARRAY[month - 1])}_${year}`}
            checked={monthIsAllSelected({ month, year })}
            disabled={false}
            type={'green'}
            onChange={() => {
              cleverTap.tapped.push({
                screen_name: screenName,
                tapped_on: 'btn_select_all'
              });
              selectStatement({ month, year });
            }}
          />
          <div
            className={classNames(
              style.StatementsDataSpace,
              style.referenceNumberColumn
            )}
          >
            {t('Reference Numbers')}
          </div>
          <div
            className={classNames(
              style.StatementsDataSpace,
              style.statementDateRangeColumn
            )}
          >
            {t('Statement Date Range')}
          </div>
          <div
            className={classNames(
              style.StatementsDataSpace,
              style.statementIssueDateColumn
            )}
          >
            {t('Statement Issue Date')}
          </div>
          <div
            className={classNames(
              style.StatementsDataSpace,
              style.amountColumnColumn
            )}
          >
            {t('Amount')}
          </div>
          <div
            className={classNames(
              style.StatementsDataSpace,
              style.actionButtonColumn
            )}
          />
        </div>
        {allStatements.map(
          ({
            reference_no,
            transaction_count,
            isSelected,
            journal_id,
            period,
            formatted_amount,
            formatted_issued_date,
            amount
          }) => (
            <div
              key={reference_no}
              className={
                isSelected
                  ? style.SelectedStatementsSpace
                  : style.StatementsSpace
              }
            >
              <Checkbox
                className={style.CheckBox}
                id={reference_no}
                name={reference_no}
                value={reference_no}
                checked={isSelected}
                disabled={false}
                type={'green'}
                onChange={() => {
                  cleverTap.tapped.push({
                    screen_name: screenName,
                    tapped_on: 'btn_select'
                  });
                  selectStatement({
                    reference_no,
                    month,
                    year,
                    journal_id,
                    isSelected
                  });
                }}
              />
              <div
                className={classNames(
                  style.StatementsDataSpace,
                  style.referenceNumberColumn,
                  style.ReferenceNumber
                )}
              >
                <p className={style.ReferenceNumberStyle}>{t(reference_no)}</p>
                <p className={style.NumberOfTransaction}>
                  {`${transaction_count > 0 ? transaction_count : 0} ${t(
                    'Transactions'
                  )}`}
                </p>
              </div>
              <div
                className={classNames(
                  style.StatementsDataSpace,
                  style.statementDateRangeColumn
                )}
              >
                {period}
              </div>
              <div
                className={classNames(
                  style.StatementsDataSpace,
                  style.statementIssueDateColumn
                )}
              >
                {formatted_issued_date}
              </div>
              <div
                className={classNames(
                  style.StatementsDataSpace,
                  style.amountColumnColumn,
                  amount < 0
                    ? style.PriceRed
                    : amount === 0
                    ? style.PriceGrey
                    : style.Price
                )}
              >
                {amount > 0 ? formatted_amount : t('No Transaction')}
              </div>
              <div className={style.actionButtonColumn}>
                <BulkActionsDropDown
                  selectedStatements={[journal_id]}
                  buttonChildren={<Icon icon="more" size="1.5rem" />}
                  buttonType={'text'}
                  canViewStatement
                />
              </div>
            </div>
          )
        )}
        {showOldBanner({ month, year }) && (
          <ContactPMTeamBanner
            olderStatementDate={
              isJakarta
                ? jakartaTime.olderStatementsDate
                : kualaSingTime.olderStatementsDate
            }
          />
        )}
      </div>
    );
  };

  const isMoreThanOneSelectedStatement = selectedStatements.length >= 2;

  return (
    <AppLayout>
      {!isLoadingChangeOutlet && (
        <div className={style.OutletStatementsViewHeading}>
          <Heading
            title={<StatementsTitle />}
            subtitle={<StatementsSubtitle />}
            className={style.Heading}
          >
            <div className={style.ButtonBar}>
              <div className={style.datePickerAndSelectionContainer}>
                {statementsOfAccountsDetailsData && (
                  <DatePicker
                    startDate={startDate ? startDate : new Date()}
                    endDate={endDate}
                    setStartDate={date =>
                      dispatch({ type: 'setStartDate', payload: date })
                    }
                    setEndDate={date => {
                      dispatch({ type: 'setEndDate', payload: date });
                      dispatch({ type: 'onApplyClearDates' });
                    }}
                    screenName={screenName}
                    showApplyClearButtons={true}
                    disableFutureDates={true}
                    CustomContent={
                      <ContactPMTeamBanner
                        olderStatementDate={
                          isJakarta
                            ? jakartaTime.olderStatementsDate
                            : kualaSingTime.olderStatementsDate
                        }
                      />
                    }
                    dateToShowCustomContent={
                      isJakarta
                        ? jakartaTime.showCustomContentDate
                        : kualaSingTime.showCustomContentDate
                    }
                    dateToHideLeftButton={
                      isJakarta
                        ? jakartaTime.showCustomContentDate
                        : kualaSingTime.showCustomContentDate
                    }
                  >
                    {(_, trigger) => (
                      <DateInputBadge
                        startDate={startDate}
                        endDate={endDate}
                        onClick={trigger}
                      />
                    )}
                  </DatePicker>
                )}
                {isMoreThanOneSelectedStatement ? (
                  <div className={style.BulkActions}>
                    <Button
                      type={'text'}
                      onClick={() => dispatch({ type: 'clearAllSelection' })}
                    >
                      {t('Clear Selections')}
                    </Button>
                    <BulkActionsDropDown
                      selectedStatements={selectedStatements}
                      buttonChildren={`${t('Bulk Actions')}(${
                        selectedStatements.length
                      })`}
                      buttonClassName={`${style.BulkActionButton}`}
                      buttonType={'primary'}
                      canViewStatement={false}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Heading>
        </div>
      )}
      <div className={style.Statements}>
        {isLoadingStatements ? (
          <LoadingSpinner />
        ) : isEmpty(allStatements) ? (
          <RenderEmptyStatements />
        ) : (
          allStatements.map(statementData => {
            return (
              <StatementsByMonthComponent
                key={`${statementData.month} ${statementData.year}`}
                details={statementData.details}
                month={statementData.month}
                year={statementData.year}
              />
            );
          })
        )}
        {!isLoadingStatements && isLoadingMoreStatements && (
          <LoadingSpinnerSmall className={style.loadingSpinnerLoadMore} />
        )}
        <div ref={fetchStatementsRef} />
      </div>
    </AppLayout>
  );
};

export default OutletStatementsReducerView;
