import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { CloseButton } from 'components/Button';
import styles from 'assets/css/Modal.module.scss';

const enableScroll = () => {
  document.documentElement.classList.remove('no-scroll');
};

const disableScroll = () => {
  document.documentElement.classList.add('no-scroll');
};

export type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  hasCloseButton?: boolean;
  closeOnBlur?: boolean;
  className?: string;
  onTransitionEnd?: (event: React.TransitionEvent<HTMLDivElement>) => void;
};

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen = false,
  onClose = () => {},
  hasCloseButton = true,
  closeOnBlur = false,
  className,
  onTransitionEnd
}) => {
  const modalRef = useRef<HTMLElement>(null);

  useEffect(() => {
    isOpen ? disableScroll() : enableScroll();
    return enableScroll;
  }, [isOpen]);

  return (
    <section
      ref={modalRef}
      onClick={e => {
        const isClickSelf =
          modalRef.current &&
          modalRef.current.contains(e.currentTarget) &&
          modalRef.current !== e.target;
        if (isClickSelf) return;
        closeOnBlur && onClose();
      }}
      className={classNames(styles.overlay, isOpen && styles.isOpen)}
    >
      <div
        className={classNames(styles.container, className)}
        onTransitionEnd={onTransitionEnd}
      >
        {hasCloseButton && (
          <CloseButton
            className={classNames(styles.button, styles.closeButton)}
            onClick={onClose}
          />
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </section>
  );
};

export default Modal;
