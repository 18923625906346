import Kfit from '../integrations/Kfit';

const getOpsSettings = partnerCode =>
  Kfit.get(
    `/v5/settings`,
    {},
    { headers: { 'X-Partner-Code': partnerCode } }
  ).then(res => res);

export default getOpsSettings;
