import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from 'assets/css/Alert.module.scss';

/**
 * add more types as needed
 */
type AlertTypes = 'info';

export type AlertProps = {
  children: ReactNode;
  className?: string;
  type: AlertTypes;
};

export const Alert: React.FC<AlertProps> = ({
  children,
  className,
  type = 'info'
}) => (
    <div
      role="alert"
      className={classNames(styles.alert, getClassName(type), className)}
    >
      <div>{children}</div>
    </div>
  );

function getClassName(type: AlertTypes) {
  switch (type) {
    case 'info':
      return styles.alertInfo;
  }
}
