import React from 'react';
import Button from 'components/Button';
import PropTypes from 'prop-types';

import styles from 'assets/css/FavePaySetupView/StepCTASection.module.scss';

function StepCTASection({ onSubmit, submitText, formValid, ...props }) {
  return (
    <section className={styles.layout}>
      <Button
        className={styles.nextButton}
        type="submit"
        size="lg"
        rightIcon="arrow-right"
        justify="space-between"
        onClick={onSubmit}
        disabled={!formValid}
      >
        {submitText}
      </Button>
      <section className={styles.actionsBelowCTA}>{props.children}</section>
    </section>
  );
}

StepCTASection.propTypes = {
  onSubmit: PropTypes.func,
  submitText: PropTypes.string.isRequired,
  formValid: PropTypes.bool
};

StepCTASection.defaultProps = {
  onSubmit: () => {},
  formValid: true
};

export default StepCTASection;
