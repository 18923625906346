import useSWR from 'hooks/useSWR';

export default ({ currentOutlet = {}, type, date }, swrOptions) =>
  useSWR({
    url: `/v1/revenues/total`,
    params: {
      outlet_ids: currentOutlet.id,
      type,
      start_datetime: date,
      end_datetime: date
    },
    swrOptions
  });
