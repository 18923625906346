import DatePicker, { DateInputBox } from 'components/DatePicker';
import { useFormikContext } from 'formik';
import { formatDateAndTimeWithSlashes } from 'helpers/dateUtility';

import React from 'react';

export interface DateTimePickerProps<T> {
  name: keyof T;
  screenName: string;
  className?: string;
  disabled?: (values: T) => boolean;
  isDayDisabled?: (date: Date, values: T) => boolean;
  onChanged: (
    value: Date,
    values: T,
    setFieldValue: (field: string, value: any) => void // must be any to allow multiple calls for different types. It is also set as 'any' in formik which makes sense
  ) => void;
  isModal?: boolean;
}

const DateTimePicker = <T extends {}>({
  name,
  screenName,
  className,
  disabled,
  onChanged,
  isModal = false,
  isDayDisabled
}: DateTimePickerProps<T>) => {
  const { values, setFieldValue } = useFormikContext<T>();

  const date = (values[name] as unknown) as Date;

  const isDisabled = disabled ? disabled(values) : false;

  const setSelectedDate = (selectedDate: Date) => {
    setFieldValue(name as string, selectedDate);
    onChanged(selectedDate, values, setFieldValue);
  };

  return (
    <DatePicker
      screenName={screenName}
      className={className}
      disabled={isDisabled}
      startDate={date}
      setStartDate={date => date && setSelectedDate(date)}
      isModal={isModal}
      isDayDisabled={
        isDayDisabled ? (day: Date) => isDayDisabled(day, values) : undefined
      }
      showTimeSelection={true}
    >
      {(_, trigger) => (
        <DateInputBox
          startDate={date}
          onClick={() => trigger()}
          customFormat={formatDateAndTimeWithSlashes(date)}
        />
      )}
    </DatePicker>
  );
};

export default DateTimePicker;
