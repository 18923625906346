import React from 'react';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import Button from 'components/Button';

import styles from 'assets/css/WelcomeModal.module.scss';

type WelcomeModalProps = {
  onClose: () => void;
  outletContext: {
    changeCompany: (companyID: number, outletID: number) => void;
    changeOutlet: (id: number) => void;
    currentCompany: object;
    currentOutlet: object;
    outlets: Array<object>;
  };
  city?: 'kuala-lumpur' | 'jakarta' | 'singapore';
};

const countryBannerImage = {
  singapore: require('assets/images/welcome/banner-feature-favepay-accept-one-percent.png'),
  jakarta: require('assets/images/welcome/banner-feature-favepay-accept-one-percent.png'),
  'kuala-lumpur': require('assets/images/welcome/banner-feature-favepay-accept-one-percent.png')
};

function WelcomeModal({ onClose, outletContext, city }: WelcomeModalProps) {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();

  return (
    <div className={styles.layout}>
      <span className={styles.title}>{t('Welcome to FaveBiz!')}</span>

      <section className={styles.featureLayout}>
        {city && (
          <img
            className={styles.bannerImage}
            src={countryBannerImage[city]}
            alt="FavePay"
          />
        )}
        <span className={styles.subtitle}>
          {t('Set up Payments for your business')}
        </span>
        <span className={styles.description}>
          {t(
            'Only 1% Fave Fee when you offer 10% cashback (or more) to your customers.'
          )}
        </span>
      </section>

      <section className={styles.featureLayout}>
        <img
          className={styles.bannerImage}
          src={require('assets/images/welcome/img-banner-favedeals@3x.png')}
          alt="FaveDeals"
        />
        <span className={styles.subtitle}>
          {t('Acquire new customers with Deals')}
        </span>
        <span className={styles.description}>
          {t(
            'Advertise your deals and promotions to millions of users on Fave.'
          )}
        </span>
      </section>
      <Button
        className={classNames(styles.button, styles.welcomeButton)}
        type="primary"
        rightIcon="angle-right"
        iconColor="white"
        iconSize="1.5rem"
        textColor="white"
        onClick={() => {
          cleverTap.tapped.push({ tapped_on: 'btn_started', outletContext });
          onClose();
          navigate('more-services');
        }}
      >
        {t('Get started')}
      </Button>
    </div>
  );
}

export default WelcomeModal;
