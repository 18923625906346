import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { NATIVE, call } from 'utils/nativeInterface';
import { WebviewContext } from 'contexts/WebviewContext';
import useECardModal from 'hooks/useECardModal';
import useCleverTapOld from 'hooks/useCleverTapOld';

import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from 'assets/css/CostStructure.module.scss';
import routesMap from 'helpers/routesMap';

type CostStructureProps = {
  currency: string;
  originalPrice?: string | number;
  sellingPrice?: string | number;
  discountRate?: string | number;
  commissionRate?: string | number;
  gstCommission?: string | number;
  takebackProfit?: string | number;
  outletNo?: number;
  isSetup?: boolean;
  isSingapore?: boolean;
  validity?: string | number;
  title?: string;
  sold?: string | number;
  revenue?: string | number;
  showDisbursementMethod?: boolean;
  className?: string;
  commissionEnabled: boolean;
};

const CostStructure = ({
  title = undefined,
  currency,
  originalPrice,
  sellingPrice,
  discountRate,
  commissionRate,
  gstCommission,
  takebackProfit,
  outletNo = 1,
  className,
  isSingapore = false,
  isSetup = false,
  commissionEnabled,
  validity = 180,
  sold = undefined,
  revenue = undefined,
  showDisbursementMethod = false
}: CostStructureProps) => {
  const { t } = useTranslation();
  const { isWebview } = useContext(WebviewContext);

  const { modals, openECardModal } = useECardModal({
    discountRate,
    originalPrice,
    currency,
    sellingPrice,
    commission: commissionRate,
    isSingapore,
    isSetup
  } as any); // temporary before useECardModal can be typed TODO: useECardModal

  const cleverTap = useCleverTapOld();

  return (
    <div className={classNames(styles.container, className)}>
      <h3 className={styles.title}>{title || t('Cost structure')}</h3>
      <div className={styles.table}>
        <div className={styles.row}>
          <span>
            {t('Original Price')} ({currency})
          </span>
          <span>{originalPrice}</span>
        </div>
        <div className={styles.row}>
          <span>
            {t('Selling Price')} ({currency})
          </span>
          <span>{sellingPrice}</span>
        </div>
        <div className={classNames(styles.row, styles.withBottomBorder)}>
          <span>{t('Discount Rate')}</span>
          <span>{discountRate}%</span>
        </div>
        <div className={styles.withBottomBorder}>
          <div className={styles.row}>
            <div className={styles.withIcon}>
              <span>{t('Commission rate')}</span>
              <Button
                type="ghost"
                rightIcon="deals-info"
                iconColor="bali-hai"
                onClick={() => {
                  // setup uses commissionEnabled but other pages checks for commissionRate = 0
                  commissionEnabled || commissionRate === 0
                    ? openECardModal({ modal: modals.commissionRate })
                    : openECardModal({ modal: modals.commissionRateWaived });
                }}
                children=""
              />
            </div>
            <span
              className={!commissionEnabled && isSetup ? styles.strike : ''}
            >
              {commissionRate}%
            </span>
          </div>
          {gstCommission !== 0 && (
            <div className={styles.row}>
              <span>{t('GST on commission')}</span>
              <span>{gstCommission}%</span>
            </div>
          )}
        </div>
        {(sold !== undefined || revenue !== undefined) && (
          <div className={styles.withBottomBorder}>
            <div className={styles.row}>
              <span>{t('No. of eCards sold')}</span>
              <span>{sold}</span>
            </div>
            <div className={styles.row}>
              <span>
                {t('Revenue')} ({currency})
              </span>
              <span>{revenue}</span>
            </div>
          </div>
        )}
        <div className={styles.takeback}>
          <div className={styles.withIcon}>
            <span>
              {t('Takeback profit')} ({currency})
            </span>
            <Button
              type="ghost"
              rightIcon="deals-info"
              iconColor="bali-hai"
              onClick={() => openECardModal({ modal: modals.takebackProfit })}
              children=""
            />
          </div>
          <span>{takebackProfit}</span>
        </div>
      </div>

      {showDisbursementMethod && (
        <div>
          <h3 className={styles.info}>{t('Disbursement method')}</h3>
          <div className={styles.term}>
            <Icon icon="coin" size="1.5rem" />
            <span>{t('Payment upon purchase')}</span>
            <Button
              type="ghost"
              rightIcon="deals-info"
              iconColor="bali-hai"
              onClick={() => {
                isSetup &&
                  cleverTap.tapped.push({
                    screen_name: 'ecard_setup2_presubmission',
                    section_name: 'tooltip',
                    tapped_on: 'tooltip_payment_upon_purchase'
                  });
                openECardModal({ modal: modals.paymentUponPurchase });
              }}
              children=""
            />
          </div>
          <Button
            className={styles.faq}
            leftIcon="question-bubble"
            rightIcon="angle-right"
            iconColor="pelorous"
            iconSize="1rem"
            onClick={() => {
              isSetup &&
                cleverTap.tapped.push({
                  screen_name: 'ecard_setup2_presubmission',
                  tapped_on: 'btn_looking_other_option'
                });
              openECardModal({ modal: modals.contactUs });
            }}
          >
            <div>
              <span>{t('Looking for other options?')}</span>
              <span>
                {t('Reach out to find out about payment upon utilisation')}
              </span>
            </div>
          </Button>
        </div>
      )}

      <div>
        <h3 className={styles.info}>{t('Additional info')}</h3>
        <div className={styles.term}>
          <Icon icon="calendar" size="1.5rem" />
          <span>
            {t('Valid for {{validity}} days from purchase', { validity })}
          </span>
        </div>
        <div className={styles.term}>
          <Icon icon="outlet" size="1.5rem" />
          <span>{t(`Available in {{outletNo}} outlet(s)`, { outletNo })}</span>
        </div>
      </div>
      <Button
        className={styles.faq}
        leftIcon="question-bubble"
        rightIcon="angle-right"
        iconColor="pelorous"
        iconSize="1rem"
        onClick={() => {
          isSetup &&
            cleverTap.tapped.push({
              screen_name: 'ecard_setup2_presubmission',
              tapped_on: 'btn_ecard_faq'
            });

          if (isWebview) {
            return call(NATIVE.ECARDS_FAQ);
          }
          return window.open(routesMap.eCardsFAQ, '_blank');
        }}
      >
        <div>
          <span>{t('Have more questions?')}</span>
          <span>{t('Take a look at our FAQ')}</span>
        </div>
      </Button>
    </div>
  );
};

export default CostStructure;
