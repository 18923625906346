import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';

import useCleverTapOld from 'hooks/useCleverTapOld';
import Button from 'components/Button';
import styles from 'assets/css/SidebarAccordionItem.module.scss';
import BetaLabel from './BetaLabel';

export type SublinksProps = {
  subtitle: string;
  path: string;
  tap: boolean;
  event: string;
  enabled?: boolean;
  isBeta?: boolean;
};

export type LinkProps = {
  title: string;
  icon: string;
  path?: string;
  tap?: boolean;
  enabled?: boolean;
  event?: string;
  sublinks?: SublinksProps[];
};

type SidebarAccordionItemProps = {
  isOpen?: boolean; // for open and closing panel
  isHighlighted: boolean; // highlight header in the opened panel
  onHeaderClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  link: LinkProps;
  accordionItemClassName?: string;
};

function SidebarAccordionItem(props: SidebarAccordionItemProps) {
  const {
    onHeaderClick = () => {},
    isOpen = false,
    isHighlighted = false,
    link,
    accordionItemClassName
  } = props;
  const CleverTap = useCleverTapOld();
  const sublinks = (link.sublinks || []).filter(c => c.enabled !== false);
  const hasSubmenu = sublinks.length > 0;

  return (
    <div className={styles.accordion}>
      <div
        className={classNames(
          styles.header,
          isHighlighted && styles.highlighted,
          hasSubmenu && (isOpen ? styles.caretDown : styles.caretRight),
          accordionItemClassName
        )}
        onClick={e => {
          if (link.tap && !isHighlighted)
            CleverTap.tapped.push({
              screen_name: 'navigation_bar',
              section_name: 'bar_side',
              tapped_on: link.event
            });
          onHeaderClick(e);
        }}
      >
        <span
          style={{ '--icon': `var(--icon-${link.icon})` } as CSSProperties}
          className={styles.icon}
        />
        {link.title}
        {hasSubmenu && (
          <span className={classNames(styles.icon, styles.caret)} />
        )}
      </div>
      <AnimateHeight duration={450} height={isOpen ? 'auto' : 0}>
        {sublinks.map(({ subtitle, path, tap, event, isBeta }) => (
          <Button
            key={path}
            {...{ type: 'text', href: path, size: 'lg', fullWidth: true }}
            className={styles.button}
            onClick={e => {
              if (
                tap &&
                !(
                  (e.target as HTMLElement).getAttribute('aria-current') ===
                  'page'
                )
              ) {
                CleverTap.tapped.push({
                  screen_name: 'navigation_bar',
                  section_name: 'bar_side',
                  tapped_on: `${event}`
                });
              }
            }}
          >
            {subtitle}
            {isBeta && (
              <>
                {/* double space as requested */}
                &nbsp;&nbsp;
                <BetaLabel />
              </>
            )}
          </Button>
        ))}
      </AnimateHeight>
    </div>
  );
}

export default SidebarAccordionItem;
