const zendeskBaseURL = 'https://favepartner.zendesk.com/hc/en-us';
const zendeskCategoryBaseURL = `${zendeskBaseURL}/categories`;
const zendeskArticleBaseURL = `${zendeskBaseURL}/articles`;

export default {
  reviews: '/reviews',
  moreServices: '/more-services',
  eCardsFAQ: `${zendeskCategoryBaseURL}/9305797563289-Ecard`,
  sideBarFAQ: zendeskBaseURL,
  faveFeedFAQ: `${zendeskArticleBaseURL}/9155941756953-Fave-Feed`,
  businessDetailsFAQ: `${zendeskCategoryBaseURL}/9153014550041-Favebiz`
};
