import React from 'react';
import Card, { ICardProps } from 'components/Card';
import Icon from 'components/Icon';

import styles from 'assets/css/AddNewCard.module.scss';

interface IAddNewCardProps extends ICardProps {
  text: string;
}

const AddNewCard: React.FC<IAddNewCardProps> = ({ onClick, text }) => {
  return (
    <Card className={styles.newCardContainer} onClick={onClick}>
      <Icon icon={'plus'} color={'apple-green'} size={'2.9875rem'} />
      <div>{text}</div>
    </Card>
  );
};

export default AddNewCard;
