import TransactionDetail from 'components/TransactionDetail';
import React from 'react';
import useGetOffMapleTransactionDetail from 'queries/useGetOffMapleTransactionDetail';

const OffMapleTransactionDetailView = props => (
  <TransactionDetail
    {...props}
    type="External Maple Transaction"
    responseProp="maple_external_transaction"
    useTransactionDetails={useGetOffMapleTransactionDetail}
  />
);
export default OffMapleTransactionDetailView;
