import React, { ReactNode } from 'react';
import classNames from 'classnames';

import Styles from '../assets/css/Heading.module.scss';

type HeadingProps = {
  title: ReactNode;
  subtitle: ReactNode;
  iconElement?: ReactNode;
  className?: string;
};

const Heading: React.FC<HeadingProps> = props => (
  <header
    className={classNames(
      Styles.Wrapper,
      props.children && Styles.divide,
      props.className
    )}
  >
    <div className={Styles.titleContainer}>
      <h2 className={Styles.Title}>{props.title}</h2>
      {props.iconElement && props.iconElement}
    </div>
    {props.subtitle && <p className={Styles.Subtitle}>{props.subtitle}</p>}
    {props.children}
  </header>
);

export default Heading;
