import React, { useContext, useEffect } from 'react';
import { Router, Link, Redirect } from '@reach/router';

import { OpsAuthContext } from 'contexts/OpsAuthContext';
import TransactionsView from 'views/TransactionsView';
import FavePayDetailView from 'views/FavePayDetailView';
import FaveLoyaltyProgramDetailView from 'views/FaveLoyaltyProgramDetailView';
import RealTimeRetailPaymentsPlatformDetailView from 'views/RealTimeRetailPaymentsPlatformDetailView';
import SwitchLanguageView from 'views/SwitchLanguageView';
import ReceivePaymentView from 'views/ReceivePaymentView';
import VoucherRedemptionView from 'views/VoucherRedemptionView';
import VoucherRedemptionDetailsView from 'views/VoucherRedemptionDetailsView';
import AlipayView from 'views/AlipayView';
import AlipayDetailView from 'views/AlipayDetailView';
import ContactUsView from 'views/ContactUsView';
import OffMapleTransactionDetailView from 'views/OffMapleTransactionDetailView';
import MapleTransactionDetailView from 'views/MapleTransactionDetailView';
import ReportView from 'views/ReportView';
import useChat from 'hooks/useChat';

const NotFound = ({ back }) => (
  <main>
    <h1>
      <span role="img" aria-label="No entry sign">
        🚫&nbsp;
      </span>
      Oops, this place does not exist...at all
    </h1>
    <Link to={back || '/'}>Click here to go back</Link>
  </main>
);

export default () => {
  const {
    isValidOps,
    isAuthenticatingOps,
    isValidUser,
    isIndonesia
  } = useContext(OpsAuthContext);
  const { showWidgetButton, hideWidgetButton } = useChat();

  const isValidOrAuthenticated = isValidOps || isAuthenticatingOps;

  useEffect(() => {
    // wait until the widget is loaded
    setTimeout(() => {
      if (isValidUser && !isIndonesia) showWidgetButton();
      else hideWidgetButton();
    }, 1600);
  }, [isValidUser, isIndonesia, showWidgetButton, hideWidgetButton]);

  if (!isValidOrAuthenticated) {
    window.location.assign('/');
    return <></>;
  }

  return (
    <Router>
      <TransactionsView path="operations/transactions" />
      <FavePayDetailView path="operations/transactions/:id" />
      <FaveLoyaltyProgramDetailView path="operations/transactions/flp/:id" />
      <RealTimeRetailPaymentsPlatformDetailView path="operations/transactions/rpp/:id" />
      <OffMapleTransactionDetailView path="operations/transactions/off-maple/:id" />
      <MapleTransactionDetailView path="operations/transactions/maple/:id" />
      <VoucherRedemptionView path="operations/redemption" />
      <VoucherRedemptionDetailsView path="operations/redemption/:code" />
      <AlipayView path="operations/transactions/alipay" />
      <AlipayDetailView path="operations/transactions/alipay/:id" />
      <SwitchLanguageView path="switch-language" />
      <ReceivePaymentView path="operations/payment" />
      <ContactUsView path="contact-us" />
      <ReportView path="operations/reports" />
      <Redirect from="/" to="operations/transactions" noThrow />
      <NotFound back="/" default />
    </Router>
  );
};
