import 'intersection-observer';
import { isInternetExplorer } from 'utils';
import * as serviceWorker from 'serviceWorker';
import { init } from 'utils/zendesk.chat.client';
import { isAppMapleBiz } from 'contexts/MapleContext';
import Cookie from 'helpers/Cookie';

// disable console function in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.group = () => {};
  console.groupEnd = () => {};
  console.warn = () => {};
}

const isWebView =
  new URLSearchParams(window.location.search).get('app') === 'true' ||
  Cookie.get('isWebview', false);

const rootEl = document.getElementById('root');

if (!isWebView && !isAppMapleBiz) init();

if (isInternetExplorer()) {
  const renderErrorPage = require('./NoApp').default;
  renderErrorPage(rootEl);
} else {
  serviceWorker.register();

  if (window.location.href.includes('operations')) {
    const renderReactApp = require('./Ops').default;
    renderReactApp(rootEl);
  } else if (window.location.pathname === '/transactions') {
    window.location.assign(
      '/operations/transactions' + window.location.search + '&old=true'
    );
  } else if (window.location.href.includes('insights')) {
    const renderReactApp = require('./Public').default;
    renderReactApp(rootEl);
  } else {
    const renderReactApp = require('./App').default;
    renderReactApp(rootEl);
  }
}
