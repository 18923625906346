import Kfit from '../integrations/Kfit';
import DataLoader from 'dataloader';

export const Repo = new DataLoader(
  keys =>
    Promise.all(
      keys.map(key =>
        Kfit.get(key, undefined, { endpoint: Kfit.ENDPOINTS().consumer })
      )
    ),
  {
    batch: false,
    cacheKeyFn: key => JSON.stringify(key)
  }
);

function loadInfo(key, reload) {
  if (reload) Repo.clearAll();
  return Repo.load(key);
}

export default ({ citySlug, query }) =>
  loadInfo(`/v3/cities/${citySlug}/places/autocomplete?query=${query}`);
