import React, { useEffect, useState, useContext, useCallback } from 'react';
import { navigate, useLocation } from '@reach/router';
import { AuthContext } from 'contexts/AuthContext';
import SidebarAccordionItem, {
  LinkProps
} from 'components/SidebarAccordionItem';
import { OutletContext } from 'contexts/OutletContext';
import { useTranslation } from 'react-i18next';
import { MapleContext } from 'contexts/MapleContext';
import routesMap from 'helpers/routesMap';
import { CLEVERTAP_TAPPED_ON } from 'helpers/TypeConstants';

type AdditionalLinkProps = LinkProps & {
  className?: string;
  isExternalLink?: boolean;
};

const rootPath = '/';
const kringlePath = process.env.REACT_APP_KRINGLE_PATH;

const SideBarNav = () => {
  const { t } = useTranslation();
  const { user, isValidUser } = useContext(AuthContext);
  const { isMaple } = useContext(MapleContext);

  const { currentCompany: company = {}, outlets = [] } = useContext(
    OutletContext
  );

  const isNotIndonesia = !(isValidUser && user.city.slug === 'jakarta');
  const isFavePayActive = outlets.some(
    (outlet: any) => outlet.has_fave_payment
  );

  const isCashbackActive = !outlets.every(
    ({
      fave_payment_outlet_detail: { cashback_rate }
    }: {
      fave_payment_outlet_detail: { cashback_rate: number };
    }) => cashback_rate === null || cashback_rate === 0
  );

  const links: LinkProps[] = React.useMemo(
    () => [
      {
        title: t('Home'),
        icon: 'home',
        path: rootPath,
        tap: true,
        event: 'btn_home'
      },
      {
        title: t('Sales'),
        icon: 'money-bag',
        className: 'sales-navigation-button',
        sublinks: [
          {
            subtitle: isMaple ? t('Summary') : t('Data'),
            path: isMaple ? `${rootPath}summary` : `${rootPath}data`,
            tap: true,
            event: isMaple ? 'btn_summary' : 'btn_data'
          },
          {
            subtitle: t('Reports'),
            path: `${rootPath}reports`,
            tap: true,
            event: 'btn_reports'
          },
          {
            subtitle: t('Statements'),
            path: `${rootPath}statements`,
            tap: true,
            event: 'btn_statements',
            enabled: !isMaple
          },
          {
            subtitle: t('Tax Invoice'),
            path: `${rootPath}invoices`,
            tap: true,
            event: CLEVERTAP_TAPPED_ON.BUTTON_TAX_INVOICE,
            enabled: !isMaple
          },
          {
            subtitle: t('Transactions'),
            path: `${rootPath}operations/transactions`,
            tap: true,
            event: 'btn_transaction',
            enabled: isMaple
          }
        ]
      },
      {
        title: t('My Services'),
        icon: 'grip-vertical',
        sublinks: [
          {
            subtitle: 'FavePay',
            path: `${rootPath}favepay`,
            tap: true,
            enabled: isFavePayActive,
            event: 'btn_explore_favepay'
          },
          {
            subtitle: 'FaveDeals',
            path: `${rootPath}deals`,
            tap: true,
            enabled: company.deals_interest || company.has_deals,
            event: 'btn_explore_favedeals'
          },
          {
            subtitle: t('Cashback'),
            path: `${rootPath}cashback`,
            tap: true,
            enabled: isCashbackActive,
            event: 'btn_explore_cashback'
          },
          {
            subtitle: t('eCards'),
            path: `${rootPath}ecards`,
            tap: true,
            enabled: company.has_e_cards,
            event: 'btn_explore_ecard'
          },
          {
            subtitle: t('More Services'),
            path: `${rootPath}more-services`,
            tap: true,
            enabled: isNotIndonesia,
            event: 'btn_moreservices'
          }
        ],
        enabled: !isMaple
      },
      {
        title: t('Customer Tools'),
        icon: 'headphone',
        className: 'customer-tools-navigation-button',
        sublinks: [
          {
            subtitle: t('Reviews'),
            path: `${rootPath}reviews`,
            tap: true,
            event: 'btn_reviews_all'
          },
          {
            subtitle: t('Feed'),
            path: `${rootPath}feed`,
            tap: true,
            isBeta: true,
            event: 'btn_feed' //tbd
          }
        ],
        enabled: !isMaple
      },
      {
        title: t('My Business'),
        icon: 'business',
        sublinks: [
          {
            subtitle: t('Business Info'),
            path: `${rootPath}business-info`,
            tap: true,
            event: 'btn_business_info'
          },
          {
            subtitle: t('Outlets'),
            path: `${rootPath}outlets`,
            tap: true,
            event: 'btn_outlet'
          }
        ]
      },
      {
        title: t('Support'),
        icon: 'phone',
        className: 'support-navigation-button',
        sublinks: [
          {
            subtitle: t('FAQ'),
            path: routesMap.sideBarFAQ,
            tap: true,
            event: 'btn_faq'
          },
          {
            subtitle: t('Contact Us'),
            path: `${rootPath}contact-us`,
            tap: true,
            event: 'btn_help_contact'
          }
        ],
        enabled: !isMaple
      },
      {
        title: t('Campaigns'),
        icon: 'campaign',
        className: 'campaign-button',
        tap: true,
        isExternalLink: true,
        event: 'btn_help_contact',
        enabled: isMaple,
        path: `${kringlePath}orders/login`
      },
      {
        title: t('Need help'),
        icon: 'phone',
        className: 'support-navigation-button',
        tap: true,
        event: 'btn_help_contact',
        enabled: isMaple,
        path: `https://partners.myfave.in/support/home`
      }
    ],
    [company, isCashbackActive, isFavePayActive, isNotIndonesia, t, isMaple]
  );

  return <Accordion links={links} />;
};

export default SideBarNav;

function Accordion({ links }: { links: LinkProps[] }) {
  const { pathname } = useLocation();
  const [expandedLink, setExpandedLink] = useState('');
  const [activeLink, setActiveLink] = useState('');

  const pathMatches = useCallback(
    (link: { path: string }) =>
      link.path === '/' ? pathname === link.path : pathname.includes(link.path),
    [pathname]
  );

  const pathMatchesChild = useCallback(
    (link: LinkProps) =>
      pathMatches(link as { path: string }) ||
      (link.sublinks || []).some(link => pathMatches(link)),
    [pathMatches]
  );

  useEffect(() => {
    const activeLink = links.find(link => pathMatchesChild(link));
    if (!activeLink) return;
    setExpandedLink(activeLink.title);
    setActiveLink(activeLink.title);
  }, [links, pathMatchesChild]);

  return (
    <>
      {links
        .filter(c => c.enabled !== false)
        .map((link: AdditionalLinkProps) => (
          <SidebarAccordionItem
            key={link.path || link.title}
            link={link}
            isHighlighted={activeLink === link.title || pathMatchesChild(link)}
            accordionItemClassName={link.className}
            {...((link.sublinks || []).length > 0
              ? {
                isOpen: expandedLink === link.title,
                onHeaderClick: () =>
                  setExpandedLink(prevState =>
                    prevState === link.title ? '' : link.title
                  )
              }
              : {
                onHeaderClick: () =>
                  link.isExternalLink === true ?
                    link.path && window.open(link.path) :
                    link.path && navigate(link.path)
              })
            }
          />
        ))}
    </>
  );
}
