import React, { useContext, useState, useEffect } from 'react';

import useHubSpotTrack from 'hooks/useHubSpotTrack';
import LoginLayout from 'layouts/LoginLayout';
import LoginHeader from 'components/LoginHeader';
import styles from 'assets/css/SignUpCountryView.module.scss';
import Button from 'components/Button';
import sgFlag from 'assets/images/ic-flag-sg.svg';
import myFlag from 'assets/images/ic-flag-my.svg';
import idFlag from 'assets/images/ic-flag-id.svg';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { ModalContext } from 'contexts/ModalContext';
import { navigate } from '@reach/router';

import IndonesiaModalImage from 'assets/images/modal-indonesia-signup.svg';

const SignUpCountryView = ({ location }) => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'onboarding' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  useHubSpotTrack(location.pathname);
  const { init, close } = useContext(ModalContext);
  const IDModal = () => {
    return (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.modalImage}>
            <img src={IndonesiaModalImage} alt="modalImg" />
          </div>
          <h5>{t('Coming soon')}</h5>
          <p>
            {t(
              'We’re doing our best to bring self-onboarding to Indonesia, thank you for your support!'
            )}
          </p>
          <div className={styles.ButtonSection}>
            <Button
              type={'skeleton'}
              href={'mailto:pm.id@myfave.com'}
              textLinkColor={'white'}
              size={'lg'}
              isRounded={true}
            >
              {t('Contact Us')}
            </Button>
            <Button
              type={'primary'}
              isRounded={true}
              size={'lg'}
              onClick={() => {
                close();
                navigate('/login');
              }}
            >
              {t('Go to Log in')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <LoginLayout withHeader withFooter={false} forSignUp={true}>
      <div className={styles.signUpContainer}>
        <LoginHeader className={styles.loginHeader}>
          <div>{t('Choose your country')}</div>
        </LoginHeader>
        <div className={styles.flagContainer}>
          <Button
            type={'ghost'}
            href="/sign-up/contract?country=my"
            onClick={() =>
              cleverTap.tapped.push({
                screen_name: 'onboarding',
                tapped_on: 'btn_country',
                selected: 'MY'
              })
            }
          >
            <img src={myFlag} alt="my-flag" />
          </Button>
          <Button
            type={'ghost'}
            href="/sign-up/contract?country=sg"
            onClick={() =>
              cleverTap.tapped.push({
                screen_name: 'onboarding',
                tapped_on: 'btn_country',
                selected: 'SG'
              })
            }
          >
            <img src={sgFlag} alt="sg-flag" />
          </Button>
          <Button
            type={'ghost'}
            onClick={() => {
              cleverTap.tapped.push({
                screen_name: 'onboarding',
                tapped_on: 'btn_country',
                selected: 'ID'
              });
              init({
                Component: IDModal,
                openOnReady: true
              });
            }}
          >
            <img src={idFlag} alt="id-flag" />
          </Button>
        </div>
      </div>
    </LoginLayout>
  );
};

export default SignUpCountryView;
