import Kfit from '../integrations/Kfit';

export const createAnnouncement = params =>
  Kfit.post('/v7/outlet_announcements', params).then(res => res);

export const updateAnnouncement = (id, params) => {
  const init = {};
  init.method = 'PUT';
  return Kfit.multipartRequest(`/v7/outlet_announcements/${id}`, params, init);
};

export const deleteAnnouncement = ({ id, onDoneCallback, onError }) =>
  Kfit.delete(`/v7/outlet_announcements/${id}`)
    .then(onDoneCallback)
    .catch(onError);
