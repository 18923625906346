import React, { useContext, useEffect } from 'react';
import BrandLogo from 'components/BrandLogo';
import { useTranslation } from 'react-i18next';
import InlineLink from 'components/InlineLink';
import styles from 'assets/css/ErrorPage.module.scss';
import { navigate } from '@reach/router';

import { WebviewContext } from 'contexts/WebviewContext';
import { call, NATIVE } from 'utils/nativeInterface';
import NoAccessImg from 'assets/images/no_access.png';
import NoAvailableImg from 'assets/images/no_available.png';

const TIMEOUT_IN_MS = 8000;

type ErrorPageProps = {
  errorType: string;
};

const ErrorPage: React.FC<ErrorPageProps> = props => {
  const { errorType } = props;
  const { isWebview } = useContext(WebviewContext);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => navigate('/'), TIMEOUT_IN_MS);
    return () => clearTimeout(timer);
  });

  const onClickContactUs = () => {
    if (isWebview) {
      call(NATIVE.OPEN_HELP);
    } else {
      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div>
          <img
            className={styles.image}
            src={errorType === 'no_access' ? NoAccessImg : NoAvailableImg }
            alt="error"
          />
        </div>
        <BrandLogo position="center" className={styles.brandLogo} />
        <h2 className={styles.errorHeader}>
          {
            errorType === 'no_access' ?
              t('Oops, you do not have access to this page.') : t('This page is no longer available.')
          }
        </h2>
        <div className={styles.description}>
          {
            errorType === 'no_access'
              ? t('It seems like you may have stumbled on this page by accident, fret not, hang tight as we redirect you back to FaveBiz. If you need help, contact us by clicking')
              : t('It seems like the page you’re looking for is no longer available, hang tight as we redirect you back to FaveBiz. If you need help, contact us by clicking')
          }{' '}
          {
            <InlineLink
              to={isWebview ? '' : '/contact-us'}
              onClick={onClickContactUs}
              children={t('here')}
              className={styles.inlineLink}
            />
          }
          .
        </div>
        <div>
          <div className={styles.loadingSpinner} />
        </div>
      </div>
      <div className={styles.footerLine}>
        {t("If you are not redirected automatically, click")}{" "}
        <InlineLink
          to={'/'}
          children={t('here')}
          className={styles.inlineLink}
        />
        
      </div>
    </div>
  );
}

export default ErrorPage;
