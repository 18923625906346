import React from 'react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import AppLayout from 'layouts/AppLayout';
import { DefaultHeading } from 'components/DefaultHeading';
import { useBankAccountList } from 'queries/getInvoiceSelfServe';
import { useOutletContext } from 'contexts/OutletContext';
import BankAccountSelectionView from './BankAccountSelectionView';
import InvoicesListingView from './InvoiceListingView';

const InvoicesView = ({
  finance_account_id
}: {
  finance_account_id: number;
}) => {
  const { t } = useTranslation();
  const { currentCompany, currentOutlet } = useOutletContext();
  const bankQuery = useBankAccountList(
    {
      company_id: currentCompany?.id,
      outlet_id: currentOutlet?.id
    },
    currentCompany !== undefined && currentOutlet !== undefined
  );

  if (!currentCompany) return null;

  return (
    <AppLayout
      onOutletSwitcherChanged={() => navigate('/invoices', { replace: true })}
    >
      <DefaultHeading
        title={t('Tax Invoice')}
        subTitle={
          finance_account_id && bankQuery.data
            ? bankQuery.data.pages[0].accounts_detail[0].bank_name
            : t('View and generate your invoices')
        }
      />
      {finance_account_id ? (
        <InvoicesListingView finance_account_id={finance_account_id} />
      ) : (
        <BankAccountSelectionView
          outlet_id={currentOutlet?.id}
          bankQuery={bankQuery}
        />
      )}
    </AppLayout>
  );
};

export default InvoicesView;
