import React, { useState, useEffect } from 'react';
import className from 'classnames';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'insights/assets/css/Nav.module.scss';
import Icon from 'components/Icon';

const getNavLinks = ({ fave_payment, voucher }) =>
  [
    {
      title: 'FaveBiz Insights',
      icon: require('insights/assets/images/nav/summary.svg'),
      href: '#favebiz-insights',
      event: 'btn_insight'
    },
    fave_payment && {
      title: 'FavePay',
      icon: require('insights/assets/images/nav/favepay.svg'),
      href: '#favepay',
      event: 'btn_favepay'
    },
    voucher && {
      title: 'FaveDeals',
      icon: require('insights/assets/images/nav/favedeals.svg'),
      href: '#favedeals',
      event: 'btn_favedeals'
    },
    {
      title: 'Customer Insights',
      icon: require('insights/assets/images/nav/customer-insights.svg'),
      href: '#customer-insights',
      event: 'btn_customerinsight'
    },
    {
      title: 'Customer Engagement',
      icon: require('insights/assets/images/nav/engagement.svg'),
      href: '#customer-engagement',
      event: 'btn_customerengagement'
    },

    // TODO: not available for now
    // {
    //   title: 'Cashback',
    //   icon: require('insights/assets/images/nav/cashback.svg'),
    //   href: '#'
    // },
    // {
    //   title: 'Dynamic Cashback',
    //   icon: require('insights/assets/images/nav/dynamic-cashback.svg'),
    //   href: '#'
    // },
    // {
    //   title: 'Table Ordering',
    //   icon: require('insights/assets/images/nav/ordering.svg'),
    //   href: '#table-ordering'
    // },
    // {
    //   title: 'Takeaway',
    //   icon: require('insights/assets/images/nav/takeaway.svg'),
    //   href: '#'
    // },
    // {
    //   title: 'eCards',
    //   icon: require('insights/assets/images/nav/ecards.svg'),
    //   href: '#'
    // },
    {
      title: 'More Services',
      icon: require('insights/assets/images/nav/more-services.svg'),
      href: '#more-services',
      color: 'brilliant-rose',
      event: 'btn_moreservice'
    }
  ].filter(Boolean);

const useHighlightActiveNav = (setActiveNavId, features) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(
          ({ isIntersecting, target }) =>
            isIntersecting && setActiveNavId(target.id)
        );
      },
      { root: null, rootMargin: '0px', threshold: 0 }
    );

    getNavLinks(features)
      .map(link => link.href.substring(1))
      .forEach(navSectionId => {
        const element = document.getElementById(navSectionId);
        if (element) observer.observe(element);
      });

    return () => observer.disconnect();
  }, [features, setActiveNavId]);
};

export default function Nav({ features, mobileScrollMarginTop }) {
  const [activeNavId, setActiveNavId] = useState();
  useHighlightActiveNav(setActiveNavId, features);
  const CleverTap = useCleverTapOld();

  const NavItem = ({
    href,
    title,
    color,
    icon,
    activeNavId,
    mobileScrollMarginTop,
    event
  }) => {
    const navId = href.substring(1);
    const isActive = activeNavId === navId;
    // https://stackoverflow.com/a/51070207
    const isSmoothScrollSupported =
      'scrollBehavior' in document.documentElement.style;

    return (
      <li
        style={{ '--link-color': `var(--${color || 'bali-hai'})` }}
        className={className(styles.navItem, isActive && styles.isActive)}
        onClick={() => {
          const element = document.getElementById(navId);
          CleverTap.tapped.push({
            screen_name: 'insight',
            section_name: 'bar_navigation',
            tapped_on: event
          });
          if (isSmoothScrollSupported) {
            element.scrollIntoView({ behavior: 'smooth' });
          } else {
            // Safari <= 11, you rascal
            const heightInPx = parseFloat(mobileScrollMarginTop);
            const baseFontSize = 16;
            const scrollOffset = heightInPx * baseFontSize;

            // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#Browser_compatibility
            element.scrollIntoView(true); // scroll to top of page first
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollBy#Browser_Compatibility
            window.scrollBy(0, -scrollOffset); // scroll down by mobile scroll margin top
          }
        }}
      >
        <Icon icon={icon} gradient size="1.25rem" color="primary" />
        <div>{title}</div>
      </li>
    );
  };

  return (
    <nav className={styles.nav}>
      <ul>
        {getNavLinks(features).map(link => (
          <NavItem
            key={link.href}
            {...link}
            activeNavId={activeNavId}
            mobileScrollMarginTop={mobileScrollMarginTop}
          />
        ))}
      </ul>
    </nav>
  );
}
