import React from 'react';
import { useTranslation } from 'react-i18next';
import ECard, { ECardProps } from 'components/ECard';
import LinkButton from 'components/Button/LinkButton';

import styles from 'assets/css/ECardWithDetails.module.scss';

type ECardWithDetailsProps = {
  revenue: string | number;
  sold: string | number;
  href: string;
  ctEvent: () => void;
} & ECardProps;

const ECardWithDetails: React.FC<ECardWithDetailsProps> = ({
  children,
  revenue,
  sold,
  currency,
  href = '',
  ctEvent = () => {},
  ...theRest
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ECard currency={currency} {...theRest} className={styles.card} />
      {(revenue || sold) && (
        <div className={styles.cardPerformance}>
          <div className={styles.cardItem}>
            <div className={styles.cardItemTitle}>
              {t('Revenue ')} ({currency})
            </div>
            <span className={styles.cardItemValue}>{revenue}</span>
          </div>
          <div className={styles.cardItem}>
            <div className={styles.cardItemTitle}>{t('Sold')}</div>
            <span className={styles.cardItemValue}>{sold}</span>
          </div>
        </div>
      )}
      <LinkButton
        size="xs"
        iconSize="1rem"
        rightIcon="angle-right"
        href={href}
        className={styles.button}
        onClick={() => ctEvent()}
      >
        Details
      </LinkButton>
    </div>
  );
};

export default ECardWithDetails;
