import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/AnnouncementDeleteModal.module.scss';
import TappedButton from './Button/TappedButton';
import { updateAnnouncement } from 'commands/announcementAPI';

type DeleteModalProps = {
  onClose: () => void;
  announcementId: number;
  onAnnouncementDelete: (announcementId: number) => void;
  onAnnouncementDeleteError: () => void;
  deleteButtonCleverTapEvent: () => void;
};

const AnnouncementDeleteModal = ({
  onClose,
  announcementId,
  onAnnouncementDelete,
  onAnnouncementDeleteError,
  deleteButtonCleverTapEvent
}: DeleteModalProps) => {
  const { t } = useTranslation();
  const formData = new FormData();

  const deleteAnnouncementById = () =>{
    formData.append("status","inactive")
    updateAnnouncement(announcementId,formData)
    .then(() => {
      deleteButtonCleverTapEvent()
      onAnnouncementDelete(announcementId);
      onClose();
    }).catch(() => {
      onAnnouncementDeleteError();
      onClose();
    });
  }
    

  return (
    <div className={styles.modalLayout}>
      <h3>{t("Confirm Delete")}</h3>
      <p>{t("Are you sure you want to delete this announcement?")}</p>
      <div className={styles.bottomButtonBar}>
        <div className={styles.individualButtonSpace}>
          <TappedButton
            type="default"
            isRounded
            fullWidth
            justify="center"
            onClick={onClose}
            children={t('No')}
            size="lg"
          />
        </div>
        <div className={styles.individualButtonSpace}>
          <TappedButton
            fullWidth
            size="lg"
            justify="center"
            type="primary"
            children={t('Yes')}
            onClick={deleteAnnouncementById}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDeleteModal;
