import React from 'react';
import classNames from 'classnames';

import styles from 'assets/css/UserTipStrip.module.scss';
import bulpIcon from 'assets/images/banners/ic_bulp.svg';

export type UserTipStripProps = {
  className?: string;
};

const UserTipStrip = ({
  className,
  children
}: React.PropsWithChildren<UserTipStripProps>) => {
  const classes = classNames(styles.missingImgStrip, className);

  return (
    <div className={classes}>
      <img src={bulpIcon} className={styles.blupIcon} alt="" />
      {children}
    </div>
  );
};

export default UserTipStrip;
