import React, { useEffect, useState, useContext, useRef } from 'react';
import QRCode from 'qrcode.react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import getQRCode from 'queries/getQRCode';
import requestQRCode from 'commands/requestQRCode';
import getPaymentLink from 'queries/getPaymentLink';
import AppLayout from 'layouts/AppLayout';
import OpsLayout from 'layouts/OpsLayout';

import { OutletContext } from 'contexts/OutletContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { ModalContext } from 'contexts/ModalContext';
import { AuthContext } from 'contexts/AuthContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';

import useCleverTapOld from 'hooks/useCleverTapOld';
import useAuthContext from 'hooks/useAuthContext';

import Heading from 'components/Heading';
import Accordion from 'components/Accordion';
import Button, { LinkButton, PrimaryInvertedButton } from 'components/Button';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import EnterPaymentModal from './EnterPaymentModal';
import GenericPaymentModal from './GenericPaymentModal';

import paymentIcons from 'assets/images/img-payment-logo.svg';
import showQRIcon from 'assets/images/img-show-qr-01.svg';
import printQRIcon from 'assets/images/img-print-qr-01.svg';
import printQRIconDuitNow from 'assets/images/duitNow/img-print-qr.svg';
import physicalQRIcon from 'assets/images/img-physical-qr-01.svg';
import physicalQRIconDuitNow from 'assets/images/duitNow/img-physical-qr.svg';
import howQRCodeWorksStepOneIcon from 'assets/images/img-how-qr-code-works-01.svg';
import howQRCodeWorksStepOneIconDuitNow from 'assets/images/duitNow/img-how-qr-code-works.svg';
import howQRCodeWorksStepTwoIcon from 'assets/images/img-how-qr-code-works-02.svg';
import howQRCodeWorksStepThreeIcon from 'assets/images/img-how-qr-code-works-03.svg';

import { useTranslation } from 'react-i18next';
import styles from 'assets/css/ReceivePaymentView.module.scss';
import { MapleContext } from 'contexts/MapleContext';

const FAVE_RUBY = '#ED2E67';

const ReceivePaymentView = ({ qr_id: outletId, location }) => {
  const { isMaple } = useContext(MapleContext);
  const { isOperations } = useAuthContext();
  const { t } = useTranslation();
  const { outlets } = useContext(
    !isOperations ? OutletContext : OpsOutletContext
  );
  const { setNotification } = useContext(NotificationContext);
  const { from } = useContext(QueryStringContext);
  const { init } = useContext(ModalContext);
  const { user } = useContext(isOperations ? OpsAuthContext : AuthContext);

  const [theQRCode, setTheQRCode] = useState();
  const [isQRFormVisible, setIsQRFormVisible] = useState(false);
  const [paymentLink, setPaymentLink] = useState();
  const hasQRCode = !!theQRCode;
  const hasSingleOutlet = (outlets || []).length === 1;
  const staff_url = process.env.REACT_APP_FAVEBIZ_STAFF;
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);

  const Layout = isOperations ? OpsLayout : AppLayout;
  const { currentOutlet } =
    useContext(isOperations ? OpsOutletContext : {}) || {};

  const { currentCompany } = useContext(
    isOperations ? OpsOutletContext : OutletContext
  );
  const amountInputRef = useRef(null);

  const currency = (user.city || {}).currency_display;
  const city_slug = (user.city || {}).slug;

  const showDuitNow = () =>
    city_slug === 'kuala-lumpur' && theQRCode?.rpp_qr_enabled;

  const paymentOutlet = isOperations
    ? currentOutlet
    : outlets.find(o => o.id === +outletId);

  //send screen displayed event
  useEffect(() => {
    if (sentCTEvent || !hasQRCode) return;
    cleverTap.screenDisplayed.push({ screen_name: 'outlet_receivepayment' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent, hasQRCode]);
  // get payment link
  useEffect(() => {
    if (!paymentOutlet) return;
    getPaymentLink({ outletId: paymentOutlet.id }).then(res =>
      setPaymentLink(res.link)
    );
  }, [paymentOutlet]);

  // set QR code
  useEffect(() => {
    let isMounted = true;
    if (outletId || paymentOutlet) {
      getQRCode({ id: outletId || paymentOutlet.id })
        .then(res => {
          if (!isMounted) return;
          setTheQRCode(res);
        })
        .catch(error => {
          if (!isMounted) return;
          if (city_slug !== 'jakarta')
            setNotification({
              message: error.value.response.error,
              type: 'error'
            });
        });
    }
    return () => (isMounted = false);
  }, [
    outletId,
    from,
    setNotification,
    isOperations,
    currentOutlet,
    city_slug,
    paymentOutlet
  ]);

  const SendEmailForm = () => (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('Invalid email'))
          .required(t('Email required'))
      })}
      validateOnBlur={false}
      onSubmit={({ email }) =>
        requestQRCode({ outlet_id: outletId || currentOutlet.id, email })
          .then(() => {
            setNotification({
              message: t('Email sent'),
              type: 'success'
            });
          })
          .catch(error =>
            setNotification({
              message: error.value.response.error,
              type: 'error'
            })
          )
      }
    >
      {({ errors, touched }) => (
        <Form>
          <section className={styles.qrForm}>
            <TextInput
              type="email"
              name="email"
              placeholder={t('Enter your email')}
              focus
              {...{ errors, touched }}
            />
            <Button
              type="submit"
              size="lg"
              className={styles.emailButton}
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'outlet_receivepayment',
                  tapped_on: 'btn_send_qrcode'
                })
              }
            >
              {t('Send Email')}
            </Button>
          </section>
        </Form>
      )}
    </Formik>
  );

  const QRCodeSection = () => (
    <section className={styles.qrCodeLayout}>
      <div className={showDuitNow() ? styles.malaysianQrCode : styles.qrCode}>
        {hasQRCode ? (
          showDuitNow() ? (
            <div className={styles.malaysianQrCodeFrame}>
              <QRCode
                size={250}
                value={theQRCode.qr_value}
                fgColor={FAVE_RUBY}
              />
            </div>
          ) : (
            <QRCode size={250} value={theQRCode.qr_value} />
          )
        ) : (
          <>
            <Icon icon="qr-missing" size="3rem" />
            <div className={styles.qrCodeNotFound}>{t('QR not found')}</div>
          </>
        )}
      </div>
      {!isMaple && hasQRCode && (
        <Button
          fullWidth
          size="lg"
          className={styles.toggleQRFormButton}
          onClick={() => setIsQRFormVisible(!isQRFormVisible)}
        >
          {t('Send QR code')}
        </Button>
      )}
      {isQRFormVisible && hasQRCode && <SendEmailForm />}
    </section>
  );

  const SubHeading = props => (
    <>
      <h3 className={classNames(styles.subheading, props.className)}>
        {props.title}
      </h3>
      {props.subtitle && <p className={styles.subtitle}>{props.subtitle}</p>}
    </>
  );

  const FAQSection = () => (
    <Accordion
      className={styles.faq}
      items={[
        {
          header: t('Why do I need this?'),
          Panel: () => (
            <>
              {showDuitNow()
                ? t(
                    'You can now accept payments via Fave DuitNow QR code. Show it to customers for scanning to receive payments.'
                  )
                : t(
                    'You can now accept payments via Fave QR code. Show it to customers for scanning to receive payments.'
                  )}
            </>
          )
        },
        {
          header: t('What do I do with this QR code?'),
          Panel: () => (
            <section className={styles.paddedPanel}>
              <div className={styles.panelFeature}>
                <img src={showQRIcon} alt="show QR" />
                <span>
                  {t(
                    'Show this QR code from your mobile to your customers to scan.'
                  )}
                </span>
              </div>
              <div className={styles.dividerOR}>OR</div>
              <div className={styles.panelFeature}>
                <img
                  src={showDuitNow() ? printQRIconDuitNow : printQRIcon}
                  alt="print QR"
                />
                <span>
                  {t('Print this QR code to display at your outlet.')}
                </span>
              </div>
              <div className={styles.dividerOR}>OR</div>
              <div className={styles.panelFeature}>
                <img
                  src={showDuitNow() ? physicalQRIconDuitNow : physicalQRIcon}
                  alt="starter kit QR"
                />
                <span>
                  {t(
                    'Display your physical QR code from your starter kit at your outlet.'
                  )}
                </span>
              </div>
            </section>
          )
        },
        {
          header: t('How does it work?'),
          Panel: () => (
            <section className={styles.paddedPanel}>
              <div className={styles.panelFeature}>
                <img
                  src={
                    showDuitNow()
                      ? howQRCodeWorksStepOneIconDuitNow
                      : howQRCodeWorksStepOneIcon
                  }
                  alt="Step 1"
                />
                <span>
                  {t(
                    'Customers scan your QR code at your outlet or from your mobile.'
                  )}
                </span>
              </div>
              <div className={styles.divider} />
              <div className={styles.panelFeature}>
                <img src={howQRCodeWorksStepTwoIcon} alt="Step 2" />
                <span>{t('Customers enter total bill and pay. ')}</span>
              </div>
              <div className={styles.divider} />
              <div className={styles.panelFeature}>
                <img src={howQRCodeWorksStepThreeIcon} alt="Step 3" />
                <span>
                  {t('Successful transactions will reflect on FaveBiz.')}
                </span>
              </div>
            </section>
          )
        },
        {
          header: showDuitNow()
            ? t('Why use Fave DuitNow QR code?')
            : t('Why use Fave QR code?'),
          Panel: () => (
            <>
              <p>
                {showDuitNow()
                  ? t(
                      'Fave DuitNow QR code directs customers to make payment to your outlet from the Fave app.'
                    )
                  : t(
                      'Fave QR code directs customers to make payment to your outlet from the Fave app.'
                    )}
              </p>
              <h4>{t('Multiple cashless payment options')}</h4>
              <p>
                {t(
                  'Accept payments via major credit cards and payment wallets.'
                )}
              </p>
              <img src={paymentIcons} alt="payment options" />
              <h4>{t('Easy, secure and convenient')}</h4>
              <p>{t('Accept payments even when you are on the go.')}</p>
              <p>
                {t(
                  'Get instant notifications when customers scan your QR code and make cashless payments.'
                )}
              </p>
              <h4>{t('No setup costs')}</h4>
              <p>
                {t(
                  'Enjoy low Fave fee with no expensive terminal setups needed, just a connected smartphone.'
                )}
              </p>
            </>
          )
        },
        {
          header: t('How do I check transactions?'),
          Panel: () => (
            <>
              <p>
                {t(
                  'You can check your FavePay transaction details in real time using Staff mode.'
                )}
              </p>
              <PrimaryInvertedButton
                fullWidth
                onClick={() => {
                  if (hasSingleOutlet) {
                    window.open(
                      staff_url + `?auth_code=${outlets[0].auth_code}`
                    );
                  } else {
                    navigate('/switch-to-staff');
                  }
                }}
              >
                {t('Switch to Staff Mode')}
              </PrimaryInvertedButton>
            </>
          )
        },
        {
          header: t('When will I receive payment?'),
          Panel: () => (
            <>
              <section className={styles.paymentSection}>
                <section
                  className={classNames(styles.weekSection, styles.weekOne)}
                >
                  <div className={styles.weekHeader}>{t('1st Week')}</div>
                  <section className={styles.weekDays}>
                    <span>{t('Mon')}</span>
                    <span>{t('Tue')}</span>
                    <span>{t('Wed')}</span>
                    <span>{t('Thu')}</span>
                    <span>{t('Fri')}</span>
                    <span>{t('Sat')}</span>
                    <span>{t('Sun')}</span>
                  </section>
                </section>
                <section className={styles.weekSection}>
                  <div className={styles.weekHeader}>{t('2nd Week')}</div>
                  <section className={styles.weekDays}>
                    <span>{t('Mon')}</span>
                    <span>{t('Tue')}</span>
                    <span>{t('Wed')}</span>
                    <span className={styles.active}>{t('Thu')}</span>
                    <span>{t('Fri')}</span>
                    <span>{t('Sat')}</span>
                    <span>{t('Sun')}</span>
                  </section>
                </section>
              </section>
              <p>
                {t(
                  'Payment will be made by every Thursday for transactions from the previous week (Monday to Sunday) to the bank account that you have linked.'
                )}
              </p>
            </>
          )
        }
      ]}
    />
  );

  const openPaymentModal = ({
    ModalType,
    openCallback = () => {},
    ...props
  }) => {
    init({
      Component: ModalType,
      openOnReady: true,
      closeOnBlur: false,
      openCallback,
      componentProps: {
        ...props,
        paymentOutlet,
        currency,
        city_slug,
        paymentLink,
        currentCompany
      }
    });
  };

  const DescriptionSection = () => (
    <section className={styles.descriptionLayout}>
      {hasQRCode ? (
        <>
          <div className={styles.faqHeading}>
            {t('Frequently Asked Questions')}
          </div>
          <FAQSection />
          <section className={styles.contactUs}>
            {t('Need help?')}
            <LinkButton
              href="/contact-us"
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'outlet_receivepayment',
                  tapped_on: 'btn_help_contact'
                })
              }
            >
              {t('Contact us.')}
            </LinkButton>
          </section>
        </>
      ) : (
        city_slug !== 'jakarta' && (
          <>
            <div>{t('This outlet does not have FavePay activated.')}</div>
            <div>{t('To do so, find out about the service here.')}</div>
            <Button
              type="primary"
              className={styles.learnMore}
              rightIcon="arrow-right"
              href="//www.favebiz.com/login/payments"
            >
              {t('Learn more')}
            </Button>
          </>
        )
      )}
    </section>
  );
  return (
    <Layout>
      <Heading
        title={t('Receive Payment')}
        subtitle={t('Ways to receive payment from your customers')}
      />
      <Button
        leftIcon="arrow-left"
        href={isOperations ? '/operations/transactions' : from || '/outlets'}
        type="text"
      >
        {from || isOperations ? t('Back') : t('Back to outlets')}
      </Button>
      {!isMaple && (
        <>
          <SubHeading
            title={t('Payment Link')}
            subtitle={t(
              'Share link to customers to receive cashless payments easily'
            )}
            className={styles.viewSubHeading}
          />
          <div className={styles.paymentLinks}>
            <Button
              type="primary"
              size="lg"
              className={`${styles.linkButton} ${styles.customLink}`}
              justify="center"
              onClick={() => {
                cleverTap.tapped.push({
                  screen_name: 'outlet_receivepayment',
                  tapped_on: 'btn_amount_share'
                });
                openPaymentModal({
                  ModalType: EnterPaymentModal,
                  openCallback: () => amountInputRef.current.focus(),
                  ref: amountInputRef
                });
              }}
            >
              {t('Enter amount and share')}
            </Button>
            <PrimaryInvertedButton
              size="lg"
              className={styles.linkButton}
              justify="center"
              onClick={() => {
                cleverTap.tapped.push({
                  screen_name: 'outlet_receivepayment',
                  tapped_on: 'btn_genericlink_share'
                });
                openPaymentModal({ ModalType: GenericPaymentModal });
              }}
            >
              {t('Share generic link')}
            </PrimaryInvertedButton>
          </div>
        </>
      )}
      <SubHeading
        title={showDuitNow() ? t('Fave DuitNow QR') : t('Fave QR')}
        subtitle={t(
          'Show this QR to customers for scanning to receive payments'
        )}
      />
      <section className={styles.layout}>
        <QRCodeSection />
        {!isMaple && !isOperations && <DescriptionSection />}
      </section>
    </Layout>
  );
};

export default ReceivePaymentView;
