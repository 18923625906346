import React from 'react';
import classNames from 'classnames';

import Button, { IButtonProps } from './Button';
import styles from 'assets/css/CloseButton.module.scss';

const CloseButton: React.FC<IButtonProps> = ({
  className,
  onClick = () => {}
}) => (
  <Button
    className={classNames(styles.closeButton, className)}
    type="text"
    size="xs"
    onClick={onClick}
  >
    &#10005;
  </Button>
);

export default CloseButton;
