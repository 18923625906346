import FaveBizAPI from 'integrations/FaveBizAPI';
export interface IEmailNotificationOutlet {
  id: number;
  name: string;
  neighbourhood_slug: string;
  email_notification: boolean;
}

export const getEmailNotifications = (param: {
  company_id: number
}): Promise<{ all_flag: boolean, outlets: IEmailNotificationOutlet[] }> =>
  FaveBizAPI.get(`/v1/email_notifications`, param);
