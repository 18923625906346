import Result from 'folktale/result';
import Cookie from 'helpers/Cookie';

export default function responseHandler(response) {
  const contentType = response.headers.get('content-type');

  const postSerializationCallback = serialized => {
    if (!response.ok) {
      if (response.status === 401) {
        // force logout if token is invalid
        Cookie.remove('accessToken');
        Cookie.remove('currentCompany');
        Cookie.remove('currentOutlet');
        Cookie.remove('currentOutletCode');
        window.location.reload();
      }

      return Promise.reject(
        Result.Error({ response: serialized, status: response.status })
      );
    }

    return serialized;
  };

  if (contentType && contentType.includes('json')) {
    return response.json().then(postSerializationCallback);
  } else if (contentType && contentType.includes('text')) {
    return response;
  } else {
    return response.text().then(postSerializationCallback);
  }
}
