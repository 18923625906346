import React, { useContext, useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import useCleverTapOld from 'hooks/useCleverTapOld';
import { OutletContext } from 'contexts/OutletContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { MapleContext } from 'contexts/MapleContext';
import {
  getAllAnnouncements,
  IGetAnnouncementsResponse
} from 'queries/getOutletAnnouncement';
import AppLayout from 'layouts/AppLayout';
import AnnouncementCard from 'components/AnnouncementCard';
import Heading from 'components/Heading';
import WebviewHeading from 'components/WebviewHeading';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import Styles from 'assets/css/AnnouncementView.module.scss';
import { call, NATIVE } from 'utils/nativeInterface';
import { ModalContext } from 'contexts/ModalContext';
import DeleteModal from 'components/AnnouncementDeleteModal';
import { NotificationContext } from 'contexts/NotificationContext';
import Icon from 'components/Icon';
import sample from 'assets/images/announcement-sample-view-maple.png';
import BannerMessage from 'components/BannerMessage';

const AnnouncementView: React.FC = () => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { init } = useContext(ModalContext);
  const { isWebview } = useContext(WebviewContext);
  const { isMaple } = useContext(MapleContext);
  const { currentCompany } = useContext(OutletContext);
  const { setNotification } = useContext(NotificationContext);
  const [showSampleView, setShowSampleView] = useState<boolean>(false);
  const [outletAnnouncement, setOutletAnnouncement] = useState<
    IGetAnnouncementsResponse[]
  >();

  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'announcement' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  useEffect(() => {
    if (!currentCompany) return;
    getAllAnnouncements({ company_id: currentCompany.id }).then(response =>
      setOutletAnnouncement(response)
    );
  }, [currentCompany]);

  const openSampleModal = () => {
    cleverTap.tapped.push({
      screen_name: 'announcement',
      tapped_on: 'btn_sample_announcement'
    });
    cleverTap.screenDisplayed.push({ screen_name: 'sample_announcement' });
    return isWebview
      ? setShowSampleView(true)
      : init({
          Component: RenderSampleImage,
          openOnReady: true,
          hasCloseButton: true,
          closeOnBlur: true
        });
  };

  const RenderNoAnnouncementView = () => {
    return (
      <div className={Styles.noAnnouncementLayout}>
        <div className={Styles.noAnnouncementImage}></div>
        <p className={Styles.noAnnouncementCopy}>
          {t(
            'Use Announcements to reach your customers! Announcements will be featured on your outlet page on the Fave app.'
          )}
        </p>
        <p className={Styles.seeSampleLinkStyle} onClick={openSampleModal}>
          {t('See what an announcement will look like on the Fave app')}
        </p>
      </div>
    );
  };

  const RenderBanner = () => {
    return (
      <div className={Styles.banner}>
        {t(
          'Fave Group reserves the right to discontinue announcements that are deemed inappropriate by the company.'
        )}
      </div>
    );
  };

  const RenderSampleImage = () => {
    return (
      <div className={Styles.imageArea}>
        <img
          className={Styles.announcementSampleImg}
          src={sample}
          alt="sample"
        />
      </div>
    );
  };

  const RenderAnnouncementCards = () => {
    const openDeleteModal = (
      annoucementId: number,
      outletAnnouncement: IGetAnnouncementsResponse[]
    ) => {
      return init({
        Component: DeleteModal,
        openOnReady: true,
        hasCloseButton: true,
        componentProps: {
          announcementId: annoucementId,
          onAnnouncementDelete: (announcementId: number) => {
            setOutletAnnouncement(
              outletAnnouncement.filter(
                (res: IGetAnnouncementsResponse) => res.id !== announcementId
              )
            );
            setNotification({
              message: t('Announcement is successfully deleted'),
              type: 'success'
            });
          },
          onAnnouncementDeleteError: () => {
            setNotification({
              message: t('Something went wrong while deleting announcement.'),
              type: 'error'
            });
          },
          deleteButtonCleverTapEvent: () =>
            cleverTap.tapped.push({
              screen_name: 'announcement',
              tapped_on: 'btn_confirm_delete_announcement'
            })
        }
      });
    };
    return (
      <>
        <RenderBanner />
        {outletAnnouncement &&
          outletAnnouncement
            .sort((announcement1, announcement2) =>
              announcement1.start_at > announcement2.start_at ? 1 : -1
            )
            .map(data => {
              const startDate = new Date(
                data.start_at
              ).toLocaleDateString('en-MY', { month: 'short', day: 'numeric' });
              const endDate = new Date(data.end_at).toLocaleDateString(
                'en-MY',
                {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }
              );
              return (
                <AnnouncementCard
                  announcementTitle={data.title}
                  startDate={startDate}
                  endDate={endDate}
                  outletCount={data.outlets.length}
                  outlets={data.outlets}
                  renderBottomButton={[
                    {
                      title: t('Delete'),
                      onClick: () =>
                        openDeleteModal(data.id, outletAnnouncement),
                      filledButton: false,
                      event: {
                        screen_name: 'announcement',
                        tapped_on: 'btn_delete_announcement'
                      }
                    },
                    {
                      link: `${data.id}/edit`,
                      title: t('Edit'),
                      filledButton: true,
                      event: {
                        screen_name: 'announcement',
                        tapped_on: 'btn_edit_announcement'
                      }
                    }
                  ]}
                  className={Styles.AnnouncementCard}
                  key={data.id}
                  image_url={data.image_url}
                />
              );
            })}
      </>
    );
  };

  return (
    <>
      <AppLayout>
        {showSampleView ? (
          <div className={Styles.fullScreenSampleModal}>
            <Button
              className={Styles.closeButtonPadding}
              type="text"
              onClick={() => setShowSampleView(false)}
              size="lg"
            >
              <Icon icon="close" color="grayish-blue" size="1.5rem" />
            </Button>
            <RenderSampleImage />
          </div>
        ) : (
          <></>
        )}
        {isWebview ? (
          <WebviewHeading
            title={t('Announcements')}
            onBack={() => call(NATIVE.CLOSE_WEBVIEW)}
            backURL=""
          />
        ) : (
          <Heading
            title={t('Announcements')}
            subtitle={t(
              'Got something to share with your customer? Create an announcement'
            )}
          />
        )}
        {!isMaple && <BannerMessage
          type={'warning'}
          Component={() => (
            <Trans>
              <span>
                This feature will be discontinued, all announcements scheduled
                after <b>October</b> will be removed.
              </span>
            </Trans>
          )}
        />}
        {currentCompany ? (
          <>
            <div className={Styles.CardLayout}>
              {outletAnnouncement && outletAnnouncement?.length > 0 ? (
                <RenderAnnouncementCards />
              ) : (
                <RenderNoAnnouncementView />
              )}
            </div>

            <Button
              className={Styles.newAnnouncementButton}
              type="primary"
              leftIcon="cross"
              size="lg"
              onClick={() => {
                cleverTap.tapped.push({
                  screen_name: 'announcement',
                  tapped_on: 'btn_add_announcement'
                });
                navigate('announcements/create');
              }}
            >
              {t('New Announcement')}
            </Button>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </AppLayout>
    </>
  );
};

export default AnnouncementView;
