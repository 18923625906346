import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import { Redirect } from '@reach/router';

import LoginLayout from 'layouts/LoginLayout';
import { AuthContext } from 'contexts/AuthContext';
import { NotificationContext } from 'contexts/NotificationContext';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FormikErrorTimed from 'components/FormikErrorTimed';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import styles from 'assets/css/AuthenticateView.module.scss';
import Cookie from 'helpers/Cookie';
import { ModalContext } from 'contexts/ModalContext';
import NoInternetModal from 'components/NoInternetModal';

import { Link } from '@reach/router';

const AuthenticateView = props => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'pin' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  const queryParams = new URLSearchParams(props.location.search);

  const { setNotification } = useContext(NotificationContext);
  const { loginUser } = useContext(AuthContext);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { values: stateValues } = props.location.state || {};
  const noPreviousFormValues = !stateValues;
  const { init } = useContext(ModalContext);

  const submitForm = values => {
    setIsLoading(true);
    loginUser({
      ...stateValues,
      phone: stateValues.code + stateValues.phone,
      pin_number: values.pinNumber
    })
      .catch(({ value }) => {
        let message = '';
        if (window.navigator.onLine === false) {
          handleNoInternetError();
        } else {
          switch (value.response.error) {
            case 'Invalid password.':
              message = t('Invalid PIN. Please try again.');
              break;
            case 'Invalid email or phone':
              message = t(
                `The email or phone number you entered is not registered. Please enter a registered email or download the app to sign up`
              );
              break;
            default:
              message = value.response.error;
              break;
          }
          setNotification({ message, type: 'error' });
          setFormError(message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleNoInternetError = () => {
    init({
      Component: NoInternetModal,
      openOnReady: true,
      hasCloseButton: false,
      componentProps: {}
    });
  };

  return (
    <>
      {noPreviousFormValues ? (
        <Redirect noThrow to="/manager-login" />
      ) : (
        <LoginLayout
          withHeader
          screenName={'manager'}
          backButtonProps={{ to: '/manager-login' }}
          prevLocation={{
            state: { values: stateValues },
            url: props.location.pathname
          }}
          redirectedPath={
            queryParams.get('redirect_path') === null
              ? '/'
              : queryParams.get('redirect_path')
          }
        >
          <Formik
            enableReinitialize
            initialValues={{ pinNumber: '', isLoading }}
            validationSchema={Yup.object().shape({
              pinNumber: Yup.string()
                .matches(/^\d+$/, t('Only numbers allowed'))
                .length(6, t(`PIN should be 6 digits long`))
                .required(t('PIN is required'))
            })}
            onSubmit={submitForm}
            validateOnBlur={false}
          >
            {({ errors, touched, values }) => (
              <Form className={styles.form}>
                <h5 className={styles.method}>
                  {Cookie.get('method') === 'email'
                    ? t('Email Address')
                    : t(`Phone Number`)}
                </h5>
                <div className={styles.valueSection}>
                  <h4 className={styles.value}>{Cookie.get('currentValue')}</h4>
                  <Link to="/manager-login">
                    <Icon
                      icon={'edit-pen'}
                      size={'1.625rem'}
                      className={styles.valueIcon}
                    />
                  </Link>
                </div>
                <TextInput
                  {...{
                    type: 'tel',
                    name: 'pinNumber',
                    maxLength: 6,
                    placeholder: t('Enter your 6 digit PIN'),
                    focus: true,
                    errors,
                    touched
                  }}
                  inputMode="tel"
                />
                <FormikErrorTimed error={formError} onTimeout={setFormError} />
                <Button
                  type="text"
                  size="xs"
                  href="/forgot_pin"
                  state={{ values: stateValues }}
                  className={classNames(styles.button, styles.forgotPIN)}
                  onClick={() =>
                    cleverTap.tapped.push({
                      screen_name: 'pin',
                      tapped_on: 'btn_forgot_pin'
                    })
                  }
                >
                  {t('I forgot my PIN')}
                </Button>
                <div className={styles.submitButtonContainer}>
                  <Button
                    type="submit"
                    size="lg"
                    rightIcon="arrow-right"
                    className={classNames(styles.button, styles.submitButton)}
                    isLoading={values.isLoading}
                    onClick={() =>
                      cleverTap.tapped.push({
                        screen_name: 'pin',
                        tapped_on: 'btn_enter_pin'
                      })
                    }
                    disabled={values.pinNumber.length === 0}
                    fullWidth
                  >
                    {t('Go to My Dashboard')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </LoginLayout>
      )}
    </>
  );
};

export default AuthenticateView;
