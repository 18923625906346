import React from 'react';
import classNames from 'classnames';

import TappedButton from './TappedButton';
import styles from 'assets/css/PrimaryInvertedButton.module.scss';
import { IButtonProps } from './Button';

const PrimaryInvertedButton: React.FC<IButtonProps> = ({
  className,
  children,
  ...restProps
}) => (
  <TappedButton
    size="lg"
    isRounded
    className={classNames(styles.button, styles.inverted, className)}
    iconColor="apple-green"
    iconSize="1.5rem"
    {...restProps}
  >
    {children}
  </TappedButton>
);

export default PrimaryInvertedButton;
