import { useContext, useCallback } from 'react';
import { OutletContext } from 'contexts/OutletContext';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { InsightsOutletContext } from 'insights/contexts/InsightsOutletContext';
import { useCleverTapContext } from 'contexts/CleverTapContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { OutletContextType } from '../types/SettingsAPIResponseType';

export interface CleverTapScreenEvent {
  screen_name: string;
  section_name?: string;
  outletContext?: OutletContextType;
  props?: CleverTapProps;
}

export interface CleverTapTappedOnEvent extends CleverTapScreenEvent {
  tapped_on: string;
}

interface CleverTapProps {
  // fill as you migrate
}

/* Migrated to typescript from useCleverTap.js */
export default function useCleverTap() {
  const { isInsights } = useContext(InsightsOutletContext) || {};
  const { isOperations } = useContext(OpsAuthContext) || {};
  const { cleverTapEvent } = useCleverTapContext();

  const outletContext = useContext<OutletContextType>(
    (isInsights && InsightsOutletContext) ||
      (isOperations && OpsOutletContext) ||
      OutletContext
  );
  const { platform } = useContext(WebviewContext);
  const mode = isOperations ? 'staff' : 'manager';

  return {
    screenDisplayed: {
      push: useCallback(
        <T extends CleverTapScreenEvent>({
          screen_name,
          section_name,
          outletContext: outletContextFromProps,
          ...props
        }: T) => {
          if (!screen_name) {
            console.error(
              'useCleverTap: event object with screen_name required'
            );
            return;
          }

          cleverTapEvent.push({
            name: 'Screen Displayed',
            screen_name,
            section_name,
            props: {
              ...props,
              screen_width: window.innerWidth,
              screen_height: window.innerHeight
            },
            outletContext: outletContext, // || outletContextFromProps,
            platform,
            mode
          });
        },
        [cleverTapEvent, outletContext, platform, mode]
      )
    },
    tapped: {
      push: useCallback(
        <T extends CleverTapTappedOnEvent>({
          screen_name,
          tapped_on,
          section_name,
          outletContext: outletContextFromProps,
          ...props
        }: T) => {
          // TODO: commented out while migration is ongoing
          // if (!screen_name || !tapped_on) {
          //   console.error(
          //     'useCleverTap: event object with screen_name and tapped_on required'
          //   );
          //   return;
          // }

          cleverTapEvent.push({
            name: 'Tapped',
            screen_name,
            tapped_on,
            section_name,
            props: {
              ...props,
              screen_width: window.innerWidth,
              screen_height: window.innerHeight
            },
            outletContext: outletContext || outletContextFromProps,
            platform,
            mode
          });
        },
        [cleverTapEvent, mode, outletContext, platform]
      )
    }
  };
}
