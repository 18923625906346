import { useContext, useCallback } from 'react';
import { OutletContext } from 'contexts/OutletContext';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { InsightsOutletContext } from 'insights/contexts/InsightsOutletContext';
import { CleverTapContextOld } from 'contexts/CleverTapContextOld';
import { OpsAuthContext } from 'contexts/OpsAuthContext';
import { WebviewContext } from 'contexts/WebviewContext';

export default function useCleverTapOld() {
  const { isInsights } = useContext(InsightsOutletContext) || {};
  const { isOperations } = useContext(OpsAuthContext) || {};
  const { cleverTapEvent } = useContext(CleverTapContextOld);
  const outletContext = useContext(
    (isInsights && InsightsOutletContext) ||
      (isOperations && OpsOutletContext) ||
      OutletContext
  );
  const { platform } = useContext(WebviewContext);
  const mode = isOperations ? 'staff' : 'manager';

  return {
    event: {
      // TODO: remove once migration is complete. Moving forward, to use screenDisplayed or tapped only
      push: useCallback(
        event => {
          if (!(event || {}).name) {
            console.error('useCleverTap: event object with name required');
            return;
          }
          cleverTapEvent.push({
            ...event,
            props: {
              ...event.props,
              screen_width: window.innerWidth,
              screen_height: window.innerHeight
            },
            outletContext,
            platform,
            mode
          });
        },
        [cleverTapEvent, outletContext, platform, mode]
      )
    },
    screenDisplayed: {
      push: useCallback(
        ({
          screen_name,
          section_name,
          outletContext: outletContextFromProps,
          ...props
        }) => {
          if (!screen_name) {
            console.error(
              'useCleverTap: event object with screen_name required'
            );
            return;
          }

          cleverTapEvent.push({
            name: 'Screen Displayed',
            screen_name,
            section_name,
            props: {
              ...props,
              screen_width: window.innerWidth,
              screen_height: window.innerHeight
            },
            outletContext: outletContext || outletContextFromProps,
            platform,
            mode
          });
        },
        [cleverTapEvent, outletContext, platform, mode]
      )
    },
    tapped: {
      push: useCallback(
        ({
          screen_name,
          tapped_on,
          section_name,
          outletContext: outletContextFromProps,
          ...props
        }) => {
          // TODO: commented out while migration is ongoing
          // if (!screen_name || !tapped_on) {
          //   console.error(
          //     'useCleverTap: event object with screen_name and tapped_on required'
          //   );
          //   return;
          // }

          cleverTapEvent.push({
            name: 'Tapped',
            screen_name,
            tapped_on,
            section_name,
            props: {
              ...props,
              screen_width: window.innerWidth,
              screen_height: window.innerHeight
            },
            outletContext: outletContext || outletContextFromProps,
            platform,
            mode
          });
        },
        [cleverTapEvent, mode, outletContext, platform]
      )
    }
  };
}
