import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import success from 'assets/images/success.gif';
import styles from 'assets/css/ECard.module.scss';

export type ECardProps = {
  companyName: string;
  currency: string;
  sellingPrice: number;
  originalPrice: number;
  discountRate: number;
  outletImage: string;
  hasSuccessIcon: boolean;
  className?: string;
  textColor?: string;
  backgroundColor?: string;
};

const ECard: React.FC<ECardProps> = ({
  companyName = '',
  currency = '',
  sellingPrice = 0,
  originalPrice = 0,
  discountRate = 0,
  outletImage = '',
  hasSuccessIcon = false,
  className,
  textColor,
  backgroundColor
}) => {
  const { t } = useTranslation();

  return (
    <article
      className={classNames(className, styles.eCard)}
      style={
        {
          '--text-color': textColor,
          '--background-color': backgroundColor
        } as React.CSSProperties
      }
    >
      <img
        className={styles.companyLogo}
        src={outletImage}
        alt="Company Logo"
      />
      <h3 className={styles.companyName}>{companyName}</h3>
      <p className={styles.value}>
        {currency}
        {originalPrice}
      </p>
      <p className={styles.price}>
        {currency}
        {sellingPrice}
        <span className={styles.originalPrice}>
          {currency}
          {originalPrice}
        </span>
        <span className={styles.discount}>
          {t('{{discountRate}}% OFF', { discountRate })}
        </span>
      </p>
      {hasSuccessIcon && (
        <img className={styles.success} src={success} alt="success" />
      )}
    </article>
  );
};

export default ECard;
