import { useEffect, useContext, useState, useCallback } from 'react';
import { ModalContext } from 'contexts/ModalContext';
import Cookie from 'helpers/Cookie';
import { isFunction } from 'utils';

function useOnceModal({
  cookieName,
  onClose: parentOnClose,
  openOnReady,
  openOnlyIf,
  ...props
}) {
  const { init, open } = useContext(ModalContext);
  const COOKIE_NAME = `faveSeen${cookieName || 'OnceModal'}`;
  const [seenModal, setSeenModal] = useState(!!Cookie.get(COOKIE_NAME));

  const onClose = useCallback(() => {
    if (isFunction(parentOnClose)) parentOnClose();
    Cookie.set(COOKIE_NAME, true, {
      maxAge: 60 * 60 * 24 * 365 * 10
    }); // never expired (10 years maxAge)
    setSeenModal(true);
  }, [COOKIE_NAME, parentOnClose]);

  useEffect(() => {
    const canShow = openOnReady && (!openOnlyIf || openOnlyIf());

    if (seenModal || !canShow) return;

    init({
      name: cookieName,
      onClose,
      openOnReady,
      openOnlyIf,
      ...props
    });
  }, [
    seenModal,
    openOnReady,
    openOnlyIf,
    COOKIE_NAME,
    cookieName,
    init,
    onClose,
    props
  ]);

  return {
    open: () => !seenModal && open()
  };
}

export default useOnceModal;
