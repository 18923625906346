import React from 'react';

import Button from 'components/Button';
import styles from 'insights/assets/css/Footer.module.scss';

const Footer = () => {
  const ButtonIcon = ({ className, link, icon, iconAlt }) => (
    <Button type="ghost" className={className} href={link}>
      <img className={styles.image} src={icon} alt={iconAlt} />
    </Button>
  );

  return (
    <footer className={styles.footer}>
      <div className={styles.iconContainer}>
        <ButtonIcon
          className={styles.socialIcon}
          link="https://www.facebook.com/favebiz"
          icon={require('insights/assets/images/footer/logo-facebook.svg')}
          iconAlt="favebiz facebook website"
        />
        <ButtonIcon
          className={styles.socialIcon}
          link="https://twitter.com/fave_biz"
          icon={require('insights/assets/images/footer/logo-twitter.svg')}
          iconAlt="favebiz twitter website"
        />
        <ButtonIcon
          className={styles.socialIcon}
          link="https://www.linkedin.com/company/fave-group"
          icon={require('insights/assets/images/footer/logo-linkedin.svg')}
          iconAlt="favebiz linkedin website"
        />
        <ButtonIcon
          className={styles.socialIcon}
          link="https://www.youtube.com/channel/UC9iLlvqHsXH90FtgGgCa2Og"
          icon={require('insights/assets/images/footer/logo-youtube.svg')}
          iconAlt="favebiz youtube website"
        />
      </div>
      <Button
        type="ghost"
        href="https://www.favebiz.com"
        className={styles.externalLink}
      >
        www.favebiz.com
      </Button>
      <div className={styles.appLinkContainer}>
        <ButtonIcon
          className={styles.storeBadge}
          link="https://play.google.com/store/apps/details?id=com.favebiz.android"
          icon={require('insights/assets/images/footer/google-play-badge.png')}
          iconAlt="favebiz google play store"
        />
        <ButtonIcon
          className={styles.storeBadge}
          link="https://apps.apple.com/my/app/favebiz/id1440119835"
          icon={require('insights/assets/images/footer/app-store-badge.png')}
          iconAlt="favebiz app store"
        />
      </div>
    </footer>
  );
};

export default Footer;
