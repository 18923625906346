import { useMemo } from 'react';
import useSWR from 'swr';

import Kfit from 'integrations/Kfit';

const objectToArray = params => Object.keys(params).map(key => params[key]);

export default ({
  url,
  params = {},
  swrOptions: {
    if: runIt = true /* conditional fetch */,
    reload = false /* cache by default */
  } = {}
}) => {
  /* refresh by new date if runIt && reload true */
  const shouldRefresh = useMemo(
    () => runIt && reload && new Date().toString(),
    [runIt, reload]
  );

  /* dependency array change causes cache revalidation, array = key */
  const key = objectToArray({ url, ...params, shouldRefresh });

  return useSWR(runIt ? key : null, () => Kfit.get(url, params), {
    revalidateOnFocus: reload
  });
};
