import Kfit from '../integrations/Kfit';
import DataLoader from 'dataloader';

export default params => loadInfo(`/v7/qr_codes?outlet_id=${params.id}`);

export const Repo = new DataLoader(
  keys =>
    Promise.all(keys.map(key => Kfit.get(key).then(response => response))),
  {
    batch: false,
    cacheKeyFn: key => JSON.stringify(key)
  }
);

function loadInfo(key) {
  return Repo.load(key);
}
