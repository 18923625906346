import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { navigate } from '@reach/router';
import classNames from 'classnames';

import { COUNTRY_CODES } from 'helpers/TypeConstants';
import { NotificationContext } from 'contexts/NotificationContext';
import getMigrationDetails from 'queries/getMigrationDetails';
import LoginLayout from 'layouts/LoginLayout';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Icon from 'components/Icon';
import PhoneInput from 'components/PhoneInput';
import FormikErrorTimed from 'components/FormikErrorTimed';
import styles from 'assets/css/ManagerLoginView.module.scss';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import Cookie from 'helpers/Cookie';
import { MapleContext } from 'contexts/MapleContext';
import { ModalContext } from 'contexts/ModalContext';
import NoInternetModal from 'components/NoInternetModal';

const SINGAPORE = COUNTRY_CODES[2].code;

const ManagerLoginView = props => {
  const { setNotification } = useContext(NotificationContext);
  const cleverTap = useCleverTapOld();
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const { init } = useContext(ModalContext);

  const isEdmLogin = props.location.pathname.includes('/edm-manager-login');
  const queryParams = new URLSearchParams(props.location.search);
  const countryCode = ['my', 'sg', 'id'].includes(queryParams.get('country'))
    ? queryParams.get('country')
    : 'my';

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'login_manager' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  /*
    Dia:
    MY: +60XX XXX XXXX (min 9)
    SG: +65 XXXX XXXX (min 8)
    ID: +62 XX XXX XXXX (min 9)
  */
  const LogInSchema = Yup.object().shape({
    method: Yup.string().required('Required'),
    phone: Yup.string().when('method', {
      is: 'phone',
      then: Yup.string()
        .required(t('Phone number incomplete'))
        .when('code', {
          is: SINGAPORE,
          then: Yup.string().matches(/\d{8,}$/, t('Minimum 8 digits')),
          otherwise: Yup.string().matches(/\d{9,}$/, t('Minimum 9 digits'))
        })
    }),
    email: Yup.string().when('method', {
      is: 'email',
      then: Yup.string()
        .email(t('Invalid email'))
        .required(t('Email required'))
    })
  });

  const [loginMethod, setLoginMethod] = useState(isMaple ? 'phone' : 'email');
  const isLoginMethodPhone = loginMethod === 'phone';

  const [formError, setFormError] = useState('');
  useEffect(() => () => setFormError(''), [loginMethod]);

  const submitForm = (values, formikBag) => {
    values.phone =
      values.phone[0] === '0' ? values.phone.slice(1) : values.phone;
    getMigrationDetails({
      method: loginMethod,
      value: isLoginMethodPhone ? `${values.code}${values.phone}` : values.email
    })
      .then(response => {
        if (!response.require_migration) {
          Cookie.set(
            'currentValue',
            isLoginMethodPhone ? values.phone : values.email
          );
          Cookie.set('method', loginMethod);
        }
        navigate(
          response.require_migration
            ? '/forgot_pin' + props.location.search
            : '/authenticate' + props.location.search,
          {
            state: { values }
          }
        );
      })
      .catch(error => {
        if ('value' in error) {
          const { value } = error;
          setNotification({ message: value.response.error, type: 'error' });
          setFormError(value.response.error);
        } else {
          if (window.navigator.onLine === false) {
            handleNoInternetError();
          } else {
            setNotification({ message: error.toString(), type: 'error' });
          }
        }
      })
      .finally(() => formikBag.setSubmitting(false));
  };

  const handleNoInternetError = () => {
    init({
      Component: NoInternetModal,
      openOnReady: true,
      hasCloseButton: false,
      componentProps: {}
    });
  };

  const EmailInput = ({ errors, touched }) => (
    <TextInput
      type="email"
      name="email"
      placeholder={t('Enter your email')}
      focus
      {...{ errors, touched }}
    />
  );

  const LoginMethodRadio = ({ name, onChange }) => {
    const isRadioPhone = name === 'phone';
    const isActive = isLoginMethodPhone === isRadioPhone;

    return (
      <label
        htmlFor={name}
        className={classNames(isActive ? styles.active : styles.inactive)}
      >
        <input
          type="radio"
          id={name}
          name="method"
          onChange={onChange}
          checked={isActive}
        />
        <Icon
          icon={isRadioPhone ? 'mobile-phone' : 'envelope'}
          color={isActive ? 'apple-green' : 'gray'}
          size="2.125rem"
        />
        {isRadioPhone ? t('Login with number') : t('Login with email')}
      </label>
    );
  };
  const LoginMethodTabs = ({ onChange }) => (
    <section className={styles.loginMethod}>
      <LoginMethodRadio name="email" onChange={onChange} />
      <LoginMethodRadio name="phone" onChange={onChange} />
    </section>
  );

  const SubmitButton = ({ isSubmitting, disabled }) => (
    <Button
      type="submit"
      size="lg"
      rightIcon="arrow-right"
      justify="space-between"
      className={styles.submitButton}
      disabled={disabled ? true : isSubmitting}
      onClick={() =>
        cleverTap.tapped.push({
          screen_name: 'login_manager',
          tapped_on: 'btn_continue'
        })
      }
    >
      {t('Next')}
    </Button>
  );

  return (
    <LoginLayout
      screenName={isEdmLogin ? 'edmManager' : 'manager'}
      backButtonProps={isEdmLogin ? null : { to: '/login' }}
      withHeader
      managerLogin={true}
      prevLocation={{ url: props.location.pathname }}
      countryCode={countryCode}
    >
      <Formik
        initialValues={{
          phone:
            Cookie.get('method') === 'phone' ? Cookie.get('currentValue') : '',
          email:
            Cookie.get('method') === 'email' ? Cookie.get('currentValue') : '',
          method: loginMethod,
          code: isMaple ? '+91' : COUNTRY_CODES[0].code
        }}
        validationSchema={LogInSchema}
        validateOnBlur={false}
        onSubmit={submitForm}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          resetForm,
          values
        }) => (
          <Form className={styles.form}>
            {!isMaple && (
              <LoginMethodTabs
                onChange={({ target }) => {
                  if ('checked' in target && target.checked) {
                    resetForm();
                    setLoginMethod(target.id);
                    target.id === 'phone'
                      ? cleverTap.tapped.push({
                          screen_name: 'login_manager',
                          tapped_on: 'btn_login_number'
                        })
                      : cleverTap.tapped.push({
                          screen_name: 'login_manager',
                          tapped_on: 'btn_login_email'
                        });
                    setFieldValue('method', target.id);
                  }
                }}
              />
            )}
            {isLoginMethodPhone ? (
              <PhoneInput {...{ errors, touched }} />
            ) : (
              <EmailInput {...{ errors, touched }} />
            )}
            <FormikErrorTimed error={formError} onTimeout={setFormError} />
            <SubmitButton
              disabled={
                isLoginMethodPhone
                  ? (values.phone || '').length === 0
                  : (values.email || '').length === 0
              }
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </LoginLayout>
  );
};

export default ManagerLoginView;
