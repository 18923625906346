import 'whatwg-fetch';
import qs from 'qs';
import Cookie from '../helpers/Cookie';
import ResponseHandler from '../helpers/ResponseHandler';

export const generateStagingList = () => {
  const stagingKeys = {};

  const apiTypes = ['PARTNER_API', 'CONSUMER_API'];

  Object.keys(process.env).forEach(key => {
    const segments = key.split('_');

    if (
      apiTypes.some(stagingKey => key.includes(stagingKey)) &&
      segments.length === 6 // staging name contains 6 segments
    )
      stagingKeys[segments[2]] = true;
  });

  return Object.keys(stagingKeys).map(key => key.toLowerCase());
};

export const getStagingInfo = () => {
  const { REACT_APP_IS_PRODUCTION } = process.env;
  const stagingNameCookie = Cookie.get('stagingName');

  const isProduction = REACT_APP_IS_PRODUCTION === 'true';

  const stagingName =
    isProduction || !stagingNameCookie ? '' : stagingNameCookie;
  const stagingIdentifier = stagingName
    ? `_${stagingName.toLocaleUpperCase()}`
    : stagingName;

  return {
    isProduction,
    stagingName,
    stagingIdentifier,
    switchStagingServer: stagingName => {
      const stagingList = generateStagingList();

      const exists = stagingList.some(name => name === stagingName);

      if (exists) {
        Cookie.set('stagingName', stagingName);
        window.location.reload();
      } else throw new Error(`Invalid staging server ${stagingName}`);
    }
  };
};

const { isProduction, stagingIdentifier } = getStagingInfo();

export default class Kfit {
  static ENDPOINTS() {
    return {
      consumer: isProduction
        ? 'REACT_APP_KFIT_CONSUMER_API'
        : `REACT_APP${stagingIdentifier}_KFIT_CONSUMER_API`,
      partner: isProduction
        ? 'REACT_APP_KFIT_PARTNER_API'
        : `REACT_APP${stagingIdentifier}_KFIT_PARTNER_API`
    };
  }

  static endpoint = process.env[this.ENDPOINTS().partner];

  static useEndpoint(endpoint = this.ENDPOINTS().partner) {
    return process.env[endpoint] || endpoint;
  }

  static defaultHeaders() {
    const headers = {
      'content-type': 'application/json',
      'x-user-agent': 'Fave-Web-Biz/v1.0.0'
    };
    const accessToken = Cookie.get('accessToken');

    if (accessToken && accessToken !== '')
      headers.authorization = `Bearer ${accessToken}`;

    const authCode = Cookie.get('authCode');

    if (authCode && authCode !== '') headers['X-Partner-Code'] = authCode;

    const currentOutletCode = Cookie.get('currentOutletCode');

    if (currentOutletCode && currentOutletCode !== '')
      headers['X-Partner-Code'] = currentOutletCode;

    return headers;
  }

  static request(resource, request, init = {}) {
    init.headers = { ...this.defaultHeaders(), ...init.headers };
    init.body = request ? JSON.stringify(request) : null;
    return fetch(`${this.useEndpoint(init.endpoint)}${resource}`, init).then(
      ResponseHandler
    );
  }

  static multipartRequest(resource, request, init = {}) {
    const defaultHeaders = this.defaultHeaders();
    delete defaultHeaders['content-type'];

    init.headers = { ...defaultHeaders, ...init.headers };
    init.body = request;

    return fetch(`${this.useEndpoint(init.endpoint)}${resource}`, init).then(
      ResponseHandler
    );
  }

  static post(resource, request, init = {}) {
    init.method = 'POST';
    return request instanceof FormData
      ? this.multipartRequest(resource, request, init)
      : this.request(resource, request, init);
  }

  static get(resource, request, init = {}) {
    init.method = 'GET';
    return this.request(
      `${resource}${qs.stringify(request, {
        arrayFormat: 'brackets',
        addQueryPrefix: true,
        skipNulls: true
      })}`,
      null,
      init
    );
  }

  static put(resource, request, init = {}) {
    init.method = 'PUT';
    return this.request(resource, request, init);
  }

  static patch(resource, request, init = {}) {
    init.method = 'PATCH';
    return this.request(resource, request, init);
  }

  static delete(resource, request, init = {}) {
    init.method = 'DELETE';
    return this.request(resource, request, init);
  }
}
