import React, { useEffect, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import * as Yup from 'yup';

import { COUNTRY_CODES } from 'helpers/TypeConstants';
import { getCountryCodeByCitySlug } from 'utils';
import claimStarterKit from 'commands/claimStarterKit';
import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { LinkButton } from 'components/Button';
import TextArea from 'components/TextArea';
import PhoneInput from 'components/PhoneInput';
import TextInput from 'components/TextInput';
import SectionHeader from './SectionHeader';
import FormGrid from './FormGrid';
import starterKitImage from 'assets/images/img-starter-kit.svg';
import starterKitImageDuitNow from 'assets/images/duitNow/img-starter-kit.svg';
import deliveryAddressIcon from 'assets/images/ic-location-black.svg';
import profileIcon from 'assets/images/ic-profile-black.svg';
import useCleverTapOld from 'hooks/useCleverTapOld';

import StepCTASection from './StepCTASection';
import SetupCard from './SetupCard';
import { useTranslation, Trans } from 'react-i18next';
import styles from 'assets/css/FavePaySetupView/StarterKitView.module.scss';
import getRPPQRCode from 'queries/getRPPQRCode';
import LoadingSpinnerSmall from 'components/LoadingSpinnerSmall';

const SINGAPORE = COUNTRY_CODES[2].code;

function StarterKitView({ onSubmit, state }) {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { user, updateUser } = useContext(AuthContext);
  const { setNotification } = useContext(NotificationContext);
  const { outlets, currentOutlet } = useContext(OutletContext);

  // needed by showDuitNow
  const [theQRCode, setTheQRCode] = useState();
  const companyOutlets = outlets || [];
  const firstOutlet = companyOutlets[0] || {};
  const [firstCompany] = user.companies || [];
  const isCitySingapore = user.city.slug === 'singapore';
  const [isLoading, setIsLoading] = useState(true);

  const outletAddress =
    (state || {}).outletAddress || firstOutlet.address || '';

  const city_slug = (user.city || {}).slug;

  // needs theQRCode
  const showDuitNow = () =>
    city_slug === 'kuala-lumpur' && theQRCode?.rpp_qr_enabled;

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);

  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({
      screen_name: 'payment_setup3_starterkit'
    });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  // set QR code
  useEffect(() => {
    let isMounted = true;
    if (currentOutlet) {
      getRPPQRCode()
        .then(res => {
          if (!isMounted) return;
          setTheQRCode(res);
          setIsLoading(false);
        })
        .catch(error => {
          if (!isMounted) return;
          if (city_slug !== 'jakarta')
            setNotification({
              message: error.value.response.error,
              type: 'error'
            });
          setIsLoading(false);
        });
    }
    return () => (isMounted = false);
  }, [setNotification, currentOutlet, city_slug]);

  function submitForm(values) {
    const { deliveryAddress, sgQrId, recipientName, code, phone } = values;
    const params = {
      outlet_id: firstOutlet.id,
      delivery_address: deliveryAddress,
      sgqr_id: sgQrId,
      recipient_name: recipientName,
      recipient_phone_number: phone ? `${code}${phone}` : undefined //'+60123456789'
    };

    claimStarterKit(firstCompany.id, params)
      .then(user => {
        updateUser(user);
        onSubmit();
        cleverTap.tapped.push({
          screen_name: 'payment_setup3_starterkit',
          tapped_on: 'btn_complete'
        });
        // give time for state update to prevent race condition
        setTimeout(() => {
          navigate('/favepay');
        }, 500);
      })
      .catch(error => {
        setNotification({
          message: error.value.response.error,
          type: 'error'
        });
      });
  }
  return (
    <section>
      <Formik
        enableReinitialize
        initialValues={{
          deliveryAddress: outletAddress,
          sgqr_id: undefined,
          recipientName: '',
          code: getCountryCodeByCitySlug(user.city.slug),
          phone: (state || {}).phone || ''
        }}
        validationSchema={Yup.object().shape({
          deliveryAddress: Yup.string().required(t('Required')),
          recipientName: Yup.string().required(t('Required')),
          phone: Yup.string()
            .required(t('Phone number incomplete'))
            .when('code', {
              is: SINGAPORE,
              then: Yup.string().matches(/\d{8,}$/, t('Minimum 8 digits')),
              otherwise: Yup.string().matches(/\d{9,}$/, t('Minimum 9 digits'))
            })
        })}
        onSubmit={submitForm}
      >
        {({ errors, touched }) => (
          <Form>
            {isLoading ? (
              <LoadingSpinnerSmall className={styles.loadingSpinner} />
            ) : (
              <>
                <SetupCard className={styles.card}>
                  <FormGrid className={styles.heroLayout}>
                    <img
                      className={styles.heroImage}
                      src={
                        showDuitNow() ? starterKitImageDuitNow : starterKitImage
                      }
                      alt="Claim starter kit"
                    />
                    <section className={styles.heroTextLayout}>
                      <h3 className={styles.header}>{t('Free starter kit')}</h3>
                      <p className={styles.paragraph}>
                        {t(
                          'Let customers know that your outlet now accepts FavePay. Your free starter kit will be delivered within 7 working days.'
                        )}
                      </p>
                      <h4 className={styles.subheader}>
                        {t('The starter kit contains:')}
                      </h4>
                      <Trans>
                        <ul className={styles.kitDetails}>
                          <li>
                            {showDuitNow() ? 'DuitNow' : 'Fave'} QR Code standee
                          </li>
                          <li>Wobbler (POS terminal signage)</li>
                          <li>Door / window sticker (exterior signage)</li>
                          <li>Wall sticker (interior signage)</li>
                          <li>1-pager training guide for Cashier</li>
                        </ul>
                      </Trans>
                    </section>
                  </FormGrid>
                  <section className={styles.deliveryLayout}>
                    <SectionHeader
                      icon={deliveryAddressIcon}
                      title={t('Delivery address')}
                    />
                    <FormGrid>
                      <TextArea
                        name="deliveryAddress"
                        placeholder={t('Delivery address')}
                        {...{ errors, touched }}
                      />
                      {isCitySingapore && (
                        <TextInput
                          type="text"
                          name="sgQrId"
                          placeholder={t('Outlet SGQR ID (optional)')}
                          {...{ errors, touched }}
                        />
                      )}
                    </FormGrid>
                  </section>
                  <section className={styles.recipientLayout}>
                    <SectionHeader
                      icon={profileIcon}
                      title={t('Recipient details')}
                    />
                    <FormGrid>
                      <TextInput
                        type="text"
                        name="recipientName"
                        placeholder={t('Recipient’s name*')}
                        {...{ errors, touched }}
                      />
                      <PhoneInput
                        compact
                        placeholder={t('Enter phone number*')}
                        {...{ errors, touched }}
                      />
                    </FormGrid>
                  </section>
                </SetupCard>
                <StepCTASection submitText={t('Complete')}>
                  <LinkButton href="/favepay">
                    {t(`No, I don't need this now.`)}
                  </LinkButton>
                </StepCTASection>
              </>
            )}
          </Form>
        )}
      </Formik>
    </section>
  );
}

StarterKitView.propTypes = {
  onSubmit: PropTypes.func
};

StarterKitView.defaultProps = {
  onSubmit: () => {}
};

export default StarterKitView;
