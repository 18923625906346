import React, { useState, useEffect, useContext, useCallback } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/analytics';
import { useTranslation } from 'react-i18next';

import { initializeFirebaseApp } from 'integrations/firebase';
import { hasKeys } from 'utils';
import { NotificationContext } from 'contexts/NotificationContext';
import useAuthContext from 'hooks/useAuthContext';
import { LangContext } from 'contexts/LangContext';
import { ModalContext } from 'contexts/ModalContext';
import { WebviewContext } from 'contexts/WebviewContext';
import AllowPushNotificationsModal from 'components/AllowPushNotificationsModal';
import subscribeToFCMTopic from 'commands/subscribeToFCMTopic';

export const COUNTRIES_BY_CITY = {
  'kuala-lumpur': 'my',
  jakarta: 'id',
  singapore: 'sg'
};

let db;

const FCMContext = React.createContext();

function useInitializeFirebaseApp() {
  useEffect(() => {
    initializeFirebaseApp();
  }, []);
}

function useInitializeFCM({ isBrowserSupported, spawnNotification }) {
  useEffect(() => {
    if (!isBrowserSupported) return;
    db = firebase.database();
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(process.env.REACT_APP_FCM_PUBLIC_VAPID_KEY);
    messaging.onMessage(spawnNotification);
  }, [isBrowserSupported, spawnNotification]);
}

const FCMContextProvider = props => {
  const { setNotification } = useContext(NotificationContext);
  const { isWebview } = useContext(WebviewContext);
  const { init } = useContext(ModalContext);
  const { user } = useAuthContext();
  const { currentLang } = useContext(LangContext);
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState([]);
  const [isGettingToken, setIsGettingToken] = useState(false);

  const isBrowserSupported = isWebview
    ? false
    : firebase.messaging.isSupported();

  const spawnNotification = useCallback(
    ({ notification: { body, title } }) => {
      const options = {
        body,
        icon: require('assets/images/favebiz-appicon.png')
      };

      try {
        new Notification(title, options);
      } catch (e) {
        setNotification({
          message: `${title}: ${body}`,
          type: 'info'
        });
      }
    },
    [setNotification]
  );

  const getFCMToken = useCallback(
    setToken => {
      const messaging = firebase.messaging();
      if (Notification.permission === 'granted') {
        messaging.getToken().then(setToken);
      } else if (Notification.permission === 'denied') {
        setNotification({
          message: t(
            'Notifications are blocked. Please enable browser permissions'
          ),
          type: 'error'
        });
      } else {
        init({
          Component: AllowPushNotificationsModal,
          openOnReady: true,
          onClose: ({ showDialog }) => {
            /* registers firebase-messaging-sw.js */
            if (showDialog) {
              setIsGettingToken(true);
              messaging
                .getToken()
                .then(setToken)
                .catch(e => {
                  console.log(`getToken e:`, e);
                })
                .finally(() => setIsGettingToken(false));
            }
          }
        });
      }
    },
    [init, t, setNotification]
  );

  function persistSubscriptionToFirebaseDB({ token, topic, subscribed }) {
    db.ref(`/tokens/${token}${topic}`).set({ subscribed });
  }

  const setSubscriptionsFromFirebaseDB = useCallback(
    (token, locale = currentLang) => {
      if (!hasKeys(user)) return;
      if (!user.city.slug) return;

      db.ref(`tokens/${token}/topics`).once('value', snapshot => {
        const topics = snapshot.val();
        if (!topics) return;

        const country = COUNTRIES_BY_CITY[user.city.slug];
        const relevantTopics = Object.keys(topics)
          .filter(key => new RegExp(`${country}_\\d*_${locale}`).test(key))
          .filter(key => topics[key].subscribed);
        // TODO: remove console
        console.log(`firebasedb subscriptions:`, relevantTopics);
        setSubscriptions(relevantTopics);
      });
    },
    [currentLang, user]
  );

  const toggleSubscription = useCallback(
    ({ outlet, subscribed, locale = currentLang, reRender = true }) => {
      const country = COUNTRIES_BY_CITY[user.city.slug];
      const topic = `/topics/${country}_${outlet.id}_${locale}`;

      getFCMToken(token => {
        persistSubscriptionToFirebaseDB({ token, topic, subscribed });
        subscribeToFCMTopic({ token, topic, unsubscribe: !subscribed });
        if (reRender) setSubscriptionsFromFirebaseDB(token, locale);
      });
    },
    [user, currentLang, setSubscriptionsFromFirebaseDB, getFCMToken]
  );

  const unsubscribeAll = useCallback(() => {
    if (!isBrowserSupported) return;
    if (Notification.permission !== 'granted') return;
    getFCMToken(token =>
      subscriptions.forEach(subscription => {
        const topic = `/topics/${subscription}`;
        persistSubscriptionToFirebaseDB({ token, topic, subscribed: false });
        subscribeToFCMTopic({ token, topic, unsubscribe: true });
      })
    );
  }, [subscriptions, getFCMToken, isBrowserSupported]);

  const resubscribeAllOnLocaleChange = useCallback(
    locale => {
      if (subscriptions.length === 0) return;
      unsubscribeAll();
      subscriptions.forEach(subscription =>
        /* e.g. subscription = "my_11640_ms-MY", outlet id at index 1 */
        toggleSubscription({
          outlet: { id: subscription.split('_')[1] },
          locale,
          subscribed: true,
          reRender: false
        })
      );
    },
    [subscriptions, toggleSubscription, unsubscribeAll]
  );

  useInitializeFirebaseApp();
  useInitializeFCM({ isBrowserSupported, spawnNotification });

  useEffect(() => {
    if (!isBrowserSupported) return;
    if (Notification.permission !== 'granted') return;

    let isMounted = true;
    getFCMToken(token => isMounted && setSubscriptionsFromFirebaseDB(token));
    return () => (isMounted = false);
  }, [setSubscriptionsFromFirebaseDB, getFCMToken, isBrowserSupported]);

  const implementation = {
    isGettingToken,
    isBrowserSupported,
    subscriptions,
    toggleSubscription,
    unsubscribeAll,
    resubscribeAllOnLocaleChange
  };

  return (
    <FCMContext.Provider value={implementation}>
      {props.children}
    </FCMContext.Provider>
  );
};

export { FCMContext, FCMContextProvider };
