import { useEffect } from 'react';

const useNavigateAwayWarning = (enabled: boolean | (() => boolean)) => {
  useEffect(() => {
    const event = 'beforeunload';

    const handler = (e: BeforeUnloadEvent) => {
      const showPrompt = typeof enabled === 'boolean' ? enabled : enabled();

      if (showPrompt) {
        // message will be replaced by browser
        const message = 'Are you sure you want to leave?';

        (e || window.event).returnValue = message;
        return message;
      }
    };

    window.addEventListener(event, handler);
    return () => window.removeEventListener(event, handler);
  }, [enabled]);
};

export default useNavigateAwayWarning;
