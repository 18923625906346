import Button, { TappedButton } from 'components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterPane from './common/FilterPane';
import style from 'assets/css/FaveFeedToggleFilter.module.scss';

type ButtonPropsType = {
  text: string;
  onClick: () => void;
};

export type ToggleFilterPropsTypes = {
  buttonProps: ButtonPropsType[];
  value: string;
  showFeedButtonSmall?: boolean;
  onClickCreatePost?: () => void;
};

export type FilterButtonType = {
  className?: string;
  event: ScreenEvent;
};

export type ScreenEvent = {
  screen_name?: string;
  tapped_on?: string;
};

const FaveFeedToggleFilter = ({
  buttonProps,
  value,
  showFeedButtonSmall = false,
  onClickCreatePost
}: React.PropsWithChildren<ToggleFilterPropsTypes>) => {
  const { t } = useTranslation();

  return (
    <FilterPane className={style.filterPane}>
      {buttonProps.map(item => (
        <TappedButton
          key={item.text}
          type={
            value.toLowerCase() === item.text.toLowerCase()
              ? 'primary'
              : 'default'
          }
          isRedirect={false}
          isRounded
          {...item}
        >
          {t(item.text)}
        </TappedButton>
      ))}

      {showFeedButtonSmall && (
        <Button
          className={style.marginLeftAuto}
          type={'primary'}
          leftIcon={'cross'}
          size={'lg'}
          onClick={onClickCreatePost}
        >
          {t('New feed post')}
        </Button>
      )}
    </FilterPane>
  );
};

export default FaveFeedToggleFilter;
