import React from 'react';
import { Location } from '@reach/router';
import qs from 'qs';

import { queryParamsDecoder } from 'utils';

const QueryStringContext = React.createContext();

const QueryStringContextProvider = props => (
  <Location>
    {({ location }) => (
      <QueryStringContext.Provider
        value={qs.parse(location.search, {
          ignoreQueryPrefix: true,
          decoder: queryParamsDecoder
        })}
      >
        {props.children}
      </QueryStringContext.Provider>
    )}
  </Location>
);

export { QueryStringContext, QueryStringContextProvider };
