import { TappedButton } from 'components/Button';
import React from 'react';
import FilterPane from './FilterPane';

export type ToggleFilterProps = {
  activeButtonProps: React.PropsWithChildren<FilterButtonProps>;
  inactiveButtonProps: React.PropsWithChildren<FilterButtonProps>;
  value: boolean;
  onChange: (val: boolean) => void;
};

export type FilterButtonProps = {
  className?: string;
  event: ScreenEvent;
};

export type ScreenEvent = {
  screen_name?: string;
  tapped_on?: string;
};

const ToggleFilter = ({
  activeButtonProps,
  inactiveButtonProps,
  value,
  onChange
}: React.PropsWithChildren<ToggleFilterProps>) => {
  const toggleHandler = (val: boolean) => {
    if (val === value) return;

    onChange(val);
  };

  return (
    <FilterPane>
      <TappedButton
        type={value ? 'primary' : 'default'}
        isRedirect={false}
        isRounded
        onClick={() => toggleHandler(true)}
        {...activeButtonProps}
      />

      <TappedButton
        type={value ? 'default' : 'primary'}
        isRedirect={false}
        isRounded
        onClick={() => toggleHandler(false)}
        {...inactiveButtonProps}
      />
    </FilterPane>
  );
};

export default ToggleFilter;
