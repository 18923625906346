import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import Cookie from 'helpers/Cookie';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import styles from 'assets/css/FinancingLendingView.module.scss';

const FinancingFeedbackModal = ({ onClose }) => {
  const { t } = useTranslation();
  const [isOthersChecked, setIsOthersChecked] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const [loanNotNeeded, setLoanNotNeeded] = useState(false);
  const [checkboxes, setCheckboxes] = useState([
    false,
    false,
    false,
    false,
    false
  ]);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  useEffect(() => {
    if (loanNotNeeded) {
      setCheckboxes(prev => prev.map(checked => false));
      setIsOthersChecked(false);
    }
  }, [loanNotNeeded]);

  const loanReasons = [
    t('Loan amount too little'),
    t('Tenure too short'),
    t('Interest rates too high'),
    t('I only take loans from banks'),
    t('Others')
  ];

  const handleFeedbackSubmission = e => {
    e.preventDefault();
    const reasons = loanNotNeeded
      ? "I don't need a loan"
      : loanReasons
          .filter((r, i) => checkboxes[i])
          .map(r => (r === 'Others' ? otherReason : r))
          .join(', ');

    const params = {
      ...Cookie.get('financingFeedback'),
      reason: reasons,
      created_at: moment()
        .utc()
        .toISOString()
    };

    firebase
      .firestore()
      .collection(process.env.REACT_APP_FIRESTORE_COLLECTION)
      .doc()
      .set(params);

    Cookie.remove('financingFeedback');
    setIsFeedbackSubmitted(true);
  };

  return (
    <div className={styles.feedbackModal}>
      {isFeedbackSubmitted ? (
        <>
          <p>{t('Thank you for your feedback')}</p>
          <Button onClick={onClose} type="primary" justify="center">
            {t('Close')}
          </Button>
        </>
      ) : (
        <>
          <h3>
            <Trans>
              This isn’t for you? <br /> <span>Tell us why</span>, we’d love to
              know!
            </Trans>
          </h3>
          <small>{t('Please select those relevant to you.')}</small>
          <form onSubmit={handleFeedbackSubmission}>
            <Checkbox
              type="green"
              onChange={e => setLoanNotNeeded(e.target.checked)}
            >
              <p>{t("I don't need a loan")}</p>
            </Checkbox>
            <p className={styles.needLoan}>{t('I need a loan, but:')}</p>
            {loanReasons.map((reason, index) => (
              <Checkbox
                key={index}
                type="green"
                checked={checkboxes[index]}
                disabled={loanNotNeeded}
                onChange={e => {
                  setCheckboxes(prev =>
                    prev.map((prevChecked, prevIndex) =>
                      index === prevIndex ? !prevChecked : prevChecked
                    )
                  );

                  if (index === loanReasons.length - 1)
                    setIsOthersChecked(prevState => !prevState);
                }}
              >
                <p>{reason}</p>
              </Checkbox>
            ))}
            <input
              disabled={!isOthersChecked}
              type="text"
              placeholder={t('Tell us why')}
              value={otherReason}
              onChange={e => setOtherReason(e.target.value)}
            />
            <small>
              {t(
                'By giving us feedback, you help us to create better value for you and your business.'
              )}
            </small>
            <Button
              type="submit"
              justify="center"
              disabled={!loanNotNeeded && !checkboxes.some(c => c)}
            >
              {t('Submit')}
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default FinancingFeedbackModal;
