import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { PrimaryInvertedButton } from 'components/Button';
import getPaymentLink from 'queries/getPaymentLink';
import numeral from 'numeral';

import styles from 'assets/css/ReceivePaymentView.module.scss';
import { copyToClipboard } from 'utils';

const EnterPaymentModal = React.forwardRef((props, ref) => {
  const { onClose, paymentOutlet, currency, city_slug, currentCompany } = props;
  const { t } = useTranslation();

  const MIN_PAYMENT = {
    'kuala-lumpur': 0.05,
    singapore: 0.1,
    jakarta: 1
  };

  const [modalStep, setModalStep] = useState(1);
  const [copyText, setCopyText] = useState(null);

  const Step1 = () => {
    const [payment, setPayment] = useState('');
    const [paymentDisplay, setPaymentDisplay] = useState('');
    const [remark, setRemark] = useState('');
    const [characterCount, setCharacterCount] = useState(0);

    useEffect(() => {
      currency !== 'RP'
        ? setPaymentDisplay(numeral(payment / 100).format('0.00'))
        : setPaymentDisplay(payment);
    }, [payment]);

    const handleInput = event => {
      let input = event.target.value;
      input = input.replace((/[^\d|.]|^0/, 'g'), '');
      event.target.setSelectionRange(input.length, input.length);
      setPayment(+input);
    };

    return (
      <>
        <h3 className={styles.subheading}>{t('Enter amount and remarks')}</h3>
        <p>
          {t(`Customer is paying to {{paymentOutlet.name}}`, {
            paymentOutlet
          })}
        </p>
        <label data-currency={currency} className={styles.amountInput}>
          <input
            className={styles.amountInputElement}
            ref={ref}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            min={MIN_PAYMENT[city_slug]}
            onChange={e => handleInput(e)}
            value={payment}
          />
          <span style={{ color: payment ? 'black' : 'grey' }}>
            {paymentDisplay}
          </span>
        </label>
        <label className={styles.remarkInput}>
          <textarea
            placeholder="eg. Receipt ID 12345 / This is for 2x Blueberry Cheesecake"
            value={remark}
            maxLength="50"
            onKeyUp={e => setCharacterCount(e.target.value.length)}
            onChange={e => setRemark(e.target.value)}
          />
          <span>{characterCount}/50</span>
        </label>
        <div className={styles.paymentModalButtons}>
          <PrimaryInvertedButton
            size="lg"
            justify="center"
            className={styles.paymentModalButton}
            onClick={onClose}
          >
            {t('Cancel')}
          </PrimaryInvertedButton>
          <Button
            type="primary"
            size="lg"
            justify="center"
            className={styles.paymentModalButton}
            disabled={
              paymentDisplay === '' || +paymentDisplay < MIN_PAYMENT[city_slug]
            }
            onClick={() => {
              getPaymentLink({
                outletId: paymentOutlet.id,
                amount: paymentDisplay
              }).then(({ link }) => {
                setCopyText(
                  `We accept FavePay for contactless payments! Click ${link} to pay ${currency +
                    paymentDisplay} ${remark ? 'for ' + remark : ''} at ${
                    paymentOutlet.name
                  }, ${currentCompany.partner_name}.`
                );
                setModalStep(2);
              });
            }}
          >
            {t('Generate Text')}
          </Button>
        </div>
      </>
    );
  };

  const Step2 = () => {
    const [linkCopied, setLinkCopied] = useState(false);

    useEffect(() => {
      let timeoutID;
      if (linkCopied) {
        timeoutID = setTimeout(() => setLinkCopied(false), 3000);
      }
      return () => {
        clearTimeout(timeoutID);
      };
    }, [linkCopied]);

    const copyURL = copyText =>
      copyToClipboard({
        text: copyText,
        useShare: true,
        onDone: () => setLinkCopied(true)
      });

    return (
      <>
        <h3 className={styles.subheading}>
          {t('Copy text and share for payment')}
        </h3>
        <Button
          leftIcon="arrow-left"
          onClick={() => setModalStep(1)}
          type="text"
          className={styles.modalBackButton}
        >
          {t('Back')}
        </Button>
        <div className={styles.paymentLinkBox}>
          <p className={styles.paymentLinkText}>{copyText}</p>
        </div>
        {linkCopied && (
          <p className={styles.copyConfirmationText}>{t('Text Copied.')}</p>
        )}
        <div className={styles.paymentModalButtons}>
          <PrimaryInvertedButton
            size="lg"
            justify="center"
            className={styles.paymentModalButton}
            onClick={onClose}
          >
            {t('Cancel')}
          </PrimaryInvertedButton>
          <Button
            type="primary"
            size="lg"
            justify="center"
            className={styles.paymentModalButton}
            onClick={() => copyURL(copyText)}
            disabled={linkCopied}
          >
            {navigator.share ? t('Share link') : t('Copy Text')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.paymentModal}>
      <div className={styles.paymentModalContent}>
        {modalStep === 1 && <Step1 />}
        {modalStep === 2 && <Step2 />}
      </div>
    </div>
  );
});

export default EnterPaymentModal;
