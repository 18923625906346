import { isObject } from 'utils';

declare global {
  interface Window {
    mobileInterface: any;
    webkit: any;
  }
}

export const isAndroid = isObject(window.mobileInterface);

export const call = (fnName: string, params?: any) => {
  try {
    isAndroid
      ? params !== undefined
        ? window.mobileInterface[fnName](params)
        : window.mobileInterface[fnName]()
      : window.webkit.messageHandlers[fnName].postMessage(params);
  } catch (e) {
    console.log(`native call failed:`, e);
  }
};

export enum NATIVE {
  CLOSE_WEBVIEW = 'closeWindow',
  NEW_DEAL_REQUEST = 'newDealRequest',
  ON_QR_CLICKED = 'onQRClicked',
  LAUNCH_REDEMPTION_SCANNER = 'launchRedemptionScanner',
  LAUNCH_DEALS_INFO_PAGE = 'activateDeals',
  ECARDS_EDUCATION_PAGE = 'activateECard',
  SHOULD_RING = 'shouldRing',
  OPEN_ORDER_DETAILS = 'openOrderDetails',
  OPEN_HELP = 'openHelp',
  CREATE_ECARDS = 'createECard',
  ECARDS_FAQ = 'ecardFAQ',
  FAVEPAY_VIEW_OUTLET_INFO = 'favepayViewOutletInfo',
  FAVEPAY_VIEW_EDUCATION = 'favepayViewEducation',
  FEED_VIEW_EDUCATION = 'feedViewEducation',
  COPY_TO_CLIPBOARD = 'copyToClipboard',
  FAVEFEED_FAQ = 'faveFeedFAQ',
  ADD_BUSINESS_INFO = 'addBusinessInfo',
  CLAIM_STARTER_KIT = 'claimStarterKit',
  NAVIGATE_SERVICES = 'navigateServices',
  OPEN_REVIEW_SORTING_POPUP = 'openReviewSortingPopup'
}

export const scannerResult = (callback: (arg: string) => void) => (
  result: string
) => callback(result);
