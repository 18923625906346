import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import Routes from 'OpsRoutes';
import { OpsAuthContextProvider } from 'contexts/OpsAuthContext';
import { QueryStringContextProvider } from 'contexts/QueryStringContext';
import { WebviewContextProvider } from 'contexts/WebviewContext';
import { LangProvider } from 'contexts/LangContext';
import { CleverTapContextProvider } from 'contexts/CleverTapContext';
import { CleverTapContextProviderOld } from 'contexts/CleverTapContextOld';
import { NotificationProvider } from 'contexts/NotificationContext';
import { ModalProvider } from 'contexts/ModalContext';
import { OpsOutletProvider } from 'contexts/OpsOutletContext';
import { RelaymanContextProvider } from 'contexts/RelaymanContext';
import { FCMContextProvider } from 'contexts/FCMContext';
import { MapleContextProvider } from 'contexts/MapleContext';
import ErrorBoundary from 'components/ErrorBoundary';
import 'i18n';
import LoadingSpinner from 'components/LoadingSpinner';
import { TransactionNotificationProvider } from 'contexts/TransactionNotification';

import 'assets/css/index.scss';
import { QueryClientProvider } from 'react-query';
import { defaultQueryClient } from 'App';

export default function renderReactApp(rootEl) {
  ReactDOM.render(
    <Suspense fallback={<LoadingSpinner />}>
      <MapleContextProvider>
        <QueryStringContextProvider>
          <LangProvider>
            <CleverTapContextProvider>
              <CleverTapContextProviderOld>
                <WebviewContextProvider>
                  <OpsAuthContextProvider>
                    <ModalProvider>
                      <OpsOutletProvider>
                        <NotificationProvider>
                          <FCMContextProvider>
                            <RelaymanContextProvider>
                              <TransactionNotificationProvider>
                                <ErrorBoundary>
                                  <QueryClientProvider
                                    client={defaultQueryClient}
                                  >
                                    <Routes />
                                  </QueryClientProvider>
                                </ErrorBoundary>
                              </TransactionNotificationProvider>
                            </RelaymanContextProvider>
                          </FCMContextProvider>
                        </NotificationProvider>
                      </OpsOutletProvider>
                    </ModalProvider>
                  </OpsAuthContextProvider>
                </WebviewContextProvider>
              </CleverTapContextProviderOld>
            </CleverTapContextProvider>
          </LangProvider>
        </QueryStringContextProvider>
      </MapleContextProvider>
    </Suspense>,
    rootEl
  );
}
