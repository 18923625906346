import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from 'assets/css/WebviewHeading.module.scss';
import CloseWindowIcon from 'assets/images/close-banner-icon.svg';
import Button from 'components/Button';
import { call, NATIVE } from 'utils/nativeInterface';

type WebviewHeadingProps = {
  title: ReactNode;
  backURL?: string;
  onBack?: () => void;
  IconElement?: React.ReactNode;
  subtitle?: ReactNode;
  hasCloseWindowIcon?: boolean;
  additionalContent?: React.ReactNode;
};

const WebviewHeading: React.FC<WebviewHeadingProps> = ({
  title = '',
  onBack,
  backURL = '',
  children,
  IconElement,
  subtitle = '',
  hasCloseWindowIcon = false,
  additionalContent
}) => (
  <header
    className={classNames(styles.webviewHeading, children && styles.divide)}
  >
    {(backURL || onBack) && (
      <div className={styles.buttonContainer}>
        <Button
          className={styles.backButton}
          href={backURL}
          onClick={onBack}
          type="ghost"
          children=""
          iconSize="1.5rem"
          leftIcon="angle-left"
          iconColor="very-light-grey"
        />
        {IconElement && IconElement}
      </div>
    )}
    {hasCloseWindowIcon && (
      <div className={styles.closeWindow}>
        <img
          onClick={() => call(NATIVE.CLOSE_WEBVIEW)}
          className={styles.closeWindowIcon}
          src={CloseWindowIcon}
          alt="close window"
        />
      </div>
    )}
    <h2 className={styles.title}>{title}</h2>
    {additionalContent && additionalContent}
    {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    {children}
  </header>
);

export default WebviewHeading;
