import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import Card from './Card';
import { useTranslation } from 'react-i18next';
import { IButtonProps } from './Button/Button';

import styles from 'assets/css/FaveCard.module.scss';

type BottomButtonProps = {
  title: string;
  link: string;
  type: IButtonProps['type'];
  rightIcon: string;
  iconColor: string;
  className: string;
};

type FaveCardProps = {
  outletTitle: string;
  outletName: string;
  outletImage: string;
  revenueAmount: string | number;
  count: number;
  status: string;
  statusType: 'cashback' | 'pending' | 'default';
  renderBottomButton: BottomButtonProps;
  transactionType: string;
  className: string;
  isCardActive: boolean;
};

const FaveCard: React.FC<FaveCardProps> = props => {
  const { t } = useTranslation();

  const {
    outletTitle,
    outletImage,
    revenueAmount,
    count = 0,
    status,
    statusType = 'default',
    renderBottomButton = {
      className: '',
      title: '',
      link: '',
      type: undefined,
      rightIcon: 'angle-right',
      iconColor: 'dark-saturated-blue'
    },
    transactionType = t('Transactions'),
    className,
    isCardActive = true
  } = props;

  const renderBottomProps = {
    ...renderBottomButton,
    rightIcon: 'angle-right',
    iconColor: 'dark-saturated-blue'
  };

  const renderStatusProps = {
    cashback: { position: 'left', color: ['apple-green', 'apple-green'] },
    pending: { position: 'left', color: ['pelorous', 'pelorous'] },
    default: { position: 'right', color: ['apple-green', 'nero-black'] }
  };

  const statusColor = isCardActive
    ? renderStatusProps[statusType].color[0]
    : renderStatusProps[statusType].color[1];

  const opacityValue = isCardActive ? 1 : 0.4;

  return (
    <Card
      className={classNames(styles.outlet, className)}
      style={
        {
          '--bg': `url(${outletImage})`,
          '--status-color': `var(--${statusColor})`,
          '--opacity-value': opacityValue
        } as CSSProperties
      }
    >
      <div className={styles.outletContentContainer}>
        {status && (
          <span
            className={classNames(
              renderStatusProps[statusType].position === 'left'
                ? styles.outletStatusLeft
                : styles.outletStatusRight
            )}
          >
            {status}
          </span>
        )}
        <div className={styles.content}>
          <h3 className={styles.outletPaymentTitle}>{outletTitle}</h3>
          <div className={styles.contentElement}>
            <div>{t('Total Revenue')}</div>
            <div className={styles.contentValue}>{revenueAmount}</div>
          </div>
          <div className={styles.contentElement}>
            <div>{transactionType}</div>
            <div className={styles.contentValue}>{count}</div>
          </div>
          {renderBottomProps.title && (
            <div className={styles.bottom}>
              <Button
                isRounded
                type={renderBottomProps.type}
                href={renderBottomProps.link}
                children={renderBottomProps.title}
                rightIcon={renderBottomProps.rightIcon}
                iconColor={renderBottomProps.iconColor}
                textColor={renderBottomProps.iconColor}
                className={classNames(
                  styles.bottomButton,
                  renderBottomProps.className
                )}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default FaveCard;
