import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'assets/css/ToggleButton.module.scss';

type ToggleButtonProps = {
  checked: boolean;
  id: string;
  onToggle: (t: boolean) => void;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  checked = false,
  id,
  onToggle = () => {}
}) => {
  const { t } = useTranslation();
  const [isToggled, setIsToggled] = useState(checked);

  useEffect(() => {
    setIsToggled(checked);
  }, [checked]);

  return (
    <div className={styles.cancellationCheckBox}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={() => {
          onToggle(!isToggled);
          setIsToggled(!isToggled);
        }}
      />
      <label htmlFor={id}>
        <div className={styles.toggleButtonText}>
          {checked ? t('ON') : t('OFF')}
        </div>
      </label>
    </div>
  );
};

export default ToggleButton;
