import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router';

import InsightsApp from 'insights/components/InsightsApp';
import InsightsStatic from 'insights/views/InsightsStatic';
import { QueryStringContextProvider } from 'contexts/QueryStringContext';
import { InsightsAuthContextProvider } from 'insights/contexts/InsightsAuthContext';
import { CleverTapContextProviderOld } from 'contexts/CleverTapContextOld';
import { InsightsOutletProvider } from 'insights/contexts/InsightsOutletContext';
import { LangProvider } from 'contexts/LangContext';
import { WebviewContextProvider } from 'contexts/WebviewContext';

import LoadingSpinner from 'components/LoadingSpinner';
import 'assets/css/index.scss';
import 'insights/assets/css/index.scss';

export default function renderReactApp(rootEl) {
  ReactDOM.render(
    <Suspense fallback={<LoadingSpinner />}>
      <QueryStringContextProvider>
        <LangProvider>
          <CleverTapContextProviderOld>
            <WebviewContextProvider>
              <InsightsAuthContextProvider>
                <InsightsOutletProvider>
                  <Router>
                    <InsightsApp path="insights/:country/:companyId" />
                    <InsightsStatic path="insights/coming-soon" />
                  </Router>
                </InsightsOutletProvider>
              </InsightsAuthContextProvider>
            </WebviewContextProvider>
          </CleverTapContextProviderOld>
        </LangProvider>
      </QueryStringContextProvider>
    </Suspense>,
    rootEl
  );
}
