import React, { useEffect, useContext, useState } from 'react';
import { navigate } from '@reach/router';
import { subDays, startOfToday, endOfToday, startOfYesterday } from 'date-fns';
import moment from 'moment';

import getDeals from 'queries/getDeals';
import getDealsRevenue from 'queries/getDealsRevenue';
import { WebviewContext } from 'contexts/WebviewContext';
import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';
import useServicesUrls from 'hooks/useServicesUrls';
import { timezone } from 'utils/Dates';
import getRevenueParams from 'utils/getRevenueParams';
import { call, NATIVE } from 'utils/nativeInterface';
import useCleverTapOld from 'hooks/useCleverTapOld';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import DealCard from 'components/DealCard';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Badge from 'components/Badge';
import DateRangePicker from 'components/DateRangePicker';
import WebviewHeading from 'components/WebviewHeading';

import { useTranslation } from 'react-i18next';
import styles from 'assets/css/DealsView.module.scss';
import { NotificationContext } from 'contexts/NotificationContext';

const DealsView = () => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  const { currentCompany, currentOutlet } = useContext(OutletContext);
  const { user } = useContext(AuthContext);
  const { isWebview } = useContext(WebviewContext);
  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [dealRevenues, setDealRevenues] = useState();
  const servicesUrls = useServicesUrls();
  const { setNotification } = useContext(NotificationContext);
  const [deals, setDeals] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [openDateRange, setOpenDateRange] = useState(false);
  const today = timezone(endOfToday());
  const [filter, setFilter] = useState({
    date: today,
    type: 'all'
  });

  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'favedeals_explore' });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (currentCompany && currentCompany.id) {
        const dealsResponse = await getDeals({
          company_id: currentOutlet === '' ? currentCompany.id : null,
          outlet_id: currentOutlet === '' ? null : currentOutlet.id
        });

        if (!isMounted) return;
        const dealIds = dealsResponse.deals.map(d => d.id);
        setDeals(dealsResponse.deals);

        if (
          currentCompany.deals_interest === true &&
          dealIds.length === 0 &&
          currentCompany.has_deals === false
        )
          setNotification({
            message: t(
              'Our business development team will contact you right away to help you with your setup.'
            ),
            type: 'success'
          });
        if (dealIds.length === 0) {
          setIsLoadingDeals(false);
          return;
        }

        const params = getRevenueParams({
          currentOutlet,
          user,
          startDate,
          endDate,
          filter,
          outlets: currentCompany.outlets,
          deal_ids: dealIds.join(',')
        });
        const allRevenues = await getDealsRevenue(params);
        setDealRevenues(allRevenues);
        if (!isMounted) return;
        setIsLoadingDeals(false);
      }
    })();
    return () => (isMounted = false);
  }, [
    currentCompany,
    setNotification,
    endDate,
    filter,
    startDate,
    currentOutlet,
    user,
    t
  ]);

  const dateFilters = [
    { text: t('Today'), value: today, tapped_on: 'filter_date_by_today' },
    {
      text: t('Yesterday'),
      value: startOfYesterday(),
      tapped_on: 'filter_date_by_yesterday'
    },
    {
      text: t('Last 7'),
      value: subDays(startOfToday(), 7),
      tapped_on: 'filter_date_by_last_7_days'
    },
    {
      text: t('Last 30'),
      value: subDays(startOfToday(), 30),
      tapped_on: 'filter_date_by_last_30_days'
    },
    { text: t('Custom'), value: 'custom', tapped_on: 'filter_by_custom_dates' }
  ];

  const onChangeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFilters = value => {
    let newFilter = { ...filter };
    if (value.date) {
      if (value.date === 'custom') {
        setOpenDateRange(true);
      } else {
        setOpenDateRange(false);
        setStartDate(moment());
        setEndDate(moment());
      }
      newFilter.date = value.date;
    }

    if (value.type) {
      newFilter.type = value.type;
    }
    setFilter(newFilter);
  };

  const findDealRevenueById = ({ id }) =>
    dealRevenues.find(revenue => revenue.id === id) || {};

  const handleNewDealRequest = () => {
    cleverTap.tapped.push({
      screen_name: 'favedeals_explore',
      tapped_on: 'btn_deals_request'
    });

    if (isWebview) {
      call(NATIVE.NEW_DEAL_REQUEST);
    } else {
      navigate('deals/enable');
    }
  };

  const launchDealInfoPage = () => {
    cleverTap.tapped.push({
      screen_name: 'favedeals_explore',
      tapped_on: 'btn_learnmore_favedeals'
    });
    if (isWebview) {
      call(NATIVE.LAUNCH_DEALS_INFO_PAGE);
    } else {
      navigate(servicesUrls.favedeals);
    }
  };

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading
          title="FaveDeals"
          onBack={() => call(NATIVE.CLOSE_WEBVIEW)}
        />
      ) : (
        <Heading
          title="FaveDeals"
          subtitle={t('View your FaveDeals performance at your outlet')}
        />
      )}

      {deals.length > 0 && dealRevenues && (
        <header className={styles.dateFilter}>
          <p>{t('Select Date Range')}</p>
          {dateFilters.map(f => {
            const formattedDate =
              f.value === 'custom' ? 'custom' : timezone(f.value);
            const isCurrent = formattedDate === filter.date;
            return (
              <Badge
                key={f.value}
                checked={isCurrent}
                onChange={() => {
                  cleverTap.tapped.push({
                    screen_name: 'favedeals_explore',
                    section_name: 'filter_tabs',
                    tapped_on: f.tapped_on
                  });
                  handleFilters({ date: formattedDate });
                }}
              >
                {f.text}
              </Badge>
            );
          })}
          {openDateRange && (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={onChangeDateRange}
              openDirection="down"
              isOutsideRange={day => day.isAfter(moment(), day)}
            />
          )}
        </header>
      )}

      {isLoadingDeals ? (
        <LoadingSpinner />
      ) : deals.length > 0 && dealRevenues && user.city ? (
        <Grid rowGap="2rem" columnGap="2rem">
          {deals
            .sort((deal1, deal2) => deal2.id - deal1.id)
            .map(deal => {
              const revenue = findDealRevenueById(deal);
              return (
                <DealCard
                  key={deal.id}
                  currency={user.city.currency_display}
                  headerImage={deal.web_image_url}
                  status={deal.status}
                  dealName={deal.name}
                  dealID={deal.id}
                  revenueAmount={revenue.revenue_amount}
                  revenueCount={revenue.count}
                  filter={filter}
                  startDate={startDate}
                  endDate={endDate}
                />
              );
            })}
        </Grid>
      ) : (
        <Card className={styles.noDealsCard}>
          <img
            alt={t('No deals')}
            src={require('assets/images/services/favebiz-deals-none.svg')}
          />
          <p>
            {currentCompany.has_deals
              ? t(
                  'Your business already has deals setup, but not in this outlet'
                )
              : t(
                  'We have received your set up request and will get back to you soon.'
                )}
          </p>
          <div className={styles.buttonSection}>
            <Button
              type="primary"
              size="lg"
              isRedirect={false}
              onClick={launchDealInfoPage}
            >
              {t('Learn about FaveDeals')}
            </Button>
            <Button
              type="skeleton"
              size="lg"
              isRounded={true}
              isRedirect={false}
              className={styles.request}
              onClick={handleNewDealRequest}
            >
              {t('Submit another request')}
            </Button>
          </div>
        </Card>
      )}

      {deals.length > 0 && dealRevenues && (
        <Button
          className={styles.newDealButton}
          type="primary"
          leftIcon="cross"
          size="lg"
          onClick={handleNewDealRequest}
        >
          {t('New deal request')}
        </Button>
      )}
    </AppLayout>
  );
};

export default DealsView;
