import Kfit from '../integrations/Kfit';

export default ({
  company_id,
  finance_account_id = null,
  page = 1,
  per_page = 20,
  start_date = undefined,
  end_date = undefined
}: {
  company_id: number;
  finance_account_id: number | null;
  page?: number;
  per_page?: number;
  start_date?: Date | string | undefined;
  end_date?: Date | string | undefined;
}) => {
  const params = {
    company_id,
    finance_account_id,
    page,
    per_page,
    start_date,
    end_date
  };

  return Kfit.get(`/v8/statements`, params)
    .then(res => res)
    .catch(error => error);
};
