import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from 'assets/css/ErrorText.module.scss';
import { ErrorMessage } from 'formik';
import { Trans } from 'react-i18next';

export const ErrorText = ({
  children,
  className
}: PropsWithChildren<{
  className?: string;
}>) => (
  <Trans>
    <p className={classNames(styles.error, className)}>{children}</p>
  </Trans>
);

const FormErrorText = ({
  name,
  className
}: {
  name: string;
  className?: string;
}) => {
  return (
    <ErrorMessage name={name}>
      {message => <ErrorText className={className}>{message}</ErrorText>}
    </ErrorMessage>
  );
};

export default FormErrorText;
