import React, { useState, useEffect, useContext } from 'react';
import { OutletContext } from 'contexts/OutletContext';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { size, first, isEmpty } from 'lodash';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import ChevronRightGreyIcon from 'assets/images/ic-chevron-right-grey.svg';
import StatementsImage from 'assets/images/statements.svg';
import { useTranslation } from 'react-i18next';
import OutOfBoundClick from 'components/OutOfBoundClick';

import styles from 'assets/css/StatementsView.module.scss';
import getStatementsOfAccounts from 'queries/getStatementsOfAccounts';
import LoadingSpinnerSmall from 'components/LoadingSpinnerSmall';
import useFetchDataOnScroll from 'hooks/useFetchDataOnScroll';

type AccountsDetail = {
  finance_account_id: number;
  bank_name: string;
  bank_account_number: string;
  outlets_name: string[];
  last_updated: string;
};

type Statements = {
  accounts_detail: AccountsDetail[];
  total_count: number;
};

const Statements = () => {
  const { t } = useTranslation();
  const { currentCompany } = useContext(OutletContext);
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [outletStatements, setOutletStatements] = useState<AccountsDetail[]>(
    []
  );
  const [currentOutletPage, setCurrentOutletPage] = useState(1);
  const [isNotLastPage, setIsNotLastPage] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({
      screen_name: 'statements'
    });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed]);

  useEffect(() => {
    setIsLoading(true);
    if (currentCompany) {
      getStatementsOfAccounts({
        company_id: currentCompany.id
      })
        .then(data => {
          if (!isEmpty(data.accounts_detail)) {
            setOutletStatements(data.accounts_detail);
            setCurrentOutletPage(page => page + 1);
            setShouldFetch(true);
          } else {
            setShouldFetch(false);
          }
        })
        .catch(() => {
          setOutletStatements([]);
          setIsLoading(false);
          setShouldFetch(false);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [currentCompany]);

  const loadMore = () => {
    setIsLoadingMore(true);
    getStatementsOfAccounts({
      company_id: currentCompany.id,
      page: currentOutletPage
    })
      .then(data => {
        if (!isEmpty(data.accounts_detail)) {
          setOutletStatements(
            [...outletStatements].concat(data.accounts_detail)
          );
          setCurrentOutletPage(page => page + 1);
        } else {
          setIsNotLastPage(false);
          setShouldFetch(false);
        }
      })
      .then(() => {
        setIsLoadingMore(false);
      });
  };

  const fetchStatementsRef = useFetchDataOnScroll(
    isLoadingMore,
    isNotLastPage,
    loadMore,
    shouldFetch
  );

  const StatementList = ({ statements }: { statements: AccountsDetail[] }) => {
    const LoadingStateRows = () => {
      return (
        <>
          {[...Array(1)].map((_, index) => {
            return (
              <div key={index} className={styles.tableBodyContainer}>
                {[...Array(5)].map((_, innerIndex) => {
                  return (
                    <div
                      key={innerIndex}
                      className={classNames(
                        styles.loadingState,
                        styles.tableRow
                      )}
                    />
                  );
                })}
              </div>
            );
          })}
        </>
      );
    };

    const ViewOutletsModals = ({ data }: { data: AccountsDetail }) => {
      const [showModal, setShowModal] = useState(false);

      const closeModal = () => {
        setShowModal(false);
      };

      const toggleModal = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }
        setShowModal(!showModal);
      };

      return (
        <div className={classNames(styles.modal, styles.tableRow)}>
          <span>
            {size(data.outlets_name)} {t('Outlets')}
          </span>
          <span className={styles.separator}>|</span>
          <OutOfBoundClick
            className={styles.outOfBoundModal}
            onClick={closeModal}
          >
            <span
              className={styles.viewOutletButton}
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => toggleModal(e)}
            >
              {t('View Outlets')}
            </span>
            <div
              onClick={e => e.stopPropagation()}
              className={classNames(
                styles.modalContents,
                showModal && styles.isOpen
              )}
            >
              <div className={styles.modalContentTitle}>
                <p className={styles.modalHeading}>
                  {t('These outlets are linked to')}
                </p>
                <p className={styles.modalBankAccountNumber}>
                  {`${data.bank_name} ${data.bank_account_number}`}
                </p>
              </div>
              <hr className={styles.horizontalLine} />
              <div className={styles.modalOutletNames}>
                {data.outlets_name.map((item: string, index: number) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            </div>
          </OutOfBoundClick>
        </div>
      );
    };

    const StatementDataRows = () => {
      return (
        <>
          {statements.map((statement: AccountsDetail) => {
            return (
              <div
                className={styles.tableBodyContainer}
                key={statement.finance_account_id}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  cleverTap.tapped.push({
                    screen_name: 'statements',
                    tapped_on: 'btn_statements_individual'
                  });
                  navigate(
                    `/statements/outlet/${statement.finance_account_id}`
                  );
                }}
              >
                {size(statement.outlets_name) > 1 ? (
                  <ViewOutletsModals data={statement} />
                ) : (
                  <div className={styles.tableRow}>
                    {first(statement.outlets_name)}
                  </div>
                )}
                <div className={styles.tableRow}>{statement.bank_name}</div>
                <div className={styles.tableRow}>
                  {statement.bank_account_number || '-'}
                </div>
                <div className={styles.tableRow}>{statement.last_updated}</div>
                <div className={styles.tableRow}>
                  <img src={ChevronRightGreyIcon} alt="" />
                </div>
              </div>
            );
          })}
        </>
      );
    };

    const RenderEmptyStatements = () => (
      <div className={styles.statementsEmpty}>
        <img src={StatementsImage} className={styles.statementImage} alt="" />
        <span className={styles.statementsEmptyData}>
          {t("There's nothing here yet, check back another time.")}
        </span>
      </div>
    );

    return (
      <div className={styles.revampedStatementList}>
        <div className={styles.tableHeader}>
          <div className={styles.tableHeaderContainer}>
            <div className={styles.tableRow}>{t('Outlet')}</div>
            <div className={styles.tableRow}>{t('Bank Name')}</div>
            <div className={styles.tableRow}>{t('Account Number')}</div>
            <div className={styles.tableRow}>{t('Last Updated')}</div>
            <div className={styles.tableRow} />
          </div>
        </div>
        <div className={styles.tableBody}>
          {isLoading ? (
            <LoadingStateRows />
          ) : !isEmpty(statements) ? (
            <StatementDataRows />
          ) : (
            <RenderEmptyStatements />
          )}
        </div>
      </div>
    );
  };

  return (
    <AppLayout>
      <Heading
        title={t('Statements')}
        subtitle={t('View and generate your statements')}
      />
      <StatementList statements={outletStatements && outletStatements} />
      <div ref={fetchStatementsRef} />
      {isLoadingMore && (
        <LoadingSpinnerSmall className={styles.loadingSpinner} />
      )}
    </AppLayout>
  );
};

export default Statements;
