import Kfit from 'integrations/Kfit';
import { GenericSuccessType } from 'types/GenericSuccessType';
import { jsonToQueryString } from 'insights/utils';

export const requestMapleReports = (
    email: String,
    start_date: String,
    end_date: String,
    company_id: number
  ): Promise<GenericSuccessType> => Kfit.post(`/v1/maple/statements${jsonToQueryString({
    email,
    start_date,
    end_date,
    company_id
})}`);
  