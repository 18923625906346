import React, { useContext, useState, useEffect } from 'react';
import useHubSpotTrack from 'hooks/useHubSpotTrack';
import LoginLayout from 'layouts/LoginLayout';
import Cookie from 'helpers/Cookie';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import classnames from 'classnames';
import { NotificationContext } from 'contexts/NotificationContext';
import Icon from 'components/Icon';
import createCompanyManager from 'commands/createCompanyManager';
import { AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import styles from 'assets/css/SetPinView.module.scss';

const SetPinView = ({ location }) => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'enter_pin_confirm' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  useHubSpotTrack(location.pathname);
  const LogInSchema = Yup.object().shape({
    enterPin: Yup.string()
      .length(6, t(`PIN should be 6 digits long`))
      .required(t('Required')),
    reEnterPin: Yup.string()
      .length(6, t(`PIN should be 6 digits long`))
      .required(t('Required'))
  });
  const header = Cookie.get('sign_up_data');
  const [pinCheck, setPinCheck] = useState(false);
  const { loginUser } = useContext(AuthContext);
  const [reEnterPinCheck, setReEnterPinCheck] = useState(false);
  const [reEnterPinValue, setReEnterPinValue] = useState('');
  const { setNotification } = useContext(NotificationContext);
  const submitForm = (values, formikBag) => {
    const submitFormBody = {
      pin_number: values.enterPin,
      verified_token: header.verified_token,
      value: header.value,
      country_code: header.city === 'kuala-lumpur' ? 'my' : 'sg',
      company_name: header.company_name,
      city: header.city,
      category_id: header.category_id,
      invite_code: header.invite_code,
      method: header.method,
      mcc_eligibility: header.mcc_eligibility
    };
    createCompanyManager(submitFormBody)
      .then(() => {
        loginUser({
          method: header.method,
          pin_number: values.enterPin,
          email: header.value,
          phone: header.value
        }).then(() => {
          Cookie.remove('sign_up_data');
        });
      })
      .catch(error => {
        if ('value' in error) {
          const { value } = error;
          setNotification({ message: value.response.error, type: 'error' });
        } else {
          setNotification({ message: error.toString(), type: 'error' });
        }
      })
      .finally(() => formikBag.setSubmitting(false));
  };
  return (
    <>
      <LoginLayout
        withFooter={false}
        backButtonProps={{
          to: '/verify_pin'
        }}
      >
        <div className={styles.pinViewContainer}>
          <div className={styles.verifyCode}>
            <h3>{t('Security')}</h3>
            <h5>{t('Set your 6-digit PIN')}</h5>
            <Formik
              enableReinitialize
              initialValues={{
                enterPin: '',
                reEnterPin: ''
              }}
              validationSchema={LogInSchema}
              validateOnBlur={false}
              onBlur={() => {}}
              onSubmit={(values, formikBag) => {
                if (reEnterPinCheck) {
                  submitForm(values, formikBag);
                } else {
                  setNotification({
                    message:
                      isNaN(values.enterPin) || isNaN(values.reEnterPin)
                        ? t('Only numbers allowed')
                        : t("PIN doesn't match"),
                    type: 'error'
                  });
                }
              }}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                resetForm,
                handleChange
              }) => (
                <Form className={styles.form}>
                  <div className={styles.pinField}>
                    <label htmlFor="enterPin" className={styles.telWrapper}>
                      <TextInput
                        type="password"
                        name="enterPin"
                        placeholder={t('Enter your pin*')}
                        className={styles.telcode}
                        onChange={e => {
                          if (
                            !isNaN(e.target.value) &&
                            e.target.value.length === 6
                          ) {
                            setPinCheck(e.target.value);
                          } else {
                            setPinCheck(false);
                          }
                          handleChange(e);
                        }}
                        maxLength={6}
                        focus
                        {...{ errors, touched }}
                      />
                      {pinCheck && (
                        <Icon
                          icon="check"
                          className={styles.checkIcon}
                          color="green"
                        />
                      )}
                    </label>
                    <label htmlFor="reEnterPin" className={styles.telWrapper}>
                      <TextInput
                        type="password"
                        name="reEnterPin"
                        maxLength={6}
                        placeholder={t('Re-enter pin*')}
                        className={classnames({
                          [styles.errorField]:
                            reEnterPinValue.length === 6 &&
                            reEnterPinCheck === false
                        })}
                        onChange={e => {
                          if (
                            !isNaN(e.target.value) &&
                            e.target.value === pinCheck
                          ) {
                            setReEnterPinCheck(true);
                          } else {
                            setReEnterPinCheck(false);
                          }
                          setReEnterPinValue(e.target.value);
                          handleChange(e);
                        }}
                        {...{ errors, touched }}
                      />
                      {reEnterPinCheck && (
                        <Icon
                          icon="check"
                          color="green"
                          className={styles.checkIcon}
                        />
                      )}
                    </label>
                    {reEnterPinValue.length === 6 &&
                      reEnterPinCheck === false && (
                        <div className={styles.errorPinValue}>
                          {isNaN(reEnterPinValue)
                            ? t('Only numbers allowed')
                            : t('6-digit PIN needs to be identical')}
                        </div>
                      )}
                  </div>
                  <Button
                    type="submit"
                    size="lg"
                    rightIcon="arrow-right"
                    justify="space-between"
                    onClick={() =>
                      cleverTap.tapped.push({
                        screen_name: 'enter_pin_confirm',
                        tapped_on: 'btn_done'
                      })
                    }
                    className={styles.verifyPinSubmit}
                  >
                    {t('Done')}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </LoginLayout>
    </>
  );
};

export default SetPinView;
