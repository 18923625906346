import React, { useState } from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import FormikError, { FormikErrorProps } from 'components/FormikError';
import styles from 'assets/css/TextInput.module.scss';

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string /* required by Formik */;
  type?: string;
  errors?: FormikErrorProps['errors'];
  touched?: FormikErrorProps['touched'];
  focus?: boolean;
  withErrors?: boolean;
  className?: string;
};

const TextInput: React.FC<TextInputProps> = ({
  name,
  type = 'text',
  errors = {},
  touched = {},
  focus = false,
  withErrors = true,
  className,
  ...fieldProps
}) => {
  // if focus true, set focus on input once
  const [isFocusDone, setIsFocusDone] = useState(false);
  return (
    <label htmlFor={name} className={classNames(styles.label, className)}>
      <Field
        type={type}
        name={name}
        innerRef={(el: HTMLElement) => {
          if (focus && !isFocusDone && el) {
            el.focus();
            setIsFocusDone(true);
          }
        }}
        /*
          if focused and submit clicked, nothing happens,
          needs another click. override onBlur to solve it
        */
        onBlur={() => {}}
        {...fieldProps}
      />
      {withErrors && <FormikError {...{ name, errors, touched }} />}
    </label>
  );
};

export default TextInput;
