import { useMemo, useState } from 'react';

export type ObjectMapBiState = {
  [key: string]: boolean;
};

const useObjectMapBiState = () => {
  const [state, setState] = useState<ObjectMapBiState>({});

  const toggleKey = (key: string) => {
    const newState = { ...state };
    newState[key] = !newState[key];

    setState(newState);
  };

  const setAll = <T extends {}>(
    array: T[],
    reducer: (acc: ObjectMapBiState, item: T) => ObjectMapBiState
  ) => setState(array.reduce<ObjectMapBiState>(reducer, {}));

  const selectedKeys = useMemo(
    () => Object.keys(state).filter(key => state[key]),
    [state]
  );

  return {
    state,
    selectedKeys,
    clear: () => setState({}),
    toggleKey,
    setState,
    setAll,
    areAllValuesSet: (total: number) => {
      const values = Object.values(state);

      return (
        values.length > 0 &&
        values.length === total &&
        values.every(value => value)
      );
    }
  };
};

export default useObjectMapBiState;
