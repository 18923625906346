import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AppLayout from 'layouts/AppLayout';
import { OutletContext } from 'contexts/OutletContext';
import { FCMContext } from 'contexts/FCMContext';
import Card from 'components/Card';
import Button from 'components/Button';
import Icon from 'components/Icon';
import ToggleButton from 'components/ToggleButton';
import LoadingSpinner from 'components/LoadingSpinner';
import styles from 'assets/css/PushNotifications.module.scss';

const PushNotifications = () => {
  const { t } = useTranslation();
  const { outlets = [] } = useContext(OutletContext);
  const { subscriptions, toggleSubscription, isGettingToken } = useContext(
    FCMContext
  );

  const OutletButton = ({ outlet }) => {
    const isAllOutlets = outlet.id === 'all';
    const [isToggle, setIsToggle] = useState(
      isAllOutlets
        ? outlets.length === subscriptions.length
        : !!subscriptions.find(subscription => subscription.includes(outlet.id))
    );
    return (
      <Button
        type="text"
        fullWidth
        size="lg"
        className={classNames(styles.button, styles.outletButton)}
      >
        {outlet.name}
        <ToggleButton
          id={`pushNotifications_${outlet.id}`}
          checked={isToggle}
          onToggle={isEnabled => {
            setIsToggle(isEnabled);
            if (isAllOutlets) {
              if (isEnabled) {
                /* turn on outlets without subscriptions */
                const outletsWithoutSubscription =
                  subscriptions.length === 0
                    ? outlets
                    : outlets.filter(outlet =>
                        subscriptions.find(
                          subscription => !subscription.includes(outlet.id)
                        )
                      );
                outletsWithoutSubscription.forEach(outlet => {
                  toggleSubscription({ outlet, subscribed: true });
                });
              } else {
                /* turn off existing subscriptions */
                subscriptions.forEach(subscription => {
                  toggleSubscription({
                    outlet: { id: subscription.split('_')[1] },
                    subscribed: false
                  });
                });
              }
            } else {
              toggleSubscription({ outlet, subscribed: isEnabled });
            }
          }}
        />
      </Button>
    );
  };

  return (
    <AppLayout>
      <div className={styles.overlay}>
        <Card className={styles.switchOutletCard}>
          <Button
            type="text"
            className={styles.backButton}
            onClick={() => window.history.back()}
          >
            <Icon icon="angle-left" />
            {t('Back')}
          </Button>
          <div className={styles.title}>{t('Push notifications')}</div>
          {outlets.length === 0 || isGettingToken ? (
            <section className={styles.loadingSpinnerWrapper}>
              <LoadingSpinner />
            </section>
          ) : (
            <section className={styles.outlets}>
              <OutletButton
                key={'all'}
                outlet={{ id: 'all', name: t('All Outlets') }}
              />
              {outlets.map(outlet => (
                <OutletButton key={outlet.id} outlet={outlet} />
              ))}
            </section>
          )}
        </Card>
      </div>
    </AppLayout>
  );
};

export default PushNotifications;
