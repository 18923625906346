import TransactionDetail from 'components/TransactionDetail';
import React from 'react';
import useGetTransactionDetails from 'queries/useGetTransactionDetails';
import { cancelTransaction } from 'commands/cancelTransaction';

const FavePayDetailView = props => (
  <TransactionDetail
    {...props}
    screenName="favepay"
    responseProp="fave_payment"
    useTransactionDetails={useGetTransactionDetails}
    cancelTransaction={cancelTransaction}
  />
);
export default FavePayDetailView;
