import useSWR from 'hooks/useSWR';

export default (
  { currentOutlet = {}, type, date, page = 1, limit = 5 },
  swrOptions
) =>
  useSWR({
    url: `/v1/transactions`,
    params: { outlet_id: currentOutlet.id, type, date, page, limit },
    swrOptions
  });
