import React, { useState, useEffect, useContext } from 'react';
import getBusinessInfo from 'queries/getBusinessInfo';
import { OutletContext } from 'contexts/OutletContext';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/BusinessInfoView.module.scss';
import businessImagePlaceholder from 'assets/images/banners/ic-business-img-placeholder.svg';

const BusinessInfoView = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const { currentCompany } = useContext(OutletContext);

  useEffect(() => {
    if (!currentCompany) return;
    getBusinessInfo({ id: currentCompany.id }).then(res => setData(res));
  }, [currentCompany]);

  const BusinessLink = ({ href, icon, children }) => (
    <>
      {children ? (
        <Button
          type="text"
          href={href}
          leftIcon={icon}
          textColor="dark-gray"
          iconColor="dark-gray"
        >
          {children}
        </Button>
      ) : (
        ''
      )}
    </>
  );
  return (
    <AppLayout>
      <Heading
        title={t('Business Info')}
        subtitle={t('Your business information')}
      />
      {!data ? (
        <LoadingSpinner />
      ) : (
        <section className={styles.businessInfo}>
          <section
            className={styles.banner}
            style={{ '--bg': `url(${data.gallery_images[0] || businessImagePlaceholder})` }}
          />
          <h2
            className={styles.businessName}
            style={{ '--logo-bg': `url(${data.logo_url})` }}
          >
            {data.name}
          </h2>
          <section className={styles.businessLinks}>
            <BusinessLink href={`mailto:${data.email}`} icon="envelope">
              {data.email}
            </BusinessLink>
            <BusinessLink href={data.facebook} icon="facebook">
              {data.facebook}
            </BusinessLink>
            <BusinessLink href={`tel:${data.phone}`} icon="phone">
              {data.phone}
            </BusinessLink>
            <BusinessLink href={data.twitter} icon="twitter">
              {data.twitter}
            </BusinessLink>
            <BusinessLink href={data.youtube} icon="youtube">
              {data.youtube}
            </BusinessLink>
            <BusinessLink href={data.instagram} icon="instagram">
              {data.instagram}
            </BusinessLink>
            <BusinessLink href={data.website} icon="globe-asia">
              {data.website}
            </BusinessLink>
          </section>
          <p className={styles.description}>{data.description}</p>
          <section className={styles.gallery}>
            {(data.gallery_images || []).map(src => (
              <img
                key={src}
                alt="gallery"
                className={styles.galleryImage}
                src={src}
              />
            ))}
          </section>
        </section>
      )}
    </AppLayout>
  );
};

export default BusinessInfoView;
