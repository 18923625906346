import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Formik, Form, ErrorMessage } from 'formik';
import { navigate } from '@reach/router';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';

import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { ModalContext } from 'contexts/ModalContext';
import { setOutletCashback } from 'commands/setOutletCashback';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import Card from 'components/Card';
import Badge from 'components/Badge';
import Checkbox from 'components/Checkbox';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'assets/css/CashbackSetupView.module.scss';

const FIELD_NAME = {
  cashback_rate: 'cashback_rate',
  custom_cashback_rate: 'custom_cashback_rate',
  outlet: 'outlet'
};

const initialValues = {
  [FIELD_NAME.cashback_rate]: 10 /* 10% */,
  [FIELD_NAME.custom_cashback_rate]: '',
  [FIELD_NAME.outlet]: ''
};

const CashbackSetupView = () => {
  const { t } = useTranslation();
  const { updateUser } = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    [FIELD_NAME.cashback_rate]: Yup.number()
      .integer()
      .required(t('Cashback rate is required')),
    [FIELD_NAME.custom_cashback_rate]: Yup.number()
      .min(1, t('Enter a value between 1 to 30'))
      .max(30, t('Enter a value between 1 to 30'))
      .positive(t('Value must be positive number'))
      .integer(t('Value entered is not a number')),
    [FIELD_NAME.outlet]: Yup.number()
      .integer()
      .required()
  });

  const cleverTap = useCleverTapOld();
  const { outlets, currentCompany } = useContext(OutletContext);
  const { setNotification } = useContext(NotificationContext);
  const { init } = useContext(ModalContext);
  const [cashbackRateId, setCashbackRateId] = useState(1); /** index of 10% */
  const [cashbackCheckboxId, setCashbackCheckboxId] = useState();
  const [isCustomRateSet, setIsCustomRateSet] = useState(false);

  const [sentCTEvent, setSentCTEvent] = useState(false);
  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'setup_cashback' });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed]);

  useEffect(() => {
    if (outlets && outlets.length === 1) {
      const selectedOutletID = ((outlets || [])[0] || {}).id;
      initialValues[FIELD_NAME.outlet] = selectedOutletID;
      setCashbackCheckboxId(selectedOutletID);
    }
  }, [outlets]);

  const onChangeCashbackRate = id => {
    return setCashbackRateId(id);
  };

  const onChangeCashbackCheckbox = (id, formikProps) => {
    formikProps.setFieldValue(FIELD_NAME.outlet, id);
    return setCashbackCheckboxId(id);
  };

  const onSetCashbackRateSubmitAPI = (id, cashback_rate, onClose) => {
    setOutletCashback(id, {
      cashback_rate,
      new_signup_flow: true,
      id
    })
      .then(user => {
        cleverTap.tapped.push({
          screen_name: 'setup_cashback',
          tapped_on: 'btn_confirmation_done'
        });
        updateUser(user);
        onClose();
        navigate('/cashback');
        setNotification({
          message: t('Cashback has been successfully set.'),
          type: 'success'
        });
      })
      .catch(error =>
        setNotification({
          message:
            error &&
            error.value &&
            error.value.response &&
            error.value.response.error,
          type: 'error'
        })
      );
  };

  const onOpenConfirmationModalSubmit = ({
    [FIELD_NAME.cashback_rate]: cashback_rate,
    [FIELD_NAME.outlet]: id
  }) => {
    const companyName = (currentCompany || {}).partner_name;
    const outletName = (outlets || []).find(data => data.id === id).name;
    init({
      Component: ConfirmationModal,
      componentProps: {
        outletId: id,
        cashbackRate: cashback_rate,
        companyName,
        outletName
      },
      openOnReady: true
    });
  };

  const ConfirmationModal = ({
    onClose,
    outletId,
    cashbackRate,
    companyName,
    outletName
  }) => {
    return (
      <div className={styles.modalContainer}>
        <div className={styles.modalTitle}>{t('Confirmation required')}</div>
        <span className={styles.modalSubtitle}>
          <Trans
            cashbackRate={cashbackRate}
            companyName={companyName}
            outletName={outletName}
          >
            {t('Are you sure you want to set ')}
            <p className={styles.modalSubtitleGreen}>
              {t('{{ cashbackRate }}% cashback', { cashbackRate })}
            </p>{' '}
            for <p className={styles.modalSubtitleBlack}>{{ companyName }}</p>{' '}
            at <p className={styles.modalSubtitleBlack}>{{ outletName }}</p>
          </Trans>
        </span>
        <div className={styles.modalButtonGroup}>
          <Button type="primary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              onSetCashbackRateSubmitAPI(outletId, cashbackRate, onClose)
            }
          >
            {t('Yes')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <AppLayout>
      <Heading
        title={t('Set up Cashback')}
        subtitle={t('Select the cashback rate for your respective outlet.')}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onOpenConfirmationModalSubmit}
      >
        {formikProps => (
          <Form>
            <div className={styles.cashbackContainer}>
              <div className={styles.topContainer}>
                <p className={styles.title}>{t('Cashback rate')}</p>
                <p className={styles.subtitle}>
                  {t('I would like to offer my customers cashback rate of:')}
                </p>
              </div>

              {/* Cashback option section */}
              <Card className={styles.cashbackRateOption}>
                {[5, 10, 15, 20, t('Set custom rate')].map(
                  (cashback, i, cashbackArray) => {
                    const isLastElement = cashbackArray.length - 1 === i;
                    const isRecommendedCashback = i === 1;

                    // check last element to show set custom cashback rate button
                    if (
                      cashbackRateId === i &&
                      isLastElement &&
                      !isCustomRateSet
                    ) {
                      return (
                        <div
                          key={i}
                          className={styles.customCashbackRateContainer}
                        >
                          <TextInput
                            type="number"
                            name={FIELD_NAME.custom_cashback_rate}
                            className={styles.customCashbackRate}
                            placeholder={t('Enter rate')}
                            errors={formikProps.errors}
                            touched={formikProps.touched}
                            withErrors={false}
                          />
                          <Button
                            type="primary"
                            className={styles.customCashbackRateButton}
                            onClick={() => {
                              formikProps.setFieldTouched(
                                FIELD_NAME.custom_cashback_rate
                              );
                              if (
                                !formikProps.errors.hasOwnProperty(
                                  FIELD_NAME.custom_cashback_rate
                                ) &&
                                formikProps.values[
                                  FIELD_NAME.custom_cashback_rate
                                ]
                              ) {
                                formikProps.setFieldValue(
                                  FIELD_NAME.cashback_rate,
                                  formikProps.values.custom_cashback_rate
                                );
                                setIsCustomRateSet(true);
                                cleverTap.tapped.push({
                                  screen_name: 'setup_cashback',
                                  section_name: 'cashback_rate',
                                  tapped_on: 'btn_set_custom_rate'
                                });
                              }
                            }}
                          >
                            <p>{t('Set')}</p>
                          </Button>
                          {!formikProps.errors.hasOwnProperty(
                            FIELD_NAME.custom_cashback_rate
                          ) && (
                            <p className={styles.recommendedSelected}>
                              {t('Enter a value (1 – 30)')}
                            </p>
                          )}
                          <ErrorMessage
                            name={[FIELD_NAME.custom_cashback_rate]}
                          >
                            {msg => (
                              <p className={styles.errorMessage}>{msg}</p>
                            )}
                          </ErrorMessage>
                        </div>
                      );
                    }

                    // check last element and if the user already set its own custom cashback rate
                    if (
                      cashbackRateId === i &&
                      isLastElement &&
                      isCustomRateSet
                    ) {
                      return (
                        <Button
                          key={i}
                          type="primary"
                          className={styles.cashbackEditButton}
                          rightIcon="pencil-edit"
                          iconSize="1.5rem"
                          onClick={() => setIsCustomRateSet(false)}
                        >
                          {formikProps.values[FIELD_NAME.cashback_rate]}%
                          cashback
                        </Button>
                      );
                    }

                    return (
                      <Badge
                        key={i}
                        className={classNames(
                          isLastElement
                            ? styles.rectangleLast
                            : styles.rectangle
                        )}
                        activeClassName={styles.active}
                        checked={cashbackRateId === i}
                        withCheckMark={false}
                        onChange={() => {
                          // if not last item, set the cashback rate
                          !isLastElement &&
                            formikProps.setFieldValue(
                              FIELD_NAME.cashback_rate,
                              cashback
                            );

                          // if last item and custom cashback and cashback rate not the same, clear the value
                          if (
                            isLastElement &&
                            formikProps.values[FIELD_NAME.cashback_rate] !==
                              formikProps.values[
                                FIELD_NAME.custom_cashback_rate
                              ]
                          ) {
                            formikProps.setFieldValue(
                              FIELD_NAME.cashback_rate,
                              ''
                            );
                          }

                          setIsCustomRateSet(false);

                          onChangeCashbackRate(i);
                        }}
                      >
                        {cashback}
                        {!isLastElement && '%'}
                        {isRecommendedCashback && (
                          <p
                            className={classNames(
                              cashbackRateId === i
                                ? styles.recommendedSelected
                                : styles.recommended
                            )}
                          >
                            {t('(recommended)')}
                          </p>
                        )}
                      </Badge>
                    );
                  }
                )}
              </Card>

              {/* Outlets selection checkbox */}
              <>
                <p className={styles.title}>{t('Outlet')}</p>
                <p className={styles.subtitle}>{t('Choose an outlet.')}</p>
                <div className={styles.outletsGrid}>
                  {(outlets || []).map(outlet => {
                    if (
                      outlet.fave_payment_outlet_detail.cashback_rate === 0 ||
                      outlet.has_setup_cashback === false
                    ) {
                      const isCheckboxSelected =
                        cashbackCheckboxId === outlet.id;
                      return (
                        <label
                          className={styles.checkboxContainer}
                          key={outlet.id}
                        >
                          {/* display outlet with checkbox selection */}
                          <Checkbox
                            className={styles.checkbox}
                            type="circular"
                            checked={cashbackCheckboxId === outlet.id}
                            onChange={() =>
                              onChangeCashbackCheckbox(outlet.id, formikProps)
                            }
                          />
                          <div className={styles.checkboxText}>
                            {/* different style based checked value */}
                            <p
                              className={classNames(
                                isCheckboxSelected
                                  ? styles.outletNameTextIsChecked
                                  : styles.outletNameText
                              )}
                            >
                              {outlet.name}
                            </p>
                            <p
                              className={classNames(
                                isCheckboxSelected
                                  ? styles.outletLocationTextIsChecked
                                  : styles.outletLocationText
                              )}
                            >
                              {outlet.favepay_location}
                            </p>
                          </div>
                        </label>
                      );
                    }
                    return null;
                  })}
                </div>
              </>
            </div>

            {/* submit form button */}
            <Button
              type="submit"
              className={styles.buttonSubmitDone}
              rightIcon="arrow-right"
              disabled={!formikProps.values[FIELD_NAME.cashback_rate]}
            >
              {t('Done')}
            </Button>
          </Form>
        )}
      </Formik>
    </AppLayout>
  );
};

export default CashbackSetupView;
