import React from 'react';
import classNames from 'classnames';

import styles from 'assets/css/Accordion/AccordionHeader.module.scss';

export interface IAccordionHeaderProps {
  className: string;
  isActive: boolean;
  style?: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const AccordionHeader: React.FC<IAccordionHeaderProps> = ({
  className,
  isActive,
  style,
  onClick,
  children
}) => (
  <div
    className={classNames(
      styles.layout,
      className,
      isActive ? styles.caretUp : styles.caretDown
    )}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

export default AccordionHeader;
