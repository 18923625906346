import React, { useEffect, useRef, useState } from 'react';

import styles from 'assets/css/ScrollToTopButton.module.scss';
import arrowUpIcon from 'assets/images/ic-arrow-up.svg';

const ScrollToTopButton = () => {
  const title = 'Back to top';
  const [isVisible, setIsVisible] = useState(false);

  const markerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting !== isVisible)
        setIsVisible(!entry.isIntersecting);
    });

    if (markerRef.current) observer.observe(markerRef.current);

    return () => observer.disconnect();
  }, [isVisible]);

  const scrollToTop = () => window.scroll({ top: 0, behavior: 'smooth' });

  return (
    <>
      <div ref={markerRef} />

      {isVisible && (
        <img
          title={title}
          alt={title}
          src={arrowUpIcon}
          className={styles.button}
          onClick={scrollToTop}
        />
      )}
    </>
  );
};

export default ScrollToTopButton;
