import TransactionDetail from 'components/TransactionDetail';
import React from 'react';
import useGetFaveLoyaltyProgramDetails from 'queries/useGetFaveLoyaltyProgramDetails';
import { refundFaveLoyaltyProgramTransaction } from 'commands/refundFaveLoyaltyProgramTransaction';

const FaveLoyaltyProgramDetailView = props => (
  <TransactionDetail
    {...props}
    type="Fave Loyalty Program"
    responseProp="flp_payment"
    useTransactionDetails={useGetFaveLoyaltyProgramDetails}
    cancelTransaction={refundFaveLoyaltyProgramTransaction}
  />
);
export default FaveLoyaltyProgramDetailView;
