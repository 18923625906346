import React from 'react';
import { useDropzone } from 'react-dropzone';

import styles from 'assets/css/ImageUpload.module.scss';
import classNames from 'classnames';

import addIcon from 'assets/images/feed/square-add.svg';
import deleteIcon from 'assets/images/feed/delete.svg';
import { useFormikContext } from 'formik';
import { UrlImage } from 'types/UrlImage';

export interface ImageFile extends File {
  preview?: string;
}

export type ImageUploadProps<T> = {
  newFilesfieldName: keyof T;
  currentFilesFieldName: keyof T;
  deletedFilesfieldName: keyof T;
  imageUrlField: string;
  className: string;
  disabled?: boolean;
  addImageButtonVisible: boolean;
  deleteImageButtonVisible: boolean;
  customImageList?: (values: T) => string[] | undefined;
  onFilesSelected: (fileObj: ImageFile[], targetCount: number) => File[];
};

type ImageUploadItemProps = {
  url: string;
  deleteImageButtonVisible: boolean;
  onDelete?: () => void;
};

const ImageUploadItem = ({
  url,
  deleteImageButtonVisible,
  onDelete
}: ImageUploadItemProps) => {
  return (
    <div
      style={{ background: `center / cover no-repeat url(${url})` }}
      className={styles.imagePreview}
    >
      {deleteImageButtonVisible && onDelete && (
        <img
          src={deleteIcon}
          alt="delete"
          className={styles.deleteButton}
          onClick={onDelete}
        />
      )}
    </div>
  );
};

export const ImageUpload = <T extends {}>({
  newFilesfieldName,
  currentFilesFieldName,
  deletedFilesfieldName,
  className,
  disabled,
  addImageButtonVisible,
  deleteImageButtonVisible,
  onFilesSelected,
  customImageList
}: ImageUploadProps<T>) => {
  const { values, setFieldValue } = useFormikContext<T>();

  const custom = customImageList ? customImageList(values) : [];
  const files = customImageList
    ? []
    : ((values[newFilesfieldName] as unknown) as ImageFile[]);
  const paths = customImageList
    ? []
    : ((values[currentFilesFieldName] as unknown) as UrlImage[]);

  const handleFilesSelected = async (acceptedFiles: ImageFile[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      for (const file of acceptedFiles) {
        const previewUrl = URL.createObjectURL(file);
        //might be re-enabled
        //const dimensions = await getImageDimensions(previewUrl);

        Object.assign(file, {
          preview: previewUrl
          //dimensions: dimensions
        });
      }

      const targetCount = files.length + acceptedFiles.length + paths.length;

      const filteredFiles = onFilesSelected(acceptedFiles, targetCount);

      if (filteredFiles && filteredFiles.length > 0) {
        const newFiles = [...files, ...filteredFiles];

        setFieldValue(newFilesfieldName as string, newFiles);
      }
    }
  };

  const handlePathDelete = (pathToDelete: UrlImage) => {
    setFieldValue(deletedFilesfieldName as string, [
      ...((values[deletedFilesfieldName] as unknown) as number[]),
      pathToDelete.id
    ]);

    const remainingPaths = paths.filter(
      (path: UrlImage) => path.id !== pathToDelete.id
    );

    setFieldValue(currentFilesFieldName as string, remainingPaths);
  };

  const handleFileDelete = (file: File) => {
    const remainingPaths = files.filter(
      (currentfile: File) => currentfile !== file
    );

    setFieldValue(newFilesfieldName as string, remainingPaths);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/gif, image/png',
    onDrop: handleFilesSelected
  });

  const containerStyles = classNames(
    `dropzone  ${disabled ? 'disabled' : ''}`,
    styles.uploadRoot,
    className
  );

  return (
    <div
      {...getRootProps({
        className: containerStyles
      })}
    >
      <input
        multiple
        name={newFilesfieldName as string}
        {...getInputProps()}
        disabled={disabled}
      />

      <div className={styles.uploadContainer}>
        {addImageButtonVisible && (
          <div className={styles.addButton} onClick={open}>
            <img src={addIcon} alt="add-img" />
          </div>
        )}

        {/* custom listing */}
        {custom &&
          custom.map((url: string) => (
            <ImageUploadItem
              key={url}
              url={url}
              deleteImageButtonVisible={deleteImageButtonVisible}
            />
          ))}

        {/* existing items */}
        {paths.map((path: UrlImage) => (
          <ImageUploadItem
            key={path.id}
            url={path.url}
            deleteImageButtonVisible={deleteImageButtonVisible}
            onDelete={() => handlePathDelete(path)}
          />
        ))}

        {/* new items */}
        {files.map((file: ImageFile) => {
          if (!file.preview) return null;

          return (
            <ImageUploadItem
              key={file.preview}
              url={file.preview}
              deleteImageButtonVisible={deleteImageButtonVisible}
              onDelete={() => handleFileDelete(file)}
            />
          );
        })}
      </div>
    </div>
  );
};

export const getImageDimensions = (path: string) => {
  return new Promise(resolve => {
    const image = document.createElement('img');

    image.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = image;

      resolve({ width, height });
    };

    image.onerror = () => resolve(undefined);
    image.src = path;
  });
};
