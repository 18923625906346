import { useContext } from 'react';
import { OpsAuthContext } from 'contexts/OpsAuthContext';
import { OutletContext } from 'contexts/OutletContext';
import { OpsOutletContext } from 'contexts/OpsOutletContext';

export default function useOutletContext() {
  const { isOperations } = useContext(OpsAuthContext) || {};
  return {
    currentOutlet: (
      useContext(isOperations ? OpsOutletContext : OutletContext) || {}
    ).currentOutlet
  };
}
