import React from 'react';
import PropTypes from 'prop-types';
import styles from 'insights/assets/css/InsightsTitleCard.module.scss';

const InsightsTitleCard = ({ id, title, subtitle, image, imageBig }) => (
  <section
    id={id}
    className={styles.titleCard}
    style={{
      '--titleCardBg': `url(${image})`,
      '--titleCardBgBig': `url(${imageBig})`
    }}
  >
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.subtitle}>{subtitle}</p>
  </section>
);

InsightsTitleCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default InsightsTitleCard;
