import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

import ResizeObserver from 'resize-observer-polyfill';

import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import styles from 'assets/css/Carousel.module.scss';

import swipeRightIcon from 'assets/images/feed/swipe-right-arrow.svg';
import swipeLeftIcon from 'assets/images/feed/swipe-left-arrow.svg';
import useDebouncedCallback from 'hooks/useDebouncedCallback';

type CarouselItemProp = {
  width: number | undefined;
  fitAspect: boolean | undefined;
};

export type CarouselProps = {
  className?: string;
  indicatorContainerStyles?: string;
  infinite?: boolean;
  showNavigationButtons?: boolean;
  autoHeight?: boolean;
  aspectHeight?: number | undefined;
  aspectWidth?: number | undefined;
  selectedPreviewState: React.MutableRefObject<number>;
  BottomChildren?: ComponentType;
  onDrawStaticContent?: (
    prevDisabled: boolean,
    nextDisabled: boolean,
    swipeIndex: (isNext: boolean) => void
  ) => ReactNode;
  onDrawIndicator?: (index: number, isActive: boolean) => ReactNode;
  onSwipeFunctionCall?: () => void;
};

export const CarouselItem = ({
  children,
  width,
  fitAspect
}: React.PropsWithChildren<CarouselItemProp>) => {
  const style = classNames(
    styles.carouselItem,
    fitAspect ? styles.fitAspect : ''
  );

  return (
    <div className={style} style={{ width: width }}>
      {children}
    </div>
  );
};

const navButtonIcon = (url: string) => `center no-repeat url(${url})`;

export const caclculateHeightByAspect = (
  width: number | undefined,
  aspectHeight: number | undefined,
  aspectWidth: number | undefined
) => {
  if (
    width === undefined ||
    aspectHeight === undefined ||
    aspectWidth === undefined
  )
    return undefined;

  return Math.round((aspectHeight * width) / aspectWidth);
};

const Carousel: React.FC<CarouselProps> = ({
  className,
  indicatorContainerStyles,
  children,
  infinite = false,
  showNavigationButtons = true,
  autoHeight = false,
  aspectHeight,
  aspectWidth,
  selectedPreviewState,
  BottomChildren,
  onDrawStaticContent,
  onDrawIndicator,
  onSwipeFunctionCall
}) => {
  const [slideWidth, setSlideWidth] = useState<number>();
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLElement | null>(null);
  const childrenRef = useRef<HTMLElement[]>([]);

  const updateIndex = useCallback(
    (newIndex: number) => {
      const count = React.Children.count(children);
      const isLeftEdge = newIndex < 0;
      const isRightEdge = newIndex >= count;

      if (infinite) {
        if (isLeftEdge) newIndex = count - 1;
        else if (isRightEdge) newIndex = 0;

        selectedPreviewState.current = newIndex;
        setActiveIndex(newIndex);
      } else if (!(isLeftEdge || isRightEdge)) {
        selectedPreviewState.current = newIndex;
        setActiveIndex(newIndex);
      }

      if (onSwipeFunctionCall) {
        onSwipeFunctionCall();
      }
    },
    [children, infinite, selectedPreviewState, onSwipeFunctionCall]
  );

  const swipeIndex = (isLeft: boolean) => {
    const newIndex = isLeft ? activeIndex - 1 : activeIndex + 1;

    updateIndex(newIndex);
  };

  const handlers = useSwipeable({
    trackMouse: true,
    trackTouch: true,
    preventDefaultTouchmoveEvent: true,
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  if (!onDrawIndicator)
    onDrawIndicator = (index: number, isActive: boolean) => (
      <div
        className={classNames(styles.dot, isActive ? styles.activeDot : '')}
        onClick={() => {
          if (!isActive) updateIndex(index);
        }}
      ></div>
    );

  const count = React.Children.count(children);
  const prevDisabled = !infinite && activeIndex === 0;
  const nextDisabled = !infinite && activeIndex === count - 1;

  const currentChildHeight = aspectHeight
    ? caclculateHeightByAspect(slideWidth, aspectHeight, aspectWidth)
    : autoHeight
    ? childrenRef.current
      ? childrenRef.current[activeIndex]?.clientHeight
      : 0
    : undefined;

  const handleResize = useDebouncedCallback(([clientWidth]) => {
    if (clientWidth > 0) setSlideWidth(clientWidth);
  }, 200);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setSlideWidth(containerRef.current.clientWidth);

      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        handleResize(entries[0].target.clientWidth)
      );

      observer.observe(containerRef.current);

      return () => {
        if (containerRef.current) observer.unobserve(containerRef.current);
      };
    }
  }, [handleResize]);

  useEffect(() => {
    if (selectedPreviewState.current) updateIndex(selectedPreviewState.current);
  }, [updateIndex, selectedPreviewState]);

  //the last image was deleted
  if (activeIndex >= count) updateIndex(count - 1);

  const refPassthrough = (el: HTMLDivElement | null) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set containerRef el so you can access it yourself
    containerRef.current = el;
  };

  return (
    <div
      {...handlers}
      className={classNames(styles.carousel, className)}
      ref={refPassthrough}
    >
      {(!aspectHeight || (slideWidth !== undefined && slideWidth > 0)) && (
        <div
          className={styles.swiper}
          style={{
            transform: `translateX(-${activeIndex * 100}%)`,
            height: currentChildHeight || 'fitContent'
          }}
        >
          {React.Children.map<ReactNode, ReactNode>(
            children,
            (child, index) => (
              <CarouselItem
                width={slideWidth}
                fitAspect={aspectHeight !== undefined && aspectHeight > 0}
              >
                {React.isValidElement(child) &&
                  React.cloneElement(child, {
                    width: slideWidth,
                    style: { width: slideWidth },
                    ref: (ref: HTMLElement) =>
                      (childrenRef.current[index] = ref)
                  })}
              </CarouselItem>
            )
          )}
        </div>
      )}

      {onDrawStaticContent &&
        onDrawStaticContent(prevDisabled, nextDisabled, swipeIndex)}

      <div className={styles.bottomPane}>
        {count > 1 && (
          <div
            className={classNames(styles.indicators, indicatorContainerStyles)}
          >
            {Array(count)
              .fill(undefined)
              .map((_, index) => (
                <React.Fragment key={index}>
                  {onDrawIndicator &&
                    onDrawIndicator(index, index === activeIndex)}
                </React.Fragment>
              ))}
          </div>
        )}
        {BottomChildren && (
          <div className={styles.bottomChildren}>
            <BottomChildren />
          </div>
        )}
      </div>

      {showNavigationButtons && !prevDisabled && count > 0 && (
        <div
          className={classNames(styles.navButton, styles.navPrev)}
          style={{ background: navButtonIcon(swipeLeftIcon) }}
          onClick={() => swipeIndex(true)}
        ></div>
      )}

      {showNavigationButtons && !nextDisabled && count > 0 && (
        <div
          className={classNames(styles.navButton, styles.navNext)}
          style={{ background: navButtonIcon(swipeRightIcon) }}
          onClick={() => swipeIndex(false)}
        ></div>
      )}
    </div>
  );
};

export default Carousel;
