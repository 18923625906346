import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import { ModalContext } from 'contexts/ModalContext';
import styles from 'assets/css/ECardModal.module.scss';
import useCleverTapOld from 'hooks/useCleverTapOld';

import { ModalProps } from 'components/Modal';
import { ECardProps } from 'components/ECard';
import Button from 'components/Button';
import ECard from 'components/ECard';

import sellingPriceImg from 'assets/images/ecards/selling_price.svg';
import originalPriceImg from 'assets/images/ecards/original_price.svg';
import takebackProfitImg from 'assets/images/ecards/takeback_profit.svg';
import confirmationImg from 'assets/images/ecards/confirmation.svg';
import paymentUponPurchaseImg from 'assets/images/ecards/payment_upon_purchase.svg';
import contactImg from 'assets/images/ecards/contact.svg';
import useChat from './useChat';

type OptionProps = {
  onYes?: () => void;
  onClose: () => void;
};

type ECardModal = {
  title: string;
  img?: string;
  description: (props: ModalProps) => JSX.Element;
  Component?: React.FC<ECardProps | {}>;
  optionsComponent?: (options: {}) => React.ComponentType;
  options?: OptionProps;
};

type ECardModalProps = {
  onClose: () => void;
  modal: ECardModal;
  eCard?: ECardProps | {};
};

type UseECardModalProps = ECardProps & {
  commission: number;
  isSingapore?: boolean;
  isSetup?: boolean;
};

// can't use ECardProps because backend response use snakecase instead of camelcase
type ECardResponse = {
  commission_rate_percent: number;
  discount_rate: number;
  gst: number;
  id: number;
  original_price: number;
  selling_price: number;
  takeback_profit: string;
};

const EMAILS: { [key: string]: string } = {
  RM: 'partners.my@myfave.com',
  S$: 'partners.sg@myfave.com',
  RP: 'pm.id@myfave.com'
};

const getEmailByCurrency = (currency: string) =>
  EMAILS[currency.toUpperCase()] || '';

export default function useECardModal({
  commission,
  discountRate,
  originalPrice,
  currency,
  sellingPrice,
  outletImage,
  companyName,
  isSingapore,
  isSetup
}: UseECardModalProps) {
  const { init } = useContext(ModalContext);
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { openWidget } = useChat();

  const onChatWithUsClick = () => openWidget();

  const eCardModal = ({ onClose, modal, eCard }: ECardModalProps) => (
    <aside className={styles.modal}>
      {modal.img && <img src={modal.img} alt={modal.title} />}
      {modal.Component && <modal.Component {...eCard} />}
      <h3 className={styles.title}>{modal.title}</h3>
      <div className={styles.description}>{modal.description({ onClose })}</div>
      {modal.optionsComponent ? (
        modal.optionsComponent({ onClose, ...modal.options })
      ) : (
        <Button
          type="primary"
          onClick={onClose}
          justify="center"
          className={styles.button}
        >
          {t('Got it')}
        </Button>
      )}
    </aside>
  );

  const openECardModal = (props: { modal: ECardModal; eCard?: ECardProps }) => {
    init({
      Component: eCardModal,
      openOnReady: true,
      closeOnBlur: true,
      componentProps: {
        modal: props.modal,
        eCard: props.eCard
      }
    });
  };

  const modals = {
    sellingPrice: {
      title: t('Selling Price'),
      img: sellingPriceImg,
      description: () => (
        <p>{t('The price you are selling this eCard to customers.')}</p>
      )
    },
    originalPrice: {
      title: t('Original Price (Recommended)'),
      img: originalPriceImg,
      description: () => (
        <>
          <p>{t('The amount customers get.')}</p>
          <p>{t('The recommendation is based on our data points.')}</p>
        </>
      )
    },
    commissionRate: {
      title: t('Commission Rate'),
      Component: () => <div className={styles.commission}>{commission}%</div>,
      description: () => (
        <p>
          {t(
            'Fave charges a commission rate to of {{commission}}% per eCard purchased.',
            { commission }
          )}
        </p>
      )
    },
    // TODO: Remove after 31 May
    commissionRateWaived: {
      title: t('Commission Rate'),
      Component: () => (
        <div className={classNames(styles.commission, styles.waived)}>
          <Trans values={{ commission }}>
            {{ commission }}%<span>Waived</span>
          </Trans>
        </div>
      ),
      description: () => (
        <>
          <p>{t('Fave charges a commission rate per eCard purchased.')}</p>
          <br />
        </>
      )
    },
    takebackProfit: {
      title: t('Takeback Profit'),
      img: takebackProfitImg,
      description: () => (
        <p>
          {isSingapore
            ? t(
                'This is what you receive after deduction of commission rate and GST. Details will be reflected in your Statements.'
              )
            : t('This is what you receive after deduction of commission rate.')}
        </p>
      )
    },
    confirmation: {
      title: t('Are you sure?'),
      img: confirmationImg,
      description: () => (
        <p>
          <Trans
            values={{ currency, originalPrice, sellingPrice, discountRate }}
          >
            You are selling an{' '}
            <strong>
              {{ currency }}
              {{ originalPrice }}
            </strong>{' '}
            eCard for{' '}
            <strong>
              {{ currency }}
              {{ sellingPrice }}
            </strong>{' '}
            at <strong>{{ discountRate }}%</strong> off.
          </Trans>
        </p>
      ),
      optionsComponent: (options: OptionProps) => (
        <div className={styles.optionsContainer}>
          <Button
            type="skeleton"
            onClick={() => options.onClose?.()}
            justify="center"
            isRounded
            className={styles.button}
          >
            {t('No')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              options.onYes?.();
              options.onClose?.();
            }}
            justify="center"
            className={styles.button}
          >
            {t("Yes I'm sure")}
          </Button>
        </div>
      )
    },
    submission: {
      title: t('Submission completed!'),
      Component: (eCard: ECardResponse) => {
        return (
          <ECard
            className={styles.eCard}
            sellingPrice={eCard.selling_price}
            discountRate={eCard.discount_rate}
            originalPrice={eCard.original_price}
            outletImage={outletImage}
            companyName={companyName}
            currency={currency}
            hasSuccessIcon={true}
          />
        );
      },
      description: ({ onClose }: ModalProps) => (
        <>
          <p>
            {t(
              'Thank you! Your eCard has been submitted. Our team will reach out to you shortly.'
            )}
          </p>
          <Button
            className={styles.link}
            type="ghost"
            onClick={() => {
              onClose?.();
              cleverTap.tapped.push({
                screen_name: 'ecard_setup3_postsubmission',
                tapped_on: 'btn_contact_us'
              });
              navigate('/contact-us');
            }}
          >
            {t('Have more questions? Talk to us')}
          </Button>
        </>
      ),
      optionsComponent: (options: OptionProps) => (
        <div className={styles.optionsContainer}>
          <Button
            type="skeleton"
            onClick={() => {
              cleverTap.tapped.push({
                screen_name: 'ecard_setup3_postsubmission',
                tapped_on: 'btn_view_ecards_video'
              });
              window.open('https://www.youtube.com/watch?v=AZ2DqUmyo6E');
            }}
            justify="center"
            isRounded
            className={styles.button}
          >
            {t('View eCards Video')}
          </Button>
          <Button
            type="primary"
            onClick={() => options.onClose?.()}
            justify="center"
            className={styles.button}
          >
            {t('Done')}
          </Button>
        </div>
      )
    },
    paymentUponPurchase: {
      title: t('Payment Upon Purchase'),
      img: paymentUponPurchaseImg,
      description: () => (
        <p>
          {t(
            'Fave will disburse payments to HQ for all eCards purchased in the next payment cadence.'
          )}
        </p>
      )
    },
    contactUs: {
      title: t('Contact Us'),
      img: contactImg,
      description: ({ onClose }: ModalProps) => (
        <>
          <p>
            {t(
              "If there's ever anything we can assist with, please don't hesitate to contact us at:"
            )}
          </p>
          <div className={styles.contact}>
            <Button
              type="ghost"
              href={`mailto:${getEmailByCurrency(currency)}`}
              leftIcon="envelope"
              iconColor="pelorous"
            >
              {getEmailByCurrency(currency)}
            </Button>
            <Button
              type="ghost"
              leftIcon="chat"
              iconColor="pelorous"
              onClick={() => {
                onClose?.();
                isSetup &&
                  cleverTap.tapped.push({
                    screen_name: 'ecard_setup2_presubmission',
                    tapped_on: 'btn_live_chat'
                  });
                onChatWithUsClick();
              }}
            >
              {t('Live chat')}
            </Button>
          </div>
        </>
      )
    }
  };

  return {
    openECardModal,
    modals
  };
}
