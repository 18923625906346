import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';
import CurrencyInput from 'react-currency-input';
import FormikError from 'components/FormikError';

const CurrencyTextInput = props => {
  const {
    errors,
    touched,
    name,
    className,
    withErrors,
    onChange,
    ...fieldProps
  } = props;

  const currencyInputComponent = useCallback(
    ({ field, form }) => (
      <CurrencyInput
        inputType="tel"
        onChangeEvent={form.handleChange(field.name)}
        value={form.values[field.name]}
        selectAllOnFocus={true}
        autoFocus={true}
        thousandSeparator=""
      />
    ),
    []
  );

  return (
    <label htmlFor={name} className={classNames(className)}>
      <Field component={currencyInputComponent} name={name} {...fieldProps} />
      {withErrors && <FormikError {...{ name, errors, touched }} />}
    </label>
  );
};

CurrencyTextInput.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  withErrors: PropTypes.bool
};

CurrencyTextInput.defaultProps = {
  withErrors: false
};

export default CurrencyTextInput;
