import React, { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import getQRCode from 'queries/getQRCode';
import requestQRCode from 'commands/requestQRCode';
import { OutletContext } from 'contexts/OutletContext';
import { NotificationContext } from 'contexts/NotificationContext';
import useCleverTapOld from 'hooks/useCleverTapOld';
import TextInput from 'components/TextInput';
import Button, { PrimaryInvertedButton, LinkButton } from 'components/Button';
import chevronRightIcon from 'assets/images/ic-chevron-right-grey.svg';
import { ReactComponent as FavePayStep1Icon } from 'assets/images/img-favepay-step-1.svg';

import StepCTASection from './StepCTASection';
import SetupCard from './SetupCard';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/FavePaySetupView/GetQRCodeView.module.scss';

function GetQRCodeView({ onSubmit, isMY }) {
  const { t } = useTranslation();
  const { outlets } = useContext(OutletContext);
  const { setNotification } = useContext(NotificationContext);
  const [isQRCTAClicked, setIsQRCTAClicked] = useState(false);

  const companyOutlets = outlets || [];
  const firstOutlet = companyOutlets[0] || {};

  const cleverTap = useCleverTapOld();
  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);

  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'payment_setup1_qrcode' });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  const [theQRCode, setTheQRCode] = useState();

  useEffect(() => {
    let isMounted = true;
    if (!firstOutlet.id || isMY) return;
    getQRCode({ id: firstOutlet.id })
      .then(response => {
        if (!isMounted) return;
        setTheQRCode(response.qr_value);
      })
      .catch(error => {
        if (!isMounted) return;
        setNotification({
          message: error.value.response.error,
          type: 'error'
        });
        navigate('/favepay');
      });
    return () => (isMounted = false);
  }, [firstOutlet, setNotification, isMY]);

  const submitForm = (values, formik) => {
    requestQRCode({ outlet_id: firstOutlet.id, email: values.email })
      .then(() => {
        setNotification({
          message: t('QR code sent to email.'),
          type: 'success'
        });
      })
      .catch(error => {
        setNotification({
          message: error.value.response.error,
          type: 'error'
        });
      })
      .finally(() => {
        formik.resetForm();
        setIsQRCTAClicked(false);
      });
  };

  return (
    <section>
      <SetupCard className={styles.card}>
        <section className={styles.qrCodeWrapper}>
          {isMY ? (
            <FavePayStep1Icon />
          ) : (
            theQRCode && <QRCode size={250} value={theQRCode} />
          )}
        </section>
        <section className={styles.details}>
          <section className={styles.detailsTop}>
            <h3 className={styles.header}>
              {isMY ? t('FavePay account created.') : t('Congratulations!')}
            </h3>
            <p className={styles.description}>
              {isMY
                ? t(
                    'To activate FavePay and get paid, you will have to submit your bank account and business details for verification purposes.'
                  )
                : t(
                    'A unique Fave QR code has been generated for your business. Show this QR code to customers for scanning to receive payments.'
                  )}
            </p>
            {!isMY && (
              <LinkButton
                rightIcon={chevronRightIcon}
                className={styles.moreInfo}
                href={`/payment/${firstOutlet.id}?from=/favepay/setup`}
              >
                {t('More info')}
              </LinkButton>
            )}
          </section>
          {!isMY && (
            <section className={styles.sendQRLayout}>
              {isQRCTAClicked ? (
                <>
                  <Formik
                    initialValues={{ email: '' }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email(t('Invalid email'))
                        .required(t('Email required'))
                    })}
                    onSubmit={submitForm}
                  >
                    {({ errors, touched }) => (
                      <Form className={styles.form}>
                        <TextInput
                          type="email"
                          name="email"
                          placeholder={t('Enter your email address')}
                          className={styles.email}
                          focus
                          {...{ errors, touched }}
                        />
                        <PrimaryInvertedButton type="submit">
                          {t('Send')}
                        </PrimaryInvertedButton>
                      </Form>
                    )}
                  </Formik>
                </>
              ) : (
                <Button
                  size="lg"
                  isRounded
                  fullWidth
                  className={styles.sendQRCTAButton}
                  onClick={() => setIsQRCTAClicked(true)}
                >
                  {t('Send QR to email')}
                </Button>
              )}
            </section>
          )}
        </section>
      </SetupCard>
      <StepCTASection
        submitText={t('Continue with step 2')}
        onSubmit={() => {
          onSubmit();
          cleverTap.tapped.push({
            screen_name: 'payment_setup1_qrcode',
            tapped_on: 'btn_continue_step2'
          });
          navigate('/favepay/setup?step=2', { replace: true });
        }}
      />
    </section>
  );
}

GetQRCodeView.propTypes = {
  onSubmit: PropTypes.func
};

GetQRCodeView.defaultProps = {
  onSubmit: () => {}
};

export default GetQRCodeView;
