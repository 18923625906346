import React, { useContext } from 'react';
import Card from 'components/Card';
import Button from './Button';
import { useTranslation } from 'react-i18next';

import styles from '../assets/css/OutletCard.module.scss';
import outletPlaceholderIcon from 'assets/images/banners/ic-outlet-img-placeholder.svg';
import { AuthContext } from 'contexts/AuthContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { MapleContext } from 'contexts/MapleContext';

export interface OutletCardProps {
  outlet: any;
  showCashbackLabel?: boolean;
  onReceivePaymentClick: () => void;
  onViewInfoClick: () => void;
}

const OutletCard = ({
  outlet,
  showCashbackLabel,
  onReceivePaymentClick,
  onViewInfoClick
}: OutletCardProps) => {
  const { t } = useTranslation();
  const { isMY } = useContext(AuthContext);
  const { isWebview } = useContext(WebviewContext);
  const { isMaple } = useContext(MapleContext);

  const cardImageUrl =
    outlet.upload_image_urls && outlet.upload_image_urls.length > 0
      ? outlet.upload_image_urls[0]
      : null;

  //todo: This uses non-standard --bg. Consider changing to "background".
  //will require refactoring
  const backgroundStyle: any = {
    '--bg': `url(${cardImageUrl || outletPlaceholderIcon})`,
    backgroundSize: 'cover'
  };

  const CashbackLabel = ({ value }: any) => {
    const labelText = value > 0 ? `${value}% cashback` : 'No cashback';

    return <span className={styles.cashbackLabel}>{labelText}</span>;
  };

  return (
    <Card className={styles.outletCard}>
      {showCashbackLabel && <CashbackLabel value={outlet.cashback_rate} />}

      <div className={styles.cardImage} style={backgroundStyle}>
        <div className={styles.gradientShadow} />
      </div>
      <section className={styles.cardContent}>
        <h3 className={styles.title}>{outlet.name}</h3>
        {isMaple && outlet.neighbourhood_slug ? 
          <h3 className={styles.neighbourhoodSlug}>| {outlet.neighbourhood_slug}</h3> :
          <></>
        }
        <div className={styles.address}>{outlet.address}</div>
        <section className={styles.footer}>
          {!isWebview &&
            (isMY() ? outlet.can_receive_payment : outlet.has_fave_payment) && (
              <Button
                type="text"
                className={styles.links}
                onClick={onReceivePaymentClick}
                size="xs"
                textColor="link-color"
                children={t('Receive Payment')}
              />
            )}
          <Button
            type="text"
            className={styles.links}
            onClick={onViewInfoClick}
            rightIcon="angle-right"
            size="xs"
            children={t(isMaple ? 'View Details' : 'View Info')}
          />
        </section>
      </section>
    </Card>
  );
};

export default OutletCard;
