/**
 * Report type
 */
export const REPORT_TYPES = {
  /* 02102019 Zhang: hide AliPay */
  // ALI_PAY: { text: 'Alipay', value: 'alipay_payments' },
  FAVE_PAY: { text: 'FavePay', value: 'fave_payments' },
  DEALS: { text: 'Deals', value: 'deals' },
  ECARD_PURCHASED: { text: 'eCard Purchased', value: 'e_card_purchases' },
  ECARD_UTILISED: { text: 'eCard Utilised', value: 'e_card_utilization' }
};

export const RESPONSIVE_BREAKPOINTS = {
  md: 768,
  sm: 480
};

export const TIMEOUT_IN_MS = 3000;

export const SELF_ONBOARDING_STATUS = {
  ACTIVATE_FAVEPAY: 'activate_favepay',
  LINK_BANK_ACCOUNT: 'link_bank_account',
  STARTER_KIT: 'starter_kit',
  COMPLETED: 'completed',
  NOT_APPLICABLE: 'not_applicable'
};

export const COUNTRY_CODES = [
  { code: '+60', text: 'Malaysia +60' },
  { code: '+62', text: 'Indonesia +62' },
  { code: '+65', text: 'Singapore +65' },
  { code: '+90', text: 'India +90' }
];

/**
 * Fave Campaign
 */
export const FAVE_PAY_CAMPAIGN = 'favepay';

/**
 * Fave Ratings View type
 */
export const FAVE_PAY_RATINGS = 'Favepay';
export const FAVE_DEALS = 'Deals';
export const ASC_RATING = 'rating:asc';
export const DESC_RATING = 'rating:desc';
export const CHRONOLOGICAL = 'created_at';

export const COUNTRIES = {
  'kuala-lumpur': 'Malaysia',
  jakarta: 'Indonesia',
  singapore: 'Singapore',
  'new-delhi': 'India'
};

export const MAP_CITY_TO_COUNTRY_CODE = {
  'kuala-lumpur': 'my',
  jakarta: 'id',
  singapore: 'sg',
  'new-delhi': 'in'
};

export const REVIEWS = {
  FAVEPAY: 'FavePay',
  DEALS: 'Deals',
  ALL: 'All',
  UNREPLIED: 'Unreplied'
};

/**
 * Fave Statements
 */
export const FAVEPAY_STATEMENT = 'fave_pay';
export const FAVEDEALS_STATEMENT = 'redemption';
export const ALIPAY_STATEMENT = 'ali_pay';

/**
 * Fave Transactions
 */
export const ALIPAY_TRANSACTION = 'alipay';

/**
 *  Languages
 */

export const LANGUAGE_KEYS = {
  US: 'en-US',
  ID: 'id-ID',
  MY: 'ms-MY',
  HK: 'zh-HK'
};

export const LANGUAGES = {
  [LANGUAGE_KEYS.US]: 'English',
  [LANGUAGE_KEYS.ID]: 'Indonesian',
  [LANGUAGE_KEYS.MY]: 'Malay',
  [LANGUAGE_KEYS.HK]: 'Mandarin'
};

/**
 *  Months
 */

export const MONTHSARRAY = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const MONTHNUMBER = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sept: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12
};

export const DAYARRAY = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const CLEVERTAP_SCREEN_NAME = {
  STATEMENTS_INDIVIDUAL: 'statements_individual',
  FAVEPAY: 'favepay',
  FAVEPAY_VIEW_INFO: 'favepay_view_info',
  OUTLET_VIEW_INFO: 'outlet_view_info',
  OUTLET: 'outlet',
  HELP_CONTACT: 'help_contact',
  HOME: 'home',
  HOME_EDUCATION: 'home_education',
  GETTING_STARTED: 'getting_started',
  DELETE_ACCOUNT: 'delete_account',
  FEED_NEW: 'feed_new',
  FEED_NEW_ANNOUNCEMENT_STEP1: 'feed_new_announcement_step1',
  FEED_NEW_ANNOUNCEMENT_STEP2: 'feed_new_announcement_step2',
  FEED_EDIT_ANNOUNCEMENT_STEP1: 'feed_edit_announcement_step1',
  FEED_EDIT_ANNOUNCEMENT_STEP2: 'feed_edit_announcement_step2',

  FEED_NEW_FEATURED_STEP1: 'feed_new_featured_step1',
  FEED_NEW_FEATURED_STEP2: 'feed_new_featured_step2',
  FEED_NEW_FEATURED_STEP3: 'feed_new_featured_step3',
  FEED_EDIT_FEATURED_STEP1: 'feed_edit_featured_step1',
  FEED_EDIT_FEATURED_STEP2: 'feed_edit_featured_step2',

  FEED_NEW_POST_SUCCESSFUL: 'feed_new_post_successful',
  FEED_EDIT_POST_SUCCESSFUL: 'feed_edit_post_successful',
  FEED_EDIT_SUBMIT_REVIEW: 'feed_edit_submit_review',
  FEED_ERROR: 'feed_error',
  FEED: 'feed',
  FEED_EDUCATION: 'feed_education',
  FEED_LIST: 'feed_list',
  FEED_DETAIL: 'feed_detail',
  FEED_NEW_LOCKED: 'feed_new_locked',

  AUTO_EXTENSION: 'auto_extension',
  TAX_INVOICE: 'tax_invoice',
  TAX_INVOICE_INDIVIDUAL: 'tax_invoice_individual'
};

export const CLEVERTAP_SECTION_NAME = {
  FILTER_TABS: 'filter_tabs',
  CALENDAR: 'calendar',
  BANNER: 'banner',
  EDUCATION_BANNER: 'education_banner',
  SELECT_OUTLET: 'select_outlet',
  FEED_SHARE: 'feed_share',
  FEED_DELETE: 'feed_delete',
  FEED_REPOST_LIVE: 'feed_repost_live',
  FEED_REPOST_SCHEDULED: 'feed_repost_scheduled'
};

export const CLEVERTAP_TAPPED_ON = {
  BUTTON_HELP_CONTACT: 'btn_help_contact',
  BUTTON_DATE: 'btn_date',
  BUTTON_APPLY_DATE: 'btn_date_apply',
  BUTTON_PAYMENT_STEP_2_BUSINESS: 'btn_payment_setup2_business',
  BUTTON_PAYMENT_STEP_3_STARTER_KIT: 'btn_payment_setup3_starterkit',
  BUTTON_INFO: 'btn_info',
  BUTTON_FAVEPAY_VIEW_INFO: 'btn_favepay_view_info',
  BUTTON_FAVEPAY_RECEIVE_PAYMENT: 'btn_favepay_receive_payment',
  BUTTON_ACTIVE: 'btn_active',
  BUTTON_INACTIVE: 'btn_inactive',
  BUTTON_RENEW_OUTLETCODE: 'btn_renew_outletcode',
  BUTTON_SHARE_OUTLETCODE: 'btn_share_outletcode',
  BUTTON_ENABLE_CODE: 'btn_enablecode',
  BUTTON_DISABLE_CODE: 'btn_disablecode',
  BUTTON_RESET_CANCELLATION_CODE: 'btn_reset_cancellationcode',
  BUTTON_OUTLET_VIEW_INFO: 'btn_outlet_view_info',
  BUTTON_OUTLET_RECEIVE_PAYMENT: 'btn_outlet_receive_payment',
  BUTTON_LIVE_CHAT: 'btn_live_chat',
  BUTTON_CHAT_US: 'btn_chatus',
  BUTTON_EMAIL_US: 'btn_emailus',
  BUTTON_WA_US: 'btn_wa_us',
  BUTTON_SHOW_ME_AROUND: 'btn_show_me_around',
  BUTTON_ECARDS: 'btn_ecards',
  BUTTON_DEALS: 'btn_deals',
  BUTTON_SELECT_DEAL: 'btn_select_deal',
  BUTTON_SELECT_ECARD: 'btn_select_ecard',
  BUTTON_SUBMIT: 'btn_submit',
  BUTTON_DONE: 'btn_done',
  BUTTON_FEATURED_POST: 'btn_featured_post',
  BUTTON_ANNOUNCEMENT_POST: 'btn_announcement_post',
  BUTTON_SEARCH_OUTLET: 'btn_search_outlet',
  BUTTON_FAQ: 'btn_faq',
  BUTTON_LEARN_MORE_FEED: 'btn_learnmore_feed',
  BUTTON_CONTINUE: 'btn_continue',
  BUTTON_ADD_FEED: 'btn_add_feed',
  BUTTON_SCHEDULED: 'btn_scheduled',
  BUTTON_FEED_DETAIL: 'btn_feed_detail',
  BUTTON_REPLACE: 'btn_replace',
  BUTTON_EDIT: 'btn_edit',
  BUTTON_MORE: 'btn_more',
  BUTTON_SHARE: 'btn_share',
  BUTTON_COPY: 'btn_copy',
  BUTTON_DELETE: 'btn_delete',
  BUTTON_CONFIRM: 'btn_confirm',
  BUTTON_CANCEL: 'btn_cancel',
  BUTTON_VIEW_OUTLETS: 'btn_view_outlets',
  BUTTON_VIEW_SERVICES: 'btn_view_services',
  BUTTON_ACTIVATE_FAVEPAY: 'btn_activate_favepay',
  BUTTON_SETUP_DEALS: 'btn_setup_deals',
  BUTTON_CREATE_NEW_ECARD: 'btn_create_new_ecard',
  BUTTON_REPLY: 'btn_reply',
  BUTTON_CONTACT_US: 'contact_us',
  BUTTON_DEACTIVATION: 'btn_deactivation',
  BUTTON_TAX_INVOICE: 'btn_tax_invoice',
  TAX_INVOICE_INDIVIDUAL: 'tax_invoice_individual',
  FILTER_TAX_INVOICE_MONTHLY: 'filter_tax_invoice_monthly',
  BUTTON_SEND: 'btn_send'
};

export const CLEVERTAP_FEED_POST_TYPES = {
  ANNOUNCEMENT: 'announcement',
  FEATURED: 'featured'
};

export const CLEVERTAP_SUBMISSION_TYPES = {
  LIVE: 'live',
  SCHEDULED: 'scheduled',
  INACTIVE: 'inactive'
};

export const CLEVERTAP_ADDITIONAL = {
  NO_SERVICES: 'no_services',
  NO_FAVEPAY: 'no_favepay',
  NO_OFFERS: 'no_offers',
  ADMIN_LOCKED_BOTH: 'admin_locked_both',
  ADMIN_LOCKED_ANNOUNCEMENT: 'admin_locked_announcement',
  ADMIN_LOCKED_FEATURED: 'admin_locked_featured',
  TYPE_DEALS: 'deals',
  TYPE_ECARDS: 'eCards'
};
