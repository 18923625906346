import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  FAVE_PAY_RATINGS,
  FAVE_DEALS,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import { postFaveDealsReply, postFavePayReply } from 'commands/replyReview';
import Button, { TappedButton } from 'components/Button';
import StarRating from 'components/StarRating';
import { useTranslation } from 'react-i18next';

import styles from '../assets/css/UserReview.module.scss';
import ShowMoreText from './common/ShowMoreText';
import { formatDate } from 'helpers/dateUtility';
import { WebviewContext } from 'contexts/WebviewContext';
import useCleverTapOld from 'hooks/useCleverTapOld';

export type Review = {
  id: number;
  created_at: string;
  comment: string;
  review_type: string;
  rating: number;
  user: {
    name: string;
  };
  review_reply: {
    content: string;
    created_at: string;
  };
};

export type UserReviewProps = {
  review: Review;
  handleReviewsChange: (type: Review['review_type']) => void;
  onReviewClicked?: (review: Review) => void;
  targetScroll: number;
};

export const ReviewCard = ({
  review,
  showMoreDisabled
}: {
  review: Review;
  showMoreDisabled?: boolean;
}) => {
  const { created_at, rating, user, comment } = review;

  const name = user ? user.name : 'Unknown';
  const reviewDate = formatDate(created_at);

  return (
    <div className={styles.card}>
      <div className={styles.name}>{name}</div>
      <section className={styles.topSection}>
        <div className={styles.starRating}>
          <StarRating star={rating} />
        </div>
        <div className={styles.date}>{reviewDate}</div>
      </section>
      <ShowMoreText className={styles.userComment} disabled={showMoreDisabled}>
        {comment}
      </ShowMoreText>
    </div>
  );
};

export const ReplyCard = ({
  review,
  onClick,
  clampSecondLine
}: {
  review: Review;
  onClick?: (() => void) | undefined;
  clampSecondLine?: boolean;
}) => {
  const { t } = useTranslation();
  const { isWebview } = useContext(WebviewContext);

  const { review_reply } = review;
  const replyValue = review.review_reply && review.review_reply.content;

  const replyDate = formatDate(review_reply?.created_at);

  return (
    <>
      {replyValue && replyValue !== '' && (
        <div className={styles.replyComment} onClick={onClick}>
          <div className={styles.replyHeader}>
            <span>{t('Your Reply')}</span>
            {replyDate && <div className={styles.date}>{replyDate}</div>}
          </div>
          {isWebview ? (
            <p className={clampSecondLine ? styles.clampSecondLine : ''}>
              {review_reply.content}
            </p>
          ) : (
            <ShowMoreText disabled={!clampSecondLine}>
              {review_reply.content}
            </ShowMoreText>
          )}
        </div>
      )}
    </>
  );
};

export const handleSubmitReview = (
  replyText: string,
  review: Review,
  handleReviewsChange: (type: string) => void,
  seteditReviewId: React.Dispatch<React.SetStateAction<number | undefined>>,
  isAllReview = false
) => {
  const userReviewType =
    review.review_type.charAt(0).toUpperCase() + review.review_type.slice(1);

  const trimmedComment = replyText.trim();

  if (replyText && trimmedComment !== '') {
    if (userReviewType === FAVE_DEALS) {
      postFaveDealsReply({
        review_id: review.id,
        content: trimmedComment
      })
        .then(
          res =>
            res.review.review_reply.content &&
            !isAllReview &&
            handleReviewsChange(userReviewType)
        )
        .finally(() => seteditReviewId(undefined));
    } else if (userReviewType === FAVE_PAY_RATINGS) {
      postFavePayReply({
        fave_save_review_id: review.id,
        content: trimmedComment
      })
        .then(
          res =>
            res.fave_save_review.review_reply &&
            !isAllReview &&
            handleReviewsChange(userReviewType)
        )
        .finally(() => seteditReviewId(undefined));
    }
  }
};

const UserReview: React.FC<UserReviewProps> = ({
  review,
  handleReviewsChange,
  onReviewClicked,
  targetScroll
}) => {
  const { t } = useTranslation();
  const { isWebview } = useContext(WebviewContext);
  const cleverTap = useCleverTapOld();
  const screenName = 'reviews';

  const replyContent = useRef<HTMLTextAreaElement>(null);
  const scrollToRef = useRef<HTMLDivElement[]>([]);

  const [editReviewId, seteditReviewId] = useState<number | undefined>();

  const replyValue = review.review_reply && review.review_reply.content;

  useEffect(() => {
    const y =
      scrollToRef.current[targetScroll] &&
      scrollToRef.current[targetScroll].getBoundingClientRect().top;

    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }, [targetScroll]);

  const handleCancel = () => seteditReviewId(undefined);

  const handleReplyClick = () => {
    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_REPLY
    });
    isWebview
      ? onReviewClicked && onReviewClicked(review)
      : seteditReviewId(review.id);
  };
  const isViewOrEditMode = editReviewId === review.id;

  const onClickSubmit = () => {
    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_SUBMIT
    });
    replyContent.current &&
      handleSubmitReview(
        replyContent.current.value,
        review,
        handleReviewsChange,
        seteditReviewId
      );
  };

  return (
    <div
      className={styles.reviewContainer}
      key={review.id}
      ref={el => el && (scrollToRef.current[review.id] = el)}
    >
      <ReviewCard review={review} />

      <section className={styles.bottomSection}>
        {isViewOrEditMode ? (
          <>
            <form className={styles.replyTextArea}>
              <textarea
                ref={replyContent}
                name="textarea1"
                placeholder={t('Enter your reply here')}
              ></textarea>
            </form>
            <div className={styles.replyButtonControl}>
              <Button
                isRounded
                className={styles.cancelButton}
                onClick={handleCancel}
              >
                {t('Cancel')}
              </Button>
              <Button
                isRounded
                className={styles.submitButton}
                onClick={onClickSubmit}
              >
                {t('Submit')}
              </Button>
            </div>
          </>
        ) : (
          <>
            {replyValue !== null && replyValue !== '' ? (
              <ReplyCard
                review={review}
                onClick={() => onReviewClicked && onReviewClicked(review)}
                clampSecondLine
              />
            ) : (
              <>
                {!review.review_reply && (
                  <TappedButton
                    isRounded
                    type="primary"
                    leftIcon="reply"
                    iconColor="text-light-gray"
                    textColor="white"
                    className={styles.replyButton}
                    onClick={handleReplyClick}
                  >
                    {t('Reply')}
                  </TappedButton>
                )}
              </>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default UserReview;
