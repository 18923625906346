import 'whatwg-fetch';

import { jsonToQueryString } from 'insights/utils';

const responseHandler = response =>
  (response.headers.get('content-type') || '').includes('json')
    ? response.json()
    : response.text();

export default {
  get: ({ url = '', params, endpoint = '/v1/' }) =>
    fetch(
      `${
        process.env.REACT_APP_FAVEDATA_API
      }${endpoint}${url}${jsonToQueryString(params)}`,
      {
        headers: { apikey: process.env.REACT_APP_FAVEDATA_TOKEN }
      }
    ).then(responseHandler)
};
