import React from 'react';

import useCleverTapOld from 'hooks/useCleverTapOld';
import Button, { IButtonProps } from './Button';

// TODO: Extract clavertap event interface to its own separate components
export interface ITappedButtonProps extends IButtonProps {
  event?: {
    // TODO: Make screen_name & tapped_on not optional after migration. remove name.
    name?: string;
    screen_name?: string;
    tapped_on?: string;
    section_name?: string;
    props?: {};
  };
}

const TappedButton: React.FC<ITappedButtonProps> = ({
  event,
  onClick = () => {},
  children,
  ...restProps
}) => {
  const CleverTap = useCleverTapOld();
  return (
    <Button
      onClick={e => {
        event && CleverTap.tapped.push(event);
        onClick(e);
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default TappedButton;
