import React, { PropsWithChildren } from 'react';
import styles from 'assets/css/Table.module.scss';
import classNames from 'classnames';

type TableProps = PropsWithChildren<{
  className?: string;
}>;

const Table: React.FC<TableProps> = ({ className, children }) => (
  <section className={classNames(styles.TableContainer, className)}>
    <table className={styles.Table}>{children}</table>
  </section>
);

export default Table;
