import { useEffect } from 'react';

// any optimization of this hook should maintain type safety
export default function useWebCallback(func: () => void) {
  useEffect(() => {
    const cleanFunc = func();

    return cleanFunc;
  }, [func]);
}
