import React, { useContext, useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import Button from 'components/Button';
import Heading from 'components/Heading';
import AppLayout from 'layouts/AppLayout';
import { Trans, useTranslation } from 'react-i18next';
import style from 'assets/css/PostDetailsView.module.scss';
import PostTemplateIcon from 'assets/images/FaveFeed/post-template-icon.svg';
import ViewsIcon from 'assets/images/FaveFeed/views-icon.svg';
import FavedBusinessIcon from 'assets/images/FaveFeed/faved-business-icon.svg';
import SharedPostIcon from 'assets/images/FaveFeed/shared-post-icon.svg';
import HidePostIcon from 'assets/images/FaveFeed/hide-post-icon.svg';
import BannerMessage from 'components/BannerMessage';
import Grid from 'components/Grid';
import classNames from 'classnames';
import useModal from 'hooks/useModal';
import BulkActionsButton from 'components/Button/BulkActionsButton';
import ModalContainer from 'components/common/ModalContainer';
import { filter, isEmpty, size } from 'lodash';
import getFaveFeedPostDetails, {
  FaveFeedPostDetailsResponse
} from 'queries/getFaveFeedPostDetails';
import LoadingSpinner from 'components/LoadingSpinner';
import { WebviewContext } from 'contexts/WebviewContext';
import WebviewHeading from 'components/WebviewHeading';
import { ImageCarousel } from 'components/FeedPostPreviewCard';
import deleteFeedPost from 'commands/deleteFeedPost';
import {
  formatDate,
  formatDateAndTime,
  formatDateAndTimeWithGMT
} from 'helpers/dateUtility';
import useCleverTapOld from 'hooks/useCleverTapOld';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import { call, NATIVE } from 'utils/nativeInterface';
import { OutletContext } from 'contexts/OutletContext';
import { MoreOutletsModal } from 'components/FaveFeedModals';
import { FeatureItem } from 'queries/getFeatureListing';
import { FeedPostTypes, getFeedPostTypeFromApiName } from 'uiHelpers/feedForm';
import FeedECardPreview from 'components/FeedECardPreview';
import { copyToClipboard } from 'utils';
import {
  DeletedOffersTemplateText,
  EmptyImageTemplateText
} from './FaveFeedView';

const PostDetailsView = (props: { post_id: number }) => {
  const { post_id } = props;
  const { t } = useTranslation();
  const { isWebview } = useContext(WebviewContext);
  const { currentCompany } = useContext(OutletContext);
  const cleverTap = useCleverTapOld();
  const [hasSentCT, setHasSentCT] = useState(false);
  const [postData, setPostData] = useState<FaveFeedPostDetailsResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShareLinkModalOpen, setIsShareLinkModalOpen] = useState(false);
  const [isMoreOutletsModalOpen, setIsMoreOutletsModalOpen] = useState(false);
  const [isErrorDeletePost, setIsErrorDeletePost] = useState(false);
  const [isErrorNoPostData, setIsErrorNoPostData] = useState(false);

  const dealsOutlets = postData?.deal?.outlets;
  const eCardsOutlets = postData?.e_card?.outlets;

  // post type
  const isAnnouncementPost = postData?.type === 'announcement';
  const isDealPost = postData?.type === 'deal';
  const isECardPost = postData?.type === 'e_card';

  const isActivePost = postData?.status === 'active';
  const isInactivePost = postData?.status === 'inactive';
  const isFlaggedPost = postData?.flagged;
  const isScheduledPost = postData?.status === 'scheduled';
  const isUnderReviewPost = postData?.status === 'review';
  const isExpired = postData?.expired;
  const isOfferDeleted =
    postData &&
    ((isDealPost && !postData.deal) || (isECardPost && !postData.e_card));

  const offerName = postData
    ? (isDealPost && postData.deal?.name) ||
      (isECardPost && postData.e_card?.name)
    : '';

  // values for clevertap
  const isPostInactiveClevertap = isInactivePost || isUnderReviewPost;
  const isAnnouncementPostClevertap =
    postData?.type === 'announcement' ? 'announcement' : 'featured';

  // hide only for isUnderReviewPost and isFlaggedPost
  // anything besides that should be displayed.
  const showInsightsData = !isUnderReviewPost && !isFlaggedPost;

  const selectedPreviewImageRef = useRef(0);

  useEffect(() => {
    setIsLoading(true);
    getFaveFeedPostDetails({ id: post_id })
      .then(data => setPostData(data))
      .catch(() => setIsErrorNoPostData(true))
      .finally(() => setIsLoading(false));
  }, [post_id]);

  useEffect(() => {
    if (!hasSentCT && postData) {
      cleverTap.screenDisplayed.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
        post_status: isPostInactiveClevertap ? 'inactive' : postData?.status,
        post_type: isAnnouncementPostClevertap
      });
      setHasSentCT(true);
    }
  }, [
    hasSentCT,
    cleverTap.screenDisplayed,
    postData,
    isPostInactiveClevertap,
    isAnnouncementPostClevertap
  ]);

  const DetailsRow = ({
    title,
    content
  }: {
    title: string;
    content: string | [] | number;
  }) => (
    <div className={style.detailsRow}>
      <p className={style.detailsTitle}>{title}</p>
      <p className={style.detailsContent}>{content}</p>
    </div>
  );

  const OutletDetailsRow = ({ title }: { title: string }) => {
    const announcementOutlets = postData?.outlets.filter(
      outlet => outlet.checked
    );

    const outlets = isAnnouncementPost
      ? announcementOutlets
      : isDealPost
      ? dealsOutlets
      : eCardsOutlets;

    const outletsArray: JSX.Element[] = [];
    const maxOutletsInRow = 2;
    const minOutletsInRow = 1;
    const numberOfOutlets = size(outlets);
    const loopLimit =
      numberOfOutlets >= maxOutletsInRow ? maxOutletsInRow : minOutletsInRow;

    const isMoreThanTwoOutlets = numberOfOutlets > maxOutletsInRow;

    const moreOutlets = () => {
      for (let index = 0; index < loopLimit; index++) {
        const shouldRenderComma = index < numberOfOutlets - 1;
        outletsArray.push(
          <p className={style.firstTwoOutlets}>
            {isAnnouncementPost &&
              announcementOutlets &&
              announcementOutlets[index].name}
            {isDealPost && dealsOutlets && dealsOutlets[index]}
            {isECardPost && eCardsOutlets && eCardsOutlets[index]}
            {shouldRenderComma && ','}
          </p>
        );
      }

      const onClickViewMoreOutlets = () => {
        cleverTap.tapped.push({
          screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
          tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_VIEW_OUTLETS,
          post_status: postData?.status,
          post_type: isAnnouncementPostClevertap
        });
        setIsMoreOutletsModalOpen(true);
      };
      return outlets ? (
        <>
          {isMoreThanTwoOutlets ? (
            <p
              className={style.remainingOutlets}
              onClick={onClickViewMoreOutlets}
            >
              {`${numberOfOutlets} ${t('outlets')}.`}
            </p>
          ) : (
            outletsArray.map((item, _) => (
              <React.Fragment key={_}>{item}</React.Fragment>
            ))
          )}
        </>
      ) : (
        '-'
      );
    };
    return (
      <div className={style.detailsRow}>
        <p className={style.detailsTitle}>{title}</p>
        <div className={style.detailsContent}>{moreOutlets()}</div>
      </div>
    );
  };

  const InsightsCard = ({
    title,
    count,
    icon
  }: {
    title: string;
    count: number;
    icon: string;
  }) => (
    <div className={style.insightsCard}>
      <div className={style.insightsIcon}>
        <img src={icon} alt="" />
      </div>
      <div className={style.insightsDetails}>
        <div className={style.title}>{title}</div>
        <div className={style.count}>{count}</div>
      </div>
    </div>
  );

  const InfoCard = ({
    text,
    onClick
  }: {
    text: string;
    onClick: () => void;
  }) => (
    <div className={style.infoCard} onClick={onClick}>
      <div className={style.infoText}>{text}</div>
      <Button
        type="text"
        className={style.viewPostDetails}
        rightIcon="angle-right"
        size="xs"
      />
    </div>
  );

  const DeleteModal = ({ onClose }: { onClose: () => void }) => {
    const onClickDeletePost = () => {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
        section_name: CLEVERTAP_SECTION_NAME.FEED_DELETE,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CONFIRM,
        post_type: isAnnouncementPostClevertap
      });
      deleteFeedPost({
        id: post_id
      })
        .then(() => {
          onClose();
          navigate('/feed?activeTab=active');
        })
        .catch(() => {
          setIsErrorDeletePost(true);
        });
    };

    const onClickCancelDeletePost = () => {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
        section_name: CLEVERTAP_SECTION_NAME.FEED_DELETE,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CANCEL,
        post_type: isAnnouncementPostClevertap
      });
      onClose();
    };
    return (
      <ModalContainer className={style.deletePostModal}>
        <p className={style.title}>{t('Are you sure?')}</p>
        <p className={style.content}>
          {t(
            'This post will be moved to the Inactive tab and can’t be reactivated.'
          )}
        </p>
        <div className={style.confirmationButtons}>
          <Button
            onClick={onClickCancelDeletePost}
            className={classNames(style.button, style.marginRight)}
            type={'inversePrimary'}
            size={'lg'}
          >
            {t('Cancel')}
          </Button>
          <Button
            className={style.button}
            type={'primary'}
            onClick={onClickDeletePost}
            size={'lg'}
          >
            {t('Confirm')}
          </Button>
        </div>
        <div className={style.errorMessage}>
          {isErrorDeletePost && <p>{t('Error occured.')}</p>}
        </div>
      </ModalContainer>
    );
  };

  const ShareLinkModal = () => {
    const [isCopiedLink, setIsCopiedLink] = useState(false);
    const clipboardText = postData?.share.url || '';
    const copyLinkToClipboard = () => {
      cleverTap.tapped.push({
        screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
        section_name: CLEVERTAP_SECTION_NAME.FEED_SHARE,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_COPY,
        post_type: isAnnouncementPostClevertap
      });
      setIsCopiedLink(true);
      if (isWebview) {
        call(NATIVE.COPY_TO_CLIPBOARD, clipboardText);
      } else {
        copyToClipboard({
          text: clipboardText
        });
      }
    };

    return (
      <ModalContainer className={style.shareLinkModal}>
        <p className={style.title}>{t('Share this post link')}</p>
        <div className={style.copyLink}>
          <p className={style.link}>{t(clipboardText)}</p>
          <Button type={'primary'} onClick={copyLinkToClipboard}>
            {t('Copy')}
          </Button>
        </div>
        <p
          className={classNames(
            style.copyLinkToClipboard,
            isCopiedLink && style.isOpen
          )}
        >
          {t('Link copied to clipboard.')}
        </p>
      </ModalContainer>
    );
  };

  useModal({
    Component: DeleteModal,
    openOnReady: isDeleteModalOpen,
    onClose: () => setIsDeleteModalOpen(false),
    hasCloseButton: false
  });

  useModal({
    Component: ShareLinkModal,
    openOnReady: isShareLinkModalOpen,
    onClose: () => setIsShareLinkModalOpen(false)
  });

  useModal({
    Component: () => (
      <MoreOutletsModal
        outlets={
          isAnnouncementPost && postData
            ? postData.outlets
                .filter(outlet => outlet.checked)
                .map(item => item.name)
            : isDealPost
            ? dealsOutlets
            : eCardsOutlets
        }
      />
    ),
    openOnReady: isMoreOutletsModalOpen,
    onClose: () => setIsMoreOutletsModalOpen(false)
  });

  const onClickShareButton = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_SHARE,
      post_type: isAnnouncementPostClevertap
    });
    setIsShareLinkModalOpen(true);
  };

  const onClickDeleteButton = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_DELETE,
      post_type: isAnnouncementPostClevertap
    });
    setIsDeleteModalOpen(true);
  };

  const onClickEditButton = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_EDIT,
      post_status: postData?.status,
      post_type: isAnnouncementPostClevertap
    });
    navigate(`/feed/edit/${post_id}`);
  };

  const onClickMoreButton = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_MORE,
      post_type: isAnnouncementPostClevertap
    });
  };

  const onClickFAQButton = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_FAQ,
      post_type: isAnnouncementPostClevertap
    });
    if (isWebview) {
      call(NATIVE.FAVEFEED_FAQ);
    } else {
      return window.open(
        'https://support.favebiz.com/support/solutions/articles/43000641836-fave-feed'
      );
    }
  };

  const onClickChatWithUsButton = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.FEED_DETAIL,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CHAT_US,
      post_type: isAnnouncementPostClevertap
    });
    if (isWebview) {
      call(NATIVE.OPEN_HELP);
    } else {
      navigate('/contact-us');
    }
  };

  const bulkActionsButtons = filter(
    [
      !isScheduledPost && {
        text: t('Share'),
        onClick: onClickShareButton
      },
      {
        text: t('Delete'),
        onClick: onClickDeleteButton
      }
    ],
    Boolean
  ) as [];

  const getBackgroundImage = (url: string) =>
    `url(${url}) center center / contain no-repeat`;

  const postType = postData
    ? getFeedPostTypeFromApiName(postData.type)
    : FeedPostTypes.Announcement;

  const featureItem = postData
    ? (postData[
        postData.type as keyof FaveFeedPostDetailsResponse
      ] as FeatureItem)
    : undefined;

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading
          title={t('Post')}
          onBack={() => {
            navigate('/feed');
          }}
        />
      ) : (
        <Heading
          title={t('Post')}
          subtitle={t('Review the post that you have created.')}
        />
      )}
      {!isWebview && (
        <Button
          leftIcon="arrow-left"
          onClick={() => navigate(`/feed`)}
          type="text"
          children={t('Back')}
          iconColor={'nobel'}
          textColor={'charcoal-gray'}
        />
      )}
      {isErrorNoPostData ? (
        <BannerMessage message={t(`Error occured.`)} type={'warning'} />
      ) : isLoading ? (
        <LoadingSpinner />
      ) : (
        postData && (
          <>
            {isScheduledPost && (
              <BannerMessage
                Component={() => (
                  <div>
                    <Trans>
                      {'Scheduled to go live on '}
                      <span className={style.bold}>
                        {{
                          datetime: formatDateAndTime(postData.scheduled_date)
                        }}
                        .
                      </span>
                    </Trans>
                  </div>
                )}
              />
            )}
            {isInactivePost && isFlaggedPost && (
              <BannerMessage
                message={
                  'Your post has been flagged. This post is hidden from your customers.'
                }
                type={'warning'}
              />
            )}
            {isUnderReviewPost && (
              <BannerMessage
                message={
                  'Your post is being reviewed. This post is hidden from your customers.'
                }
                type={'warning'}
              />
            )}
            {isExpired && (
              <BannerMessage
                message={
                  'Your offer has expired. This post is hidden from your customers.'
                }
                type={'warning'}
              />
            )}
            <p className={style.bold}>{t(`Post Details`)}</p>
            <div className={style.detailsContainer}>
              <div className={classNames(style.cardWrapper)}>
                <div className={style.postCard}>
                  <div className={style.header}>
                    <div
                      style={{
                        background: getBackgroundImage(currentCompany.logo_url)
                      }}
                      className={style.logo}
                    />
                    <div className={style.headerContent}>
                      <span className={style.title}>
                        {currentCompany.partner_name}
                      </span>
                      <span className={style.subtitle}>
                        {formatDate(postData.scheduled_date)}
                      </span>
                    </div>
                  </div>

                  <span className={style.description}>
                    {postData.description}
                  </span>

                  <div className={style.image}>
                    {postType === FeedPostTypes.eCards ? (
                      isOfferDeleted ? (
                        <ImageCarousel
                          images={[]}
                          EmptyImagesPreview={
                            isEmpty(postData.images)
                              ? () => (
                                  <div className={style.postTemplate}>
                                    <img
                                      src={PostTemplateIcon}
                                      alt="post template"
                                    />
                                    <Trans>
                                      <DeletedOffersTemplateText
                                        isDealPost={isDealPost}
                                      />
                                    </Trans>
                                  </div>
                                )
                              : undefined
                          }
                          decription={featureItem?.name}
                          selectedPreviewState={selectedPreviewImageRef}
                        />
                      ) : (
                        <FeedECardPreview item={featureItem} />
                      )
                    ) : (
                      <ImageCarousel
                        images={
                          postType === FeedPostTypes.Deals && featureItem
                            ? featureItem.images
                            : postData.images.map(image => image.url)
                        }
                        EmptyImagesPreview={
                          isEmpty(postData.images)
                            ? () => (
                                <div className={style.postTemplate}>
                                  <img
                                    src={PostTemplateIcon}
                                    alt="post template"
                                  />
                                  <Trans>
                                    {isOfferDeleted ? (
                                      <DeletedOffersTemplateText
                                        isDealPost={isDealPost}
                                      />
                                    ) : (
                                      <EmptyImageTemplateText />
                                    )}
                                  </Trans>
                                </div>
                              )
                            : undefined
                        }
                        decription={featureItem?.name}
                        selectedPreviewState={selectedPreviewImageRef}
                      />
                    )}
                  </div>
                  <div className={style.postDetailsButtons}>
                    {(isActivePost || isScheduledPost || isFlaggedPost) && (
                      <Button
                        type={'primary'}
                        onClick={onClickEditButton}
                        className={style.editButton}
                        fullWidth={true}
                        size={'lg'}
                        textBold={true}
                      >
                        {isWebview ? t('Edit post') : t('Edit')}
                      </Button>
                    )}
                    {/* should hide if flagged */}
                    {!isInactivePost && !isUnderReviewPost && (
                      <BulkActionsButton
                        className={style.bulkActionsButton}
                        buttonProps={bulkActionsButtons}
                        onClickProps={onClickMoreButton}
                        size={'lg'}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={style.detailsRowContainer}>
                <DetailsRow title={t('Post ID')} content={postData.id} />
                <DetailsRow
                  title={t('Created By')}
                  content={`${postData.created_by}, ${formatDateAndTimeWithGMT(
                    postData.created_on
                  )}`}
                />
                <DetailsRow
                  title={t('Updated By')}
                  content={
                    postData.updated_by
                      ? `${postData.updated_by}, ${formatDateAndTimeWithGMT(
                          postData.updated_on
                        )}`
                      : '-'
                  }
                />
                {(isDealPost || isECardPost) && (
                  <DetailsRow
                    title={t('Offer')}
                    content={
                      isOfferDeleted ? 'offer is deleted' : offerName || '-'
                    }
                  />
                )}
                <OutletDetailsRow title={t('Outlets')} />

                {/* for flagged and under review posts */}
                {(isFlaggedPost || isUnderReviewPost) && (
                  <>
                    <div className={style.marginTop} />
                    <InfoCard
                      text={t('Frequently asked question')}
                      onClick={onClickFAQButton}
                    />
                    <InfoCard
                      text={t('Chat with us')}
                      onClick={onClickChatWithUsButton}
                    />
                  </>
                )}
              </div>
            </div>

            {/* for active, scheduled, inactive posts */}
            {showInsightsData && (
              <>
                <p className={classNames(style.marginTop, style.bold)}>
                  {t(`Insights`)}
                </p>
                <Grid
                  columns={3}
                  minWidth={'13rem'}
                  rowGap={'1rem'}
                  columnGap={'1.5rem'}
                  className={style.insightsContainer}
                >
                  <InsightsCard
                    title={t('Interactions')}
                    count={postData.viewed_count}
                    icon={ViewsIcon}
                  />
                  <InsightsCard
                    title={t('Faved Business')}
                    count={postData.faved_count}
                    icon={FavedBusinessIcon}
                  />
                  <InsightsCard
                    title={t('Shared Post')}
                    count={postData.shared_count}
                    icon={SharedPostIcon}
                  />
                  <InsightsCard
                    title={t('Hide Post')}
                    count={postData.hide_count}
                    icon={HidePostIcon}
                  />
                </Grid>
              </>
            )}
          </>
        )
      )}
    </AppLayout>
  );
};

export default PostDetailsView;
