import FaveBizAPI from 'integrations/FaveBizAPI';
import DataLoader from 'dataloader';

const Repo = new DataLoader(
  keys => Promise.all(keys.map(key => FaveBizAPI.get(key))),
  {
    batch: false,
    cacheKeyFn: key => JSON.stringify(key)
  }
);

function loadInfo(key, reload) {
  if (reload) Repo.clearAll();
  return Repo.load(key);
}

export default () => loadInfo(`/v1/deals/deactivation_reasons`);
