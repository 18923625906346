import kachingSound from 'assets/sound/kaching.mp3';
import { RelaymanContext } from 'contexts/RelaymanContext';
import { WebviewContext } from 'contexts/WebviewContext';
import { Howl, Howler } from 'howler';
import React, { createContext, useContext, useEffect, useRef } from 'react';

const TransactionNotificationContext = createContext({});

const TransactionNotificationProvider = (props: any) => {
  Howler.autoUnlock = true;
  const { isWebview } = useContext(WebviewContext);
  const buzzerAudio = useRef<Howl>();
  const unlock = () => {
    if (!buzzerAudio.current) return;
    buzzerAudio.current.play();
    buzzerAudio.current.stop();
    buzzerAudio.current.mute(false);
  };

  useEffect(() => {
    if (isWebview) return;
    // init only once, keep the permission after unlock
    if (!buzzerAudio.current) {
      buzzerAudio.current = new Howl({
        src: [kachingSound],
        mute: true,
        onplayerror: () => {
          buzzerAudio.current?.once('unlock', unlock);
        },
        onunlock: () => unlock()
      });
    }
  }, [isWebview]);

  const { onNewTransaction } = useContext(RelaymanContext);
  useEffect(() => {
    onNewTransaction(() => {
      buzzerAudio.current?.play();
    });
  }, [onNewTransaction]);

  return (
    <TransactionNotificationContext.Provider value={{}}>
      {props.children}
    </TransactionNotificationContext.Provider>
  );
};

export { TransactionNotificationContext, TransactionNotificationProvider };
