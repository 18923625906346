import { useEffect } from 'react';

type UseCloseOnElementBlurProps = {
  elementRef: React.RefObject<HTMLElement>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  stayOpen: boolean;
  elementToIgnore: React.RefObject<HTMLElement>;
};

export default function useCloseOnElementBlur({
  elementRef,
  setIsOpen,
  stayOpen = false,
  elementToIgnore
}: UseCloseOnElementBlurProps) {
  useEffect(() => {
    if (!elementRef.current) return;
    const onClick = (e: MouseEvent) => {
      // stackoverflow.com/questions/43842057/detect-if-click-was-inside-react-component-or-not-in-typescript
      const isClickSelf = elementRef.current!.contains(e.target as HTMLElement);
      const noElementsToIgnore = elementToIgnore
        ? !elementToIgnore.current!.contains(e.target as HTMLElement)
        : true;
      setIsOpen(
        isClickSelf &&
          (stayOpen && noElementsToIgnore
            ? true
            : (prevIsOpen) => !prevIsOpen)
      );
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [elementRef, setIsOpen, stayOpen, elementToIgnore]);
}
