import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { PrimaryInvertedButton } from 'components/Button';

import styles from 'assets/css/ReceivePaymentView.module.scss';
import { copyToClipboard } from 'utils';

const GenericPaymentModal = ({
  onClose,
  paymentLink,
  paymentOutlet,
  currentCompany
}) => {
  const { t } = useTranslation();
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    let timeoutID;
    if (linkCopied) {
      timeoutID = setTimeout(() => setLinkCopied(false), 3000);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [linkCopied]);

  const copyURL = copyText => {
    copyToClipboard({
      text: copyText,
      useShare: true,
      onDone: () => setLinkCopied(true)
    });
  };

  const copyText = `We accept FavePay for contactless payments! Click ${paymentLink} to pay at ${paymentOutlet.name}, ${currentCompany.partner_name}`;

  return (
    <div className={styles.paymentModal}>
      <div className={styles.paymentModalContent}>
        <h3
          className={styles.subheading}
          style={{ textAlign: 'center', marginBottom: '1rem' }}
        >
          {t('Payment Link')}
        </h3>
        <div className={styles.paymentLinkBox}>
          <p className={styles.paymentLinkText}>{copyText}</p>
        </div>
        {linkCopied && (
          <p className={styles.copyConfirmationText}>{t('Text Copied.')}</p>
        )}
        <div className={styles.paymentModalButtons}>
          <PrimaryInvertedButton
            size="lg"
            justify="center"
            className={styles.paymentModalButton}
            onClick={onClose}
          >
            {t('Cancel')}
          </PrimaryInvertedButton>
          <Button
            type="primary"
            size="lg"
            justify="center"
            className={styles.paymentModalButton}
            onClick={() => copyURL(copyText)}
            disabled={linkCopied}
          >
            {navigator.share ? t('Share Link') : t('Copy Text')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GenericPaymentModal;
