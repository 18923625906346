import React, { useState, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';
import LoginLayout from 'layouts/LoginLayout';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Button from 'components/Button';
import styles from 'assets/css/LoginView.module.scss';

import chevronRightIcon from 'assets/images/ic-chevron-right-grey.svg';
import managerImage from 'assets/images/img-manager-small.png';
import staffImage from 'assets/images/img-staff-small.png';
import managerIcon from 'assets/images/manager-icon.png';
import staffIcon from 'assets/images/staff-icon.png';
import useCleverTapOld from 'hooks/useCleverTapOld';

import { useTranslation } from 'react-i18next';
import { MapleContext } from 'contexts/MapleContext';

const LoginView = props => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const { isMaple } = useContext(MapleContext);

  const SelectionCard = props => {
    return (
      <Card className={styles.selectionCard}>
        <div
          className={
            isMaple ? styles.selectionCardImageMaple : styles.selectionCardImage
          }
        >
          <img src={props.image} alt="card-img" />
        </div>
        <div className={styles.selectionCardContent}>{props.children}</div>
        <i className={styles.selectionCardIcon}>
          <Icon icon={chevronRightIcon} />
        </i>
      </Card>
    );
  };

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'login' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  return (
    <LoginLayout showStagingDropdown withHeader>
      <div className={styles.cardSelectionSection}>
        <Button
          type="ghost"
          onClick={() => {
            cleverTap.tapped.push({
              screen_name: 'login',
              tapped_on: 'btn_staff'
            });
            navigate('/ops-login' + props.location.search);
          }}
        >
          <SelectionCard image={isMaple ? staffIcon : staffImage}>
            <h5>{t('I’m a staff')}</h5>
            <p>{t('Monitor transactions and orders')}</p>
          </SelectionCard>
        </Button>

        <Button
          type="ghost"
          onClick={() => {
            cleverTap.tapped.push({
              screen_name: 'login',
              tapped_on: 'btn_manager'
            });
            navigate('/manager-login' + props.location.search);
          }}
        >
          <SelectionCard image={isMaple ? managerIcon : managerImage}>
            <h5>{t('I’m a manager')}</h5>
            <p>{t('Manage accounts and business')}</p>
          </SelectionCard>
        </Button>
      </div>
    </LoginLayout>
  );
};

export default LoginView;
