import { timezone } from 'utils/Dates';
import {
  startOfToday,
  endOfToday,
  endOfYesterday,
  startOfYesterday
} from 'date-fns';
import { formatISOToLocalTime } from 'helpers/dateUtility';
const today = timezone(endOfToday());

type GetRevenueParamsProps = {
  currentOutlet?: {
    id: number;
  };
  user: {
    city: {
      slug: string;
    };
  };
  startDate: Date;
  endDate: Date;
  filter: {
    date: string;
    type: string;
  };
  outlets: { id: number }[];
};

export default function getRevenueParams({
  currentOutlet,
  user,
  startDate,
  endDate,
  filter,
  outlets,
  ...restProps
}: GetRevenueParamsProps) {
  if (user.city) {
    let startDateTime =
      startDate && filter.date === 'custom'
        ? formatISOToLocalTime(startDate)
        : timezone(filter.date);
    let endDateTime =
      endDate && filter.date === 'custom'
        ? formatISOToLocalTime(endDate)
        : timezone(today);
    let outletIds = outlets && outlets.map(data => data.id).join(',');
    const params = {
      outlet_ids: currentOutlet ? currentOutlet.id : outletIds,
      schema_name: user.city.slug,
      start_datetime:
        startDateTime === timezone(today)
          ? timezone(startOfToday())
          : startDateTime,
      end_datetime:
        startDateTime === timezone(startOfYesterday())
          ? timezone(endOfYesterday())
          : endDateTime,
      ...restProps
    };

    return params;
  }
  return {};
}
