import { format, formatISO, parseISO } from 'date-fns';

// place all date functions that can be reused anywhere here.

const getDate = (date: Date | string): Date =>
  typeof date === 'string' ? parseISO(date) : date;

export const formatDate = (date: Date | string | undefined) => {
  if (!date) return date;

  return format(getDate(date), 'd MMM yyyy');
};

export const formatDateAndTime = (date: Date | string) => {
  return format(getDate(date), 'd MMM yyyy, h.mm aa');
};

export const formatDateAndTimeWithSlashes = (date: Date) => {
  return format(new Date(date), 'dd/MM/yyyy, h.mm aa');
};

export const formatDateAndTimeWithGMT = (date: string) => {
  return format(parseISO(date), 'd MMM yyyy, h.mm aa (O)');
};

export const formatISOToLocalTime = (date: Date | string) => {
  // converts to Date type if date is string
  const newDate = new Date(date);
  return formatISO(newDate);
};
