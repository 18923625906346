import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { hasKeys } from 'utils';
import MoreServices from 'insights/views/MoreServices';
import InsightsTitleCard from 'insights/components/InsightsTitleCard';
import LoadingSpinner from 'components/LoadingSpinner';

const lazyLoad = componentUrl =>
  React.lazy(() => import(`insights/views/${componentUrl}`));

const groupCards = ({ base, views = [], enabledIf }) =>
  views.map(view => ({ Component: lazyLoad(`${base}/${view}`), enabledIf }));

function Cards({ features, companyId }) {
  if (!hasKeys(features)) return <LoadingSpinner />;
  const {
    fave_payment: favePayment,
    voucher,
    unlock_more_data: unLockMoreData
  } = features;

  // logic table https://docs.google.com/spreadsheets/d/1b4_bigQ5NHbZEw6nXhR_rQYrnv4W9fyPP9U2Gt0MCkk/edit#gid=0
  const completeFPFD = favePayment && voucher && !unLockMoreData;
  const reportFPFD = favePayment && voucher && unLockMoreData;
  const reportFP = favePayment && !voucher && unLockMoreData;
  const reportFD = !favePayment && voucher && unLockMoreData;
  const completeFP = favePayment && !voucher && !unLockMoreData;
  const completeFD = !favePayment && voucher && !unLockMoreData;

  const cards = [
    {
      Component: lazyLoad('Insights/TotalSales'),
      enabledIf: true
    },
    {
      Component: lazyLoad('Insights/BreakdownOfSales'),
      enabledIf: completeFPFD || reportFPFD || reportFP || reportFD
    },
    ...groupCards({
      base: 'Insights',
      views: ['CustomerTrends', 'DailySales'],
      enabledIf: completeFPFD || completeFP || completeFD
    }),
    {
      Component: () => (
        <InsightsTitleCard
          id="favepay"
          title="FavePay: Overall Performance"
          subtitle="Here's what you should know about your Favepay performance and how to improve it"
          image={require('insights/assets/images/titlecard_favepay.jpg')}
          imageBig={require('insights/assets/images/titlecard_favepay@2x.jpg')}
        />
      ),
      enabledIf: favePayment
    },
    ...groupCards({
      base: 'FavePayView',
      views: ['FavePayOverview', 'OutletDetailedPerformance'],
      enabledIf: completeFPFD || reportFPFD || reportFP || completeFP
    }),
    {
      Component: () => (
        <InsightsTitleCard
          title="FavePay: Driving Customer Loyalty"
          subtitle="Find out your customer’s spending habits at your business"
          image={require('insights/assets/images/titlecard_favepay.jpg')}
          imageBig={require('insights/assets/images/titlecard_favepay@2x.jpg')}
        />
      ),
      enabledIf: completeFPFD || completeFP
    },
    {
      Component: lazyLoad('FavePayView/WeekdaySalesCustomers'),
      enabledIf: completeFPFD || completeFP
    },
    {
      Component: lazyLoad('FavePayView/CustomersByHourOfDay'),
      enabledIf: completeFPFD || completeFP
    },
    {
      Component: () => (
        <InsightsTitleCard
          id="favedeals"
          title="FaveDeals: Attracting New Customers"
          subtitle="Here's what you should know about your FaveDeal performance and how to improve it"
          image={require('insights/assets/images/titlecard_favedeal.jpg')}
          imageBig={require('insights/assets/images/titlecard_favedeal@2x.jpg')}
        />
      ),
      enabledIf: voucher
    },
    ...groupCards({
      base: 'FaveDealsView',
      views: ['FaveDealsOverview', 'DealsPerformanceBreakdown'],
      enabledIf: completeFPFD || reportFPFD || reportFD || completeFD
    }),
    {
      Component: () => (
        <InsightsTitleCard
          title="FaveDeals: Knowing Your Customers"
          subtitle="Find out your FaveDeals customers’ habits at your business"
          image={require('insights/assets/images/titlecard_favedeal.jpg')}
          imageBig={require('insights/assets/images/titlecard_favedeal@2x.jpg')}
        />
      ),
      enabledIf: voucher
    },
    {
      Component: lazyLoad('FaveDealsInsights/UnlockMore'),
      enabledIf: reportFPFD || reportFP || reportFD
    },
    {
      Component: lazyLoad('FaveDealsInsights/RedemptionHourly'),
      enabledIf: completeFPFD || completeFD
    },
    {
      Component: () => (
        <InsightsTitleCard
          id="customer-insights"
          title="Fave Insights on your Business"
          subtitle="Drive more customers to your business"
          image={require('insights/assets/images/titlecard_faveinsights.jpg')}
          imageBig={require('insights/assets/images/titlecard_faveinsights@2x.jpg')}
        />
      ),
      enabledIf: true
    },
    ...groupCards({
      base: 'FaveInsights',
      views: ['TotalSalesByAgeAndGender', 'AverageSalesByAgeAndGender'],
      enabledIf: completeFPFD || completeFP || completeFD
    }),
    ...groupCards({
      base: 'FaveInsights',
      views: [
        'NewAndCurrentCustomer',
        'CustomerBreakdownByWeekAndType',
        'TotalSalesPerformanceBenchmark',
        'NoOfCustomersAgainstSimilarMerchants'
      ],
      enabledIf: completeFPFD || completeFP
    }),
    {
      Component: () => (
        <InsightsTitleCard
          id="customer-engagement"
          title="Engage with your Customers"
          subtitle="Customer Feedback"
          image={require('insights/assets/images/titlecard_customerfeedback.jpg')}
          imageBig={require('insights/assets/images/titlecard_customerfeedback@2x.jpg')}
        />
      ),
      enabledIf: true
    },
    {
      Component: lazyLoad('CustomerRelations/ServiceRatingsReviews'),
      enabledIf: completeFPFD || completeFP || completeFD
    },
    ...groupCards({
      base: 'CustomerRelations',
      views: ['CustomerFeedbackRatings', 'LatestReviews'],
      enabledIf: true
    }),
    {
      Component: () => (
        <MoreServices id="more-services" companyId={companyId} />
      ),
      enabledIf: true
    }
  ].filter(d => d.enabledIf);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      {cards.map((card, index) => (
        <card.Component key={index} />
      ))}
    </Suspense>
  );
}

Cards.propTypes = {
  features: PropTypes.object,
  companyId: PropTypes.string
};

export default Cards;
