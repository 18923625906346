import FaveBizAPI from 'integrations/FaveBizAPI';

type FaveFeedListParams = {
  company_id: number;
  status: 'active' | 'inactive' | 'scheduled';
  page?: number;
  limit?: number;
};

type Image = {
  id: number;
  url: string;
};

type Share = {
  url: string;
  description: string;
};

type PostDetails = {
  id: number;
  status: string;
  start_at: Date;
};

export type FaveFeed = {
  id: number;
  created_by: string;
  created_on: Date;
  updated_by: string;
  updated_on: Date;
  deleted_on: Date;
  description: string;
  scheduled_date: Date;
  expired: boolean;
  type: 'announcement' | 'deal' | 'e_card';
  shared_count: number;
  hide_count: number;
  viewed_count: number;
  faved_count: number;
  flagged: boolean;
  flagged_on: Date;
  status: 'active' | 'inactive' | 'scheduled' | 'review';
  share: Share;
  images: Image[];
  outlets: string[];
  deal: {
    id: number;
    name: string;
    images: string[];
    start_date: Date;
    end_date: Date;
    purchase_count: number;
    post_id: number;
    post_status: string;
    post_start_date: Date;
    outlets: string[];
  };
  e_card: {
    id: number;
    name: string;
    company_name: string;
    company_id: number;
    company_logo: string;
    start_date: Date;
    end_date: Date;
    background_color: string;
    sold_count: number;
    total_value: string;
    payable_amount: string;
    bonus_credit_amount: string;
    validity: string;
    discount_percentage: number;
    theme: string;
    post_details?: PostDetails;
    share: Share;
    outlets: string[];
  };
};

export type FaveFeedResponse = {
  feed: FaveFeed[];
  total_count: number;
};

export default (params: FaveFeedListParams) =>
  FaveBizAPI.get(`/v1/feed`, params);
