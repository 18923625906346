import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import Card from './Card';

import styles from 'assets/css/ImageCard.module.scss';

export type StatusProps = 'active' | 'inactive';

type StatusContentProps = {
  bgColor: string;
  opacity: number;
};

type StatusMapProps = Record<StatusProps, StatusContentProps>;

type ImageCardProps = {
  headerImage: string;
  className: string;
  status: StatusProps;
};

const ImageCard: React.FC<ImageCardProps> = props => {
  const { headerImage, className, status = 'inactive' } = props;

  const statusMap: StatusMapProps = {
    active: {
      bgColor: 'apple-green',
      opacity: 1
    },
    inactive: {
      bgColor: 'nero-black',
      opacity: 0.4
    }
  };

  return (
    <Card
      className={classNames(className, styles.cardContainer)}
      style={
        {
          '--bg': `url(${headerImage})`,
          '--status-color': `var(--${statusMap[status].bgColor})`,
          '--opacity-value': `var(--${statusMap[status].opacity})`
        } as CSSProperties
      }
    >
      {status && <span className={styles.status}>{status}</span>}
      {props.children}
    </Card>
  );
};

export default ImageCard;
