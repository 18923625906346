import qs from 'qs';

export const jsonToQueryString = json =>
  qs.stringify(json, {
    arrayFormat: 'brackets',
    addQueryPrefix: true,
    skipNulls: true
  });

export const hasData = data => data && !data.detail;
