import React from 'react';
import classNames from 'classnames';
import styles from 'assets/css/FormikError.module.scss';
import { FormikErrors, FormikTouched } from 'formik';

interface FormikState<Values> {
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
}

export type FormikErrorProps = {
  name: string;
} & FormikState<{
  [x: string]: string;
}>;

const FormikError: React.FC<FormikErrorProps> = ({
  name = '',
  errors = {},
  touched = {}
}) => {
  const hasError = errors[name] && touched[name];
  return (
    <div className={classNames(styles.error, hasError && styles.hasError)}>
      {hasError ? errors[name] : ''}
    </div>
  );
};

export default FormikError;
