import React, { useState, useEffect, useContext } from 'react';

import Cookie from 'helpers/Cookie';
import verifyUser from 'queries/verifyUser';
import { CleverTapContextOld } from 'contexts/CleverTapContextOld';

const InsightsAuthContext = React.createContext();

const InsightsAuthContextProvider = props => {
  const [settings, setSettings] = useState({});
  const [user, setUser] = useState();
  const { cleverTapProfile } = useContext(CleverTapContextOld);

  useEffect(() => {
    if (user) cleverTapProfile.push(user);
  }, [user, cleverTapProfile]);

  useEffect(() => {
    const accessToken = Cookie.get('accessToken');
    if (!accessToken) return;
    verifyUser('/v1/company_managers/current_manager', accessToken).then(user =>
      setUser(user)
    );
  }, []);

  return (
    <InsightsAuthContext.Provider value={{ user, settings, setSettings }}>
      {props.children}
    </InsightsAuthContext.Provider>
  );
};

export { InsightsAuthContext, InsightsAuthContextProvider };
