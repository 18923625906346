import React, { FC, PropsWithChildren } from 'react';
import styles from 'assets/css/ReportingTable.module.scss';
import Table from './Table';
import classNames from 'classnames';

type ReportingTableProps = PropsWithChildren<{
  className?: string;
}>;

type TextAlignment = {
  textCenter?: boolean;
  textRight?: boolean;
  textLeft?: boolean;
};

type RowProps = PropsWithChildren<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > & {
    groupRow?: boolean;
  }
>;

const ReportingTable: FC<PropsWithChildren<ReportingTableProps>> = ({
  className,
  children
}) => <Table className={classNames(className, styles.table)}>{children}</Table>;

export const TableHead: FC<PropsWithChildren<{}>> = ({ children }) => (
  <thead className={styles.head}>{children}</thead>
);
export const TableBody: FC<PropsWithChildren<{}>> = ({ children }) => (
  <tbody className={styles.body}>{children}</tbody>
);
export const TableRow: FC<RowProps> = ({
  groupRow,
  children,
  className,
  ...props
}) => (
  <tr
    {...props}
    className={classNames(styles.row, className, {
      [styles.groupRow]: groupRow
    })}
  >
    {children}
  </tr>
);

export const TableHeadCell: FC<PropsWithChildren<
  React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >
> &
  TextAlignment> = ({
  textLeft = true,
  textCenter,
  textRight,
  className,
  children,
  ...props
}) => (
  <th
    {...props}
    className={classNames(styles.headerCell, className, {
      [styles.textLeft]: textLeft,
      [styles.textCenter]: textCenter,
      [styles.textRight]: textRight
    })}
  >
    {children}
  </th>
);

export const TableBodyCell: FC<React.DetailedHTMLProps<
  React.TdHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
> &
  TextAlignment> = ({
  textLeft = true,
  textCenter,
  textRight,
  className,
  ...props
}) => (
  <td
    {...props}
    className={classNames(styles.bodyCell, className, {
      [styles.textLeft]: textLeft,
      [styles.textCenter]: textCenter,
      [styles.textRight]: textRight
    })}
  >
    {props.children}
  </td>
);
export default ReportingTable;
