// useful for InlineLink's to prop
export const navigateUrlIfBrowser = (
  isWebview: boolean = false,
  url: string
) => {
  if (isWebview) {
    return '';
  } else {
    return url;
  }
};
