import React, { useState, useEffect, useContext } from 'react';
import Cookie from 'helpers/Cookie';
import i18n from 'i18n';
import { QueryStringContext } from './QueryStringContext';
import { LANGUAGE_KEYS } from 'helpers/TypeConstants';

export const LangContext = React.createContext();

export const LangProvider = props => {
  const { lng } = useContext(QueryStringContext);
  const [currentLang, setCurrentLang] = useState();

  const changeLang = lang => {
    setCurrentLang(lang);
    Cookie.set('currentLang', lang);
    i18n.changeLanguage(lang);
  };

  const useVerticalButtonLayout =
    currentLang === LANGUAGE_KEYS.MY || currentLang === LANGUAGE_KEYS.ID;

  const implementation = {
    useVerticalButtonLayout,
    currentLang,
    changeLang
  };

  useEffect(() => {
    let lang = lng || Cookie.get('currentLang', 'en-US');
    setCurrentLang(lang);
    Cookie.set('currentLang', lang);
    setTimeout(() => i18n.changeLanguage(lang), 0);
  }, [lng]);

  return (
    <LangContext.Provider value={implementation}>
      {props.children}
    </LangContext.Provider>
  );
};
