import React from 'react';
import classnames from 'classnames';

import { isImagePath } from 'utils';
import styles from 'assets/css/Icon.module.scss';

type IconProps = {
  icon: string;
  gradient?: boolean;
  color?: string;
  size?: string;
  className?: string;
  onClick?: () => void;
};

const Icon: React.FC<IconProps> = ({
  icon,
  gradient = false,
  color = 'black',
  size = '1rem',
  className,
  onClick
}) => (
  <span
    data-icon
    className={classnames(styles.icon, className)}
    onClick={onClick}
    style={
      {
        '--icon': isImagePath(icon) ? `url(${icon})` : `var(--icon-${icon})`,
        '--icon-color': `var(--${color})`,
        '--icon-width': size
      } as React.CSSProperties
    }
  >
    {gradient && <img src={icon} alt="icon" />}
  </span>
);

export default Icon;
