import React from 'react';
import classNames from 'classnames';

import { TappedButton } from 'components/Button';
import Card from './Card';
import Styles from 'assets/css/AnnouncementCard.module.scss';
import { IGetAnnouncementsResponse } from 'queries/getOutletAnnouncement';

type IAnnouncementCard = {
  announcementTitle: string;
  className: string;
  startDate: string;
  endDate: string;
  outletCount: number;
  renderBottomButton: Array<{
    link?: string;
    onClick?: () => void;
    title: string;
    filledButton: boolean;
    event?: {
      screen_name: string;
      tapped_on: string;
    };
  }>;
  image_url: string;
  outlets: IGetAnnouncementsResponse['outlets'];
};

const AnnouncementCard: React.FC<IAnnouncementCard> = props => {
  const {
    announcementTitle,
    className,
    startDate,
    endDate,
    outletCount,
    outlets,
    renderBottomButton,
    image_url
  } = props;
  return (
    <Card
      className={classNames(Styles.announcement, className)}
      style={
        {
          '--bg': `url(${image_url})`
        } as React.CSSProperties
      }
    >
      <div className={Styles.cardContainer}>
        <div className={Styles.cardContent}>
          <h3 className={Styles.cardTitle}>{announcementTitle}</h3>
          <section>
            <div className={Styles.cardDate}>
              <span>{`${startDate} - ${endDate}`}</span>
            </div>
            <div className={Styles.cardOutlet}>
              {outletCount > 1
                ? 'Available at ' + outlets.length + ' outlets'
                : 'Available at ' + outlets[0].name}
            </div>
          </section>
        </div>
      </div>
      <div className={Styles.bottomButtonBar}>
        {renderBottomButton &&
          renderBottomButton.map(
            ({ link, title, onClick = () => {}, filledButton, event }) => (
              <div className={Styles.bottomButtonDiv} key={title}>
                {link ? (
                  <TappedButton
                    type={filledButton ? 'primary' : 'default'}
                    href={link}
                    isRounded
                    children={title}
                    event={event}
                  />
                ) : (
                  <TappedButton
                    type={filledButton ? 'primary' : 'default'}
                    onClick={onClick}
                    isRounded
                    children={title}
                    event={event}
                  />
                )}
              </div>
            )
          )}
      </div>
    </Card>
  );
};

export default AnnouncementCard;
