import React from 'react';

import AlertToUseDesktop from 'insights/components/AlertToUseDesktop';
import ScrollProgressBar from 'insights/components/ScrollProgressBar';
import Header from 'insights/components/Header';

import styles from 'insights/assets/css/StickyHeader.module.scss';

export default function StickyHeader(props) {
  return (
    <section className={styles.layout}>
      <AlertToUseDesktop {...props} />
      <ScrollProgressBar />
      <Header {...props} />
    </section>
  );
}
