import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';

import useStateMachine from 'hooks/useStateMachine';
import Modal from 'components/Modal';
import ConfirmModal from 'components/ConfirmModal';

const ModalContext = React.createContext();

const EVENT = {
  CLOSE: 'close',
  INIT: 'init',
  OPEN: 'open',
  DATA: 'data',
  ANIMATIONEND: 'animationEnd'
};

const STATE = {
  IDLE: 'idle',
  READY: 'ready',
  OPEN: 'open'
};

const schema = Yup.object().shape({
  state: Yup.string(),
  openOnReady: Yup.boolean(),
  onClose: Yup.object(),
  openOnlyIf: Yup.object(),
  closeOnBlur: Yup.boolean(),
  Component: Yup.object(),
  componentProps: Yup.object(),
  data: Yup.mixed(),
  animationEnd: Yup.boolean().nullable(),
  hasCloseButton: Yup.boolean()
});

const initialState = {
  state: STATE.IDLE,
  openOnReady: false,
  closeOnBlur: false,
  openCallback: () => {},
  onClose: () => {},
  openOnlyIf: () => true,
  Component: () => <></>,
  animationEnd: null,
  hasCloseButton: true
};

const initReducer = (current, event) => {
  event.openOnlyIf = event.openOnlyIf || initialState.openOnlyIf;
  event.onClose = event.onClose || initialState.onClose;
  event.animationEnd = event.animationEnd || initialState.animationEnd;
  schema.validateSync(initialState);
  schema.validateSync(event);

  return isEqual(current.Component, event.Component) &&
    isEqual(current.componentProps, event.componentProps) &&
    isEqual(current.openOnlyIf, event.openOnlyIf) &&
    isEqual(current.onClose, event.onClose) &&
    isEqual(current.animationEnd, event.animationEnd)
    ? current
    : {
        ...initialState,
        ...event,
        state:
          event.openOnReady && event.openOnlyIf() ? STATE.OPEN : STATE.READY
      };
};

const stateMachine = {
  [STATE.IDLE]: {
    [EVENT.INIT]: initReducer,
    [EVENT.ANIMATIONEND]: (current, event) => ({
      ...current,
      animationEnd: null
    })
  },
  [STATE.READY]: {
    [EVENT.INIT]: initReducer,
    [EVENT.OPEN]: current =>
      current.openOnlyIf() && { ...current, state: STATE.OPEN },
    [EVENT.ANIMATIONEND]: (current, event) => ({
      ...current,
      animationEnd: false
    })
  },
  [STATE.OPEN]: {
    [EVENT.CLOSE]: () => initialState,
    [EVENT.DATA]: (current, event) => ({ ...current, data: event.data }),
    [EVENT.ANIMATIONEND]: (current, event) => ({
      ...current,
      animationEnd: event
    })
  }
};

const ModalProvider = ({ children }) => {
  const [current, send] = useStateMachine(stateMachine, initialState);
  const [isTransitionEnd, setIsTransitionEnd] = useState(null);

  const { Component, componentProps: props } = current;

  const onClose = useCallback(
    (data = current.data) => {
      current.onClose(data);
      send(EVENT.CLOSE);
    },
    [current, send]
  );

  useEffect(() => {
    if (current.state === STATE.OPEN && isTransitionEnd) current.openCallback();
    else setIsTransitionEnd(false);
  }, [current, isTransitionEnd]);

  const onChange = useCallback(data => send(EVENT.DATA, { data }), [send]);
  const init = useCallback(props => send(EVENT.INIT, props), [send]);
  const open = useCallback(() => send(EVENT.OPEN), [send]);

  const showConfirmModal = useCallback(
    (
      content,
      onConfirm,
      onCancel,
      yesText = 'Yes',
      noText = 'No',
      title = 'Are you sure?'
    ) => {
      init({
        Component: ConfirmModal,
        componentProps: {
          title,
          content,
          onConfirm,
          onCancel,
          yesText,
          noText
        },
        openOnReady: true
      });
    },
    [init]
  );

  return (
    <ModalContext.Provider
      value={{ init, open, showConfirmModal, close: onClose }}
    >
      <Modal
        isOpen={current.state === STATE.OPEN}
        onClose={onClose}
        hasCloseButton={current.hasCloseButton}
        closeOnBlur={current.closeOnBlur}
        onTransitionEnd={() => setIsTransitionEnd(true)}
        className={props?.className}
      >
        <Component {...props} onClose={onClose} onChange={onChange} />
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
