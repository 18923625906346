import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { NotificationContext } from 'contexts/NotificationContext';
import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';
import useServicesUrls from 'hooks/useServicesUrls';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import Card from 'components/Card';
import Grid from 'components/Grid';
import { TappedButton, PrimaryInvertedButton } from 'components/Button';
import { activateFavepay } from 'commands/activateFavepay';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { hasKeys } from 'utils';
import { WebviewContext } from 'contexts/WebviewContext';
import WebviewHeading from 'components/WebviewHeading';

import styles from 'assets/css/MoreServicesView.module.scss';
import LockedIcon from 'assets/images/locked-services-icon.svg';
import { LangContext } from 'contexts/LangContext';
import { OutletType } from 'types/SettingsAPIResponseType';

type ServiceCardsType = {
  title: () => void | string;
  description: () => void | string;
  imageSource: string;
  learnMoreAt: {
    link: string;
    event: {
      tapped_on: string;
    };
  };
  ctaButton?: {
    title: string;
    event: {
      tapped_on: string;
    };
    onClick?: () => void;
    href?: string;
    disabled?: boolean;
  };
  showIf: boolean;
  requireFavePay?: boolean;
  requireFavePayOrFaveDeals?: boolean;
};

type UserCityType = 'jakarta' | 'singapore' | 'kuala-lumpur';

export default function MoreServicesView() {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const { useVerticalButtonLayout } = useContext(LangContext);

  const {
    currentCompany: company = {},
    outlets = [],
    currentOutlet
  } = useContext(OutletContext);
  const { isValidUser, updateUser, user } = useContext(AuthContext);
  const [selfOnboarded, setSelfOnboarded] = useState(false);
  const servicesUrls = useServicesUrls();

  useEffect(() => {
    currentOutlet && setSelfOnboarded(currentOutlet.self_onboard);
  }, [currentOutlet]);

  const merchantHasNoCashback = !outlets.some(
    (data: OutletType) => data.has_setup_cashback
  );
  const merchantHasNoFavepay = !outlets.some(
    (data: OutletType) => data.has_fave_payment
  );
  const merchantHasNoDeals = !(company.deals_interest || company.has_deals);
  const merchantHasNotSubmittedECards = !company.has_e_cards;

  const userCity: UserCityType = user?.city?.slug;
  const notIndonesia = isValidUser && user.city.slug !== 'jakarta';
  const merchantHasNoBankAccount = !company.has_valid_account;
  const { isWebview } = useContext(WebviewContext);

  const favePayNotApproved = !company.enabled;

  const campaignLinks = {
    jakarta: servicesUrls['campaign-id'],
    singapore: servicesUrls['singapore-malaysian-campaign'],
    'kuala-lumpur': servicesUrls['singapore-malaysian-campaign']
  };

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'view_more_info' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  const useServiceCards = () => {
    const { t } = useTranslation();
    const { setNotification } = useContext(NotificationContext);

    const serviceCards: ServiceCardsType[] = [
      {
        title: t('FavePay'),
        description: t(
          'Accept all forms of cashless payment easily, quickly at low cost'
        ),
        imageSource: require('assets/images/services/favebiz-payment.jpg'),
        learnMoreAt: {
          link: servicesUrls.payments,
          event: {
            tapped_on: 'btn_learnmore_favepay'
          }
        },
        ctaButton: {
          title: t('Activate'),
          event: { tapped_on: 'btn_activate_favepay' },
          onClick: () =>
            activateFavepay({ outlet_id: currentOutlet.id })
              .then(user => {
                updateUser(user);
                navigate('/favepay/setup');
              })
              .catch(error =>
                setNotification({
                  message: error.value
                    ? error.value.response.error
                    : error.toString(),
                  type: 'error'
                })
              )
        },
        showIf: selfOnboarded && merchantHasNoFavepay
      },
      {
        title: t('FaveDeals'),
        description: t(
          'Simple, low-cost, and effective customer acquisition solution'
        ),
        imageSource: require('assets/images/services/favebiz-deal.jpg'),
        learnMoreAt: {
          link: servicesUrls.favedeals,
          event: { tapped_on: 'btn_learnmore_favedeals' }
        },
        ctaButton: {
          title: t('Set up'),
          event: { tapped_on: 'btn_deals_request' },
          href: '/deals/enable'
        },
        showIf: merchantHasNoDeals
      },
      {
        title: t('Cashback'),
        description: t(
          'Reward customers with irresistible cashback rates and turn more walk-ins into regulars'
        ),
        imageSource: require('assets/images/services/favebiz-cashback.jpg'),
        learnMoreAt: {
          link: servicesUrls.cashback,
          event: { tapped_on: 'btn_learnmore_cashback' }
        },
        ctaButton: {
          title: t('Activate'),
          event: { tapped_on: 'btn_activate_cashback' },
          href: '/cashback/setup',
          disabled: merchantHasNoFavepay
        },
        requireFavePay: favePayNotApproved,
        showIf: selfOnboarded && merchantHasNoCashback
      },
      {
        title: t('eCards'),
        description: t(
          'Sell more, get paid upfront. It’s like gift cards, but better.'
        ),
        imageSource: require('assets/images/services/img-e-cards.jpg'),
        learnMoreAt: {
          link: servicesUrls.ecards,
          event: { tapped_on: 'btn_learnmore_ecard' }
        },
        ctaButton: {
          title: t('Create'),
          event: { tapped_on: 'btn_create_new_ecard' },
          href: '/ecards/create',
          disabled: merchantHasNoFavepay || merchantHasNoBankAccount
        },
        requireFavePay: favePayNotApproved,
        showIf: notIndonesia && merchantHasNotSubmittedECards
      },
      {
        title: t('Financing'),
        description: t('Get fast and easy access to loans'),
        imageSource: require('assets/images/services/favebiz-financing.jpg'),
        learnMoreAt: {
          link: servicesUrls.financing,
          event: { tapped_on: 'btn_learnmore_financing' }
        },
        showIf: false
      },
      {
        title: t('Campaign'),
        description: t(
          'Feature your brand with a special promotion in Fave campaigns'
        ),
        imageSource: require('assets/images/services/favebiz-campaign.png'),
        learnMoreAt: {
          link: campaignLinks[userCity],
          event: { tapped_on: 'btn_learnmore_campaign' }
        },
        requireFavePayOrFaveDeals: favePayNotApproved && merchantHasNoDeals,
        showIf: true
      },
      {
        title: t('Advertisement'),
        description: t('Acquire new customers and grow your sales'),
        imageSource: require('assets/images/services/favebiz-marketing@3x.jpg'),
        learnMoreAt: {
          link: servicesUrls.marketing,
          event: { tapped_on: 'btn_learnmore_advertisement' }
        },
        requireFavePayOrFaveDeals: favePayNotApproved && merchantHasNoDeals,
        showIf: notIndonesia
      }
    ];

    return serviceCards.filter(item => item.showIf);
  };

  const ServiceCard = (props: ServiceCardsType) => {
    const { t } = useTranslation();

    const LearnMoreButton = () => (
      <PrimaryInvertedButton
        size="md"
        href={props.learnMoreAt.link}
        isRedirect={false}
        className={classNames(styles.button, styles.exploreButton)}
        children={t('Learn more')}
        events={{
          screen_name: 'view_more_info',
          tapped_on: props.learnMoreAt.event.tapped_on
        }}
      />
    );

    const CallToActionButton = () => (
      <TappedButton
        type="primary"
        isRedirect={false}
        className={styles.ctaButton}
        {...props.ctaButton}
        event={{ screen_name: 'view_more_info', ...props.ctaButton?.event }}
        children={props.ctaButton?.title}
      />
    );

    const Image = (
      props: JSX.IntrinsicAttributes &
        React.ClassAttributes<HTMLDivElement> &
        React.HTMLAttributes<HTMLDivElement>
    ) => <div className={styles.imageLayout} {...props} />;
    const Body = (
      props: JSX.IntrinsicAttributes &
        React.ClassAttributes<HTMLDivElement> &
        React.HTMLAttributes<HTMLDivElement>
    ) => <div className={styles.body} {...props} />;
    const ActivateNotice = ({ includeFaveDeals = false }) => (
      <div className={styles.notice}>
        <div>
          <div></div>
          <div>
            <img
              className={styles.lockedIcon}
              src={LockedIcon}
              alt="locked services"
            />
            <p className={styles.unlockParagraph}>Unlock after</p>
            <p className={styles.unlockParagraph}>activating FavePay</p>
            {includeFaveDeals && (
              <p className={styles.unlockParagraph}>or FaveDeals</p>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <Card className={styles.cardLayout}>
        {props?.requireFavePay && <ActivateNotice />}
        {props?.requireFavePayOrFaveDeals && (
          <ActivateNotice includeFaveDeals={true} />
        )}
        <Image>
          <img src={props?.imageSource} alt={`${props.title}`} />
        </Image>
        <Body>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.description}>{props?.description}</div>
          <div
            className={
              useVerticalButtonLayout
                ? styles.buttonLayoutVertical
                : styles.buttonLayout
            }
          >
            {props.learnMoreAt && <LearnMoreButton />}
            {hasKeys(props.ctaButton) && <CallToActionButton />}
          </div>
        </Body>
      </Card>
    );
  };

  const cards = useServiceCards();
  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading
          title={t('More Services')}
          subtitle={t(
            'Browse through all FaveBiz services and what they can benefit your business'
          )}
          hasCloseWindowIcon={true}
          onBack={() => {}}
        />
      ) : (
        <Heading
          title={t('More Services')}
          subtitle={t(
            'Browse through all FaveBiz services and what they can benefit your business'
          )}
        />
      )}

      <Grid>
        {cards.map((card: ServiceCardsType) => (
          <ServiceCard key={`${card.title}`} {...card} />
        ))}
      </Grid>
    </AppLayout>
  );
}
