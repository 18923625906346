import { useQuery } from 'react-query';

import FaveBizAPI from 'integrations/FaveBizAPI';
import { ApiError } from 'types/ApiError';
import { defaultClientConfig } from 'App';

type NeedHelpResponse = {
  whatsapp: string;
  operating_hours: string;
  partner_manager_email: string;
};

type NeedHelpPayload = {
  country_code: string;
};

const getNeedHelp = (country_code: string) =>
  FaveBizAPI.get(`/v1/need_help?country_code=${country_code}`);

export const useNeedHelp = ({ country_code }: NeedHelpPayload) =>
  useQuery<NeedHelpResponse, ApiError>(
    ['needHelp'],
    () => getNeedHelp(country_code),
    {
      ...defaultClientConfig,
      enabled: !!country_code
    }
  );
