import React, { useRef, useState, useEffect } from 'react';
import Measure from 'react-measure';
import classNames from 'classnames';
import {
  DateRangePicker as AirbnbDateRangePicker,
  FocusedInputShape,
  OrientationShape
} from 'react-dates';
// @ts-ignore
import { START_DATE, END_DATE } from 'react-dates/constants';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import useNoScrollBackground from 'hooks/useNoScrollBackground';
import { RESPONSIVE_BREAKPOINTS } from 'helpers/TypeConstants';
import 'assets/css/DatePicker.scss'; /* why no `module`? see `.scss` comments */
import 'assets/css/DateRangePicker.scss'; /* why no `module`? see `.scss` comments */
import styles from 'assets/css/DateRangePicker.module.scss';

type DateRangePickerProps = {
  onChange: (props: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    isValid: moment.Moment | null;
    isOpen?: boolean;
  }) => void;
  startDate: moment.Moment;
  endDate: moment.Moment;
  openDirection: 'up' | 'down';
  isOutsideRange: (day: moment.Moment) => boolean;
  dateFormat: string;
  keepOpen: boolean;
  onFocusChange: (input: FocusedInputShape | null) => void;
  onClickCloseButton: () => void;
};

function DateRangePicker({
  onChange = () => {},
  startDate,
  endDate,
  openDirection = 'down',
  isOutsideRange = () => false,
  dateFormat = 'DD/MM/YYYY',
  keepOpen = false,
  onFocusChange = () => {},
  onClickCloseButton = () => {}
}: DateRangePickerProps) {
  const [boundsMeasured, setBoundsMeasured] = useState(false);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [isTouched, setIsTouched] = useState(true);
  const currentStartDate = useRef<moment.Moment>();
  const currentEndDate = useRef<moment.Moment>();
  const shouldKeepOpen = useRef<boolean>();
  useNoScrollBackground(!!focusedInput);

  useEffect(() => {
    // update dates to local ref and callback
    if (focusedInput || !startDate || !endDate) return;

    const isSameStartDate = startDate.isSame(currentStartDate.current, 'day');
    const isSameEndDate = endDate.isSame(currentEndDate.current, 'day');
    if (isSameStartDate && isSameEndDate) return;

    currentStartDate.current = startDate;
    currentEndDate.current = endDate;

    // on blur, ensure dates are valid
    onChange({ startDate, endDate, isValid: startDate && endDate });
  }, [focusedInput, startDate, endDate, onChange]);

  useEffect(() => {
    // if keepOpen, invalid dates and bounds measured (to avoid wrong orientation flicker), show picker
    if (focusedInput || (startDate && endDate)) return;
    if (keepOpen && boundsMeasured)
      setFocusedInput(startDate ? END_DATE : START_DATE);
  }, [keepOpen, focusedInput, startDate, endDate, boundsMeasured]);

  useEffect(() => {
    // if focusedInput changes, update shouldKeepOpen
    if (focusedInput) shouldKeepOpen.current = keepOpen;
  }, [focusedInput, keepOpen]);

  useEffect(() => {
    // if keepOpen is false, allow parent to close date picker
    if (!keepOpen) setFocusedInput(null);
  }, [keepOpen]);

  const [orientation, setOrientation] = useState<OrientationShape>(
    'horizontal'
  );
  const isMobile = orientation === 'vertical';

  return (
    <Measure
      bounds
      onResize={({ bounds }) => {
        if (!bounds?.right) return;
        setBoundsMeasured(true);
        setOrientation(
          bounds.right < RESPONSIVE_BREAKPOINTS.md ? 'vertical' : 'horizontal'
        );
      }}
    >
      {({ measureRef }) => (
        <div
          ref={measureRef}
          className={classNames(isTouched && styles.touched)}
        >
          <AirbnbDateRangePicker
            minimumNights={0} /* allow same start/end date */
            startDateId="startDate"
            endDateId="endDate"
            startDate={startDate as any}
            endDate={endDate as any}
            displayFormat={dateFormat}
            openDirection={openDirection}
            onDatesChange={({ startDate, endDate }) => {
              setIsTouched(true);
              const isValidDates = startDate && endDate;
              shouldKeepOpen.current = !isValidDates;
              onChange({
                startDate,
                endDate,
                isValid: isValidDates,
                isOpen: !!focusedInput
              });
            }}
            withPortal
            orientation={orientation}
            isOutsideRange={isOutsideRange} /* enable past days selection */
            /* handles day picker toggle / render */
            focusedInput={focusedInput}
            onFocusChange={focusedInput => {
              onFocusChange(focusedInput); // notify parent
              const isClosing = !focusedInput;
              if (shouldKeepOpen.current && isClosing) return;

              setFocusedInput(focusedInput);
              // https://github.com/airbnb/react-dates/issues/1108#issuecomment-451768482
              document.activeElement instanceof HTMLElement &&
                document.activeElement.blur(); // Do not prompt the keyboard on mobile.
            }}
            {...(isMobile
              ? {
                  calendarInfoPosition: 'top',
                  renderCalendarInfo: () => (
                    <img
                      className={styles.closeButton}
                      src={require('insights/assets/images/alert-close.svg')}
                      alt="Close"
                      onClick={onClickCloseButton}
                    />
                  )
                }
              : {})}
          />
        </div>
      )}
    </Measure>
  );
}

export default DateRangePicker;
