import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import { getAlipayPaymentDetails } from 'queries/getAlipayPayment';
import { getOutlet } from 'queries/getOutlet';
import { alipayRefund } from 'commands/alipayTransaction';
import { NotificationContext } from 'contexts/NotificationContext';
import { OpsOutletContext } from 'contexts/OpsOutletContext';
import { ModalContext } from 'contexts/ModalContext';
import useCleverTapOld from 'hooks/useCleverTapOld';

import TransactionCard from 'components/TransactionCard';
import OpsLayout from 'layouts/OpsLayout';
import Heading from 'components/Heading';
import Button, { CancelButton } from 'components/Button';
import TextInput from 'components/TextInput';

import styles from 'assets/css/AlipayDetailView.module.scss';
import { WebviewContext } from 'contexts/WebviewContext';

const AlipayDetailView = props => {
  const { t } = useTranslation();
  const { init } = useContext(ModalContext);
  const { setNotification } = useContext(NotificationContext);
  const { currentOutlet } = useContext(OpsOutletContext);
  const cleverTap = useCleverTapOld();
  const { isWebview } = useContext(WebviewContext);

  const [hasSentCleverTapEvent, setHasSentCleverTapEvent] = useState(false);
  useEffect(() => {
    if (hasSentCleverTapEvent) return;
    cleverTap.screenDisplayed.push({
      screen_name: 'transactiondetails_alipay'
    });
    setHasSentCleverTapEvent(true);
  }, [hasSentCleverTapEvent, cleverTap.screenDisplayed]);

  const [alipayResponse, setAlipayResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [outletResponse, setOutletResponse] = useState(null);

  const {
    created_at_json,
    cancelled,
    cancelled_at_json,
    receipt_id,
    bill_amount,
    cancellable,
    cancelled_by
  } = !isEmpty(alipayResponse) && alipayResponse.external_payment;

  const alipayVerifyPaymentAPI = useCallback(() => {
    getAlipayPaymentDetails(props.id)
      .then(response => setAlipayResponse(response))
      .catch(err => {
        const errorStatement = err && err.value && err.value.response.error;
        setNotification({
          message: t('{{errorStatement}}. Please try again', {
            errorStatement
          }),
          type: 'error'
        });
      });
  }, [props.id, setNotification, t]);

  useEffect(() => {
    if (isEmpty(currentOutlet)) return;
    alipayVerifyPaymentAPI();
  }, [alipayVerifyPaymentAPI, currentOutlet]);

  useEffect(() => {
    if (isEmpty(currentOutlet)) return;
    getOutlet(currentOutlet.id)
      .then(data => setOutletResponse(data))
      .catch(error => ({}));
  }, [currentOutlet]);

  const checkForCancellationCode = () => {
    if (outletResponse && outletResponse.require_cancellation_code) {
      onOpenCancellationCodeModal();
    } else {
      setIsLoading(true);
      handleCancel({ onClose: null, finishSubmit: () => setIsLoading(false) });
    }
  };

  const handleCancel = ({ onClose, finishSubmit, cancellationCode }) => {
    alipayRefund({
      external_payment_id: props.id,
      cancellation_code: cancellationCode
    })
      .then(res => {
        if (res === 200) {
          alipayVerifyPaymentAPI();
          onClose && onClose();
          cleverTap.tapped.push({
            screen_name: 'transactiondetails_alipay',
            tapped_on: 'btn_cancel_transaction'
          });
        }
      })
      .catch(error => {
        const errorStatement =
          error &&
          error.value &&
          error.value.response &&
          error.value.response.error;

        setNotification({
          message: t('{{errorStatement}} Please try again', {
            errorStatement
          }),
          type: 'error'
        });
      })
      .finally(() => {
        finishSubmit && finishSubmit();
      });
  };

  const onOpenCancellationCodeModal = () => {
    init({
      Component: CancellationCodeModal,
      openOnReady: true,
      hasCloseButton: false
    });
  };

  const CancellationCodeModal = ({ onClose }) => {
    const initialValues = { cancellation_code: '' };

    const onSubmit = (value, formikBag) => {
      handleCancel({
        onClose,
        finishSubmit: () => formikBag.setSubmitting(false),
        cancellationCode: value.cancellation_code
      });
    };

    const validationSchema = Yup.object().shape({
      cancellation_code: Yup.string().required('Required')
    });

    return (
      <div className={classNames(styles.cancellationCodeModal)}>
        <h3>{t('Cancel transaction')}</h3>
        <p>{t('Please key in your cancellation code to proceed')}</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <TextInput
                type="text"
                className={classNames(
                  errors.cancellation_code &&
                    touched.cancellation_code &&
                    styles.textInputError
                )}
                {...{ errors, touched }}
                name="cancellation_code"
                placeholder={t('Cancellation Code')}
              />

              <Button
                isLoading={isSubmitting}
                type="submit"
                size="lg"
                fullWidth
                justify="center"
                className={classNames(styles.button, styles.primary)}
                onClick={() =>
                  cleverTap.tapped.push({
                    screen_name: 'transaction_cancel',
                    tapped_on: 'btn_proceed'
                  })
                }
              >
                {t('Proceed')}
              </Button>
              <CancelButton
                onClick={onClose}
                fullWidth
                className={classNames(styles.button, styles.cancel)}
              >
                {t('Cancel')}
              </CancelButton>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <OpsLayout>
      {!isWebview && (
        <Heading title="Alipay" subtitle={t('Generate Alipay QR code here')} />
      )}
      <Button
        leftIcon="arrow-left"
        href="/operations/transactions"
        type="text"
        children={t('Back to transactions')}
      />
      <TransactionCard
        dateCreated={created_at_json}
        dateCancelled={cancelled_at_json}
        isCancelled={cancelled}
        isCancellable={cancellable}
        receiptId={receipt_id}
        billAmount={bill_amount}
        customerName={null}
        onCancelledIsLoading={isLoading}
        onCancelledClick={checkForCancellationCode}
        cancelledBy={cancelled_by}
      />
    </OpsLayout>
  );
};

export default AlipayDetailView;
