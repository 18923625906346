import React from 'react';
import { Trans } from 'react-i18next';
import UserTipStrip from './UserTipStrip';
import InlineLink from './InlineLink';
import {
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import useChat from 'hooks/useChat';
import { capitalize } from 'lodash';

export type MissingOutletImgStripProps = {
  screenName: string;
};

const MissingOutletImgTip = ({ screenName }: MissingOutletImgStripProps) => {
  const { chatText, openWidget } = useChat();

  const handleChatClick = () => openWidget();

  return (
    <UserTipStrip>
      <span>
        <Trans>
          {'Improve your storefront with an outlet image. '}
          <InlineLink
            children={capitalize(chatText)}
            onClick={handleChatClick}
            event={{
              screen_name: screenName,
              section_name: CLEVERTAP_SECTION_NAME.BANNER,
              tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_LIVE_CHAT
            }}
          />
          {' to upload an image.'}
        </Trans>
      </span>
    </UserTipStrip>
  );
};

export default MissingOutletImgTip;
