import Kfit from '../integrations/Kfit';

export interface IGetAnnouncementsResponse {
  id: number;
  announcement: string;
  status: string;
  title: string;
  image_url: string;
  outlets: [
    {
      id: number;
      name: string;
      fave_payment_outlet_detail: {
        fee_percentage: string;
        cashback_rate: string;
      };
      self_onboarding_status: string;
      self_onboard: string;
      address: string;
      upload_image_urls: string;
      has_fave_payment: string;
      qr_codes: {
        qr_value: string;
        source: string;
      };
      auth_code: string;
      favepay_location: string;
      has_setup_cashback: string;
      fave_deals_interest: string;
      has_alipay: string;
    }
  ];
  start_at: string;
  end_at: string;
}

export const getAllAnnouncements = (param: {
  company_id: number;
}): Promise<IGetAnnouncementsResponse[]> =>
  Kfit.get(`/v7/outlet_announcements`, param);

export const getAnnouncement = (
  companyId: number
): Promise<IGetAnnouncementsResponse> =>
  Kfit.get(`/v7/outlet_announcements/${companyId}`);
