import React, { useState } from 'react';
import styles from 'insights/assets/css/EmailFader.module.scss';

export default function EmailFader({ screenshot_height }) {
  const [fadeVisible, setFadeVisible] = useState(false);
  return (
    <div
      className={fadeVisible ? styles.fader : ''}
      style={{ '--fade-offset-y': screenshot_height + 'px' }}
    >
      <button hidden id="fade-screen" onClick={() => setFadeVisible(true)} />
    </div>
  );
}
