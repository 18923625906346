import React from 'react';
import classNames from 'classnames';
import { UrlImage } from 'types/UrlImage';
import { ImageFile } from 'components/common/forms/ImageUpload';
import ModalContainer from 'components/common/ModalContainer';
import {
  getAvailableOutlets,
  IAvailableOutlet
} from 'queries/getAvailableOutlets';

import modalStyles from 'assets/css/FaveFeedModals.module.scss';
import PostSubmittedScheduledImage from 'assets/images/FaveFeed/fave-feed-post-submitted-scheduled.svg';

import { Trans, useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { formatDateAndTime } from 'helpers/dateUtility';
import Button from 'components/Button';
import { TFunction } from 'i18next';
import { CLEVERTAP_SCREEN_NAME } from 'helpers/TypeConstants';
import { FeatureItem } from 'queries/getFeatureListing';

export interface FaveFeedFormViewProps {
  post_id: number | undefined;
  '*': string;
}

export type FeedPostStatus = '' | 'active' | 'scheduled' | 'inactive';

export enum FeedPostTypes {
  Announcement = 1,
  Deals = 2,
  eCards = 3
}

export type FeedPostFields = {
  id: number;
  company_id: number;
  date: Date;
  description: string;
  type: FeedPostTypes;
  status: FeedPostStatus;
  flagged: boolean;
  isLive?: boolean;
  availableOutlets: IAvailableOutlet[];
  selectedOutlets: string;
  images: ImageFile[];
  paths: UrlImage[];
  deleted_images_ids: number[];
  isLoading?: boolean;
  isError?: boolean;
  featureItem?: FeatureItem;
};

export const getFeedPostType = (keyName: string): FeedPostTypes | undefined => {
  if (!keyName) return undefined;

  const key = Object.keys(FeedPostTypes).find(
    enumKey => enumKey.toUpperCase() === keyName.toUpperCase()
  );

  return key ? FeedPostTypes[key as keyof typeof FeedPostTypes] : undefined;
};

export const getScreenDisplayedEvent = (
  step: number,
  isEdit: boolean,
  postType: FeedPostTypes
) => {
  const screenName = getScreenName(step, isEdit, postType);

  const event = {
    screen_name: screenName
  } as any; //clever tap uses "any" object. We don't have types yet

  if (postType !== FeedPostTypes.Announcement)
    event.offers_type = getClevertapOfferType(postType);

  return event;
};

export const getFeedPostTypeFromApiName = (type: string): FeedPostTypes => {
  switch (type) {
    case 'deal':
      return FeedPostTypes.Deals;
    case 'e_card':
      return FeedPostTypes.eCards;
    case 'announcement':
    default:
      return FeedPostTypes.Announcement;
  }
};

export const getFeedPostApiTypeName = (type: FeedPostTypes): string => {
  switch (type) {
    case FeedPostTypes.Deals:
      return 'deal';
    case FeedPostTypes.eCards:
      return 'e_card';
    case FeedPostTypes.Announcement:
    default:
      return 'announcement';
  }
};

const getScreenEventType = (postType: FeedPostTypes) =>
  postType === FeedPostTypes.Announcement ? 'announcement' : 'featured';

export const getClevertapOfferType = (postType: FeedPostTypes) =>
  FeedPostTypes[postType].toLowerCase();

export const getScreenName = (
  step: number,
  isEdit: boolean,
  postType: FeedPostTypes
): string => {
  const postTypeName = getScreenEventType(postType);
  const newEditName = isEdit ? 'EDIT' : 'NEW';

  const key = `FEED_${newEditName}_${postTypeName}_STEP${step}`.toUpperCase();

  return CLEVERTAP_SCREEN_NAME[key as keyof typeof CLEVERTAP_SCREEN_NAME];
};

export const joinParams = (outlets: IAvailableOutlet[]) =>
  outlets.map((outlet: IAvailableOutlet) => outlet.id).join(',');

export const loadOutlets = (
  company_id: number,
  date: Date,
  field: string,
  setFieldValue: (
    field: string,
    value: IAvailableOutlet[] | string | boolean
  ) => void
) => {
  setFieldValue('isLoading', true);
  setFieldValue('isError', false);

  getAvailableOutlets({
    company_id: company_id,
    start_at: date
  })
    .then(({ outlets }: { outlets: IAvailableOutlet[] }) => {
      const newOutletsArray = outlets.map((outlet: IAvailableOutlet) => {
        return {
          ...outlet,
          checked: true
        };
      });

      setFieldValue(field, newOutletsArray);
      setFieldValue('selectedOutlets', joinParams(newOutletsArray));
    })
    .catch(() => {
      setFieldValue(field, []);
      setFieldValue('selectedOutlets', '');

      setFieldValue('isError', true);
    })
    .finally(() => setFieldValue('isLoading', false));
};

export const handleDateChanged = (
  value: Date,
  values: FeedPostFields,
  setFieldValue: (
    field: string,
    value: IAvailableOutlet[] | string | boolean
  ) => void
) => loadOutlets(values.company_id, value, 'availableOutlets', setFieldValue);

export const getOutletPlaceholderText = (
  t: TFunction,
  availableOutletsCount: number,
  selectedOutletsCount: number,
  isLoading: boolean | undefined
) => {
  if (isLoading) return t('Loading outlets...');
  else if (selectedOutletsCount > 0)
    return t(`{{selectedOutletsCount}} outlet(s) selected`, {
      selectedOutletsCount
    });
  else if (availableOutletsCount === 0)
    return t(`{{availableOutletsCount}} outlet(s)`, {
      availableOutletsCount
    });
  else if (availableOutletsCount > 0)
    return t(`{{availableOutletsCount}} available outlet(s)`, {
      availableOutletsCount
    });
  else return '';
};

//modals
export const PostSubmittedScheduledModal = ({
  onClose,
  scheduledPostDate
}: {
  onClose: () => void;
  scheduledPostDate: Date;
}) => {
  const { t } = useTranslation();

  const onClickClose = () => {
    onClose();
    navigate('/feed?activeTab=scheduled');
  };

  return (
    <ModalContainer className={modalStyles.postSubmittedModal}>
      <div className={modalStyles.slideContainer}>
        <img
          src={PostSubmittedScheduledImage}
          className={classNames(modalStyles.slide)}
          alt="scheduled post submitted"
        />
      </div>

      <p className={modalStyles.title}>{t('Post submitted!')}</p>
      <p className={modalStyles.content}>
        <Trans>
          {'Your post is scheduled to go live on '}
          <span className={modalStyles.bold}>
            {{ datetime: formatDateAndTime(scheduledPostDate) }}
          </span>
          {' in the Fave app.'}
        </Trans>
      </p>
      <div className={modalStyles.okayButtonContainer}>
        <Button
          type={'primary'}
          onClick={onClickClose}
          textCenter={true}
          fullWidth={true}
          size={'lg'}
        >
          {t('Okay')}
        </Button>
      </div>
    </ModalContainer>
  );
};
// end modals
