import DataLoader from 'dataloader';
import Cookie from '../helpers/Cookie';
import FaveBizAPI from 'integrations/FaveBizAPI';

const userCacheKeyHasher = key => {
  return JSON.stringify(key) + Cookie.get('accessToken');
};

export const Repo = new DataLoader(
  keys =>
    Promise.all(
      keys.map(({ key, token }) => {
        return FaveBizAPI.get(
          key,
          {},
          { headers: { authorization: `Bearer ${token}` } }
        ).then(response => {
          return response;
        });
      })
    ),
  {
    batch: false,
    cacheKeyFn: userCacheKeyHasher
  }
);

export default function verifyUser(key, token) {
  return Repo.load({ key, token });
}
