import Kfit from '../integrations/Kfit';
import DataLoader from 'dataloader';

const Repo = new DataLoader(
  keys => Promise.all(keys.map(({ key, params }) => Kfit.get(key, params))),
  {
    batch: false,
    cacheKeyFn: key => JSON.stringify(key)
  }
);

function loadInfo(key, params) {
  return Repo.load({ key, params });
}

export default params => loadInfo(`/v4/redemptions`, params);
