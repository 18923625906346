import useSWR from 'swr';

import useInsightsParams from 'insights/hooks/useInsightsParams';
import FaveData from 'integrations/FaveData';

const objectToArray = params => Object.keys(params).map(key => params[key]);

export default ({ url: relativeUrl, params = {} }) => {
  const {
    country,
    companyId,
    outletId: outlet_id,
    startDate: start_date,
    endDate: end_date
  } = useInsightsParams();
  const runIt = start_date && end_date;
  const finalParams = {
    start_date: start_date && start_date.format('YYYY-MM-DD'),
    end_date: end_date && end_date.format('YYYY-MM-DD'),
    outlet_id,
    ...params
  };
  const url = `${country}/${companyId}/${relativeUrl}`;

  /* dependency array change causes cache revalidation, array = key */
  const key = objectToArray({ url, ...finalParams });

  const { data } = useSWR(
    runIt ? key : null,
    () => FaveData.get({ url, params: finalParams }),
    { revalidateOnFocus: false }
  );
  // transform custom API response to match useSWR's API response { data, error }
  return {
    data: (data || {}).detail ? undefined : data,
    error: (data || {}).detail
  };
};
