import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from 'assets/css/LoginHeader.module.scss';

type LoginHeaderProps = {
  className: string;
} & React.HTMLAttributes<HTMLHeadingElement>;

export default function LoginHeader({
  className,
  children,
  ...props
}: PropsWithChildren<LoginHeaderProps>) {
  return (
    <h3 className={classNames(styles.root, className)} {...props}>
      {children}
    </h3>
  );
}
