import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import { call, NATIVE } from 'utils/nativeInterface';

import useCleverTapOld from 'hooks/useCleverTapOld';
import routes from 'helpers/routesMap';
import Button from 'components/Button';
import FavePayCompleteImage from 'assets/images/favepay-complete-setup.svg';
import styles from 'assets/css/FavePaySetupView/FavePaySetupDoneModal.module.scss';
import { WebviewContext } from 'contexts/WebviewContext';

function FavePaySetupDoneModal({ onClose, outletContext }) {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { isWebview } = useContext(WebviewContext);

  return (
    <section className={styles.layout}>
      <img
        className={styles.image}
        src={FavePayCompleteImage}
        alt="FavePay setup complete"
      />
      <h2 className={styles.heading}>{t('Hooray!')}</h2>
      <p className={styles.paragraph}>
        {t('You’ve successfully activated Payment for your business!')}
      </p>
      <Button
        type="primary"
        size="lg"
        onClick={() => {
          onClose();
          cleverTap.tapped.push({
            screen_name: 'payment_setup_success',
            tapped_on: 'btn_moreservices',
            outletContext
          });

          if (isWebview) call(NATIVE.NAVIGATE_SERVICES);
          else navigate(routes.moreServices);
        }}
      >
        {t('Activate more services')}
      </Button>
    </section>
  );
}

FavePaySetupDoneModal.propTypes = {
  onClose: PropTypes.func
};

FavePaySetupDoneModal.defaultProps = {
  onClose: () => {}
};

export default FavePaySetupDoneModal;
