import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './common/ModalContainer';
import Button from 'components/Button';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import styles from 'assets/css/AutoExtensionDeactivatedModal.module.scss';
import DeactivatedImg from 'assets/images/deactivated.png';
import { Formik, Form } from 'formik';
import useCleverTapOld from 'hooks/useCleverTapOld';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
  CLEVERTAP_ADDITIONAL
} from 'helpers/TypeConstants';

type AutoExtensionDeactivatedModalProps = {
  onClose: () => void;
  type: string;
  id: number;
  reasonsList: [];
  handleDeactivation: (
    id: number,
    values: ReasonsType,
    onSuccess?: () => void,
    finishSubmit?: () => void
  ) => void;
  outletContext: any;
};

type ReasonsType = { reason: string, note: string };

function AutoExtensionDeactivatedModal({ onClose, type, id, reasonsList, handleDeactivation, outletContext}: AutoExtensionDeactivatedModalProps) {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();

  return (
    <ModalContainer className={styles.modal}>
      <div>
        <img className={styles.image} src={DeactivatedImg} alt='deactivated' />
      </div>
      <p className={styles.title}>
        {type === 'deal' ? t('This Deal will be deactivated.') : t('This eCard will be deactivated.')}
      </p>
      <p className={styles.content}>
        {type === 'deal' ? 
          t('This Deal will be permanently remove from the Fave app, and you shall still serve all the unredeemed voucher(s).') :
          t('Are you sure you want to remove this eCard from the Fave app?')
        }
        {type === 'deal' && (
          <span className={styles.subSpan}>
            {t('Reason for deactivating this Deal:')}
          </span>
        )}
      </p>
      
      <Formik
        initialValues={ {reason: '', note: ''}}
        onSubmit={(values, formikBag) => {
          handleDeactivation(id, values, onClose, () => formikBag.setSubmitting(false));
        }}
        validateOnBlur={false}

      >
        {({
          errors,
          touched,
          isSubmitting,
          values
        }) => (
          <Form
            className={styles.form} 
            onChange={e=> {
              console.log(e.target);
            }}
          >
            {type === 'deal' && (
              <Select
                name="reason"
                items={reasonsList}
                className={styles.reason}
                placeholder={t('Select a reason*')}
              />
            )}
            {type === 'deal' && values.reason === 'others' && (
              <TextInput
                type="text"
                name="note"
                placeholder={t('eg. No longer interested')}
                className={styles.othersReason}
                {...{ errors, touched }}
              />
            )}
            <div className={styles.buttonPane}>
              <Button
                disabled={ (type === 'deal' && (values.reason === '' || values.reason === 'placeholder' || (values.reason === 'others' && values.note === '')))}
                isLoading={isSubmitting}
                type="submit"
                fullWidth
                justify="center"
                size={'lg'}>

                {t('Confirm')}
              </Button>
              <Button
                type="inversePrimary"
                disabled={isSubmitting}
                fullWidth
                className={styles.cancel}
                onClick={() => {
                  cleverTap.tapped.push({ 
                    screen_name: CLEVERTAP_SCREEN_NAME.AUTO_EXTENSION,
                    tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CANCEL,
                    offers_type: type === 'deal' ? CLEVERTAP_ADDITIONAL.TYPE_DEALS : CLEVERTAP_ADDITIONAL.TYPE_ECARDS,
                    outletContext
                  });
                  onClose();
                }}
                justify="center"
                size={'lg'}>

                {t('Cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
}

export default AutoExtensionDeactivatedModal;
