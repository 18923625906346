import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import classNames from 'classnames';
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';
import BrandLogo from 'components/BrandLogo';
import Button from 'components/Button';
import LoginHeader from 'components/LoginHeader';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import chevronDown from 'assets/images/ic-chevron-down-grey.svg';
import Icon from 'components/Icon';
import styles from 'assets/css/LoginLayout.module.scss';
import LoginImage from 'assets/images/favebiz-login.jpg';
import StaffImage from 'assets/images/favebiz-login-staff.png';
import ManagerImage from 'assets/images/favebiz-login-manager.png';
import { MapleContext } from 'contexts/MapleContext';
import { generateStagingList, getStagingInfo } from 'integrations/Kfit';
import Dropdown from 'components/Dropdown';

const { isProduction, stagingName, switchStagingServer } = getStagingInfo();

const stagingList = generateStagingList().map(name => ({
  title: name,
  onClick: () => switchStagingServer(name)
}));

const StagingDropdown = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Dropdown
      contentClassName={styles.dropdownContent}
      dropdownButton={
        <Link to="#" className={styles.langBadge}>
          {stagingName || <>&nbsp;&nbsp;&nbsp;</>}{' '}
          <img src={chevronDown} alt="staging-select" />
        </Link>
      }
      onClick={isOpen => setDropdownOpen(isOpen)}
      dropdownItems={stagingList}
      showTopRightDropDown={isDropdownOpen}
      showToggleButton={false}
    />
  );
};

function LoginLayout({
  withHeader,
  withFooter,
  forSignUp,
  brandLogoHeight,
  managerLogin,
  backButtonProps,
  screenName,
  countryCode,
  redirectedPath,
  prevLocation,
  showStagingDropdown,
  ...props
}) {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { currentLang } = useContext(LangContext);
  const { isMaple } = useContext(MapleContext);
  const BackButton = ({ to = '/login', className }) => (
    <Button
      type="text"
      size="xs"
      href={to}
      className={classNames(styles.button, styles.backButton, className)}
      replace
    >
      <Icon icon={'angle-left'} className={styles.backButtonIcon} />
      {t('Back')}
    </Button>
  );

  const languageBadgeText = {
    'en-US': 'EN',
    'id-ID': 'ID',
    'ms-MY': 'MY',
    'zh-HK': '中文'
  };

  const imageSelector = () => {
    if (screenName === 'login' || screenName === 'edmManager')
      return LoginImage;
    else if (screenName === 'staff') return StaffImage;
    else return ManagerImage;
  };

  const mailTo = () => {
    if (countryCode === 'my') return 'mailto:fiona.malik@myfave.com';
    else if (countryCode === 'sg') return 'mailto:fiona.see@myfave.com';
    else if (countryCode === 'id') return 'mailto:fiona.ismawati@myfave.com';
  };

  const { isValidUser } = useContext(AuthContext);
  // for sign up, full reload to use longer token to get shorter token
  // longer token throws error in authenticated route API calls
  if (isValidUser) window.location.assign(redirectedPath);
  return isValidUser ? (
    <React.Fragment />
  ) : (
    <main className={styles.wrapper}>
      <section
        className={styles.intro}
        style={{ '--bg': `url(${imageSelector()})` }}
      >
        <section className={styles.maskOverlay}></section>
        <section className={styles.introDescription}>
          <h2 className={styles.introHeader}>
            {screenName === 'login' && t('Welcome to FaveBiz.')}
            {screenName === 'staff' && t('Here’s Staff Mode.')}
            {(screenName === 'manager' || screenName === 'edmManager') &&
              t('Here’s Manager Mode.')}
          </h2>
          <p className={styles.introDescriptionText}>
            {screenName === 'login' &&
              t(
                'Track your earnings and campaign performance, redeem vouchers, and transaction history, all with this easy to use dashboard.'
              )}
            {screenName === 'staff' &&
              t(
                `You can monitor transactions and orders with ease.
              Daily operations simplified in one view.`
              )}
            {(screenName === 'manager' || screenName === 'edmManager') &&
              t(
                `View and manage all accounts, reports and statements,
                sign up for services and make informed decisions
                with data to help your business grow.`
              )}
          </p>
        </section>
      </section>
      <section className={styles.content}>
        <section className={styles.topStrip}>
          {!isMaple && currentLang && (
            <Link
              to="/switch-language"
              state={{ prevLocation }}
              className={classNames(
                styles.langBadge,
                currentLang === 'zh-HK' && [styles.chineseFont]
              )}
            >
              {languageBadgeText[currentLang]}
              <img src={chevronDown} alt="lang-img" />
            </Link>
          )}
          {!(isProduction || isMaple) && showStagingDropdown && (
            <StagingDropdown />
          )}
        </section>
        <section className={styles.contentContainer}>
          {backButtonProps && (
            <BackButton
              to={backButtonProps.to}
              className={backButtonProps.className}
            />
          )}
          {withHeader && (
            <>
              <BrandLogo />
              {forSignUp ? (
                <LoginHeader className={styles.loginHeader}>
                  <div>{t('Setup your new account in')}</div>
                  <div>{t('4 easy steps.')}</div>
                </LoginHeader>
              ) : (
                <LoginHeader className={styles.loginHeader}>
                  {screenName === 'login' && (
                    <>
                      <div>{t('Hey, it’s good to see you!')}</div>
                      <div>{t('Choose your log in mode.')}</div>
                    </>
                  )}
                  {screenName === 'staff' && (
                    <>
                      <div>{t('You’re logging into Staff Mode.')}</div>
                    </>
                  )}
                  {(screenName === 'manager' ||
                    screenName === 'edmManager') && (
                    <>
                      <div>{t('You’re logging into Manager Mode.')}</div>
                    </>
                  )}
                </LoginHeader>
              )}
            </>
          )}

          {props.children}
        </section>

        {!isMaple && withFooter && managerLogin && (
          <section className={styles.footer}>
            {screenName === 'edmManager'
              ? t('Need help logging in?')
              : t("Don't have an account yet?")}
            <Button
              type="text"
              textColor="blue"
              size="xs"
              href={screenName === 'edmManager' ? mailTo() : '/sign-up'}
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'login_manager',
                  tapped_on: 'btn_signup'
                })
              }
            >
              {screenName === 'edmManager' ? t('Talk to us') : t('Sign up now')}
            </Button>
          </section>
        )}
        {forSignUp && (
          <section className={styles.footer}>
            {t('Already have an account?')}
            <Button
              type="text"
              textColor="blue"
              size="xs"
              href="/login"
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'onboarding',
                  tapped_on: 'btn_login'
                })
              }
            >
              {t('Log in here')}
            </Button>
          </section>
        )}
      </section>
    </main>
  );
}

LoginLayout.propTypes = {
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  forSignUp: PropTypes.bool,
  backButtonProps: PropTypes.object,
  managerLogin: PropTypes.bool,
  screenName: PropTypes.oneOf(['login', 'staff', 'manager', 'edmManager']),
  countryCode: PropTypes.oneOf(['my', 'sg', 'id']),
  redirectedPath: PropTypes.string
};

LoginLayout.defaultProps = {
  withHeader: false,
  withFooter: true,
  forSignUp: false,
  backButtonProps: undefined,
  managerLogin: false,
  screenName: 'login',
  countryCode: 'my',
  redirectedPath: '/'
};

export default LoginLayout;
