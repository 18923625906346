import React, { useState, useEffect } from 'react';
import FormikError from 'components/FormikError';
import { TIMEOUT_IN_MS } from 'helpers/TypeConstants';

type FormikErrorTimedProps = {
  error: string;
  onTimeout: (err: string) => void;
};

const FormikErrorTimed: React.FC<FormikErrorTimedProps> = ({
  error: parentError = '',
  onTimeout = () => {}
}) => {
  const [formError, setFormError] = useState(parentError);

  useEffect(() => {
    setFormError(parentError);
    const timer = setTimeout(() => setFormError(''), TIMEOUT_IN_MS);
    return () => clearTimeout(timer);
  }, [parentError]);

  useEffect(() => {
    onTimeout(formError);
  }, [formError, onTimeout]);

  return (
    <FormikError
      {...{
        name: 'formError',
        errors: { formError },
        touched: { formError: true }
      }}
    />
  );
};

export default FormikErrorTimed;
