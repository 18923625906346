import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import classNames from 'classnames';
import { AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';

import useDebounce from 'hooks/useDebounce';
import getAutocomplete from 'queries/getAutocomplete';
import getGooglePlace from 'queries/getGooglePlace';
import getGeoLocation from 'queries/getGeoLocation';
import TextInput from 'components/TextInput';

import styles from 'assets/css/AutoSuggest.module.scss';

type AutoSuggestAddressProps = {
  name?: string;
  description: string;
  latitude: number;
  longitude: number;
  place_id?: string;
  access_token?: string;
};

type AutoSuggestProps = {
  value: AutoSuggestAddressProps;
  suggestionsHeight: string;
  className: string;
  onChange: (address: Partial<AutoSuggestAddressProps>) => void;
  onChangeQuery: (value: string) => void;
  name: string;
};

function AutoSuggest({
  value: propAddress,
  suggestionsHeight = '40vh',
  className,
  onChange,
  onChangeQuery,
  name,
  ...props
}: AutoSuggestProps) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [query, setQuery] = useState('');
  const [activeSuggestion, setActiveSuggestion] = useState<
    AutoSuggestAddressProps
  >({} as AutoSuggestAddressProps);
  const [prevSuggestion, setPrevSuggestion] = useState<AutoSuggestAddressProps>(
    {} as AutoSuggestAddressProps
  );
  const [suggestions, setSuggestions] = useState<AutoSuggestAddressProps[]>([]);
  const debouncedQuery = useDebounce(query);

  useEffect(() => {
    if (Object.keys(propAddress).length !== 0) {
      setActiveSuggestion(propAddress);
      setPrevSuggestion(propAddress);
    }
    return () => {
      setActiveSuggestion({} as AutoSuggestAddressProps);
      setPrevSuggestion({} as AutoSuggestAddressProps);
    };
  }, [propAddress, query, debouncedQuery]);

  useEffect(() => {
    if (debouncedQuery.length === 0) return;
    let isMounted = true;
    getAutocomplete({ citySlug: user.city.slug, query: debouncedQuery }).then(
      ({ places }: { places: AutoSuggestAddressProps[] }) => {
        if (!isMounted) return;
        setSuggestions(places);
        setSuggestionsVisible(true);
      }
    );
    return () => {
      isMounted = false;
    };
  }, [debouncedQuery, user]);

  useEffect(() => {
    setSuggestionsVisible(false);
    const hasKeys = Object.keys(activeSuggestion).length !== 0;
    if (hasKeys) {
      if (prevSuggestion.place_id === activeSuggestion.place_id) return;
      setPrevSuggestion(activeSuggestion);
      getGooglePlace({
        access_token: activeSuggestion.access_token,
        place_id: activeSuggestion.place_id
      }).then(coordinates => onChange({ ...activeSuggestion, ...coordinates }));
    }
  }, [prevSuggestion, activeSuggestion, onChange]);

  const noSuggestions = suggestions.length === 0 && query.length !== 0;

  return (
    <section className={classNames(styles.layout, className)}>
      <TextInput
        {...props}
        name={name}
        type="text"
        aria-autocomplete="both"
        aria-haspopup="false"
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        value={activeSuggestion.description || query}
        onKeyUp={e => {
          const ESCAPE_KEYCODE = 27;
          const isEscapeKey = e.keyCode === ESCAPE_KEYCODE;
          isEscapeKey && setSuggestionsVisible(false);
        }}
        onChange={e => {
          setActiveSuggestion({} as AutoSuggestAddressProps);
          setQuery(e.target.value);
          onChangeQuery(e.target.value);
        }}
      />
      <section
        style={{ '--suggestions-height': suggestionsHeight } as CSSProperties}
        className={classNames(
          styles.suggestionsLayout,
          suggestionsVisible && styles.visible,
          noSuggestions && styles.noSuggestions
        )}
      >
        {suggestions.map(suggestion => (
          <div
            key={suggestion.place_id}
            className={styles.suggestion}
            onClick={() => setActiveSuggestion({ ...suggestion })}
          >
            <div className={styles.name}>{suggestion.name}</div>
            <div className={styles.description}>{suggestion.description}</div>
          </div>
        ))}
        {noSuggestions && (
          <div
            className={styles.suggestion}
            onClick={() => {
              getGeoLocation(query).then(
                ({ status, lat: latitude, long: longitude }) => {
                  if (status) {
                    onChange({
                      description: query,
                      latitude,
                      longitude
                    });
                  }
                }
              );
            }}
          >
            <div className={styles.name}>
              {t('Can’t find your outlet address?')}
            </div>
            <div className={styles.description}>
              {t(
                'Try moving the pin on the map to your desired location, or click here for us to find the nearest location to your entered address'
              )}
            </div>
          </div>
        )}
      </section>
    </section>
  );
}

export default AutoSuggest;
