import React, { useEffect, useState } from 'react';
import useAuthContext from 'hooks/useAuthContext';
import AppLayout from 'layouts/AppLayout';
import OpsLayout from 'layouts/OpsLayout';
import Heading from 'components/Heading';
import { TappedButton } from 'components/Button';
import { useTranslation } from 'react-i18next';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
  COUNTRIES,
  MAP_CITY_TO_COUNTRY_CODE
} from 'helpers/TypeConstants';
import useCleverTapOld from 'hooks/useCleverTapOld';

import styles from 'assets/css/ContactUsView.module.scss';
import useChat from 'hooks/useChat';
import { useNeedHelp } from 'queries/needHelp';

export const getContactByCityCurrency = city =>
  ({
    RM: { email: 'partners.my@myfave.com' },
    S$: { email: 'partners.sg@myfave.com' },
    RP: { email: 'pm.id@myfave.com' }
  }[(city || {}).currency_display] || {});

const ContactUsView = () => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { user, isOperations } = useAuthContext();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const { openWidget } = useChat();
  const { data: needHelpData } = useNeedHelp({
    country_code: MAP_CITY_TO_COUNTRY_CODE[user?.city?.slug]
  });

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'help_contact' });
    setSentCTEvent(true);
  }, [cleverTap.screenDisplayed, sentCTEvent]);

  const onChatWithUsClick = () => openWidget();

  const { email } = getContactByCityCurrency(user.city);
  const isIndonesia =
    user && user.city && COUNTRIES[user.city.slug] === 'Indonesia';

  const Layout = isOperations ? OpsLayout : AppLayout;
  return (
    <Layout>
      <Heading title={t('Contact Us')} subtitle={t('Get in touch with us')} />
      <div className={styles.contactUsCard}>
        <p>
          {t(
            "If there's ever anything we can assist with, please don't hesitate to contact us at:"
          )}
        </p>
        {email ? (
          <section className={styles.buttons}>
            <TappedButton
              className={styles.button}
              href={`mailto:${email}`}
              leftIcon="envelope"
              iconColor="pelorous"
              event={{
                screen_name: CLEVERTAP_SCREEN_NAME.HELP_CONTACT,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_EMAIL_US
              }}
            >
              {email}
            </TappedButton>
          </section>
        ) : (
          <span>...</span>
        )}
        {!isIndonesia && (
          <TappedButton
            className={styles.chatWithUsButton}
            leftIcon="chat"
            iconColor="pelorous"
            onClick={onChatWithUsClick}
            event={{
              screen_name: CLEVERTAP_SCREEN_NAME.HELP_CONTACT,
              tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CHAT_US
            }}
          >
            {t('Chat with us')}
          </TappedButton>
        )}
        {needHelpData && (
          <section className={styles.buttons}>
            <TappedButton
              className={styles.button}
              href={`//wa.me/${needHelpData.whatsapp}`}
              leftIcon="chat"
              iconColor="pelorous"
              event={{
                screen_name: CLEVERTAP_SCREEN_NAME.HELP_CONTACT,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_WA_US
              }}
            >
              {t('WhatsApp Us')}
            </TappedButton>
          </section>
        )}
        {needHelpData?.call_us && (
          <section className={styles.buttons}>
            <TappedButton
              className={styles.button}
              href={`tel:${needHelpData.call_us}`}
              leftIcon="phone"
              iconColor="pelorous"
              event={{
                screen_name: CLEVERTAP_SCREEN_NAME.HELP_CONTACT,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CALL_US
              }}
            >
              {t('Call Us')}
            </TappedButton>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default ContactUsView;
