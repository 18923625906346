import React from 'react';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { ITappedButtonProps } from './Button/TappedButton';
import styles from 'assets/css/InlineLink.module.scss';
import { Link } from '@reach/router';
import classNames from 'classnames';

export interface IInlineLinkProps extends ITappedButtonProps {
  to?: string;
  className?: string;
  onClick?: () => void;
}

export const InlineLink = ({
  to = '',
  event,
  children,
  className,
  onClick,
  ...rest
}: React.PropsWithChildren<IInlineLinkProps>) => {
  const CleverTap = useCleverTapOld();

  if (to)
    return (
      <Link
        to={to}
        className={classNames(styles.inlineLink, className)}
        onClick={() => {
          event && CleverTap.tapped.push(event);
          onClick && onClick();
        }}
        {...rest}
      >
        {children}
      </Link>
    );

  return (
    <span
      className={classNames(styles.inlineLink, className)}
      onClick={() => {
        event && CleverTap.tapped.push(event);
        onClick && onClick();
      }}
      {...rest}
    >
      {children}
    </span>
  );
};

export default InlineLink;
