import { useCallback, useRef } from 'react';

const HEIGHT_TO_TRIGGER_LOAD_ON_SCROLL = '750px';

const useFetchDataOnScroll = (
  isLoading: boolean,
  hasMore: boolean,
  onLoadNext: () => void,
  enabled: boolean = true
) => {
  const observer: React.MutableRefObject<any> = useRef();
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      const option = {
        root: null,
        rootMargin: HEIGHT_TO_TRIGGER_LOAD_ON_SCROLL,
        threshold: 0
      };
      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && hasMore && enabled) onLoadNext();
      }, option);
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, enabled, onLoadNext]
  );
  return lastElementRef;
};
export default useFetchDataOnScroll;
