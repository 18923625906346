import React from 'react';
import styles from 'insights/assets/css/InsightsStatic.module.scss';
import banner from 'insights/assets/images/static/insights-coming-soon.svg';
import logo from 'insights/assets/images/static/favebiz-insights-logo.svg';

const InsightsStatic = () => (
  <section className={styles.main}>
    <div className={styles.card}>
      <div className={styles.images}>
        <img alt="coming-soon" src={banner} />
        <img alt="insights-logo" src={logo} />
      </div>
      <div>
        <div className={styles.textTitle}>Data is populating...</div>
        <p className={styles.textSub}>
          Hey there, early bird! <br /> Personalised Insights for your business{' '}
          <br />
          will be ready soon!
        </p>
      </div>
    </div>
  </section>
);

export default InsightsStatic;
