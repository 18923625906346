import React, { MutableRefObject, useEffect, useRef } from 'react';
import {
  Formik,
  Form as FormikWrapperForm,
  FormikContextType,
  FormikProps,
  FormikValues
} from 'formik';

export interface FormProps<T> {
  className?: string;
  formRef?: MutableRefObject<FormikContextType<T> | undefined>;
}

const Form = <T extends {}>({
  className,
  initialValues,
  onSubmit,
  validationSchema,
  validateOnChange = false,
  validateOnSchemaChange = false,
  children,
  formRef,
  ...rest
}: FormikValues & FormProps<T>) => {
  const ref = useRef<FormikContextType<T>>();

  useEffect(() => {
    if (validateOnSchemaChange) ref.current?.validateForm();
  }, [ref, validateOnSchemaChange, validationSchema]);

  const refPassthrough = (instance: FormikContextType<T>) => {
    ref.current = instance;

    if (formRef) formRef.current = instance;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnMount
      enableReinitialize
      innerRef={refPassthrough}
      {...rest}
    >
      {typeof children === 'function'
        ? (state: FormikProps<T>) => (
            <FormikWrapperForm className={className}>
              {children(state)}
            </FormikWrapperForm>
          )
        : () => <>{children}</>}
    </Formik>
  );
};

export default Form;
