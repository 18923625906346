import React, { ComponentType } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from './Button';
import ModalContainer from './common/ModalContainer';
import styles from 'assets/css/ConfirmModal.module.scss';

export type ConfirmModalProps = {
  title: string | ComponentType;
  content: string | ComponentType;
  yesText: string;
  noText: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
};

const ConfirmModal = ({
  title,
  content,
  onConfirm,
  onCancel,
  onClose: closeCallback,
  yesText,
  noText
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalContainer>
      <p className={styles.title}>
        <Trans>{title}</Trans>
      </p>
      <p className={styles.content}>
        <Trans>{content}</Trans>
      </p>
      <div className={styles.buttonPane}>
        <Button
          onClick={() => {
            closeCallback();
            onCancel && onCancel();
          }}
          type="inversePrimary"
          justify="center"
          size="lg"
        >
          {t(noText)}
        </Button>
        <Button
          onClick={() => {
            closeCallback();
            onConfirm();
          }}
          type="primary"
          justify="center"
          size="lg"
        >
          {t(yesText)}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default ConfirmModal;
