import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ModalContainer from './common/ModalContainer';

import styles from 'assets/css/FaveFeedModals.module.scss';

import PostSubmittedLive from 'assets/images/FaveFeed/fave-feed-post-submitted-live.svg';
import PostSubmittedScheduled from 'assets/images/FaveFeed/fave-feed-post-submitted-scheduled.svg';
import PostSubmittedReview from 'assets/images/FaveFeed/fave-feed-post-submitted-review.svg';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { IAvailableOutlet } from 'queries/getAvailableOutlets';
import { isEmpty } from 'lodash';
import Checkbox from './Checkbox';

export const MoreOutletsModal = ({
  outlets
}: {
  outlets: string[] | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <ModalContainer className={styles.moreOutletsModal}>
      <p className={styles.title}>
        {t('This post is created for these outlets')}
      </p>
      <hr />
      <div className={styles.outletList}>
        {outlets &&
          outlets.map(outlet => (
            <p className={styles.outletName} key={outlet}>
              {outlet}
            </p>
          ))}
      </div>
    </ModalContainer>
  );
};

export const SelectOutletsModal = ({
  items,
  onSearchTapped,
  onConfrimTapped,
  onClose
}: {
  onClose: (items: IAvailableOutlet[]) => void;
  items: IAvailableOutlet[];
  onSearchTapped: () => void;
  onConfrimTapped: () => void;
}) => {
  const [searchOutlet, setSearchOutlet] = useState('');
  const [itemList, setItemList] = useState<IAvailableOutlet[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setItemList(items);
  }, [items, setItemList]);

  const selectItem = (selectedItem: IAvailableOutlet) => {
    const newItems = itemList.map(item => {
      const newItem = { ...item };

      if (item.id === selectedItem.id) newItem.checked = !item.checked;

      return newItem;
    });

    setItemList(newItems);
  };

  const isAllOutletsSelected = itemList.every(outlet => outlet.checked);
  const isOneOutletSelected = itemList.find(outlet => outlet.checked);
  const selectAllOutlets = () => {
    const allOutlets = isAllOutletsSelected
      ? itemList.map(outlet => {
          return {
            ...outlet,
            checked: false
          };
        })
      : itemList.map(outlet => {
          return {
            ...outlet,
            checked: true
          };
        });
    setItemList(allOutlets);
  };

  const searchedOutletsList = itemList.filter(word => {
    if (isEmpty(searchOutlet)) {
      return word.name;
    } else if (searchOutlet.includes(' ')) {
      return word.name.toLowerCase().includes(searchOutlet);
    } else {
      return word.name
        .toLowerCase()
        .split(' ')
        .find(
          splitWord => splitWord.substr(0, searchOutlet.length) === searchOutlet
        );
    }
  });

  const isOutletExist = !isEmpty(searchedOutletsList);

  const ReturnSearchedOutlet = () => {
    return (
      <>
        {isOutletExist ? (
          searchedOutletsList.map(item => (
            <p key={item.id}>
              <Checkbox
                name={item.name}
                value={item.name}
                id={item.name}
                checked={item.checked}
                onChange={() => selectItem(item)}
                type={'green'}
                disabled={false}
              >
                {item.name}
              </Checkbox>
            </p>
          ))
        ) : (
          <p className={styles.noOutletsFound}>
            {t('No outlet with that name')}
          </p>
        )}
      </>
    );
  };

  const showClearButton = searchOutlet.length > 0;

  return (
    <ModalContainer className={styles.selectOutletsModal}>
      <p className={styles.title}>{t('Select outlets')}</p>
      <p className={styles.description}>
        {t('These are the list of available outlets to schedule this post')}
      </p>

      <form className={styles.form}>
        <input
          type={'text'}
          value={searchOutlet}
          onChange={e => setSearchOutlet(e.target.value.toLowerCase())}
          className={styles.searchOutletBar}
          placeholder={t('Search outlet')}
          onClick={onSearchTapped}
        />
        {showClearButton && (
          <button
            className={styles.closeIcon}
            type="reset"
            onClick={() => {
              setSearchOutlet('');
            }}
          />
        )}
      </form>
      {isOutletExist && (
        <div className={styles.selectAllButtonContainer}>
          <p className={styles.selectAll} onClick={selectAllOutlets}>
            {t(isAllOutletsSelected ? 'Deselect all' : 'Select all')}
          </p>
        </div>
      )}
      <div className={styles.availableOutletsList}>
        <ReturnSearchedOutlet />
      </div>
      <div className={styles.doneButtonContainer}>
        <Button
          type={'primary'}
          disabled={!isOneOutletSelected}
          onClick={() => {
            onConfrimTapped();
            onClose(itemList);
          }}
        >
          {t('Done')}
        </Button>
      </div>
    </ModalContainer>
  );
};

const PostSubmittedLiveModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer className={styles.postSubmittedModal}>
      <div className={styles.slideContainer}>
        <img
          src={PostSubmittedLive}
          className={classNames(styles.slide)}
          alt="new post submitted"
        />
      </div>

      <p className={styles.title}>{t('Post submitted!')}</p>
      <p className={styles.content}>
        {t(
          'Your post is now live on the Feed & your outlet page in the Fave app'
        )}
      </p>
      <div className={styles.okayButtonContainer}>
        <Button
          type={'primary'}
          onClick={onClose}
          textCenter={true}
          fullWidth={true}
          size={'lg'}
        >
          {t('Okay')}
        </Button>
      </div>
    </ModalContainer>
  );
};

const PostSubmittedScheduledModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer className={styles.postSubmittedModal}>
      <div className={styles.slideContainer}>
        <img
          src={PostSubmittedScheduled}
          className={classNames(styles.slide)}
          alt="scheduled post submitted"
        />
      </div>

      <p className={styles.title}>{t('Post submitted!')}</p>
      <p className={styles.content}>
        {t(
          'Your post is scheduled to go live on 30 Jul 2021, 2:30 PM in the Fave app.'
        )}
      </p>
      <div>
        <Button type={'primary'} onClick={onClose}>
          {t('Okay')}
        </Button>
      </div>
    </ModalContainer>
  );
};

const PostSubmittedReviewModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer className={styles.postSubmittedModal}>
      <div className={styles.slideContainer}>
        <img
          src={PostSubmittedReview}
          className={classNames(styles.slide)}
          alt="post submitted for review"
        />
      </div>

      <p className={styles.title}>{t('Post submitted for review')}</p>
      <p className={styles.content}>
        {t('Your post will reactivate upon approval of the review.')}
      </p>
      <div className={styles.okayButtonContainer}>
        <Button
          type={'primary'}
          onClick={onClose}
          textCenter={true}
          fullWidth={true}
          size={'lg'}
        >
          {t('Okay')}
        </Button>
      </div>
    </ModalContainer>
  );
};

const modals = {
  PostSubmittedLiveModal,
  PostSubmittedReviewModal,
  PostSubmittedScheduledModal
};

export default modals;
