import React, { useContext } from 'react';

import useHubSpotTrack from 'hooks/useHubSpotTrack';
import LoginLayout from 'layouts/LoginLayout';
import Button from 'components/Button';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { ModalContext } from 'contexts/ModalContext';
import { navigate } from '@reach/router';

import ModalContractImage from 'assets/images/modal-contract.svg';

import styles from 'assets/css/SignUpContractView.module.scss';

const SignUpContractView = ({ location }) => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  useHubSpotTrack(location.pathname);
  const currentQueryString = useContext(QueryStringContext);
  const { init, close } = useContext(ModalContext);

  const contractModal = () => {
    return (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.modalImage}>
            <img src={ModalContractImage} alt="modalImg" />
          </div>
          <h5>{t('Hi there!')}</h5>
          <p>
            {t(
              `It is likely that you already have an account.
              Please check your “Welcome to Fave” email
              for verification and instructions.`
            )}
          </p>
          <div className={styles.ButtonSection}>
            <Button
              type={'skeleton'}
              href={`mailto:pm.${
                currentQueryString.country === 'my' ? 'my' : 'sg'
              }@myfave.com`}
              isRounded={true}
              size={'lg'}
            >
              {t('Contact Us')}
            </Button>
            <Button
              type={'primary'}
              isRounded={true}
              size={'lg'}
              onClick={() => {
                close();
                navigate('/login');
              }}
            >
              {t('Go to Log in')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <LoginLayout
        withFooter={true}
        withHeader
        backButtonProps={{ to: '/sign-up' }}
        forSignUp={true}
      >
        <div className={styles.ContractViewContainer}>
          <div className={styles.buttonContainer}>
            <Button
              type={'primary'}
              href={`/sign-up/form?country=${currentQueryString.country}&method=email`}
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'signup',
                  tapped_on: 'btn_signup_email'
                })
              }
              fullWidth
              className={styles.mailButton}
              iconSize={'1.375rem'}
              leftIcon={'envelope'}
            >
              {t('Continue with email address')}
            </Button>
            <Button
              type={'primary'}
              href={`/sign-up/form?country=${currentQueryString.country}&method=phone`}
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'signup',
                  tapped_on: 'btn_signup_number'
                })
              }
              fullWidth
              iconSize={'1.375rem'}
              className={styles.phoneButton}
              leftIcon={'mobile-phone'}
            >
              {t('Continue with phone number')}
            </Button>
          </div>
          <div className={styles.contractContainer}>
            <p>{t('or')}</p>
            <Button
              type={'skeleton'}
              className={styles.contractButton}
              fullWidth
              isRounded
              iconSize={'1.375rem'}
              iconColor={'forest-green'}
              leftIcon={'contract'}
              onClick={() => {
                cleverTap.tapped.push({
                  screen_name: 'signup',
                  tapped_on: 'btn_existing_contract'
                });
                init({
                  Component: contractModal,
                  openOnReady: true
                });
              }}
            >
              {t(`I've signed a contract with Fave`)}
            </Button>
          </div>
        </div>
      </LoginLayout>
    </>
  );
};

export default SignUpContractView;
