import React, { PropsWithChildren, ReactElement } from 'react';

import styles from '../assets/css/EmptyDataMessage.module.scss';

type EmptyDataMessageProps = PropsWithChildren<{
  title?: ReactElement;
  visible?: boolean;
}>;

const EmptyDataMessage = ({
  visible,
  title,
  children
}: EmptyDataMessageProps) =>
  visible === undefined || visible ? (
    <span className={styles.message}>{title || children}</span>
  ) : null;

export default EmptyDataMessage;
