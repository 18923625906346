import React from 'react';
import style from 'assets/css/InfoIcon.module.scss';
import InfoSymbolIcon from 'assets/images/FaveFeed/info-icon.svg';
import { call } from 'utils/nativeInterface';
import useCleverTapOld from 'hooks/useCleverTapOld';

type CleverTapType = {
  screen_name?: string;
  tapped_on?: string;
  section_name?: string;
};

type InfoIconPropsType = {
  isWebview?: boolean;
  onClickNativeInterface?: string;
  cleverTapEvents?: CleverTapType;
  onClick?: () => void;
};

const InfoIcon: React.FC<InfoIconPropsType> = props => {
  const {
    isWebview = false,
    onClickNativeInterface = '',
    cleverTapEvents = undefined,
    onClick = () => {}
  } = props;
  const CleverTap = useCleverTapOld();

  const onClickInfoIcon = () => {
    if (isWebview) {
      call(onClickNativeInterface);
    } else {
      if (cleverTapEvents) {
        CleverTap.tapped.push({
          ...cleverTapEvents
        });
      }
      onClick();
    }
  };

  return (
    <img
      onClick={onClickInfoIcon}
      className={style.infoIcon}
      src={InfoSymbolIcon}
      alt="view favepay info"
    />
  );
};

export default InfoIcon;
