import React from 'react';
import classNames from 'classnames';
import styles from 'assets/css/FilterPane.module.scss';

export type FilterPaneProps = {
  className?: string
};

const FilterPane = ({
  className,
  children
}: React.PropsWithChildren<FilterPaneProps>) => {
  const classes = classNames(styles.filterPane, className);

  return <header className={classes}>{children}</header>;
};

export default FilterPane;
