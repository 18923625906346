import React, { useContext, useReducer, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { isEmpty, get } from 'lodash';
import { isObject } from 'utils';
import { format } from 'date-fns';

import getReportRequest from 'queries/getReportRequest';
import requestReports from 'commands/requestReports';
import { requestMapleReports } from 'commands/requestMapleReports';
import { REPORT_TYPES, COUNTRIES } from 'helpers/TypeConstants';
import { NotificationContext } from 'contexts/NotificationContext';
import useOutletContext from 'hooks/useOutletContext';
import useAuthContext from 'hooks/useAuthContext';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { OutletContext } from 'contexts/OutletContext';
import { OpsAuthContext } from 'contexts/OpsAuthContext';

import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Card from 'components/Card';
import Badge from 'components/Badge';
import AppLayout from 'layouts/AppLayout';
import OpsLayout from 'layouts/OpsLayout';
import Heading from 'components/Heading';

import { MapleContext } from 'contexts/MapleContext';
import styles from 'assets/css/ReportView.module.scss';
import DatePicker, { DateInputBox } from 'components/DatePicker';
import { formatISOToLocalTime } from 'helpers/dateUtility';

const today = new Date();
const ecardCutOffDate = new Date('2023-06-01');

const ReportView = () => {
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const { currentOutlet } = useOutletContext();
  const { user, isOperations } = useAuthContext();
  const { setNotification } = useContext(NotificationContext);
  const { currentCompany } = useContext(OutletContext) || {};
  const { settings } = useContext(OpsAuthContext) || {};
  const screenName = 'reports';

  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: screenName });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed]);

  const allowDeals = !!get(settings, 'features.voucher', null);

  const allowAliPay =
    !!get(settings, 'features.alipay', null) &&
    settings.country !== COUNTRIES.jakarta;

  const REPORT_TYPES_OPS = {
    ALL: { text: 'All', value: 'all' },
    ...REPORT_TYPES,
    ALI_PAY: { text: 'Alipay', value: 'alipay_payments' }
  };

  const initialValues = {
    startDate: today,
    endDate: today,
    reportType: isOperations
      ? REPORT_TYPES_OPS.ALL.value
      : REPORT_TYPES.FAVE_PAY.value,
    activityIds: undefined,
    isSubmitting: false,
    isValidInput: true,
    isOpenEmailInput: false
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_DATE':
        return {
          ...state,
          startDate: action.startDate || state.startDate,
          endDate: action.endDate
        };
      case 'CHANGE_START_DATE':
        return {
          ...state,
          startDate: action.startDate
        };
      case 'CHANGE_END_DATE':
        return {
          ...state,
          endDate: action.endDate
        };
      case 'SELECT_REPORT_TYPE':
        return { ...state, reportType: action.reportType };
      case 'DEALS_ACTIVITY_IDS':
        return { ...state, activityIds: action.activityIds };
      case 'INPUT_EMAIL_IS_OPEN':
        return { ...state, isOpenEmailInput: true };
      case 'INPUT_IS_VALID':
        return { ...state, isValidInput: true };
      case 'INPUT_IS_SUBMITTING':
        return { ...state, isSubmitting: true };
      case 'INPUT_DONE_SUBMITTING':
        return { ...state, isSubmitting: false };
      case 'INPUT_IS_RESET':
        return initialValues;

      default:
        return state;
    }
  };

  const [
    {
      startDate,
      endDate,
      isOpenEmailInput,
      isSubmitting,
      reportType,
      activityIds,
      isValidInput
    },
    dispatch
  ] = useReducer(reducer, initialValues);

  /**
   * Operation: check whether to show badge of report types based on settings.features
   * Manager: no check, display all
   */
  const isAllowBadge = badgeTypeText => {
    if (isOperations && !isEmpty(settings)) {
      if (badgeTypeText === REPORT_TYPES_OPS.DEALS.text && !allowDeals)
        return false;
      if (badgeTypeText === REPORT_TYPES_OPS.ALI_PAY.text && !allowAliPay)
        return false;
      // Ecards: Manager only
      if (badgeTypeText === REPORT_TYPES_OPS.ECARD_PURCHASED.text) return false;
      if (badgeTypeText === REPORT_TYPES_OPS.ECARD_UTILISED.text) return false;

      return true;
    }
    return true;
  };

  const onReportTypeChange = (reportType, checked) => {
    checked && dispatch({ type: 'SELECT_REPORT_TYPE', reportType });
  };

  const onSubmitEmail = email => {
    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on: 'btn_send_report'
    });
    dispatch({ type: 'INPUT_IS_SUBMITTING' });

    const { ALL, ALI_PAY, FAVE_PAY, DEALS } = REPORT_TYPES_OPS;

    const isDealsTheActiveReport = reportType === REPORT_TYPES.DEALS.value;

    const isAllOutlets = !isObject(currentOutlet);

    const isAlipayAllowAll =
      reportType === ALL.value &&
      allowAliPay &&
      settings.country !== COUNTRIES.jakarta;

    const isFavePayAllowAll = reportType === ALL.value;

    const isDealsAllowAll = reportType === ALL.value && allowDeals;

    const formData = {
      ops: {
        email,
        alipay: reportType === ALI_PAY.value || isAlipayAllowAll,
        fave_payment: reportType === FAVE_PAY.value || isFavePayAllowAll,
        date: formatISOToLocalTime(startDate),
        end_date: formatISOToLocalTime(endDate),
        outlet_id: currentOutlet.id,
        activity: reportType === DEALS.value || isDealsAllowAll
      },
      manager: {
        email,
        start_date: formatISOToLocalTime(startDate),
        end_date: formatISOToLocalTime(endDate),
        company_id:
          isDealsTheActiveReport && isAllOutlets && !isEmpty(currentCompany)
            ? currentCompany.id
            : undefined,
        outlet_ids:
          isDealsTheActiveReport && isAllOutlets
            ? undefined
            : (
                (isObject(currentOutlet) && [currentOutlet]) ||
                (!isEmpty(currentCompany) && currentCompany.outlets)
              )
                .map(outlet => outlet.id)
                .join(',')
      }
    };

    const genericReportFetch = isOperations
      ? requestReports(formData.ops)
      : isMaple
      ? requestMapleReports(
          email,
          format(new Date(startDate), 'ddMMyyyy'),
          format(new Date(endDate), 'ddMMyyyy'),
          currentCompany.id
        )
      : getReportRequest(formData.manager, reportType);

    genericReportFetch
      .then(response => {
        if (response.message)
          return setNotification({
            message: response.message,
            type: 'success'
          });
        if (response.status_text)
          return setNotification({
            message: response.status_text,
            type: 'success'
          });
      })

      .catch(error => {
        if ('value' in error) {
          const { value } = error;
          setNotification({
            message: value.response.error,
            type: 'error'
          });
        } else {
          throw error;
        }
      })

      .finally(() => dispatch({ type: 'INPUT_DONE_SUBMITTING' }));
  };

  const Layout = isOperations ? OpsLayout : AppLayout;

  const reportTypesObject = isOperations ? REPORT_TYPES_OPS : REPORT_TYPES;

  const reportTypesObjectValues = Object.keys(reportTypesObject).map(
    key => reportTypesObject[key]
  );

  return (
    <Layout>
      <Heading
        title={t('Reports')}
        subtitle={t('Generate your sales and transactions report')}
      />
      {isOperations && (
        <Button
          leftIcon="arrow-left"
          href={'/operations/transactions'}
          type="text"
        >
          {t('Back to transactions')}
        </Button>
      )}
      <div className={styles.cardWrapper}>
        <Card className={classNames(styles.card, styles.reportCard)}>
          {!isMaple && (
            <>
              <p className={styles.selectReport}>
                {t('Select the type of report you would like to generate')}
              </p>
              <section className={styles.reportTypeBadges}>
                {reportTypesObjectValues.map(({ value, text }) => {
                  return (
                    isAllowBadge(text) && (
                      <Badge
                        key={value}
                        checked={value === reportType}
                        onChange={checked => onReportTypeChange(value, checked)}
                      >
                        {text}
                      </Badge>
                    )
                  );
                })}
              </section>
            </>
          )}
          <p className={styles.selectDateRange}>{t('Select date range')}</p>
          <section className={styles.dateOptions}>
            <Badge
              key="today"
              checked={startDate === today && endDate === today}
              onChange={() => {
                dispatch({ type: 'CHANGE_START_DATE', startDate: today });
                dispatch({ type: 'CHANGE_END_DATE', endDate: today });
              }}
            >
              Today
            </Badge>

            <DatePicker
              dateToHideLeftButton={
                (reportType === REPORT_TYPES.ECARD_PURCHASED.value ||
                  reportType === REPORT_TYPES.ECARD_UTILISED.value) &&
                ecardCutOffDate
              }
              startDate={startDate}
              setStartDate={date =>
                dispatch({
                  type: 'CHANGE_START_DATE',
                  startDate: date || today
                })
              }
              endDate={endDate}
              setEndDate={date =>
                dispatch({ type: 'CHANGE_END_DATE', endDate: date || today })
              }
              disableFutureDates={true}
              isModal={true}
              screenName={screenName}
              showApplyClearButtons={true}
            >
              {(_, trigger) =>
                startDate === today && endDate === today ? (
                  <Badge key="dates" onClick={() => trigger()}>
                    Dates
                  </Badge>
                ) : (
                  <DateInputBox
                    startDate={startDate}
                    endDate={endDate}
                    rangeDateSelection={true}
                    onClick={() => trigger()}
                    isTextGreen={true}
                  />
                )
              }
            </DatePicker>
          </section>
        </Card>
        {/* todo: Migrate the below to SendToEmailForm component */}
        <section className={styles.actionButton}>
          {!isOpenEmailInput ? (
            <Button
              type="primary"
              size="lg"
              rightIcon="envelope"
              onClick={() => dispatch({ type: 'INPUT_EMAIL_IS_OPEN' })}
              disabled={isSubmitting || !isValidInput}
            >
              {t('Send to email')}
            </Button>
          ) : (
            <Formik
              initialValues={{ email_send_to: (user && user.email) || '' }}
              validationSchema={Yup.object().shape({
                email_send_to: Yup.string()
                  .email(t('Invalid Email'))
                  .required(t('Email is required'))
              })}
              onSubmit={({ email_send_to }) => onSubmitEmail(email_send_to)}
            >
              {({ errors, touched }) => (
                <Form className={styles.statementsEmailContainer}>
                  <TextInput
                    {...{
                      type: 'email',
                      name: 'email_send_to',
                      placeholder: t('Enter your email address'),
                      focus: true,
                      errors,
                      touched
                    }}
                  />
                  <Button type="submit" size="lg" disabled={isSubmitting}>
                    {t('Send')}
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </section>
      </div>
    </Layout>
  );
};

export default ReportView;
