import useSWR from 'hooks/useSWR';

export default ({ company_id }) =>
  useSWR({
    url: `/v1/revenues/e_cards`,
    params: { company_id },
    swrOptions: {
      if: Boolean(company_id)
    }
  });
