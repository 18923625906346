import React from 'react';
import classNames from 'classnames';
import { withContentRect } from 'react-measure';
import Button, { CloseButton } from 'components/Button';
import styles from 'assets/css/Notification.module.scss';
import { RESPONSIVE_BREAKPOINTS } from 'helpers/TypeConstants';

export interface INotificationProps {
  handleDismiss: () => void;
  message: string | undefined;
  type: 'success' | 'error' | 'warning' | 'info' | undefined;
  mobileMessage?: string;
  cta?: { text: string; link: string };
}

const Notification: React.FC<INotificationProps> = ({
  handleDismiss,
  message,
  mobileMessage,
  type,
  cta,
  children
}) => {

  const MeasureNotification = withContentRect('bounds')(
    ({ measureRef, contentRect }) => (
      <p ref={measureRef} className={styles.message}>
        {contentRect!.bounds!.width <= RESPONSIVE_BREAKPOINTS.sm
          ? mobileMessage
          : message}
      </p>
  )
  );

  return (
    <div className={classNames(styles.container, message && styles.visible)}>
      <div className={styles.content} data-type={type}>
        {mobileMessage ? (
         <MeasureNotification/>
        ) : (
          <p className={styles.message}>
            {message}
          </p>
        )}
        {cta && (
          <Button
            type="skeleton"
            isRounded={true}
            className={styles.ctaButton}
            href={cta.link}
          >
            {cta.text}
          </Button>
        )}
        {children}
        <CloseButton
          className={classNames(styles.button, styles.closeButton)}
          onClick={handleDismiss}
        />
      </div>
    </div>
  );
};

export default Notification;
