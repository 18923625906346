import { useContext } from 'react';
import qs from 'qs';

import { AuthContext } from 'contexts/AuthContext';
import { OutletContext } from 'contexts/OutletContext';
import { COUNTRIES } from 'helpers/TypeConstants';

const queryStringBuilder = ({
  user: { email, phone, manager_name = '', city } = {},
  currentCompany: {
    partner_name: company_brand_name,
    parent_category_name
  } = {}
}) => fave_product_interested => {
  const [firstName, ...lastNames] = manager_name.split(' ');
  const firstname = firstName || undefined;
  const lastname = lastNames.join(' ').trim() || undefined;
  const country_of_origin = COUNTRIES[city && city.slug];

  const companyCategory = (() => {
    switch ((parent_category_name || '').toLowerCase()) {
      case 'food & beverages':
      case 'eat':
        return 'Food & Beverages';

      case 'retails':
      case 'retail':
        return 'Retail';

      case 'beauty':
        return 'Beauty & Wellness';

      case 'travel':
        return 'Travel';

      case 'activity':
      case 'activities':
        return 'Activities';

      case 'service':
      case 'services':
        return 'Services';

      default:
        return undefined;
    }
  })();

  return qs.stringify({
    company_brand_name,
    parent_category: companyCategory,
    fave_product_interested,
    firstname,
    lastname,
    email,
    phone: phone || undefined,
    country_of_origin
  });
};

const campaignQueryStringBuilder = ({
  user: { email, phone, manager_name = '', city } = {},
  currentCompany: { partner_name: company_brand_name } = {}
}) => {
  const country_of_origin = COUNTRIES[city && city.slug] || '';
  const [firstName, ...lastNames] = manager_name.split(' ');
  const CAMPAIGN_LINK_BASE_SG =
    'https://www.favebiz.com/en/join-fave-campaign/sg?';
  const CAMPAIGN_LINK_BASE_MY =
    'https://www.favebiz.com/en/join-fave-campaign/my?';

  const usp = new URLSearchParams({
    firstname: firstName,
    lastname: lastNames,
    utm_campaign: 'Fave Campaign',
    utm_source: 'FaveBiz More Services',
    utm_medium: country_of_origin,
    company_brand_name: company_brand_name,
    country_of_origin,
    email: email || '',
    phone: phone || ''
  });
  return (
    (country_of_origin === 'Singapore'
      ? CAMPAIGN_LINK_BASE_SG
      : CAMPAIGN_LINK_BASE_MY) + usp.toString()
  );
};

export default function useServicesUrls(authContext = AuthContext, companyId) {
  const BASE_URL = 'https://www.favebiz.com/login';
  const { user = {} } = useContext(authContext);

  const outletContext = useContext(OutletContext);
  const currentCompany = companyId
    ? (user.companies || []).find(company => company.id === +companyId)
    : outletContext.currentCompany;

  const getParams = queryStringBuilder({ user, currentCompany });
  const campaignURL = campaignQueryStringBuilder({ user, currentCompany });
  return {
    payments: `${BASE_URL}/payments`,
    cashback: `${BASE_URL}/cashback`,
    favedeals: `${BASE_URL}/favedeals?${getParams('FaveDeal')}`,
    financing: `/financing`,
    ecards: `${BASE_URL}/ecards?${getParams('eCards')}`,
    marketing: `${BASE_URL}/marketing-booster-package?${getParams()}`,
    'campaign-id': `https://www.favebiz.com/id/bergabung-campaign-fave?${getParams()}`,
    'singapore-malaysian-campaign': campaignURL
  };
}
