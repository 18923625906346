import React, { useEffect, useContext, useState } from 'react';
import AppLayout from 'layouts/AppLayout';
import { AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import style from 'assets/css/OfferDeactivateView.module.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import InlineLink from 'components/InlineLink';
import DealCard from 'components/AutoExtendDealCard';
import ECard from 'components/AutoExtendECard';
import ErrorPage from 'components/ErrorPage';
import { ModalContext } from 'contexts/ModalContext';
import { NotificationContext } from 'contexts/NotificationContext';
import NoInternetModal from 'components/NoInternetModal';
import {
  getAutoExtendEcards,
  getAutoExtendDeals
} from 'queries/getAutoExtension';
import {
  deactivateEcard,
  deactivateDeal
} from 'commands/deactivateProduct';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
  CLEVERTAP_ADDITIONAL
} from 'helpers/TypeConstants';
import { WebviewContext } from 'contexts/WebviewContext';
import { call, NATIVE } from 'utils/nativeInterface';

type ProductType = {
  id: number,
  name: string,
  purchase_count: number,
  image_url: string,
  background_color: string,
  text_color: string,
  original_price: string,
  discounted_price: string,
  total_value: string,
  bonus_credit_amount: string,
  theme: string,
  is_active: boolean
}

const OfferDeactivateView = (props: { location: any }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { isWebview } = useContext(WebviewContext);
  const cleverTap = useCleverTapOld();
  const { init } = useContext(ModalContext);
  const { setNotification } = useContext(NotificationContext);
  const [sentCTEvent, setSentCTEvent] = useState(false);
  const queryParams = new URLSearchParams(props.location.search);
  const uid = queryParams.get('uid');
  const type =
    props.location.pathname === '/offer/deactivate/ecards' ? 'ecards' : 'deals';

  const [dateData, setDateData] = useState('');
  const [companyNameData, setCompanyNameData] = useState('');
  const [companyLogoData, setCompanyLogoData] = useState('');
  const [productsData, setProductsData] = useState<Array<ProductType>>([]);
  const [deactivateButtonVisible, setDeactivateButtonVisible] = useState(false);
  const [errorPageVisible, setErrorPageVisible] = useState(false);
  const [errorTypeData, setErrorTypeData] = useState('no_access');

  const renderTitle = (type: string, date: string) => {
    if (type === 'ecards') {
      return t('Auto Extended eCards') + ': ' + date;
    } else {
      return t('Auto Extended Deals') + ': ' + date;
    }
  };

  const onClickContactUs = () => {
    if (isWebview) {
      call(NATIVE.OPEN_HELP);
    } else {
      return;
    }
  };

  const handleNoInternetError = () => {
    init({
      Component: NoInternetModal,
      openOnReady: true,
      hasCloseButton: false,
      componentProps: {}
    });
  };

  const setProductInactive = (id: number) => {
    let products = [...productsData];  
    let productID = products.findIndex(product => product.id === id);
    products[productID].is_active = false;
    setProductsData(products);
  }

  const handleDeactivation = (id: number, deactivation_reasons: any, onSucess: () => void, finishSubmit: () => void) => {
    
    if (type === 'ecards'){
      cleverTap.tapped.push({ 
        screen_name: CLEVERTAP_SCREEN_NAME.AUTO_EXTENSION,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CONFIRM,
        offers_type: CLEVERTAP_ADDITIONAL.TYPE_ECARDS
      });
      deactivateEcard(id)
        .then( () => {
          setProductInactive(id)
          onSucess();
        })
        .catch(error=>{
          if (window.navigator.onLine === false) {
            onSucess();
            handleNoInternetError();
          } else {
            setNotification({
              message: error.value.response.error,
              type: 'error'
            });
          }

        })
        .finally(() =>{
          finishSubmit();
        })
    } else {
      cleverTap.tapped.push({ 
        screen_name: CLEVERTAP_SCREEN_NAME.AUTO_EXTENSION,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_CONFIRM,
        offers_type: CLEVERTAP_ADDITIONAL.TYPE_DEALS
      });
      deactivateDeal(id, deactivation_reasons.reason, deactivation_reasons.note)
        .then( () => {
          setProductInactive(id)
          onSucess();
        })
        .catch(error=>{
          if (window.navigator.onLine === false) {
            onSucess();
            handleNoInternetError();
          } else {
            setNotification({
              message: error.value.response.error,
              type: 'error'
            });
          }
        })
        .finally(() =>{
          finishSubmit();
        })
    }

  }

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ 
      screen_name: CLEVERTAP_SCREEN_NAME.AUTO_EXTENSION,
      offers_type: type === 'ecards' ? CLEVERTAP_ADDITIONAL.TYPE_ECARDS : CLEVERTAP_ADDITIONAL.TYPE_DEALS
    });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed, type]);

  useEffect(() => {
    let isMounted = true;

    const getListingApi = type === 'ecards' ? getAutoExtendEcards : getAutoExtendDeals;

    if (user && uid) {
      getListingApi({
        uid: uid
      })
        .then(response => {
          if (!isMounted) return;
          setDateData(response.date);
          setCompanyNameData(response.company_name);
          setCompanyLogoData(response.company_logo);
          setDeactivateButtonVisible(response.show_deactivate_button || false);
          setProductsData(response.product_list || []);
        })
        .catch(err => {
          if (window.navigator.onLine === false) {
            handleNoInternetError();
          } else {
            const statusCode = err.value.response.code;
            setErrorPageVisible(true);
            if (statusCode === 410) setErrorTypeData('no_available');
            else setErrorTypeData('no_access');
          }
        });
    } else {
      setErrorPageVisible(true);
      setErrorTypeData('no_access');
    }

    return () => {(isMounted = false)};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, uid, type]);

  type productsProps = {
    productsData: ProductType[];
  };
  const ProductsListing: React.FC<productsProps> = props => {
      return (
        <>
          <div>
            {type === 'deals'
              ? props.productsData.map(
                  ({
                    id,
                    name,
                    purchase_count,
                    image_url,
                    original_price,
                    discounted_price,
                    is_active
                  }) => (
                    <DealCard
                      key={id}
                      id={id}
                      name={name}
                      purchaseCount={purchase_count}
                      imageUrl={image_url}
                      originalPrice={original_price}
                      discountedPrice={discounted_price}
                      deactivateVisible={deactivateButtonVisible}
                      isActive={is_active}
                      handleDeactivation={handleDeactivation}
                    />
                  )
                )
              : props.productsData.map(
                  ({
                    id,
                    background_color,
                    purchase_count,
                    original_price,
                    total_value,
                    bonus_credit_amount,
                    theme,
                    is_active
                  }) => (
                    <ECard
                      key={id}
                      id={id}
                      companyName={companyNameData}
                      purchaseCount={purchase_count}
                      originalPrice={original_price}
                      totalValue={total_value}
                      bonusCreditAmount={bonus_credit_amount}
                      deactivateVisible={deactivateButtonVisible}
                      isActive={is_active}
                      item={
                        {
                          id: id,
                          sold_count: purchase_count,
                          company_logo: companyLogoData,
                          company_name: companyNameData,
                          payable_amount: original_price,
                          bonus_credit_amount: bonus_credit_amount ? "Bonus "+bonus_credit_amount : '',
                          total_value: total_value,
                          theme: theme,
                          background_color: background_color
                        }
                      }
                      handleDeactivation={handleDeactivation}
                    />
                  )
                )}
          </div>
        </>
      );
  };

  if (errorPageVisible) return <ErrorPage errorType={errorTypeData} />;
  else
    return (
      <AppLayout>
        {!Boolean(productsData.length) ? (
          <LoadingSpinner />
        ) : (
          <>
            <header>
              <h2 className={style.title}>{renderTitle(type, dateData)}</h2>
              <div className={style.description}>
                <div>
                  {type === 'ecards'
                    ? t('Here is a list of eCards that were auto-extended.')
                    : t('Here is a list of Deals that were auto-extended.')}
                </div>
                {deactivateButtonVisible && (
                  <div>
                    {t(
                      'If you wish to deactivate any of them, click on the button to proceed.'
                    )}
                  </div>
                )}
                <div>
                  {t(
                    'If you would like to have a chat with one of our Fave teammates, click'
                  )}{' '}
                  {
                    <InlineLink
                      to={isWebview ? '' : '/contact-us'}
                      onClick={onClickContactUs}
                      children={t('here')}
                      className={style.inlineLink}
                      event={{
                        screen_name: CLEVERTAP_SCREEN_NAME.AUTO_EXTENSION,
                        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_HELP_CONTACT
                      }}
                    />
                  }
                  .
                </div>
              </div>
            </header>
            <ProductsListing productsData={productsData} />
          </>
        )}
      </AppLayout>
    );
};

export default OfferDeactivateView;
