import Kfit from '../integrations/Kfit';

export type StatementOfAccountParams = {
  company_id: number;
  outlet_id?: number | null;
  page?: number;
  per_page?: number;
};

export type StatementOfAccountBank = {
  finance_account_id: 1873;
  bank_name: string;
  bank_account_number: string;
  outlets_name: string[];
  last_updated: string;
};

export type StatementOfAccountResponse = {
  page: number;
  accounts_detail: StatementOfAccountBank[];
  total_count: number;
};

export default ({
  company_id,
  outlet_id = null,
  page = 1,
  per_page = 20
}: StatementOfAccountParams) => {
  const payload = { company_id, outlet_id, page, per_page };
  return Kfit.get(`/v8/statements/bank_accounts`, payload)
    .then(res => {
      res.page = page;
      return res;
    })
    .catch(error => error);
};
