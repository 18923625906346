import React from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { format, parseISO, isValid } from 'date-fns';

import { CancelButton } from 'components/Button';
import Card from 'components/Card';
import Icon from 'components/Icon';

import styles from 'assets/css/TransactionCard.module.scss';

type TransactionCardProps = {
  dateCreated: string;
  dateCancelled: string;
  isCancelled: boolean;
  receiptId: string;
  billAmount: string;
  customerName: string;
  onCancelledClick: () => void;
  className: string;
  onCancelledIsLoading: boolean;
  cancelledBy: string;
  isCancellable: boolean;
};

const TransactionCard: React.FC<TransactionCardProps> = props => {
  const {
    className = null,
    dateCreated = '',
    dateCancelled = '',
    isCancelled = false,
    isCancellable,
    receiptId = '',
    billAmount = '',
    customerName = '',
    onCancelledClick = undefined,
    onCancelledIsLoading = undefined,
    cancelledBy = ''
  } = props;

  const { t } = useTranslation();

  const formatDate = (dateObject: string) => {
    const dateInISOFormat = parseISO(dateObject);

    if (isValid(dateInISOFormat)) {
      return format(dateInISOFormat, 'MMM d, yyy, h:mm:ss a');
    }
    return null;
  };

  const successDateOfTransaction = dateCreated && formatDate(dateCreated);

  const cancelledDateOfTransaction = dateCancelled && formatDate(dateCancelled);

  return (
    <Card className={classNames(className, styles.receiptCard)}>
      <div className={styles.successContainer}>
        <Icon icon="check" color="green" size="1.125rem" />
        <div className={styles.successText}>
          {t('Paid on {{successDateOfTransaction}}', {
            successDateOfTransaction
          })}
        </div>
      </div>
      {isCancelled && (
        <div className={styles.cancelledContainer}>
          <Icon icon="times" color="watermelon-red" size="1.125rem" />
          <div className={styles.cancelledText}>
            {cancelledBy ? (
              <Trans>
                Cancelled by <strong>{{ cancelledBy }}</strong> on{' '}
                {{ cancelledDateOfTransaction }}
              </Trans>
            ) : (
              <Trans>Cancelled on {{ cancelledDateOfTransaction }}</Trans>
            )}
          </div>
        </div>
      )}
      <div className={styles.receiptTitle}>{t('Receipt ID')}</div>
      <div className={styles.receiptValue}>{receiptId}</div>
      <div className={styles.divider} />
      <div className={styles.receiptTitle}>{t('Total Bill')}</div>
      <div className={styles.receiptValue}>{billAmount}</div>
      <div className={styles.divider} />
      <div className={styles.receiptTitle}>{t('Paid by')}</div>
      <div className={styles.receiptValue}>{customerName || '-'}</div>
      <div className={styles.divider} />
      {!isCancelled && isCancellable && (
        <CancelButton
          type="skeleton"
          className={styles.cancelTransactionButton}
          isLoading={onCancelledIsLoading}
          onClick={onCancelledClick}
        >
          {t('Cancel Transaction')}
        </CancelButton>
      )}
    </Card>
  );
};

export default TransactionCard;
