import React, { useState, useCallback } from 'react';

const HubSpotContext = React.createContext();

function HubSpotContextProvider(props) {
  const [isLoaded, setIsLoaded] = useState(false);

  const trackRoute = useCallback(
    route => {
      // https://developers.hubspot.com/docs/methods/tracking_code_api/tracking_code_overview
      const _hsq = (window._hsq = window._hsq || []);

      // The domain is set automatically based on the URL of the page on load,
      // and the path that is set using setPath is always treated
      // as relative to that detected domain.
      // https://developers.hubspot.com/docs/methods/tracking_code_api/set_page_path

      // runs for first and subsequent page views
      _hsq.push(['setPath', route]);

      // only runs for subsequent page views after tracking code loaded
      if (isLoaded) _hsq.push(['trackPageView']);

      // analytics updates every 20 minutes. don't panic if you don't see it right away :)
      // https://knowledge.hubspot.com/reports/how-often-do-analytics-in-hubspot-update?KBOpenTab
    },
    [isLoaded]
  );

  (function() {
    if (isLoaded) return;

    // While trackPageView cannot be prevented when tracking code loads,
    // you can control the URL recorded for the page
    // by pushing a setPath call into _hsq before the tracking code is loaded.
    // https://developers.hubspot.com/docs/methods/tracking_code_api/track_page_view
    trackRoute('/');

    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.onload = () => setIsLoaded(true);
    script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUB_ID}.js`;
    document.body.appendChild(script);
  })();

  return (
    <HubSpotContext.Provider value={{ trackRoute, isLoaded }}>
      {props.children}
    </HubSpotContext.Provider>
  );
}

export { HubSpotContext, HubSpotContextProvider };
