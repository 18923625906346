import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LoadingSpinner from 'components/LoadingSpinner';
import styles from 'insights/assets/css/InsightsCard.module.scss';

const InsightsCard = props => {
  const hasData = props.swrResponse.data;
  const hasError = props.swrResponse.error;
  const isLoading = !(hasError || hasData);
  return (
    <article
      data-insights-card
      style={{
        '--gradientLeft': `var(--${props.gradientLeft})`,
        '--gradientRight': `var(--${props.gradientRight})`
      }}
      className={classnames(
        styles.insightsCard,
        props.column2 && styles.column2,
        hasError && styles.errorTheme,
        isLoading && styles.isLoading
      )}
    >
      {props.defaultHeader && (
        <header className={styles.insightsHeading}>
          <h3
            style={{ '--subheading-color': `var(--${props.subheadingColor})` }}
          >
            {props.subheading}
          </h3>
          <h2>{props.heading}</h2>
        </header>
      )}
      {hasError ? (
        <div className={styles.error}>
          <img
            src={require('insights/assets/images/alert.svg')}
            alt="No data found"
          />
          <p>
            No meaningful data found,
            <br />
            please select another date range.
          </p>
        </div>
      ) : hasData ? (
        props.children
      ) : (
        <LoadingSpinner />
      )}
    </article>
  );
};

InsightsCard.propTypes = {
  gradientRight: PropTypes.string,
  gradientLeft: PropTypes.string,
  subheading: PropTypes.string,
  subheadingColor: PropTypes.string,
  heading: PropTypes.string,
  column2: PropTypes.bool,
  defaultHeader: PropTypes.bool,
  swrResponse: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  })
};

InsightsCard.defaultProps = {
  gradientLeft: 'brilliant-rose',
  gradientRight: 'salmon',
  subheadingColor: 'salmon',
  column2: false,
  defaultHeader: true,
  swrResponse: { data: [], error: undefined }
};

export default InsightsCard;
