import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames';

import AppLayout from 'layouts/AppLayout';
import useCleverTapOld from 'hooks/useCleverTapOld';
import useTriggeredText from 'hooks/useTriggeredText';

import { OutletContext } from 'contexts/OutletContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { getOutlet } from 'queries/getOutlet';
import {
  resetAccessCode,
  setCancellationCode,
  updateRequireCancellation
} from 'commands/outletCodes';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Card from 'components/Card';
import { useTranslation } from 'react-i18next';
import styles from 'assets/css/OutletInfoView.module.scss';
import { QueryStringContext } from 'contexts/QueryStringContext';
import outletImagePlaceholder from 'assets/images/banners/ic-business-img-placeholder.svg';
import MissingOutletImgTip from 'components/MissingOutletImgTip';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';
import { copyToClipboard } from 'utils';
import { MapleContext } from 'contexts/MapleContext';

const OutletInfo = props => {
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const [outlet, setOutlet] = useState();
  const [currentOutlet, setCurrentOutlet] = useState();
  const [toggleCancellationCode, setToggleCancellationCode] = useState(false);
  const [toggleCancellationForm, setToggleCancellationForm] = useState(false);
  const { outlets } = useContext(OutletContext);
  const { setNotification } = useContext(NotificationContext);
  const { from } = useContext(QueryStringContext);
  const hasCancellationCode = code => (code || '').length !== 0;
  const cleverTap = useCleverTapOld();

  const {
    text: shareCodeText,
    trigger: triggerShareCodeText
  } = useTriggeredText('Share Code', 'Code copied', 4);

  const [sentCTEvent, setSentCTEvent] = useState(false);
  const isFavePayPage = window.location.pathname.includes('/favepay');
  const screenName = isFavePayPage
    ? CLEVERTAP_SCREEN_NAME.FAVEPAY_VIEW_INFO
    : CLEVERTAP_SCREEN_NAME.OUTLET_VIEW_INFO;

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({
      screen_name: screenName
    });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent, screenName]);

  const updateCancellationCode = ({ code }, formikBag) => {
    setCancellationCode(outlet.id, { cancellation_code: code }).then(data => {
      const { cancellation_code } = data;
      setOutlet({ ...outlet, cancellation_code });
      setToggleCancellationForm(false);
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      setNotification({ message: t('Code updated'), type: 'success' });
    });
  };

  const validateCode = code => {
    let error;
    if (code.length !== 6) {
      error = t('Code should be 6 characters long');
    }
    if (isNaN(code)) {
      error = t('Enter a valid number');
    }
    return error;
  };

  const resetAuthCode = _ => {
    resetAccessCode(outlet.id).then(data => {
      setOutlet({ ...outlet, auth_code: data.mobile_access_code });
      cleverTap.tapped.push({
        screen_name: screenName,
        tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_RENEW_OUTLETCODE
      });
    });
  };

  const copyPartnerCode = () => {
    cleverTap.tapped.push({
      screen_name: screenName,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_SHARE_OUTLETCODE
    });

    copyToClipboard({
      text: outlet.auth_code,
      onDone: () => triggerShareCodeText()
    });
  };

  const randomNumberGenerator = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };

  const outletImage = () => {
    if (!currentOutlet) return null;

    return currentOutlet.upload_image_urls &&
      currentOutlet.upload_image_urls.length > 0
      ? currentOutlet.upload_image_urls[0]
      : null;
  };

  useEffect(() => {
    getOutlet(props.id).then(data => {
      setOutlet(data);
      if (data.require_cancellation_code === true) {
        setToggleCancellationCode(true);
      }
      const currentSelectedOutlet =
        outlets && outlets.find(data => data.id === parseInt(props.id));

      //stored outlets do not have web_image_url
      if (currentSelectedOutlet)
        currentSelectedOutlet.web_image_url = data.web_image_url;

      setCurrentOutlet(currentSelectedOutlet);
    });
  }, [props.id, outlets]);

  const ouletImage = outletImage();

  return (
    <AppLayout>
      <Heading
        title={t('Your Outlets')}
        subtitle={t('Your Business Information')}
      />
      <Button
        leftIcon="arrow-left"
        href={from ? `/${from}` : '/outlets'}
        type="text"
        children={t('Back to outlets')}
      />

      {!isMaple && !ouletImage && !isFavePayPage && (
        <MissingOutletImgTip screenName="outlet_view_info" />
      )}

      {outlet && currentOutlet && (
        <>
          {}
          <div
            className={styles.outletInfoEnvelope}
            style={{
              '--bg': `url(${ouletImage || outletImagePlaceholder})`
            }}
          >
            <div className={styles.outletInfoContainer}>
              <div className={styles.outletInfoContent}>
                <div className={styles.outletInfoLeft}>
                  <h3 className={styles.outletTitle}>
                    {outlet.name}
                    {isMaple && currentOutlet.neighbourhood_slug ? 
                      <span className={styles.outletNeighbourhoodSlug}> | {currentOutlet.neighbourhood_slug}</span> :
                      <></>
                    }
                  </h3>
                  <div className={styles.outletAddress}>
                    <h5 className={styles.labelTitle}>{t('Address')}</h5>
                    {outlet.address}
                  </div>
                  <div className={styles.outletEmail}>
                    <h5 className={styles.labelTitle}>{t('Email')}</h5>
                    {outlet.email}
                  </div>
                  <div className={styles.outletPhone}>
                    <h5 className={styles.labelTitle}>{t('Phone')}</h5>
                    {outlet.primary_telephone}
                  </div>
                </div>
                <div className={styles.outletInfoRight}>
                  <Card
                    className={classNames(
                      styles.card,
                      styles.outletAuthorization
                    )}
                  >
                    <h5 className={styles.labelBoxTitle}>
                      {t('Partner Outlet Code')}
                    </h5>
                    <span className={styles.labelBoxDescription}>
                      {t('Use this code to log into Staff Mode')}
                    </span>
                    <div className={styles.authCodeAndFavepayInactiveBanner}>
                      <div className={styles.authorizationCode}>
                        {outlet.auth_code}
                      </div>
                      {!currentOutlet.has_fave_payment && (
                        <div className={styles.favePayInactive}>
                          {t('FavePay inactive')}
                        </div>
                      )}
                    </div>
                    <div className={styles.partnerCodeButtons}>
                      <Button
                        className={styles.renewCodeButton}
                        type="primary"
                        onClick={() => resetAuthCode()}
                      >
                        {t('Renew')}
                      </Button>
                      <Button
                        className={styles.renewCodeButton}
                        type="primary"
                        onClick={() => copyPartnerCode()}
                      >
                        {t(shareCodeText)}
                      </Button>
                    </div>
                  </Card>
                  {!isMaple && (
                    <>
                      <Card
                        className={classNames(
                          styles.card,
                          styles.outletCancellation
                        )}
                      >
                        <div className={styles.outletCancellationTop}>
                          <div className={styles.cancellationTextAndButton}>
                            <h5 className={styles.labelBoxTitle}>
                              {t('Cancellation Code')}
                            </h5>
                            <div className={styles.cancellationCheckBox}>
                              <input
                                type="checkbox"
                                id={'cancelCheck'}
                                className={styles.checkLabel}
                                checked={toggleCancellationCode}
                                onChange={() => {
                                  if (!outlet.cancellation_code) {
                                    setCancellationCode(outlet.id, {
                                      cancellation_code: randomNumberGenerator()
                                    }).then(data => {
                                      const { cancellation_code } = data;
                                      setOutlet({
                                        ...outlet,
                                        cancellation_code
                                      });
                                      cleverTap.tapped.push({
                                        screen_name: screenName,
                                        tapped_on:
                                          CLEVERTAP_TAPPED_ON.BUTTON_ENABLE_CODE
                                      });
                                      setNotification({
                                        message: t(
                                          'We have generated a code for you.'
                                        ),
                                        type: 'success'
                                      });

                                      updateRequireCancellation(outlet.id, {
                                        require_cancellation_code: true
                                      });
                                    });
                                    setToggleCancellationCode(
                                      !toggleCancellationCode
                                    );
                                  } else {
                                    setToggleCancellationCode(
                                      !toggleCancellationCode
                                    );
                                    updateRequireCancellation(outlet.id, {
                                      require_cancellation_code: !toggleCancellationCode
                                    }).then(res => {
                                      res.require_cancellation_code === true
                                        ? cleverTap.tapped.push({
                                            screen_name: screenName,
                                            tapped_on:
                                              CLEVERTAP_TAPPED_ON.BUTTON_ENABLE_CODE
                                          })
                                        : cleverTap.tapped.push({
                                            screen_name: screenName,
                                            tapped_on:
                                              CLEVERTAP_TAPPED_ON.BUTTON_DISABLE_CODE
                                          });
                                      res.require_cancellation_code === true
                                        ? setNotification({
                                            message:
                                              'Cancellation code enabled',
                                            type: 'success'
                                          })
                                        : setNotification({
                                            message:
                                              'Cancellation code disabled',
                                            type: 'warning'
                                          });
                                    });
                                    setToggleCancellationForm(false);
                                  }
                                }}
                              />

                              <label
                                htmlFor="cancelCheck"
                                onClick={() => setToggleCancellationForm(false)}
                              />
                              <div className={styles.toggleLabel}>
                                {toggleCancellationCode
                                  ? t('Enabled')
                                  : t('Disabled')}
                              </div>
                            </div>
                          </div>
                        </div>
                        {!toggleCancellationForm && (
                          <div className={styles.cancellationCodeSection}>
                            <>
                              <div
                                className={classNames(
                                  toggleCancellationCode &&
                                    styles.cancelEditActive,
                                  styles.cancellationCode,
                                  toggleCancellationForm && styles.hidden
                                )}
                              >
                                {hasCancellationCode(
                                  outlet.cancellation_code
                                ) ? (
                                  outlet.cancellation_code
                                ) : (
                                  <div className={styles.cancelText}>
                                    {t(
                                      'Turn on this feature if you want to enable the cancellation code on your outlet'
                                    )}
                                  </div>
                                )}
                              </div>
                              {outlet.cancellationCode !== '' && (
                                <Button
                                  className={classNames(
                                    styles.resetCancellationCodeButton,
                                    !toggleCancellationCode && styles.hidden
                                  )}
                                  type="primary"
                                  rightIcon="reload"
                                  onClick={() => {
                                    cleverTap.tapped.push({
                                      screen_name: screenName,
                                      tapped_on:
                                        CLEVERTAP_TAPPED_ON.BUTTON_RESET_CANCELLATION_CODE
                                    });
                                    setToggleCancellationForm(true);
                                  }}
                                >
                                  {t('Reset')}
                                </Button>
                              )}
                            </>
                          </div>
                        )}
                        {toggleCancellationForm && toggleCancellationCode && (
                          <div className={styles.cancellationForm}>
                            <Formik
                              validateOnChange={false}
                              initialValues={{ code: '' }}
                              onSubmit={updateCancellationCode}
                            >
                              {({ errors, touched }) => (
                                <Form>
                                  <label>
                                    <Field
                                      name="code"
                                      placeholder={t('Enter 6 digits number')}
                                      validate={validateCode}
                                      maxLength={6}
                                      className={styles.inputField}
                                      onBlur={() => {}}
                                    />
                                    {errors.code && touched.code && (
                                      <span className={styles.error}>
                                        {errors.code}
                                      </span>
                                    )}
                                  </label>
                                  <div
                                    className={styles.cancellationFormButtons}
                                  >
                                    <Button
                                      type="default"
                                      className={styles.formCancelButton}
                                      onClick={() =>
                                        setToggleCancellationForm(false)
                                      }
                                    >
                                      {t('Cancel')}
                                    </Button>
                                    <Button
                                      type="submit"
                                      className={styles.formSubmitButton}
                                    >
                                      {t('Save')}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        )}
                      </Card>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AppLayout>
  );
};

export default OutletInfo;
