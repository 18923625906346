import { useContext } from 'react';
import { useParams } from '@reach/router';
import moment from 'moment';

import { QueryStringContext } from 'contexts/QueryStringContext';

export default function useInsightsParams() {
  const { country = '', companyId } = useParams();
  const { outletId, startDate, endDate } = useContext(QueryStringContext);
  const isValidStartDate = moment(startDate || null).isValid();
  const isValidEndDate = moment(endDate || null).isValid();
  return {
    country: country.toLowerCase(),
    companyId,
    outletId,
    startDate: isValidStartDate
      ? moment(startDate)
      : moment().subtract(30, 'days'),
    endDate:
      isValidStartDate && isValidEndDate
        ? moment(endDate)
        : moment().subtract(1, 'days')
  };
}
