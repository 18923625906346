import React from 'react';
import classNames from 'classnames';

import styles from 'assets/css/FavePaySetupView/FormGrid.module.scss';

function FormGrid({ className, children }) {
  return (
    <section className={classNames(styles.formGrid, className)}>
      {children}
    </section>
  );
}

export default FormGrid;
