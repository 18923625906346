import React from 'react';
import { HistoryUnsubscribe, LocationProvider } from '@reach/router';
import {
  UseTranslationResponse,
  WithTranslation,
  withTranslation
} from 'react-i18next';
import { WebviewContextConsumer } from 'contexts/WebviewContext';
import Button from 'components/Button';
import styles from 'assets/css/ErrorBoundary.module.scss';

interface ErrorBoundaryProps extends WithTranslation {
  t: UseTranslationResponse['t'];
}

type ErrorBoundaryState = {
  error?: {
    message: string;
  } | null;
};

class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    error: null
  };

  unlisten: HistoryUnsubscribe | undefined = () => {};

  static getDerivedStateFromError(error: ErrorBoundaryState) {
    return { error: error };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleRejections);
    // @ts-ignore
    this.unlisten = LocationProvider.defaultProps.history?.listen(
      this.handleLocationChange
    );
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleRejections);
    this.unlisten?.();
  }

  componentDidCatchError(_error: any, _info: any) {
    /* Possibly log errors through Sentry here */
    // console.error(error)
  }

  handleLocationChange = () => {
    if (this.state.error) {
      this.setState({
        error: null
      });
    }
  };

  handleRejections = (event: PromiseRejectionEvent) => {
    event.promise.catch(errors => {
      this.setState({
        error: { message: errors.toString() }
      });
    });
  };

  render() {
    const { t } = this.props;

    if (!this.state.error) return this.props.children;

    const errorHandlers = ['Sid', 'Rash', 'Abhi', 'Vinz', 'Sammie', 'Idris'];
    const currentHandler =
      errorHandlers[Math.floor(Math.random() * errorHandlers.length)];
    return (
      <section className={styles.content}>
        <section className={styles.errorImage} />
        <h2 className={styles.errorHeader}>{t('Oh Snap!')}</h2>
        <p className={styles.errorDescription}>
          {t(
            'Something went wrong, {{currentHandler}} here is looking into it.',
            {
              currentHandler
            }
          )}
        </p>
        <WebviewContextConsumer>
          {props =>
            !props.isWebview && (
              <Button
                type="primary"
                size="lg"
                className={styles.button}
                href="/"
              >
                {t('Back to Dashboard')}
              </Button>
            )
          }
        </WebviewContextConsumer>
      </section>
    );
  }
}

export default withTranslation()(ErrorBoundary);
