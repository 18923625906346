import React, { useState, useEffect, useContext } from 'react';
import Measure from 'react-measure';
import { useTranslation } from 'react-i18next';
import { AddToHomescreenContext } from 'contexts/AddToHomescreenContext';
import { RESPONSIVE_BREAKPOINTS } from 'helpers/TypeConstants';
import Button, { CloseButton } from 'components/Button';

import styles from 'assets/css/AddToHomescreenPromo.module.scss';

const AddToHomescreenPromo: React.FC = () => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(0);
  const [renderComponent, setRenderComponent] = useState(true);
  const { isEnable, prompt, promptToInstall } = useContext(
    AddToHomescreenContext
  );

  useEffect(() => {
    prompt?.userChoice.then(choice => {
      if (choice.outcome) {
        setRenderComponent(false);
      }
    });
  }, [prompt]);

  const handleInstall = () => promptToInstall();

  const handleDismiss = () => setRenderComponent(false);

  const RenderPromo = ({ isSmall }: { isSmall: boolean }) => {
    return (
      <div className={styles.promoContainer}>
        <div className={styles.empty} />
        <span className={styles.promoText}>
          {isSmall && (
            <span className={styles.promoTitle}>
              FaveBiz <br />
            </span>
          )}
          {isSmall
            ? t(
                'Add this page to home page to access it faster. It won’t take up space in your phone.'
              )
            : t(
                'Gain faster access to your business on FaveBiz by installing our free app. It won’t take up space on your desktop.'
              )}
        </span>
        <Button
          type="skeleton"
          isRounded
          className={styles.promoInstallButton}
          onClick={handleInstall}
        >
          {isSmall ? t('Add') : t('Install')}
        </Button>
        <CloseButton
          className={styles.promoCloseButton}
          onClick={handleDismiss}
        />
      </div>
    );
  };

  return isEnable && renderComponent ? (
    <Measure
      bounds
      onResize={contentRect => {
        if (contentRect.bounds) {
          setScreenWidth(contentRect.bounds.width);
        }
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} className={styles.wrapper}>
          <RenderPromo isSmall={screenWidth <= RESPONSIVE_BREAKPOINTS.sm} />
        </div>
      )}
    </Measure>
  ) : (
    <></>
  );
};

export default AddToHomescreenPromo;
