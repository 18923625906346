import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from '@reach/router';

import useHubSpotTrack from 'hooks/useHubSpotTrack';
import { QueryStringContext } from 'contexts/QueryStringContext';
import { OutletContext } from 'contexts/OutletContext';
import { AuthContext } from 'contexts/AuthContext';

import AppLayout from 'layouts/AppLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Heading from 'components/Heading';
import Steps from 'components/Steps';
import { SELF_ONBOARDING_STATUS } from 'helpers/TypeConstants';

import GetQRCodeView from './GetQRCodeView';
import BusinessDetailsView from './BusinessDetailsView';
import StarterKitView from './StarterKitView';
import { useTranslation } from 'react-i18next';

import styles from 'assets/css/FavePaySetupView.module.scss';

function FavePaySetupView(props) {
  const { t } = useTranslation();
  const { state, pathname } = props.location;
  useHubSpotTrack(pathname);
  const { LINK_BANK_ACCOUNT, STARTER_KIT } = SELF_ONBOARDING_STATUS;
  const LINK_BANK_ACCOUNT_STEP_1 = 'LINK_BANK_ACCOUNT_STEP_1';
  const LINK_BANK_ACCOUNT_STEP_2 = 'LINK_BANK_ACCOUNT_STEP_2';

  const { step: queryStringStep } = useContext(QueryStringContext);
  const { outlets } = useContext(OutletContext);
  const { user } = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState();
  const companyOutlets = outlets || [];
  const firstOutlet = companyOutlets[0] || {};

  const isMY = user?.city?.slug === 'kuala-lumpur';

  useEffect(() => {
    if (currentStep) return;

    const getOnboardingStatus = outlet =>
      [outlet.self_onboarding_status].map(status =>
        status === LINK_BANK_ACCOUNT
          ? (queryStringStep === 2 && LINK_BANK_ACCOUNT_STEP_2) ||
            LINK_BANK_ACCOUNT_STEP_1
          : status
      )[0];

    setCurrentStep(getOnboardingStatus(firstOutlet));
  }, [currentStep, firstOutlet, queryStringStep, LINK_BANK_ACCOUNT]);

  const steps = [
    {
      text: isMY ? t('Create FavePay account') : t('Get QR Code'),
      value: LINK_BANK_ACCOUNT_STEP_1,
      View: GetQRCodeView,
      isComplete: true
    },
    {
      text: t('Enter business and bank details'),
      value: LINK_BANK_ACCOUNT_STEP_2,
      View: BusinessDetailsView
    },
    {
      text: t('Get a free starter kit'),
      value: STARTER_KIT,
      View: StarterKitView
    }
  ];

  const currentStepIndex = steps.findIndex(step => step.value === currentStep);
  const hasNoCurrentStep = currentStepIndex === -1;
  const CurrentStepView = (steps[currentStepIndex] || {}).View;

  const onNextStep = () => {
    const nextStep = steps[currentStepIndex + 1];
    if (nextStep) setCurrentStep(nextStep.value);
  };

  const isLoadingOutlets = !currentStep;

  return (
    <AppLayout>
      <Heading
        title={t('Set up Payment')}
        subtitle={t(
          'Please fill up your business info in order to receive weekly payments from us.'
        )}
      />
      {isLoadingOutlets ? (
        <LoadingSpinner />
      ) : hasNoCurrentStep ? (
        <Redirect to="/favepay" noThrow />
      ) : (
        <section className={styles.layout}>
          <Steps
            className={styles.steps}
            steps={steps}
            currentStep={currentStep}
          />
          {CurrentStepView && (
            <CurrentStepView
              state={state}
              outlet={firstOutlet}
              onSubmit={onNextStep}
              isMY={isMY}
            />
          )}
        </section>
      )}
    </AppLayout>
  );
}

export default FavePaySetupView;
