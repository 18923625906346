import Kfit from '../integrations/Kfit';

export const postFaveDealsReply = params =>
  Kfit.post('/v6/reviews/reply', params);
export const postFavePayReply = params =>
  Kfit.post('/v6/reviews/fave_payment/reply', params);
export const putFaveDealsSeenReview = (params, reviewId) =>
  Kfit.put(`/v6/reviews/${reviewId}`, params);
export const putFavePaySeenReview = (params, reviewId) =>
  Kfit.put(`/v6/fave_payment_reviews/${reviewId}`, params);
