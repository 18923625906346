import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';

import LoginLayout from 'layouts/LoginLayout';
import Cookie from 'helpers/Cookie';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import getOpsSettings from 'queries/getOpsSettings';
import { NotificationContext } from 'contexts/NotificationContext';
import FormikErrorTimed from 'components/FormikErrorTimed';
import OutOfBoundClick from 'components/OutOfBoundClick';
import { ModalContext } from 'contexts/ModalContext';
import staffHelps from 'assets/images/opsLogin/staff-help.svg';
import SGFlag from 'assets/images/countries/sg-flag-small.svg';
import MYFlag from 'assets/images/countries/my-flag-small.svg';
import IDFlag from 'assets/images/countries/id-flag-small.svg';

import styles from 'assets/css/OpsLoginView.module.scss';
import { MapleContext } from 'contexts/MapleContext';
import NoInternetModal from 'components/NoInternetModal';

const OpsLoginView = props => {
  const { isMaple } = useContext(MapleContext);
  const { t } = useTranslation();
  const { setNotification } = useContext(NotificationContext);
  const { init, close } = useContext(ModalContext);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'login_staff' });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap]);

  const RenderSupportHelp = ({ country }) => {
    const getContactByCountry = country =>
      ({
        Malaysia: {
          icon: MYFlag,
          email: 'partners.my@myfave.com'
        },
        Singapore: {
          icon: SGFlag,
          email: 'partners.sg@myfave.com'
        },
        Indonesia: {
          icon: IDFlag,
          email: 'pm.id@myfave.com'
        }
      }[country]);

    const { email, icon } = getContactByCountry(country);

    return (
      <>
        <div className={styles.countryFlag}>
          <img src={icon} alt={`${country}-flag`} />
          <p className={styles.countryName}>{country}</p>
        </div>
        <Button
          type="ghost"
          className={styles.modalButton}
          href={`mailto:${email}`}
        >
          {email}
        </Button>
      </>
    );
  };

  const RenderIDKPartnerCodeModal = () => {
    return (
      <OutOfBoundClick onClick={() => close()} className={styles.modal}>
        <img
          src={staffHelps}
          alt="Staff Helps in Counter"
          className={styles.staffIcon}
        />
        <p className={styles.modalTitle}>{t('Oops, need help?')}</p>
        <p className={styles.modalDesc}>
          {t(
            'If you don’t know your partner code, please contact your manager. Managers will be able to find the partner code for your outlet in:'
          )}
        </p>
        <div className={styles.box}>
          <p>FaveBiz website</p>
          <p>
            {t(
              isMaple
                ? 'Go to My Business > Outlets > View Details > Partner Outlet Code.'
                : 'Go to My Business > Outlets > View Info > Partner Outlet Code.'
            )}
          </p>
        </div>
        {!isMaple && (
          <div>
            <p className={styles.modalDesc}>
              {t(
                'If you require more help, please don’t hesitate to contact us at:'
              )}
            </p>
            <div className={styles.contactSupport}>
              <RenderSupportHelp country="Malaysia" />
              <RenderSupportHelp country="Singapore" />
              <RenderSupportHelp country="Indonesia" />
            </div>
          </div>
        )}
      </OutOfBoundClick>
    );
  };

  const openIDKPartnerCodeModal = () => {
    return init({
      Component: RenderIDKPartnerCodeModal,
      openOnReady: true,
      hasCloseButton: true
    });
  };

  const submitForm = values => {
    setIsLoading(true);
    getOpsSettings(values.outletCode)
      .then(() => {
        Cookie.set('authCode', values.outletCode);
        window.location.assign('operations/transactions');
      })
      .catch(error => {
        if (window.navigator.onLine === false) {
          handleNoInternetError();
        } else {
          const errorMessage =
            error.value.response.error === 'Unauthorized.'
              ? t('That code doesn’t look right. Please try again')
              : t('{{error}}', { error: error.value.response.error });

          setNotification({
            message: errorMessage,
            type: 'error'
          });
          setFormError(errorMessage);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleNoInternetError = () => {
    init({
      Component: NoInternetModal,
      openOnReady: true,
      hasCloseButton: false,
      componentProps: {}
    });
  };

  return (
    <LoginLayout
      withHeader
      screenName={'staff'}
      backButtonProps={{ to: '/login' }}
      prevLocation={{ url: props.location.pathname }}
    >
      <Formik
        enableReinitialize
        initialValues={{ outletCode: '', isLoading }}
        validationSchema={Yup.object().shape({
          outletCode: Yup.string()
            .required(t('Please enter outlet code'))
            .matches(/^[A-Z0-9]+$/, t('Only alphanumeric codes allowed'))
        })}
        onSubmit={submitForm}
        validateOnBlur={false}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className={styles.form}>
            <div className={styles.formContent}>
              <TextInput
                {...{
                  type: 'text',
                  name: 'outletCode',
                  placeholder: t('Enter partner code (eg. SD93JK)'),
                  focus: true,
                  onChange: event =>
                    setFieldValue(
                      'outletCode',
                      typeof event.target.value === 'string' &&
                        event.target.value.toUpperCase()
                    ),
                  errors,
                  touched
                }}
              />
              <FormikErrorTimed error={formError} onTimeout={setFormError} />
              <Button
                type="ghost"
                className={styles.partnerCode}
                onClick={() => {
                  cleverTap.tapped.push({
                    screen_name: 'login_staff',
                    tapped_on: 'btn_help_partnercode'
                  });
                  openIDKPartnerCodeModal();
                }}
              >
                <p>{t('I don’t know my partner code')}</p>
              </Button>
            </div>
            <Button
              type="submit"
              size="lg"
              rightIcon="arrow-right"
              justify="space-between"
              isLoading={values.isLoading}
              onClick={() =>
                cleverTap.tapped.push({
                  screen_name: 'login_staff',
                  tapped_on: 'btn_continue'
                })
              }
              fullWidth
              disabled={values.outletCode.length === 0}
            >
              {t('Log in')}
            </Button>
          </Form>
        )}
      </Formik>
    </LoginLayout>
  );
};

export default OpsLoginView;
