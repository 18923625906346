import Cookies from 'universal-cookie';
import root from 'window-or-global';

class Cookie {
  static DEFAULT_OPTIONS = {
    path: '/',
    secure: root.location.protocol === 'https:',
    domain: process.env.REACT_APP_COOKIE_DOMAIN || '',
    maxAge: 60 * 60 * 24 * 365 * 2
  };

  constructor() {
    this.instance = new Cookies();
  }

  set(key, value, options = {}) {
    return this.instance.set(key, value, {
      ...Cookie.DEFAULT_OPTIONS,
      ...options
    });
  }

  get(key, defaultValue) {
    const value = this.instance.get(key) || defaultValue;
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  }

  remove(key, options = {}) {
    return this.instance.remove(key, { ...Cookie.DEFAULT_OPTIONS, ...options });
  }
}

export default new Cookie();
