import React, { useEffect, useContext, useState } from 'react';
import { navigate } from '@reach/router';
import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import { OutletContext } from 'contexts/OutletContext';
import getFavePayRevenue from 'queries/getFavePayRevenue';
import { AuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import FaveCard from 'components/FaveCard';
import AddNewCard from 'components/AddNewCard';
import Grid from 'components/Grid';
import useCleverTapOld from 'hooks/useCleverTapOld';

const CashbackView = props => {
  const { t } = useTranslation();
  const { currentCompany, currentOutlet, outlets } = useContext(OutletContext);
  const [revenues, setRevenues] = useState([]);
  const { user } = useContext(AuthContext);

  const cleverTap = useCleverTapOld();
  const [sentCTEvent, setSentCTEvent] = useState(false);
  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'cashback_explore' });
    setSentCTEvent(true);
  }, [sentCTEvent, cleverTap.screenDisplayed]);

  useEffect(() => {
    if (user && user.city && currentCompany && currentCompany.outlets) {
      getFavePayRevenue({
        outlet_ids: currentOutlet
          ? currentOutlet.id
          : currentCompany.outlets.map(({ id }) => id).join(',')
      }).then(response => {
        setRevenues(response);
      });
    }
  }, [currentCompany, user, currentOutlet, outlets]);

  const CashbackViewLayout = () => {
    const hasNoCashback =
      outlets &&
      outlets.every(outlet => {
        return (
          outlet.fave_payment_outlet_detail.cashback_rate === null ||
          outlet.fave_payment_outlet_detail.cashback_rate === 0
        );
      });

    return (
      <>
        {outlets && !!revenues.length && (
          <Grid>
            {hasNoCashback && (
              <AddNewCard
                onClick={() => navigate('cashback/setup')}
                text={t('Add new Cashback')}
              />
            )}
            {/* currentOutlet = '' -> use outlets, currentOutlet = {} -> use [currentOutlet] */}
            {((currentOutlet && [currentOutlet]) || outlets).map(outlet => {
              const matchingRevenueData =
                (revenues || []).find(({ id }) => id === outlet.id) || {};

              return (
                outlet.fave_payment_outlet_detail.cashback_rate > 0 &&
                outlet.has_setup_cashback === true && (
                  <FaveCard
                    key={outlet.id}
                    status={`${outlet.fave_payment_outlet_detail.cashback_rate}% cashback`}
                    statusType="cashback"
                    outletImage={outlet.upload_image_urls[0]}
                    outletTitle={outlet.name}
                    revenueAmount={matchingRevenueData.revenue_amount || 0}
                    transactionType={t('Transactions')}
                    count={matchingRevenueData.count || 0}
                  />
                )
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  return (
    <AppLayout>
      <Heading
        title={t('Cashback')}
        subtitle={t(
          'Reward customers with irresistible cashback rates and turn more walk-ins into regulars'
        )}
      />
      <CashbackViewLayout />
    </AppLayout>
  );
};

export default CashbackView;
