import React, { useState, useEffect, useContext } from 'react';
import InsightsCard from 'insights/components/InsightsCard';
import ScrollToElement from 'insights/components/ScrollToElement';
import Card from '../../components/Card';
import styles from 'insights/assets/css/MoreServices.module.scss';
import useServicesUrls from 'hooks/useServicesUrls';
import useGetSettings from 'insights/queries/useGetSettings';
import { InsightsAuthContext } from 'insights/contexts/InsightsAuthContext';
import { hasData } from 'insights/utils';
import TappedButton from 'components/Button/TappedButton';

const MoreServices = ({ id, companyId }) => {
  const { user } = useContext(InsightsAuthContext);
  const servicesUrls = useServicesUrls(InsightsAuthContext, companyId);
  const response = useGetSettings();
  const [features, setFeatures] = useState();

  useEffect(() => {
    if (hasData(response.data)) {
      setFeatures(response.data.settings.features);
    }
  }, [response.data]);

  const ServiceCard = ({
    title,
    description,
    imgSource,
    href,
    reload,
    event
  }) => (
    <Card className={styles.serviceCard}>
      <img src={imgSource} alt={title} />
      <div className={styles.serviceCardContent}>
        <h3>{title}</h3>
        <p>{description}</p>
        <TappedButton
          className={styles.callToActionButton}
          type="text"
          event={{ screen_name: 'insight', tapped_on: event }}
          {...(reload
            ? { onClick: () => window.location.assign(reload) }
            : { href })}
        >
          Explore
        </TappedButton>
      </div>
    </Card>
  );
  return (
    <ScrollToElement id={id}>
      <InsightsCard
        defaultHeader={false}
        gradientLeft="summer-sky"
        gradientRight="emerald"
      >
        <header className={styles.moreServicesHeader}>
          <h2>Explore All Our Services</h2>
          <p>Want to know how Fave can help your business grow?</p>
        </header>
        {features && (
          <section className={styles.serviceCardContainer}>
            {!features.fave_payment && (
              <ServiceCard
                title="FavePay"
                description="Accept all forms of cashless payment easily, quickly at low cost"
                imgSource={require('assets/images/services/favebiz-payment.jpg')}
                href={servicesUrls.payments}
                event="btn_learnmore_favepay"
              />
            )}
            {!features.voucher && (
              <ServiceCard
                title="FaveDeals"
                description="Simple, low-cost, and effective customer acquisition solution"
                imgSource={require('assets/images/services/favebiz-deal.jpg')}
                href={servicesUrls.favedeals}
                reload={user && '/deals/enable'}
                event="btn_learnmore_favedeals"
              />
            )}
            {!features.cashback && (
              <ServiceCard
                title="Cashback"
                description="Reward customers with irresistible cashback rates and turn more walk-ins into regulars"
                imgSource={require('assets/images/services/favebiz-cashback.jpg')}
                href={servicesUrls.cashback}
                event="btn_learnmore_cashback"
              />
            )}
            {!features.ecards && (
              <ServiceCard
                title="e-Cards"
                description="Sell more, get paid upfront. It’s like gift cards, but better."
                imgSource={require('assets/images/services/img-e-cards.jpg')}
                href={servicesUrls.ecards}
                event="btn_learnmore_ecard"
              />
            )}
            {false && (
              <ServiceCard
                title="Financing"
                description="Get fast and easy access to loans"
                imgSource={require('assets/images/services/favebiz-financing.jpg')}
                href="https://www.favebiz.com/login/financing"
                reload={user && '/financing'}
                event="btn_learnmore_financing"
              />
            )}
            <ServiceCard
              title="Advertisement"
              description="Acquire new customers and grow your sales"
              imgSource={require('assets/images/services/favebiz-marketing.jpg')}
              href={servicesUrls.marketing}
              event="btn_learnmore_advertisement"
            />
          </section>
        )}
      </InsightsCard>
    </ScrollToElement>
  );
};

export default MoreServices;
