/**
 * This client does not natively support typescript and also does
 * not support unsubscribing from events which does not
 * work well with react cleanup in useEffect, hence this wrapper
 * also no support for settings as of yet.
 * this is NOT the classic widget
 */

type Callback = () => void;

declare global {
  interface Window {
    zE: (event: string, eventName: string, callback?: Callback) => void;
  }
}

const addCloseButton = false;
const hiddenButtonCssClass = 'chat-button-hidden';

const domElements: {
  chatWindowFrame?: HTMLIFrameElement;
  chatWindowContainer?: HTMLDivElement;
  chatWindowToggleButton?: HTMLIFrameElement;
  chatWindowToggleButtonContainer?: HTMLDivElement;
} = {};

let onOpenHandlers: Callback[] = [];
let onCloseHandlers: Callback[] = [];

const addOpenHandler = (callback: Callback) => onOpenHandlers.push(callback);
const addCloseHandler = (callback: Callback) => onCloseHandlers.push(callback);

const removeOpenHandler = (callback: Callback) =>
  (onOpenHandlers = onOpenHandlers.filter(item => item !== callback));
const removeCloseHandler = (callback: Callback) =>
  (onCloseHandlers = onCloseHandlers.filter(item => item !== callback));

const handleWidgetOpen = () => onOpenHandlers.forEach(callback => callback());
const handleWidgetClosed = () =>
  onCloseHandlers.forEach(callback => callback());

const closeWidget = () => window.zE('messenger', 'close');

const openWidget = () => {
  const { zE } = window;

  zE('messenger', 'open');

  if (addCloseButton) {
    // wait until window is initialized
    // to inject close button
    setTimeout(() => {
      const { chatWindowFrame } = domElements;

      const cssLink = document.createElement('link');
      cssLink.href = '/zendesk.css';
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      chatWindowFrame?.contentWindow?.document.head.appendChild(cssLink);

      const titleHeading = chatWindowFrame?.contentWindow?.document.querySelector(
        '[role="presentation"] h2'
      )!;

      const titleContainer = titleHeading.parentNode as HTMLDivElement;
      titleContainer.classList.add('chat-header');

      const closeButton = document.createElement('button');
      closeButton.classList.add('close-btn');
      closeButton.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><line x1="200" y1="56" x2="56" y2="200" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="200" y1="200" x2="56" y2="56" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>';
      closeButton.onclick = () => zE('messenger', 'close');

      titleContainer.prepend(closeButton);
    }, 400);
  }
};

const showWidgetButton = () =>
  domElements.chatWindowToggleButtonContainer?.classList.remove(
    hiddenButtonCssClass
  );
const hideWidgetButton = () =>
  domElements.chatWindowToggleButtonContainer?.classList.add(
    hiddenButtonCssClass
  );

const retry = (callback: Callback, timeout = 1000) => {
  setTimeout(() => {
    try {
      callback();
    } catch (err) {
      console.log('retrying...');
      retry(callback, timeout);
    }
  }, timeout);
};

const init = () => {
  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;

  script.onload = () => {
    const { zE } = window;
    zE('messenger', 'close');

    // bind event handlers
    zE('messenger:on', 'open', handleWidgetOpen);
    zE('messenger:on', 'close', handleWidgetClosed);

    console.log('Chat client init');

    //wait until widget is loaded
    retry(() => {
      const chatWindowFrame = document.querySelector(
        '[title="Messaging window"]'
      ) as HTMLIFrameElement;

      chatWindowFrame.classList.add('chat-container');

      const chatWindowContainer = chatWindowFrame.parentNode! as HTMLDivElement;
      chatWindowContainer.classList.add('z-chat');

      domElements.chatWindowFrame = chatWindowFrame;
      domElements.chatWindowContainer = chatWindowContainer;

      const chatWindowToggleButton = document.querySelector(
        'iframe[title="Button to launch messaging window"]'
      ) as HTMLIFrameElement;

      const chatWindowToggleButtonContainer = chatWindowToggleButton.parentNode! as HTMLDivElement;
      chatWindowToggleButtonContainer.classList.add(
        'chat-button-container',
        hiddenButtonCssClass
      );

      domElements.chatWindowToggleButton = chatWindowToggleButton;
      domElements.chatWindowToggleButtonContainer = chatWindowToggleButtonContainer;
    });
  };

  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=60c75924-e399-4754-9a21-2ca50c44eec1';

  document.head.appendChild(script);
};

export {
  init,
  openWidget,
  closeWidget,
  showWidgetButton,
  hideWidgetButton,
  addOpenHandler,
  addCloseHandler,
  removeOpenHandler,
  removeCloseHandler
};
