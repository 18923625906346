import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Redirect } from '@reach/router';
import * as Yup from 'yup';
import classNames from 'classnames';

import LoginLayout from 'layouts/LoginLayout';
import requestOTP from 'commands/requestOTP';
import verifyOTP from 'commands/verifyOTP';
import resetPIN from 'commands/resetPIN';
import { AuthContext } from 'contexts/AuthContext';
import { NotificationContext } from 'contexts/NotificationContext';
import LoginHeader from 'components/LoginHeader';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { useTranslation } from 'react-i18next';
import useCleverTapOld from 'hooks/useCleverTapOld';
import styles from 'assets/css/ResetPinView.module.scss';
import { isAppMapleBiz } from 'contexts/MapleContext';

const TIMEOUT_IN_MS = 8000;
const DISABLE_IN_MS = 3000;

const ResetPinView = props => {
  const { t } = useTranslation();
  const cleverTap = useCleverTapOld();
  const { values } = props.location.state || {};
  const { setNotification } = useContext(NotificationContext);
  const [login, setLogin] = useState();
  const [loginMethod, setLoginMethod] = useState('email');
  const [willRequestOTP, setWillRequestOTP] = useState(false);
  const [willVerifyOTP, setWillVerifyOTP] = useState(false);
  const [sentCTEvent, setSentCTEvent] = useState(false);

  useEffect(() => {
    values && setWillRequestOTP(true);
  }, [values]);

  useEffect(() => {
    if (sentCTEvent) return;
    cleverTap.screenDisplayed.push({ screen_name: 'pin_forgot' });
    setSentCTEvent(true);
  }, [cleverTap, sentCTEvent]);

  useEffect(() => {
    if (!willRequestOTP) return;
    let isMounted = true;
    setNotification();
    const { method, email, phone, code } = values;
    const valueBy = { email, phone: code + phone };
    const value = valueBy[method];

    requestOTP({ method, value, route: 'reset_pin' })
      .then(({ request_id }) => {
        setLogin({ request_id, value, fromMigration: true });
        setLoginMethod(method);
        setNotification({
          message: t(`Your OTP has been sent to {{value}}`, { value: value }),
          type: 'success',
          wait: TIMEOUT_IN_MS
        });
        setTimeout(() => isMounted && setWillRequestOTP(false), DISABLE_IN_MS);
      })
      .catch(error => {
        console.log(`requestOTP error:`, error);
      });

    return () => (isMounted = false);
  }, [values, willRequestOTP, setNotification, t]);

  const { updateUser } = useContext(AuthContext);

  if (!values) return <Redirect noThrow to="authenticate" />;

  const updatePin = values => {
    if (isNaN(values.pin) || isNaN(values.pin_confirm)) {
      setNotification({ message: t('Only numbers allowed'), type: 'error' });
    } else if (values.pin !== values.pin_confirm) {
      setNotification({ message: t('Pin does not match'), type: 'error' });
    } else {
      setWillVerifyOTP(true);
      verifyOTP({
        request_id: login.request_id,
        code: values.otp,
        method: loginMethod
      })
        .then(({ verified_token }) => {
          resetPIN({
            value: login.value,
            platform: 'mobile',
            pin_number: values.pin,
            verified_token,
            method: loginMethod
          })
            .then(updateUser)
            .catch(({ value }) => {
              setWillVerifyOTP(false);
              setNotification({
                message: isAppMapleBiz
                  ? value.response.message
                  : value.response.error,
                type: 'error'
              });
            });
        })
        .catch(({ value }) => {
          setWillVerifyOTP(false);
          setNotification({
            message: isAppMapleBiz
              ? value.response.message
              : value.response.error,
            type: 'error'
          });
        });
    }
  };
  const shouldDisable = willRequestOTP || willVerifyOTP;
  return (
    <LoginLayout
      backButtonProps={{
        to: '/authenticate',
        className: classNames(styles.button, styles.backButton)
      }}
    >
      <LoginHeader className={styles.header}>{t('Reset PIN')}</LoginHeader>
      <p className={styles.paragraph}>
        {t('Enter your OTP and set a new PIN.')}
      </p>
      {login && (
        <Formik
          initialValues={{ otp: '', pin: '', pin_confirm: '' }}
          validationSchema={Yup.object().shape({
            otp: Yup.string()
              .typeError('Invalid OTP')
              .length(6, t(`Invalid OTP, should be at least 6 digits long`))
              .required(t('OTP is required')),
            pin: Yup.string()
              .typeError('Invalid PIN')
              .length(6, t(`Invalid pin, should be at least 6 digits long`))
              .required(t('PIN is required')),
            pin_confirm: Yup.string().required(
              t('Confirmation PIN is required')
            )
          })}
          onSubmit={updatePin}
        >
          {({ errors, touched, values }) => (
            <Form className={styles.form}>
              <TextInput
                {...{
                  type: 'text',
                  name: 'otp',
                  placeholder: t('Enter OTP'),
                  maxLength: 6,
                  focus: true,
                  errors,
                  touched
                }}
              />
              <TextInput
                {...{
                  type: 'password',
                  name: 'pin',
                  placeholder: t('Enter your new 6-digit app pin'),
                  maxLength: 6,
                  errors,
                  touched
                }}
              />
              <TextInput
                {...{
                  type: 'password',
                  name: 'pin_confirm',
                  placeholder: t('Re-enter your new 6-digit app pin'),
                  maxLength: 6,
                  errors,
                  touched
                }}
              />
              {values.pin_confirm.length === 6 && (
                <div className={styles.errorPinValue}>
                  {(isNaN(values.pin) || isNaN(values.pin_confirm)) && (
                    <p>{t('Only numbers allowed')}</p>
                  )}
                  {values.pin !== values.pin_confirm && (
                    <p>{t('Pin does not match')}</p>
                  )}
                </div>
              )}
              <Button
                type="submit"
                size="lg"
                rightIcon="arrow-right"
                justify="space-between"
                disabled={shouldDisable}
                onClick={() =>
                  cleverTap.tapped.push({
                    screen_name: 'pin_forgot',
                    tapped_on: 'btn_reset_pin'
                  })
                }
              >
                {t('Reset my PIN')}
              </Button>
            </Form>
          )}
        </Formik>
      )}
      <p className={styles.resendOTP}>
        <span>{t("Didn't receive your OTP?")}</span>
        <Button
          type="text"
          textColor="apple-green"
          disabled={shouldDisable}
          onClick={() => {
            cleverTap.tapped.push({
              screen_name: 'pin_forgot',
              tapped_on: 'btn_otp_resend'
            });
            setWillRequestOTP(true);
          }}
        >
          {t('Resend')}
        </Button>
      </p>
    </LoginLayout>
  );
};

export default ResetPinView;
