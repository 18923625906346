import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ImageCard, { StatusProps } from 'components/ImageCard';
import TappedButton from 'components/Button/TappedButton';

import styles from '../assets/css/DealCard.module.scss';

type DealCardProps = {
  headerImage: string;
  status: StatusProps;
  className: string;
  currency: string;
  dealName: string;
  dealID: number;
  revenueAmount: string | number;
  revenueCount: number;
  filter?: {
    date: string;
    type: string;
  };
  startDate: string;
  endDate: string;
};

const DealCard: React.FC<DealCardProps> = props => {
  const {
    currency,
    headerImage,
    status = 'inactive',
    className,
    dealName,
    dealID,
    revenueAmount = 0,
    revenueCount = 0,
    filter,
    startDate,
    endDate
  } = props;
  const { t } = useTranslation();

  const dateQueryString = () => {
    if (!filter || !startDate || !endDate) return;
    return filter.date === 'custom'
      ? `?customStartDate=${encodeURIComponent(
          startDate
        )}&customEndDate=${encodeURIComponent(endDate)}&type=${
          filter.type
        }&date=${filter.date}`
      : `?date=${encodeURIComponent(filter.date)}&type=${filter.type}`;
  };

  return (
    <ImageCard
      className={classNames(className, styles.dealCard)}
      headerImage={headerImage}
      status={status}
    >
      <section className={styles.contentContainer}>
        <h4 className={styles.smallTitle}>
          {t('Deal ID:')} {dealID}
        </h4>
        <h3 className={styles.title}>{dealName}</h3>
        <dl>
          <dt className={styles.smallTitle}>
            {t('Revenue')} ({currency})
          </dt>
          <dd className={styles.title}> {revenueAmount}</dd>
          <dt className={styles.smallTitle}>{t('Redemptions')}</dt>
          <dd className={styles.title}> {revenueCount}</dd>
        </dl>
        <footer>
          <TappedButton
            type="text"
            textColor="pelorous"
            iconColor="pelorous"
            size="xs"
            iconSize="1rem"
            rightIcon="angle-right"
            href={`/deals/${dealID}/revenue${dateQueryString()}`}
            event={{
              screen_name: 'favedeals_explore',
              tapped_on: 'btn_revenue_breakdown'
            }}
          >
            {t('Revenue breakdown')}
          </TappedButton>
          <TappedButton
            type="text"
            textColor="pelorous"
            iconColor="pelorous"
            size="xs"
            iconSize="1rem"
            rightIcon="angle-right"
            href={`/deals/${dealID}`}
            event={{
              screen_name: 'favedeals_explore',
              tapped_on: 'btn_details'
            }}
          >
            {t('Details')}
          </TappedButton>
        </footer>
      </section>
    </ImageCard>
  );
};

export default DealCard;
