import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import completedIcon from 'assets/images/ic-completed.svg';
import completedCurrentIcon from 'assets/images/ic-completed-current.svg';
import styles from 'assets/css/Steps.module.scss';

type StepsProps = {
  steps: any[];
  currentStep: string;
  className: string;
};

function Steps({ className, steps = [], currentStep }: StepsProps) {
  /*
    clone, find and mark current & completed steps,
    right to left by reverse order.
    allow isComplete prop override
  */
  let currentStepIndex = 0;
  const stepsWithStatus = [...steps]
    .reverse()
    .map((step, index) => {
      currentStepIndex = step.value === currentStep ? index : currentStepIndex;
      return {
        ...step,
        isCurrent: step.value === currentStep,
        isComplete: step.isComplete || index > currentStepIndex
      };
    })
    .reverse();
  return (
    <section className={classNames(styles.steps, className)}>
      {stepsWithStatus.map((step, index) => (
        <div
          key={step.text}
          className={classNames(
            styles.step,
            step.isComplete && styles.complete,
            step.isCurrent && styles.current
          )}
        >
          <div
            className={styles.iconWrapper}
            style={{ '--step-z-index': `-${index + 1}` } as CSSProperties}
          >
            {step.isComplete ? (
              <img
                className={styles.icon}
                src={step.isCurrent ? completedCurrentIcon : completedIcon}
                alt="progress icon"
              />
            ) : (
              <div className={styles.number}>{index + 1}</div>
            )}
          </div>
          <div className={styles.text}>{step.text}</div>
        </div>
      ))}
    </section>
  );
}

export default Steps;
