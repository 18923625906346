import { useEffect, useReducer, useCallback } from 'react';

const debugNextStateNotFound = ({
  current,
  event: { type: event },
  machine,
  debug = false
}) => () => {
  if (!debug) return;
  const state = current.state;
  console.group(state);

  console.warn(
    `State %c${state} %cdoes not have the event %c${event}`,
    'font-weight: bold',
    'font-weight:normal',
    'font-weight: bold'
  );
  console.log('Misspelled your event name?');

  console.group(`Events defined for ${state}:`);
  Object.keys(machine[state]).forEach(key => {
    console.log(`${key}`);
  });
  console.groupEnd();

  console.groupEnd();
};

export const resolveNextState = ({
  current,
  event,
  machine,
  initialState,
  debug
}) => {
  const getNextState =
    (machine[current.state] || {})[event.type] ||
    debugNextStateNotFound({ current, event, machine, debug });
  return getNextState(current, event, initialState) || current;
};

function useDebugger({ current, debug }) {
  useEffect(() => {
    if (debug) {
      const { state, ...restCurrent } = current;
      console.group(state);
      Object.keys(restCurrent).forEach(key => {
        console.log(`${key}:`, restCurrent[key]);
      });
      console.groupEnd();
    }
  }, [current, debug]);
}

export default function useStateMachine(
  machine,
  initialState,
  { debug = false } = {}
) {
  const init = () => initialState;
  const reducer = (current, event) =>
    resolveNextState({ current, event, machine, initialState, debug });

  const [current, dispatch] = useReducer(reducer, initialState, init);

  useDebugger({ current, debug });

  const send = useCallback((type, params) => dispatch({ type, ...params }), []);
  return [current, send];
}
