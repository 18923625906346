import React from 'react';
import classNames from 'classnames';

import styles from 'assets/css/Separator.module.scss';

function Separator({ className }: { className?: string }) {
  return <div className={classNames(styles.separator, className)}></div>;
}

export default Separator;
