import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SingleDatePicker as AirbnbSingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Icon from 'components/Icon';
import Button from 'components/Button';
import 'assets/css/DatePicker.scss'; /* why no `module`? see `.scss` comments */
import 'assets/css/SingleDatePicker.scss'; /* why no `module`? see `.scss` comments */
import styles from 'assets/css/SingleDatePicker.module.scss';

type SingleDatePickerProps = {
  date: moment.Moment;
  onChange: (date: moment.Moment) => void;
  orientation: 'vertical' | 'horizontal';
  isOutsideRange: (day: moment.Moment) => boolean;
  showToday: boolean;
  initialVisibleMonth: () => moment.Moment;
};

function SingleDatePicker({
  date,
  onChange = () => {},
  orientation = 'horizontal',
  isOutsideRange = () => false,
  showToday = false,
  initialVisibleMonth = () => moment()
}: SingleDatePickerProps) {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  return (
    <section className={styles.layout}>
      <Button
        type="ghost"
        className={classNames(styles.buttonLayout, isTouched && styles.touched)}
        onClick={() => setFocused(prevState => !prevState)}
      >
        <Icon
          className={styles.icon}
          icon="datepicker"
          size="1.5rem"
          color="primary"
        />
        <div className={styles.dateDisplay}>
          {showToday && date.isSame(moment(), 'day') ? `${t('Today')}, ` : ''}
          {date.format(`MMMM D`)}
        </div>
      </Button>
      <span className={styles.airbnbSingleDatePicker}>
        <AirbnbSingleDatePicker
          id="single_date_picker" // required for accessibility. Must be unique in a page
          withPortal
          displayFormat="DD/MM/YYYY"
          date={date as any} // as any needed as moment imported is from node_modules. so there's a mismatch of types here
          onDateChange={date => {
            setIsTouched(true);
            date && onChange(date);
          }}
          focused={focused}
          orientation={orientation}
          isOutsideRange={isOutsideRange}
          onFocusChange={({ focused }) => {
            setFocused(focused);
            // https://github.com/airbnb/react-dates/issues/1108#issuecomment-451768482
            document.activeElement instanceof HTMLElement &&
              document.activeElement.blur(); // Do not prompt the keyboard on mobile.
          }}
          initialVisibleMonth={initialVisibleMonth as any}
        />
      </span>
    </section>
  );
}

export default SingleDatePicker;
