import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

import { OutletContext } from 'contexts/OutletContext';
import getECardsRevenueDetails from 'queries/getECardsRevenueDetails';
import { WebviewContext } from 'contexts/WebviewContext';

import AppLayout from 'layouts/AppLayout';
import Heading from 'components/Heading';
import Button from 'components/Button';
import ECard from 'components/ECard';
import CostStructure from 'components/CostStructure';
import WebviewHeading from 'components/WebviewHeading';

import styles from 'assets/css/ECardsPendingDetailsView.module.scss';

const ECardsPendingDetailsView = ({ ecards_id }) => {
  const { t } = useTranslation();
  const { currentCompany } = useContext(OutletContext);
  const { isWebview } = useContext(WebviewContext);

  const [eCardsResponse, setECardsResponse] = useState({});
  const {
    currency,
    sellingPrice,
    originalPrice,
    discountRate,
    commissionRate,
    gstCommission,
    takebackProfit,
    createdAt,
    outletNo,
    validity,
    hasDefaultLogo,
    companyLogoUrl
  } = eCardsResponse || {};
  const {
    logo_url: outletImage,
    partner_name: companyName,
    id: company_id,
    num_bank_accounts
  } = currentCompany || {};
  const response = getECardsRevenueDetails({ ecards_id, company_id });
  const formatDate =
    (createdAt && format(parseISO(createdAt), 'h:mma, dd/MM/yyyy')) || '';

  useEffect(() => {
    if (!response.data) return;
    const {
      e_card: {
        currency,
        selling_price,
        original_price,
        discount,
        commision_rate,
        gst,
        takeback_profit,
        created_at,
        outlets_count,
        validity,
        default_logo,
        company_logo_url
      }
    } = response.data;

    setECardsResponse({
      currency,
      sellingPrice: selling_price,
      originalPrice: original_price,
      discountRate: discount,
      commissionRate: commision_rate,
      gstCommission: gst,
      takebackProfit: takeback_profit,
      createdAt: created_at,
      outletNo: outlets_count,
      validity: validity,
      hasDefaultLogo: default_logo,
      companyLogoUrl: company_logo_url
    });
  }, [response.data]);

  return (
    <AppLayout>
      {isWebview ? (
        <WebviewHeading title="View Details" backURL={'/ecards/pending'} />
      ) : (
        <>
          <Heading
            title="Pending approval"
            subtitle={t('Take a look at the eCards that you have submitted')}
          />
          <Button
            leftIcon="arrow-left"
            type="text"
            href={'/ecards/pending'}
            iconColor="light-black"
            children={t('Back')}
            textColor="charcoal-gray"
          />
        </>
      )}

      <div className={styles.container}>
        <div className={isWebview ? styles.webviewCard : styles.card}>
          <ECard
            sellingPrice={sellingPrice}
            discountRate={discountRate}
            originalPrice={originalPrice}
            outletImage={hasDefaultLogo ? outletImage : companyLogoUrl}
            companyName={companyName}
            currency={currency}
          />
          <span className={styles.createdDate}>
            {t('Submitted on ')} {formatDate}
          </span>
        </div>
        <CostStructure
          currency={currency || ''}
          originalPrice={originalPrice}
          sellingPrice={sellingPrice}
          discountRate={discountRate}
          commissionRate={commissionRate}
          commissionEnabled={Boolean(commissionRate)}
          takebackProfit={takebackProfit}
          gstCommission={gstCommission}
          outletNo={outletNo}
          validity={validity}
          showDisbursementMethod={num_bank_accounts > 1}
        />
      </div>
    </AppLayout>
  );
};

export default ECardsPendingDetailsView;
