import React, { useContext } from 'react';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { ModalContext } from 'contexts/ModalContext';
import styles from '../assets/css/AutoExtendECard.module.scss';
import classNames from 'classnames';
import AutoExtensionDeactivatedModal from 'components/AutoExtensionDeactivatedModal';
import FeedECardPreview from './FeedECardPreview';
import useCleverTapOld from 'hooks/useCleverTapOld';
import { OutletContext } from 'contexts/OutletContext';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
  CLEVERTAP_ADDITIONAL
} from 'helpers/TypeConstants';

type AutoExtendECardProps = {
  id: number
  companyName: string;
  purchaseCount: number;
  originalPrice: string;
  totalValue: string;
  bonusCreditAmount: string;
  deactivateVisible: boolean;
  isActive: boolean;
  item: any;
  handleDeactivation: (
    id: number,
    deactivation_reasons: object,
    onSuccess: () => void,
    finishSubmit: () => void
  ) => void;
};

const AutoExtendECard: React.FC<AutoExtendECardProps> = ({
    id,
    companyName,
    purchaseCount,
    originalPrice,
    totalValue,
    deactivateVisible,
    isActive,
    item,
    handleDeactivation
  }) => {
  const { t } = useTranslation();
  const { init } = useContext(ModalContext);
  const cleverTap = useCleverTapOld();
  const outletContext = useContext(OutletContext);

  const onClose = () => {
  }
 
  const renderDeactivatedModal = () => {
    init({
      Component: AutoExtensionDeactivatedModal,
      openOnReady: true,
      hasCloseButton: false,
      componentProps: {
        type: 'ecard',
        id: id,
        reasonsList: [],
        handleDeactivation: handleDeactivation,
        onClose: onClose,
        outletContext: outletContext
      }
    });
  };

  return (
    <div 
      className={classNames(styles.Container, !isActive && styles.inactive) }
    >
      <div className={styles.ecard}>
        <FeedECardPreview item={item} />
      </div>
      <div className={styles.content}>
        <div className={styles.companyName}>{companyName}</div>
        <div className={styles.price}>
            <span className={styles.word}>PAY</span> {originalPrice}, <span className={styles.word}>GET</span> {totalValue}
          </div>
        <div>
          <div className={styles.soldBox}>{purchaseCount} eCards sold</div>
        </div>
      </div>
      { deactivateVisible && isActive && (
        <div className={styles.action}>
          <Button
            fullWidth
            size="lg"
            className={styles.deactivate}
            onClick={() => {
              cleverTap.tapped.push({ 
                screen_name: CLEVERTAP_SCREEN_NAME.AUTO_EXTENSION,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_DEACTIVATION,
                offers_type: CLEVERTAP_ADDITIONAL.TYPE_ECARDS
              });
              renderDeactivatedModal();
              }}
          >
            {t('Deactivate')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AutoExtendECard;
