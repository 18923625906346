import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Badge from 'components/Badge';
import { BadgeWrapper, OptionsCard, OptionsLabel } from 'components/Reports';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import ReportingTable, {
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow
} from 'components/ReportingTable';
import { useInvoicesList, useProductList } from 'queries/getInvoiceSelfServe';
import { sendInvoiceEmail } from 'commands/invoiceSelfServe';
import { useOutletContext } from 'contexts/OutletContext';
import LoadingSpinnerSmall from 'components/LoadingSpinnerSmall';
import LoadingSpinner from 'components/LoadingSpinner';
import useObjectMapBiState, {
  ObjectMapBiState
} from 'hooks/useObjectMapBiState';
import useOnceCallback from 'hooks/useOnceCallback';
import { MonthOption, getInvoiceKey, monthList } from './uiHelper';

import styles from 'assets/css/InvoicesView.module.scss';
import useQueryErrorModal from 'hooks/useQueryErrorModal';
import EmptyDataMessage from 'components/EmptyDataMessage';
import SendToEmailForm from 'components/SendToEmailForm';
import { useNotificationContext } from 'contexts/NotificationContext';
import useCleverTap from 'hooks/useCleverTap';
import {
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON
} from 'helpers/TypeConstants';

const screenName = CLEVERTAP_SCREEN_NAME.TAX_INVOICE_INDIVIDUAL;

const InvoicesListingView = ({
  finance_account_id
}: {
  finance_account_id: number;
}) => {
  const { t } = useTranslation();
  const { screenDisplayed, tapped } = useCleverTap();
  const { currentCompany } = useOutletContext();
  const { setNotification } = useNotificationContext();

  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<MonthOption>();

  useOnceCallback(() =>
    screenDisplayed.push({
      screen_name: screenName
    })
  );

  const canFetchProductData =
    currentCompany !== undefined && selectedMonth !== undefined;

  const {
    data: products,
    isFetching: isFetchingProducts,
    isError: isProductListError,
    error: productListError
  } = useProductList(
    {
      finance_account_id,
      invoice_period_start: selectedMonth?.dataParam!,
      invoice_period_end: selectedMonth?.dataParam!
    },
    canFetchProductData
  );

  const {
    state: selectedProductsState,
    selectedKeys: selectedProductKeys,
    clear: clearSelectedProducts,
    toggleKey: toggleSelectedProduct,
    setAll: setAllSelectedProducts
  } = useObjectMapBiState();

  const {
    data: invoices,
    isFetching: isFetchingInvoices,
    isError: isInvoiceListError,
    error: invoiceListError
  } = useInvoicesList(
    {
      finance_account_id,
      invoice_period_start: selectedMonth?.dataParam!,
      invoice_period_end: selectedMonth?.dataParam!,
      products: selectedProductKeys
    },
    canFetchProductData && !isFetchingProducts && selectedProductKeys.length > 0
  );

  const {
    state: selectedInvoices,
    selectedKeys: selectedInvoicesKeys,
    clear: clearSelectedInvoices,
    toggleKey: toggleSelectedInvoice,
    setState: setSelectedInvoices,
    areAllValuesSet
  } = useObjectMapBiState();

  // auto select all products for the first time
  const { setUsed: setAutoSelectAllProductsUsed } = useOnceCallback(() => {
    setAllSelectedProducts(products!, (acc, product) => {
      acc[product] = true;
      return acc;
    });
  }, products !== undefined && Object.keys(selectedProductsState).length === 0);

  const handleSelectAllInvoicesClick = (checked: boolean) => {
    if (!invoices) return;

    const state = invoices.reduce<ObjectMapBiState>((acc, invoice) => {
      invoice.products.forEach(product =>
        product.documents.forEach(document => {
          acc[getInvoiceKey(invoice, product, document)] = checked;
        })
      );

      return acc;
    }, {});

    setSelectedInvoices(state);
  };

  const apiErrors = useMemo(
    () => [
      {
        isTriggered: isProductListError,
        error: productListError
      },
      {
        isTriggered: isInvoiceListError,
        error: invoiceListError
      }
    ],
    [isProductListError, isInvoiceListError, productListError, invoiceListError]
  );

  const { ErrorModal, reset: resetErrorModal } = useQueryErrorModal(apiErrors);

  const monthOptions = useMemo(
    () =>
      monthList.map(month => {
        const isSelected = selectedMonth === month;
        const classNames = [styles.monthOption];

        if (isSelected) classNames.push(styles.selected);

        return {
          title: t(month.title),
          classNames,
          showCheckMark: isSelected,
          onClick: () => {
            clearSelectedProducts();
            clearSelectedInvoices();

            setAutoSelectAllProductsUsed(false);
            setSelectedMonth(month);
            resetErrorModal();

            tapped.push({
              screen_name: screenName,
              tapped_on: CLEVERTAP_TAPPED_ON.FILTER_TAX_INVOICE_MONTHLY
            });
          }
        };
      }),
    [
      selectedMonth,
      t,
      setSelectedMonth,
      resetErrorModal,
      clearSelectedProducts,
      clearSelectedInvoices,
      setAutoSelectAllProductsUsed
    ]
  );

  const totalRowCount = useMemo(() => {
    if (!invoices) return 0;

    return invoices.reduce((acc, invoice) => {
      acc += invoice.products.reduce((acc, product) => {
        acc += product.documents.length;
        return acc;
      }, 0);

      return acc;
    }, 0);
  }, [invoices]);

  const allInvoicesSelected = areAllValuesSet(totalRowCount);

  return (
    <>
      <ErrorModal />

      <OptionsCard className={styles.optionsCard}>
        <OptionsLabel className={styles.optionsLabel}>
          {t('Select Month')}
        </OptionsLabel>
        <BadgeWrapper>
          <Dropdown
            contentClassName={styles.dropdownContent}
            dropdownButton={
              <Badge checked={selectedMonth !== undefined}>
                {t(selectedMonth ? selectedMonth.title : 'Select By Month')}
              </Badge>
            }
            onClick={isOpen => setIsMonthDropdownOpen(isOpen)}
            dropdownItems={monthOptions}
            showTopRightDropDown={isMonthDropdownOpen}
            showToggleButton={false}
          />

          {/* todo: remove comment when date range support is added */}
          {/* <Badge checked={false} onChange={() => handleBadgeClick()}>
            {t('Select By Range')}
          </Badge> */}
        </BadgeWrapper>

        {selectedMonth && (
          <>
            <OptionsLabel
              className={classNames(styles.optionsLabel, styles.last)}
            >
              {t('Select the type of report you would like to generate')}
            </OptionsLabel>

            {isFetchingProducts ? (
              <LoadingSpinnerSmall />
            ) : (
              <BadgeWrapper>
                {!products || products.length === 0 ? (
                  <EmptyDataMessage
                    title={t('No available products for the selected period')}
                  />
                ) : (
                  products.map(product => (
                    <Badge
                      key={product}
                      checked={selectedProductsState[product]}
                      onChange={() => toggleSelectedProduct(product)}
                    >
                      {t(product)}
                    </Badge>
                  ))
                )}
              </BadgeWrapper>
            )}
          </>
        )}
      </OptionsCard>

      {isFetchingInvoices ? (
        <LoadingSpinner className={styles.invoicesSpinner} />
      ) : !invoices || invoices.length === 0 ? (
        <EmptyDataMessage
          title={t('No data is currently available')}
          visible={selectedProductKeys.length > 0}
        />
      ) : (
        <>
          <ReportingTable>
            <TableHead>
              <TableRow>
                <TableHeadCell>{t('Period')}</TableHeadCell>
                <TableHeadCell textCenter>{t('Product')}</TableHeadCell>
                <TableHeadCell>{t('Document')}</TableHeadCell>
                <TableHeadCell textRight>
                  <Checkbox
                    name={'selectAll'}
                    id={'selectAll'}
                    checked={allInvoicesSelected}
                    disabled={false}
                    type={'green'}
                    onChange={handleSelectAllInvoicesClick}
                  />
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invoices.map(invoice => {
                const { invoice_period, products } = invoice;

                return products.map(product => {
                  const { name: productName, documents } = product;
                  const groupRowCount = documents.length;

                  return documents.map((document, docIndex) => {
                    const { name: docName } = document;
                    const key = getInvoiceKey(invoice, product, document);
                    const isGroupingRow = docIndex > 0;
                    const isLastGroupingRow = docIndex === documents.length - 1;

                    return (
                      <TableRow key={key} groupRow={isLastGroupingRow}>
                        {!isGroupingRow && (
                          <>
                            <TableBodyCell rowSpan={groupRowCount}>
                              {t(invoice_period)}
                            </TableBodyCell>
                            <TableBodyCell textCenter rowSpan={groupRowCount}>
                              {t(productName)}
                            </TableBodyCell>
                          </>
                        )}
                        <TableBodyCell className={styles.docName}>
                          {docName}
                        </TableBodyCell>
                        <TableBodyCell textRight>
                          <Checkbox
                            name={key}
                            id={key}
                            checked={selectedInvoices[key]}
                            disabled={false}
                            type={'green'}
                            onChange={() => toggleSelectedInvoice(key)}
                          />
                        </TableBodyCell>
                      </TableRow>
                    );
                  });
                });
              })}
            </TableBody>
          </ReportingTable>

          <SendToEmailForm
            disabled={selectedInvoicesKeys.length === 0}
            onSubmit={email => {
              tapped.push({
                screen_name: screenName,
                tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_SEND
              });

              sendInvoiceEmail({
                finance_account_id,
                email,
                invoice_period_start: selectedMonth!.dataParam!,
                invoice_period_end: selectedMonth!.dataParam!,
                documents: selectedInvoicesKeys.map(
                  key => key.split('/').pop()!
                )
              })
                .then(() =>
                  setNotification({
                    message: t(
                      'We are processing your request. Please check your email for confirmation'
                    ),
                    type: 'success'
                  })
                )

                .catch(() =>
                  setNotification({
                    message: t(
                      'An error occurred while processing your request'
                    ),
                    type: 'error'
                  })
                );
            }}
          />
        </>
      )}
    </>
  );
};

export default InvoicesListingView;
