import useOutletContext from 'hooks/useOutletContext';
import { Channel, Socket } from 'phoenix';
import React, { useCallback, useEffect, useRef } from 'react';
import { isObject } from 'utils';

// TODO: move to OutletContext.tsx later
interface Outlet {
  id: string;
}

interface ProviderValue {
  onNewTransaction: Function;
}

const RelaymanContext = React.createContext<ProviderValue>({
  onNewTransaction: () => {}
});

const RelaymanContextProvider = (props: any) => {
  const { currentOutlet } = useOutletContext();
  const { onNewTransaction } = useTransactionsRelayman(currentOutlet);
  return (
    <RelaymanContext.Provider value={{ onNewTransaction }}>
      {props.children}
    </RelaymanContext.Provider>
  );
};

function useTransactionsRelayman(currentOutlet: Outlet): ProviderValue {
  const callbacksRef = useRef<Function[]>([]);
  const socketRef = useRef<Socket>(
    new Socket(process.env.REACT_APP_RELAYMAN_API)
  );
  const channelRef = useRef<Channel>();
  useEffect(() => {
    if (!isObject(currentOutlet)) return;
    if (channelRef.current) return;
    if (!socketRef.current.isConnected()) socketRef.current.connect();
    const topic = `source:/outlets/${currentOutlet.id}/transactions`;
    channelRef.current = socketRef.current.channel(topic);
    channelRef.current.on('created', res =>
      callbacksRef.current.forEach(callback => callback(res))
    );
    channelRef.current.join();
  }, [currentOutlet]);

  return {
    onNewTransaction: useCallback(callback => {
      callbacksRef.current.push(callback);
    }, [])
  };
}

export { RelaymanContext, RelaymanContextProvider };
