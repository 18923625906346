import Kfit from '../integrations/Kfit';
import DataLoader from 'dataloader';

export default params => loadInfo('/v5/campaigns', params);

const userCacheKeyHasher = key => {
  return JSON.stringify(key);
};

export const Repo = new DataLoader(
  keys => Promise.all(keys.map(({ key, params }) => Kfit.get(key, params))),
  {
    batch: false,
    cacheKeyFn: userCacheKeyHasher
  }
);

function loadInfo(key, params) {
  return Repo.load({ key, params });
}
